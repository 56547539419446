import { JobTypeEnum } from './../dtos/job-type.enum';
import { NotificationService } from './../services/notification.service';
import { UserService } from './../services/felixApi/user.service';
import { CompanyService } from './../services/felixApi/company.service';
import { Component, ElementRef, OnInit, ViewChild, Output, EventEmitter, HostListener, OnDestroy, Input } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IJob } from '../dtos/job';
import { JobService } from '../services/felixApi/job.service';
import { GlobalService } from '../services/global.service';
import { JobSearchModalComponent } from '../jobs/job-search-modal.component';
import { JobSearchTypeEnum } from '../dtos/job-search-type.enum';
import { AuthService } from '../services/auth.service';
import { Subscription } from 'rxjs';
import { JobExtra } from '../dtos/job-extra';

@Component({
  selector: 'js-job-number',
  templateUrl: './job-number.component.html',
  styleUrls: ['./job-number.component.scss']
})
export class JobNumberComponent implements OnInit, OnDestroy {
  @Input() fromDashboard: boolean;
  @Input() fromJobMargin: boolean;
  @Input() calcWidth = true;
  @Output() refreshJobNumber: EventEmitter<string> =
    new EventEmitter<string>();

  COMPONENT_NAME = 'job-number';
  DASHBOARD_WIDTH = 720;
  PHONE_SIZE = 660;
  subscriptions: Subscription[] = [];

  newJobNumberUpperCase = '';
  currentJob: IJob;
  errorMessage: any;
  closeResult = '';
  jobSearchTypeEnum = JobSearchTypeEnum; // when we do a job search we can specify if we want to get the templates or all jobs initially
  loading = false; // loading spinner
  isClient: boolean; // it this user a client or a trade
  innerWidth: number; // width of screen
  stringWidth: number;
  isOnlyOneJob = false; // for clients if we have only one job we can hide the list search button

  @ViewChild('jobElementRef') jobElementRef: ElementRef;
  includeNameInDesc: boolean;
  currentJobExtra: JobExtra;

  jobString: string;
  jobAddress: string;
  currentActivity = '';
  isAssociate: boolean;
  listSearchText = 'List / Search';

  // get the width of the screen
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.calcWidthDeduct();
  }

  constructor(private globalService: GlobalService,
    private modalService: NgbModal, private _authService: AuthService,
    private companyService: CompanyService,
    private userService: UserService,
    private notiService: NotificationService,
    public _jobService: JobService) { }

  ngOnInit() {
    // console.log('job-number OnInit');
    this.isClient = this._authService.isClient();
    this.isAssociate = this._authService.isAssociate();
    if (this.isClient) {
      this.isOnlyOneJob = true;
    }

    if (this.fromDashboard) {
      this.listSearchText = 'List';
    }

    this._jobService.currentJobNum = this.globalService.getCurrentJob();
    this.newJobNumberUpperCase = this._jobService.currentJobNum.toUpperCase().trim();
    this.getUsers();

    this.innerWidth = window.innerWidth;
    this.calcWidthDeduct();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getUsers() {
    this.subscriptions = this.subscriptions.concat(
      this.userService.getUsersAndDivisions(true)
        .subscribe({
          next: () => {
            this.setUpJob();
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  setUpJob() {
    if (this._jobService.currentJobNum && this._jobService.currentJobNum !== '') {
      this.getJob(this._jobService.currentJobNum, true);
    } else if (this.isClient) {
      this.loadClientsFirstjob();
    } else if (this.isAssociate) {
      this.jobSearch();
    }
  }

  loadClientsFirstjob() {
    this.jobAddress = '';
    this.subscriptions = this.subscriptions.concat(
      this._jobService.getJobsByName('')
        .subscribe({
          next: (jobs) => {
            this.loading = false;
            if (!jobs.length) {
              this.changeJNum();
              this._jobService.currentJobString = 'No jobs found. Please see company contact';
              this.jobString = this._jobService.currentJobString;
            } else {
              if (jobs.length === 1) {
                this.isOnlyOneJob = true;
              } else {
                this.isOnlyOneJob = false;
              }
              this.calcWidthDeduct();
              this.globalService.setCurrentJob(jobs[0].jobNumber);
              this._jobService.currentJobNum = jobs[0].jobNumber;
              this.currentJob = jobs[0];
              this.jobString = this.fromDashboard ? '(' + this.currentJob.jobNumber + ') ' + this.currentJob.contractName : this.currentJob.contractName;
              this.jobAddress = this.globalService.getJobString(this.currentJob, false);
              this._jobService.currentJob = this.currentJob;

              this.getJobExtras();
              // this.refreshJobNumber.emit(this._jobService.currentJobNum);
            }
          },
          error: (err) => {
            this.changeJNum();
            this._jobService.currentJobString = 'No jobs found. Please see company contact';
            this.jobString = this._jobService.currentJobString;
            this.errorMessage = <any>err;
            this.loading = false;
          }
        })
    );
  }

  checkClientForMultipleJobs() {
    this.jobAddress = '';
    this.subscriptions = this.subscriptions.concat(
      this._jobService.getJobsByName('')
        .subscribe({
          next: (jobs) => {
            this.loading = false;
            if (!jobs.length) {
              this.changeJNum();
              this._jobService.currentJobString = 'No jobs found. Please see company contact';
              this.jobString = this._jobService.currentJobString;
            } else {
              if (jobs.length === 1) {
                this.isOnlyOneJob = true;
              } else {
                this.isOnlyOneJob = false;
              }
              this.calcWidthDeduct();
            }
          },
          error: (err) => {
            this.changeJNum();
            this._jobService.currentJobString = 'No jobs found. Please see company contact';
            this.jobString = this._jobService.currentJobString;
            this.errorMessage = <any>err;
            this.loading = false;
          }
        })
    );
  }

  calcWidthDeduct() {
    if (this.innerWidth < this.PHONE_SIZE || this.isClient) {
      if (this.fromDashboard) {
        if (this.innerWidth > this.DASHBOARD_WIDTH) {
          this.innerWidth = this.DASHBOARD_WIDTH;
        }
      }
      this.includeNameInDesc = false;
      this.stringWidth = this.innerWidth - 140;

      if (this.isOnlyOneJob) {
        this.stringWidth += 113;
      }
    } else {
      this.includeNameInDesc = true;

      if (this.fromDashboard) {
        if (this.innerWidth > this.DASHBOARD_WIDTH) {
          this.innerWidth = this.DASHBOARD_WIDTH;
        }
      }

      this.stringWidth = this.innerWidth - 330;

      if (this.fromJobMargin && this.stringWidth > 900) {
        this.stringWidth = 900;
      }
    }
    if (this.currentJob) {
      this.jobString = this.fromDashboard ? '(' + this.currentJob.jobNumber + ') ' + this.currentJob.contractName : this.currentJob.contractName;
      this.jobAddress = this.globalService.getJobString(this.currentJob, false);
    }
  }

  setJobNum() {
    this._jobService.currentJobNum = this._jobService.currentJobNum.trim();
    this.getJob(this._jobService.currentJobNum, false);
  }

  onBlur() {
    this._jobService.currentJobNum = this._jobService.currentJobNum.trim();
    if (this.newJobNumberUpperCase !== this._jobService.currentJobNum.toUpperCase()) {
      this.getJob(this._jobService.currentJobNum, false);
    }
  }

  getJob(jobno: string, calledFromInit: boolean) {
    if (!this.loading) {
      this._jobService.currentJobString = '';
      this.jobString = '';
      this.jobAddress = '';
      this.loading = true;
      this.currentActivity = '';

      this.subscriptions = this.subscriptions.concat(
        this._jobService.getJob(jobno)
          .subscribe({
            next: (currentJob) => {
              this.currentJob = currentJob;

              if (currentJob) {
                this._jobService.currentJobNum = currentJob?.jobNumber;
                this.newJobNumberUpperCase = currentJob?.jobNumber.toUpperCase();
                this.loading = false;
                this.globalService.setCurrentJob(this._jobService.currentJobNum);
                this._jobService.currentJobString = this.globalService.getJobString(this.currentJob, false);
                this.jobString = this.fromDashboard ? '(' + this.currentJob.jobNumber + ') ' + this.currentJob.contractName : this.currentJob.contractName;
                this.jobAddress = this.globalService.getJobString(this.currentJob, false);
                this._jobService.currentJob = this.currentJob;
                this.getJobExtras();
                if (this.isClient) {
                  this.checkClientForMultipleJobs();
                }
              } else {
                this.jobNotFound(calledFromInit);
              }
            },
            error: () => {
              this.jobNotFound(calledFromInit);
            }
          })
      );
    }
  }

  jobNotFound(calledFromInit: boolean) {
    this.changeJNum();
    if (calledFromInit) {
      this._jobService.currentJobString = '';
      this.globalService.setCurrentJob('');
      this._jobService.currentJobNum = '';
      this._jobService.currentJobExtra = null;
      if (this.isClient) {
        this.loadClientsFirstjob();
      } else if (this.isAssociate) {
        this.jobSearch();
      }
    } else {
      this._jobService.currentJobString = 'Job Not Found';
    }
    this.jobString = this._jobService.currentJobString;
    this.loading = false;
    this.currentJob = null;
  }

  changeJNum() {
    this.currentJob = null;
    this.loading = false;
    this.globalService.setCurrentJob('');
    this._jobService.currentJobString = '';
    this.jobString = '';
    this.jobAddress = '';
    this._jobService.currentJob = null;
    this._jobService.currentJobExtra = null;
    this.currentJobExtra = null;
    this.refreshJobNumber.emit(null);
  }

  jobSearch() {
    const modalRef = this.modalService.open(JobSearchModalComponent, { windowClass: 'modal-search' });
    modalRef.componentInstance.searchType = this.jobSearchTypeEnum.AllJobs;

    modalRef.result.then((jobNumber) => {
      if (jobNumber) {
        this.getJob(jobNumber, false);
      } else if (this._jobService.currentJobNum && this._jobService.currentJobNum !== '') {
        this.getJob(this._jobService.currentJobNum, false);
      } else {
        this.changeJNum();
      }
    }, (reason) => {
      if (this._jobService.currentJobNum && this._jobService.currentJobNum !== '') {
        this.getJob(this._jobService.currentJobNum, false);
      } else {
        this.changeJNum();
      }
    });
  }

  getJobExtras() {
    this.currentJobExtra = null;
    this._jobService.currentJobExtra = null;
    this.currentActivity = '';

    this.subscriptions.push(
      this._jobService.getJobExtras(this.currentJob.id)
        .subscribe({
          next: (jobExtra) => {
            this.currentJobExtra = jobExtra;
            this._jobService.currentJobExtra = jobExtra;

            if (jobExtra?.maintenanceCompleteDate) {
              this.currentActivity = 'Maintenance Complete';
            } else {
              this.currentActivity = this.companyService
                .activities.find(i => i.id === this._jobService.currentJobExtra?.currentActivityId)?.description;

              this.setActivityNotSold();
            }
            this.refreshJobNumber.emit(this.currentJob.jobNumber);
          },
          error: () => {
            this.setActivityNotSold();
            this.refreshJobNumber.emit(this.currentJob.jobNumber);
          }
        })
    );
  }

  setActivityNotSold() {
    if ((!this.currentActivity || this.currentActivity === '') && !this._jobService.currentJob.salesDate) {
      if (this._jobService.currentJob.jobTypeId === JobTypeEnum.StandardJob) {
        this.currentActivity = 'Pending Sale';
      } else {
        this.currentActivity = '';
      }
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
