<div *ngIf="!prevItem && !sameItem && prevAndCurrentValid && priceChange">
  <span *ngIf="priceChange > 0">
    ${{priceChange | number}}
  </span>
  <span *ngIf="priceChange < 0">
    ${{(priceChange * -1) | number}}CR
  </span>
</div>

<div *ngIf="!prevItem && !sameItem && !prevAndCurrentValid">
  $TBA
</div>

<div *ngIf="sameItem || prevEqualsSelected">
  <i class="material-icons backGreen">done</i>
</div>

<div *ngIf="prevItem && !prevEqualsSelected">
  <i class="material-icons backRed">restore</i>
</div>