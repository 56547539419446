import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { Vendor } from '../../dtos/vendor';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class ManualOrdersService {
  // loadingCostCentres: boolean;
  // userVendors: UserVendor[] = [];
  vendors: Vendor[];
  vendorCompany: string;

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService
  ) { }

  // getVarianceCodes(): Observable<VarianceCode[]> {
  //   this.globalService.setIsGetChildren(true);
  //   return this._http.get<VarianceCode[]>(this.globalService.getApiUrl() +
  //     '/variancecodes', this.httpService.getHttpOptions()).pipe(
  //       catchError(this.handleError));
  // }

  // getVarianceReasons(): Observable<VarianceReason[]> {
  //   this.globalService.setIsGetChildren(true);
  //   return this._http.get<VarianceReason[]>(this.globalService.getApiUrl() +
  //     '/variancereasons', this.httpService.getHttpOptions()).pipe(
  //       catchError(this.handleError));
  // }

  getVendors(): Observable<Vendor[]> {
    if (this.vendors && this.vendors.length
      && this.vendorCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.vendors);
    } else {
    this.globalService.setIsGetChildren(true);
    return this._http.get<Vendor[]>(this.globalService.getApiUrl() +
      '/vendors', this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.vendors = res; this.vendorCompany = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
      }
  }

  // getUserVendors(): Observable<UserVendor[]> {
  //   this.globalService.setIsGetChildren(true);
  //   return this._http.get<UserVendor[]>(this.globalService.getApiUrl() +
  //     '/uservendors', this.httpService.getHttpOptions()).pipe(
  //       catchError(this.handleError));
  // }

  // addUserVendor(vendorId: number) {
  //   const url = this.globalService.getApiUrl() + '/uservendors/?vendorId=' + vendorId;
  //   return this._http.post(url, '', this.httpService.getHttpOptions());
  // }

  // deleteUserVendor(vendorId: number) {
  //     const url = this.globalService.getApiUrl() + '/uservendors/?vendorId=' + vendorId;
  //     return this._http.delete(url, this.httpService.getHttpOptions());
  // }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
