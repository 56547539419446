<div class="modal-header">
  <h3 class="modal-title">Select Categories</h3>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <dx-data-grid #dataGrid id="dataGrid" [dataSource]="dataSource" [allowColumnReordering]="true" [height]="gridHeight"
    [showColumnLines]="true" [showRowLines]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true"
    [showBorders]="true" [rowAlternationEnabled]="false" [wordWrapEnabled]="true" [(selectedRowKeys)]="selectedRows">

    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"> </dxo-paging>
    <dxo-selection selectAllMode="allPages" showCheckBoxesMode="always" mode="multiple"> </dxo-selection>

    <dxi-column dataField="description" caption="Category"> </dxi-column>
  </dx-data-grid>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-primary" (click)="close()">Ok</button>
</div>
