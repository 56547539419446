<div class="modal-header">
  <h2 *ngIf="!loading" class="modal-title">Confirm Delete</h2>
  <button type="button" class="close" aria-label="Close" (click)="passAnswer(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div *ngIf="!loading">
    <div>Reverting this item to it's previous value will delete this variation item and also delete any changes to any
      linked items.</div>
    <br>
    <button class="btn btn-sm btn-primary" (click)="passAnswer(true)">Delete Variation Item(s)</button>
    <button class="btn btn-sm btn-primary marginLeft15" (click)="passAnswer(false)">Cancel</button>
  </div>
  <!-- Loading Spinner -->
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>
</div>