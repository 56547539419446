export class TrackingFieldLookup {
    public id: number;
    public trackingFieldId: number;
    public description: string;
    public orderNo: number;
    public modifiedDate: Date;
    public modifiedUserId: number;

    constructor(id: number, desc: string, orderNo: number, trackingFieldId: number) {
      this.id = id;
      this.description = desc;
      this.orderNo = orderNo;
      this.trackingFieldId = trackingFieldId;
    }
}
