import { Component, OnInit, Input, OnDestroy, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { IJobItem } from '../../dtos/job-item';
import { ItemTypeEnum } from '../../dtos/item-type.enum';
import { IJobItemAttachment } from '../../dtos/job-item-attachment';
import { JobItemAttachmentService } from '../../services/felixApi/job-item-attachment.service';
import { JobVarItemAttachmentService } from '../../services/felixApi/job-var-item-attachment.service';
import { AttachmentTypeEnum } from '../../dtos/attachment-type.enum';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'js-selection-attachment-modal',
  templateUrl: './selection-attachment-modal.component.html',
  styleUrls: ['./selection-attachment-modal.component.scss']
})
export class SelectionAttachmentModalComponent implements OnInit, OnDestroy {
  @Input() jobitem: IJobItem;
  @Input() variationId: number;
  @Input() isReadOnly: boolean;

  COMPONENT_NAME = 'selection-attachment-modal';
  subscriptions: Subscription[] = [];

  filesToUpload: any[] = [];
  wrongFileType = false;
  jobItemAttachment: IJobItemAttachment;
  modalImageLoading = false;
  deleteImgSelected = false;
  ItemType = ItemTypeEnum;
  attachmentId: number;

  attachmentForm: UntypedFormGroup;
  updatedOption: any;
  loading = false;
  updateError = false;
  errorMessage = '';
  fileIsOpen = false;
  deleteAreYouSureCheck = false;
  attachmentForThisVO = false; // is this attachment for this variation or not
  pdf: any;
  attachmentTypeEnum = AttachmentTypeEnum;
  isClient: boolean;
  fileInputId: any;
  mobileFriendlyZoomSetting = '150%';
  showLoader = false;
  imageWidth: number;
  imageHeight: number;
  compressImageToStore = true;

  @HostListener('window:resize') onResize() {
    this.setImageDimensions();
  }

  constructor(private _jobItemAttachmentService: JobItemAttachmentService,
    private _jobVarItemAttachmentService: JobVarItemAttachmentService,
    private formBuilder: UntypedFormBuilder, private activeModal: NgbActiveModal,
    private _authService: AuthService,
    private fileService: FileService,
    private notiService: NotificationService
  ) { }

  ngOnInit() {
    this.setImageDimensions();
    this.isClient = this._authService.isClient();

    this.attachmentForm = this.formBuilder.group({
      selection: this.jobitem.selection,
      attachmentName: ''
    });

    if (this.jobitem.attachmentVariationId === this.variationId) {
      this.attachmentForThisVO = true; // so can delete this file
    }

    if (this.jobitem.attachmentTypeId) {
      this.attachmentId = this.jobitem.id; // store in case we change it
    }

    if (this.attachmentId) {
      this.openFile();
    }

    if (!this.loading && !this.isReadOnly
      && ((!this.variationId && !this.attachmentId)
        || (!this.attachmentForThisVO && this.jobitem.changedByVOId === this.variationId && this.variationId))) {
      this.showLoader = true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setImageDimensions() {
    this.imageWidth = window.innerWidth > 1000 ? 760 : window.innerWidth - 85;
    this.imageHeight = this.imageWidth * 2 / 3;

    if (this.imageHeight > window.innerHeight - 220) {
      this.imageHeight = window.innerHeight - 220;
      this.imageWidth = this.imageHeight * 3 / 2;
    }
  }

  openFile() {
    // open the file
    this.loading = true;
    if (this.jobitem.attachmentVariationId == null || this.jobitem.attachmentVariationId === 0) {
      // get original job item attachment
      this.subscriptions = this.subscriptions.concat(
        this._jobItemAttachmentService.getJobItemAttachmentById(this.jobitem.id).subscribe({
          next: (jobItemAttachment) => {
            this.jobItemAttachment = jobItemAttachment;
            if (this.jobItemAttachment.attachmentTypeId === this.attachmentTypeEnum.PDF) {
              this.pdf = this.base64ToArrayBuffer(this.jobItemAttachment.attachment);
            }
            this.fileIsOpen = true;
            this.loading = false;
            this.showLoader = false;
            this.updateError = false;
            if (this.jobitem.attachmentId) {
              this.attachmentForm.patchValue({
                attachmentName: this.jobItemAttachment.attachmentName
              });
            }
          },
          error: (err) => {
            this.updateError = true;
            this.loading = false;
            this.errorMessage = 'There was an error opening the file. Please try again in a moment.';
            this.notiService.notify(err);
          }
        })
      );
    } else {
      // get job variationitem attachment
      this.subscriptions = this.subscriptions.concat(
        this._jobVarItemAttachmentService.getJobItemAttachmentById(this.jobitem.changedByJobVarId).subscribe({
          next: (jobItemAttachment) => {
            this.jobItemAttachment = jobItemAttachment;
            if (this.jobItemAttachment.attachmentTypeId === this.attachmentTypeEnum.PDF) {
              this.pdf = this.base64ToArrayBuffer(this.jobItemAttachment.attachment);
            }
            this.fileIsOpen = true;
            this.loading = false;
            this.showLoader = false;
            this.updateError = false;
            if (this.jobitem.attachmentId) {
              this.attachmentForm.patchValue({
                attachmentName: this.jobItemAttachment.attachmentName
              });
            }
          },
          error: (err) => {
            this.updateError = true;
            this.loading = false;
            this.errorMessage = 'There was an error opening the file. Please try again in a moment.';
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  addFile() {
    this.loading = true;
    const fileList = this.filesToUpload;
    const file = fileList[0];

    const attachmentType = this.fileService.getFileType(file.name);
    // console.log(file.name);
    // console.log(attachmentType);
    if (!attachmentType) {
      this.notiService.showWarning('Only a PDF, PNG or JPG file can be loaded. Please reselect.');
      this.loading = false;
    } else if (attachmentType === this.attachmentTypeEnum.PDF) {
      // console.log('PDF here');
      this.saveFile(file, attachmentType);
    } else {
      this.subscriptions = this.subscriptions.concat(
        this.fileService.fileToBase64(file).subscribe({
          next: (base64Image) => {
            this.subscriptions = this.subscriptions.concat(
              this.fileService.compressImage(base64Image, file.name, this.compressImageToStore).subscribe({
                next: (compressedImgForm) => {
                  this.saveFile(compressedImgForm, attachmentType);
                }, error: (err) => {
                  this.fileError(err, 'saving');
                }
              })
            );
          }, error: (err) => {
            this.fileError(err, 'saving');
          }
        })
      );
    }
  }

  saveFile(file, attachmentType: number) {
    const formData = this.fileService.fileToFormData(file);

    const isMaster = !this.variationId || this.variationId === 0;
    const service = isMaster
      ? this._jobItemAttachmentService // master
      : this._jobVarItemAttachmentService; // VO
    const updateID = isMaster
      ? this.jobitem.id
      : this.jobitem.changedByJobVarId;

    this.subscriptions = this.subscriptions.concat(
      service.postJobItemAttachment(updateID, formData).subscribe({
        next: () => {
          this.updateError = false;
          this.jobitem.attachmentName = file.name;
          this.jobitem.attachmentTypeId = attachmentType;
          this.jobitem.attachmentId = updateID;
          this.attachmentForm.patchValue({
            attachmentName: file.name
          });
          this.filesToUpload = [];
          this.attachmentId = updateID;
          this.attachmentForThisVO = true;

          if (!isMaster) {
            this.jobitem.attachmentVariationId = this.variationId;
          }
          this.openFile();
        },
        error: (err) => {
          this.fileError(err, 'saving');
        }
      })
    );
  }

  deleteFile() {
    if (this.deleteAreYouSureCheck) {
      this.deleteAreYouSureCheck = false;
      this.loading = true;

      const isMaster = !this.jobitem.changedByJobVarId || this.jobitem.changedByJobVarId === 0;
      const service = isMaster
        ? this._jobItemAttachmentService
        : this._jobVarItemAttachmentService;
      const updateID = isMaster
        ? this.jobitem.id
        : this.jobitem.changedByJobVarId;

      this.subscriptions = this.subscriptions.concat(
        service.deleteJobItemAttachment(updateID).subscribe({
          next: () => {
            this.attachmentId = null;
            this.fileIsOpen = false;
            this.attachmentForm.patchValue({
              attachmentName: ''
            });

            if (!isMaster) {
              this.attachmentForThisVO = false;
            }

            this.filesToUpload = [];
            this.showLoader = true;
            this.updateError = false;
            this.loading = false;
          },
          error: (err) => {
            this.fileError(err, 'deleting');
          }
        })
      );
    } else {
      this.deleteAreYouSureCheck = true;
    }
  }

  fileError(err, verb: string) {
    this.updateError = true;
    this.errorMessage = 'There was an error' + verb + 'the file. Please try again in a moment.';
    this.notiService.notify(err);
    this.loading = false;
  }

  handleFileInput(eventRecord) {
    if (eventRecord.value?.length) {
      for (let i = 0; i < eventRecord.value.length; i++) {
        let nameExtn = '';
        nameExtn = eventRecord.value[i].name.toUpperCase().split('.').pop();
        // const fileType = this.fileService.getFileType(eventRecord.value[i].name);

        if (!nameExtn || (nameExtn.toUpperCase() !== 'JPG' && nameExtn.toUpperCase() !== 'JPEG'
          && nameExtn.toUpperCase() !== 'PNG' && nameExtn.toUpperCase() !== 'PDF')) {
          // console.log(nameExtn.toUpperCase());
          this.wrongFileType = true;
          return;
        }
      }
      this.wrongFileType = false;
      this.filesToUpload = eventRecord.value;
      this.addFile();
    }
  }

  base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  close() {
    this.activeModal.close(this.attachmentId);
  }
}
