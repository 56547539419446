import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { JobsModule } from '../jobs/jobs.module';

import { VariationlistComponent } from './variationlist.component';
import { VariationService } from '../services/felixApi/variation.service';
import { VariationDetailComponent } from './variation-detail.component';
import { EmailService } from '../services/felixApi/email.service';
import { AdminOverrideModalComponent } from './admin-override-modal/admin-override-modal.component';
import { CreateTaskModalComponent } from './create-task-modal/create-task-modal.component';
import { VariationSplitComponent } from './variation-split/variation-split.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    JobsModule,
    SharedModule,
    ReactiveFormsModule
  ],
  declarations: [
    VariationlistComponent,
    VariationDetailComponent,
    AdminOverrideModalComponent,
    CreateTaskModalComponent,
    VariationSplitComponent
  ],
  providers: [
    VariationService,
    EmailService
  ]
})
export class VariationsModule { }
