import { GlobalService } from './../../services/global.service';
import { AuthService } from './../../services/auth.service';
import { VariationSplitsService } from './../../services/felixApi/variation-splits.service';
import { NotificationService } from './../../services/notification.service';
import { ClaimsService } from './../../services/felixApi/claims.service';
import { CompanyService } from './../../services/felixApi/company.service';
import { JobService } from './../../services/felixApi/job.service';
import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import CustomStore from 'devextreme/data/custom_store';
import { CompanyActivity } from '../../dtos/company-activity';
import { ClaimJobLine } from '../../dtos/claim-job-line';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { Subscription } from 'rxjs';
import { formatDate } from 'devextreme/localization';
import { ConfigurationEnum } from '../../dtos/configuration-enum';

@Component({
  selector: 'js-variation-split',
  templateUrl: './variation-split.component.html',
  styleUrls: ['./variation-split.component.css']
})
export class VariationSplitComponent implements OnInit, OnDestroy {
  @Input() variationId: number;
  @Input() variationTotal: number;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  activities: CompanyActivity[];
  claimJobLines: ClaimJobLine[];

  claimSplitTypes = [
    { id: 1, description: 'Add amount to a claim' },
    { id: 2, description: 'Invoice at an activity' },
    { id: 3, description: 'Invoice at a date' }
  ];
  invoiceTos = [
    { id: 1, description: 'As per Claim' },
    { id: 2, description: 'From Loan' },
    { id: 3, description: 'Direct to Client' }
  ];
  claimTotal: number;
  claimsExist = true;
  outstandingAmount: number;
  isAdmin: boolean;
  lenderInformationEnabled: boolean;

  constructor(private activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private jobService: JobService,
    private authService: AuthService,
    private companyService: CompanyService,
    private claimService: ClaimsService,
    private variationSplitsService: VariationSplitsService,
    private notiService: NotificationService) {
    this.reorder = this.reorder.bind(this);
  }

  ngOnInit() {
    this.isAdmin = this.authService.isAdminOrSuperUser();

    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.LenderInformationEnabled) === 1) {
      this.lenderInformationEnabled = true;
    }

    this.setUpDataSet();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setUpDataSet() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.variationSplitsService
            .getVariationSplitsData(this.jobService.currentJob.jobNumber, this.variationId, this.jobService.currentJob.id)
            .subscribe({
              next: (res) => {
                this.activities = this.companyService.activities;
                this.claimJobLines = this.claimService.claimJobLines;
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }));
      },
      insert: async (values) => {
        values.jobVariationId = this.variationId;
        if (values.claimDate && values.claimDate instanceof Date) {
          values.claimDate = formatDate(values.claimDate, 'yyyy-MM-dd');
        }
        return new Promise((resolve, reject) =>
          this.variationSplitsService.addVariationSplit(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.variationSplitsService.updateVariationSplit(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.variationSplitsService.deleteVariationSplit(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  close() {
    this.activeModal.close();
  }

  reorder(e) {
    const visibleRows = e.component.getVisibleRows();
    const newOrderIndex = visibleRows[e.toIndex].data.orderNo;
    this.grid.instance.beginCustomLoading('Re-ordering');

    this.subscriptions.push(
      this.variationSplitsService.moveVariationSplit(e.itemData.id, newOrderIndex).subscribe({
        next: () => {
          this.grid.instance.endCustomLoading();
          e.component.refresh();
        }, error: () => {
          this.notiService.showInfo('Cannot drop here');
          this.grid.instance.endCustomLoading();
        }
      })
    );
  }

  calculateTotal = (options) => {
    if (options.name === 'gridTotal') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
        this.claimTotal = 0;
        this.claimsExist = false;

      } else if (options.summaryProcess === 'calculate') {
        this.claimsExist = true;
        options.totalValue += options.value.amount ? options.value.amount : 0;
        this.claimTotal = options.totalValue;

      } else if (options.summaryProcess === 'finalize') {
        this.outstandingAmount = this.variationTotal - this.claimTotal;
      }
    }
  }

  initNewRow = (e) => {
    e.data.isInvoiceToClient = true;
  }

  calculateInvoiceToValue(data) {
    return data.variationSplitTypeId === 1 ? 'As per Claim' : data.isInvoiceToClient ? 'Direct to Client' : 'From Loan';
  }

  setInvoiceToCellValue(rowData, value, originalData) {
    rowData.isInvoiceToClient = value === 'Direct to Client';
  }

  setVariationSplitType(rowData, value, originalData) {
    rowData.variationSplitTypeId = value;
    if (value === 1) {
      rowData.invoice = 'As per Claim';
      rowData.isInvoiceToClient = false;
    }
  }

  onEditorPreparing(e: any) {
    if (e.parentType === 'dataRow' && e.dataField === 'invoice' && e.row.data.variationSplitTypeId === 1) {
      e.editorOptions.disabled = true;
    }
  }
}
