<div class="modal-header">
  <h4 class="modal-title">Add Maintenance Job</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <div *ngIf="!loading" class="page m-auto">

    <dx-form id="form" [(formData)]="jobForm" [readOnly]="false" [showColonAfterLabel]="true" validationGroup="formData"
      labelLocation="left" [colCount]="4">

      <dxi-item dataField="jobNumber" editorType="dxTextBox" [editorOptions]="{ width: '150px' }" [colSpan]="4">
        <dxo-label location="left" alignment="right" text="Job Number"> </dxo-label>
        <dxi-validation-rule type="required" message="Job Number is required"> </dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="divisionId" editorType="dxSelectBox" [colSpan]="4"
        [editorOptions]="{ items: divisions, valueExpr: 'id', displayExpr: 'description' }">
        <dxo-label location="left" alignment="right" text="Division"> </dxo-label>
        <dxi-validation-rule type="required" message="Divison is required"> </dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="contractName" editorType="dxTextArea" [editorOptions]="{ height: 100 }" [colSpan]="4">
        <dxo-label location="left" alignment="right" text="Contract Name"> </dxo-label>
        <dxi-validation-rule type="required" message="Name is required"> </dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="streetNumber" editorType="dxTextBox" [editorOptions]="{ width: '150px' }" [colSpan]="4">
        <dxo-label location="left" alignment="right" text="Street Number"> </dxo-label>
      </dxi-item>

      <dxi-item dataField="streetName1" editorType="dxTextBox" [colSpan]="4">
        <dxo-label location="left" alignment="right" text="Street 1"> </dxo-label>
      </dxi-item>

      <dxi-item dataField="streetName2" editorType="dxTextBox" [colSpan]="4">
        <dxo-label location="left" alignment="right" text="Street 2"> </dxo-label>
      </dxi-item>

      <dxi-item dataField="suburbTown" editorType="dxTextBox" [colSpan]="2">
        <dxo-label location="left" alignment="right" text="Suburb"> </dxo-label>
      </dxi-item>

      <dxi-item dataField="state" editorType="dxTextBox" [colSpan]="1">
        <dxo-label location="left" alignment="right" text="State"> </dxo-label>
      </dxi-item>

      <dxi-item dataField="postCode" editorType="dxTextBox" [colSpan]="1">
        <dxo-label location="left" alignment="right" text="Post Code"> </dxo-label>
      </dxi-item>

      <dxi-item dataField="firstName1" editorType="dxTextBox" [colSpan]="2">
        <dxo-label location="left" alignment="right" text="First Name"> </dxo-label>
      </dxi-item>

      <dxi-item dataField="lastName1" editorType="dxTextBox" [colSpan]="2">
        <dxo-label location="left" alignment="right" text="Last Name"> </dxo-label>
      </dxi-item>

      <dxi-item dataField="phoneNumber1" editorType="dxTextBox" [colSpan]="2">
        <dxo-label location="left" alignment="right" text="Phone Number"> </dxo-label>
      </dxi-item>

      <dxi-item dataField="clientEmail" editorType="dxTextBox" [colSpan]="2">
        <dxo-label location="left" alignment="right" text="Email"> </dxo-label>
      </dxi-item>

      <dxi-item dataField="salesDate" editorType="dxDateBox" [editorOptions]="{ width: 150, type: 'date' }"
        [colSpan]="2">
        <dxo-label location="left" alignment="right" text="Date Sold"> </dxo-label>
        <dxi-validation-rule type="required" message="Date Sold is required"> </dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="contractSignedDate" editorType="dxDateBox" [editorOptions]="{ width: 150, type: 'date' }"
        [colSpan]="2">
        <dxo-label location="left" alignment="right" text="Contract Signed"> </dxo-label>
        <dxi-validation-rule type="required" message="Contract Signed date is required"> </dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="completionDate" editorType="dxDateBox" [editorOptions]="{ width: 150, type: 'date' }"
        [colSpan]="2">
        <dxo-label location="left" alignment="right" text="Completion Date"> </dxo-label>
        <dxi-validation-rule type="required" message="Completion date is required"> </dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="handoverDate" editorType="dxDateBox" [editorOptions]="{ width: 150, type: 'date' }"
        [colSpan]="2">
        <dxo-label location="left" alignment="right" text="Handover Date"> </dxo-label>
        <dxi-validation-rule type="required" message="Handover date is required"> </dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="maintenanceCompleteDate" editorType="dxDateBox" [colSpan]="2"
        [editorOptions]="{ width: 150, type: 'date' }">
        <dxo-label location="left" alignment="right" text="Maintenance Completed"> </dxo-label>
        <dxi-validation-rule type="required" message="Maintenance Complete date is required"> </dxi-validation-rule>
      </dxi-item>
    </dx-form>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" (click)="submit()">Update</button>
</div>
