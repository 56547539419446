import { JobDocumentService } from './../../services/felixApi/job-document.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { IJob } from '../../dtos/job';
import { JobItemService } from '../../services/felixApi/job-item.service';
import { JobService } from '../../services/felixApi/job.service';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '../../services/felixApi/configuration.service';
import { HouseType } from '../../dtos/house-type';
import { VariationService } from '../../services/felixApi/variation.service';
import { GlobalService } from '../../services/global.service';
import { JobTypeEnum } from '../../dtos/job-type.enum';

@Component({
  selector: 'js-job-copy-modal',
  templateUrl: './job-copy-modal.component.html',
  styleUrls: ['./job-copy-modal.component.scss']
})
export class JobCopyModalComponent implements OnInit, OnDestroy {
  @Input() askToDelete: boolean;
  @Input() appendToEnd: boolean;

  jobToCopy: IJob;
  jobToCopyFrom = '';
  deleteExistingJobItems: boolean;
  loading = true;
  updatedItem: any;
  subscriptions: Subscription[] = [];
  houseType: HouseType;
  hasVariations = false;
  copyVariations = false;
  deleteJobDocuments = false;
  askToDeleteInfo: boolean;

  constructor(private _jobItemService: JobItemService,
    private _variationService: VariationService,
    private _globalService: GlobalService,
    private _activeModal: NgbActiveModal,
    private _jobService: JobService,
    private _configurationService: ConfigurationService,
    private jobDocumentService: JobDocumentService,
    private notiService: NotificationService) { }

  ngOnInit() {
    if (this.askToDelete) {
      this.deleteExistingJobItems = false;
      this.loading = false;
    } else {
      this.deleteExistingJobItems = true;
      this.getHouseType();
    }

    this.checkInfo();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  checkInfo() {
    // we may have info records
    this.subscriptions = this.subscriptions.concat(
      this.jobDocumentService.getJobDocumentsWithChildren(this._globalService.getCurrentJob(), null).subscribe({
        next: (jobDocuments) => {
          if (jobDocuments && jobDocuments.length) {
            this.askToDeleteInfo = true;
          } else {
            this.askToDeleteInfo = false;
            this.deleteJobDocuments = true;
          }
        },
        error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  copyJob() {
    this.loading = true;
    let copyTypeId = 0;
    if (this.deleteExistingJobItems) {
      copyTypeId = 1;
    } else if (this.appendToEnd) {
      copyTypeId = 2;
    }

    this.updatedItem = {
      copyFromJobNo: this.jobToCopyFrom,
      createMissingToJob: false,
      copyTypeId: copyTypeId,
      includeVariations: this.copyVariations,
      deleteJobDocuments: this.deleteJobDocuments
    };
    this._jobItemService.currentJobItems = [];

    this.subscriptions = this.subscriptions.concat(
      this._jobItemService.copyJobItems(this.updatedItem).subscribe({
        next: (res) => {
          this._activeModal.close(this.copyVariations); // need to tell calling code to not create VO
        },
        error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  checkAnyVOsForCopiedJob() {
    // if at sales stage and there are VOs we can ask to copy them
    if (!this._jobService.currentJob.salesDate) {
      this.subscriptions = this.subscriptions.concat(
        this._variationService.getVariations(this.jobToCopyFrom)
          .subscribe({
            next: (variations) => {
              if (variations.length) {
                // we have variations so ask to copy also
                variations.forEach(varn => {
                  if (varn.variationType > 10 && varn.variationType < 20) {  // was && varn.hasItems
                    this.hasVariations = true;
                    this.copyVariations = true;
                  }
                });
              }
              this.loading = false;
            },
            error: () => {
              this.loading = false;
            }
          })
      );
    } else {
      this.loading = false;
    }
  }

  getJobToCopy() {
    this.jobToCopy = null;
    this.hasVariations = false;
    this.copyVariations = false;

    if (this.jobToCopyFrom === this._globalService.getCurrentJob()) {
      this.notiService.showInfo('Cannot copy from itself');
      this.loading = false;
    } else {
      this.loading = true;

      this.subscriptions = this.subscriptions.concat(
        this._jobService.getJob(this.jobToCopyFrom)
          .subscribe({
            next: (jobToCopy) => {
              if (jobToCopy) {
                this.jobToCopy = jobToCopy;
                this.checkAnyVOsForCopiedJob();
              } else {
                this.notiService.showInfo('Job not found');
                this.loading = false;
              }
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    }
  }

  refreshJobNumber(jobNumber: string) {
    this.jobToCopyFrom = jobNumber;
    this.getJobToCopy();
  }

  getHouseType() {
    if (this._jobService.currentJob.houseTypeId) {
      this.subscriptions = this.subscriptions.concat(
        this._configurationService.getHouseTypeById(this._jobService.currentJob.houseTypeId)
          .subscribe({
            next: (houseType) => {
              if (houseType.jobNumber && houseType.jobNumber !== '') {
                this.jobToCopyFrom = houseType.jobNumber;
                this.getJobToCopy();
              } else {
                this.getSalesTemplates();
              }
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    } else {
      this.getSalesTemplates();
    }
  }

  getSalesTemplates() {
    // if no sales date we can check if there is only one sales template and use that as default
    if (!this._jobService.currentJob.salesDate) {
      this.subscriptions = this.subscriptions.concat(
        this._jobService.getTemplateJobs()
          .subscribe({
            next: (templates) => {
              const salesTemplates = templates.filter(t => t.jobTypeId === JobTypeEnum.SalesTemplate && t.isActive);
              if (salesTemplates && salesTemplates.length === 1) {
                this.jobToCopyFrom = salesTemplates[0].jobNumber;
                this.getJobToCopy();
              } else {
                this.loading = false;
              }
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    } else {
      this.loading = false;
    }
  }

  cancel() {
    this._activeModal.dismiss();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
