import { TaskService } from './../services/felixApi/task.service';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalesTasksComponent } from './sales-tasks.component';
import { CreateSalesTaskComponent } from './create-sales-task/create-sales-task.component';
import { DxTextAreaModule } from 'devextreme-angular';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DxTextAreaModule
  ],
  providers: [
    TaskService
  ],
  declarations: [
    SalesTasksComponent,
    CreateSalesTaskComponent]
})
export class SalesTasksModule { }
