import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { GlobalService } from '../global.service';
import { BoardItem } from '../../dtos/board-item';
import { BoardItemRow } from '../../dtos/board-item-row';
import { HttpService } from '../http.service';

@Injectable()
export class BoardItemService {

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  getBoardItemRows(): Observable<BoardItemRow[]> {
    return this._http.get<BoardItemRow[]>(this.globalService.getApiUrl() + '/board-items',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getBoardItem(boardId: number, optionListId: number): Observable<BoardItem> {
    return this._http.get<BoardItem>(this.globalService.getApiUrl() + '/boards/' + boardId + '/items?optionListId=' + optionListId,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addBoardItem(boardId: number, itm: any): Observable<BoardItem> {
    const url = this.globalService.getApiUrl() + '/boards/' + boardId + '/items';
    return this._http.post<BoardItem>(url, JSON.stringify(itm), this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  updateBoardItem(boardId: number, itm: any) {
    const url = this.globalService.getApiUrl() + '/board-items/' + boardId;
    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteBoardItem(id: number) {
    const url = this.globalService.getApiUrl() + '/board-items/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }

}
