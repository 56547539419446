<div class="modal-header">
    <h2 class="modal-title">{{jobitem.itemDescription}}</h2>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body" [style.height.px]="gridHeight" class="scroll">

    <!-- Loading Spinner -->
    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <ng-container *ngFor="let optionByHeader of optionsByHeaders">
        <js-option-header *ngIf="optionByHeader.header" class="full-width" [option]="optionByHeader.header"></js-option-header>

        <mat-grid-list *ngIf="!loading" [cols]="numCollumns" rowHeight="6:2" gutterSize="5px">
            <mat-grid-tile *ngFor='let opt of optionByHeader.subOptions' [colspan]="opt.colSpan"
                [rowspan]="opt.rowSpan" (click)="selectOption(opt)">

                <mat-grid-tile-header *ngIf="!opt.notSelectable">
                    <h3>{{opt.description}}</h3>
                    <js-image-select-price [jobitem]=jobitem [option]=opt [isQtyRequired]=isQtyRequired
                        [previousSelectedOption]=previousSelectedOption [prevQty]=prevQty>
                    </js-image-select-price>
                </mat-grid-tile-header>

                <js-show-image *ngIf="opt.attachmentId" [imageId]="opt.id" [attachmentImageId]="opt.attachmentId"
                    [maxWidth]="tileWidth" [maxHeight]="tileHeight" [fullHeight]="true" (emitImageClick)="selectOption(opt)"></js-show-image>

                <js-show-image *ngIf="opt.optionImageId && !opt.attachmentId" [imageId]="opt.optionImageId"
                    [imageLibrary]="true" [maxWidth]="tileWidth" [maxHeight]="tileHeight" [fullHeight]="true"
                    (emitImageClick)="selectOption(opt)"></js-show-image>

                <h5 *ngIf="!opt.notSelectable && !opt.optionImageId && !opt.attachmentId"
                    (emitImageClick)="selectOption(opt)">No Image Available
                </h5>

                <mat-grid-tile-footer *ngIf="opt.id === selectedOption?.id">
                    <i class="material-icons">done</i>
                </mat-grid-tile-footer>

            </mat-grid-tile>

        </mat-grid-list>
    </ng-container>
    <mat-grid-list *ngIf='jobitem.canEnterManualSelection' cols="1" rowHeight="100px" class="customSelect">
        <mat-grid-tile [colspan]="1" [rowspan]="2"
                    (click)="manualSelect()">
                    <div class="mx-4 text-center">
                        <b>Select here to enter a custom value</b>.<br>You will be able to enter your own text and image once you have selected 'Ok'.
                    </div>

                    <mat-grid-tile-footer *ngIf="manualSelection">
                        <i class="material-icons">done</i>
                    </mat-grid-tile-footer>
        </mat-grid-tile>
    </mat-grid-list>
</div>

<div class="modal-footer">
    <button class="btn btn-primary" (click)="closeOk()" [disabled]="loading || (!selectedOption &&
    !manualSelection)">Ok</button>
    <button class="btn btn-secondary" (click)="cancel()" [disabled]="loading">Cancel</button>
</div>
