<div class="modal-header">
  <h4 class="modal-title">Attachments</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismissModal()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="card">

  <div class="card-body">

    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <table *ngIf="!loading" class="table table-sm">
      <!-- <thead class="rowclass">
        <tr class="headingclass">
          <th scope="col">Title</th>
          <th scope="col"></th>
        </tr>
      </thead> -->

      <div *ngIf='!attachments || !attachments.length'>No attachments</div>

      <tbody *ngIf='attachments && attachments.length' class="rowclass">
        <tr *ngFor='let attachment of attachments' class="rowclass">

          <ng-container>
            <td (click)='openAttachment(attachment.attachment, attachment.attachmentName, attachment.attachmentTypeId)' class="col1class">
              <button class="btn btn-sm btn-outline-primary border-0 text-start backgroundTransparent pt-0">
                {{ attachment.attachmentName }}
              </button>
            </td>
            <td>
              <button class='btn btn-sm btn-outline-danger' (click)='deleteAttachment(attachment.attachmentName)'>
                Delete
              </button>
            </td>

          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="modal-footer">
      <button class="btn btn-primary" (click)="uploadFile()" [disabled]="loading">Upload New Attachment</button>
    <button class="btn btn-primary" (click)="dismissModal()" [disabled]="loading">Close</button>
  </div>
</div>

<dx-popup class="popup" [width]="400" [height]="350" [showTitle]="true" title="Upload file" [dragEnabled]="true"
  [hideOnOutsideClick]="false" [(visible)]="popupVisible">
  <div *dxTemplate="let data of 'content'">
    <div id="fileuploader-container">
      <dx-file-uploader selectButtonText="Select file" labelText="or drop file here" [multiple]="false" accept="*"
        [(value)]="value" uploadMode="useForm" [allowedFileExtensions]="['.pdf', '.PDF', '.jpg','.JPG','.png','.PNG']">
      </dx-file-uploader>
    </div>
    <dx-button id="button" text="Load" type="success" (onClick)="uploadClick()">
    </dx-button>
  </div>
</dx-popup>

<dx-popup class="popup" [width]="400" [height]="200" [showTitle]="true" title="Delete file" [dragEnabled]="true"
  [hideOnOutsideClick]="false" [(visible)]="deletePopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-button id="button" text="Confirm Delete" type="danger" (onClick)="deleteClick()">
    </dx-button>
  </div>
</dx-popup>
