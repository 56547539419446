import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'js-create-task-modal',
  templateUrl: './create-task-modal.component.html',
  styleUrls: ['./create-task-modal.component.scss']
})
export class CreateTaskModalComponent implements OnInit {

  answered = false;

  constructor(private _activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  closeYes() {
    this._activeModal.close();
  }

  close() {
    if (this.answered) {
      this._activeModal.dismiss();
    }
    this.answered = true;
  }
}
