<div class="modal-header">
  <h2 class="modal-title">Change the job number</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <label for="jobNumberId" class="col-form-label"></label>
  <input id="jobNumberId" class="form-control form-control-sm" type="text" placeholder="Enter New Job Number"
    [(ngModel)]="newJobNumber" />

  <div> Beware of changing the job number as previous reports will have the old number. </div>
  <div> Only letters, numbers, - and _ allowed. </div>

  <!-- Image Loading Spinner -->
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

</div>

<div *ngIf="!loading" class="modal-footer">
  <button type="button" class="btn btn-sm btn-primary" (click)="update()"
    [disabled]="newJobNumber === ''">Update</button>
</div>
