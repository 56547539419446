import { JobService } from './../../services/felixApi/job.service';
import { AttachmentTypeEnum } from './../../dtos/attachment-type.enum';
import { NotificationService } from './../../services/notification.service';
import { JobDocumentAttachmentService } from './../../services/felixApi/job-document-attachment.service';
import { JobDocumentService } from './../../services/felixApi/job-document.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import CustomStore from 'devextreme/data/custom_store';
import * as saveAs from 'file-saver';
import { IJob } from '../../dtos/job';

@Component({
  selector: 'js-job-documents-changed',
  templateUrl: './job-documents-changed.component.html',
  styleUrls: ['./job-documents-changed.component.scss']
})
export class JobDocumentsChangedComponent implements OnInit {

  subscriptions: Subscription[] = [];
  gridHeight: number;
  dataSource: CustomStore;
  dateFrom: Date;
  showPDFs = false;
  jobs: IJob[];
  loading: boolean;

  constructor(private _activeModal: NgbActiveModal,
    private jobDocumentService: JobDocumentService,
    private jobDocumentAttachmentService: JobDocumentAttachmentService,
    private jobService: JobService,
    private notiService: NotificationService,
    private globalService: GlobalService) {
    this.downloadImage = this.downloadImage.bind(this);
  }

  ngOnInit() {
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerWidthChanged.subscribe(() => {
        setTimeout(() => {
          this.setHeight();
        }, 500); // wait for iPhone
      })
    );
    this.setHeight();
    this.getData();
  }

  setHeight() {
    this.gridHeight = this.globalService.innerHeight - 400;
  }

  getData() {
    this.subscriptions = this.subscriptions.concat(
      this.jobService.getJobsByAddress('')
        .subscribe({
          next: (jobs) => {
            this.jobs = jobs;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxDateBox',
        options: {
          value: this.dateFrom,
          onValueChanged: this.dateFromChanged.bind(this)
        }
      },
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show PDFs',
          value: this.showPDFs,
          rtlEnabled: true,
          onValueChanged: this.showPDFsChanged.bind(this)
        }
      },);
  }

  showPDFsChanged(e) {
    this.showPDFs = e.value;
    if (this.dateFrom) {
      this.setUpDataSource();
    }
  }

  dateFromChanged(e) {
    this.dateFrom = e.value;
    this.setUpDataSource();
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'jobDocumentId',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.jobDocumentService.getJobDocumentsModified(this.dateFrom.toDateString(), this.showPDFs).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  close() {
    this._activeModal.close(null);
  }

  downloadImage(e) {
    if (!e.row.data.sharePointFileId || e.row.data.sharePointFileId === '') {
      this.subscriptions = this.subscriptions.concat(
        this.jobDocumentAttachmentService.getJobDocAttachment(e.row.data.jobDocumentId, false).subscribe({
          next: (res) => {
            const job = this.jobs.find(i => i.id === e.row.data.jobId);
            this.convertAndSave(job.jobNumber + ' ' + res.attachmentName, res.attachment, res.attachmentTypeId);
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    } else {
      const job = this.jobs.find(i => i.id === e.row.data.jobId);
      this.subscriptions = this.subscriptions.concat(
        this.jobDocumentAttachmentService.getSharePointDocument(job.jobNumber, e.row.data.sharePointFileId, false).subscribe({
          next: (res) => {
            this.convertAndSave(job.jobNumber + ' ' + res.name, res.attachment, res.attachmentTypeId);
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  convertAndSave(fileName: string, base64: any, attachmentTypeId: number) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    if (attachmentTypeId === AttachmentTypeEnum.JPEG
      || attachmentTypeId === AttachmentTypeEnum.JPG
      || attachmentTypeId === AttachmentTypeEnum.PNG) {
      const blob = new Blob([bytes], { type: 'application/image' });
      saveAs(blob, fileName);
    } else {
      const blob = new Blob([bytes], { type: 'application/pdf' });
      saveAs(blob, fileName);
    }
  }
}
