import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { JobsModule } from '../jobs/jobs.module';
import { SharedModule } from '../shared/shared.module';
import { ClientWelcomeComponent } from './client-welcome/client-welcome.component';
import { ProgressTrackerComponent } from './progress-tracker/progress-tracker.component';
import { JobProgressComponent } from './job-progress/job-progress.component';

@NgModule({
  imports: [
    CommonModule,
    JobsModule,
    SharedModule,
  ],
  declarations: [
    DashboardComponent,
    ClientWelcomeComponent,
    ProgressTrackerComponent,
    JobProgressComponent
  ]
})
export class DashboardModule { }
