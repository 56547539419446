import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { JobSearchTypeEnum } from '../../../dtos/job-search-type.enum';
import { JobService } from '../../../services/felixApi/job.service';
import { VariationService } from '../../../services/felixApi/variation.service';
import { Variation } from '../../../dtos/variation';
import { NotificationService } from '../../../services/notification.service';
import { JobItemService } from '../../../services/felixApi/job-item.service';
import { IJobItem } from '../../../dtos/job-item';
import { IJobItemWithChildren } from '../../../dtos/job-item-with-children';
import { ItemTypeEnum } from '../../../dtos/item-type.enum';
import { TreeComponent } from '@circlon/angular-tree-component';
import { SelectedJobItems } from '../../../dtos/selectedJobItemIds';
import { JobVarItemService } from '../../../services/felixApi/job-var-item.service';
import { ChangeTypeEnum } from '../../../dtos/change-type.enum';

@Component({
  selector: 'js-variation-copy-modal',
  templateUrl: './variation-copy-modal.component.html',
  styleUrls: ['./variation-copy-modal.component.scss']
})
export class VariationCopyModalComponent implements OnInit, OnDestroy {

  @ViewChild('tree3') tree: TreeComponent;

  loading = false;
  jobNumber = ''; // to copy from
  subscriptions: Subscription[] = [];
  jobSearchTypeEnum = JobSearchTypeEnum;
  anyVariations: boolean;
  selectedVariationId: number;
  variations: Variation[];
  errorMessage: string;
  variationString = '';
  jobVariationItems: IJobItem[];
  treeOptions = {};
  oneNode: IJobItemWithChildren = new IJobItemWithChildren;
  treeNodes: IJobItemWithChildren[] = [];
  itemTypeEnum = ItemTypeEnum;
  id: number;
  foundItemSet: boolean;
  itemsToSet: any[] = [];
  treeJobItemNodes: IJobItem[];
  copyTreeOptions: {};
  currentJobNum: string;
  selectAll = false;
  itemsToCopyDto: SelectedJobItems;
  itemsToCopy: any[] = [];
  changeTypeEnum = ChangeTypeEnum;
  popupVisible: boolean;
  untickChildItems: boolean;
  nodeStore: any;

  constructor(private _activeModal: NgbActiveModal,
    private _jobService: JobService,
    private _jobItemService: JobItemService,
    private _jobVarItemService: JobVarItemService,
    private _variationService: VariationService,
    private notiService: NotificationService) { }

  ngOnInit() {
    // this.jobString = '';
    this.currentJobNum = this._jobService.currentJobNum;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  cancelWithNo() {
    this._activeModal.dismiss();
  }

  getVariations() {
    this.anyVariations = false;
    this.selectedVariationId = null;

    this.subscriptions = this.subscriptions.concat(
      this._variationService.getVariations(this.jobNumber)
        .subscribe({
          next: (variations) => {
            this.variations = variations;
            if (!this._variationService.currentVariations.length) {
              // No variations found for given job
            } else {
              // find the current one
              this.variations.forEach(element => {
                if (this.jobNumber !== this.currentJobNum
                  || element.id !== this._jobItemService.currentVariation.id) {
                  this.anyVariations = true;
                }
              });
            }
            this.loading = false;
          },
          error: (err) => {
            if (err.status === undefined) {
              this.errorMessage = 'No variations Exist for this job';
            } else {
              this.notiService.notify(err);
            }
            this.loading = false;
          }
        })
    );
  }

  setVariationNum(varn: Variation) {
    this.selectedVariationId = varn.id;
    this.variationString = varn.displayedVariationNumber + ': ' + varn.title;

    // read this variation
    this.loading = true;
    this.copyTreeOptions = {
      idField: 'id',
      displayField: 'description',
      childrenField: 'children',
      useVirtualScroll: true,
      nodeHeight: 22,
      scrollOnActivate: false
    };

    this.subscriptions = this.subscriptions.concat(
      this._jobItemService.getAllJobItems(this.jobNumber, false, true,
        false, true, varn.id)
        .subscribe({
          next: (treeJobItemNodes) => {
            this.treeJobItemNodes = treeJobItemNodes;
            this.treeNodes = [];
            this.treeJobItemNodes.forEach(item => {
              if (!item.jobItemAboveId && (!item.isHistoryRecord || item.changeTypeId === this.changeTypeEnum.Delete)
                && !item.isDeleted && !item.isSetUpLine) {

                if (item.itemTypeId === this.itemTypeEnum.Heading) {
                  this.oneNode.children = this._jobItemService.setupJobItemNodesChildren(item.originalItemTypeId,
                    item.id, this.treeJobItemNodes, varn.id);
                } else {
                  this.oneNode.children = [];
                }

                if ((item.itemTypeId === this.itemTypeEnum.Heading && this.oneNode.children && this.oneNode.children.length)
                  || (item.itemTypeId !== this.itemTypeEnum.Heading && item.jobVariationId === varn.id)) {
                  if (!item.itemDescription || item.itemDescription === '') {
                    this.oneNode.description = item.selection;
                  } else if (item.selection) {
                    this.oneNode.description = item.itemDescription + ' - ' + item.selection;
                  } else {
                    this.oneNode.description = item.itemDescription;
                  }

                  // if the item has not been changed use the original id
                  if (item.changedByJobVarId && item.changedByJobVarId !== 0) {
                    this.id = item.changedByJobVarId;
                  } else {
                    this.id = item.id;
                  }

                  this.treeNodes.push({
                    id: this.id,
                    itemTypeId: item.itemTypeId,
                    changeTypeId: item.changeTypeId,
                    jobVarTypeId: item.jobVarTypeId,
                    itemChangedId: item.itemChangedId,
                    description: this.oneNode.description,
                    originalItemTypeId: item.originalItemTypeId,
                    originalItemId: item.id,
                    connectedItemId: item.connectedItemId,
                    children: this.oneNode.children,
                    parentId: null,
                    changedByVOId: item.changedByVOId,
                    changedByJobVarId: item.changedByJobVarId,
                    masterItemId: item.masterItemId,
                    hasLinkedItems: item.hasLinkedItems,
                    optionNumber: item.optionNumber,
                    checked: false,
                    indeterminate: false,
                    isChecked: item.isChecked,
                    variationItemNumber: item.variationItemNumber
                  });
                }
              }
            });
            this.loading = false;
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
    );
  }

  // when the job item tree is initialised set the focused node to the one already selected.
  onItemTreeInit(tree: TreeComponent) {
    tree.treeModel.virtualScroll.setViewport(tree.treeModel.virtualScroll.viewport);
    this.tree.treeModel.expandAll();
    this.loading = false;
  }

  // set the linked job item when clicked on from tree
  copyTreeNodeClicked(node) {
    this.errorMessage = ''; // clear possible error message
    node.data.checked = !node.data.checked;
    this.nodeStore = node;

    if (!node.data.checked && node.children && node.children.length) {
      this.popupVisible = true;
      this.untickChildItems = true;
    } else {
      this.copyTreeNodeChecked();
    }
  }

  copyTreeNodeChecked() {
    this.popupVisible = false
    if (this.nodeStore.children) {
      this.nodeStore.children.forEach((child) => this.checkAllChildren(child, this.nodeStore.data.checked));
    }

    if (this.nodeStore.data.masterItemId !== null || this.nodeStore.data.hasLinkedItems) {
      // if we have linked items we keep together so tick/untick them automatically
      this.tree.treeModel.roots.forEach(item => {
        if (this.nodeStore.data.parentId === item.data.parentId) {
          // must be siblings
          if (this.nodeStore.data.masterItemId === item.data.id
            || this.nodeStore.data.id === item.data.masterItemId
            || (this.nodeStore.data.masterItemId && this.nodeStore.data.masterItemId === item.data.masterItemId)) {
            item.data.checked = this.nodeStore.data.checked;
          }
        } else if (item.data.itemTypeId === this.itemTypeEnum.Heading) {
          this.checkChildrenToCheck(item, this.nodeStore);
        }
      });
    }
    this.tree.treeModel.update();
  }

  checkChildrenToCheck(parent, node) {
    parent.children.forEach(item => {
      if (node.data.parentId === item.data.parentId) {
        // must be siblings
        if (node.data.masterItemId === item.data.id
          || node.data.id === item.data.masterItemId
          || (node.data.masterItemId && node.data.masterItemId === item.data.masterItemId)) {
          item.data.checked = node.data.checked;
        }
      } else if (item.data.itemTypeId === this.itemTypeEnum.Heading) {
        this.checkChildrenToCheck(item, node);
      }
    });
  }

  checkAllChildren(node, checkValue) {
    node.data.checked = checkValue;
    if (node.children) {
      node.children.forEach((child) => this.checkAllChildren(child, checkValue));
    }
  }

  selectAllNodesForCopy() {
    this.selectAll = !this.selectAll;
    this.tree.treeModel.roots.forEach((child) => this.checkAllChildren(child, this.selectAll));
    this.tree.treeModel.update();
  }

  runCopy() {
    this.loading = true;
    this.getItemsToCopy();
    this.itemsToCopyDto = { selectedJobItemIds: this.itemsToCopy };

    this.subscriptions = this.subscriptions.concat(
      this._jobVarItemService.copyJobVarItems(this.jobNumber, this.selectedVariationId,
        this._jobItemService.currentVariation.id, this.itemsToCopyDto).subscribe({
          next: () => {
            this._activeModal.close();
          },
          error: (err) => {
            this.notiService.notify(err);
            if (err.status === 422) {
              if (err.error && err.error.message) {
                this.errorMessage = err.error.message;
              }
            }
            this.loading = false;
          }
        })
    );
  }

  getItemsToCopy() {
    // get the items in the right order
    this.itemsToCopy = [];
    for (let i = 0; i < this.tree.treeModel.nodes.length; i++) {
      const node = this.tree.treeModel.getNodeById(this.tree.treeModel.nodes[i].id);
      if (node.data.checked === true && node.data.changedByVOId === this.selectedVariationId) {
        this.itemsToCopy = this.itemsToCopy.concat(+this.tree.treeModel.nodes[i].id);
      }
      if (this.tree.treeModel.nodes[i].children) {
        this.tree.treeModel.nodes[i].children.forEach((child) => this.checkAddItemsToCopy(child));
      }
    }
  }

  checkAddItemsToCopy(node) {
    if (this.tree.treeModel.getNodeById(node.id).data.checked === true && node.changedByVOId === this.selectedVariationId) {
      this.itemsToCopy = this.itemsToCopy.concat(+node.id);
    }
    if (node.children) {
      node.children.forEach((child) => this.checkAddItemsToCopy(child));
    }
  }

  refreshJobNumber(jobNumber: string) {
    this.jobNumber = jobNumber;
    this.variations = [];

    if (this.jobNumber && this.jobNumber !== '') {
      this.getVariations();
    }
  }
}
