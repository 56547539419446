import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { Invoice } from '../../dtos/invoice';
import { PDFReports } from '../../dtos/pdf-report';
import { IJobItemAttachment } from '../../dtos/job-item-attachment';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService
  ) { }

  getInvoices(): Observable<Invoice[]> {
    this.globalService.setIsGetChildren(true);
    return this._http.get<Invoice[]>(this.globalService.getApiUrl() +
      '/invoices', this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addInvoice(dataRecord: any): Observable<Invoice> {
    const url = this.globalService.getApiUrl() + '/invoices';
    return this._http.post<Invoice>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  postInvoiceAttachment(id: number, imageFile: any) {
    const options = this.httpService.getHttpFileOptions();
    return this._http.post(this.globalService.getApiUrl() + '/invoices/' + id + '/attachments/upload', imageFile, options);
  }

  getInvoiceAttachment(id): Observable<IJobItemAttachment> {
    return this._http.get<IJobItemAttachment>(this.globalService.getApiUrl() + '/invoice-attachments/' + id,
      this.httpService.getHttpFileOptions()).pipe(
        catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
