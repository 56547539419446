import { VariationService } from './variation.service';
import { JobService } from './job.service';
import { throwError as observableThrowError, Observable, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { ClaimJobLine } from '../../dtos/claim-job-line';
import { ClaimMaster } from '../../dtos/claim-master';
import { PDFReports } from '../../dtos/pdf-report';
import { HttpService } from '../http.service';
import { IncomeInvoice } from '../../dtos/income-invoice';
import { Bank } from '../../dtos/bank';
import { JobBank } from '../../dtos/job-bank';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {
  claimJobLines: ClaimJobLine[];
  claimInvoices: IncomeInvoice[];
  banks: Bank[];
  banksCompany: string;

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  getClaimMasters(): Observable<ClaimMaster[]> {
    const url = this.globalService.getApiUrl() + '/claim-masters/';
    return this._http.get<ClaimMaster[]>(url, this.httpService.getHttpOptions()).pipe(catchError(this.handleError));
  }

  getClaimsData(jobId: number): Observable<ClaimJobLine[]> {
    return forkJoin(
      [
        this.getJobClaims(jobId),
        this.getClaimInvoices(jobId),
        this.getBanks()
      ]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getJobClaims(jobId: number): Observable<ClaimJobLine[]> {
    const url = this.globalService.getApiUrl() + '/jobs/' + jobId + '/claim-lines';
    return this._http.get<ClaimJobLine[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.claimJobLines = res;
      }),
      catchError(this.handleError));
  }

  getClaimInvoices(jobId: number): Observable<IncomeInvoice[]> {
    const url = this.globalService.getApiUrl() + '/income-invoices?jobId=' + jobId;

    return this._http.get<IncomeInvoice[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.claimInvoices = res;
      }),
      catchError(this.handleError));
  }

  postJobClaim(jobId: number, updateRecord: any) {
    const url = this.globalService.getApiUrl() + '/jobs/' + jobId + '/claim-lines';
    return this._http.post(url, JSON.stringify(updateRecord), this.httpService.getHttpOptions());
  }

  updateJobClaim(jobId: number, claimJobLineId: string, updateRecord: any) {
    const url = this.globalService.getApiUrl() + '/jobs/' + jobId + '/claim-lines/' + claimJobLineId;
    return this._http.patch(url, JSON.stringify(updateRecord), this.httpService.getHttpOptions());
  }

  moveJobClaim(jobId: number, id: number, orderNumber: number) {
    const url = this.globalService.getApiUrl() + '/claim-job-lines/move/' + jobId
      + '?claimJobLineId=' + id + '&orderNumber=' + orderNumber;
    return this._http.patch(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }

  deleteJobClaim(jobId: number, claimJobLineId: string) {
    const url = this.globalService.getApiUrl() + '/jobs/' + jobId + '/claim-lines/' + claimJobLineId;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  generateJobClaimItem(jobId: number, claimMasterId: number) {
    const url = this.globalService.getApiUrl() + '/jobs/' + jobId + '/create-claim-lines';
    return this._http.post(url, JSON.stringify({ claimMasterId: claimMasterId }), this.httpService.getHttpOptions());
  }

  getClaimsReport(): Observable<PDFReports> {
    const url = this.globalService.getApiUrl() + '/reports/jobs/' + this.globalService.getCurrentJob() + '/claims';

    return this._http.get<PDFReports>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getBanks() {
    if (this.banks && this.banks.length && this.banksCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.banks);
    } else {
      return this._http.get<Bank[]>(this.globalService.getApiUrl() + '/banks/', this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.banks = res;
          this.banksCompany = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getJobBankDetails(jobId: number) {
    return this._http.get<JobBank>(this.globalService.getApiUrl() + '/job/' + jobId + '/bank', this.httpService.getHttpOptions());
  }

  postJobBank(updateRecord: any) {
    const url = this.globalService.getApiUrl() + '/job-banks';
    return this._http.post<JobBank>(url, JSON.stringify(updateRecord), this.httpService.getHttpOptions());
  }

  patchJobBank(id: number, updateRecord: any) {
    const url = this.globalService.getApiUrl() + '/job-banks/' + id;
    return this._http.patch(url, JSON.stringify(updateRecord), this.httpService.getHttpOptions());
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
