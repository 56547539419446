<div class="modal-header">
  <h4 class="modal-title" placement="bottom">Loan Shortfall</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div>
    <span class="jobLabel">Job:</span>
    <dx-text-box id="jobNumberField" [width]="150" [(value)]="jobNumber" (keyup)="changeJobNum()" class="inline"
      (keyup.enter)="getJob()"></dx-text-box>
    <button type="button" class="btn btn-sm btn-primary ms-0" (click)="getJob()" [disabled]="!jobNumber">Select</button>
    <button type="button" class="btn btn-sm btn-secondary ms-1 me-2" (click)="jobSearch()">List/Search</button>
    <span>{{job?.contractName}}</span>
  </div>

  <dx-data-grid *ngIf="jobId" [dataSource]="dataSource" [height]="gridHeight" [remoteOperations]="false"
    [showRowLines]="false" [showColumnLines]="true" [showBorders]="true" [columnAutoWidth]="false"
    [allowColumnResizing]="true" [wordWrapEnabled]="true">

    <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-paging [enabled]="false"> </dxo-paging>

    <dxi-column dataField="invoiceType" width="100"> </dxi-column>
    <dxi-column dataField="claimNumber" caption="Number/Split" [width]="100" alignment="center"> </dxi-column>
    <dxi-column dataField="description"> </dxi-column>
    <dxi-column dataField="claimSplitType" caption="Type" [width]="150"> </dxi-column>
    <dxi-column dataField="companyActivity" caption="Activity" [width]="180"> </dxi-column>
    <dxi-column dataField="amountToClient" caption="By Client" dataType="number" [width]="100" format="#,###">
    </dxi-column>
    <dxi-column dataField="amountToLoan" caption="From Loan" dataType="number" [width]="100" format="#,###">
    </dxi-column>
    <dxi-column dataField="lineTotal" caption="Total" dataType="number" [width]="100" format="#,##0"
      [calculateCellValue]="calcLineTotal">
    </dxi-column>

    <dxo-summary>
      <dxi-total-item column="amountToLoan" summaryType="sum" valueFormat="#,##0" displayFormat="{0}"
        showInColumn="amountToLoan">
      </dxi-total-item>
      <dxi-total-item column="amountToClient" summaryType="sum" valueFormat="#,##0" displayFormat="{0}"
        showInColumn="amountToClient">
      </dxi-total-item>
      <dxi-total-item column="lineTotal" summaryType="sum" valueFormat="#,##0" displayFormat="{0}"
        showInColumn="lineTotal">
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>

  <div *ngIf="!loading && jobBank" class="totalLine">
    <span>Loan Amount:</span>
    <div class="totalClass">
      <span>{{ jobBank.loanAmount | number }}</span>
    </div>
  </div>

  <div *ngIf="!loading && jobBank" class="totalLine">
    <span>Shortfall:</span>
    <div class="totalClass">
      <span [class.red]="loanShortfallAmount">{{ loanShortfallAmount | number }}</span>
    </div>
  </div>
</div>
