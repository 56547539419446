import { UserService } from './../../services/felixApi/user.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobCustomerService } from '../../services/felixApi/job-customer.service';
import { UserTypeEnum } from '../../dtos/user-type.enum';
import { User } from '../../dtos/user';
import { IJobCustomer } from '../../dtos/job-customer';
import { IJob } from '../../dtos/job';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'js-job-customer-modal',
  templateUrl: './job-customer-modal.component.html',
  styleUrls: ['./job-customer-modal.component.scss']
})
export class JobCustomerModalComponent implements OnInit, OnDestroy {
  @Input() jobNumber: string;
  @Input() users: User[];
  @Input() job: IJob;

  COMPONENT_NAME = 'JobCustomerModalComponent';
  subscriptions: Subscription[] = [];

  jobCustomers: IJobCustomer[] = [];
  associates: User[] = [];
  shownUsers: User[] = [];
  currentPage = 0;
  pageSize = 15;
  numberOfPages = 0;
  loading = true;
  userTypeEnum = UserTypeEnum;
  filterText = '';
  updatingNumber = 0; // number of records waiting update
  emailString: string;

  constructor(private _jobCustomerService: JobCustomerService,
    private auth: AuthService,
    private _activeModal: NgbActiveModal,
    private _userService: UserService,
    private notiService: NotificationService) { }

  ngOnInit() {
    this.getAllJobCustomers();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getAllJobCustomers() {
    // read all JobCustomers records for this job
    this.subscriptions = this.subscriptions.concat(
      this._jobCustomerService.getJobCustomers(this.jobNumber)
        .subscribe({
          next: (jobCustomers) => {
            this.jobCustomers = jobCustomers;
            this.setUserCheckedField();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  setUserCheckedField() {
    // we populate the checked flag for all users already selected for this job
    this.associates = [];
    let filterText = null;
    if (this.filterText) {
      filterText = this.filterText.toLowerCase();
    }
    this._userService.users.forEach(user => {
      if (user.userTypeId === this.userTypeEnum.Associate) {
        if (!filterText || filterText === ''
          || (user.firstName && user.firstName.toLowerCase().search(filterText) >= 0)
          || (user.middleName && user.middleName.toLowerCase().search(filterText) >= 0)
          || (user.lastName && user.lastName.toLowerCase().search(filterText) >= 0)) {
          user.checked = false;
          user.jobCustomerId = null;
          this.jobCustomers.forEach(jobCustomer => {
            if (jobCustomer.customer.id === user.id) {
              user.checked = true;
              user.jobCustomerId = jobCustomer.id;
            }
          });
          this.associates = this.associates.concat(user);
        }
      }
    });
    this.getNumberOfPages();
    this.getCurrentPages();
    this.createEmailString();
    this.loading = false;
  }

  getNumberOfPages() {
    this.currentPage = 0;
    this.numberOfPages = Math.floor(this.associates.length / this.pageSize);
    if ((this.associates.length / this.pageSize) === Math.floor(this.associates.length / this.pageSize)) {
      this.numberOfPages--;
    }
  }

  getCurrentPages() {
    this.shownUsers = [];
    for (let i = 0; i < this.pageSize; i++) {
      if (((this.currentPage * this.pageSize) + i) < this.associates.length) {
        this.shownUsers[i] = this.associates[(this.currentPage * this.pageSize) + i];
      }
    }
  }

  checkUser(user: User) {
    this.updatingNumber += 1;
    if (user.checked) {
      // delete the record
      this.subscriptions = this.subscriptions.concat(
        this._jobCustomerService.deleteJobCustomer(user.jobCustomerId).subscribe({
          next: () => {
            this.createEmailString();
            this.updatingNumber -= 1;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.updatingNumber -= 1;
          }
        })
      );
    } else {
      // add the record
      this.subscriptions = this.subscriptions.concat(
        this._jobCustomerService.postJobCustomer(this.jobNumber, user.email, false).subscribe({
          next: (res) => {
            const newRecord = res as IJobCustomer;
            // set the jobCustomerId in the array
            this.associates.forEach(associate => {
              if (associate.id === user.id) {
                associate.jobCustomerId = newRecord.id;
              }
            });

            this.createEmailString();
            this.updatingNumber -= 1;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.updatingNumber -= 1;
          }
        })
      );
    }
  }

  nextPage() {
    // move the next page into the shown array
    this.currentPage++;
    this.getCurrentPages();
  }

  previousPage() {
    // move to previous page
    this.currentPage--;
    this.getCurrentPages();
  }

  cancel() {
    this._activeModal.close();
  }

  createEmailString() {
    // send an email to the trades selected
    this.emailString = 'mailto:' + this.auth.getCurrentUser().email
      + '?subject=You have been allocated in Truth Engine to job ' + this.jobNumber
      + '&body=To log in copy this address into your browser: https://truthengine.azurewebsites.net%0D%0D';
    let tradesFound = false;

    this.associates.forEach(trade => {
      if (trade.checked) {
        if (!tradesFound) {
          this.emailString += '&bcc=';
          tradesFound = true;
        } else {
          this.emailString += ';';
        }

        this.emailString += trade.email;
      }
    });
  }
}
