<div>
  <dx-text-box id="jobNumberField" [width]="200" [(value)]="jobNumber" placeholder="Job Number..."
    (onEnterKey)="getJob()"></dx-text-box>
  <button type="button" class="btn btn-sm btn-primary" (click)="getJob()">Select</button>
  <button type="button" class="btn btn-sm btn-primary ms-1" (click)="jobSearch()">List/Search</button>
  <button type="button" class="btn btn-sm btn-secondary ms-1" (click)="clearJob()">Clear</button>
</div>

<div class="form-group row">
  <p>{{jobString}}</p>
</div>
