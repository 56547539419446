import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';
import { Estate } from '../../dtos/estate';
import { environment } from '../../../environments/environment';
import { ConstructiveSelection } from '../../dtos/constructive-selection';

@Injectable({
  providedIn: 'root'
})
export class ConstructiveService {

  apiUrl = environment.apiUrl + '/constructive-selections';

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  // importData(jobId: number) {
  //   return this.http.get(this.apiUrl + '/import/?jobId=' + jobId, this.httpService.getHttpOptions()).pipe(
  //     catchError(this.httpService.handleError));
  // }

  getSelections(jobId: number): Observable<ConstructiveSelection[]> {
    return this.http.get<ConstructiveSelection[]>(this.apiUrl + '/?jobId=' + jobId, this.httpService.getHttpOptions()).pipe(
      catchError(this.httpService.handleError));
  }

  refreshSelections(jobId: number) {
    return this.http.get(environment.apiUrl + '/integration/constructive/get-selections?jobId=' + jobId,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.httpService.handleError));
  }

  updateSelections(constructiveSelectionId: any, dataRecord: any): Observable<ConstructiveSelection> {
    return this.http.patch<ConstructiveSelection>(this.apiUrl + '/' + constructiveSelectionId, JSON.stringify(dataRecord),
      this.httpService.getHttpOptions()).pipe(
        catchError(this.httpService.handleError));
  }

  updateVariationWithSelections(jobNumber: string, jobVariationId: number) {
    const url = this.apiUrl + '/update-variation' + '?jobNumber=' + jobNumber + '&jobVariationId=' + jobVariationId;
    return this.http.patch<ConstructiveSelection>(url, JSON.stringify({}), this.httpService.getHttpOptions()).pipe(
      catchError(this.httpService.handleError));
  }
}
