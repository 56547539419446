import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobService } from '../../services/felixApi/job.service';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'js-change-number-modal',
  templateUrl: './change-number-modal.component.html',
  styleUrls: ['./change-number-modal.component.scss']
})
export class ChangeNumberModalComponent implements OnInit, OnDestroy {
  @Input() jobId: number;

  newJobNumber = '';
  loading = false;
  subscriptions: Subscription[] = [];

  constructor(
    private _activeModal: NgbActiveModal,
    private notiService: NotificationService,
    private _jobService: JobService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  cancel() {
    this._activeModal.dismiss();
  }

  update() {
    this.loading = true;
    this.subscriptions = this.subscriptions.concat(
      this._jobService.updateJobNumber(this.jobId, this.newJobNumber)
        .subscribe({
          next: () => {
            this._activeModal.close(this.newJobNumber);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }
}
