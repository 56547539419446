import { User } from './user';

export class Variation {
    id: number;
    jobId: number;
    variationNumber: number;
    title: string;
    extraDescription: string;
    variationDate: Date;
    dueDate: Date;
    adminFee: number;
    variationType: number;
    approvedDate: Date;
    approvedUserId: number;
    statusId: number;
    statusComment: string;
    statusModifiedDate: Date;
    statusModifiedUserId: number;
    approvedUser: User;
    statusModifiedUser: User;
    variationTotal: number;
    hasItems: boolean;
    includedItemsTotal: number;
    canBeModifiedByClient: boolean;
    author: string;
    additionalDays: number;
    companyActivityId: number;
    assignedUserId: number;
    displayedVariationNumber: string;
    estimatingCostingDate: Date;

    variationTypeDesc: string;

    constructor(varNumber: number, title: string, total: number) {
        this.id = 0;
        this.variationNumber = varNumber;
        this.title = title;
        this.variationTotal = total;
        this.displayedVariationNumber = '';
    }
}
