<!-- <js-loading-spinner *ngIf="loading"></js-loading-spinner> -->

<div class="card">
  <div class="card-body">
    <div *ngIf="logo" class='horizontal-auto-margin text-center mb-2 mt-2 max-w-500'>
      <img *ngIf="logoFileTypeId === attachmentTypeEnum.JPEG" [src]="'data:image/jpeg;base64,'+logo"
        class="logoClass" />

      <img *ngIf="logoFileTypeId === attachmentTypeEnum.PNG" [src]="'data:image/png;base64,'+logo" class="logoClass" />
    </div>

    <div class="grey-border job-number">
      <js-job-number [fromDashboard]="true" [calcWidth]="false" (refreshJobNumber)='refreshJobNumber()'></js-job-number>
    </div>

    <div *ngIf="clientSelectionVariation" class="client-selections-available">
      <div class="m-t-5">You have Selections waiting for you!</div>
      <button class="btn btn-success m-t-15" (click)="goToClientSelections()">Go To Selections</button>
      <div class="m-t-20 info">When you have finished, ensure you <b>Submit</b> your selections using the button at the
        bottom.</div>
    </div>

    <js-client-welcome></js-client-welcome>

    <div *ngIf="currentActivity && activities && activities.length" class="grey-border m-t-20">
      <js-progress-tracker [steps]="activities" [activeStepLabel]="currentActivity.description"></js-progress-tracker>
    </div>

    <div *ngIf="jobDocument" class="grey-border m-t-10 m-b-10">
      <h5 class='blue-heading'>Latest
        <span *ngIf='jobDocument.attachment'>Image / Document</span>
        <span *ngIf='!jobDocument.attachment'>Note</span>
        for the last week
      </h5>
      <div class='doc-item'>
        <button *ngIf="jobDocument.attachmentTypeId === attachmentTypeEnum.PDF"
          class="btn btn-sm btn-outline-primary pdf-button" (click)='showPDF()'>
          <i class="material-icons">attach_file</i>
        </button>
      </div>

      <div *ngIf="jobDocument.attachment" class='centerText'>
        <img *ngIf="jobDocument.attachmentTypeId === attachmentTypeEnum.JPEG"
          [src]="'data:image/jpeg;base64,'+jobDocument.attachment" class="imageClass" />

        <img *ngIf="jobDocument.attachmentTypeId === attachmentTypeEnum.PNG"
          [src]="'data:image/png;base64,'+jobDocument.attachment" class="imageClass" />
        
        <img *ngIf="jobDocument.attachmentTypeId === attachmentTypeEnum.HEIC"
          [src]="'data:image/jpeg;base64,'+jobDocument.attachment" class="imageClass" />
      </div>
    </div>
  </div>
</div>
