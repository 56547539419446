import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { exportDataGrid } from 'devextreme/excel_exporter';
const ExcelJS = require('exceljs');
import { saveAs } from 'file-saver';
import { GlobalService } from './global.service';
import { JobService } from './felixApi/job.service';

@Injectable({
  providedIn: 'root'
})
export class GridService {

  constructor(private utils: UtilsService,
    private globalService: GlobalService,
    private jobService: JobService) { }

  /* 'e' is the event receieved on dx grid (onToolbarPreparing) event */

  addRefreshBtnToToolbar(e, numItemsInToolbar = 3, refreshPosition = 2) {
    let toolbarItems = e.toolbarOptions.items;

    toolbarItems = toolbarItems.concat({
      location: 'after',
      template: 'refreshTemplate'
    });

    this.utils.moveArrayItemToNewIndex(toolbarItems, numItemsInToolbar - 1, refreshPosition - 1);
  }

  addItemTotoolbar(e, templateName: string, after = true) {
    let toolbarItems = e.toolbarOptions.items;

    toolbarItems = toolbarItems.concat({
      location: after ? 'after' : 'before',
      template: templateName
    });
  }

  addItemTotoolbarPosition(e, templateName: string, after: boolean, numItemsInToolbar = 3, position = 2) {
    let toolbarItems = e.toolbarOptions.items;

    toolbarItems = toolbarItems.concat({
      location: after ? 'after' : 'before',
      template: templateName
    });

    this.utils.moveArrayItemToNewIndex(toolbarItems, numItemsInToolbar - 1, position - 1);
  }

  onExporting(e, reportName: string = 'GridReport', jobNumber: string) {
    const jobAddressString = this.globalService.getJobString(this.jobService.currentJob, false);
    const contractName = this.jobService.currentJob.contractName;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(reportName,
      { pageSetup: { paperSize: 9, orientation: 'landscape' } });

    if (reportName === 'Statement') {
      worksheet.columns = [
        { width: 9 }, { width: 5 }, { width: 30 }, { width: 15, style: { numFmt: '$#,##0.00' } },
        { width: 15, style: { numFmt: '$#,##0.00' } }, { width: 13, style: { numFmt: 'd-mmm-yy' } },
        { width: 15, style: { numFmt: '$#,##0.00' } }, { width: 13, style: { numFmt: 'd-mmm-yy' } },
        { width: 15, style: { numFmt: '$#,##0.00' } }
      ];

      // worksheet.headerFooter.evenHeader = 'Job ' + jobNumber + ' - ' + reportName;
      worksheet.headerFooter.evenFooter = '&L' + new Date().toLocaleDateString() + '&CPage &P of &N' + '&R' + this.globalService.getCurrentCompany().companyName;
      // worksheet.headerFooter.oddHeader = 'Job ' + jobNumber + ' - ' + reportName;
      worksheet.headerFooter.oddFooter = '&L' + new Date().toLocaleDateString() + '&CPage &P of &N' + '&R' + this.globalService.getCurrentCompany().companyName;
    } else {
      worksheet.headerFooter.evenHeader = 'Job ' + jobNumber + ' - ' + reportName;
      worksheet.headerFooter.evenFooter = '&L' + new Date().toLocaleDateString() + '&CPage &P of &N' + '&R' + this.globalService.getCurrentCompany().companyName;
      worksheet.headerFooter.oddHeader = 'Job ' + jobNumber + ' - ' + reportName;
      worksheet.headerFooter.oddFooter = '&L' + new Date().toLocaleDateString() + '&CPage &P of &N' + '&R' + this.globalService.getCurrentCompany().companyName;
    }

    exportDataGrid({
      worksheet: worksheet,
      component: e.component,
      autoFilterEnabled: true,
      keepColumnWidths: false,
      customizeCell: function (options) {
        const { gridCell, excelCell } = options;
        if (reportName === 'Statement' && gridCell.rowType === 'data') {
          if (gridCell.column.dataField === 'invoiceDate' || gridCell.column.dataField === 'lastPaidDate') {
            excelCell.numFmt = 'd-mmm-yy';
          }
        }
      },
      topLeftCell: { row: 5, column: 1 },
    }).then(() => {
      // header
      worksheet.getCell('A1').value = 'Job:';
      worksheet.getCell('A1').font = { name: 'Calibri', size: 12, bold: true };
      worksheet.getCell('B1').value = jobNumber;
      worksheet.getCell('B1').font = { name: 'Calibri', size: 12, bold: true };
      worksheet.getCell('A2').value = 'Address:';
      worksheet.getCell('A2').font = { name: 'Calibri', size: 12, bold: true };
      worksheet.getCell('B2').value = jobAddressString;
      worksheet.getCell('B2').font = { name: 'Calibri', size: 12, bold: true };
      worksheet.getCell('A3').value = 'Client:';
      worksheet.getCell('A3').font = { name: 'Calibri', size: 12, bold: true };
      worksheet.getCell('B3').value = contractName;
      worksheet.getCell('B3').font = { name: 'Calibri', size: 12, bold: true };
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), reportName + ' for job ' + jobNumber + '.xlsx');
      });
    });
  }
}
