<div class="modal-header">
  <h4 class="modal-title" placement="bottom">Margin Fuel Gauge</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <!-- enter job number -->
  <div *ngIf="!loadingBaseData" class="jobClass">
    <js-job-number [fromJobMargin]="true" [calcWidth]="true" (refreshJobNumber)='refreshJobNumber($event)'>
    </js-job-number>
  </div>

  <div *ngIf="!loadingBaseData" class="districtClass">
    <div class='totalClass'>
      <span class='me-1'>Price Book:</span>
      <dx-select-box class="costingDateClass" [dataSource]="districts" displayExpr="description" valueExpr="id"
        [(value)]="districtId" (onSelectionChanged)="getData()">
      </dx-select-box>

      <span class='me-1 ms-3'>Current costs as at:</span>
      <dx-date-box class="costingDateClass" [(value)]="effectiveDate" type="date"
        (onValueChanged)="getData()"></dx-date-box>
    </div>
  </div>

  <js-loading-spinner *ngIf="(loading && jobNumber && jobNumber !== '') || loadingBaseData"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading && jobNumber && districtId && dataSource" class="mt-3" #treeList id="tree-list"
    [dataSource]="dataSource" [height]="gridHeight" [remoteOperations]="false" [showRowLines]="false"
    [showColumnLines]="false" [showBorders]="true" [columnAutoWidth]="false" [wordWrapEnabled]="true"
    (onCellPrepared)="onCellPrepared($event)"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate', 'toolbarTemplate2', 'toolbarTemplate3', 'toolbarTemplate4', 'toolbarTemplate5', 'toolbarTemplate6')">

    <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-paging [enabled]="false"> </dxo-paging>

    <dxo-editing mode="row" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false" [useIcons]="true"
      refreshMode="full"></dxo-editing>

    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
    </dxo-grouping>

    <dxi-column dataField="variationPhaseId" caption="Phase" dataType="number" [allowEditing]="false" [groupIndex]="0"
      sortOrder="asc">
      <dxo-lookup [dataSource]="phases" valueExpr="id" displayExpr="name">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="variationNumber" dataType="number" [visible]="false" sortOrder="asc">
    </dxi-column>

    <dxi-column dataField="isItemsNoCost" dataType="boolean" [visible]="false">
    </dxi-column>

    <dxi-column dataField="displayedVariationNumber" caption="Variation" [width]="120" [allowEditing]="false"
      alignment="center">
    </dxi-column>

    <dxi-column dataField="title" [allowEditing]="false"> </dxi-column>

    <dxi-column dataField="statusId" caption="Status" [allowEditing]="false" [width]="200" alignment="center">
      <dxo-lookup [dataSource]="statusTypes" valueExpr="id" displayExpr="name">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="variationTotal" caption="Price (Inc GST)" dataType="number" [allowEditing]="false"
      [width]="150" format="#,###"> </dxi-column>

    <dxi-column dataField="manualCost" caption="Cost (Ex GST)" dataType="number" [width]="150" format="#,###">
    </dxi-column>

    <dxi-column dataField="currentCost" caption="Current Cost" dataType="number" [width]="150" format="#,###">
    </dxi-column>

    <dxi-column type="buttons" [width]="50">
      <dxi-button name="edit" [onClick]="editItem">
      </dxi-button>
    </dxi-column>

    <!-- tool bar templates -->
    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <span class="me-2">Include:</span>
        <span class="me-2">Base Price</span>
        <dx-check-box [(value)]="showBasePrice" [width]="30" (onValueChanged)="showBasePriceChanged($event)"
          class="me-0">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate2'">
      <div>
        <span class="me-2">Sales</span>
        <dx-check-box [(value)]="showSalesVariations" [width]="30" (onValueChanged)="showSalesVariationsChanged($event)"
          class="me-0">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate3'">
      <div>
        <span class="me-2">Pre-Contract</span>
        <dx-check-box [(value)]="showPreContract" [width]="30" (onValueChanged)="showPreContractChanged($event)"
          class="me-0">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate4'">
      <div>
        <span class="me-2">Post-Contract</span>
        <dx-check-box [(value)]="showPostContract" [width]="30" (onValueChanged)="showPostContractChanged($event)">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate5'">
      <div>
        <span class="ms-2 me-2">Include Open Variations in Totals</span>
        <dx-check-box [(value)]="includeOpenVariations" [width]="30"
          (onValueChanged)="includeOpenVariationsChanged($event)">
        </dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate6'">
      <div>
        <span class="ms-2 me-2">Ignore Estimating Extra when Re-Costing</span>
        <dx-check-box [(value)]="ignoreEstimatingExtra" [width]="30"
          (onValueChanged)="ignoreEstimatingExtraChanged($event)">
        </dx-check-box>
      </div>
    </div>
  </dx-data-grid>

  <div *ngIf="!loading && districtId">
    <div class="totalLine">
      <div class="totalClass">
        <div class="costClass">{{ totalPrice | number : '1.0-0' }}</div>
        <div class="costClass">{{ totalManualCost | number : '1.0-0' }}</div>
        <div class="costClass">{{ totalNewCost | number : '1.0-0' }}</div>
      </div>
    </div>
    <div class="totalLine">
      <div class="totalClass">
        <div class="costClass">Profit: {{ totalMargin | number : '1.0-0' }}</div>
        <div class="costClass">Current: {{ totalNewMargin | number : '1.0-0' }}</div>
      </div>
    </div>
    <div class="marginLine">
      <div class="totalClass">
        <div class="markupClass">Markup: {{ markupPercent | number : '1.2-2' }}%</div>
        <div class="markupClass">Current: {{ newMarkupPercent | number : '1.2-2' }}%</div>
      </div>
    </div>
    <div class="marginLine">
      <div class="totalClass">
        <div class="markupClass">Margin: {{ marginPercent | number : '1.2-2' }}%</div>
        <div class="markupClass">Current: {{ newMarginPercent | number : '1.2-2' }}%</div>
      </div>
    </div>
    <div *ngIf="orderedMarkupPercent" class="marginLine">
      <div class="totalClass">
        <div class="orderedMarkupClass">Ordered Markup: {{ orderedMarkupPercent | number : '1.2-2' }}%</div>
      </div>
    </div>
    <div *ngIf="orderedMarkupPercent" class="marginLine">
      <div class="totalClass">
        <div class="orderedMarkupClass">Ordered Margin: {{ orderedMarginPercent | number : '1.2-2' }}%</div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button *ngIf="jobMargins.length" class="btn btn-sm btn-secondary" (click)="getSalesUpsDowns()">Sales
    Give Aways</button>
  <button *ngIf="jobMargins.length" class="btn btn-sm btn-secondary" (click)="openAllEstimatingGrid()">All Estimating
    Items</button>
  <button class="btn btn-sm btn-primary" (click)="close()">Close</button>
</div>
