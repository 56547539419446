import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IJobItem } from '../../../dtos/job-item';
import { CategoryService } from '../../../services/felixApi/category.service';
import { Category } from '../../../dtos/category';
import { NotificationService } from '../../../services/notification.service';
import { JobItemCategory } from '../../../dtos/job-item-category';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-category-modal',
  templateUrl: './category-modal.component.html',
  styleUrls: ['./category-modal.component.scss']
})
export class CategoryModalComponent implements OnInit, OnDestroy {
  @Input() jobItem: IJobItem;

  subscriptions: Subscription[] = [];
  categories: Category[];
  itemCategories: JobItemCategory[];
  timeout: any = null;
  debounceDelay = 300;
  query = '';
  loading = true;

  constructor(public _categoryService: CategoryService,
    private _activeModal: NgbActiveModal,
    private notiService: NotificationService) { }

  ngOnInit() {
    this.getCategories();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getCategories() {
    // read all JobCustomers records for this job
    this.subscriptions = this.subscriptions.concat(
      this._categoryService.getCategories()
        .subscribe({
          next: (categories) => {
            this.categories = categories;
            this.getJobItemCategories();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }})
    );
  }

  getJobItemCategories() {
    // read all JobCustomers records for this job
    this.subscriptions = this.subscriptions.concat(
      this._categoryService.getJobItemCategories(this.jobItem.id)
        .subscribe({
          next: (itemCategories) => {
            this.itemCategories = itemCategories;
            this.setCategoriesCheckedField();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }})
    );
  }

  setCategoriesCheckedField() {
    // we populate the checked flag for all users already selected for this job
    this.categories.forEach(category => {
      category.checked = false;
      this.itemCategories.forEach(itemCategory => {
        if (category.id === itemCategory.category.id) {
          category.checked = true;
          category.itemCategoryId = itemCategory.id;
        }
      });
    });
    this.loading = false;
  }

  checkCategory(category: Category) {

    if (category.checked) {
      this.cancelTimeout();
      this.timeout = setTimeout(() => {
        // delete the record
        this.subscriptions = this.subscriptions.concat(
          this._categoryService.deleteJobItemCategory(category.itemCategoryId).subscribe({
            next: () => {
              this.notiService.showSuccess('Category Removed');
            },
            error: (err) => {
              this.notiService.notify(err);
            }})
        );
      }, this.debounceDelay);
    } else {
      this.cancelTimeout();
      this.timeout = setTimeout(() => {
        // add the record
        this.subscriptions = this.subscriptions.concat(
          this._categoryService.postJobItemCategory(this.jobItem.id, category.id).subscribe({
            next: (res) => {
              const newitemCategoryId = res as number;
              // set the jobCustomerId in the array
              this.categories.forEach(cat => {
                if (cat.id === category.id) {
                  cat.itemCategoryId = newitemCategoryId;
                }
              });
              this.notiService.showSuccess('Category Added');
            },
            error: (err) => {
              this.notiService.notify(err);
            }})
        );
      }, this.debounceDelay);
    }
  }

  cancelTimeout(): void {
    clearTimeout(this.timeout);
    this.timeout = undefined;
  }

  filterCats = (cat: Category) => {
    if (this.query === '') {
      return true;
    }
    return (cat.description.toLowerCase().indexOf(this.query.toLowerCase()) > -1);
  }

  launchConfigApp() {
    window.open('https://truthengineconfiguration.azurewebsites.net/');
  }

  cancel() {
    this._activeModal.close();
  }
}
