<div class="modal-header">
  <h2 class="modal-title">Copy items from another job to this one</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <js-job-number-small [jobNumber]="jobToCopyFrom" [saveJobNumber]="false" [defaultToTemplatesOnSearch]="true"
    (refreshJobNumber)="refreshJobNumber($event)"></js-job-number-small>

  <!-- flag to indicate if we should delete existing items first -->
  <div *ngIf="!loading && askToDelete" class="custom-control custom-checkbox mt-3">
    <input type="checkbox" class="custom-control-input me-1" id="deleteExistingJobItems" [checked]="deleteExistingJobItems"
      (change)="deleteExistingJobItems = !deleteExistingJobItems">
    <label class="custom-control-label" for="deleteExistingJobItems">
      Delete any existing job items?</label>
  </div>

  <div *ngIf="!loading && askToDeleteInfo" class="custom-control custom-checkbox mt-3">
    <input type="checkbox" class="custom-control-input me-1" id="deleteJobDocuments" [checked]="deleteJobDocuments"
      (change)="deleteJobDocuments = !deleteJobDocuments">
    <label class="custom-control-label" for="deleteJobDocuments">
      Delete <b>existing</b> Info records and copy from job above?</label>
  </div>

  <!-- flag to indicate if we should delete existing items first -->
  <div *ngIf="!loading && hasVariations" class="custom-control custom-checkbox mt-3 mb-3">
    <input type="checkbox" class="custom-control-input me-1" id="copyVariations" [checked]="copyVariations"
      (change)="copyVariations = !copyVariations">
    <label class="custom-control-label" for="copyVariations">Copy Sales Variations also?</label>
  </div>
  <br>

  <!-- Image Loading Spinner -->
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>
</div>

<div *ngIf="!loading" class="modal-footer">
  <button type="button" class="btn btn-sm btn-primary" (click)="copyJob()"
    [disabled]='jobToCopy === null || !deleteExistingJobItems'>Run
    Copy</button>
</div>
