<div class="modal-header">
  <h2 class="modal-title">Assign users to roles for this job</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="card">
    <!-- <div class="card-header">
        Assign users to roles for this job
      </div> -->

    <div class="card-body">

      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Role</th>
            <th scope="col">User</th>
            <th scope="col">Modified By</th>
            <th scope="col">Modified</th>
          </tr>
        </thead>

        <!-- Image Loading Spinner -->
        <js-loading-spinner *ngIf="loading"></js-loading-spinner>

        <tbody *ngIf='!loading && jobRoles && jobRoles.length'>
          <tr *ngFor='let jobRole of jobRoles'>
            <ng-container>
              <td>
                {{jobRole.companyRoleDescription}}
              </td>
              <td>
                <js-select-user *ngIf="hasPermission" [jobRole]=jobRole [jobNum]=jobNum
                  (updating)="updatingRole($event)"></js-select-user>
                <div *ngIf="!hasPermission" class="dropdownmenuclass">
                  <span *ngIf="jobRole.user && jobRole.user.fullName">
                    {{jobRole.user.fullName}}
                  </span>
                </div>
              </td>
              <td>
                {{getUserName(jobRole.modifiedUserId)}}
              </td>
              <td>
                {{jobRole.modifiedDate | date}}
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>

    </div>

    <div class="card-footer">
      <button *ngIf="!loading && hasPermission" class="btn btn-sm btn-primary" (click)="cancel()"
        [disabled]="updatingRecordCount !== 0">Close</button>
    </div>
  </div>
</div>
