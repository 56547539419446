import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { OptionListService } from '../../services/felixApi/option-list.service';

@Component({
  selector: 'js-house-options-grid',
  templateUrl: './house-options-grid.component.html',
  styleUrls: ['./house-options-grid.component.scss']
})
export class HouseOptionsGridComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  dataSource: DataSource;
  gridHeight: number;

  constructor(
    private optionListService: OptionListService,
    public activeModal: NgbActiveModal,
    private globalService: GlobalService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe(() => {
        this.gridHeight = window.innerHeight - 130;
      })
    );

    this.gridHeight = window.innerHeight - 130;

    this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  close() {
    this.activeModal.close();
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.optionListService.getHouseOptionList().subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }
}
