export enum CostTypeEnum {
    Priced = 0,
    Note = 1,
    NoCharge = 2,
    EOStandard = 3,
    ProvisionalSum = 4,
    PSFinalised = 5,
    ByOwner = 6,
    Included = 7,
    Promotion = 8,
    BonusItem = 9,
    Excluded = 10
}
