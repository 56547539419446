import { NotificationService } from './../../../services/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobService } from './../../../services/felixApi/job.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { formatDate } from 'devextreme/localization';

@Component({
  selector: 'js-maintenance-complete',
  templateUrl: './maintenance-complete.component.html',
  styleUrls: ['./maintenance-complete.component.css']
})
export class MaintenanceCompleteComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  maintenanceCompleteDate: Date;
  loading = false;

  constructor(
    private jobService: JobService,
    private activeModal: NgbActiveModal,
    private notiService: NotificationService
  ) { }

  ngOnInit() {
    this.maintenanceCompleteDate = this.jobService.currentJobExtra?.maintenanceCompleteDate;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  close() {
    this.activeModal.dismiss();
  }

  submit() {
    this.loading = true;
    const newDate = this.maintenanceCompleteDate ? formatDate(this.maintenanceCompleteDate, 'yyyy-MM-dd') : null;

    this.subscriptions = this.subscriptions.concat(
      this.jobService.updateJobExtra(this.jobService.currentJobExtra.id, { maintenanceCompleteDate: newDate })
        .subscribe({
          next: () => {
            this.activeModal.close(newDate);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

}
