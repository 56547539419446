import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AreaPermission } from '../../dtos/areaPermission';
import { User } from '../../dtos/user';
import { Company } from '../../dtos/company';
import { HttpService } from '../http.service';
import { AuthService } from '../auth.service';
import { ApplicationVersion } from '../../dtos/applicationVersion';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  baseUrl = environment.apiUrl;

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private httpService: HttpService) { }

  getCurrentApplicationVersion(): Observable<ApplicationVersion> {
    const url = this.baseUrl + '/applications/' + environment.appId + '/latest-version';
    return this.http.get<ApplicationVersion>(url, this.httpService.getHttpOptions());
  }

  getAreaPermissions(): Observable<AreaPermission[]> {
    const url = this.baseUrl + '/applications/' + environment.appId + '/area-permissions/me';
    const options = this.httpService.getHttpOptions();
    return this.http.get<AreaPermission[]>(url, options);
  }

  getAuthorisedCompanies() {
    const url = this.baseUrl + '/email-addresses/' + this.auth.getUserEmail().replace('@', '%40')
        + '/authorised-companies';
    return this.http.get<Company[]>(url, this.httpService.getHttpOptions());
  }

  addUserToComp(user: User) {
    const url = this.baseUrl + '/companies/users/client';
    return this.http.post<User>(url, JSON.stringify(user), this.httpService.getHttpOptions());
  }

  getCompanyPermission(compId: number, userId: number) {
    const url = this.baseUrl + '/users/' + userId + '/companies/' + compId + '/user-permissions';
    const res = this.http.get(url, this.httpService.getHttpOptions());
    return res;
  }
}
