<js-background-logo [showImg]="showBackgroundLogo"></js-background-logo>

<div class="card">

  <div class="card-header" [ngClass]="{'header-sticky' : desktop}">

    <!-- enter job number -->
    <js-job-number (refreshJobNumber)='refreshJobNumber($event)'></js-job-number>

    <div *ngIf="!isClientOrAssociate" class="checkbox">
      Show Images &nbsp;
      <input type="checkbox" id="showImages" [checked]="showImages" (change)="showImages = !showImages">
    </div>

    <div *ngIf="isAdmin" class="checkbox">
      Show Deleted &nbsp;
      <input type="checkbox" [checked]="showDeleted" (change)="showDeleted = !showDeleted">
    </div>
  </div>

  <div *ngIf="jobNumber && !loading" class="card-body">
    <!-- recursive call so that headings can have sub-headings. start with top level 0 -->
    <js-job-documents-tree [jobNumber]=jobNumber [showImages]=showImages [showDeleted]=showDeleted [changeJobNum]=changeJobNum></js-job-documents-tree>
  </div>

</div>
