import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { OptionClientHeader } from '../../../../dtos/option-client-header';
import { OptionHeaderService } from '../../../../services/felixApi/option-header.service';
import { IOptionListHeader } from '../../../../dtos/option-list-header';
import { PHONE_SIZE } from '../../../../../config/variables';
import { GlobalService } from '../../../../services/global.service';

@Component({
  selector: 'js-option-header',
  templateUrl: './option-header.component.html',
  styleUrls: ['./option-header.component.scss']
})
export class OptionHeaderComponent implements OnInit {

  @Input() option: IOptionListHeader;
  header: OptionClientHeader;
  headerAttachment;

  loading = true;
  backgroundColor: string;
  htmlAbove: SafeHtml;
  htmlAside: SafeHtml;
  htmlBelow: SafeHtml;

  phoneSize = false;
  subscriptions = [];

  constructor(
    private headerService: OptionHeaderService,
    private globalService: GlobalService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.subscribeToWidthChanges();

    this.subscriptions = this.subscriptions.concat(
      this.headerService.fetchHeader(this.option.id).subscribe({
        next: ([header, attach]) => {
          this.headerAttachment = attach;

          if (header?.htmlDetailAboveImage && header?.htmlDetailAboveImage.length) {
            this.htmlAbove = this.sanitizer.bypassSecurityTrustHtml(
              header.htmlDetailAboveImage
            );
            this.header = header; // only assign if one is valid
          }

          if (header?.htmlDetailAsideImage && header?.htmlDetailAsideImage.length) {
            this.htmlAside = this.sanitizer.bypassSecurityTrustHtml(
              header.htmlDetailAsideImage
            );
            this.header = header; // only assign if one is valid
          }

          if (header?.htmlDetailBelowImage && header?.htmlDetailBelowImage.length) {
            this.htmlBelow = this.sanitizer.bypassSecurityTrustHtml(
              header.htmlDetailBelowImage
            );
            this.header = header; // only assign if one is valid
          }

          this.backgroundColor = header?.backgroundColor;
          this.loading = false;
        }, error: (err) => {
          if (err.status !== 404) {
            // error
          }
          this.loading = false;
        }
      })
    );
  }

  subscribeToWidthChanges() {
    this.setWidth(this.globalService.innerWidth);

    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerWidthChanged.subscribe((width: number) => {
        this.setWidth(width);
      })
    );
  }

  setWidth(innerWidth: number) {
    if (innerWidth <= PHONE_SIZE) {
      this.phoneSize = true;
    } else {
      this.phoneSize = false;
    }
  }

}
