/* Cannot set height of navbar as will break dropdown hamburger menu.
    Need to update here if it changes due to differeent contents
    Currently affects the display of addenda in selections section.     */
export const MENU_HEIGHT = 49;
export const DESKTOP_SIZE = 1000;
export const PHONE_SIZE = 620; // will hide certain fields eg qty less than this width
export const SML_PHONE_SIZE = 380;

export const MAX_SELECTION_IMG_SIZE_MB = 0.075; // GH 29-3-21 - descrease as Addendums are getting too large
export const MAX_DOC_IMAGE_SIZE_MB = 0.5;

export const DEFAULT_ERR_MSG = 'An error has occurred. Please try again in a moment and contact support if the issue persists.';
