export class ClaimJobLine {
    id: number;
    masterId: number;
    orderNo: number;
    claimTriggerId: number;
    companyActivityId: number;
    description: string;
    percent: number;
    amount: number;
    isDepositDeducted: boolean;
    isDepositLine: boolean;
    isInvoiceToClient: boolean;
    daysToPay: number;

    constructor(amount: number) {
      this.id = 0;
      this.description = 'Initial Deposit';
      this.amount = amount;
      this.claimTriggerId = 5;
      this.isDepositLine = true;
      this.isInvoiceToClient = true;
    }
}
