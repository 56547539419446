import { AuthService } from '../../../services/auth.service';
import { IntegrationTypeEnum } from '../../../dtos/integration-type.enum';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';
import { IntegrationService } from '../../../services/felixApi/integration.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LeadManagementDeal } from '../../../dtos/lead-management-deal';
import CustomStore from 'devextreme/data/custom_store';
import { GridService } from '../../../services/grid.service';

@Component({
  selector: 'js-lead-management-fields',
  templateUrl: './lead-management-fields.component.html',
  styleUrls: ['./lead-management-fields.component.scss']
})
export class LeadManagementFieldsComponent implements OnInit, OnDestroy {
  @Input() jobId: number;
  @Input() isHubSpotActive: boolean;
  @Input() divisionId: number;

  loadingData = true;
  loading = true;
  subscriptions: Subscription[] = [];
  includeLostDeals = false;
  includeWonDeals = false;
  dataSource: CustomStore;
  selectedRecord = '';
  gridHeight: number;
  descWidth: number;
  leadManagementDealId: string;
  salesRepDescription: string;
  selectedRecords: any[] = [];
  isAdmin: boolean;
  dataLoaded: boolean;
  importClicked: boolean;
  leadManagementDeal: LeadManagementDeal = new LeadManagementDeal();

  constructor(
    private activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private integrationService: IntegrationService,
    private notiService: NotificationService,
    private authService: AuthService,
    public gridService: GridService) { }

  ngOnInit() {
    this.isAdmin = this.authService.isAdminOrSuperUser();
    this.gridHeight = window.innerHeight - 430;

    if (window.innerWidth > 1370) {
      this.descWidth = 700;
    } else {
      this.descWidth = window.innerWidth / 2;
    }
    this.loadJobIntegrationData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  loadJobIntegrationData() {
    this.subscriptions = this.subscriptions.concat(
      this.integrationService.getJobIntegrationData(this.jobId,
        this.isHubSpotActive ? IntegrationTypeEnum.HubSpot : IntegrationTypeEnum.ActiveCampaign).subscribe({
          next: (res) => {
            this.loadingData = false;
            this.dataLoaded = true;
            this.leadManagementDealId = res?.integrationId;
            if (this.leadManagementDealId) {
              this.getDeal(true);
              this.setUpDataSet(true);
            } else {
              this.notiService.showInfo('Not connected to ' + (this.isHubSpotActive ? "HubSpot" : "Active Campaign") + ".");
            }
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingData = false;
          }
        })
    );
  }

  getDeal(useCache: boolean) {
    this.integrationService.getDeal(useCache, this.leadManagementDealId, this.divisionId).subscribe({
      next: (res) => {
        this.leadManagementDeal = res;
      }, error: (err) => {
        this.notiService.notify(err);
      }
    });
  }

  setUpDataSet(useCache: boolean) {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.integrationService.getDealFieldsData(useCache, this.leadManagementDealId, this.divisionId).subscribe({
            next: (res) => {
              this.loading = false;
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      });
  }

  refresh() {
    this.loading = true;
    this.setUpDataSet(false);
  }

  close() {
    this.activeModal.dismiss();
  }

  importData(onlyJobData: boolean) {
    if (this.importClicked) {
      this.integrationService.onlyJobData = onlyJobData;
      this.activeModal.close(this.leadManagementDealId);
    } else {
      this.importClicked = true;
    }
  }

  disconnect() {
    this.loadingData = true;
    this.subscriptions = this.subscriptions.concat(
      this.integrationService.deleteJobIntegration(this.jobId,
        this.isHubSpotActive ? IntegrationTypeEnum.HubSpot : IntegrationTypeEnum.ActiveCampaign).subscribe({
          next: () => {
            this.notiService.showSuccess('Disconnected');
            this.activeModal.close("Disconnected");
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingData = false;
          }
        })
    );
  }
}
