import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { JobDocumentsComponent } from './job-documents.component';
import { JobDocumentsTreeComponent } from './job-documents-tree.component';
import { JobDocumentService } from '../services/felixApi/job-document.service';
import { JobsModule } from '../jobs/jobs.module';
import { DocumentModalComponent } from './job-documents-modals/document-modal.component';
import { DocumentVersionsComponent } from './job-documents-modals/document-versions/document-versions.component';
import { AddDocumentsComponent } from './job-documents-modals/add-documents/add-documents.component';
import { JobDocumentsClientComponent } from '../job-documents-client/job-documents-client.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        JobsModule,
        NgbModule,
        NgbCollapseModule
    ],
    providers: [
        JobDocumentService
    ],
    declarations: [
        JobDocumentsComponent,
        JobDocumentsTreeComponent,
        DocumentModalComponent,
        DocumentVersionsComponent,
        AddDocumentsComponent,
        JobDocumentsClientComponent
    ]
})
export class JobDocumentsModule { }
