<div class="modal-header">
  <h4 class="modal-title" placement="bottom" [ngbTooltip]=modalTitle>
    {{itemDescription}}: {{selection}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false)" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <mat-expansion-panel [(expanded)]="feesSectionExpanded" (opened)="setHeight()" (closed)="setHeight()">
    <mat-expansion-panel-header>
      <mat-panel-title class="titleClass">Mark-Ups</mat-panel-title>
    </mat-expansion-panel-header>

    <form *ngIf="!loading && optionsForm" class="form formClass" novalidate [formGroup]="optionsForm">
      <fieldset>
        <div class="">
          <!-- Mark-Up -->
          <label class="form-label marginLabel text-end" for="variationMargin">Mark-Up%</label>
          <input id="variationMargin" class="form-control-sm marginField" type="number" placeholder=""
            formControlName="variationMargin" (keydown.enter)="setMarginInJob();" tabindex="-1"
            [class.redBackground]="!optionsForm.get('variationMargin').value" />

          <!-- Fees Mark-Up -->
          <!-- Can turn the fees off for this item -->
          <button *ngIf="allowUpdate && !optionsForm.get('isFeesMarginActive').value"
            class="btn btn-sm btn-primary ms-1 buttonClass" (click)="setMarginInJob()">
            Set
          </button>

          <label *ngIf="estimatingPreContractFeesMargin" class="form-label gstLabel text-end"
            for="isFeesMarginActive">Use Fees?
            <mat-checkbox *ngIf="estimatingPreContractFeesMargin" class="ms-1" formControlName="isFeesMarginActive"
              tabindex="-1" (click)='onClickFeesActive()'>
            </mat-checkbox>
          </label>

          <label *ngIf="estimatingPreContractFeesMargin && optionsForm.get('isFeesMarginActive').value"
            class="form-label feesLabel text-end" for="feesMargin">
            Insurances & Fees%
          </label>
          <input *ngIf="estimatingPreContractFeesMargin && optionsForm.get('isFeesMarginActive').value" id="feesMargin"
            class="form-control-sm col-sm-1" type="number" placeholder="" formControlName="feesMargin"
            (keydown.enter)="setMarginInJob();" tabindex="-1"
            [class.redBackground]="!optionsForm.get('feesMargin').value" />

          <button *ngIf="allowUpdate && optionsForm.get('isFeesMarginActive').value"
            class="btn btn-sm btn-primary ms-1 buttonClass" (click)="setMarginInJob()">
            Set
          </button>

          <!-- GST Applicable? -->
          <label class="form-label gstLabel text-end" for="isGSTFree">GST Free?
            <mat-checkbox class="ms-1" formControlName="isGSTFree" (click)="onGSTFreeClick()" tabindex="-1">
            </mat-checkbox>
          </label>

          <!-- GST -->
          <label class="form-label gstLabel2 text-end" for="gstRate">GST%</label>
          <input *ngIf="!optionsForm.get('isGSTFree').value" id="gstRate" class="form-control-sm gstDataField ms-2"
            type="number" placeholder="" (keyup)="onGSTChange()" (click)="onGSTChange()" formControlName="gstRate"
            tabindex="-1" />
          <input *ngIf="optionsForm.get('isGSTFree').value" id="gstRate"
            class="form-control-sm gstDataField ms-2 redBackground" type="number" placeholder=""
            formControlName="gstRate" readonly />

          <!-- Distict and Costing Date -->
          <span class='ms-4 text-end'>Price Book: <b>{{setBook}}</b>
            <span class='ms-3'>
              Prices as at:
              <b *ngIf="estimatingCostingDate">{{estimatingCostingDate | date}}</b>
              <b *ngIf="!estimatingCostingDate" class="red">Date NOT Set</b>
            </span>
          </span>
          <button *ngIf="allowUpdate" class="btn btn-sm btn-primary ms-2 buttonClass"
            (click)="selectPriceFile()">Change</button>

        </div>
      </fieldset>
    </form>
  </mat-expansion-panel>

  <dx-data-grid *ngIf="!loading && estimatingCostingDate && optionsForm" #dataGrid id="dataGrid"
    [dataSource]="dataSource" [allowColumnReordering]="true" [showColumnLines]="true" [showRowLines]="true"
    [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="false"
    [wordWrapEnabled]="true" (onInitNewRow)="initNewRow($event)" [height]="gridHeight"
    (onRowInserted)="$event.component.navigateToRow($event.key)" (onRowPrepared)="onRowPrepared($event)"
    (onFocusedCellChanging)="onFocusedCellChanging($event)" (onCellPrepared)="onCellPrepared($event)"
    (onEditorPreparing)="onEditorPreparing($event)" (onToolbarPreparing)="onToolbarPreparing($event)">

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="estimatingtaskgrid"
      [savingTimeout]="500"></dxo-state-storing>

    <dxo-keyboard-navigation [editOnKeyPress]="editOnkeyPress" [enterKeyAction]="enterKeyAction"
      [enterKeyDirection]="enterKeyDirection">
    </dxo-keyboard-navigation>

    <dxo-editing mode="batch" [refreshMode]="refreshMode" [allowAdding]="allowUpdate" [allowUpdating]="allowUpdate"
      [allowDeleting]="allowUpdate" [useIcons]="true" startEditAction="click">
    </dxo-editing>

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-sorting mode="single"></dxo-sorting>

    <dxi-column caption="" [width]="44" cellTemplate="explodeTemplate"></dxi-column>
    <div *dxTemplate="let data of 'explodeTemplate'" class="text-center">
      <dx-button id="explodeFieldButton" hint="explode" icon="share" [visible]="isExplodeIconVisible(data)"
        (onClick)="explodeAsk(data)">
      </dx-button>
    </div>

    <dxi-column dataField="id" sortOrder="asc" [visible]="false"></dxi-column>

    <dxi-column dataField="recipeCode" caption="Code" [width]="130" editCellTemplate="codeDropDown"
      [setCellValue]="setRecipeCellValue" [calculateSortValue]="calculateCodeSortValue">
    </dxi-column>

    <dxi-column dataField="description" dataType="string" [minWidth]="100" cellTemplate="descriptionTemplate">
    </dxi-column>
    <div *dxTemplate="let cell of 'descriptionTemplate'">
      <div id="wrapComment" class='mx-0 px-0'>{{cell.value}}</div>
    </div>

    <dxi-column dataField="unitOfMeasure" caption="Units" [width]="100" [setCellValue]="setUnitOfMeasureCellValue">
      <dxo-lookup [dataSource]="unitOfMeasures" valueExpr="description" displayExpr="description">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="newQuantityString" caption="New Qty" [width]="300"
      [setCellValue]="setNewQuantityStringCellValue">
    </dxi-column>

    <dxi-column dataField="newQuantity" caption="New Qty" [width]="70" [visible]="false">
    </dxi-column>

    <dxi-column dataField="oldQuantityString" caption="Old Qty" [width]="110"
      [setCellValue]="setOldQuantityStringCellValue">
    </dxi-column>

    <dxi-column dataField="oldQuantity" caption="Old Qty" [width]="70" [visible]="false">
    </dxi-column>

    <dxi-column caption="Qty" [calculateCellValue]="calculateQty" dataType="number" [allowEditing]="false" [width]="70">
    </dxi-column>

    <dxi-column dataField="rate" dataType="number" format="#,###.00" [width]="90" [setCellValue]="setRateCellValue"
      [calculateCellValue]="getRate" cellTemplate="rateCellTemplate">
    </dxi-column>

    <dxi-column dataField="costTotal" caption="Cost" dataType="number" [width]="90" format="#,###"
      [allowEditing]="false" [calculateCellValue]="calculateCostTotal">
    </dxi-column>

    <dxi-column dataField="margin" caption="MarkUp%" dataType="number" cellTemplate="marginCellTemplate" [width]="80"
      [setCellValue]="setMarginCellValue">
    </dxi-column>

    <dxi-column dataField="lineTotal" caption="Total" dataType="number" [width]="90" format="#,###"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="isTBA" caption="TBA" dataType="boolean" [width]="40">
    </dxi-column>

    <dxi-column type="buttons" [width]="44">
      <dxi-button name="delete"></dxi-button>
    </dxi-column>

    <dxo-summary [calculateCustomSummary]="calculateTotal" [recalculateWhileEditing]="true">
      <dxi-total-item column="costTotal" summaryType="sum" [valueFormat]="{ type: 'fixedPoint', precision: 0 }"
        displayFormat="{0}">
      </dxi-total-item>

      <dxi-total-item name="marginTotal" summaryType="custom" displayFormat="Total:" showInColumn="margin">
      </dxi-total-item>

      <dxi-total-item name="gridTotal" summaryType="custom" valueFormat="#,##0" displayFormat="{0}"
        showInColumn="lineTotal">
      </dxi-total-item>
    </dxo-summary>

    <div *dxTemplate="let cellInfo of 'codeDropDown'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="recipeData" [(value)]="cellInfo.value"
        displayExpr="recipeCode" valueExpr="recipeCode" contentTemplate="contentTemplate">
        <!-- Note we have to use standard scrolling so the list will "autoNavigateToFocusedRow" if the item is not near the top of the list -->
        <div *dxTemplate="let e of 'contentTemplate'" class="dropdownClass">
          <dx-data-grid [dataSource]="recipeData" [remoteOperations]="false" height="99%" [showBorders]="true"
            [focusedRowEnabled]="true" [focusedRowKey]="cellInfo.value" [autoNavigateToFocusedRow]="true"
            [wordWrapEnabled]="true" [selectedRowKeys]="[cellInfo.value]" [hoverStateEnabled]="true"
            [autoNavigateToFocusedRow]="true" (onSelectionChanged)="onSelectionChanged(cellInfo, e.component, $event)">

            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling mode="virtual" [preloadEnabled]="true"></dxo-scrolling>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-search-panel [visible]="true" [width]="758"></dxo-search-panel>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
            </dxo-grouping>

            <dxi-column dataField="masterGroupCostCentre" dataType="string" caption="" [groupIndex]="0" [width]="200"
              groupCellTemplate="groupCellTemplate">
            </dxi-column>

            <dxi-column dataField="subGroupItemDesc" dataType="string" caption="" [groupIndex]="1" [width]="220"
              groupCellTemplate="groupCellTemplate">
            </dxi-column>

            <dxi-column dataField="recipeCode" caption="Code" [width]="140"></dxi-column>

            <dxi-column dataField="description"></dxi-column>
            <dxi-column dataField="rate" dataType="number" [width]="100" format="#,###.00"></dxi-column>
            <dxi-column dataField="scale" [visible]="false"></dxi-column>

            <div *dxTemplate="let data of 'groupCellTemplate'">
              {{ getRecipeGroupTitle(data) }}
            </div>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>


    <!-- TEMPLATES -->
    <div *dxTemplate="let d of 'marginCellTemplate'">
      <span *ngIf="feesSectionExpanded"> {{d.value | number }} </span>
      <span *ngIf="!feesSectionExpanded"> ** </span>
    </div>

    <div *dxTemplate="let d of 'rateCellTemplate'">
      <div id="rateToolTip" (mouseenter)="toggleToolTip(d)" (mouseleave)="defaultTipVisible = false">
        <span *ngIf="d.value === 'Invalid'; else elseBlock">Invalid</span>
        <ng-template #elseBlock>
          <span>{{d.value | number : '1.2-2' }}</span>
        </ng-template>
      </div>
      <dx-tooltip target="#rateToolTip" showEvent="dxhoverstart" hideEvent="dxhoverend" [hideOnOutsideClick]="true">
        <div *dxTemplate="let data of 'content'">
          <p>Pink indicates zero rate or a rate that does not match the price file. Invalid prices are when recipes have
            inactive or out of date prices</p>
        </div>
      </dx-tooltip>
    </div>
  </dx-data-grid>

  <!-- Total line -->
  <div *ngIf="!loading && estimatingCostingDate" class="totalLine">
    <div class="totalClass">
      <span *ngIf="isTBA">TBA</span>
      <span *ngIf="!isTBA">{{ itemTotal | number }}</span>
    </div>
    <div class="totalLabel">
      Total +GST<span *ngIf="estimatingPreContractFeesMargin && optionsForm.get('isFeesMarginActive').value">
        +Fees<span placement="top" ngbTooltip="Fees also has GST added if applicable" class="red">**</span></span>:
    </div>
  </div>
</div>

<div *ngIf="estimatingCostingDate" class="modal-footer">
  <!-- load multiple recipes -->
  <div class="justifyLeft">
    <div *ngIf="allowUpdate" class="class500 floatLeft">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="recipeData" [(value)]="selectedRecipes"
        [(opened)]="isDropDownBoxOpened" displayExpr="recipeCode" valueExpr="recipeCode"
        placeholder="Select recipes to load" [showClearButton]="true">
        <div *dxTemplate="let data of 'content'">
          <dx-data-grid #lookupdataGrid id="lookupdataGrid" [dataSource]="recipeData" [remoteOperations]="false"
            [columnAutoWidth]="false" height="99%" [showBorders]="true" [focusedRowEnabled]="true"
            [(selectedRowKeys)]="selectedRecipes" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
            [scrolling]="{ mode: 'virtual', preloadEnabled: true }" (onRowClick)="onMultiRowClick($event)">

            <dxo-selection [allowSelectAll]="false" showCheckBoxesMode='always' mode="multiple">
            </dxo-selection>
            <dxo-paging [enabled]="false"> </dxo-paging>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-search-panel [visible]="true" [width]="758"></dxo-search-panel>
            <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
            </dxo-grouping>

            <dxi-column dataField="masterGroupCostCentre" dataType="string" caption="" [groupIndex]="0" [width]="200"
              groupCellTemplate="groupCellTemplate">
            </dxi-column>

            <dxi-column dataField="subGroupItemDesc" dataType="string" caption="" [groupIndex]="1" [width]="220"
              groupCellTemplate="groupCellTemplate">
            </dxi-column>

            <dxi-column dataField="recipeCode" caption="Code" [width]="110"></dxi-column>
            <dxi-column dataField="description"></dxi-column>
            <dxi-column dataField="rate" dataType="number" [width]="100" format="#,###.00"></dxi-column>
            <dxi-column dataField="scale" [visible]="false"></dxi-column>

            <div *dxTemplate="let data of 'groupCellTemplate'">
              {{ getGroupTitle(data) }}
            </div>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>
    <button *ngIf="allowUpdate" class="btn btn-secondary floatLeft ms-2" (click)="addRecipesFromList()"
      [disabled]="loading || !selectedRecipes || !selectedRecipes.length">Load</button>

    <button *ngIf="allowUpdate" class="btn btn-secondary floatLeft ms-2" (click)="refreshRecipes()"
      [disabled]="loading">Refresh</button>

    <button *ngIf="allowUpdate" class="btn btn-primary floatRight" (click)="closeModal(true)"
      [disabled]="loading">Ok</button>
    <button class="btn btn-secondary floatRight me-2" (click)="closeModal(false)" [disabled]="loading">Cancel</button>
    <button class="btn btn-secondary floatRight me-2" (click)="saveToRecipe()" [disabled]="loading">Save to
      Recipe</button>
    <button class="btn btn-secondary floatRight me-2" (click)="goToAttachments()"
      [disabled]="loading">Attachments</button>
  </div>
</div>

<dx-popup class="popup" [width]="230" [height]="130" [showTitle]="true" title="Explode?" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="popupVisible">
  <div id="explodeButton" *dxTemplate="let data of 'content'">
    <dx-button text="Explode" type="success" (onClick)="explode()"></dx-button>
  </div>
</dx-popup>

<dx-popup class="popup" [width]="320" [height]="235" [showTitle]="true" title="Set Price File" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="setPriceFilePopupVisible">
  <div id="setPriceFile" *dxTemplate="let data of 'content'">
    <div class='mt-2 ms-3'>
      <span class='me-1 w-100'>Book:</span>
      <dx-select-box class="costingDateClass" [dataSource]="districtsDataSource" displayExpr="description"
        valueExpr="id" [(value)]="priceFileBookId">
      </dx-select-box>
    </div>
    <div class='mt-2 ms-3'>
      <span class='me-1 w-100'>As at:</span>
      <dx-date-box class="costingDateClass" [(value)]="estimatingCostingDate" type="date"></dx-date-box>
    </div>
    <br>
    <div class="mt-2 d-flex justify-content-end">
      <dx-button class='ms-3 me-3' id="button" text="Set" type="success" (onClick)="setPriceFile()">
      </dx-button>
    </div>
  </div>
</dx-popup>

<dx-popup class="popup" [width]="190" [height]="140" [showTitle]="true" title="Reset" [dragEnabled]="false"
  [hideOnOutsideClick]="false" [(visible)]="resetPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-button id="button" text="Reset Layout" type="success" (onClick)="clearStatePersistanceGo()" class="mt-2 ms-3">
    </dx-button>
  </div>
</dx-popup>


<dx-popup class="popup" [width]="330" [height]="140" [showTitle]="true" title="Update lines" [dragEnabled]="false"
  [hideOnOutsideClick]="false" [(visible)]="updateMarkupPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-button id="button" text="Update lines with the same markup" type="success" (onClick)="updateLinesToNewMarkup()"
      class="mt-2 ms-3">
    </dx-button>
  </div>
</dx-popup>


<dx-popup class="popup" [width]="200" [height]="120" [showTitle]="true" title="Re-Price" [dragEnabled]="true"
  [shading]="false" [hideOnOutsideClick]="true" [(visible)]="repricePopupVisible">
  <div *dxTemplate="let data of 'content'">
    <div *ngIf="!loading">
      <dx-button [width]="158" text="Go" type="default" (onClick)="runReprice()"></dx-button>
    </div>
  </div>
</dx-popup>


<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
