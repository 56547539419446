<div class="card">
  <div class="card-header">
    Create contract for job {{currentJob?.jobNumber}}: {{jobString}}
  </div>

  <div class="card-body">

    <!-- Loading Spinner -->
    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <div *ngIf="!loading">
      Select which items to carry over to the contract:
    </div>

    <div *ngIf="!loading" class="treeclass">
      <tree-root #tree [focused]="true" [nodes]="treeNodes" [options]="treeOptions" (initialized)="onTreeInit(tree)">
        <ng-template #treeNodeTemplate let-node="node" let-index="index" m-0 p-0>
          <input (change)="treeNodeClicked(node)" type="checkbox" id="check-{{node.data.id}}"
            [(checked)]="node.data.checked" [indeterminate]="node.data.indeterminate">
          {{ node.data.description }}
        </ng-template>
      </tree-root>
    </div>

  </div>

  <div class="card-footer">
    <div *ngIf="!loading" class="form-group">
      <button type="button" class="btn btn-sm btn-primary" (click)="createContract()">Create Contract</button>
    </div>
  </div>
</div>
