import { UserService } from './../../services/felixApi/user.service';
import { CompanyService } from './../../services/felixApi/company.service';
import { VariationStatusEnum } from './../../dtos/variation-status.enum';
import { UtilsService } from './../../services/utils.service';
import { JobService } from './../../services/felixApi/job.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { NotificationService } from '../../services/notification.service';
import { Variation } from '../../dtos/variation';
import { DxDataGridComponent } from 'devextreme-angular';
import { IJob } from '../../dtos/job';
import { JobTypeEnum } from '../../dtos/job-type.enum';
import { User } from '../../dtos/user';
import { RoleTypeEnum } from '../../dtos/role-type.enum';
import { JobRole } from '../../dtos/job-role';

@Component({
  selector: 'js-variation-report',
  templateUrl: './variation-report.component.html',
  styleUrls: ['./variation-report.component.css']
})
export class VariationReportComponent implements OnInit, OnDestroy {
  @Input() variations: Variation[];

  @ViewChild('allEstimateGrid') dataGrid: DxDataGridComponent;

  loading = true;
  subscriptions: Subscription[] = [];
  gridHeight: number;
  dataSource: DataSource;
  jobs: IJob[];
  variationStatuses: { id: number, description: string }[];
  users: User[];
  allJobRoles: JobRole[];
  siteSupervisorName: string;
  liaisonName: string;
  prestartOfficer: string;
  constructionCoordinator: string;
  preConstructionCoordinator: string;

  constructor(private _activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private jobService: JobService,
    private notiService: NotificationService,
    private userService: UserService,
    private companyService: CompanyService,
    private utilsService: UtilsService) {
    this.calcSiteAddress = this.calcSiteAddress.bind(this);
    this.calculateJobSortValue = this.calculateJobSortValue.bind(this);
    this.calculateContractName = this.calculateContractName.bind(this);
    this.calculateCurrentActivity = this.calculateCurrentActivity.bind(this);
    this.calculateActivitySort = this.calculateActivitySort.bind(this);
    this.calculateSiteSupervisor = this.calculateSiteSupervisor.bind(this);
    this.calculateLiaison = this.calculateLiaison.bind(this);
    this.calculatePrestartOfficer = this.calculatePrestartOfficer.bind(this);
    this.calculateConstructionCoordinator = this.calculateConstructionCoordinator.bind(this);
    this.calculatePreConstructionCoordinator = this.calculatePreConstructionCoordinator.bind(this);
  }

  ngOnInit() {
    this.variationStatuses = this.utilsService.enumToArray(VariationStatusEnum);

    this.variations.forEach(variation => {
      variation.variationTypeDesc = variation.variationType < 10 ? 'Post-Contract' : variation.variationType < 20 ? 'Sales' : 'Pre-Contract';
    });

    this.subscribeToWidthChanges();
    this.setHeight();
    this.getData();
  }

  getData() {
    this.subscriptions = this.subscriptions.concat(
      this.jobService.getJobsWithRoles(true)
        .subscribe({
          next: () => {
            this.jobs = this.jobService.jobs;
            this.users = this.userService.users;
            this.allJobRoles = this.jobService.companyJobRoles;
            this.siteSupervisorName = this.allJobRoles.find(i => i.roleId === RoleTypeEnum.SiteManager)?.companyRoleDescription;
            this.liaisonName = this.allJobRoles.find(i => i.roleId === RoleTypeEnum.LiaisonOfficer)?.companyRoleDescription;
            this.prestartOfficer = this.allJobRoles.find(i => i.roleId === RoleTypeEnum.PreStartOfficer)?.companyRoleDescription;
            this.constructionCoordinator = this.allJobRoles.find(i => i.roleId === RoleTypeEnum.ConstructionCoordinator)?.companyRoleDescription;
            this.preConstructionCoordinator = this.allJobRoles.find(i => i.roleId === RoleTypeEnum.PreconstructionCoordinator)?.companyRoleDescription;
            this.loading = false;
            this.setUpDataSet();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  subscribeToWidthChanges() {
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerHeightChanged.subscribe(() => {
        this.setHeight();
      })
    );
  }

  setHeight() {
    this.gridHeight = this.globalService.innerHeight - 300;
  }

  setUpDataSet() {
    this.dataSource = new DataSource({
      key: 'id',
      load: () => this.variations
    });
  }

  close() {
    this._activeModal.close(null);
  }

  calcSiteAddress(data) {
    const job = this.jobs?.find(j => j.id === data.jobId);
    return this.globalService.getJobString(job, false);
  }

  calculateJobSortValue(data) {
    const job = this.jobs.find(i => i.id === data.jobId);
    if (job) {
      return job.jobNumber;
    }

    return '';
  }

  calculateContractName(data) {
    if (data && data.jobId) {
      // on hold?
      let contractName = '';
      const job = this.jobs.find(i => i.id === data.jobId);
      if (job) {
        contractName = job.contractName;
      }
      return contractName;
    }
    return '';
  }

  calculateCurrentActivity(data) {
    if (data && data.jobId) {
      const job = this.jobs.find(i => i.id === data.jobId);
      const jobExtra = this.jobService.jobExtras?.find(i => i.jobId === data.jobId);
      if (jobExtra) {
        if (jobExtra?.maintenanceCompleteDate) {
          job.currentActivityDesc = 'Maintenance Complete';
        } else if (jobExtra.cancellationDate) {
          job.currentActivityDesc = 'Cancelled';
        } else if (jobExtra.isOnHold) {
          job.currentActivityDesc = 'On Hold';
        } else {
          const activity = this.companyService.activities?.find(i => i.id === jobExtra?.currentActivityId);
          if (activity) {
            job.currentActivityDesc = activity.description;
            job.currentActivityCode = activity.activityCode;
          } else {
            if (!job.isActive) {
              job.currentActivityDesc = 'Inactive Job';
            } else if (!job.salesDate && job.jobTypeId === JobTypeEnum.StandardJob) {
              job.currentActivityDesc = 'Pending Sale';
              job.currentActivityCode = '';
            }
          }
        }
      } else {
        if (!job.isActive) {
          job.currentActivityDesc = 'Inactive Job';
        } else if (!job.salesDate && job.jobTypeId === JobTypeEnum.StandardJob) {
          job.currentActivityDesc = 'Pending Sale';
          job.currentActivityCode = '';
        }
      }
      return job.currentActivityDesc;
    }
    return '';
  }

  calculateActivitySort(data) {
    if (data && data.jobId) {
      const job = this.jobs.find(i => i.id === data.jobId);
      return job?.currentActivityCode;
    }
    return '';
  }

  calculateSiteSupervisor(data) {
    const jobRole = this.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.SiteManager);
    return jobRole?.user?.fullName ?? "";
  }

  calculateLiaison(data) {
    const jobRole = this.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.LiaisonOfficer);
    return jobRole?.user?.fullName ?? "";
  }

  calculatePrestartOfficer(data) {
    const jobRole = this.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.PreStartOfficer);
    return jobRole?.user?.fullName ?? "";
  }

  calculateConstructionCoordinator(data) {
    const jobRole = this.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.ConstructionCoordinator);
    return jobRole?.user?.fullName ?? "";
  }

  calculatePreConstructionCoordinator(data) {
    const jobRole = this.allJobRoles?.find(i => i.jobId === data.jobId && i.roleId === RoleTypeEnum.PreconstructionCoordinator);
    return jobRole?.user?.fullName ?? "";
  }
}
