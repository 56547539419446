import { throwError as observableThrowError, of, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { GlobalService } from '../global.service';
import { IImageLibraryEntry } from '../../dtos/image-library-entry';
import { IImageLibraryAttachment } from '../../dtos/image-library-attachment';
import { HttpService } from '../http.service';

@Injectable({
    providedIn: 'root'
})
export class ImageLibraryService {

    cache: IImageLibraryAttachment[] = [];

    constructor(
        private httpService: HttpService,
        private http: HttpClient,
        private globalService: GlobalService) { }

    getLibraryImageAttachment(id): Observable<IImageLibraryAttachment> {
        const img = this.cache.filter(i => i.optionImageId === id);
        if (img[0]) {
            return of(img[0]);
        } else {
            return this.http.get<IImageLibraryAttachment>(
                this.globalService.getApiUrl() + '/option-image-attachments/' + id,
                this.httpService.getHttpOptions()
            )
                .pipe(
                    tap(res => this.cache = this.cache.concat(res)),
                    catchError(this.handleError)
                );
        }
    }

    getImageLibrary() {
        return this.http.get<IImageLibraryEntry[]>(this.globalService.getApiUrl() + '/option-images',
            this.httpService.getHttpOptions()).pipe(
                catchError(this.handleError));
    }

    getImageLibraryEntry(id) {
        return this.http.get<IImageLibraryEntry>(this.globalService.getApiUrl() + '/option-images/' + id,
            this.httpService.getHttpOptions()).pipe(
                catchError(this.handleError));
    }

    // patchLibraryImageEntry(libraryEntry: IImageLibraryEntry) {
    //     return this.http.patch<IImageLibraryEntry>(this.globalService.getApiUrl() + '/option-images/' + libraryEntry.id,
    //         libraryEntry,
    //         this.httpService.getHttpOptions());
    // }

    // postLibraryImageEntry(libraryEntry) {
    //     return this.http.post<IImageLibraryEntry>(this.globalService.getApiUrl() + '/option-images',
    //         libraryEntry,
    //         this.httpService.getHttpOptions());
    // }

    // deleteLibraryImageEntry(id) {
    //     return this.http.delete<IImageLibraryEntry>(this.globalService.getApiUrl() + '/option-images/' + id,
    //         this.httpService.getHttpOptions());
    // }

    // patchLibraryImageAttachment(id, image) {
    //     return this.http.patch<IImageLibraryAttachment>(this.globalService.getApiUrl() + '/option-image-attachments/' + id,
    //         image,
    //         this.globalService.getHttpFileOptions());
    // }

    // postLibraryImageAttachment(id, image) {
    //     return this.http.post<IImageLibraryAttachment>(this.globalService.getApiUrl() + '/option-image-attachments/' + id,
    //         image,
    //         this.globalService.getHttpFileOptions());
    // }

    // getLibraryImageAttachment(id) {
    //     return this.http.get<IImageLibraryAttachment>(this.globalService.getApiUrl() + '/option-image-attachments/' + id,
    //         this.httpService.getHttpOptions()).pipe(
    //             catchError(this.handleError));
    // }

    // deleteLibraryImageAttachment(id) {
    //     return this.http.delete<IImageLibraryEntry>(this.globalService.getApiUrl() + '/option-image-attachments/' + id,
    //         this.httpService.getHttpOptions());
    // }

    private handleError(err: HttpErrorResponse) {
        console.log(JSON.stringify(err));
        return observableThrowError(err);
    }
}

