
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { JobItemExtra } from '../../dtos/job-item-extra';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';

@Injectable()
export class JobItemExtraService {

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  // getJobItemExtras(jobId): Observable<JobItemExtra[]> {
  //   return this._http.get<JobItemExtra[]>(this.globalService.getApiUrl() + '/job/' + jobId + '/job-item-extras',
  //     this.httpService.getHttpOptions()).pipe(
  //       catchError(this.handleError));
  // }

  getJobItemExtra(jobItemId: number): Observable<JobItemExtra> {
    return this._http.get<JobItemExtra>(this.globalService.getApiUrl() + '/job-item/' + jobItemId + '/job-item-extras',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addJobItemExtra(itm: any) {
    const url = this.globalService.getApiUrl() + '/job-item-extras';
    return this._http.post(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  updateJobItemExtra(itm: any) {
    const url = this.globalService.getApiUrl() + '/job-item-extras/' + itm.id;
    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  // deleteJobItemExtra(itmId: number) {
  //   const url = this.globalService.getApiUrl() + '/jobitem-extras/' + itmId;
  //   return this._http.delete(url, this.httpService.getHttpOptions());
  // }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
