<div class="modal-header">
  <h4 class="modal-title" placement="bottom">Job Document Attachments Modified</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-data-grid *ngIf="!loading" [dataSource]="dataSource" [height]="gridHeight" [remoteOperations]="false"
    [showRowLines]="false" [showColumnLines]="true" [showBorders]="true" [columnAutoWidth]="false"
    [allowColumnResizing]="true" [wordWrapEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)">

    <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-paging [enabled]="false"> </dxo-paging>

    <dxo-editing mode="row" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false" [useIcons]="true"
      refreshMode="full"></dxo-editing>

    <!-- <dxi-column dataField="jobNumber" width="100"> </dxi-column> -->

    <dxi-column dataField="jobId" caption="Job" dataType="number" sortOrder="asc" [width]="200">
      <dxo-lookup [dataSource]="jobs" valueExpr="id" displayExpr="jobNumber">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="description"> </dxi-column>
    <dxi-column dataField="modifiedDate" dataType="date" [width]="150"> </dxi-column>

    <dxi-column type="buttons" [width]="50">
      <dxi-button icon="download" [onClick]="downloadImage" hint='Download'>
      </dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>
