import { JobService } from '../../../services/felixApi/job.service';
import { IntegrationTypeEnum } from '../../../dtos/integration-type.enum';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';
import { IntegrationService } from '../../../services/felixApi/integration.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LeadManagementDeal } from '../../../dtos/lead-management-deal';
import CustomStore from 'devextreme/data/custom_store';
import { GridService } from '../../../services/grid.service';
import { RoleTypeEnum } from '../../../dtos/role-type.enum';

@Component({
  selector: 'js-lead-management',
  templateUrl: './lead-management.component.html',
  styleUrls: ['./lead-management.component.scss']
})
export class LeadManagementComponent implements OnInit, OnDestroy {
  @Input() jobId: number;
  @Input() isHubSpotActive: boolean;
  @Input() divisionId: number;

  loadingData = true;
  loading = true;
  subscriptions: Subscription[] = [];
  deals: LeadManagementDeal[];
  dataSource: CustomStore;
  selectedRecord = '';
  gridHeight: number;
  descWidth: number;
  jobIntegrationId: string;
  salesRepDescription: string;

  constructor(
    private activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private integrationService: IntegrationService,
    private notiService: NotificationService,
    private jobService: JobService,
    public gridService: GridService) { }

  ngOnInit() {
    this.gridHeight = window.innerHeight - 300;

    if (window.innerWidth > 1370) {
      this.descWidth = 500;
    } else {
      this.descWidth = window.innerWidth / 3;
    }
    this.loadDeals();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  loadDeals() {
    if (this.jobId) {
      this.subscriptions = this.subscriptions.concat(
        this.integrationService.getJobIntegrationData(this.jobId,
          this.isHubSpotActive ? IntegrationTypeEnum.HubSpot : IntegrationTypeEnum.ActiveCampaign).subscribe({
            next: (res) => {
              this.jobIntegrationId = res?.integrationId;
              this.selectedRecord = this.jobIntegrationId;
              this.salesRepDescription = this.jobService.companyRoles.find(i => i.roleId === RoleTypeEnum.SalesRep)?.companyRoleDescription;
              this.loadingData = false;
              this.setUpDataSet(true);
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loadingData = false;
            }
          })
      );
    } else {
      this.salesRepDescription = this.jobService.companyRoles?.find(i => i.roleId === RoleTypeEnum.SalesRep)?.companyRoleDescription;
      this.loadingData = false;
      this.setUpDataSet(true);
    }
  }

  setUpDataSet(useCache: boolean) {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.integrationService.getDeals(useCache, this.divisionId).subscribe({
            next: (res) => {
              this.loading = false;
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      });
  }

  refresh() {
    this.loading = true;
    this.setUpDataSet(false);
  }

  close() {
    this.activeModal.dismiss();
  }

  select(importData: boolean) {
    if (this.selectedRecord) {
      if (this.selectedRecord !== this.jobIntegrationId && this.jobId) {
        this.loadingData = true;
        this.subscriptions = this.subscriptions.concat(
          this.integrationService.updateJobIntegration(this.jobId,
            this.isHubSpotActive ? IntegrationTypeEnum.HubSpot : IntegrationTypeEnum.ActiveCampaign,
            this.selectedRecord).subscribe({
              next: () => {
                this.notiService.showSuccess('Connection stored');
                this.activeModal.close({ selectedRecord: this.selectedRecord, importData: importData });
              },
              error: (err) => {
                this.notiService.notify(err);
                this.loadingData = false;
              }
            })
        );
      } else {
        this.activeModal.close({ selectedRecord: this.selectedRecord, importData: importData });
      }
    }
  }
}
