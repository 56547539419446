<div class="modal-header">
  <h2 class="modal-title">Move items to another variation</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <div *ngIf="!loading && !isOpenVariations ">
    There are no open variations to move items to. Please create a new variation.
  </div>

  <div *ngIf="!loading && isOpenVariations ">
    <span>
      Select variation to move items to:
    </span>
    <span ngbDropdown class="voClass">
      <button class="btn-sm btn-outline-primary dropdownclass2" id="dropdownBasic1" ngbDropdownToggle>
        {{variationString}}<span *ngIf="variationString === ''" class='red'>Please select a variation...</span>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdownmenuclass">
        <!-- until service returns only the ones required test for Ids equal -->
        <div *ngFor="let varn of openVariations">
          <button class="dropdown-item" (click)='setVariationNum(varn)'>
            {{varn.displayedVariationNumber}}: {{varn.title}}
          </button>
        </div>
      </div>
    </span>

    <br><br>

    <div>
      Select items to move:
    </div>

    <div class="treeclass">
      <tree-root #tree [focused]="true" [nodes]="treeNodes" [options]="treeOptions" (initialized)="onTreeInit(tree)">
        <ng-template #treeNodeTemplate let-node="node" let-index="index">
          <!-- <input type="checkbox" [checked]="node.isActive" (change)="node.toggleActivated(true);nodeClicked(node, $event)" /> -->
          <input (change)="treeNodeClicked(tree, node)" type="checkbox" id="check-{{node.data.id}}"
            [(checked)]="node.data.checked">
          <span class="variationItemNumberClass">{{node.data.variationItemNumber}}</span>
          {{ node.data.description }} {{ node.data.itemTypeId === itemTypeEnum.Heading ? '' : node.data.isChecked ?
          '(checked)' : '(unchecked)' }}
        </ng-template>

      </tree-root>
    </div>
  </div>

  <!-- Loading Spinner -->
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>
</div>

<div class="modal-footer">
  {{numItemsToMove}} items to move
  <button type="button" class="btn btn-sm btn-secondary ms-2" (click)="selectAll(tree)" [disabled]="loading">Select
    All</button>
  <button type="button" class="btn btn-sm btn-secondary" (click)="deselectAll(tree)" [disabled]="loading">Unselect
    All</button>
  <button type="button" class="btn btn-sm btn-primary" (click)="moveItems()"
    [disabled]="loading || !variationToId || numItemsToMove === 0">Move</button>
</div>
