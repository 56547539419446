import { AuthService } from './../auth.service';
import { VariationService } from './variation.service';
import { JobService } from './job.service';
import { ClaimsService } from './claims.service';
import { CompanyService } from './company.service';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';
import { VariationSplit } from '../../dtos/variation-split';
import { Variation } from '../../dtos/variation';

@Injectable({
  providedIn: 'root'
})
export class VariationSplitsService {
  variationSplitsForJob: VariationSplit[];

  constructor(
    private _http: HttpClient,
    private _authService: AuthService,
    private httpService: HttpService,
    private companyService: CompanyService,
    private jobService: JobService,
    private claimsService: ClaimsService,
    private variationService: VariationService,
    private globalService: GlobalService) { }


  getVariationSplitsData(jobNumber: string, variationId: number, jobId: number): Observable<VariationSplit[]> {
    return forkJoin(
      [
        this.getVariationSplits(jobNumber, variationId),
        this.companyService.getCompanyActivities(),
        this.claimsService.getJobClaims(jobId)
      ]
    )
      .pipe(map(
        ([jobs]) => {
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getClaimsData(jobId: number): Observable<number> {
    return forkJoin(
      [
        this.jobService.getTotalContractValue(),
        this.getVariationSplitsForJob(jobId),
        this.companyService.getCompanyActivities(true, false),
        this.variationService.getVariations()
      ]
    )
      .pipe(map(
        ([jobs]) => {
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getVariationSplits(jobNumber: string, variationId: number): Observable<VariationSplit[]> {
    if (this._authService.isClient() || this._authService.isAssociate()) {
      this.variationSplitsForJob = [];
      return of(this.variationSplitsForJob);
    }
    let url = this.globalService.getApiUrl();
    url += '/variation-splits?jobNumber=' + jobNumber + '&variationId=' + variationId;
    return this._http.get<VariationSplit[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.httpService.handleError));
  }

  getVariationSplitsForJob(jobId: number): Observable<VariationSplit[]> {
    if (this._authService.isClient() || this._authService.isAssociate()) {
      this.variationSplitsForJob = [];
      return of(this.variationSplitsForJob);
    }
    const url = this.globalService.getApiUrl() + '/variation-splits/job/' + jobId;
    return this._http.get<VariationSplit[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.variationSplitsForJob = res;
      }),
      catchError(this.httpService.handleError));
  }

  addVariationSplit(itm: any): Observable<VariationSplit> {
    const url = this.globalService.getApiUrl() + '/variation-splits';
    return this._http.post<VariationSplit>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  updateVariationSplit(id: string, itm: any) {
    const url = this.globalService.getApiUrl() + '/variation-splits/' + id;
    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteVariationSplit(itmId: string) {
    const url = this.globalService.getApiUrl() + '/variation-splits/' + itmId;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  moveVariationSplit(id: number, orderNumber: number) {
    const url = this.globalService.getApiUrl() + '/variation-splits/move/' + id
      + '?orderNumber=' + orderNumber;
    return this._http.patch(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }


  getVariationsWithSplits(jobNum: string, jobId: number): Observable<Variation[]> {
    return forkJoin(
      [
        this.variationService.getVariations(jobNum),
        this.getVariationSplitsForJob(jobId)
      ]
    )
      .pipe(map(
        ([data]) => {
          return data;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  // loan shortfall data
  getLoanShortfallData(jobId: number, jobNumber: string): Observable<number> {
    return forkJoin(
      [
        this.jobService.getTotalContractValue(),
        this.variationService.getVariations(jobNumber),
        this.claimsService.getJobClaims(jobId),
        this.getVariationSplitsForJob(jobId),
        this.companyService.getCompanyActivities()
      ]
    )
      .pipe(map(
        ([jobVal, variations]) => {
          this.variationService.currentVariations = variations;
          return jobVal;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }
}
