import { Component, OnInit, Input } from '@angular/core';
import { IJobItemAttachment } from '../dtos/job-item-attachment';
import { AttachmentTypeEnum } from '../dtos/attachment-type.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'js-image-window',
  templateUrl: './image-window.component.html',
  styleUrls: ['./image-window.component.scss']
})
export class ImageWindowComponent implements OnInit {
  @Input() attachment: Blob;
  @Input() attachmentTypeId: number;

  attachmentTypeEnum = AttachmentTypeEnum;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  close() {
    this.activeModal.close();
  }

}
