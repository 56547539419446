import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { VariationService } from '../services/felixApi/variation.service';
import { Variation } from '../dtos/variation';
import { VariationStatusEnum } from '../dtos/variation-status.enum';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'js-select-variation',
  templateUrl: './select-variation.component.html',
  styleUrls: ['./select-variation.component.scss']
})
export class SelectVariationComponent implements OnInit {
  @Input() variationNumber: number;
  // event to emit the valid variation to the parent component
  @Output() variation: EventEmitter<Variation> =
    new EventEmitter<Variation>();

  COMPONENT_NAME = 'select-variation';

  errorMessage = '';
  variationFound: Variation;
  variationString = 'Please Select a Variation...';
  trimmedTitle = '';
  trimmedLength = 33;
  haveValidVariation = false;
  updatedVariations: Variation[] = [];
  haveCheckedForVariations = false;
  anyVariations = false;
  openVariations = false; // any open variations? warn user
  variationStatusEnum = VariationStatusEnum;
  isAssociate: boolean; // indicates if this use is an associate
  salesReadPermission: boolean;
  preContractReadPermission: boolean;
  postContractReadPermission: boolean;
  permissionLevel: string;

  constructor(private _variationService: VariationService,
    private _authService: AuthService) { }

  ngOnInit() {
    this.isAssociate = this._authService.isAssociate();
    this.calcPermission();
    this.getVariations();
  }

  getVariations() {
    // we get all the variations for this job number so we can display in the dropdown
    this.haveCheckedForVariations = false;
    this.openVariations = false;

    if (!this._variationService.currentVariations.length) {
      // No variations found for given job
      this.errorMessage = '';
      this.anyVariations = false;
    } else {
      this.anyVariations = true;
      this.haveCheckedForVariations = true;

      // un-approved VOs are at the top
      this._variationService.currentVariations.forEach(variation => {
        if ((variation.variationType < 10 && this.postContractReadPermission)
          || (variation.variationType > 10 && variation.variationType < 20 && this.salesReadPermission)
          || (variation.variationType > 20 && this.preContractReadPermission)) {
          if (this.variationNumber && variation.variationNumber === this.variationNumber) {
            // found the current one
            this.setVariationNum(variation, false);
          }
          // if this VO is open then we want it at the top so get these ones
          if (variation.statusId < this.variationStatusEnum.Cancelled) {
            this.updatedVariations = this.updatedVariations.concat(variation);
          }
        }
      });

      // now look for approved ones to have at the end
      let foundFirst = false;

      this._variationService.currentVariations.forEach(variation => {
        if ((variation.variationType < 10 && this.postContractReadPermission)
          || (variation.variationType > 10 && variation.variationType < 20 && this.salesReadPermission)
          || (variation.variationType > 20 && this.preContractReadPermission)) {
          if (variation.statusId >= this.variationStatusEnum.Cancelled) {
            // first add a break
            if (!foundFirst) {
              this.updatedVariations = this.updatedVariations.concat((new Variation(0, '', 0))); // dummy record
              foundFirst = true;
            }
            this.updatedVariations = this.updatedVariations.concat(variation);
          }
        }
      });
    }
  }

  calcPermission() {
    if (this._authService.isAdminOrSuperUser()) {
      this.salesReadPermission = true;
      this.preContractReadPermission = true;
      this.postContractReadPermission = true;
    } else if (this._authService.isClient()) {
      this.salesReadPermission = true;
      this.preContractReadPermission = true;
      this.postContractReadPermission = true;
    } else if (this._authService.isAssociate()) {
      this.preContractReadPermission = true;
      this.postContractReadPermission = true;
    } else {

      // Sales Permission
      this.permissionLevel = this._authService.getSelectionsPermissions('SalesVariations');
      if (this.permissionLevel === 'Admin' || this.permissionLevel === 'Read' || this.permissionLevel === 'Write') {
        this.salesReadPermission = true;
      }

      // Pre-Contract Permission
      this.permissionLevel = this._authService.getSelectionsPermissions('PreContractVariations');
      if (this.permissionLevel === 'Admin' || this.permissionLevel === 'Read' || this.permissionLevel === 'Write') {
        this.preContractReadPermission = true;
      }

      // Post-Contract Permission
      this.permissionLevel = this._authService.getSelectionsPermissions('Variations');
      if (this.permissionLevel === 'Admin' || this.permissionLevel === 'Read' || this.permissionLevel === 'Write') {
        this.postContractReadPermission = true;
      }
    }
  }

  setVariationNum(varn, emitResult: boolean) {
    // read the variation
    let variationStatus = '';

    if (varn.statusId === this.variationStatusEnum.Open) {
      variationStatus = ' (Open)';
    }
    if (varn.statusId === this.variationStatusEnum.Estimating) {
      variationStatus = ' (Estimating)';
    }
    if (varn.statusId === this.variationStatusEnum.PendingApproval) {
      variationStatus = ' (Pending Approval)';
    }
    if (varn.statusId === this.variationStatusEnum.Cancelled) {
      variationStatus = ' (Cancelled)';
    }
    if (varn.statusId === this.variationStatusEnum.Approved) {
      variationStatus = ' (Approved)';
    }
    if (varn.statusId === this.variationStatusEnum.Actioned) {
      variationStatus = ' (Actioned)';
    }
    if (varn.statusId === this.variationStatusEnum.Invoiced) {
      variationStatus = ' (Invoiced)';
    }

    if (varn.title.length > this.trimmedLength) {
      this.trimmedTitle = varn.title.substring(0, this.trimmedLength - 3) + '...';
    } else {
      this.trimmedTitle = varn.title;
    }

    this.variationString = varn.displayedVariationNumber + ': ' + this.trimmedTitle + variationStatus;
    this.variationNumber = varn.variationNumber;
    this.haveValidVariation = true;

    if (emitResult) {
      this.variation.emit(varn);
    }
  }
}
