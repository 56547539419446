<div class="modal-header">
  <h4 class="modal-title">{{modalHeading}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form *ngIf="!submitLoading && !loading" [formGroup]="documentForm">

  <div class="modal-body">
    <!-- first use a radio button to select the type of line -->
    <div *ngIf="(modalButton === 'Add') && !(filesToUpload && filesToUpload.length)">
      <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic">
        <label ngbButtonLabel class="btn btn-sm btn-primary">
          <input ngbButton type="radio" [value]="jobDocumentRecordTypeEnum.Heading" formControlName="recordTypeId">
          Heading
        </label>

        <label ngbButtonLabel class="btn btn-sm btn-primary">
          <input ngbButton type="radio" [value]="jobDocumentRecordTypeEnum.Detail" formControlName="recordTypeId">
          Detail
        </label>

        <label ngbButtonLabel class="btn btn-sm btn-primary">
          <input ngbButton type="radio" [value]="jobDocumentRecordTypeEnum.ImportMultipleFiles"
            formControlName="recordTypeId">
          Import Multiple Files
        </label>

        <label *ngIf="!parentId" ngbButtonLabel class="btn btn-sm btn-primary">
          <input ngbButton type="radio" [value]="jobDocumentRecordTypeEnum.Copy" formControlName="recordTypeId">
          Copy
        </label>
      </div>
      <br><br>
    </div>

    <div
      *ngIf="documentForm.get('recordTypeId').value !== 0 && documentForm.get('recordTypeId').value !== jobDocumentRecordTypeEnum.Copy">
      <div *ngIf="documentForm.get('recordTypeId').value === jobDocumentRecordTypeEnum.Heading">
        <label class="form-label" for="description">Description:</label>
        <input type="text" class="form-control" name="description" id="description" formControlName="description">
      </div>
      <div *ngIf="documentForm.get('recordTypeId').value === jobDocumentRecordTypeEnum.Detail">
        <label class="form-label" for="description">Description:</label>
        <textarea class="form-control noteEntryClass" name="description" id="description"
          formControlName="description"></textarea>
      </div>

      <div *ngIf="documentForm.get('recordTypeId').value === jobDocumentRecordTypeEnum.ImportMultipleFiles">
        <label class="form-label" for="description">Description: (if blank the file name will be used)</label>
        <input type="text" class="form-control" name="description" id="description" formControlName="description">
      </div>
      <br>

      <label class="form-label" for="jobDocumentTypeId" class="typeStatusLabel">Type:</label>
      <select name="jobDocumentTypeId" id="jobDocumentTypeId" formControlName="jobDocumentTypeId"
        class="typeStatusSelect" (change)="setIsStatusRequired($event.target.value)">
        <option [value]="0">Default</option>
        <option *ngFor="let jobDocumentType of jobDocumentTypes" [value]="jobDocumentType.id">
          {{jobDocumentType.description}}
        </option>
      </select>

      <div *ngIf='isStatusRequired' class="mt-2">
        <label class="form-label" for="jobDocumentStatusId" class="typeStatusLabel">Status:</label>
        <select name="jobDocumentStatusId" id="jobDocumentStatusId" formControlName="jobDocumentStatusId"
          class="typeStatusSelect">
          <option *ngFor="let jobDocumentStatus of jobDocumentStatuses" [value]="jobDocumentStatus.id">
            {{jobDocumentStatus.description}}
          </option>
        </select>
      </div>

      <div *ngIf='isTrackingActive' class="mt-2">
        <label class="form-label" for="callUpDocsTypeId" class="typeStatusLabel">Call-Up Type:</label>
        <select name="callUpDocsTypeId" id="callUpDocsTypeId" formControlName="callUpDocsTypeId"
          class="typeStatusSelect">
          <option *ngFor="let callUpDocsType of callUpDocsTypes" [value]="callUpDocsType.id">
            <span *ngIf="parentId || callUpDocsType.id">{{callUpDocsType.description}}</span>
            <span *ngIf="!parentId && !callUpDocsType.id"></span>
          </option>
        </select>
      </div>

      <br>

      <label class="form-label">Trade Viewable:&nbsp;</label>
      <mat-checkbox formControlName="isTradeViewable"></mat-checkbox>
      &nbsp;&nbsp;
      <label class="form-label">Customer Viewable:&nbsp;</label>
      <mat-checkbox formControlName="isCustomerViewable"></mat-checkbox>
      <br>
    </div>

    <div *ngIf="documentForm.get('recordTypeId').value === jobDocumentRecordTypeEnum.Detail">
      <js-loading-spinner *ngIf="modalImageLoading"></js-loading-spinner>

      <dx-file-uploader *ngIf="!imageFound && !modalImageLoading"
        [ngClass]="wrongFileType ? 'red-faint-back' : 'b-blue-faint-back'" [value]="filesToUpload" uploadMode="useForm"
        selectButtonText="Select file" labelText="or drop file here" (onValueChanged)="handleFileInput($event)">
      </dx-file-uploader>
      <div *ngIf="wrongFileType" style="color: red">{{wrongFileTypeErrorMessage}}</div>

      <img *ngIf="imageFound && jobDocAttachment?.attachmentTypeId === attachmentTypeEnum.JPEG"
        [src]="'data:image/jpeg;base64,'+jobDocAttachment.attachment" />

      <img *ngIf="imageFound && jobDocAttachment?.attachmentTypeId === attachmentTypeEnum.PNG"
        [src]="'data:image/png;base64,'+jobDocAttachment.attachment" />

      <button *ngIf="jobDocument?.attachmentTypeId && jobDocument.attachmentTypeId === attachmentTypeEnum.PDF"
        class="btn btn-sm btn-outline-primary mt-2 me-2" (click)='showImage()'>
        Open PDF
      </button>

      <button *ngIf="imageFound && (modalButton === 'Update') && !jobDocument.isDeleted" type="button"
        class="btn btn-sm btn-outline-danger mt-2 me-2" (click)="updateImage()">
        Update File/Image
      </button>

      <button *ngIf="jobDocument?.attachmentTypeId && jobDocument.attachmentTypeId >= attachmentTypeEnum.DOCX
         && jobDocument.attachmentTypeId <= attachmentTypeEnum.DXF" class="btn btn-sm btn-outline-primary mt-2 me-2"
        (click)='showImage()'>
        Download file
      </button>

      <button *ngIf="hasVersions && isInfoAdmin" class="btn btn-sm btn-outline-secondary mt-2" (click)='showVersions()'>
        Versions
      </button>
    </div>

    <div *ngIf="jobDocument && jobDocument.modifiedDate" class="lastUpdated">Last Updated:
      {{modifiedUser}} - {{jobDocument.modifiedDate | date}}</div>

    <div *ngIf="documentForm.get('recordTypeId').value === jobDocumentRecordTypeEnum.ImportMultipleFiles">
      <js-loading-spinner *ngIf="modalImageLoading"></js-loading-spinner>
      <br><br>
      <span style="color: grey; font-size: 0.8rem">Hold control and click or use shift and arrow keys to select multiple
        files</span>
      <dx-file-uploader *ngIf="!modalImageLoading" class="uploadClass"
        [ngClass]="wrongFileType ? 'red-faint-back' : 'b-blue-faint-back'" [multiple]="true" [value]="filesToUpload"
        uploadMode="useForm" selectButtonText="Select files" labelText="or drop files here"
        (onValueChanged)="handleFileInput($event)">
      </dx-file-uploader>
      <div *ngIf="wrongFileType" style="color: red">{{wrongFileTypeErrorMessage}}</div>
    </div>


    <!-- COPY -->
    <div *ngIf="documentForm.get('recordTypeId').value === jobDocumentRecordTypeEnum.Copy">

      <js-job-number-small [jobNumber]="jobNumber" [saveJobNumber]="false" [defaultToTemplatesOnSearch]="true"
        (refreshJobNumber)="refreshJobNumber($event)"></js-job-number-small>

      <js-loading-spinner *ngIf="loadingDocs"></js-loading-spinner>

      <div *ngIf="!loadingDocs && jobDocumentsForCopy && jobDocumentsForCopy.length">
        <dx-tree-list id="jobDocumentsTree" [dataSource]="jobDocumentsForCopy" keyExpr="id" parentIdExpr="parentId"
          [showRowLines]="true" [showBorders]="true" [columnAutoWidth]="true" [(selectedRowKeys)]="selectedJobDocs"
          (onSelectionChanged)="attachmentSelectionChanged($event)">
          <dxo-selection mode="multiple" [recursive]="true"> </dxo-selection>
          <dxi-column dataField="description" caption=""></dxi-column>
        </dx-tree-list>

        <dx-button text="Copy" class="mt-1" type="default" (onClick)="runCopy()" [disabled]="!selectedJobDocs.length">
        </dx-button>
        <span class="ms-3 me-2">Insert Missing Records?</span>
        <dx-check-box [(value)]="insertMissing" [width]="30"> </dx-check-box>
      </div>
    </div>

    <div *ngIf="updateError" class="error">
      {{errorMessage}}
    </div>
  </div>

  <div
    *ngIf="documentForm.get('recordTypeId').value !== 0 && documentForm.get('recordTypeId').value !== jobDocumentRecordTypeEnum.Copy"
    class="modal-footer">

    <div *ngIf="deleteConfirmed">
      <button *ngIf="modalButton === 'Update'" type="button" class="btn btn-danger" (click)="delete()">
        Confirm Delete
      </button>
    </div>

    <div *ngIf="deleteImageConfirmed">
      <button *ngIf="imageFound && (modalButton === 'Update')" type="button" class="btn btn-outline-danger"
        (click)="deleteImage(false)">
        Confirm Delete File/Image
      </button>
    </div>

    <div *ngIf="!deleteConfirmed && !deleteImageConfirmed">
      <button *ngIf="imageFound && (modalButton === 'Update') && !jobDocument.isDeleted" type="button"
        class="btn btn-sm btn-outline-danger" (click)="deleteImage(false)">
        Delete File/Image
      </button>
      <button *ngIf="modalButton === 'Update' && !jobDocument.isDeleted" type="button"
        class="btn btn-sm btn-danger ms-1" (click)="delete()">
        Delete
      </button>
      <button *ngIf="modalButton === 'Update' && jobDocument.isDeleted" type="button" class="btn btn-sm btn-danger ms-1"
        (click)="unDelete()">
        Un-Delete
      </button>

      <span *ngIf="documentForm.get('recordTypeId').value === jobDocumentRecordTypeEnum.ImportMultipleFiles">
        <label class="form-label">Overwrite existing records:&nbsp;</label>
        <mat-checkbox formControlName="isOverWrite"></mat-checkbox>
        &nbsp;&nbsp;&nbsp;
      </span>

      <button type="button" class="btn btn-sm btn-primary ms-2" (click)="submit()"
        [disabled]="wrongFileType || (!documentForm.valid && documentForm.get('recordTypeId').value !== jobDocumentRecordTypeEnum.ImportMultipleFiles)">
        {{modalButton}}
      </button>
    </div>

  </div>
</form>

<js-loading-spinner *ngIf="submitLoading || loading"></js-loading-spinner>
<div *ngIf="submitLoading && docsLeftToLoad > 0">Files still to upload: {{docsLeftToLoad}}</div>
