import { Pipe, PipeTransform } from '@angular/core';
import { IAddress } from '../dtos/address';

@Pipe({
  name: 'addressToString'
})
export class AddressToStringPipe implements PipeTransform {
  addressString: string;

  transform(address: IAddress, isJobAddress: string): string {
    this.addressString = '';
    if (address) {

      if (address.lotNumber) {
        this.addressString = 'Lot ' + address.lotNumber;
      }
      if (address.streetNumber) {
        this.addressString = this.addressString + ' #' + address.streetNumber;
      }
      if (address.streetName1) {
        this.addressString = this.addressString + ' ' + address.streetName1;
      }
      if (address.streetName2) {
        this.addressString = this.addressString + ' ' + address.streetName2;
      }
      if (address.suburbTown) {
        this.addressString = this.addressString + ' ' + address.suburbTown;
      }
      if (address.state) {
        this.addressString = this.addressString + ' ' + address.state;
      }

    }
    return this.addressString;
  }

}
