import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { JobVarItemService } from '../../../services/felixApi/job-var-item.service';
import { JobItemService } from '../../../services/felixApi/job-item.service';
import { IJobItemWithChildren } from '../../../dtos/job-item-with-children';
import { ItemTypeEnum } from '../../../dtos/item-type.enum';
import { ChangeTypeEnum } from '../../../dtos/change-type.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TreeComponent } from '@circlon/angular-tree-component';
import { NotificationService } from '../../../services/notification.service';
import { IJobItem } from '../../../dtos/job-item';
import { VariationService } from '../../../services/felixApi/variation.service';
import { JobVarTypeEnum } from '../../../dtos/job-var-type.enum';
import { HideSection } from '../../../dtos/hide-section';

@Component({
  selector: 'js-move-item',
  templateUrl: './move-item.component.html',
  styleUrls: ['./move-item.component.scss']
})
export class MoveItemComponent implements OnInit, OnDestroy {
  @Input() jobItem: IJobItem;

  @ViewChild('tree3') tree: TreeComponent;

  subscriptions: Subscription[] = [];
  oneNode: IJobItemWithChildren = new IJobItemWithChildren;
  treeNodes: IJobItemWithChildren[] = [];
  itemTypeEnum = ItemTypeEnum;
  changeTypeEnum = ChangeTypeEnum;
  jobVarTypeEnum = JobVarTypeEnum;
  id: number;
  loading = true;
  errorMessage: string;
  itemToAddAfter: IJobItemWithChildren;
  addAsChild = true;
  copyTreeOptions: {};
  hiddenSection: HideSection;
  previousItemType: number;
  treeHeight: number;

  constructor(private _activeModal: NgbActiveModal,
    private _jobItemService: JobItemService,
    private notiService: NotificationService,
    private variationService: VariationService,
    private _jobVarItemService: JobVarItemService) { }

  ngOnInit() {
    this.treeHeight = window.innerHeight - 300;
    this.treeNodes = [];

    this.copyTreeOptions = {
      idField: 'id',
      displayField: 'description',
      childrenField: 'children',
      useVirtualScroll: true,
      nodeHeight: 22,
      scrollOnActivate: false
    };
    this._jobItemService.currentJobItemsUnfiltered.forEach(item => {

      if (!item.jobItemAboveId && !item.isHistoryRecord && !item.isDeleted && !item.isSetUpLine) {

        if (item.itemTypeId === this.itemTypeEnum.Heading) {
          // exclude hidden sections
          this.hiddenSection = this.variationService.hiddenSectionsInVariation
            .find(i => item.jobItemAboveTypeId !== this.jobVarTypeEnum.JobVarItem && i.jobItemId === item.id);

          if (!this.hiddenSection) {
            this.oneNode.children = this._jobItemService.setupJobItemNodesChildren(item.originalItemTypeId,
              item.id, this._jobItemService.currentJobItemsUnfiltered, null);
          }
        } else {
          this.hiddenSection = null;
          this.oneNode.children = [];
        }

        if (!this.hiddenSection) {
          if (!item.itemDescription || item.itemDescription === '') {
            this.oneNode.description = item.selection;
          } else if (item.selection) {
            this.oneNode.description = item.itemDescription + ' - ' + item.selection;
          } else {
            this.oneNode.description = item.itemDescription;
          }

          // if the item has not been changed use the original id
          if (item.changedByJobVarId && item.changedByJobVarId !== 0) {
            this.id = item.changedByJobVarId;
          } else {
            this.id = item.id;
          }

          this.treeNodes.push({
            id: this.id,
            itemTypeId: item.itemTypeId,
            changeTypeId: item.changeTypeId,
            jobVarTypeId: item.jobVarTypeId,
            itemChangedId: item.itemChangedId,
            description: this.oneNode.description,
            originalItemTypeId: item.originalItemTypeId,
            originalItemId: item.id,
            connectedItemId: item.connectedItemId,
            children: this.oneNode.children,
            parentId: null,
            changedByVOId: item.changedByVOId,
            changedByJobVarId: item.changedByJobVarId,
            masterItemId: item.masterItemId,
            hasLinkedItems: item.hasLinkedItems,
            optionNumber: item.optionNumber,
            checked: false,
            indeterminate: false,
            isChecked: item.isChecked,
            variationItemNumber: item.variationItemNumber
          });
        }
      }
    });
    this.loading = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  cancelWithNo() {
    this._activeModal.dismiss();
  }

  onItemTreeInit(tree: TreeComponent) {
    tree.treeModel.virtualScroll.setViewport(tree.treeModel.virtualScroll.viewport);
  }

  selectItemToAddAfter(item: IJobItemWithChildren) {
    if (item.hasLinkedItems) {
      this.notiService.showInfo('Cannot select this item as it has linked items');
    } else {
      this.itemToAddAfter = item;
    }
  }

  runMove() {
    // move the item
    this.loading = true;

    let jobItemId = this.itemToAddAfter.changedByJobVarId ? null : this.itemToAddAfter.id;
    let jobVarItemId = this.itemToAddAfter.changedByJobVarId ? this.itemToAddAfter.changedByJobVarId : null;

    if (this.addAsChild && this.itemToAddAfter.itemTypeId === this.itemTypeEnum.Heading
      && this.itemToAddAfter.changedByVOId === this.jobItem.changedByVOId
      && this.itemToAddAfter.changeTypeId === ChangeTypeEnum.Change) {
      // we have changed the heading so we use the original
      if (this.itemToAddAfter.jobVarTypeId === JobVarTypeEnum.JobItem) {
        jobItemId = this.itemToAddAfter.itemChangedId;
        jobVarItemId = null;
      } else {
        jobItemId = null;
        jobVarItemId = this.itemToAddAfter.itemChangedId;
      }
    }
    this.subscriptions = this.subscriptions.concat(
      this._jobVarItemService.moveJobVarItem(this.jobItem.changedByJobVarId, jobItemId, jobVarItemId, this.addAsChild).subscribe({
        next: () => {
          this._jobItemService.setWaitingJobItemAPI(true); // tell the service to tell all other components that an API is pending
          this._activeModal.close();
        },
        error: (err) => {
          this.notiService.notify(err);
          if (err.status === 422) {
            if (err.error && err.error.message) {
              this.errorMessage = err.error.message;
            }
          }
          this.loading = false;
        }
      })
    );
  }
}
