<!-- Add attachments -->
<dx-popup class="popup" [width]="popupWidth" [height]="popupHeight" [showTitle]="true" title="Save File"
  [dragEnabled]="false" [hideOnOutsideClick]="true" [visible]="true" (onHidden)="close()" [shading]="false"
  [showCloseButton]="true">
  <div *dxTemplate="let data of 'content'">
    <js-loading-spinner *ngIf="loadingDocs"></js-loading-spinner>

    FileName:
    <dx-text-box type="text" [(value)]="saveDescription"></dx-text-box>
    <br>

    <dx-scroll-view *ngIf="!loadingDocs" width="100%" [height]="popupHeight - 100">
      <dx-tree-list id="jobDocumentsTree" [dataSource]="jobDocuments" keyExpr="keyId" [height]="popupHeight - 180"
        parentIdExpr="parentKeyId" [showRowLines]="true" [showBorders]="true" [columnAutoWidth]="false"
        [(selectedRowKeys)]="selectedFolder" (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate')">

        <dxo-search-panel [visible]="true" width="200"></dxo-search-panel>
        <dxo-selection mode="single"> </dxo-selection>

        <dxi-column dataField="description" caption="Save to:" [allowSorting]="false"></dxi-column>

        <div *dxTemplate="let data of 'toolbarTemplate'">
          <dx-button *ngIf="selectedFolder && selectedFolder.length" text="Save" type="default" (onClick)="save()">
          </dx-button>
        </div>
      </dx-tree-list>
    </dx-scroll-view>
  </div>
</dx-popup>
