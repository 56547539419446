<div class="modal-header">
  <h2 class="modal-title">Select categories</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <input type="text" class="form-control" [(ngModel)]="query" placeholder="filter..." #search ngbAutoFocus>
  <br>

  <div class="card">
    <!-- <div class="card-header header">
      <span class="col1class">Select</span>Category
    </div> -->

    <div class="card-body scroll">

      <div *ngIf='!loading && categories && categories.length === 0' class="noCats">
        <span class="noCatText">
            <p>No Categories have been configured.</p>
            <p>Add and edit Categories from the Configuration App.</p>
            <button class="btn btn-primary" (click)="launchConfigApp()">Launch Config App</button>
        </span>
      </div>

      <js-loading-spinner *ngIf="loading"></js-loading-spinner>

      <table *ngIf='!loading && categories && categories.length' class="table table-sm">
        <!---thead class="rowclass">
          <tr>
            <th scope="col" class="col1class">Select</th>
            <th scope="col" class="col2class">Category</th>
          </tr>
        </thead-->

        <tbody class="rowclass">
          <tr *ngFor='let category of categories | filterPipe: filterCats' class="rowclass">

            <ng-container>
              <td class="col1class">
                <mat-checkbox [(ngModel)]="category.checked" (click)="checkCategory(category)"></mat-checkbox>
              </td>
              <td>{{category.description}}</td>
            </ng-container>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-secondary" (click)="cancel()">Close</button>
</div>