<div class="modal-header">
  <h2 class="modal-title">{{modalHeading}}</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <!-- Loading Spinner -->
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <div *ngIf="!loading">

    <!-- first use a radio button to select the type of line -->
    <div *ngIf="(modalButton === 'Add')">
      <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic">
        <label ngbButtonLabel class="btn btn-sm btn-primary">
          <input ngbButton type="radio" [value]="ItemType.Heading" (click)="resetMasterType()"
            [(ngModel)]="optItemTypeId">
          Heading
        </label>

        <label *ngIf="!baseLevel" ngbButtonLabel class="btn btn-sm btn-primary">
          <input ngbButton type="radio" [value]="ItemType.Detail" (click)="resetTreeForLinkingItems()"
            [(ngModel)]="optItemTypeId">
          Detail
        </label>

        <label *ngIf="!baseLevel" ngbButtonLabel class="btn btn-sm btn-primary">
          <input ngbButton type="radio" [value]="ItemType.Note" (click)="resetMasterType()" [(ngModel)]="optItemTypeId">
          Note
        </label>

        <label ngbButtonLabel class="btn btn-sm btn-primary">
          <input ngbButton type="radio" [value]="ItemType.Copy" (click)="resetTreeForCopy()"
            [(ngModel)]="optItemTypeId">
          Copy
        </label>
      </div>
      <br>
    </div>

    <div *ngIf="optItemTypeId && optItemTypeId != ItemType.Copy">
      <!-- description is always set-->
      <div class="row mt-3">
        <label *ngIf="(optItemTypeId === ItemType.Detail)" class="form-label col-sm-2"
          for="optDescription">Description:*</label>
        <label *ngIf="(optItemTypeId !== ItemType.Detail)" class="form-label col-sm-2"
          for="optDescription">Description:</label>
        <input type="text" class="form-control-sm col-sm-6" name="optDescription" ngModel id="optDescription"
          [(ngModel)]="optDescription">
        <label class="form-label col-sm-3 righttext" for="editIsSetupLine">Job Setup Line:
          <input type="checkbox" name="editIsSetupLine" ngModel id="editIsSetupLine" [(ngModel)]="editIsSetupLine"
            class="marginleft15 margingTop5" tabindex="-1">
        </label>
      </div>

      <div *ngIf="(optItemTypeId === ItemType.Heading) && !editIsSetupLine" class="row mt-3">
        <label class="form-label col-sm-11 righttext" for="isDoNotPrint">Do NOT print on {{addendumName}}?
          <input class="marginleft15 marginTop5" type="checkbox" ngModel id="isDoNotPrint" name="isDoNotPrint" ngModel
            id="isDoNotPrint" [(ngModel)]="isDoNotPrint">
        </label>
      </div>

      <div *ngIf="(optItemTypeId === ItemType.Detail)">
        <div>
          <div class="mt-3">
            <b>Select type of selection field required:</b>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
              [(ngModel)]="detailOptionType" value="Checkbox" (click)="resetMasterType()">
            <label class="form-check-label" for="inlineRadio1">Checkbox</label>
          </div>

          <div class="form-check form-check-inline mb-3">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
              [(ngModel)]="detailOptionType" value="Use an Option List">
            <label class="form-check-label" for="inlineRadio1">Drop Down using an Option List</label>
          </div>

          <div class="form-check form-check-inline mb-3">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
              [(ngModel)]="detailOptionType" value="Link to another line">
            <label class="form-check-label" for="inlineRadio1">Linked to Another Selection</label>
          </div>
        </div>

        <div *ngIf="(detailOptionType === 'Use an Option List')">
          <!-- if we need to select an option list use a tree -->
          <div>
            <b>Select an Option List:</b>
          </div>

          <!-- Loading Spinner -->
          <js-loading-spinner *ngIf="!treeOptionNodesSetup"></js-loading-spinner>

          <div *ngIf="treeOptionNodesSetup">
            <input id="filter" #filter (keyup)="tree.treeModel.filterNodes(filter.value)" placeholder="filter..."
              class='mt-1 pb-1 pt-0 ms-2p' autocomplete="off" />
            <button class="btn btn-sm btn-secondary"
              (click)="$event.preventDefault(); $event.stopPropagation(); tree.treeModel.clearFilter(); tree.treeModel.collapseAll(); filter.value=''">
              Clear Filter
            </button>

            <button class="btn btn-sm btn-secondary ms-1"
              (click)="$event.preventDefault(); $event.stopPropagation(); refreshList();">
              Refresh
            </button>

            <div class="treeclass">
              <tree-root #tree [focused]="true" [nodes]="treeOptionNodes" [options]="treeOptionOptions"
                (focus)="setOptionList(tree.treeModel.getFocusedNode())" (initialized)="onOptionTreeInit(tree)"
                (updateData)="onOptionTreeInit(tree)"></tree-root>
            </div>
          </div>
        </div>

        <div *ngIf="(detailOptionType === 'Link to another line')">
          <!-- if we need to select another job item use a tree -->
          <div>
            <b>Select the job line to connect to:</b>
          </div>

          <!-- Loading Spinner -->
          <js-loading-spinner *ngIf="!treeJobItemNodesSetup"></js-loading-spinner>

          <div *ngIf="treeJobItemNodesSetup" class="treeclass">
            <tree-root #tree2 [focused]="true" [nodes]="treeJobItemNodes" [options]="treeJobItemOptions"
              (focus)="setLinkedItemId(tree2.treeModel.getFocusedNode().data.id)" (initialized)="onItemTreeInit(tree2)"
              (updateData)="onItemTreeInit(tree2)"></tree-root>
          </div>
        </div>

        <!-- checkbox to set/unset the canEnterManualSelection flag-->
        <div *ngIf="(detailOptionType !== 'Checkbox')" class="mb-1">
          <br>
          <label class="form-label" for="canEnterManualSelection">
            <b>Can a user enter their own value:</b>
          </label>
          <input type="checkbox" name="canEnterManualSelection" ngModel id="canEnterManualSelection"
            [(ngModel)]="canEnterManualSelection" class="ms-1">
        </div>
      </div>

      <!-- if Note then can change note stored in the selection field -->
      <div *ngIf="(optItemTypeId === ItemType.Note)">
        <div>
          <label class="form-label" for="optSelection">Note:</label>
          <textarea class="form-control noteEntryClass" name="optSelection" ngModel id="optSelection"
            [(ngModel)]="optSelection"></textarea>
        </div>
      </div>

      <!-- if note select colour and is bold -->
      <div *ngIf="(optItemTypeId === ItemType.Note)" class="row">
        <label class="form-label col-sm-2" for="selection">Text Colour:</label>
        <div class="form-control-sm col-sm-2">
          <!-- next is the selection item linked to the option list -->
          <div ngbDropdown>
            <button class="btn-sm btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle
              (click)="$event.preventDefault(); $event.stopPropagation();">{{optNoteColourName}}</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <div *ngFor="let colour of colours">
                <button class="dropdown-item"
                  (click)='$event.preventDefault(); setColour(colour.id)'>{{colour.name}}</button>
              </div>
            </div>
          </div>
        </div>

        <label class="form-label col-sm-2" for="optIsBoldNote">Bold Text:
          <input type="checkbox" name="optIsBoldNote" ngModel id="optIsBoldNote" [(ngModel)]="optIsBoldNote"
            class="marginleft5 margingTop10">
        </label>
        <label class="form-label col-sm-3" for="optIsItalicNote">Italic Text:
          <input type="checkbox" name="optIsItalicNote" ngModel id="optIsItalicNote" [(ngModel)]="optIsItalicNote"
            class="marginleft5 margingTop10">
        </label>
      </div>

      <div *ngIf="optItemTypeId === ItemType.Detail && detailOptionType === 'Use an Option List' && isQtyRequired"
        class="row mb-1">
        <label class="form-label col-sm-2" for="quantity">Quantity:</label>
        <input class="form-control-sm col-sm-2 ms-2 rightText" ngModel id="quantity" name="quantity"
          [(ngModel)]="quantity" type="number" autocomplete="off" />
      </div>

      <!-- Price - Provisional Sum if required -->
      <div *ngIf="(optItemTypeId === ItemType.Note || (optItemTypeId === ItemType.Detail && detailOptionType === 'Use an Option List' && optionListId))
      && !editIsSetupLine && !headingIsSetUpLine" class="row mb-1">
        <!-- Cost Type -->
        <label class="form-label col-sm-2">Master Type:</label>
        <div class="form-control-sm col-sm-2">
          <div ngbDropdown>
            <button class="btn-sm btn-outline-primary" id="dropdownBasicNew" ngbDropdownToggle
              (click)="$event.preventDefault();">{{costTypeDesc}}</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasicNew" class="dropdownmenuclass">
              <div *ngFor="let c of costTypeDropdown">
                <button class="dropdown-item" (click)='$event.preventDefault(); setCostType(+c.id)'>{{c.name}}</button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="costTypeId === costTypeEnum.Note" class="form-control-sm col-sm-4">
          <label class="form-label mt-1" for="isDoNotPrint">Hide Item from PDFs?
            <input type="checkbox" name="isDoNotPrint" ngModel id="isDoNotPrint" [(ngModel)]="isDoNotPrint"
              class="marginleft5">
          </label>
        </div>
      </div>

      <!-- now we do the provisional sum if set -->
      <div *ngIf="costTypeId === costTypeEnum.ProvisionalSum" class="row mb-1">
        <label class="form-label col-sm-2 pe-0" for="provisionalSum">
          <span *ngIf="isQtyRequired">PS Rate:</span>
          <span *ngIf="!isQtyRequired">Provisional Sum:</span>
        </label>
        <input class="form-control-sm col-sm-2 ms-2" ngModel id="provisionalSum" name="provisionalSum"
          [(ngModel)]="provisionalSum" currencyMask [options]="{ precision: 0, prefix: '' }" autocomplete="off" />
      </div>

      <div *ngIf="costTypeId === costTypeEnum.ProvisionalSum && isQtyRequired" class="row mb-1">
        <!-- If Qty we show a line total -->
        <!-- Provisional Sum: {{quantity * provisionalSum | number}} -->
        <label class="form-label col-sm-2 pe-0" for="provisionalSumTotal">Provisional Sum:</label>
        <input class="form-control-sm col-sm-2 ms-2" ngModel id="provisionalSumTotal" name="provisionalSumTotal"
          [ngModel]="quantity * provisionalSum" currencyMask [options]="{ precision: 0, prefix: '' }" readonly />
      </div>

      <div>
        <button type="button" class="btn btn-sm btn-primary" (click)="update()"
          [disabled]="optItemTypeId === ItemType.Detail && optDescription === ''">{{modalButton}}</button>
        <button *ngIf="(modalButton === 'Add')" type="button" class="btn btn-sm btn-primary modal2ndButton"
          (click)="addAnother()"
          [disabled]="optItemTypeId === ItemType.Detail && optDescription === ''">Add+Another</button>
        <!-- <button *ngIf="(optItemTypeId === ItemType.Heading) && !editIsSetupLine && !jobItemAboveId" type="button"
          class="btn btn-sm btn-primary modal2ndButton" (click)="setCategories()">Categories</button> -->
      </div>

    </div>

    <!-- copy job items -->
    <div *ngIf="optItemTypeId && optItemTypeId == ItemType.Copy">
      <br>
      <!-- from which job? -->
      <js-job-number-small [jobNumber]="jobNumber" [saveJobNumber]="false"
        (refreshJobNumber)="refreshJobNumber($event)">
      </js-job-number-small>

      <!-- select items -->
      <form *ngIf="treeJobItemNodesSetup">
        <div>
          <button type="button" class="btn btn-sm btn-primary mb-1" (click)="selectAllNodesForCopy()">Select
            All</button>
          <div>
            <input id="filter" #filter (keyup)="tree3.treeModel.filterNodes(filter.value)" placeholder="filter nodes" />
            <button
              (click)="$event.preventDefault(); $event.stopPropagation(); tree3.treeModel.clearFilter(); tree3.treeModel.collapseAll(); filter.value=''">Clear
              Filter</button>

            <div class="treeclass">
              <tree-root #tree3 [focused]="true" [nodes]="treeJobItemNodes" [options]="copyTreeOptions"
                (initialized)="onItemTreeInit(tree3)">
                <ng-template #treeNodeTemplate let-node="node" let-index="index">
                  <input (change)="copyTreeNodeClicked(node, $event)" type="checkbox" id="check-{{node.data.id}}"
                    [indeterminate]="node.data.indeterminate" [checked]="node.data.checked">
                  <span class="ms-2">{{ node.data.itemDescription }}</span>
                  <span *ngIf="node.data.itemDescription && node.data.selection">: </span>
                  <span>{{ node.data.selection }}</span>
                </ng-template>
              </tree-root>
            </div>
          </div>
        </div>

        <div>
          <button type="button" class="btn btn-sm btn-primary" (click)="runCopy()">Go</button>
        </div>

        <!-- Loading Spinner -->
        <js-loading-spinner *ngIf="!copyComplete"></js-loading-spinner>

      </form>
    </div>
  </div>
</div>
