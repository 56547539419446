import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';
import { Company } from '../dtos/company';
import { IJob } from '../dtos/job';
import { CompanyConfiguration } from '../dtos/company-configuration';
import { TruthEngineSession, SessionName } from '../dtos/session';
import { DESKTOP_SIZE, PHONE_SIZE, SML_PHONE_SIZE } from '../../config/variables';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {

  isDebugMode = false; // we can start in debug mode with ?debug=true

  private currentCompany: Company;
  companyConfiguration: CompanyConfiguration[] = [];
  companySelected = new EventEmitter<Company>();

  private currentJobNo: string;
  private currentJobItemAbove: number;
  private isGetChildren: boolean;
  jobstring = '';

  private areaSelected = '';
  areaChanged = new EventEmitter<string>();

  menuDisable = new EventEmitter<boolean>();

  innerWidth: number;
  innerWidthChanged = new EventEmitter<number>();
  innerHeight: number;
  innerHeightChanged = new EventEmitter<number>();
  phoneSize = false;
  smlPhoneSize = false;
  desktopSize = false;

  selectonsInnerWidth: number;
  selectonsInnerWidthChanged = new EventEmitter<number>();

  resetStorage = false; // used when we run the ?reset=true parameter
  isTrackingActive = false; // is the tracking system active
  isPurchaseOrdersActive: boolean;
  isPurchaseOrderSystemActive = false;
  isMobile: boolean;


  constructor() { }

  isHttps() {
    return window.location.protocol.startsWith('https') || window.location.hostname === 'localhost';
  }

  getApiUrl(): string {
    return environment.apiUrl;
  }

  getAppID() {
    return environment.appId;
  }

  setInnerWidth(width: number) {
    this.innerWidth = width;
    if (innerWidth <= PHONE_SIZE) {
      this.phoneSize = true;
    } else {
      this.phoneSize = false;
    }
    if (innerWidth <= SML_PHONE_SIZE) {
      this.smlPhoneSize = true;
    } else {
      this.smlPhoneSize = false;
    }
    if (innerWidth >= DESKTOP_SIZE) {
      this.desktopSize = true;
    } else {
      this.desktopSize = false;
    }
    this.innerWidthChanged.emit(this.innerWidth);
  }

  setSelectionsInnerWidth(width: number) {
    this.selectonsInnerWidth = width;
    this.selectonsInnerWidthChanged.emit(this.selectonsInnerWidth);
  }

  setInnerHeight(height: number) {
    this.innerHeight = height;
    this.innerHeightChanged.emit(this.innerHeight);
  }

  setAreaSelected(area) {
    this.areaSelected = area;
    this.setSessionAtt('areaSelected', area);
    this.areaChanged.emit(this.areaSelected);
  }

  isSalesVariationsActive() {
    return this.currentCompany.salesVariationsActive;
  }

  getSessionObject(): TruthEngineSession {
    if (sessionStorage.getItem(SessionName)) {
      return JSON.parse(sessionStorage.getItem(SessionName));
    } else {
      const session = new TruthEngineSession();
      sessionStorage.setItem(SessionName, JSON.stringify(session));
      return JSON.parse(sessionStorage.getItem(SessionName));
    }
  }

  setSessionAtt(att: string, obj: any) {
    const session = this.getSessionObject();
    session[att] = obj;
    const sessionString = JSON.stringify(session);
    sessionStorage.setItem(SessionName, sessionString);
  }

  returnError(err: any): string {
    console.log(JSON.stringify(err));
    if (err && err.error && err.error.message) {
      return JSON.stringify(err.error.message);
    } else if (err && err.error && err.error.detail) {
      return JSON.stringify(err.error.detail);
    } else if (err && err.error && err.error.Description && err.error.Description.length) {
      return JSON.stringify(err.error.Description[0]);
    } else {
      return JSON.stringify(err);
    }
  }


  ///  MOVE TO COMPANY SERVICE  ///
  setCurrentCompany(company: Company) {
    this.currentCompany = company;
    this.setSessionAtt('currentCompanyId', company.id);
    this.companySelected.emit(this.currentCompany);
  }

  getCurrentCompany(): Company {
    return this.currentCompany;
  }

  getAddendumName(): string {
    return this.currentCompany && this.currentCompany.addendumName && this.currentCompany.addendumName !== ''
      ? this.currentCompany.addendumName : 'Addendum';
  }

  clearCurrentCompany() {
    this.currentCompany = null;
  }

  isCompSet() {
    if (this.currentCompany) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentCompanyId(): string {
    if (this.currentCompany !== undefined && this.currentCompany.id !== undefined && this.currentCompany.id !== null) {
      return this.currentCompany.id.toString();
    }
    return '';
  }

  getCompanyConfigObject(id: number): CompanyConfiguration {
    return this.companyConfiguration.find(i => i.configurationId === id);
  }

  getCompanyConfigValue(id: number): number {
    return this.companyConfiguration.find(i => i.configurationId === id)?.configurationValue;
  }

  ///////////      MOVE BELOW TO RELEVANT SERVICES      \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

  setCurrentJob(job: string) {
    this.currentJobNo = job;
    // store the latest job number to be the default in long term storage as well as session
    localStorage.setItem('jobANNX-LastJobNumber', job);
    this.setSessionAtt('currentJobNo', job);
  }

  getCurrentJob(): string {
    if (this.currentJobNo !== undefined && this.currentJobNo !== null) {
      return this.currentJobNo;
    }
    // return the last used job number on this client as default
    const session = this.getSessionObject();
    if (session && session.currentJobNo) {
      this.currentJobNo = session.currentJobNo;
      return this.currentJobNo;
    }
    if (localStorage.getItem('jobANNX-LastJobNumber')) {
      this.currentJobNo = localStorage.getItem('jobANNX-LastJobNumber');
      if (this.currentJobNo !== undefined && this.currentJobNo !== null) {
        this.currentJobNo = this.currentJobNo;
        return this.currentJobNo;
      }
    }
    return '';
  }

  getShowHistoryOfChanges(): boolean {
    if (localStorage.getItem('jobANNX-showHistoryOfChanges')) {
      const showHistoryOfChanges = localStorage.getItem('jobANNX-showHistoryOfChanges');
      if (showHistoryOfChanges && showHistoryOfChanges === 'true') {
        return true;
      }
    }
    return false;
  }

  setShowHistoryOfChanges(showHistoryOfChanges: boolean) {
    if (showHistoryOfChanges) {
      localStorage.setItem('jobANNX-showHistoryOfChanges', 'true');
    } else {
      localStorage.setItem('jobANNX-showHistoryOfChanges', 'false');
    }
  }

  getShowVariationNumbering(): boolean {
    if (localStorage.getItem('jobANNX-showVariationNumbering')) {
      const showVariationNumbering = localStorage.getItem('jobANNX-showVariationNumbering');
      if (showVariationNumbering && showVariationNumbering === 'true') {
        return true;
      }
    }
    return false;
  }

  setShowVariationNumbering(showVariationNumbering: boolean) {
    if (showVariationNumbering) {
      localStorage.setItem('jobANNX-showVariationNumbering', 'true');
    } else {
      localStorage.setItem('jobANNX-showVariationNumbering', 'false');
    }
  }

  getJobString(job: IJob, includeName: boolean): string {
    this.jobstring = '';
    if (job?.contractName && includeName) {
      this.jobstring = job.contractName.trim() + ': ';
    }
    if (job?.jobAddress) {
      if (job.jobAddress.lotNumber && job.jobAddress.lotNumber.trim() !== '') {
        this.jobstring = this.jobstring.concat('Lot ' + job.jobAddress.lotNumber.trim());
      }
      if (job.jobAddress.streetNumber && job.jobAddress.streetNumber.trim() !== '') {
        this.jobstring = this.jobstring.concat(' #' + job.jobAddress.streetNumber.trim());
      }
      if (job.jobAddress.streetName1 && job.jobAddress.streetName1.trim() !== '') {
        this.jobstring = this.jobstring.concat(' ' + job.jobAddress.streetName1.trim());
      }
      if (job.jobAddress.streetName2 && job.jobAddress.streetName2.trim() !== '') {
        this.jobstring = this.jobstring.concat(' ' + job.jobAddress.streetName2.trim());
      }
      if (job.jobAddress.suburbTown && job.jobAddress.suburbTown.trim() !== '') {
        this.jobstring = this.jobstring.concat(' ' + job.jobAddress.suburbTown.trim());
      }
      if (job.jobAddress.postCode && job.jobAddress.postCode.trim() !== '') {
        this.jobstring = this.jobstring.concat(' ' + job.jobAddress.postCode.trim());
      }
    }
    return this.jobstring;
  }

  setCurrentJobItemAbove(jobid: number) {
    this.currentJobItemAbove = jobid;
    this.setSessionAtt('currentJobItemAbove', jobid);
  }

  getCurrentJobItemAbove(): string {
    if (this.currentJobItemAbove !== undefined && this.currentJobItemAbove !== null && this.currentJobItemAbove !== 0) {
      return this.currentJobItemAbove.toString();
    }
    return '';
  }

  setIsGetChildren(getChildren: boolean) {
    this.isGetChildren = getChildren;
    this.setSessionAtt('isGetChildren', getChildren);
  }

  getIsGetChildren(): string {
    if (this.isGetChildren === undefined || this.isGetChildren === null || this.isGetChildren === false) {
      return 'false';
    }
    return 'true';
  }

  getObservableAsPromise(observable: Observable<any>): any[] {
    // Converting subscribe to a Promise
    const promise = new Promise<any>((resolve, reject) => {
      observable.subscribe({
        next: (data) => {
          // Resolve the promise with the received data
          resolve(data);
        },
        error: (err) => {
          // Reject the promise with the encountered error
          reject(err);
        }});
    });

    // Using the promise
    promise.then((data) => {
      // Handle the resolved data
      return data;
    }).catch((error) => {
      return this.returnError(error);
    });

    return [];
  }

  base64ToArrayBuffer(base64, saveName: string): Blob {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    if (saveName.endsWith('.pdf') || !saveName.includes('.')) {
      return new Blob([bytes], { type: 'application/pdf' });
    } else if (saveName.toLowerCase().endsWith('.jpg')) {
      return new Blob([bytes], { type: 'image/jpg' });
    } else if (saveName.toLowerCase().endsWith('.png')) {
      return new Blob([bytes], { type: 'image/png' });
    } else if (saveName.toLowerCase().endsWith('.doc') || saveName.toLowerCase().endsWith('.docx')) {
      return new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    } else if (saveName.toLowerCase().endsWith('.xls') || saveName.toLowerCase().endsWith('.xlsx') || saveName.toLowerCase().endsWith('.xlsm')) {
      return new Blob([bytes], { type: 'application/vnd.ms-excel' });
    } else {
      return new Blob([bytes], { type: 'application/octet-stream' });
    }
  }
}
