<dx-popup *ngIf="usePopUp" [width]="200" [height]="200" [showTitle]="false"
  [dragEnabled]="false" [animation]="null" [deferRendering]="false" [hideOnOutsideClick]="false"
  [(visible)]="showSpinner" class="spinnerPopup" [shading]="false">
  <div *dxTemplate="let data of 'content'">
    <div id="floatingCirclesG">
      <div class="f_circleG" id="frotateG_01"></div>
      <div class="f_circleG" id="frotateG_02"></div>
      <div class="f_circleG" id="frotateG_03"></div>
      <div class="f_circleG" id="frotateG_04"></div>
      <div class="f_circleG" id="frotateG_05"></div>
      <div class="f_circleG" id="frotateG_06"></div>
      <div class="f_circleG" id="frotateG_07"></div>
      <div class="f_circleG" id="frotateG_08"></div>
    </div>
  </div>
</dx-popup>

<!-- If we don't want to use the popup to centre the spinner on the page -->
<div *ngIf="!usePopUp && showSpinner">
  <div id="floatingCirclesG">
    <div class="f_circleG" id="frotateG_01"></div>
    <div class="f_circleG" id="frotateG_02"></div>
    <div class="f_circleG" id="frotateG_03"></div>
    <div class="f_circleG" id="frotateG_04"></div>
    <div class="f_circleG" id="frotateG_05"></div>
    <div class="f_circleG" id="frotateG_06"></div>
    <div class="f_circleG" id="frotateG_07"></div>
    <div class="f_circleG" id="frotateG_08"></div>
  </div>
</div>
