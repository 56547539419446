import { UserService } from './../../../services/felixApi/user.service';
import { GlobalService } from './../../../services/global.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../../services/notification.service';
import { JobDocumentAttachmentService } from '../../../services/felixApi/job-document-attachment.service';
import { JobDocAttachmentVersion } from '../../../dtos/job-doc-attachment-version';
import DataSource from 'devextreme/data/data_source';
import { User } from '../../../dtos/user';
import { ShowPdfComponent } from '../../../shared/show-pdf.component';
import { PDFReports } from '../../../dtos/pdf-report';

@Component({
  selector: 'js-document-versions',
  templateUrl: './document-versions.component.html',
  styleUrls: ['./document-versions.component.scss']
})
export class DocumentVersionsComponent implements OnInit {
  @Input() jobDocumentId: number;

  subscriptions: Subscription[] = [];
  loading = true;
  gridHeight: number;
  attachmentVersions: JobDocAttachmentVersion[];
  dataSource: DataSource;
  users: User[];
  pdfReport: PDFReports = new PDFReports;

  constructor(
    private jobDocAttachmentService: JobDocumentAttachmentService,
    private notiService: NotificationService,
    private userService: UserService,
    private globalService: GlobalService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal) {
    this.openVersion = this.openVersion.bind(this);
  }

  ngOnInit() {
    this.gridHeight = this.globalService.innerHeight - 480;
    this.getUsers();
  }

  close() {
    this.activeModal.close();
  }

  getUsers() {
    this.subscriptions = this.subscriptions.concat(
      this.userService.getAllCurrCompUsers(true).subscribe({
        next: (users) => {
          this.users = users;
          this.checkForVersions();
        }, error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  checkForVersions() {
    this.subscriptions = this.subscriptions.concat(
      this.jobDocAttachmentService.getJobDocAttachmentVersions(this.jobDocumentId).subscribe({
        next: (versions) => {
          this.attachmentVersions = versions;
          this.setUpDataSet();
          this.loading = false;
        }, error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  setUpDataSet() {
    this.dataSource = new DataSource({
      key: 'version',
      load: () => this.attachmentVersions
    });
  }

  openVersion(e) {
    // console.log(e.row.data.attachmentName);
    // console.log(e.row.key);

    this.subscriptions = this.subscriptions.concat(
      this.jobDocAttachmentService
        .getJobDocAttachmentVersion(this.jobDocumentId, e.row.data.attachmentName, e.row.key).subscribe({
          next: (versionFile) => {

            this.pdfReport.attachmentTypeId = versionFile.attachmentTypeId;
            this.pdfReport.pdfReport = versionFile.attachment;
            this.pdfReport.pdfReportName = versionFile.attachmentName;

            const modalRef1 = this.modalService.open(ShowPdfComponent, { windowClass: 'modal-pdf' });
            modalRef1.componentInstance.pdfReport = this.pdfReport;

            modalRef1.result.then(() => { });
          }, error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }
}
