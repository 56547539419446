<div class="modal-header">
  <h3 class="modal-title">Mark all as checked</h3>

  <button *ngIf="!loading" type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<js-loading-spinner *ngIf="loading"></js-loading-spinner>

<div *ngIf="!loading" class="modal-footer">
  <button class="btn btn-sm btn-danger" (click)="submit(false)">Un-Check All</button>
  <button class="btn btn-sm btn-primary" (click)="submit(true)">Check All</button>
</div>
