import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../dtos/user';
import { JobService } from '../../services/felixApi/job.service';
import { JobRole } from '../../dtos/job-role';
import { AuthService } from '../../services/auth.service';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/felixApi/user.service';

@Component({
  selector: 'js-job-users-modal',
  templateUrl: './job-users-modal.component.html',
  styleUrls: ['./job-users-modal.component.scss']
})
export class JobUsersModalComponent implements OnInit, OnDestroy {
  @Input() jobNum: string;
  @Input() users: User[];

  COMPONENT_NAME = 'JobUsersModalComponent';
  subscriptions: Subscription[] = [];

  jobRoles: JobRole[] = [];
  loading = true;
  hasPermission = false;
  updatingRecordCount = 0; // we count to ensure all updates have completed

  constructor(
    private _jobService: JobService, private _activeModal: NgbActiveModal,
    private _authService: AuthService,
    private notiService: NotificationService,
    private _userService: UserService) { }

  ngOnInit() {
    this.getJobRoles();

    const permissionLevel = this._authService.getSelectionsPermissions('Jobs');
    if (permissionLevel === 'Admin' || permissionLevel === 'Write' || this._authService.isAdminOrSuperUser()) {
      this.hasPermission = true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getJobRoles() {
    this.subscriptions = this.subscriptions.concat(
      this._jobService.getJobRoles(this.jobNum)
        .subscribe({
          next: (jobRoles) => {
            this.jobRoles = jobRoles;
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  cancel() {
    this._activeModal.close();
  }

  updatingRole(updating: boolean) {
    // are we waiting
    if (updating) {
      this.updatingRecordCount++;
    } else {
      this.updatingRecordCount--;
    }
  }

  getUserName(userId: number) {
    return this._userService.users?.find(i => i.id === userId)?.fullName;
  }
}
