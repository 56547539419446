import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { OptionClientHeader } from '../../dtos/option-client-header';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class OptionHeaderService {

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  fetchHeader(optionId: number): Observable<[OptionClientHeader, any]> {
    return this.getOptionHeaderByOption(optionId.toString()).pipe(
      mergeMap(header => {
        if (header.hasBlob) {
          return zip(of(header), this.getheaderImage(header.id.toString()));
        } else {
          return zip(of(header), of(null));
        }
      })
    );
  }

  getOptionHeaderByOption(optionId: string): Observable<any> {
    const url = this.globalService.getApiUrl() + '/option-list/' + optionId + '/client-selection-option-header';
    return this.http.get(url, this.httpService.getHttpOptions());
  }

  getheaderImage(optionId: string): Observable<any> {
    const url = this.globalService.getApiUrl() + '/client-selection-option-headers/' + optionId + '/attachments';
    return this.http.get(url, this.httpService.getHttpFileOptions());
  }

}
