import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { JobVarItemService } from '../../../services/felixApi/job-var-item.service';
import { NotificationService } from '../../../services/notification.service';
import { JobItemService } from '../../../services/felixApi/job-item.service';

@Component({
  selector: 'js-change-type',
  templateUrl: './change-type.component.html',
  styleUrls: ['./change-type.component.css']
})
export class ChangeTypeComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  loading = false;
  variationTypeId: number;
  jobVariationId: number;
  variationTypes: { text: string; id: number; }[];

  constructor(private _activeModal: NgbActiveModal,
    private notiService: NotificationService,
    private _jobItemService: JobItemService,
    private jobVarItemService: JobVarItemService) { }


  ngOnInit(): void {
    this.jobVariationId = this._jobItemService.currentVariation.id;

    if (this._jobItemService.currentVariation.variationType < 10) {
      this.variationTypes = [
        { text: 'Pre-Contract', id: 21 }
      ];
    } else {
      this.variationTypes = [
        { text: 'Post-Contract', id: 1 }
      ];
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  cancel() {
    this._activeModal.dismiss();
  }

  submit() {
    this.loading = true;

    this.subscriptions = this.subscriptions.concat(
      this.jobVarItemService.updateJobVariationType(this.jobVariationId, this.variationTypeId).subscribe({
        next: () => {
          this._activeModal.close();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }
}
