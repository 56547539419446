<div class="modal-header">
  <h4 class="modal-title">{{jobitem.itemDescription}} Attachment</h4>
  <!-- <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button> -->
  <button *ngIf="!loading" type="submit" class="btn btn-primary" (click)="close()">
    Close
  </button>
</div>

<form [formGroup]="attachmentForm">

  <div class="modal-body">
    <div *ngIf="!fileIsOpen && (jobitem.itemTypeId === ItemType.Note)">
      <div class="form-group row">
        <label class="col-sm-2 control-label-sm" for="note">Note:</label>
        <textarea type="text" class="form-control-sm col-sm-9" id="note" formControlName="selection"
          readonly></textarea>
      </div>
    </div>

    <!-- Image Loading Spinner -->
    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <!-- Show the PDF -->
    <!-- <pdf-viewer *ngIf="!loading && fileIsOpen && jobItemAttachment?.attachmentTypeId === attachmentTypeEnum.PDF"
      [src]="pdf" [render-text]="true" [original-size]="false" style="display: block;"></pdf-viewer> -->

    <!-- Show the PDF -->
    <div *ngIf="!loading && fileIsOpen && jobItemAttachment?.attachmentTypeId === attachmentTypeEnum.PDF"
      class="pdfClass">
      <ngx-extended-pdf-viewer [src]="pdf" useBrowserLocale="true" height="calc(100vh - 230px)"
        [mobileFriendlyZoom]="mobileFriendlyZoomSetting" [zoom]="'auto'" [showOpenFileButton]="false"
        [useBrowserLocale]="true" [showSidebarButton]="false" [showDownloadButton]="true" [showPrintButton]="false"
        [showFindButton]="false">
      </ngx-extended-pdf-viewer>
    </div>

    <img *ngIf="!loading && fileIsOpen && jobItemAttachment?.attachmentTypeId === attachmentTypeEnum.JPEG"
      [src]="'data:image/jpeg;base64,' + jobItemAttachment.attachment"
      [ngStyle]="{'width': 'calc(' + imageWidth + 'px' + ')'}"
      [ngStyle]="{'height': 'calc(' + imageHeight + 'px' + ')'}" />

    <img *ngIf="!loading && fileIsOpen && jobItemAttachment?.attachmentTypeId === attachmentTypeEnum.PNG"
      [src]="'data:image/png;base64,' + jobItemAttachment.attachment" />

    <dx-file-uploader *ngIf="showLoader" [ngClass]="wrongFileType ? 'red-faint-back' : 'b-blue-faint-back'"
      multiple="true" [value]="filesToUpload" uploadMode="useForm" selectButtonText="Select files"
      labelText="or drop files here" (onValueChanged)="handleFileInput($event)">
      <!-- Following seemes to be broken for dx uploader with pdf: handling manually -->
      <!-- allowedFileExtensions="['.pdf','.PDF','.jpg','.jpeg','.JPG','.JPEG','.png','.PNG']"
        invalidFileExtensionMessage="Please select PDF, JPG or PNG only" -->
    </dx-file-uploader>
    <div *ngIf="wrongFileType" style="color: red">Please select PDF, JPG or PNG only</div>

    <div *ngIf="!loading && fileIsOpen && !isClient" class="form-group row">
      <label class="col-sm-2 control-label-sm" for="attachedfile">Attached File:</label>
      <input type="text" class="form-control-sm col-sm-9" id="attachedfile" formControlName="attachmentName" readonly>
    </div>

    <div *ngIf="attachmentId && jobitem.changedByVOId !== variationId && variationId">To overwrite this file you must
      first change the line via the edit button.
    </div>

    <div style="margin-top: 20px;"
      *ngIf="((!variationId && !attachmentId) || (!attachmentForThisVO && jobitem.changedByVOId === variationId && variationId)) && !isClient">
      <dx-check-box text="Compress Image" [(value)]="compressImageToStore"></dx-check-box>
      <br>
      An attachment can be seen by <b>all users</b> of this job including the <b>client</b> and <b>trades</b> assigned
      to this job.
    </div>
  </div>

  <div *ngIf="!loading" class="modal-footer">
    <button *ngIf="!isReadOnly && attachmentId && !deleteAreYouSureCheck
      && ((jobitem.attachmentVariationId === null && !variationId)
      || (jobitem.attachmentVariationId === variationId && variationId))" type="button" class="btn btn-outline-danger"
      (click)="deleteFile()">
      Delete File
    </button>

    <button *ngIf="deleteAreYouSureCheck" type="button" class="btn btn-outline-danger" (click)="deleteFile()">
      Confirm Delete
    </button>

    <button *ngIf="fileIsOpen && !deleteAreYouSureCheck" type="submit" class="btn btn-primary" (click)="close()">
      Close
    </button>
  </div>
</form>
