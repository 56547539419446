import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalService } from '../global.service';
import { JobItemComment } from '../../dtos/job-item-comment';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  getJobItemComment(id): Observable<JobItemComment> {
    return this._http.get<JobItemComment>(this.globalService.getApiUrl() + '/job-items/' + id + '/comments',
      this.httpService.getHttpFileOptions()).pipe(
        catchError(this.handleError));
  }

  getJobVarItemComment(id): Observable<JobItemComment> {
    return this._http.get<JobItemComment>(this.globalService.getApiUrl() + '/job-varitem-comments/' + id,
      this.httpService.getHttpFileOptions()).pipe(
        catchError(this.handleError));
  }

  addJobItemComment(id: number, dataRecord: any): Observable<number> {
      const url = this.globalService.getApiUrl() + '/job-items/' + id + '/comments';
      return this._http.post<number>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  addJobVarItemComment(id: number, dataRecord: any): Observable<number> {
      const url = this.globalService.getApiUrl() + '/job-var-items/' + id + '/comments';
      return this._http.post<number>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateJobItemComment(id: number, dataRecord: any): Observable<JobItemComment> {
      const url = this.globalService.getApiUrl() + '/job-item-comments/' + id;
      return this._http.patch<JobItemComment>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  deleteJobItemComment(id: number) {
      const url = this.globalService.getApiUrl() + '/job-item-comments/' + id;
      return this._http.delete(url, this.httpService.getHttpOptions());
  }

  private handleError(err: HttpErrorResponse) {
      console.log(err);
      return observableThrowError(err);
  }
}
