export class HouseType {
  public id: number;
  public description: string;
  public isActive: boolean;
  public salesPrice: number;
  public jobId: number;
  public jobNumber: string;
  public defaultDeposit: number;
  public recipeId: number;

  constructor(desc: string, id: number) {
    this.id = id;
    this.description = desc;
  }
}
