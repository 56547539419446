import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IJobItem } from '../../dtos/job-item';
import { AddEditDeleteEnum } from '../../dtos/add-edit-delete.enum';
import { GlobalService } from '../../services/global.service';
import { JobItemService } from '../../services/felixApi/job-item.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobVarItemService } from '../../services/felixApi/job-var-item.service';
import { ItemTypeEnum } from '../../dtos/item-type.enum';
import { JobVarTypeEnum } from '../../dtos/job-var-type.enum';
import { ChangeTypeEnum } from '../../dtos/change-type.enum';
import { AuthService } from '../../services/auth.service';
import { IJobVarItem } from '../../dtos/job-var-item';
import { NotificationService } from '../../services/notification.service';
import { CategoryModalComponent } from './category-modal/category-modal.component';
import { Subscription } from 'rxjs';
import { MoveItemComponent } from './move-item/move-item.component';
import { HideSectionComponent } from './hide-section/hide-section.component';

@Component({
  selector: 'js-heading-modal',
  templateUrl: './heading-modal.component.html',
  styleUrls: ['./heading-modal.component.scss']
})
export class HeadingModalComponent implements OnInit, OnDestroy {
  @Input() addEditDelete: number;
  @Input() jobitem: IJobItem;
  @Input() jobItemAboveId: number;
  @Input() variationNumber: number;
  @Input() variationId: number;
  @Input() indexNo: number;

  COMPONENT_NAME = 'heading-modal';
  subscriptions: Subscription[] = [];

  loading = false;
  addEditDeleteEnum = AddEditDeleteEnum;
  modalHeading: string;
  modalButton: string;
  editForm: UntypedFormGroup;
  addAboveOrAfter: number;
  updatedItem: any; // used for updating/adding a record and returning data to the calling component
  isDeletePressed = false;
  changeTypeEnum = ChangeTypeEnum;
  itemTypeEnum = ItemTypeEnum;
  hasChildItems = false;
  jobLineSetupAdmin = false;
  updatedJobVarItems: IJobVarItem[] = [];
  jobVarTypeEnum = JobVarTypeEnum;
  addendumName: string;
  hasJobLineWritePerm = false;
  canDelete = true;
  confirmDeleteChildren = false;

  constructor(private _globalService: GlobalService,
    private _jobItemService: JobItemService,
    private _jobVarItemService: JobVarItemService,
    private _authService: AuthService,
    private notiService: NotificationService,
    private modalService: NgbModal,
    private _formBuilder: UntypedFormBuilder, private _activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.hasJobLineWritePerm = this._authService.isAdminOrSuperUser();
    const permissionLevel = this._authService.getSelectionsPermissions('JobLineSetup');
    if (permissionLevel === 'Write' || permissionLevel === 'Admin') {
      this.hasJobLineWritePerm = true;
    }

    // console.log(this.jobitem);
    const permissionJobLineSetup = this._authService.getSelectionsPermissions('JobLineSetup');
    if (permissionJobLineSetup === 'Admin' || this._authService.isAdminOrSuperUser()) {
      this.jobLineSetupAdmin = true;
    }

    this.addendumName = this._globalService.getAddendumName();

    if (this.addEditDelete === this.addEditDeleteEnum.Add) {
      if (this.indexNo === -1) {
        this.modalHeading = 'Add to end';
      } else {
        this.modalHeading = 'Add at ' + this.jobitem.itemDescription;
      }
      this.modalButton = 'Add';
      this.editForm = this._formBuilder.group({
        itemTypeId: this.itemTypeEnum.Detail,
        addAboveOrAfter: 'After',
        itemDescription: '',
        isSetUpLine: false,
        itemNotTaken: false,
        isHiddenFromMaster: false,
        isDoNotPrint: false
      });

      if (!this.jobItemAboveId) {
        // if set to null then we are at the top and just want headings
        this.editForm.patchValue({
          itemTypeId: this.itemTypeEnum.Heading,
        });
      }
    } else {
      // this.jobitem.changedByVOId === this.variationId   ???????????
      // console.log('editing item - ' + JSON.stringify(this.jobitem));
      // console.log('variationId - ' + this.variationId);

      this.modalHeading = 'Edit';
      this.modalButton = 'Save';
      this.editForm = this._formBuilder.group({
        itemTypeId: this.jobitem.itemTypeId,
        addAboveOrAfter: 'After',
        itemDescription: this.jobitem.itemDescription,
        isSetUpLine: this.jobitem.isSetUpLine,
        itemNotTaken: this.jobitem.itemNotTaken,
        isHiddenFromMaster: this.jobitem.isHiddenFromMaster,
        isDoNotPrint: this.jobitem.isDoNotPrint
      });

      // if a heading and it has items beneath we cannot delete - check for child items
      this._jobItemService.currentJobItems.forEach(element => {
        if ((element.jobItemAboveTypeId ? element.jobItemAboveTypeId : 1)
          === (this.jobitem.originalItemTypeId ? this.jobitem.originalItemTypeId : 1)
          && element.jobItemAboveId === this.jobitem.id) {
          if (this.variationId) {
            if (element.changedByVOId !== this.variationId || element.changeTypeId !== this.changeTypeEnum.Delete ||
              element.isChecked === false) {
              this.canDelete = false;
            }
          } else {
            this.hasChildItems = true;
            this.canDelete = this.hasJobLineWritePerm ? true : false;
          }
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  update() {
    if (this.addEditDelete === this.addEditDeleteEnum.Add) {
      if (!this.variationId) {
        // we are adding a job item
        this.updatedItem = {
          itemTypeId: this.editForm.get('itemTypeId').value,
          itemDescription: this.editForm.get('itemDescription').value,
          selection: '',
          jobItemAboveTypeId: JobVarTypeEnum.JobItem,
          jobItemAboveId: this.jobItemAboveId,
          isSetUpLine: this.editForm.get('isSetUpLine').value,
          isDoNotPrint: this.editForm.get('isDoNotPrint').value
        };

        if (this.indexNo !== -1) {
          // -1 means we are adding to the end so we don't pass this
          if (this.editForm.get('addAboveOrAfter').value === 'After') {
            this.updatedItem.orderNo = this.jobitem.orderNo + 1;
          } else {
            this.updatedItem.orderNo = this.jobitem.orderNo;
          }
        }

        this.loading = true;
        this.subscriptions = this.subscriptions.concat(
          this._jobItemService.addJobItem(this.updatedItem).subscribe({
            next: () => {
              this.close();
            },
            error: (err) => {
              this.loading = false;
              this.notiService.notify(err);
            }
          })
        );
      } else {
        // in a variation
        if (this.editForm.get('addAboveOrAfter').value === 'After') {
          // add to the same job item above (heading) below this itm
          if (this.jobitem.changedByJobVarId === 0) {
            // add with previous item = JobItem
            this.updatedItem = {
              jobVariationId: this.variationId,
              jobVarTypeId: this.jobitem.originalItemTypeId,
              itemChangedId: this.jobitem.id,
              changeTypeId: ChangeTypeEnum.Add,
              jobItemAboveTypeId: this.jobitem.jobItemAboveTypeId,
              jobItemAboveId: this.jobitem.jobItemAboveId,
              orderNo: 1,
              itemTypeId: this.editForm.get('itemTypeId').value,
              itemDescription: this.editForm.get('itemDescription').value,
              selection: '',
              itemNotTaken: this.editForm.get('itemNotTaken').value,
              isHiddenFromMaster: this.editForm.get('isHiddenFromMaster').value,
              isDoNotPrint: this.editForm.get('isDoNotPrint').value
            };
          } else if (this.jobitem.changedByVOId === this.variationId) {
            // add with previous item = JobVarItem and insert below current one
            if (this.jobitem.changeTypeId === ChangeTypeEnum.Add) {
              this.updatedItem = {
                jobVariationId: this.variationId,
                jobVarTypeId: this.jobitem.jobVarTypeId,
                itemChangedId: this.jobitem.itemChangedId,
                changeTypeId: ChangeTypeEnum.Add,
                jobItemAboveTypeId: this.jobitem.jobItemAboveTypeId,
                jobItemAboveId: this.jobitem.jobItemAboveId,
                orderNo: this.jobitem.orderNo + 1,
                itemTypeId: this.editForm.get('itemTypeId').value,
                itemDescription: this.editForm.get('itemDescription').value,
                selection: '',
                itemNotTaken: this.editForm.get('itemNotTaken').value,
                isHiddenFromMaster: this.editForm.get('isHiddenFromMaster').value,
                isDoNotPrint: this.editForm.get('isDoNotPrint').value
              };
            } else {
              this.updatedItem = {
                jobVariationId: this.variationId,
                jobVarTypeId: this.jobitem.originalItemTypeId,
                itemChangedId: this.jobitem.id,
                changeTypeId: ChangeTypeEnum.Add,
                jobItemAboveTypeId: this.jobitem.jobItemAboveTypeId,
                jobItemAboveId: this.jobitem.jobItemAboveId,
                orderNo: 1,
                itemTypeId: this.editForm.get('itemTypeId').value,
                itemDescription: this.editForm.get('itemDescription').value,
                selection: '',
                itemNotTaken: this.editForm.get('itemNotTaken').value,
                isHiddenFromMaster: this.editForm.get('isHiddenFromMaster').value,
                isDoNotPrint: this.editForm.get('isDoNotPrint').value
              };
            }
          } else {
            // add with previous item = JobVarItem
            this.updatedItem = {
              jobVariationId: this.variationId,
              jobVarTypeId: JobVarTypeEnum.JobVarItem,
              itemChangedId: this.jobitem.id,
              changeTypeId: ChangeTypeEnum.Add,
              jobItemAboveTypeId: this.jobitem.jobItemAboveTypeId,
              jobItemAboveId: this.jobitem.jobItemAboveId,
              orderNo: 1,
              itemTypeId: this.editForm.get('itemTypeId').value,
              itemDescription: this.editForm.get('itemDescription').value,
              selection: '',
              itemNotTaken: this.editForm.get('itemNotTaken').value,
              isHiddenFromMaster: this.editForm.get('isHiddenFromMaster').value,
              isDoNotPrint: this.editForm.get('isDoNotPrint').value
            };
          }
        } else {
          // add section to the top
          this.updatedItem = {
            jobVariationId: this.variationId,
            jobVarTypeId: 0,
            itemChangedId: 0,
            changeTypeId: ChangeTypeEnum.Add,
            jobItemAboveTypeId: this.jobitem.jobItemAboveTypeId,
            jobItemAboveId: this.jobitem.jobItemAboveId,
            orderNo: 1,
            itemTypeId: this.editForm.get('itemTypeId').value,
            itemDescription: this.editForm.get('itemDescription').value,
            selection: '',
            itemNotTaken: this.editForm.get('itemNotTaken').value,
            isHiddenFromMaster: this.editForm.get('isHiddenFromMaster').value,
            isDoNotPrint: this.editForm.get('isDoNotPrint').value
          };
        }
        this.loading = true;
        this.subscriptions = this.subscriptions.concat(
          this._jobVarItemService.addJobVarItem(this.variationNumber, this.updatedItem).subscribe({
            next: () => {
              this.close();
            },
            error: (err) => {
              this.loading = false;
              this.notiService.notify(err);
            }
          })
        );
      }
    } else {
      // edit
      if (this.jobitem.changedByJobVarId && !this.variationId) {
        // we are coming from the master addendum - admin user can fix this flag
        this.loading = true;
        this.subscriptions = this.subscriptions.concat(
          this._jobVarItemService.updateJobVarItemIsDoNotPrint(this.jobitem.changedByJobVarId,
            this.editForm.get('isDoNotPrint').value, this.editForm.get('itemDescription').value).subscribe({
              next: () => {
                this.updatedItem = { isDoNotPrint: this.editForm.get('isDoNotPrint').value };
                this.close();
              },
              error: (err) => {
                this.loading = false;
                this.notiService.notify(err);
              }
            })
        );
      } else if (!this.variationId) {
        // in a job item
        this.updatedItem = { id: this.jobitem.id };
        if (this.jobitem.itemDescription !== this.editForm.get('itemDescription').value) {
          this.updatedItem.itemDescription = this.editForm.get('itemDescription').value;
        }
        if (this.jobitem.isSetUpLine !== this.editForm.get('isSetUpLine').value) {
          this.updatedItem.isSetUpLine = this.editForm.get('isSetUpLine').value;
        }
        if (this.jobitem.itemNotTaken !== this.editForm.get('itemNotTaken').value) {
          this.updatedItem.itemNotTaken = this.editForm.get('itemNotTaken').value;
        }
        if (this.jobitem.isHiddenFromMaster !== this.editForm.get('isHiddenFromMaster').value) {
          this.updatedItem.isHiddenFromMaster = this.editForm.get('isHiddenFromMaster').value;
        }
        if (this.jobitem.isDoNotPrint !== this.editForm.get('isDoNotPrint').value) {
          this.updatedItem.isDoNotPrint = this.editForm.get('isDoNotPrint').value;
        }

        this.loading = true;
        this.subscriptions = this.subscriptions.concat(
          this._jobItemService.updateJobItem(this.updatedItem).subscribe({
            next: () => {
              this.close();
            },
            error: (err) => {
              this.loading = false;
              this.notiService.notify(err);
            }
          })
        );
      } else {
        this.variationEdit();
      }
    }
  }

  variationEdit() {
    if (this.jobitem.changedByVOId === this.variationId) {
      // edit the existing VO
      this.updatedItem = { id: this.jobitem.changedByJobVarId };
      if (this.jobitem.itemDescription !== this.editForm.get('itemDescription').value) {
        this.updatedItem.itemDescription = this.editForm.get('itemDescription').value;
      }
      if (this.jobitem.itemNotTaken !== this.editForm.get('itemNotTaken').value) {
        this.updatedItem.itemNotTaken = this.editForm.get('itemNotTaken').value;
      }
      if (this.jobitem.isHiddenFromMaster !== this.editForm.get('isHiddenFromMaster').value) {
        this.updatedItem.isHiddenFromMaster = this.editForm.get('isHiddenFromMaster').value;
      }
      if (this.jobitem.isDoNotPrint !== this.editForm.get('isDoNotPrint').value) {
        this.updatedItem.isDoNotPrint = this.editForm.get('isDoNotPrint').value;
      }

      // console.log('updating VO - ' + JSON.stringify(this.updatedItem));

      this.loading = true;
      this.subscriptions = this.subscriptions.concat(
        this._jobVarItemService.updateJobVarItem(this.updatedItem).subscribe({
          next: (updatedJobVarItems) => {
            this.updatedJobVarItems = updatedJobVarItems;
            this.close();
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
      );
    } else if (this.jobitem.changedByJobVarId === 0) {
      // add with previous item = JobItem
      this.updatedItem = {
        jobVariationId: this.variationId,
        jobVarTypeId: JobVarTypeEnum.JobItem,
        itemChangedId: this.jobitem.id,
        changeTypeId: ChangeTypeEnum.Change,
        jobItemAboveTypeId: JobVarTypeEnum.JobItem,
        jobItemAboveId: this.jobitem.jobItemAboveId,
        orderNo: 0,
        itemTypeId: this.jobitem.itemTypeId,
        itemDescription: this.editForm.get('itemDescription').value,
        selection: '',
        itemNotTaken: this.editForm.get('itemNotTaken').value,
        isHiddenFromMaster: this.editForm.get('isHiddenFromMaster').value,
        isDoNotPrint: this.editForm.get('isDoNotPrint').value
      };
      this.loading = true;
      this.subscriptions = this.subscriptions.concat(
        this._jobVarItemService.addJobVarItem(this.variationNumber, this.updatedItem).subscribe({
          next: () => {
            this.close();
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
      );
    } else {
      // add with previous item = JobVarItem
      this.updatedItem = {
        jobVariationId: this.variationId,
        jobVarTypeId: JobVarTypeEnum.JobVarItem,
        itemChangedId: this.jobitem.changedByJobVarId,
        changeTypeId: ChangeTypeEnum.Change,
        jobItemAboveTypeId: this.jobitem.jobItemAboveTypeId,
        jobItemAboveId: this.jobitem.jobItemAboveId,
        orderNo: 0,
        itemTypeId: this.jobitem.itemTypeId,
        itemDescription: this.editForm.get('itemDescription').value,
        selection: '',
        itemNotTaken: this.editForm.get('itemNotTaken').value,
        isHiddenFromMaster: this.editForm.get('isHiddenFromMaster').value,
        isDoNotPrint: this.editForm.get('isDoNotPrint').value
      };
      this.loading = true;
      this.subscriptions = this.subscriptions.concat(
        this._jobVarItemService.addJobVarItem(this.variationNumber, this.updatedItem).subscribe({
          next: () => {
            this.close();
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  close() {
    this.updatedItem.cancel = false;
    this._activeModal.close(this.updatedItem);
  }

  cancel() {
    this.updatedItem = { cancel: true, gotoSetupTags: false };
    this._activeModal.close(this.updatedItem);
  }

  setupTags() {
    this.updatedItem = { cancel: true, gotoSetupTags: true };
    this._activeModal.close(this.updatedItem);
  }

  delete() {
    if (!this.isDeletePressed) {
      if (this.hasChildItems) {
        this.notiService.showWarning('Child items exist!');
      }
      this.isDeletePressed = true;
    } else {
      if (this.variationId) {
        // in a job var item line
        this.variationDelete();
      } else {
        this.loading = true;
        this.subscriptions = this.subscriptions.concat(
          this._jobItemService.deleteJobItem(this.jobitem.id, this.confirmDeleteChildren).subscribe({
            next: () => {
              this.updatedItem = { cancel: false };
              this._activeModal.close(this.updatedItem);
            },
            error: (err) => {
              this.loading = false;
              this.notiService.notify(err);
            }
          })
        );
      }
    }
  }

  variationDelete() {
    if (this.jobitem.changedByVOId === this.variationId) {
      // delete the existing item
      this.loading = true;
      this.subscriptions = this.subscriptions.concat(
        this._jobVarItemService.deleteJobVarItem(this.jobitem.changedByJobVarId).subscribe({
          next: () => {
            this.updatedItem = { cancel: false };
            this._activeModal.close(this.updatedItem);
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
      );
    } else if (this.jobitem.changedByJobVarId === 0) {
      // add with previous item = JobItem
      this.updatedItem = {
        jobVariationId: this.variationId,
        jobVarTypeId: JobVarTypeEnum.JobItem,
        itemChangedId: this.jobitem.id,
        changeTypeId: ChangeTypeEnum.Delete,
        jobItemAboveTypeId: this.jobitem.jobItemAboveTypeId,
        jobItemAboveId: this.jobitem.jobItemAboveId,
        itemTypeId: this.jobitem.itemTypeId
      };
      this.loading = true;
      this.subscriptions = this.subscriptions.concat(
        this._jobVarItemService.addJobVarItem(this.variationNumber, this.updatedItem).subscribe({
          next: () => {
            this.updatedItem.cancel = false;
            this._activeModal.close(this.updatedItem);
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
      );
    } else {
      // add with previous item = JobVarItem
      this.updatedItem = {
        jobVariationId: this.variationId,
        jobVarTypeId: JobVarTypeEnum.JobVarItem,
        itemChangedId: this.jobitem.changedByJobVarId,
        changeTypeId: ChangeTypeEnum.Delete,
        jobItemAboveTypeId: this.jobitem.jobItemAboveTypeId,
        jobItemAboveId: this.jobitem.jobItemAboveId,
        itemTypeId: this.jobitem.itemTypeId
      };
      this.loading = true;
      this.subscriptions = this.subscriptions.concat(
        this._jobVarItemService.addJobVarItem(this.variationNumber, this.updatedItem).subscribe({
          next: () => {
            this.updatedItem.cancel = false;
            this._activeModal.close(this.updatedItem);
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  setCategories() {
    // add remove categories to a heading
    const modalRef = this.modalService.open(CategoryModalComponent);
    modalRef.componentInstance.jobItem = this.jobitem;

    modalRef.result.then((updatedItem) => {
    }, (reason) => {
    });
  }

  moveItem() {
    // move added items around
    const modalRef = this.modalService.open(MoveItemComponent, { windowClass: 'modal-1000' });
    modalRef.componentInstance.jobItem = this.jobitem;

    modalRef.result.then((result) => {
      this.notiService.showInfo('Item Moved');
      this.updatedItem = { cancel: false, gotoSetupTags: false };
      this.close();
    }, (reason) => {
    });
  }

  setHideSection() {
    // do we show or hide this for in a variation
    const modalRef = this.modalService.open(HideSectionComponent);
    modalRef.componentInstance.jobItem = this.jobitem;

    modalRef.result.then();
  }
}
