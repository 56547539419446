import { AuthService } from './../services/auth.service';
import { NotificationService } from './../services/notification.service';
import { TaskService } from './../services/felixApi/task.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { IJob } from '../dtos/job';
import { GlobalService } from '../services/global.service';
import { UserService } from '../services/felixApi/user.service';
import { UserTypeEnum } from '../dtos/user-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobService } from '../services/felixApi/job.service';
import { CreateSalesTaskComponent } from './create-sales-task/create-sales-task.component';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { TaskStatusEnum } from '../dtos/task-status.enum';

@Component({
  selector: 'js-sales-tasks',
  templateUrl: './sales-tasks.component.html',
  styleUrls: ['./sales-tasks.component.scss']
})
export class SalesTasksComponent implements OnInit, OnDestroy {

  @ViewChild('dataGrid', { static: false }) dataGrid: DxDataGridComponent;

  loading = true;
  subscriptions: Subscription[] = [];
  dataSource: any = {};
  dropDownOptions: object;
  jobData: CustomStore;
  users: CustomStore;
  taskTypes: CustomStore;
  taskStatus = [
    { id: 1, status: 'Not Started' },
    { id: 2, status: 'In Progress' },
    { id: 3, status: 'Hold' },
    { id: 4, status: 'Problem' },
    { id: 5, status: 'Waiting' },
    { id: 8, status: 'Cancelled' },
    { id: 9, status: 'Completed' },
    { id: 10, status: 'Not Applicable' }
  ];
  taskStatusList: CustomStore;
  jobs: IJob[];
  gridHeight: number;
  contractNameWidth: number;
  gridWidth: number;
  editPopupWidth: number;
  editPopupHeight: number;
  buttonOptions: any = {
    text: 'Create',
    type: 'default',
    useSubmitBehavior: true
  };
  showCompleted = false;
  canEditDates: boolean;
  ownTask: boolean;

  constructor(
    private globalService: GlobalService,
    private userService: UserService,
    private jobService: JobService,
    private taskService: TaskService,
    private notiService: NotificationService,
    private modalService: NgbModal,
    private authService: AuthService
  ) {
    this.calculateJobSortValue = this.calculateJobSortValue.bind(this);
    this.calculateContractName = this.calculateContractName.bind(this);
    this.calculateSiteAddress = this.calculateSiteAddress.bind(this);
    this.calculateTaskSortValue = this.calculateTaskSortValue.bind(this);
    this.getTaskList = this.getTaskList.bind(this);
    this.cancelClickHandler = this.cancelClickHandler.bind(this);
    this.saveClickHandler = this.saveClickHandler.bind(this);
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
  }

  ngOnInit() {
    this.setGridMeasurements();
    this.subscribeToInnerHeight();
    this.canEditDates = this.authService.isAdminOrSuperUser();
    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  subscribeToInnerHeight() {
    this.subscriptions.push(
      this.globalService.innerWidthChanged.pipe().subscribe(
        () => {
          setTimeout(() => {
            this.setGridMeasurements();
          }, 500); // wait for iPhone
        }
      )
    );
  }

  setGridMeasurements() {
    this.gridHeight = this.globalService.innerHeight - 70;
    this.gridWidth = window.innerWidth - 30;
    this.contractNameWidth = this.globalService.innerWidth < 1500 ? 130
      : (this.globalService.innerWidth - 1200) / 2;

    if (this.authService.isAdminOrSuperUser()) {
      this.canEditDates = true;
      this.editPopupHeight = 340;
    } else {
      this.canEditDates = false;
      this.editPopupHeight = 250;
    }
  }

  getData() {
    this.subscriptions.push(
      this.taskService.getSalesJobTasksData()
        .subscribe({
          next: (jobs) => {
            this.jobs = jobs;

            this.jobs.forEach(job => {
              job.jobAddressString = this.globalService.getJobString(job, false);

              const thisJobsSalesTasks = this.taskService.salesTasks.filter(i => i.jobId === job.id);

              thisJobsSalesTasks.forEach(salesTasks => {
                salesTasks.jobAddressString = job.jobAddressString;
                salesTasks.contractName = job.contractName;
              });
            });

            this.jobData = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.jobs.filter(i => i.isActive)
            });

            this.taskTypes = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.taskService.taskTypes
            });

            this.users = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.userService.users.filter(i => i.userTypeId !== UserTypeEnum.Associate
                && i.userTypeId !== UserTypeEnum.Client)
            });

            this.taskStatusList = new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => this.taskStatus
            });

            this.loading = false;
            this.setUpDataSource();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      loadMode: 'raw',
      load: () => this.taskService.salesTasks.filter(i => this.showCompleted || !i.endDate),
      update: async (key, values) => {
        return new Promise((resolve, reject) => {
          if (values.endDate !== undefined) {
            values.statusId = values.endDate ? TaskStatusEnum.Completed : TaskStatusEnum.InProgress;
          }

          this.taskService.updateJobTask(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              const thisTask = this.taskService.salesTasks.find(i => i.id === key);
              if (values.officeComment !== undefined) {
                thisTask.officeComment = values.officeComment;
              }
              if (values.dueDate !== undefined) {
                thisTask.dueDate = values.dueDate;
              }
              if (values.endDate !== undefined) {
                thisTask.endDate = values.endDate;
              }
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          });
        });
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.taskService.deleteSalesJobTask(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onEditorPreparing(e) {
    if (e.dataField === 'taskTypeId' || e.dataField === 'taskMasterId') {
      e.editorOptions.dropDownOptions = { minWidth: 350 };
    }
    if (e.dataField === 'userId') {
      e.editorOptions.dropDownOptions = { minWidth: 220 };
    }
    if (e.dataField === 'jobId') {
      e.editorOptions.dropDownOptions = { minWidth: 120 };
    }
    if (e.row !== undefined && e.row.data !== undefined && e.row.data.id !== undefined) {
      if (!e.row.data.userId || e.row.data.userId === this.authService.getCurrentUserId()) {
        this.ownTask = true;
        this.editPopupHeight = 340;
      } else {
        this.ownTask = false;
        this.editPopupHeight = 250;
      }
    }
  }

  calculateJobSortValue(data) {
    let jobNumber = '';
    const job = this.jobs.find(i => i.id === data.jobId);
    if (job) {
      jobNumber = job.jobNumber;
    }

    return jobNumber;
  }

  calculateContractName(data) {
    if (data && data.jobId) {
      // on hold?
      let contractName = '';
      const job = this.jobs.find(i => i.id === data.jobId);
      if (job) {
        contractName = job.contractName;
      }
      return contractName;
    }
  }

  calculateSiteAddress(data) {
    if (data && data.jobId) {
      const job = this.jobs.find(i => i.id === data.jobId);
      return this.globalService.getJobString(job, false);
    }
  }

  calculateTaskSortValue(data) {
    let sortOrder = 0;
    const task = this.taskService.taskMasters.find(i => i.id === data.taskMasterId);
    if (task) {
      sortOrder = task.orderNumber;
    }

    return sortOrder;
  }

  getTaskList(options) {
    return {
      store: options.isNewRow ? this.taskService.taskMasters.filter(i => i.isActive) : this.taskService.taskMasters,
      filter: options.data ? ['taskTypeId', '=', options.data.taskTypeId] : null
    };
  }

  onToolbarPreparing(e, templateName: string) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Create Task',
          type: 'default',
          onClick: this.addTask.bind(this)
        }
      });

    e.toolbarOptions.items.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName
    });
  }

  addTask() {
    const modalRef = this.modalService.open(CreateSalesTaskComponent, { windowClass: 'modal-edit' });

    modalRef.result.then((result) => {
      if (result) {
        this.loading = true;
        this.subscriptions.push(
          this.taskService.addSalesJobTask(result.jobId, result.templateTaskId, result.officeComment)
            .subscribe({
              next: () => {
                this.getData();
              },
              error: (err) => {
                this.notiService.notify(err);
                this.loading = false;
              }
            })
        );
      }
    }, (err) => {
      this.notiService.notify(err);
    });
  }

  showCompletedChanged(e) {
    this.showCompleted = e.value ? true : false;
    this.setUpDataSource();
  }

  cancelClickHandler() {
    this.dataGrid.instance.cancelEditData();
  }

  saveClickHandler() {
    this.dataGrid.instance.saveEditData();
  }
}
