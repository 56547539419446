<div class="modal-header">
  <h2 class="modal-title">Create Task</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div>
    <span class="jobLabel">Job:</span>
    <dx-text-box id="jobNumberField" [width]="150" [(value)]="jobNumber" (keyup)="changeJobNum()"
      (keyup.enter)="getJob()"></dx-text-box>
    <button type="button" class="btn btn-sm btn-primary ms-0" (click)="getJob()" [disabled]="!jobNumber">Select</button>
    <button type="button" class="btn btn-sm btn-secondary ms-1" (click)="jobSearch()">List/Search</button>
  </div>

  <div class="mt-2 mb-3">
    <span class='me-1'>Create:</span>
    <dx-select-box class="salesTaskSelect me-2" [dataSource]="salesTemplateTasks" displayExpr="taskTitle" valueExpr="id"
      [(value)]="salesTemplateTaskId" searchEnabled="true">
    </dx-select-box>

    <div class="red mt-3">Note: All information to be entered into a sales variation</div>
    <br>

    <div>Comment:</div>

    <dx-text-area [height]="80" [(value)]="officeComment"> </dx-text-area>
  </div>
</div>


<div class="modal-footer">
  <dx-button text="Create" class="marginLess5" type="default" (onClick)="createTask()"
    [disabled]="!jobId || !salesTemplateTaskId">
  </dx-button>
</div>
