export class User {
  public id: number;
  public email: string;
  public password: string;
  public title: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public suffix: string;
  public isActive: boolean;
  public isSuperUser: boolean;
  public mobileNumber: string;
  public userTypeId: number;
  public userType: string;
  public fullName: string;
  public vendorId: number;
  public canOnlySeeAllocatedJobs: boolean;
  public vendorName: string;
  public divisionId: number;

  checked: boolean; // used when selecting clients/trades for jobs
  jobCustomerId: number; // used when selecting clients/trades for jobs

  constructor(desc: string, inOrderNo) {
    this.firstName = desc;
    this.id = inOrderNo;
    this.lastName = desc;
  }
}
