<div class="modal-header">
    <button type="button" class="btn btn-sm btn-primary margin15" aria-label="Close" (click)="close()">
        <span aria-hidden="true">Close</span>
    </button>
</div>

<div class="modal-body">

    <img *ngIf="attachmentTypeId === attachmentTypeEnum.JPEG"
        [src]="'data:image/jpeg;base64,'+attachment" />

    <img *ngIf="attachmentTypeId === attachmentTypeEnum.PNG"
        [src]="'data:image/png;base64,'+attachment" />

</div>