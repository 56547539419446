<h5 class='blue-heading'>
  Job Progress
  <button *ngIf="truncationPossible" class="btn btn-secondary btn-sm view-all-btn" (click)="toggleViewMode()">
    {{getToggleText()}}
  </button>
</h5>

<div [ngClass]="{'horizontal-scroll' : !phoneSize}">
  <ul class="progress-tracker progress-tracker--text progress-tracker--center"
    [ngClass]="{'progress-tracker--vertical' : phoneSize, 'force-all' : truncationPossible && !truncatedMode && !phoneSize}">
    <li *ngFor="let step of visibleSteps; index as i" class="progress-step" [ngClass]="progressStepClass(i)"
        [matTooltip]="!desktopSize ? '' : tooltip(i)" matTooltipPosition="above" matTooltipClass="multiline-tooltip">
      <div class="progress-marker">
        <i *ngIf="isComplete(i)" [ngClass]="{'step-icon-horiz' : !phoneSize}" class="step-icon material-icons">done</i>
        <i *ngIf="isActive(i)" [ngClass]="{'step-icon-horiz' : !phoneSize}" class="step-icon material-icons">more_horiz</i>
      </div>
      <div class="progress-text">
        <h6 class="progress-title" [ngClass]="progressTextClass(i)">{{visibleSteps[i].label}}</h6>
        <span *ngIf="phoneSize && visibleSteps[i].label !== visibleSteps[i].desc">{{visibleSteps[i].desc}}</span>
      </div>
    </li>
  </ul>
</div>
