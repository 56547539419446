<!-- enter job number -->
<div>
  <div class="jobHeight">
    <div *ngIf="!isClient && innerWidth > PHONE_SIZE" class="ms-0 me-1 mt-1">

      <dx-text-box class="jobNumberClass" [(ngModel)]="_jobService.currentJobNum" (onBlur)="onBlur()"
        (onEnterKey)="setJobNum()">
      </dx-text-box>

      <button type="submit" class="btn btn-sm btn-primary goButton" (click)="setJobNum()"
        [disabled]="!_jobService.currentJobNum">Go</button>
    </div>
    <div *ngIf='!isOnlyOneJob' class="marginRight10">
      <button type="button" class="btn btn-sm btn-primary srchbutton" (click)="jobSearch()"
        [class.clientSearchPadding]="fromDashboard" [class.mtTop1]="!fromDashboard">List / Search</button>
    </div>

    <div *ngIf="loading" class="jobString" [ngStyle]="{'width': calcWidth ? stringWidth + 'px' : ''}">
      Loading details please wait...
    </div>


    <div *ngIf="!loading" class="jobString" [ngStyle]="{'max-width': 'calc(' + stringWidth + 'px' + ')'}">
      <div class="jobStringText text-nowrap">
        <span *ngIf='currentJobExtra && currentJobExtra.isOnHold && currentJobExtra.cancellationDate === null'
          class='me-1 red'>
          ON HOLD
        </span>
        <span *ngIf='currentJobExtra && currentJobExtra.cancellationDate !== null' class='me-1 red'>
          CANCELLED
        </span>
        {{jobString}}
      </div>
      <div class="jobStringText text-nowrap">
        {{jobAddress}}
        <span *ngIf="currentActivity && currentActivity !== ''">({{currentActivity}})</span>
      </div>
    </div>
  </div>
</div>
