<div *ngIf="!loading && htmlAbove" [ngClass]="{'text-center' : phoneSize}" class="remove-dx-html-editor-p-tag-padding m-b-30 m-t-20">
  <div *ngIf="htmlAbove" [innerHTML]="htmlAbove"></div>
  <div class="m-t-15 m-b-15" [ngClass]="{'noAsideText' : !htmlAside, 'asideText' : htmlAside && !phoneSize}">
    <div [ngClass]="{'flex' : phoneSize}">
      <img *ngIf="attachment" [src]="'data:image/jpeg;base64,'+attachment.attachment"
      class="max-h-400" [ngClass]="{'max-w-400 m-r-15' : htmlAside && !phoneSize, 'horizontal-auto-margin max-width-full' : phoneSize || !htmlAside}">
    </div>
    <div *ngIf="htmlAside" [ngClass]="{'flex align-items-center justify-center' : !phoneSize, 'm-t-20' : phoneSize}">
      <div [innerHTML]="htmlAside" style="vertical-align: middle;"></div>
    </div>
  </div>
  <div *ngIf="htmlBelow" [innerHTML]="htmlBelow"></div>
</div>

<js-loading-spinner *ngIf="loading"></js-loading-spinner>
