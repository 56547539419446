<div class="modal-header">
  <div>
    <dx-button icon="download" type="default" stylingMode="default" (onClick)="export()"> </dx-button>

    <dx-button *ngIf="!loading && !isRestrictedForm && !isClientOrAssociate" text="Save"
      type="default" stylingMode="outlined" (onClick)="saveAsAnotherFile()" class="ms-2">
    </dx-button>
  </div>

  <span *ngIf="!isRestrictedForm" class="jobStringClass">{{jobAddressString}}</span>

  <div>
    <span *ngIf="jobDocumentId || sharePointFileId">
      <dx-button *ngIf="!loading && !isRestrictedForm" [text]="disableFormText" type="default"
        stylingMode="outlined" (onClick)="enableDisableForm()" class="me-2">
      </dx-button>
    </span>

    <dx-button icon="close" type="default" stylingMode="default" (onClick)="close()"> </dx-button>
  </div>
</div>

<div class="modal-body">
  <!-- Image Loading Spinner -->
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <!-- Show the PDF -->
  <div *ngIf="!loading && !fileDownloaded && errorMessage === ''" [style.height.px]="pdfHeight" class="pdfClass">
    <!-- not Info tab -->
    <div *ngIf="!formEnabled">
      <ngx-extended-pdf-viewer [src]="blob" [useBrowserLocale]="true" [mobileFriendlyZoom]="mobileFriendlyZoomSetting"
        [zoom]="'auto'" [showOpenFileButton]="false" [useBrowserLocale]="true" [showSidebarButton]="false"
        [showPrintButton]="false" [showFindButton]="false" [showDownloadButton]="false">
      </ngx-extended-pdf-viewer>
    </div>

    <!-- Info tab -->
    <div *ngIf="formEnabled">
      <!-- <ngx-extended-pdf-viewer *ngIf="disabledForm" [src]="blob" [useBrowserLocale]="true"
        [mobileFriendlyZoom]="mobileFriendlyZoomSetting" [zoom]="'auto'" [showOpenFileButton]="false"
        [useBrowserLocale]="true" [showSidebarButton]="false" [(scrollMode)]="scrollMode" [showPrintButton]="false"
        [showFindButton]="false" [showDownloadButton]="false">
      </ngx-extended-pdf-viewer> -->

      <ngx-extended-pdf-viewer [src]="blob" [useBrowserLocale]="true" [mobileFriendlyZoom]="mobileFriendlyZoomSetting"
        [zoom]="'auto'" [showOpenFileButton]="false" [useBrowserLocale]="true" [showSidebarButton]="false"
        [(scrollMode)]="scrollMode" [showPrintButton]="false" [showFindButton]="false" [showDownloadButton]="false"
        [(formData)]="formData">
      </ngx-extended-pdf-viewer>
    </div>
  </div>

  <div *ngIf="errorMessage !== ''" class="error">
    {{errorMessage}}
  </div>

  <div *ngIf="fileDownloaded" class="success">
    File Downloaded
  </div>
</div>
