import { GlobalService } from './../../../services/global.service';
import { JobItemService } from './../../../services/felixApi/job-item.service';
import { NotificationService } from './../../../services/notification.service';
import { ConstructiveService } from './../../../services/felixApi/constructive.service';
import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { IJobItem } from '../../../dtos/job-item';
import { ItemTypeEnum } from '../../../dtos/item-type.enum';
import DataSource from 'devextreme/data/data_source';
import { DxDataGridComponent } from 'devextreme-angular';
import { ConstructiveSelection } from '../../../dtos/constructive-selection';
import { JobVarTypeEnum } from '../../../dtos/job-var-type.enum';

@Component({
  selector: 'js-constructive-selections',
  templateUrl: './constructive-selections.component.html',
  styleUrls: ['./constructive-selections.component.css']
})
export class ConstructiveSelectionsComponent implements OnInit, OnDestroy {
  @Input() jobNumber: string;
  @Input() jobId: number;
  @Input() jobVariationId: number;

  @ViewChild('constructiveGrid', { static: false }) dataGrid: DxDataGridComponent;

  loading = true;
  updatingVariation = false;
  subscriptions: Subscription[] = [];
  constructiveSelections: CustomStore;
  sectionHeadings: IJobItem[];
  subHeadings: IJobItem[];
  jobItems: IJobItem[];
  gridHeight: number;
  dropDownOptions: object;
  subHeadingsForDropdown: DataSource;
  itemsForDropdown: DataSource;
  selectionsStore: ConstructiveSelection[];

  // get the width of the screen
  @HostListener('window:resize', ['$event']) onResize() {
    this.setHeight();
  }

  constructor(private _activeModal: NgbActiveModal,
    private jobItemService: JobItemService,
    private constructiveService: ConstructiveService,
    private globalService: GlobalService,
    private notiService: NotificationService) {
    this.setUpSubHeadings = this.setUpSubHeadings.bind(this);
    this.setUpItems = this.setUpItems.bind(this);
    this.matchSelections = this.matchSelections.bind(this);
    this.onSaved = this.onSaved.bind(this);
  }

  ngOnInit() {
    this.setHeight();
    this.sectionHeadings = this.jobItemService.currentJobItemsUnfiltered.filter(i => i.jobItemAboveId === null && !i.isDoNotPrint && !i.isHistoryRecord);
    this.subHeadings = this.jobItemService.currentJobItemsUnfiltered.filter(i => i.jobItemAboveId !== null && i.itemTypeId === ItemTypeEnum.Heading && !i.isDoNotPrint && !i.isHistoryRecord);
    this.jobItems = this.jobItemService.currentJobItemsUnfiltered.filter(i => i.jobItemAboveId !== null && i.itemTypeId === ItemTypeEnum.Detail && !i.isHistoryRecord);

    this.loading = false;
    this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setHeight() {
    this.gridHeight = window.innerHeight - 140;
    this.dropDownOptions = { width: 800, height: this.gridHeight < 800 ? 500 : this.gridHeight - 300 };
  }

  cancel() {
    this._activeModal.dismiss();
  }

  setUpDataSource() {
    this.constructiveSelections = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.constructiveService.getSelections(this.jobId).subscribe({
            next: (res) => {
              res.forEach(element => {
                if (element.jobVarTypeId && element.itemChangedId) {
                  const existingItem = this.jobItemService.currentJobItemsUnfiltered
                    .filter(i => i.jobVarTypeId === element.jobVarTypeId && i.itemChangedId === element.itemChangedId && !i.isHistoryRecord)[0];
                  if (existingItem) {
                    element.itemDescription = existingItem.itemDescription;

                    // find the heading
                    const existingSubHeading = this.jobItemService.currentJobItemsUnfiltered
                      .filter(i => i.jobItemAboveTypeId === existingItem.jobItemAboveTypeId && i.id === existingItem.jobItemAboveId && !i.isHistoryRecord)[0];
                    if (existingSubHeading) {
                      element.subHeading = existingSubHeading.itemDescription;

                      // find the section
                      const existingSection = this.jobItemService.currentJobItemsUnfiltered
                        .filter(i => i.jobItemAboveTypeId === existingSubHeading.jobItemAboveTypeId && i.id === existingSubHeading.jobItemAboveId && !i.isHistoryRecord)[0];
                      if (existingSection) {
                        element.section = existingSection.itemDescription;
                      }
                    } else {
                      // may only have one level of heading
                      const existingSection = this.jobItemService.currentJobItemsUnfiltered
                        .filter(i => i.jobItemAboveTypeId === existingItem.jobItemAboveTypeId && i.id === existingItem.jobItemAboveId && !i.isHistoryRecord)[0];
                      if (existingSection) {
                        element.section = existingSection.itemDescription;
                      }
                    }
                  }
                }
              });
              this.selectionsStore = res;
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.constructiveService.updateSelections(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  setSectionValue(rowData, value) {
    rowData.section = value;
    rowData.subHeading = null;
  }

  setSubHeadingValue(rowData, value) {
    rowData.subHeading = value;
    rowData.itemDescription = null;
  }

  setItemValue(rowData, value) {
    rowData.itemDescription = value;
  }

  setUpSubHeadings(options) {
    const headingItem = this.sectionHeadings.filter(i => i.itemDescription === options.data?.section)[0];

    this.subHeadingsForDropdown = new DataSource({
      key: 'itemDescription',
      loadMode: 'raw',
      load: () => this.subHeadings
        .filter(i => i.jobItemAboveId === headingItem?.id && i.jobItemAboveTypeId === headingItem?.originalItemTypeId && i.itemTypeId === ItemTypeEnum.Heading && !i.isDoNotPrint)
    });
  }

  setUpItems(options) {
    const headingItem = this.sectionHeadings.filter(i => i.itemDescription === options.data?.section)[0];

    if (options.data?.subHeading && options.data.subHeading !== '') {
      const subHeading = this.subHeadings
        .filter(i => i.jobItemAboveId === headingItem.id && i.jobItemAboveTypeId === headingItem.originalItemTypeId && i.itemDescription === options.data?.subHeading)[0];

      this.itemsForDropdown = new DataSource({
        key: 'itemDescription',
        loadMode: 'raw',
        load: () => this.jobItems
          .filter(i => i.jobItemAboveId === subHeading?.id && i.jobItemAboveTypeId === subHeading?.originalItemTypeId)
      });
    } else {
      this.itemsForDropdown = new DataSource({
        key: 'itemDescription',
        loadMode: 'raw',
        load: () => this.jobItems
          .filter(i => i.jobItemAboveId === headingItem?.id && i.jobItemAboveTypeId === headingItem?.originalItemTypeId)
      });
    }
  }

  onSelectionChanged(cellInfo, dropDownBox, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0].itemDescription);
      dropDownBox.close();
    }
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Auto Match',
          type: 'default',
          onClick: this.matchSelections.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Import selections from Constructive',
          type: 'danger',
          onClick: this.refreshSelections.bind(this)
        }
      });
  }

  refreshSelections() {
    this.loading = true;
    this.subscriptions = this.subscriptions.concat(
      this.constructiveService.refreshSelections(this.jobId).subscribe({
        next: () => {
          // this.getData();
          this.loading = false;
          this.setUpDataSource();
        },
        error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  matchSelections() {
    if (this.dataGrid.instance.hasEditData()) {
      this.notiService.showInfo('Please Save or Cancel the edited data');
    } else {
      this.selectionsStore.forEach(selectionItem => {
        const rowIndex = this.dataGrid.instance.getRowIndexByKey(selectionItem.id);

        const sectionHeading = this.sectionHeadings.filter(i => i.itemDescription === selectionItem.area)[0];

        if (sectionHeading) {
          this.dataGrid.instance.cellValue(rowIndex, 'section', sectionHeading.itemDescription);

          const subHeading = this.subHeadings.filter(i => i.jobItemAboveId === sectionHeading?.id && i.itemDescription === selectionItem.selectionGroup)[0];

          let jobItem = new IJobItem;

          if (subHeading) {
            // found a sub-heading
            this.dataGrid.instance.cellValue(rowIndex, 'subHeading', subHeading.itemDescription);
            jobItem = this.jobItems.filter(i => i.jobItemAboveId === subHeading?.id && i.itemDescription === selectionItem.selection)[0];
          } else {
            // no sub-heading found but see if there is an item
            jobItem = this.jobItems.filter(i => i.jobItemAboveId === sectionHeading?.id && i.itemDescription === selectionItem.selection)[0];
          }

          if (jobItem) {
            this.dataGrid.instance.cellValue(rowIndex, 'itemDescription', jobItem.itemDescription);
            this.dataGrid.instance.cellValue(rowIndex, 'jobVariationId', this.jobVariationId);

            if (!jobItem.changedByJobVarId || jobItem.changedByJobVarId === 0) {
              // add with previous item = JobItem
              this.dataGrid.instance.cellValue(rowIndex, 'jobVarTypeId', JobVarTypeEnum.JobItem);
              this.dataGrid.instance.cellValue(rowIndex, 'itemChangedId', jobItem.id);
            } else {
              // add with previous item = JobVarItem
              this.dataGrid.instance.cellValue(rowIndex, 'jobVarTypeId', JobVarTypeEnum.JobVarItem);
              this.dataGrid.instance.cellValue(rowIndex, 'itemChangedId', jobItem.changedByJobVarId);
            }
          }
        }
      });
    }
  }

  onSaved() {
    this.updatingVariation = true;
    this.subscriptions = this.subscriptions.concat(
      this.constructiveService.updateVariationWithSelections(this.jobNumber, this.jobVariationId).subscribe({
        next: () => {
          this._activeModal.close();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.updatingVariation = false;
        }
      })
    );
  }
}
