import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ClientWelcomeAttachment } from '../../dtos/client-welcome';
import { ClientWelcomeService } from '../../services/felixApi/client-welcome.service';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'js-client-welcome',
  templateUrl: './client-welcome.component.html',
  styleUrls: ['./client-welcome.component.scss']
})
export class ClientWelcomeComponent implements OnInit {

  htmlAbove: SafeHtml;
  htmlAside: SafeHtml;
  htmlBelow: SafeHtml;
  attachment: ClientWelcomeAttachment;

  loading = true;
  phoneSize = false;
  subscriptions: Subscription[] = [];

  constructor(
    private clientwelcomeService: ClientWelcomeService,
    private globalService: GlobalService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.subscribeToWidthChanges();

    this.clientwelcomeService.fetchClientWelcome()
      .subscribe({
        next: ([clientWelcome, image]) => {
          if (clientWelcome) {
            this.htmlAbove = this.sanitizer.bypassSecurityTrustHtml(
              clientWelcome.htmlDetailAboveImage
            );
            this.htmlAside = this.sanitizer.bypassSecurityTrustHtml(
              clientWelcome.htmlDetailAsideImage
            );
            this.htmlBelow = this.sanitizer.bypassSecurityTrustHtml(
              clientWelcome.htmlDetailBelowImage
            );
          }
          this.attachment = image;
          this.loading = false;
        }, error: (err) => {
          console.log(err);
          this.loading = false;
        }
      });
  }

  subscribeToWidthChanges() {
    this.phoneSize = this.globalService.phoneSize;

    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerWidthChanged.subscribe((width: number) => {
        this.phoneSize = this.globalService.phoneSize;
      })
    );
  }

}
