import { GlobalService } from './../global.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, forkJoin } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { PurchaseOrder } from '../../dtos/purchase-order';
import { HttpService } from '../http.service';
import { PriceFileItem } from '../../dtos/price-file-item';
import { PriceFileItemTypeEnum } from '../../dtos/price-file-item-type.enum';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrdersService {

  purchaseOrdersForJob: PurchaseOrder[];
  costCentres: PriceFileItem[] = [];

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService
  ) { }


  getPurchaseOrdersForJob(jobId: number): Observable<PurchaseOrder[]> {
    return this._http.get<PurchaseOrder[]>(this.globalService.getApiUrl() +
      '/purchase-orders?jobId=' + jobId, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.purchaseOrdersForJob = res;
        }),
        catchError(this.handleError));
  }

  getCostCentres(): Observable<PriceFileItem[]> {
    let url = this.globalService.getApiUrl() + '/price-file-items';

    url += '?priceFileItemTypeId=' + PriceFileItemTypeEnum.Group;
    url += '&includeCostCentreData=' + true;

    return this._http.get<PriceFileItem[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.costCentres = res.filter(i => !i.priceFileItemParentId);
      }),
      catchError(this.handleError));
  }

  getPurchaseOrdersAndCostCentresForJob(jobId: number): Observable<PurchaseOrder[]> {
    return forkJoin(
      [this.getPurchaseOrdersForJob(jobId),
      this.getCostCentres()]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
