import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GlobalService } from '../global.service';
import { IJobDocument } from '../../dtos/job-document';
import { JobDocumentType } from '../../dtos/job-document-type';
import { CallUpDocsType } from '../../dtos/call-up-docs-type';
import { HttpService } from '../http.service';
import { JobDocumentChanged } from '../../dtos/job-document-changed';
import { IJobDocAttachment } from '../../dtos/job-doc-attachment';
import { SharePointDocument } from '../../dtos/sharepoint-document';

@Injectable({
  providedIn: 'root'
})
export class JobDocumentService {
  private _jobItemUrl = '';
  public treeNodes: IJobDocument[] = [];
  jobDocumentTypes: JobDocumentType[];
  callUpDocsTypesCompany: number;
  callUpDocsTypes: CallUpDocsType[] = [{ id: 0, description: 'As per parent heading' }];
  cache: IJobDocAttachment[] = [];
  cacheSharePoint: SharePointDocument[] = [];

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

    getJobDocumentsWithChildren(jobNo, parentId, showDeleted: boolean = false): Observable<IJobDocument[]> {
    this._jobItemUrl = this.globalService.getApiUrl() + '/job-documents/' + jobNo + '/documents';

    if (parentId) {
      this._jobItemUrl += '?parentId=' + parentId + '&includeDeleted=' + showDeleted;
    } else {
      this._jobItemUrl += '?includeDeleted=' + showDeleted;
    }

    return this._http.get<IJobDocument[]>(this._jobItemUrl, this.httpService.getHttpOptions()).pipe(catchError(this.handleError));
  }

  getJobDocuments(jobId: number): Observable<IJobDocument[]> {
    return this._http
      .get<IJobDocument[]>(this.globalService.getApiUrl() + '/job/' + jobId + '/documents-for-client',
        this.httpService.getHttpOptions()).pipe(
          catchError(this.handleError));
  }

  getJobDocAttachment(jobDocId: number, useCache: boolean): Observable<IJobDocAttachment> {
    const img = this.cache.filter(i => i.jobDocumentId === jobDocId);
    if (useCache && img[0]) {
      return of(img[0]);
    } else {
      this._jobItemUrl = this.globalService.getApiUrl() + '/job-document-attachments/' + jobDocId;

      return this._http.get<IJobDocAttachment>(this._jobItemUrl, this.httpService.getHttpOptions())
        .pipe(
          tap(res => this.cache = this.cache.concat(res)),
          catchError(this.handleError)
        );
    }
  }

  // get a SharePoint/Google document
  getSharePointDocument(jobNumber: string, sharePointFileId: string, useCache: boolean): Observable<SharePointDocument> {
    const img = this.cacheSharePoint.filter(i => i.id === sharePointFileId);
    if (useCache && img[0]) {
      return of(img[0]);
    } else {
      this._jobItemUrl = this.globalService.getApiUrl() + '/sharepoint/job/' + jobNumber + '/get-file?sharePointFileId=' + sharePointFileId;

      return this._http.get<SharePointDocument>(this._jobItemUrl, this.httpService.getHttpOptions())
        .pipe(
          tap(res => this.cacheSharePoint.push(res)),
          catchError(this.handleError)
        );
    }
  }

  getLatestJobDocumentsForClient(jobNo: string, autoConvertHEIC: boolean = false): Observable<IJobDocument> {
    this._jobItemUrl = this.globalService.getApiUrl() + '/job-documents/' + jobNo + '/latest-document';
    if (autoConvertHEIC) 
      this._jobItemUrl += '?autoConvertHEIC=true';  
    
    return this._http.get<IJobDocument>(this._jobItemUrl, this.httpService.getHttpOptions()).pipe(catchError(this.handleError));
  }

  getJobDocumentForEmail(jobId: number, onlyAttachments: boolean): Observable<IJobDocument[]> {
    return this._http
      .get<IJobDocument[]>(this.globalService.getApiUrl() + '/job/' + jobId + '/documents?onlyAttachments=' + onlyAttachments,
        this.httpService.getHttpOptions()).pipe(
          catchError(this.handleError));
  }

  addJobDocument(jobDocument: any): Observable<IJobDocument> {
    this._jobItemUrl = this.globalService.getApiUrl() + '/job-documents/' + this.globalService.getCurrentJob() + '/document';
    return this._http.post<IJobDocument>(this._jobItemUrl, JSON.stringify(jobDocument), this.httpService.getHttpOptions());
  }

  updateJobDocument(jobDocumentId: number, jobDocument: any) {
    this._jobItemUrl = this.globalService.getApiUrl() + '/job-documents/' + jobDocumentId;
    return this._http.patch(this._jobItemUrl, JSON.stringify(jobDocument), this.httpService.getHttpOptions());
  }

  deleteJobDocument(jobDocumentId: number) {
    this._jobItemUrl = this.globalService.getApiUrl() + '/job-documents/' + jobDocumentId;
    return this._http.delete(this._jobItemUrl, this.httpService.getHttpOptions());
  }

  moveJobDocument(id, parent, index) {
    let url = null;
    if (parent) {
      url = this.globalService.getApiUrl() + '/job-documents/move/' + id + '?parentId=' + parent + '&orderNo=' + index;
    } else {
      url = this.globalService.getApiUrl() + '/job-documents/move/' + id + '?&orderNo=' + index;
    }
    const params = {
      optionListIdAbove: parent,
      orderNo: index
    };
    return this._http.patch(url, params, this.httpService.getHttpOptions());
  }

  getJobDocumentTypes() {
    const url = this.globalService.getApiUrl() + '/job-document-types';
    return this._http.get<JobDocumentType[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getJobDocumentsModified(dateFrom: string, showPDFs: boolean) {
    const url = this.globalService.getApiUrl() + '/job-document-attachments/modified?dateFrom=' + dateFrom
      + '&showPDFs=' + showPDFs;
    return this._http.get<JobDocumentChanged[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }


  sendEmail(jobId: number, ids: string[], toEmail: string, emailMessage: string, ccToSelf: boolean,
    ccEmail: string, bccEmail: string, sendAddendum: boolean, emailSubject: string, variationId: number,
    printImages: boolean, printVariationPrices: boolean, printNotApplicable: boolean, printNonPrintItems: boolean,
    printConnectedTogether: boolean, printVONumber: boolean, selectedPurchaseOrderId: number, printPrices: boolean) {
    const url = this.globalService.getApiUrl() + '/job/' + jobId + '/job-documents/emails';
    return this._http.post(
      url,
      JSON.stringify({
        jobDocumentIds: ids,
        toEmail: toEmail,
        emailMessage: emailMessage,
        ccToSelf: ccToSelf,
        ccEmail: ccEmail,
        bccEmail: bccEmail,
        sendAddendum: sendAddendum,
        emailSubject: emailSubject,
        jobVariationId: variationId,
        printImages: printImages,
        printVariationPrices: printVariationPrices,
        printNotApplicable: printNotApplicable,
        printNonPrintItems: printNonPrintItems,
        printConnectedTogether: printConnectedTogether,
        printVONumber: printVONumber,
        purchaseOrderId: selectedPurchaseOrderId,
        printPrices: printPrices
      }),
      this.httpService.getHttpOptions()
    );
  }

  copyItems(jobNumber: string, parentId: number, selectedJobDocs: any[], insertMissing: boolean) {
    this._jobItemUrl = this.globalService.getApiUrl() + '/job-documents/' + jobNumber + '/copy';

    if (parentId) {
      this._jobItemUrl += '?parentId=' + parentId;

      if (insertMissing) {
        this._jobItemUrl += '&insertMissing=true';
      }
    } else if (insertMissing) {
      this._jobItemUrl += '?insertMissing=true';
    }

    return this._http.post(this._jobItemUrl, JSON.stringify({ selectedJobItemIds: selectedJobDocs }), this.httpService.getHttpOptions());
  }


  getCallUpDocsTypes(useCache: boolean): Observable<CallUpDocsType[]> {
    if (useCache && this.callUpDocsTypesCompany === this.globalService.getCurrentCompany().id
      && this.callUpDocsTypes && this.callUpDocsTypes.length) {
      return of(this.callUpDocsTypes);
    } else {
      const url = this.globalService.getApiUrl() + '/call-up-docs-types/';
      return this._http.get<CallUpDocsType[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.callUpDocsTypes = this.callUpDocsTypes.concat(res);
          this.callUpDocsTypesCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  getJobDocsData(): Observable<JobDocumentType[]> {
    return forkJoin(
      [
        this.getJobDocumentTypes(),
        this.getCallUpDocsTypes(true)
      ]
    )
      .pipe(map(
        ([taskMasters]) => {
          return taskMasters;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
