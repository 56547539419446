import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GlobalService } from '../global.service';
import { IJobDocAttachment } from '../../dtos/job-doc-attachment';
import { HttpService } from '../http.service';
import { JobDocAttachmentVersion } from '../../dtos/job-doc-attachment-version';
import { IJobDocument } from '../../dtos/job-document';
import { SharePointDocument } from '../../dtos/sharepoint-document';

@Injectable({
  providedIn: 'root'
})
export class JobDocumentAttachmentService {
  private _jobItemUrl = '';
  cache: IJobDocAttachment[] = [];
  cacheSharePoint: SharePointDocument[] = [];

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  getJobDocAttachment(jobDocId: number, useCache: boolean, autoConvertHEIC: boolean = false ): Observable<IJobDocAttachment> {
    const img = this.cache.filter(i => i.jobDocumentId === jobDocId);
    if (useCache && img[0]) {
      return of(img[0]);
    } else {
      this.deleteCachedImage(jobDocId);
      this._jobItemUrl = this.globalService.getApiUrl() + '/job-document-attachments/' + jobDocId;
      if (autoConvertHEIC) 
        this._jobItemUrl += '?autoConvertHEIC=true';
      return this._http.get<IJobDocAttachment>(this._jobItemUrl, this.httpService.getHttpOptions())
        .pipe(
          tap(res => this.cache = this.cache.concat(res)),
          catchError(this.handleError)
        );
    }
  }

  deleteCachedImage(jobDocumentId: number) {
    const attachmentIndex = this.cache.findIndex(i => i.jobDocumentId === jobDocumentId);
    if (attachmentIndex >= 0) {
      // remove the record from the array if it exists
      this.cache.splice(attachmentIndex, 1);
    }
  }

  patchJobDocAttachment(jobDocAttachmentId: number, jobDocAttachment: any) {
    this._jobItemUrl = this.globalService.getApiUrl() + '/job-document-attachments/' + jobDocAttachmentId;
    return this._http.patch(this._jobItemUrl, jobDocAttachment, this.httpService.getHttpFileOptions())
      .pipe(
        tap(() => {
          this.deleteCachedImage(jobDocAttachmentId);
        }),
        catchError(this.handleError)
      );
  }

  deleteJobDocAttachment(jobDocAttachmentId: number): Observable<IJobDocument> {
    this._jobItemUrl = this.globalService.getApiUrl() + '/job-document-attachments/' + jobDocAttachmentId;
    return this._http.delete<IJobDocument>(this._jobItemUrl, this.httpService.getHttpOptions())
      .pipe(
        tap(() => {
          this.deleteCachedImage(jobDocAttachmentId);
        }),
        catchError(this.handleError)
      );
  }

  getJobDocAttachmentVersions(jobDocId: number): Observable<JobDocAttachmentVersion[]> {
    this._jobItemUrl = this.globalService.getApiUrl() + '/job-document-attachments/' + jobDocId + '/versions';

    return this._http.get<JobDocAttachmentVersion[]>(this._jobItemUrl, this.httpService.getHttpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  getJobDocAttachmentVersion(jobDocId: number, fileName: string, version: string): Observable<IJobDocAttachment> {
    this._jobItemUrl = this.globalService.getApiUrl() + '/job-document-attachments/' + jobDocId + '/version';
    this._jobItemUrl += '?version=' + version;

    return this._http.patch<IJobDocAttachment>(this._jobItemUrl, JSON.stringify({ fileName: fileName }), this.httpService.getHttpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }


  // get a SharePoint document
  getSharePointDocument(jobNumber: string, sharePointFileId: string, useCache: boolean, autoConvertHEIC: boolean = false): Observable<SharePointDocument> {
    const img = this.cacheSharePoint.filter(i => i.id === sharePointFileId);
    if (useCache && img[0]) {
      return of(img[0]);
    } else {
      this._jobItemUrl = this.globalService.getApiUrl() + '/sharepoint/job/' + jobNumber + '/get-file?sharePointFileId=' + sharePointFileId;
      if (autoConvertHEIC) {
        this._jobItemUrl += '&autoConvertHEIC=true';
      }

      return this._http.get<SharePointDocument>(this._jobItemUrl, this.httpService.getHttpOptions())
        .pipe(
          tap(res => this.cacheSharePoint.push(res)),
          catchError(this.handleError)
        );
    }
  }

  uploadDocument(jobDocumentId: string, jobDocAttachment: any, jobNumber: string): Observable<string> {
    const url = this.globalService.getApiUrl() + '/job-document-attachments/' + jobDocumentId + '?jobNumber=' + jobNumber;
    return this._http.post<string>(url, jobDocAttachment, this.httpService.getHttpFileOptions())
      .pipe(catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
