import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';

export class Step {
  label: string;
  desc: string;
}

@Component({
  selector: 'js-progress-tracker',
  templateUrl: './progress-tracker.component.html',
  styleUrls: ['./progress-tracker.component.scss']
})
export class ProgressTrackerComponent implements OnInit, OnChanges {
  @Input() activeStepLabel: string;
  @Input() steps: Step[];
  @Input() maxVisibleSteps = 5;

  visibleSteps: Step[];
  activeStepIndex = 0;
  visibleActiveStepIndex = 0;
  truncatedMode = false;
  truncationPossible = false;

  phoneSize = false;
  desktopSize = false;
  subscriptions: Subscription[] = [];

  constructor(private globalService: GlobalService) { }

  ngOnInit() {
    this.subscribeToWidthChanges();
  }

  ngOnChanges() {
    if (this.steps) {
      this.steps.map(s => {
        if (!s.desc || s.desc === '') {
          s.desc = s.label;
        }
      });
      for (let i = 0; i < this.steps.length; i++) {
        if (this.steps[i].label === this.activeStepLabel) {
          break;
        }
        this.activeStepIndex++;
      }

      this.checkForTruncation();
    }
  }

  checkForTruncation() {
    if (this.steps.length > this.maxVisibleSteps) {
      this.truncationPossible = true;
      this.truncatedMode = true;
      const start = this.activeStepIndex === 0 ? 0
        : this.activeStepIndex - 1;
      const end = this.activeStepIndex === 0 ? this.activeStepIndex + this.maxVisibleSteps
        : this.activeStepIndex + (this.maxVisibleSteps - 1);
      this.visibleSteps = this.steps.slice(start, end);
      if (this.activeStepIndex !== 0) {
        this.visibleActiveStepIndex = 1;
      }
    } else {
      this.visibleSteps = this.steps;
    }
  }

  toggleViewMode() {
    this.truncatedMode = !this.truncatedMode;
    if (!this.truncatedMode) {
      this.visibleSteps = this.steps;
      this.visibleActiveStepIndex = this.activeStepIndex;
    } else {
      this.checkForTruncation();
    }
  }

  getToggleText(): string {
    if (this.phoneSize && this.truncatedMode) {
      return 'View All';
    } if (!this.phoneSize && this.truncatedMode) {
      return 'View All Activities';
    } if (this.phoneSize && !this.truncatedMode) {
      return 'View Relevant';
    } if (!this.phoneSize && !this.truncatedMode) {
      return 'View Relevant Activities';
    }
  }

  progressStepClass(index: number) {
    let classes: string;
    if (this.isComplete(index)) {
      classes = 'is-complete';
    } else if (this.isActive(index)) {
      classes = this.phoneSize ? 'is-active' : 'is-active is-progress-70';
    } else {
      classes = '';
    }
    if (this.desktopSize) {
      classes += ' hover-step';
    }
    return classes;
  }

  progressTextClass(index: number) {
    let classes: string;
    if (this.isComplete(index)) {
      classes = 'text-is-complete';
    } else if (this.isActive(index)) {
      classes = 'text-is-active bold';
    } else {
      classes = '';
    }
    if (this.desktopSize) {
      classes += ' larger-text';
    }
    return classes;
  }

  tooltip(index: number): string {
    const status = this.progressStepClass(index);
    const statusText = status.indexOf('is-complete') > -1 ? 'COMPLETE'
      : status.indexOf('is-active') > -1 ? 'IN-PROGRESS'
        : 'NOT STARTED';

    return `
    ${this.visibleSteps[index].desc}

    Status: ${statusText}
    `;
  }

  isComplete(i: number) {
    return i < this.visibleActiveStepIndex;
  }
  isActive(i: number) {
    return i === this.visibleActiveStepIndex;
  }

  subscribeToWidthChanges() {
    this.phoneSize = this.globalService.phoneSize;
    this.desktopSize = this.globalService.desktopSize;

    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe((width: number) => {
        this.phoneSize = this.globalService.phoneSize;
        this.desktopSize = this.globalService.desktopSize;
      })
    );
  }
}
