import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Subscription } from 'rxjs';
import { JobDocumentService } from '../services/felixApi/job-document.service';
import { CallUpDocsType } from '../dtos/call-up-docs-type';
import { GlobalService } from '../services/global.service';
import { DESKTOP_SIZE } from '../../config/variables';
import { ConfigurationService } from '../services/felixApi/configuration.service';
import { JobService } from '../services/felixApi/job.service';

@Component({
  selector: 'js-job-documents',
  templateUrl: './job-documents.component.html',
  styleUrls: ['./job-documents.component.scss']
})
export class JobDocumentsComponent implements OnInit {

  jobNumber: string;
  showImages = true;
  showDeleted = false;
  componentWidth: number; // to fit the show images checkbox
  isClientOrAssociate = true;
  isAdmin = false;
  changeJobNum = true;
  subscriptions: Subscription[] = [];
  loading = true;
  desktop = false;
  showBackgroundLogo: boolean;
  callUpDocsTypes: CallUpDocsType[];

  constructor(
    public jobDocumentService: JobDocumentService,
    private _authService: AuthService,
    private jobService: JobService,
    private configurationService: ConfigurationService,
    private _globalService: GlobalService) { }

  ngOnInit() {
    this.subscribeToWidthChanges();

    if (!this._authService.isClient() && !this._authService.isAssociate()) {
      this.isClientOrAssociate = false;
    }
    if (this._authService.isAdminOrSuperUser()) {
      this.isAdmin = true;
    }

    this.readJobDocumentTypes();
  }

  subscribeToWidthChanges() {
    this.setWidth(this._globalService.innerWidth);

    this.subscriptions = this.subscriptions.concat(
      this._globalService.innerWidthChanged.subscribe((width: number) => {
        this.setWidth(width);
      })
    );
  }

  setWidth(innerWidth: number) {
    if (innerWidth >= DESKTOP_SIZE) {
      this.desktop = true;
    } else {
      this.desktop = false;
    }
  }

  refreshJobNumber(jobNo: string) {
    this.jobNumber = jobNo;
    this.changeJobNum = !this.changeJobNum;

    if (this.jobNumber && this.jobNumber !== '') {
      this.showBackgroundLogo = false;
    } else {
      this.showBackgroundLogo = true;
    }

    this.getJobRolesHouseTypesClaims();
  }

  readJobDocumentTypes() {
    this.jobDocumentService.jobDocumentTypes = []; // reset in case we are changing companies

    this.subscriptions = this.subscriptions.concat(
      this.jobDocumentService.getJobDocsData().subscribe({
        next: (jobDocumentTypes) => {
          this.jobDocumentService.jobDocumentTypes = jobDocumentTypes;
          this.callUpDocsTypes = this.jobDocumentService.callUpDocsTypes;
          this.loading = false;
        },
        error: (err) => {
          // assume none set up
          console.log(err);
          this.loading = false;
        }
      })
    );
  }

  getJobRolesHouseTypesClaims() {
    if (this.jobService.currentJob) {
      this.subscriptions = this.subscriptions.concat(
        this.configurationService.getJobRolesHouseTypesClaims(this.jobService.currentJob.id, this.jobService.currentJob.jobNumber)
          .subscribe({
            next: () => {
            },
            error: (err) => {
              console.log(err);
            }
          })
      );
    }
  }
}
