import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../services/global.service';
import { JobVarItemService } from '../../services/felixApi/job-var-item.service';
import { IJobItem } from '../../dtos/job-item';
import { ITreeOptions, TreeComponent } from '@circlon/angular-tree-component';
import { JobItemService } from '../../services/felixApi/job-item.service';
import { ItemTypeEnum } from '../../dtos/item-type.enum';
import { IJobItemWithChildren } from '../../dtos/job-item-with-children';
import { SelectedJobItems } from '../../dtos/selectedJobItemIds';
import { ChangeTypeEnum } from '../../dtos/change-type.enum';
import { JobVarTypeEnum } from '../../dtos/job-var-type.enum';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-connect-items-modal',
  templateUrl: './connect-items-modal.component.html',
  styleUrls: ['./connect-items-modal.component.scss']
})
export class ConnectItemsModalComponent implements OnInit, OnDestroy {
  @Input() jobitem: IJobItem;
  @Input() variationId: number;

  subscriptions: Subscription[] = [];
  errorMessage: any;
  formErrorMessage = '';
  connectForm: UntypedFormGroup;
  treeOptions: ITreeOptions;
  loading = true;
  oneNode: IJobItemWithChildren = new IJobItemWithChildren;
  treeNodes: IJobItemWithChildren[] = [];
  itemTypeEnum = ItemTypeEnum;
  indexNo = 0;
  itemsToSet: any[] = [];
  itemsToSetDto: SelectedJobItems;
  firstSelectedId: number;
  changeTypeEnum = ChangeTypeEnum;
  parentChecked: boolean;
  checked: boolean;
  id: number;
  jobVarTypeEnum = JobVarTypeEnum;
  itemFound: number;

  COMPONENT_NAME = 'ConnectItemsModalComponent';

  @ViewChild('tree') tree: TreeComponent;
  treeHeight: number;
  variationItemNumber: number;

  constructor(private _globalService: GlobalService,
    private _jobItemService: JobItemService,
    private _jobVarItemService: JobVarItemService,
    private notiService: NotificationService,
    private _formBuilder: UntypedFormBuilder, private _activeModal: NgbActiveModal) { }


  ngOnInit(): void {
    // create root form group for search fields
    // and create form controls for each field
    // console.log('Item: ' + JSON.stringify(this.jobitem));

    this.treeHeight = window.innerHeight - 325;

    this.connectForm = this._formBuilder.group({
      itemDescription: this.jobitem.itemDescription,
      selection: this.jobitem.selection
    });

    this.setupJobItemNodes();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  // set up the tree nodes for the add/edit item
  setupJobItemNodes() {
    this.treeOptions = {
      idField: 'id',
      displayField: 'description',
      childrenField: 'children',
      useVirtualScroll: true,
      nodeHeight: 22,
      scrollOnActivate: false
    };
    // get the items from this VO only - create from list in memory
    this.treeNodes = [];
    this._jobItemService.currentJobItems.forEach(item => {
      if (item.jobItemAboveId === null && (!item.isHistoryRecord || item.changeTypeId === this.changeTypeEnum.Delete)) {

        if ((item.changedByVOId === this.variationId &&
          (item.connectedItemId === null
            || item.connectedItemId === this.jobitem.changedByJobVarId))
          || (item.itemTypeId === this.itemTypeEnum.Heading &&
            this.setupJobItemNodesCheckHeading(item))) {

          if (item.itemTypeId === this.itemTypeEnum.Heading) {
            this.oneNode.children = this.setupJobItemNodesChildren(item);
          } else {
            this.oneNode.children = [];
          }
          if (!item.itemDescription || item.itemDescription === '') {
            this.oneNode.description = item.selection;
          } else if (item.selection) {
            this.oneNode.description = item.itemDescription + ' - ' + item.selection;
          } else {
            this.oneNode.description = item.itemDescription;
          }

          // if the item has not been changed use the original id
          if (item.changedByJobVarId && item.changedByJobVarId !== 0) {
            this.id = item.changedByJobVarId;
          } else {
            this.id = item.id;
          }
          if (item.connectedItemId === this.jobitem.changedByJobVarId) {
            this.checked = true;
            if (!this.firstSelectedId) {
              this.firstSelectedId = this.id;
            }
          } else {
            this.checked = false;
          }

          this.treeNodes.push({
            id: this.id,
            itemTypeId: item.itemTypeId,
            changeTypeId: item.changeTypeId,
            jobVarTypeId: item.jobVarTypeId,
            itemChangedId: item.itemChangedId,
            description: this.oneNode.description,
            originalItemTypeId: item.originalItemTypeId,
            originalItemId: item.id,
            connectedItemId: item.connectedItemId,
            children: this.oneNode.children,
            parentId: null,
            changedByVOId: item.changedByVOId,
            changedByJobVarId: item.changedByJobVarId,
            masterItemId: item.masterItemId,
            hasLinkedItems: item.hasLinkedItems,
            optionNumber: item.optionNumber,
            checked: this.checked,
            indeterminate: false,
            isChecked: item.isChecked,
            variationItemNumber: item.variationItemNumber
          });
        }
      }
    });

    // console.log('setupJobItemNodes - treeNodes = ' + JSON.stringify(this.treeNodes));
    this.loading = false;
  }

  setupJobItemNodesCheckHeading(item: IJobItem): boolean {
    // check if this heading has any children in this VO so we can add
    let returnVal = false;
    this._jobItemService.currentJobItems.some(elementToCheck => {
      if (elementToCheck.jobItemAboveTypeId === item.originalItemTypeId &&
        elementToCheck.jobItemAboveId === item.id && !returnVal) {
        if (elementToCheck.changedByVOId === this.variationId && this.validEntry(elementToCheck)) {
          returnVal = true;
          return true;
        }
        if (elementToCheck.itemTypeId === this.itemTypeEnum.Heading) {
          if (this.setupJobItemNodesCheckHeading(elementToCheck)) {
            returnVal = true;
            return true;
          }
        }
      }
    });
    return returnVal;
  }

  setupJobItemNodesChildren(node): IJobItemWithChildren[] {
    // recursively check the children

    const childrenNodes: IJobItemWithChildren[] = [];
    const childNode: IJobItemWithChildren = new IJobItemWithChildren;
    this._jobItemService.currentJobItems.forEach(element => {
      if (element.isHistoryRecord || element.changeTypeId === this.changeTypeEnum.Add) {
        this.variationItemNumber = element.variationItemNumber;
      }

      if (element.jobItemAboveTypeId === node.originalItemTypeId && element.jobItemAboveId === node.id
        && (!element.isHistoryRecord || element.changeTypeId === this.changeTypeEnum.Delete)) {

        if (element.originalItemTypeId !== this.jobitem.originalItemTypeId || element.id !== this.jobitem.id) {

          if (((element.changedByVOId === this.variationId && this.validEntry(element)) &&
            (!element.isHistoryRecord || element.changeTypeId === this.changeTypeEnum.Delete))
            || (element.itemTypeId === this.itemTypeEnum.Heading &&
              this.setupJobItemNodesCheckHeading(element))) {

            if (element.itemTypeId === this.itemTypeEnum.Heading) {
              childNode.children = this.setupJobItemNodesChildren(element);
            } else {
              childNode.children = [];
            }
            if (!element.itemDescription || element.itemDescription === '') {
              childNode.description = element.selection;
            } else if (element.selection) {
              childNode.description = element.itemDescription + ' - ' + element.selection;
            } else {
              childNode.description = element.itemDescription;
            }

            // if the item has not been changed use the original id
            if (element.changedByJobVarId && element.changedByJobVarId !== 0) {
              this.id = element.changedByJobVarId;
            } else {
              this.id = element.id;
            }
            if (element.connectedItemId === this.jobitem.changedByJobVarId) {
              this.checked = true;
              if (!this.firstSelectedId) {
                this.firstSelectedId = this.id;
              }
            } else {
              this.checked = false;
            }

            // add manually as the point will not work.
            childrenNodes.push({
              id: this.id,
              itemTypeId: element.itemTypeId,
              changeTypeId: element.changeTypeId,
              jobVarTypeId: element.jobVarTypeId,
              itemChangedId: element.itemChangedId,
              description: childNode.description,
              originalItemTypeId: element.originalItemTypeId,
              originalItemId: element.id,
              connectedItemId: element.connectedItemId,
              children: childNode.children,
              parentId: node.id,
              changedByVOId: element.changedByVOId,
              changedByJobVarId: element.changedByJobVarId,
              masterItemId: element.masterItemId,
              hasLinkedItems: element.hasLinkedItems,
              optionNumber: element.optionNumber,
              checked: this.checked,
              indeterminate: false,
              isChecked: element.isChecked,
              variationItemNumber: element.itemTypeId === ItemTypeEnum.Heading ? null : this.variationItemNumber
            });
          }
        }
      }
    });
    return childrenNodes;
  }

  validEntry(item: IJobItem): boolean {
    if ((this.jobitem.linkedJobItemId && this.jobitem.linkedJobItemId === item.changedByJobVarId)
      || (this.jobitem.changedByJobVarId && this.jobitem.changedByJobVarId === item.linkedJobItemId)
      || (this.jobitem.masterItemId && this.jobitem.masterItemId === item.changedByJobVarId)
      || (this.jobitem.changedByJobVarId && this.jobitem.changedByJobVarId === item.masterItemId)
      || (this.jobitem.masterItemId && this.jobitem.masterItemId === item.masterItemId)
      || (this.jobitem.varOptionId && this.jobitem.varOptionId === item.varOptionId)
      || (item.connectedItemId && item.connectedItemId !== this.jobitem.changedByJobVarId)) {
      return false;
    } else {
      return true;
    }
  }

  treeNodeClicked(node, $event) {
    // if the parent node is ticked dissallow the untick of a child item - otherwise how will the heading be shown
    // console.log('in treeNodeClicked - node: ' + JSON.stringify(node.data));
    if (node.data.changedByVOId !== this.variationId) {
      // alert('Cannot select this item as it has not been added or changed in this variation.');
      this.notiService.showWarning('Cannot select this item as it has not been added or changed in this variation.');
      // this.tree.treeModel.getNodeById(node.id).data.checked = !$event.target.checked;
      node.data.checked = false;
      node.setIsHidden(true); // do twice to force the update of the tree
      node.setIsHidden(false);
    } else {
      if (node.data.parentId) {
        // check if parent ticked
        this.parentChecked = this.tree.treeModel.getNodeById(node.id).parent.data.checked;
      } else {
        this.parentChecked = false;
      }

      if (this.parentChecked) {
        // alert('Cannot deselect and item where the heading above has been selected.');
        this.notiService.showWarning('Cannot deselect and item where the heading above has been selected.');
        // this.tree.treeModel.getNodeById(node.id).data.checked = $event.target.checked;
        node.data.checked = true;
        node.setIsHidden(true);
        node.setIsHidden(false);
        // this.tree.treeModel.getNodeById(node.id).parent.toggleExpanded();
        // this.tree.treeModel.getNodeById(node.id).parent.toggleExpanded();
        // this.tree.treeModel.update();
      } else {
        if (node.children) {
          node.children.forEach((child) => this.checkAllChildren(child, $event.target.checked));
          node.expandAll();
        }
        node.data.checked = !node.data.checked;

        // if we have linked items we keep together so tick/untick them automatically
        if (node.data.masterItemId !== null) {
          node.parent.children.forEach((child) => {
            if ((child.data.originalItemId === node.data.masterItemId || child.data.masterItemId === node.data.masterItemId) &&
              (child.data.optionNumber === node.data.optionNumber)) {
              child.data.checked = node.data.checked;
            }
          });
        } else if (node.data.hasLinkedItems) {
          node.parent.children.forEach((child) => {
            if ((child.data.masterItemId === node.data.originalItemId) &&
              (child.data.optionNumber === node.data.optionNumber)) {
              child.data.checked = node.data.checked;
            }
          });
        }
      }
    }
  }

  checkAllChildren(node, checkValue) {
    node.data.checked = checkValue;
    if (node.children) {
      node.children.forEach((child) => this.checkAllChildren(child, checkValue));
    }
  }

  onTreeInit(tree: TreeComponent) {
    if (this.firstSelectedId) {
      tree.treeModel.getNodeById(this.firstSelectedId)?.setActiveAndVisible();
    }

    tree.treeModel.virtualScroll.setViewport(tree.treeModel.virtualScroll.viewport);
  }

  save() {
    // save the items
    this.itemsToSet = [];
    for (let i = 0; i < this.tree.treeModel.nodes.length; i++) {
      if (this.tree.treeModel.getNodeById(this.tree.treeModel.nodes[i].id).data.checked === true) {
        this.itemsToSet = this.itemsToSet.concat(+this.tree.treeModel.nodes[i].id);
      }
      if (this.tree.treeModel.nodes[i].children) {
        this.tree.treeModel.nodes[i].children.forEach((child) => this.checkAddItemsToCopy(child));
      }
    }

    this.itemsToSetDto = { selectedJobItemIds: this.itemsToSet };

    // console.log('save - this.itemsToSet: ' + JSON.stringify(this.itemsToSet));
    this.loading = true;
    this.subscriptions = this.subscriptions.concat(
      this._jobVarItemService.setConnectedItems(this.variationId, this.jobitem.changedByJobVarId, this.itemsToSetDto).subscribe({
        next: () => {
          // update the local lists
          this._jobItemService.currentJobItemsUnfiltered.forEach(element => {
            // first reset this one
            if (element.connectedItemId === this.jobitem.changedByJobVarId) {
              element.connectedItemId = null;
            }

            // then check if it's in
            this.itemFound = this.itemsToSet.filter(i => i === element.changedByJobVarId)[0];
            if (this.itemFound) {
              element.connectedItemId = this.jobitem.changedByJobVarId;
            }
          });

          this._jobItemService.currentJobItems.forEach(element => {
            // first reset this one
            if (element.connectedItemId === this.jobitem.changedByJobVarId) {
              element.connectedItemId = null;
            }

            // then check if it's in
            this.itemFound = this.itemsToSet.filter(i => i === element.changedByJobVarId)[0];
            if (this.itemFound) {
              element.connectedItemId = this.jobitem.changedByJobVarId;
            }
          });
          this.cancel();

          if (this.itemsToSet && this.itemsToSet.length) {
            this.jobitem.hasConnectedItems = true;
          } else {
            this.jobitem.hasConnectedItems = false;
          }
        },
        error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  checkAddItemsToCopy(node) {
    if (this.tree.treeModel.getNodeById(node.id).data.checked === true) {
      this.itemsToSet = this.itemsToSet.concat(+node.id);
    }
    if (node.children) {
      node.children.forEach((child) => this.checkAddItemsToCopy(child));
    }
  }

  cancel() {
    this._activeModal.close();
  }
}
