import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {

    constructor(private toastr: ToastrService) { }

    showSuccess(message, title = null) {
        this.toastr.success(message, title);
    }

    showError(message, title = null) {
        this.toastr.error(message, title, {disableTimeOut: true, closeButton: true});
    }

    showInfo(message, title = null) {
        this.toastr.info(message, title);
    }

    showWarning(message, title = null) {
        this.toastr.warning(message, title);
    }

    showWarningNoTimeOut(message, title = null) {
        this.toastr.warning(message, title, {disableTimeOut: true, closeButton: true});
    }

    notify(error: HttpErrorResponse, title = null, disableTimeOut: boolean = false) {
        let msg;
        if (error.statusText !== 'handled') {
            if (error.status === 422) {
                if (error.error && error.error.message) {
                    msg = error.error.message;
                } else {
                    msg = 'There was an unexpected error. Please try again in a moment.';
                }
                this.toastr.warning(msg, title, {disableTimeOut: disableTimeOut, closeButton: true});
            } else if (error.status === 403) {
                msg = 'Permission denied. Please see your administrator.';
                this.toastr.warning(msg, title, {disableTimeOut: disableTimeOut, closeButton: true});
            } else {
                msg = 'There was an unexpected error. Please try again in a moment.';
                this.toastr.error(msg, title, {disableTimeOut: disableTimeOut, closeButton: true});
            }
            console.log(error);
        }
    }

    /* when failed API call */
    // apiError(error: any) {
    //     let errorMsg = '';
    //     if (error) {
    //         if (!error.error || !error.error.message) {
    //             errorMsg = errorMsg + '. STATUS: ' + error.status + '. NO MSG FOUND PRINTING ERROR OBJ: ' + JSON.stringify(error);
    //         } else {
    //             errorMsg = errorMsg + '. STATUS: ' + error.status + '. MSG: ' + error.error.message;
    //         }
    //     }

    //     let errorMessage = errorMsg;
    //     if (error && error.error && error.error.message) {
    //         errorMessage = error.error.message;
    //     }
    //     this.showError(errorMessage);
    // }
}
