<div class="card">

    <div class="card-header">
        <span>Invoices for {{vendorName}}</span>
    </div>

    <div class="card-body">
        <table class="table table-sm">
            <thead class="rowclass">
                <tr>
                    <th scope="col">Invoice Number</th>
                    <th scope="col">Invoice Date</th>
                    <th scope="col">Order Number</th>
                    <th scope="col" class="rightText">Total Inc GST</th>
                    <th *ngIf="isWideScreenWidth" scope="col">Entered By</th>
                    <th *ngIf="isWideScreenWidth" scope="col">Date Entered</th>
                    <th scope="col">Status</th>
                </tr>
            </thead>

            <div *ngIf="errorMessage">
                {{ errorMessage }}
            </div>

            <!-- Image Loading Spinner -->
            <js-loading-spinner *ngIf="loading"></js-loading-spinner>

            <tbody *ngIf='invoices && invoices.length && !loading' class="rowclass">
                <tr *ngFor='let invoice of invoices' class="rowclass">
                    <ng-container>
                        <td>
                            <button class='btn btn-sm btn-outline-primary btnselect'
                                (click)='openInvoicePDF(invoice.id)'>{{ invoice.invoiceNumber }}</button>
                        </td>
                        <td>{{ invoice.invoiceDate | date }}</td>
                        <td>{{ invoice.orderNumber }}</td>
                        <td class="rightText">{{ invoice.totalIncGST | number : '1.2-2' }}</td>
                        <td *ngIf="isWideScreenWidth">
                            <span *ngIf="invoice.createUserFirstName && invoice.createUserFirstName !== ''">
                                {{ invoice.createUserFirstName }}
                            </span>
                            {{ invoice.createUserLastName }}
                        </td>
                        <td *ngIf="isWideScreenWidth">{{ invoice.createDate | date }}</td>
                        <td>{{ invoice.invoiceStatus }}</td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="validVendor && !loading" class="card-footer">
        <button class="btn btn-sm btn-primary mt-2" (click)="addInvoice()">
            Upload New Invoice
        </button>
    </div>

</div>