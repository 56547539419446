export class IncomeInvoice {
    public id: number;
    public jobId: number;
    public incomeInvoiceTypeId: number;
    public claimJobLineId: number;
    public jobVariationId: number;
    public variationSplitId: number;
    public orderNumber: number;
    public invoiceDate: string;
    public dueDate: Date;
    public totalExGST: number;
    public totalGST: number;
    public totalIncGST: number;
    public incomeInvoiceStatusId: number;
    public modifiedDate: Date;
    public modifiedUserId: number;
    public selectedCode: string;
    public totalPaid: number;
    public fullyPaidOnDate: Date;
    public lastPaidDate: Date;
    public description: string;

    public claimAmount: number;
    public claimNumber: string;

    constructor(incomeInvoiceTypeId: number, description: string, claimAmount: number, claimNumber: string) {
      this.id = 0;
      this.incomeInvoiceTypeId = incomeInvoiceTypeId;
      this.description = description;
      this.claimAmount = claimAmount;
      this.claimNumber = claimNumber;
    }
}

export enum IncomeInvoiceTypeEnum {
  Deposit = 1,
  Claim = 2,
  Variation = 3
}
