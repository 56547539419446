<div class="modal-header">
  <h4 class="modal-title" placement="bottom">
    Variation {{displayedVariationNumber}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<js-loading-spinner *ngIf="updatingVariation"></js-loading-spinner>

<div *ngIf="!updatingVariation" class="modal-body">

  <dx-tree-list #treeList id="tree-list" [dataSource]="treeListDataSource" keyExpr="key" [height]="gridHeight"
    parentIdExpr="parent" [remoteOperations]="false" [showRowLines]="true" [showColumnLines]="true"
    [allowColumnResizing]="true" [autoExpandAll]="true" [showBorders]="true" [columnAutoWidth]="false" [rootValue]=null
    [expandedRowKeys]="[0]" [wordWrapEnabled]="true" (onCellPrepared)="onCellPrepared($event)"
    (onEditorPreparing)="onEditorPreparing($event)" (onToolbarPreparing)="onToolbarPreparing($event)">

    <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>

    <dxo-editing [mode]="editMode" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false"
      [useIcons]="true" refreshMode="full"></dxo-editing>

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"> </dxo-paging>

    <dxi-column dataField="itemDescription" caption="Item" [allowEditing]="false" [minWidth]="150"> </dxi-column>

    <dxi-column dataField="optionNumber" caption="Option" [allowEditing]="false" [width]="65" alignment="center">
    </dxi-column>

    <dxi-column dataField="selection" [allowEditing]="false" [minWidth]="400"> </dxi-column>

    <dxi-column dataField="costTypeId" caption="Price Type" [allowEditing]="false" [width]="100" alignment="right">
      <dxo-lookup [dataSource]="costTypes" valueExpr="id" displayExpr="name">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="quantity" dataType="number" [allowEditing]="false" [width]="70" format="#,###">
    </dxi-column>

    <dxi-column dataField="price" caption="Total Price (Inc.GST)" [allowEditing]="false" dataType="number" [width]="90"
      format="#,###"> </dxi-column>

    <dxi-column dataField="totalOfLinePrices" caption="Total Estimated Sales Price" dataType="number"
      [allowEditing]="false" [width]="90" format="#,###">
    </dxi-column>

    <dxi-column dataField="costTotal" caption="Estimated Cost (Ex.GST)" dataType="number" [allowEditing]="false"
      [width]="90" format="#,###">
    </dxi-column>

    <dxi-column dataField="itemNotTaken" dataType="boolean" [allowEditing]="false" [width]="60"> </dxi-column>

    <dxi-column dataField="manualCost" caption="Cost Used (Ex.GST)" dataType="number" [width]="90" [format]="{ type: 'fixedPoint', precision: 2 }"
      [editorOptions]="{ showClearButton: true }">
    </dxi-column>

    <dxi-column dataField="isIgnoreCosts" caption="Ignore this cost" dataType="boolean" [width]="70"> </dxi-column>

    <dxi-column dataField="salesRepGiveAway" caption="Sales Give Away" dataType="number" [width]="90" format="#,###"
      [editorOptions]="{ showClearButton: true }">
    </dxi-column>

    <dxi-column dataField="isChecked" caption="Checked" dataType="boolean" [width]="70"> </dxi-column>

    <dxi-column dataField="hasLinkedItems" dataType="boolean" [visible]="false"> </dxi-column>

    <dxi-column dataField="itemTypeId" [visible]="false"></dxi-column>

    <dxi-column type="buttons" [width]="90">
      <dxi-button name="edit" [visible]="isEditIconVisible"></dxi-button>
      <dxi-button [visible]="isEditIconVisible" template="editCommand">
        <div class="inlineClass" *dxTemplate="let data of 'editCommand'">
          <i class="material-icons calcButton" (click)="estimatePrice(data)">keyboard</i>
        </div>
      </dxi-button>
    </dxi-column>
  </dx-tree-list>

  <div class="totalLine">
    <div class="totalClass">
      <div class="priceClass"><span class="me-5">Admin Fee:</span>{{ adminFee | number : '1.0-0' }}</div>
      <div class="costClass"></div>
      <div class="manualCostClass"></div>
      <div class="markupClass"></div>
    </div>
  </div>

  <div class="totalLine">
    <div class="totalClass">
      <div class="priceClass">{{ totalPrice | number : '1.0-0' }}</div>
      <div class="costClass">{{ totalCost | number : '1.0-0' }}</div>
      <div class="manualCostClass">{{ totalManualCost | number : '1.2-2' }}</div>
      <div class="markupClass">Markup: {{ markupPercent | number : '1.2-2' }}%</div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button *ngIf="variationStatusId < variationStatusEnum.Cancelled" class="btn btn-secondary"
    (click)="approveVariation()">Approve Variation</button>
  <button class="btn btn-primary" (click)="close()">Close</button>
</div>


<dx-popup class="popup" [width]="350" [height]="200" [showTitle]="true" title="Recalc All Estimates"
  [dragEnabled]="true" [shading]="false" [hideOnOutsideClick]="true" [(visible)]="repricePopupVisible">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <div *ngIf="!loading">
    <div class="dx-field">
      <div class="dx-field-label">Date:</div>
      <div class="dx-field-value">
        <dx-date-box type="date" displayFormat="dd-MMM-yy" [width]="125" [calendarOptions]="{ showTodayButton: true }"
          [(value)]="newDate"></dx-date-box>
      </div>

      <dx-button class="generateButton mt-3" text="Go" type="default" (onClick)="runReprice()"></dx-button>
    </div>
  </div>
</dx-popup>
