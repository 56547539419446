import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { JobRole } from '../../dtos/job-role';
import { User } from '../../dtos/user';
import { JobRoleUpdated } from '../../dtos/job-role-updated';
import { JobService } from '../../services/felixApi/job.service';
import { NotificationService } from '../../services/notification.service';
import { CompanyRoleUser } from '../../dtos/companyRoleUser';
import { UserService } from '../../services/felixApi/user.service';

@Component({
  selector: 'js-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss']
})
export class SelectUserComponent implements OnInit, OnDestroy {
  @Input() jobRole: JobRole;
  @Input() jobNum: string;

  @Output() updating: EventEmitter<boolean> = new EventEmitter<boolean>(); // sends updating message to parent component

  loading = true;
  roleUsers: CompanyRoleUser[];
  users: User[];
  subscriptions: Subscription[] = [];
  updatedJobRoles: JobRoleUpdated[] = [];

  constructor(
    private _jobService: JobService,
    private _userService: UserService,
    private notiService: NotificationService) { }

  ngOnInit() {
    this.getRoleUsers();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getRoleUsers() {
    this.users = [new User('', 0)];
    this.subscriptions = this.subscriptions.concat(
      this._userService.getCompanyRoleUsers(this.jobRole.companyRoleId).subscribe({
        next: (roleUsers) => {
          this.roleUsers = roleUsers;
          this.roleUsers.forEach(roleUser => {
            if (roleUser.user) {
              if (roleUser.user.isActive) {
                this.users = this.users.concat(roleUser.user);
              }
            }
          });
        }, error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  saveUser(user: User) {
    // update
    // console.log(JSON.stringify(this.updatedJobRoles));
    if (user.id !== this.jobRole.userId) {
      this.loading = true;
      const updatedJobRole: JobRoleUpdated = new JobRoleUpdated();
      updatedJobRole.roleId = this.jobRole.roleId;
      updatedJobRole.userId = user.id;
      this.updatedJobRoles = [updatedJobRole];

      this.updating.next(true);

      this.subscriptions = this.subscriptions.concat(
        this._jobService.updateJobRoles(this.jobNum, this.updatedJobRoles).subscribe({
          next: () => {
            if (!user.firstName) {
              user.firstName = '';
            }
            if (!user.lastName) {
              user.lastName = '';
            }
            this.jobRole.user = new User('', 0);
            this.jobRole.user.fullName = user.firstName.trim() + ' ' + user.lastName.trim();
            this.jobRole.userId = user.id;
            this.jobRole.user.id = user.id;
            this.updating.next(false);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.updating.next(false);
            this.loading = false;
          }
        })
      );
    }
  }
}
