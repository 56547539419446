import { TrackingFieldsService } from './felixApi/tracking-fields.service';
import { Injectable } from '@angular/core';
import { formatDate } from 'devextreme/localization';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private trackingFieldsService: TrackingFieldsService
  ) { }

  sortBy(name, minor, isAscending = true) {
    const reverseMutliplier = isAscending ? 1 : -1;
    return function (o, p) {
      let a, b;
      let result;
      a = o[name];
      b = p[name];
      if (a === b) {
        return typeof minor === 'function' ? minor(o, p) : 0;
      }
      if (typeof a === typeof b) {
        result = a < b ? -1 : 1;
      } else {
        result = typeof a < typeof b ? -1 : 1;
      }
      return result * reverseMutliplier;
    };
  }

  moveArrayItemToNewIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr = arr.concat(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  getErrorString(err): string {
    if (err && err.error && err.error.message) {
      return JSON.stringify(err.error.message);
    } else {
      return JSON.stringify(err);
    }
  }

  // assumes 'id' field
  editCache(object, cache: any[]) {
    if (cache) {
      const match = cache.filter(t => t.id === object.id)[0];
      if (match) {
        const index = cache.indexOf(match);
        cache[index] = object;
      }
    }
  }

  // assumes INPUT id is string and object ids are numbers
  deleteFromCache(id: string, cache: any[]) {
    const match = cache.filter(t => t.id.toString() === id)[0];
    if (match) {
      const index = cache.indexOf(match);
      cache.splice(index, 1);
    } else {
      console.log('couldnt delete from cache!');
    }
  }

  roundEven(val: number): number {
    if (Math.abs(val % 1) === 0.5) {
      return Math.floor(val / 2 + .5) * 2;
    }
    return Math.round(val);
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  convertDateToString(date: any, defaultString: any): string {
    if (!date) {
      return defaultString;
    }

    if (date instanceof Date) {
      let dateString = formatDate(date, 'yyyy-MM-dd');
      if (dateString.slice(0, 4) === '0000') {
        const dateNowString = formatDate(new Date(), 'yyyy-MM-dd');
        dateString = dateNowString.slice(0, 4) + dateString.slice(4, 10);
      } else if (dateString.slice(0, 2) === '00') {
        const dateNowString = formatDate(new Date(), 'yyyy-MM-dd');
        dateString = dateNowString.slice(0, 2) + dateString.slice(2, 10);
      }

      return dateString;
    } else {
      if (date.length >= 10) {
        return date.substr(0, 10);
      } else if (date.trim() === '') {
        return defaultString;
      } else {
        return date;
      }
    }
  }

  convertDateToStringDMY(date: any, defaultString: any): string {
    if (!date || date === '') {
      return defaultString;
    }

    if (date instanceof Date) {
      return formatDate(date, 'd-MMM-yyyy');
    }

    const newDate = new Date(date);
    return formatDate(newDate, 'd-MMM-yyyy');

  }

  // calc days excluding holidays
  addDaysExHolidays(startDate: Date, days): Date {
    if (!days || days === 0) {
      return startDate;
    }

    if (!startDate) {
      return null;
    }

    const newDate = new Date(startDate.valueOf());
    let dayCount = 0;

    // we may be going + or - days
    if (days > 0) {
      while (dayCount < days) {
        newDate.setDate(newDate.getDate() + 1);

        const dateString = newDate.getFullYear() + '-'
          + ('0' + (newDate.getMonth() + 1)).toString().slice(-2) + '-'
          + ('0' + newDate.getDate()).slice(-2);

        if (newDate.getDay() !== 6 && newDate.getDay() !== 0 && !this.trackingFieldsService.holidays.find(i => i.date.toString().substr(0, 10) === dateString)) {
          dayCount++;
        }
      }
    } else {
      while (dayCount > days) {
        newDate.setDate(newDate.getDate() - 1);

        const dateString = newDate.getFullYear() + '-'
          + ('0' + (newDate.getMonth() + 1)).toString().slice(-2) + '-'
          + ('0' + newDate.getDate()).slice(-2);

        if (newDate.getDay() !== 6 && newDate.getDay() !== 0 && !this.trackingFieldsService.holidays.find(i => i.date.toString().substr(0, 10) === dateString)) {
          dayCount--;
        }
      }
    }
    return newDate;
  }

  // calc days excluding holidays
  addDays(startDate: Date, days, isWorkingDays: boolean = false): Date {
    if (isWorkingDays) {
      return this.addDaysExHolidays(startDate, days);
    } else {
      if (!days || days === 0) {
        return startDate;
      }

      if (!startDate) {
        return null;
      }

      const newDate = new Date(startDate.valueOf());
      let dayCount = 0;

      if (days > 0) {
        while (dayCount < days) {
          newDate.setDate(newDate.getDate() + 1);

          dayCount++;
        }
      } else {
        while (dayCount > days) {
          newDate.setDate(newDate.getDate() - 1);

          dayCount--;
        }
      }

      return newDate;
    }
  }

  enumToArray(enumSource: any): any[] {
    const keys = Object.keys(enumSource);
    const values = Object.values(enumSource);
    const result = [];
    for (let i = 0; i < keys.length; i++) {
      result.push({ id: +values[i], description: keys[i] });
    }
    return result;
  }
}
