export class Estate {
  id: number;
  developerId: number;
  name: string;
  salesDescription: string;
  isActive: boolean;
  modifiedDate: Date;
  modifiedUserId: number;

  constructor(name: string) {
    this.name = name;
  }
}