import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { JobService } from '../services/felixApi/job.service';
import CustomStore from 'devextreme/data/custom_store';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'js-call-ups',
  templateUrl: './call-ups.component.html',
  styleUrls: ['./call-ups.component.css']
})
export class CallUpsComponent implements OnInit {
  subscriptions: Subscription[] = [];
  jobData: CustomStore;
  constructor(
    private _jobService: JobService,
    private _globalService: GlobalService

  ) { }

  ngOnInit() {
    this.subscriptions = this.subscriptions.concat(
      this._jobService.getJobsByAddressWithExtras(false)
        .subscribe({
          next: (jobs) => {
            jobs.forEach(job => {
              job.jobAddressString = this._globalService.getJobString(job, false);
            });

            this.jobData = new CustomStore({
              key: 'jobNumber',
              loadMode: 'raw',
              load: () => jobs
            });
          },
          error: () => {

          }
        })
    );
  }

}
