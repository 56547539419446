<div class="modal-header">
  <h3 *ngIf="!isClient" class="modal-title">Submit Variation to Estimating</h3>
  <h3 *ngIf="isClient" class="modal-title">Submit Selections</h3>

  <button *ngIf="!loading" type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div *ngIf="!loading" class="modal-body">
  <div *ngIf="isClient" class="red">{{itemsToBeSelectedWarning}}</div>
  <div *ngIf="isClient && this.itemsToBeSelectedWarning === ''" class="warning">
    Once submitted, you will need to contact your support officer to re-open this Variation if you wish to make further changes
  </div>
</div>

<js-loading-spinner *ngIf="loading"></js-loading-spinner>

<div *ngIf="!loading" class="modal-footer">
  <button class="btn btn-sm btn-secondary" (click)="cancel()">Cancel</button>
  <button *ngIf="!isClient || this.itemsToBeSelectedWarning === ''" class="btn btn-sm btn-primary" (click)="submit()">Submit</button>
</div>
