<div class="modal-header">
  <h4 class="modal-title" placement="bottom">Summary</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-data-grid class="mt-3" #treeList id="tree-list" [dataSource]="dataSource" [height]="gridHeight"
    [remoteOperations]="false" [showRowLines]="false" [showColumnLines]="true" [showBorders]="true"
    [columnAutoWidth]="false" [wordWrapEnabled]="true" [allowColumnResizing]="true"
    (onCellPrepared)="onCellPrepared($event)" (onToolbarPreparing)="onToolbarPreparing($event)">

    <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-paging [enabled]="false"> </dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-sorting mode="multiple"></dxo-sorting>

    <dxi-column dataField="costCode" caption="Code" dataType="string" [width]="100" sortOrder="asc">
    </dxi-column>

    <dxi-column dataField="description" dataType="string">
    </dxi-column>

    <dxi-column dataField="total" caption="Cost" dataType="number" [width]="130" format="#,###">
    </dxi-column>

    <dxo-summary>
      <dxi-total-item column="total" summaryType="sum" [valueFormat]="{ type: 'fixedPoint', precision: 0 }"
        displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>
</div>
