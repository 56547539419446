import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobVarItemService } from '../../services/felixApi/job-var-item.service';
import { TreeComponent } from '@circlon/angular-tree-component';
import { SelectedJobItems } from '../../dtos/selectedJobItemIds';
import { IJobItemWithChildren } from '../../dtos/job-item-with-children';
import { ItemTypeEnum } from '../../dtos/item-type.enum';
import { JobItemService } from '../../services/felixApi/job-item.service';
import { ChangeTypeEnum } from '../../dtos/change-type.enum';
import { IJobItem } from '../../dtos/job-item';
import { VariationService } from '../../services/felixApi/variation.service';
import { Variation } from '../../dtos/variation';
import { VariationStatusEnum } from '../../dtos/variation-status.enum';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-move-items-modal',
  templateUrl: './move-items-modal.component.html',
  styleUrls: ['./move-items-modal.component.scss']
})
export class MoveItemsModalComponent implements OnInit, OnDestroy {
  @Input() variationId: number;

  COMPONENT_NAME = 'MoveItemsModalComponent';
  subscriptions: Subscription[] = [];

  treeOptions = {};
  loading = true;
  oneNode: IJobItemWithChildren = new IJobItemWithChildren;
  treeNodes: IJobItemWithChildren[] = [];
  itemTypeEnum = ItemTypeEnum;
  itemsToSet: any[] = [];
  itemsToSetDto: SelectedJobItems;
  checked: boolean;
  id: number;
  changeTypeEnum = ChangeTypeEnum;
  parentChecked: boolean;
  isOpenVariations = false;
  openVariations: Variation[] = [];
  variationStatusEnum = VariationStatusEnum;
  variationString = '';
  variationToId: number;
  numItemsToMove = 0;

  @ViewChild('tree') tree: TreeComponent;
  connectedInfoMessageSent: boolean;

  constructor(
    private _jobItemService: JobItemService,
    private _variationService: VariationService,
    private _jobVarItemService: JobVarItemService,
    private notiService: NotificationService,
    private _activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.getVariations();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getVariations() {
    // we get all the variations that are open and not this one

    if (!this._variationService.currentVariations.length) {
      // No variations found for given job
      this.loading = false;
    } else {
      this._variationService.currentVariations.forEach(varn => {
        if (varn.id !== this.variationId && varn.statusId < this.variationStatusEnum.PendingApproval) {
          this.openVariations = this.openVariations.concat(varn);
          this.isOpenVariations = true;
        }
      });
      if (this.isOpenVariations) {
        this.setupJobItemNodes();
      } else {
        this.loading = false;
      }
    }
  }

  // set up the tree nodes for the add/edit item
  setupJobItemNodes() {
    this.treeOptions = {
      idField: 'id',
      displayField: 'description',
      childrenField: 'children',
      useVirtualScroll: true,
      nodeHeight: 22,
      scrollOnActivate: false
    };
    // get the items from this VO only - create from list in memory
    this.treeNodes = [];
    this._jobItemService.currentJobItems.forEach(item => {
      if (item.jobItemAboveId === null && (!item.isHistoryRecord || item.changeTypeId === this.changeTypeEnum.Delete)) {

        if ((item.changedByVOId === this.variationId)
          || (item.itemTypeId === this.itemTypeEnum.Heading &&
            this.setupJobItemNodesCheckHeading(item))) {

          if (item.itemTypeId === this.itemTypeEnum.Heading) {
            this.oneNode.children = this._jobItemService.setupJobItemNodesChildren(item.originalItemTypeId,
              item.id, this._jobItemService.currentJobItems, this.variationId);
          } else {
            this.oneNode.children = [];
          }

          if ((item.itemTypeId === this.itemTypeEnum.Heading && this.oneNode.children && this.oneNode.children.length)
            || (item.itemTypeId !== this.itemTypeEnum.Heading && item.jobVariationId === this.variationId)) {

            if (!item.itemDescription || item.itemDescription === '') {
              this.oneNode.description = item.selection;
            } else if (item.selection) {
              this.oneNode.description = item.itemDescription + ' - ' + item.selection;
            } else {
              this.oneNode.description = item.itemDescription;
            }

            // if the item has not been changed use the original id
            if (item.changedByJobVarId && item.changedByJobVarId !== 0) {
              this.id = item.changedByJobVarId;
            } else {
              this.id = item.id;
            }

            this.treeNodes.push({
              id: this.id,
              itemTypeId: item.itemTypeId,
              changeTypeId: item.changeTypeId,
              jobVarTypeId: item.jobVarTypeId,
              itemChangedId: item.itemChangedId,
              description: this.oneNode.description,
              originalItemTypeId: item.originalItemTypeId,
              originalItemId: item.id,
              connectedItemId: item.connectedItemId,
              children: this.oneNode.children,
              parentId: null,
              changedByVOId: item.changedByVOId,
              changedByJobVarId: item.changedByJobVarId,
              masterItemId: item.masterItemId,
              hasLinkedItems: item.hasLinkedItems,
              optionNumber: item.optionNumber,
              checked: false,
              indeterminate: false,
              isChecked: item.isChecked,
              variationItemNumber: item.variationItemNumber
            });
          }
        }
      }
    });

    // now add the item numbering
    this.treeNodes.forEach(node => {
      this.addItemNumbering(node);
    });
    // console.log('treeNodes', this.treeNodes);

    this.loading = false;
  }

  addItemNumbering(node: IJobItemWithChildren) {
    if (node.itemTypeId === this.itemTypeEnum.Heading) {
      node.children.forEach(childNode => {
        this.addItemNumbering(childNode);
      });
    } else {
      node.variationItemNumber = this._jobItemService.currentJobItemsUnfiltered.find(i => (i.isHistoryRecord || i.changeTypeId !== ChangeTypeEnum.Change) && i.changedByJobVarId === node.changedByJobVarId)?.variationItemNumber ?? null;
    }

  }

  setupJobItemNodesCheckHeading(item: IJobItem): boolean {
    // check if this heading has any children in this VO so we can add
    let returnVal = false;
    this._jobItemService.currentJobItems.some(elementToCheck => {
      if (elementToCheck.jobItemAboveTypeId === item.originalItemTypeId &&
        elementToCheck.jobItemAboveId === item.id && !returnVal) {
        if (elementToCheck.changedByVOId === this.variationId) {
          returnVal = true;
          return true;
        }
        if (elementToCheck.itemTypeId === this.itemTypeEnum.Heading) {
          if (this.setupJobItemNodesCheckHeading(elementToCheck)) {
            returnVal = true;
            return true;
          }
        }
      }
    });
    return returnVal;
  }

  onTreeInit(tree: TreeComponent) {
    tree.treeModel.virtualScroll.setViewport(tree.treeModel.virtualScroll.viewport);
  }

  setVariationNum(varn: Variation) {
    this.variationString = varn.displayedVariationNumber + ': ' + varn.title;
    this.variationToId = varn.id;
  }

  treeNodeClicked(tree: TreeComponent, node) {
    if (node.data.changedByVOId !== this.variationId) {
      this.notiService.showWarning('Cannot select this item as it has not been added or changed in this variation.');
      node.data.checked = false;
      node.setIsHidden(true); // do twice to force the update of the tree
      node.setIsHidden(false);
    } else {
      if (node.data.parentId) {
        // check if parent ticked
        this.parentChecked = this.tree.treeModel.getNodeById(node.id).parent.data.checked;
      } else {
        this.parentChecked = false;
      }

      if (this.parentChecked) {
        this.notiService.showWarning('Cannot deselect and item where the heading above has been selected.');
        // this.tree.treeModel.getNodeById(node.id).data.checked = $event.target.checked;
        node.data.checked = true;
        node.setIsHidden(true);
        node.setIsHidden(false);
        // this.tree.treeModel.getNodeById(node.id).parent.toggleExpanded();
        // this.tree.treeModel.getNodeById(node.id).parent.toggleExpanded();
        // this.tree.treeModel.update();
      } else {
        node.data.checked = !node.data.checked;
        if (node.data.checked) {
          this.numItemsToMove += 1;
        } else {
          this.numItemsToMove -= 1;
        }

        if (node.children) {
          node.children.forEach((child) => this.checkAllChildren(child, node.data.checked));
          node.expandAll();
        }

        // if has connected or linked items or has options then tick all
        tree.treeModel.roots.forEach(item => {
          this.testForFound(item, node);

          if (item.data.itemTypeId === this.itemTypeEnum.Heading) {
            this.checkChildrenToCheck(item, node);
          }
        });
      }
    }
    // tree.treeModel.update();
  }

  checkChildrenToCheck(parent, node) {
    parent.children.forEach(item => {
      this.testForFound(item, node);

      if (item.data.itemTypeId === this.itemTypeEnum.Heading) {
        this.checkChildrenToCheck(item, node);
      }
    });
  }

  testForFound(item, node) {
    if (node.data.checked !== item.data.checked) {
      if (node.data.id === item.data.connectedItemId
        || (node.data.originalItemTypeId === item.data.originalItemTypeId && node.data.originalItemId === item.data.originalItemId)
        || (node.data.connectedItemId === item.data.id)) {
        item.data.checked = node.data.checked;
        if (node.data.checked) {
          this.numItemsToMove += 1;
          if (!this.connectedInfoMessageSent) {
            this.notiService.showInfo('Linked and connected items are automatically selected');
            this.connectedInfoMessageSent = true;
          }
        } else {
          this.numItemsToMove -= 1;
        }
      } else if (node.data.masterItemId === item.data.id
        || (node.data.masterItemId
          && (node.data.masterItemId === item.data.masterItemId || node.data.masterItemId === item.data.originalItemId))
        || (item.data.masterItemId
          && (node.data.id === item.data.masterItemId || item.data.masterItemId === node.data.originalItemId))) {
        item.data.checked = node.data.checked;
        if (node.data.checked) {
          this.numItemsToMove += 1;
          if (!this.connectedInfoMessageSent) {
            this.notiService.showInfo('Linked and connected items are automatically selected');
            this.connectedInfoMessageSent = true;
          }
        } else {
          this.numItemsToMove -= 1;
        }
      }
    }
  }

  checkAllChildren(node, checkValue) {
    if (node.data.changedByVOId === this.variationId) {
      node.data.checked = checkValue;
      if (node.data.checked) {
        this.numItemsToMove += 1;
      } else {
        this.numItemsToMove -= 1;
      }
    }
    if (node.children) {
      node.children.forEach((child) => this.checkAllChildren(child, checkValue));
    }
  }

  moveItems() {
    // move the items
    this.loading = true;
    this.itemsToSet = [];
    for (let i = 0; i < this.tree.treeModel.nodes.length; i++) {
      if (this.tree.treeModel.getNodeById(this.tree.treeModel.nodes[i].id).data.checked === true) {
        this.itemsToSet = this.itemsToSet.concat(+this.tree.treeModel.nodes[i].id);
      }
      if (this.tree.treeModel.nodes[i].children) {
        this.tree.treeModel.nodes[i].children.forEach((child) => this.checkAddItemsToCopy(child));
      }
    }

    this.itemsToSetDto = { selectedJobItemIds: this.itemsToSet };

    this.subscriptions = this.subscriptions.concat(
      this._jobVarItemService.moveItemsToNewVO(this.variationId, this.variationToId, this.itemsToSetDto).subscribe({
        next: () => {
          this._activeModal.close();
        },
        error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }})
    );
  }

  checkAddItemsToCopy(node) {
    if (this.tree.treeModel.getNodeById(node.id).data.checked === true) {
      this.itemsToSet = this.itemsToSet.concat(+node.id);
    }
    if (node.children) {
      node.children.forEach((child) => this.checkAddItemsToCopy(child));
    }
  }

  selectAll(tree: TreeComponent) {
    this.numItemsToMove = 0;
    tree.treeModel.expandAll();

    tree.treeModel.roots.forEach(item => {
      if (item.data.itemTypeId === this.itemTypeEnum.Heading) {
        this.checkAllChildren(item, true);
      } else if (item.data.changedByVOId === this.variationId) {
        item.data.checked = true;
        this.numItemsToMove += 1;
      }
    });
  }

  deselectAll(tree: TreeComponent) {
    tree.treeModel.collapseAll();

    tree.treeModel.roots.forEach(item => {
      item.data.checked = false;
      if (item.data.itemTypeId === this.itemTypeEnum.Heading) {
        this.deselectAllChildren(item);
      }
    });

    this.numItemsToMove = 0;
  }

  deselectAllChildren(node) {
    node.data.checked = false;

    if (node.children) {
      node.children.forEach((child) => this.deselectAllChildren(child));
    }
  }

  cancel() {
    this._activeModal.dismiss();
  }
}
