import { GlobalService } from './../../../services/global.service';
import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../../services/notification.service';
import { EstimatingService } from '../../../services/felixApi/estimating.service';
import { Subscription } from 'rxjs';
import { RecipeTypeEnum } from '../../../dtos/recipe-type.enum';
import { Recipe } from '../../../dtos/recipe';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DxTreeListComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-estimating-save-to-recipe-modal',
  templateUrl: './estimating-save-to-recipe-modal.component.html',
  styleUrls: ['./estimating-save-to-recipe-modal.component.scss']
})
export class EstimatingSaveToRecipeModalComponent implements OnInit, OnDestroy {
  @Input() jobVarItemId: number;
  @Input() gridHeight: number;

  loading = false;
  subscriptions: Subscription[] = [];
  recipeId: number;
  recipeClasses: Recipe[];
  recipeForm: UntypedFormGroup;
  treeGrid: DxTreeListComponent;
  treeListDataSource: CustomStore;
  recipeParentIds: any[] = [];

  @ViewChild('treeList') treeList: DxTreeListComponent;
  maxTreeHeight: number;

  constructor(private _activeModal: NgbActiveModal,
    private notiService: NotificationService,
    private _formBuilder: UntypedFormBuilder,
    private globalService: GlobalService,
    private estimatingService: EstimatingService) { }

  ngOnInit() {
    this.maxTreeHeight = this.gridHeight - 100;
    this.getRecipeClasses();
    this.recipeForm = this._formBuilder.group({
      recipeCode: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getRecipeClasses() {
    this.treeListDataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.estimatingService.getRecipeGroups().subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  dismissModal() {
    this._activeModal.dismiss();
  }

  runCopy() {
    // check for existing recipe code
    const existingRecipe = this.estimatingService.allRecipes.filter(i => i.recipeCode === this.recipeForm.get('recipeCode').value);

    if (existingRecipe && existingRecipe[0]) {
      this.notiService.showInfo('Recipe code already exists');
    } else {
      this.loading = true;

      const recipeData = {
        recipeParentId: this.recipeParentIds[0],
        recipeTypeId: RecipeTypeEnum.Recipe,
        recipeCode: this.recipeForm.get('recipeCode').value,
        description: this.recipeForm.get('description').value,
      };

      this.subscriptions = this.subscriptions.concat(
        this.estimatingService.copyJobVarCostItemsToRecipe(this.jobVarItemId, recipeData).subscribe({
          next: () => {
            this.notiService.showSuccess('Recipe saved');
            this._activeModal.close();
          }, error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
      );
    }
  }
}
