export class IJobVarItem {
    id: number;
    jobVariationId: number;
    jobVarTypeId: number;
    itemChangedId: number;
    changeTypeId: number;
    jobItemAboveTypeId: number;
    jobItemAboveId: number;
    orderNo: number;
    itemTypeId: number;
    itemDescription: string;
    selectionTypeId: number;
    selection: string;
    optionListId: number;
    linkedJobItemId: number;
    selectedOptionId: number;
    originalItemTypeId: number;
    originalItemId: number;
    costTypeId: number;
    price: number;
    itemNotTaken: boolean;
    deleteComment: string;
    attachmentName: string;
    attachmentId: number;
    attachmentTypeId: number;
    isHiddenFromMaster: boolean;
    isDeleted: boolean;
    noteColour: number;
    isBoldNote: boolean;
    isItalicNote: boolean;
    isChecked: boolean;
    isChangeableByCustomer: boolean;
    isConfirmedbyCustomer: boolean;
    isDoNotPrint: boolean;
    connectedItemId: number;
    hasLinkedItems: boolean;
    masterItemId: number;
    optionNumber: number;
    varOptionId: number;
    hideFixedPSum: boolean;
    jobItemCommentId: number;
    commentTypeId: number;
    patchReturnTypeId: number; // used when we patch an item so we can tell the front end to do a full re-read
    optionListAttachmentId: number; // returned when we patch
    optionImageId: number; // returned when we patch
    quantity: number;
    unitOfMeasure: string;
    patchReturnWarning: string;
}
