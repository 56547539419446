<div class="modal-header">
  <h2 class="modal-title">Connect Items</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form (ngSubmit)="save()" [formGroup]="connectForm">

    <div>{{jobitem.itemDescription}}</div>
    <div class="selectionText">{{jobitem.selection}}</div>

    <div><b>Select items to be connected to this item:</b></div>

    <input id="filter" #filter (keyup)="tree.treeModel.filterNodes(filter.value)" placeholder="filter..."
      class='mt-1 pb-1 pt-0 ms-2p' />
    <button class="btn btn-sm btn-secondary"
      (click)="$event.preventDefault(); $event.stopPropagation(); tree.treeModel.clearFilter(); tree.treeModel.collapseAll(); filter.value=''">
      Clear Filter
    </button>
    <div *ngIf="!loading" class="globalTreeclass" [ngStyle]="{'height': 'calc(' + treeHeight + 'px' + ')'}">
      <tree-root #tree [focused]="true" [nodes]="treeNodes" [options]="treeOptions" (initialized)="onTreeInit(tree)">
        <ng-template #treeNodeTemplate let-node="node" let-index="index">
          <!-- <input type="checkbox" [checked]="node.isActive" (change)="node.toggleActivated(true);nodeClicked(node, $event)" /> -->
          <input (change)="treeNodeClicked(node, $event)" type="checkbox" id="check-{{node.data.id}}"
            [(checked)]="node.data.checked">
          <span class="variationItemNumberClass">{{node.data.variationItemNumber}}</span>
          {{ node.data.description }}
        </ng-template>

      </tree-root>
    </div>

    <!-- <div *ngIf="!loading" class="form-group">
      <button type="submit" class="btn btn-sm btn-primary">Update</button>
    </div> -->

    <!-- Loading Spinner -->
    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  </form>
</div>

<div class="modal-body pt-0">
  <button type="button" class="btn btn-sm btn-primary" (click)="save()">Update</button>
  <span class="smallText">Note: If this item is marked Not Taken, connected items are also marked Not Taken.</span>
</div>
