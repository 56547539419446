import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VariationTypeEnum } from '../../dtos/variation-type.enum';
import { VariationService } from '../../services/felixApi/variation.service';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import { GlobalService } from '../../services/global.service';
import { CompanyConfiguration } from '../../dtos/company-configuration';

@Component({
  selector: 'js-create-sales-variation-modal',
  templateUrl: './create-sales-variation-modal.component.html',
  styleUrls: ['./create-sales-variation-modal.component.scss']
})
export class CreateSalesVariationModalComponent implements OnInit, OnDestroy {

  COMPONENT_NAME = 'create-sales-variation-modal';
  subscriptions: Subscription[] = [];

  loading = false;
  editForm: UntypedFormGroup;
  updaterecord: any;
  variationTypeEnum = VariationTypeEnum;
  todaysDate = new Date();
  configurationEnum = ConfigurationEnum;
  companyConfigOfficeVO: CompanyConfiguration;
  createOfficeVO = false;
  officeVOTitle: string;

  constructor(private _formBuilder: UntypedFormBuilder,
    private _variationService: VariationService,
    private _globalService: GlobalService,
    private notiService: NotificationService,
    private _activeModal: NgbActiveModal) { }

  ngOnInit() {
    // set up form for delete modal
    // do we create the Office VO?
    this.companyConfigOfficeVO = this._globalService.getCompanyConfigObject(this.configurationEnum.AutoCreateFirstSalesOfficeVariation);
    if (this.companyConfigOfficeVO?.configurationValue === 1) {
      this.createOfficeVO = true;
      this.officeVOTitle = this.companyConfigOfficeVO.configurationString
        && this.companyConfigOfficeVO.configurationString.trim() !== ''
        ? this.companyConfigOfficeVO.configurationString : 'Promotions and Discounts';
    }

    this.editForm = this._formBuilder.group({
      varnTitle: 'Additions and Alterations',
      varnOfficeTitle: this.officeVOTitle
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  cancel() {
    this._activeModal.dismiss();
  }

  createVO() {
    // create the variation
    this.loading = true;
    this.updaterecord = {
      title: this.editForm.get('varnTitle').value,
      variationType: this.variationTypeEnum.SalesVariation,
      variationDate: JSON.stringify(this.todaysDate).substr(1, 10)
    };

    this.subscriptions = this.subscriptions.concat(
      this._variationService.addVariation(this.updaterecord).subscribe({
        next: () => {
          if (this.createOfficeVO) {
            this.createOfficeVariation();
          } else {
            this._activeModal.close();
          }
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  createOfficeVariation() {
    // create the variation
    this.updaterecord = {
      title: this.editForm.get('varnOfficeTitle').value,
      variationType: this.variationTypeEnum.SalesOffice,
      variationDate: JSON.stringify(this.todaysDate).substr(1, 10)
    };

    this.subscriptions = this.subscriptions.concat(
      this._variationService.addVariation(this.updaterecord).subscribe({
        next: () => {
          this._activeModal.close();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }
}
