import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VariationService } from '../../services/felixApi/variation.service';
import { VariationStatusEnum } from '../../dtos/variation-status.enum';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { EmailService } from '../../services/felixApi/email.service';
import { AuthService } from '../../services/auth.service';
import { JobItemService } from '../../services/felixApi/job-item.service';
import { ItemTypeEnum } from '../../dtos/item-type.enum';

@Component({
  selector: 'js-submit-to-estimating-modal',
  templateUrl: './submit-to-estimating-modal.component.html',
  styleUrls: ['./submit-to-estimating-modal.component.scss']
})
export class SubmitToEstimatingModalComponent implements OnInit, OnDestroy {
  @Input() variationId: number;

  subscriptions: Subscription[] = [];
  updaterecord: any;
  loading = false;
  variationStatus = VariationStatusEnum;
  isClient = false;
  itemsToBeSelectedWarning = '';
  itemTypeEnum = ItemTypeEnum;

  constructor(private _activeModal: NgbActiveModal,
    private notiService: NotificationService,
    private _emailService: EmailService,
    private _authService: AuthService,
    public _jobItemService: JobItemService,
    private _variationService: VariationService) { }

  ngOnInit() {
    this.isClient = this._authService.isClient();
    if (this.isClient) {
      this.checkItemsStillToBeSelected();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  checkItemsStillToBeSelected() {
    // for a client we can warn if there are items still to be selected by them
    this.itemsToBeSelectedWarning = '';
    this._jobItemService.currentJobItems.forEach(jobItem => {
      if (!jobItem.isHistoryRecord && jobItem.itemTypeId === this.itemTypeEnum.Detail
        && (!jobItem.selection || jobItem.selection === '' || this._jobItemService.checkNotSelected(jobItem))) {
        this.itemsToBeSelectedWarning = 'Warning: there are items still to be selected! E.g. ' + jobItem.itemDescription;
        // console.log(jobItem.itemDescription + ' - ' + jobItem.selection);
      }
    });
  }

  cancel() {
    this._activeModal.dismiss();
  }

  submit() {
    this.loading = true;
    // edit a variation so save it.
    this.updaterecord = { id: this.variationId, statusId: this.variationStatus.Estimating };

    if (this.isClient) {
      this.subscriptions = this.subscriptions.concat(
        this._variationService.updateVariationByClient(this.updaterecord).subscribe({
          next: () => {
            this.sendEmailNotification();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
      );
    } else {
      this.subscriptions = this.subscriptions.concat(
        this._variationService.updateVariation(this.updaterecord).subscribe({
          next: () => {
            this.sendEmailNotification();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
      );
    }
  }

  sendEmailNotification() {
    this.subscriptions = this.subscriptions.concat(
      this._emailService.sendVariationStatusEmail(this.variationId).subscribe({
        next: () => {
          this.notiService.showSuccess('Variation successfully submitted');
          this._activeModal.close();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }
}
