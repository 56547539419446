import { AuthService } from './../../../services/auth.service';
import { UtilsService } from './../../../services/utils.service';
import { TrackingCalculationFieldEnum } from './../../../dtos/tracking-calculation-field.enum';
import { JobService } from './../../../services/felixApi/job.service';
import { UserService } from './../../../services/felixApi/user.service';
import { formatDate } from 'devextreme/localization';
import { Component, OnInit, Input, ViewChild, OnDestroy, OnChanges, Output, EventEmitter } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { TrackingFieldsService } from '../../../services/felixApi/tracking-fields.service';
import { TrackingFieldGroup } from '../../../dtos/tracking-field-group';
import { TrackingFieldLookup } from '../../../dtos/tracking-field-lookup';
import { GlobalService } from '../../../services/global.service';
import { GridService } from '../../../services/grid.service';
import { NotificationService } from '../../../services/notification.service';
import { TrackingFieldTypeEnum } from '../../../dtos/tracking-field-type.enum';
import { JobField } from '../../../dtos/job-field';
import { User } from '../../../dtos/user';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'js-job-fields',
  templateUrl: './job-fields.component.html',
  styleUrls: ['./job-fields.component.scss']
})
export class JobFieldsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() jobId: number;
  @Input() saveJobData: boolean;
  @Input() reload: number;

  @Output() saveDone: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  loading = false;
  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  trackingFieldId: number;
  trackingFieldTypeEnum = TrackingFieldTypeEnum;
  trackingFieldTypeId: number;
  lookupList: TrackingFieldLookup[];
  adhocItem: TrackingFieldLookup;
  updatedData: any;
  tradeRegionId: number;
  gridHeight: number;
  trackingFieldGroups: TrackingFieldGroup[];
  adhocSelection: string;
  editMode: string;
  users: User[];
  showModifiedFields: boolean;
  dataFieldForEdit: any;
  canUpdateData: boolean;

  constructor(
    private trackingFieldsService: TrackingFieldsService,
    private notiService: NotificationService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private userService: UserService,
    private jobService: JobService,
    private utilsService: UtilsService,
    private authService: AuthService
  ) {
    this.onEditingStart = this.onEditingStart.bind(this);
    this.onLookupValueChanged = this.onLookupValueChanged.bind(this);
    this.calculateGroupSortValue = this.calculateGroupSortValue.bind(this);
    this.changEditMode = this.changEditMode.bind(this);
    this.changeColour = this.changeColour.bind(this);
    this.calculateField = this.calculateField.bind(this);
  }

  ngOnInit(): void {
    this.editMode = 'row';
    this.subscribeToInnerWidth();
    this.getData();
  }

  ngOnChanges(): void {
    if (this.saveJobData) {
      if (this.grid) {
        this.grid?.instance.saveEditData();
      }
      this.saveDone.emit(true);
      this.saveJobData = false;
    } else if (this.reload) {
      this.setUpDataSource();
    }
    this.setJobStringMaxWidth();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  subscribeToInnerWidth() {
    this.subscriptions.push(
      this.globalService.innerWidthChanged.pipe(debounceTime(250)).subscribe(
        () => {
          this.setJobStringMaxWidth();
        }
      )
    );
  }

  setJobStringMaxWidth() {
    this.loading = true;
    this.gridHeight = window.innerHeight < 955 ? 370 : window.innerHeight - 585;

    if (this.jobService.currentJob.warningNote && this.jobService.currentJob.warningNote.trim() !== '') {
      this.gridHeight -= 25;
    }

    if (window.innerWidth < 900) {
      this.showModifiedFields = false;
    } else {
      this.showModifiedFields = true;
    }

    setTimeout(() => {
      this.loading = false;
    }, 200); // wait for grid
  }

  getData() {
    this.loading = true;
    this.subscriptions.push(
      this.trackingFieldsService.getJobFieldsData()
        .subscribe({
          next: () => {
            this.trackingFieldGroups = this.trackingFieldsService.trackingFieldGroups;
            // console.log(this.trackingFieldGroups);
            this.users = this.userService.users;

            // check if this is a sales rep and check permissions
            this.checkUserIsNotRepOrRepForJob();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  checkUserIsNotRepOrRepForJob() {
    if (this.authService.isAdminOrSuperUser()) {
      this.canUpdateData = true;
      this.setUpDataSource();
    } else {
      const permissionSalesRepsOthersData = this.authService.getSelectionsPermissions('SalesRepsOthersData');

      if (permissionSalesRepsOthersData === 'Write' || permissionSalesRepsOthersData === 'Admin') {
        this.canUpdateData = true;
        this.setUpDataSource();
      } else {
        this.canUpdateData = false;
        this.subscriptions = this.subscriptions.concat(
          this.jobService.checkSalesRepAndHasJobRole(this.jobService.currentJob.jobNumber)
            .subscribe({
              next: (jobSalesConsultant) => {
                this.canUpdateData = jobSalesConsultant.isNotRepOrIsRepForJob;
                this.setUpDataSource();
              },
              error: (err) => {
                this.notiService.notify(err);
                this.loading = false;
              }
            })
        );
      }
    }
  }

  setUpDataSource() {
    this.loading = false;
    this.dataSource = new CustomStore({
      key: 'trackingFieldId',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.trackingFieldsService.getJobFields(this.jobId).subscribe({
            next: (res) => {
              const jobFields: JobField[] = [];
              res.forEach(element => {
                if (element.trackingFieldTypeId === this.trackingFieldTypeEnum.Date && !this.dateParse(element.textValue)) {
                  element.textValue = null;
                }

                const field = this.trackingFieldsService.trackingFields.find(i => i.id === element.trackingFieldId);
                // SiteStart not handled so take out
                if (!field || field.trackingCalculationFieldId !== TrackingCalculationFieldEnum.SiteStart) {
                  if (element.modifiedDate.toString() === '0001-01-01T00:00:00Z') {
                    element.modifiedDate = null;
                  }
                  jobFields.push(element);
                }
              });
              return resolve(jobFields);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        // console.log(values);
        return new Promise((resolve, reject) =>
          this.trackingFieldsService.updateJobField(this.jobId, encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onEditingStart(e) {
    this.trackingFieldId = e.data.trackingFieldId;
    this.trackingFieldTypeId = e.data.trackingFieldTypeId;
    this.adhocSelection = e.data.textValue; // set

    if (this.trackingFieldTypeId === this.trackingFieldTypeEnum.Lookup) {
      this.lookupList = this.trackingFieldsService.trackingFieldLookups.filter(i => i.trackingFieldId === this.trackingFieldId);

      if (e.data.textValue && e.data.textValue.length) {
        const foundSelection = this.lookupList.find(i => i.description === e.data.textValue);
        if (!foundSelection) {
          // asdd it to the list
          this.addToList(e.data.textValue);
        }
      }
    } else if (this.trackingFieldTypeId === this.trackingFieldTypeEnum.Boolean) {
      this.lookupList = [];

      this.adhocItem = new TrackingFieldLookup(1, 'Yes', 1, 0);
      this.lookupList.push(this.adhocItem);
      this.adhocItem = new TrackingFieldLookup(2, 'No', 2, 0);
      this.lookupList.push(this.adhocItem);
      this.adhocItem = new TrackingFieldLookup(3, 'N/A', 3, 0);
      this.lookupList.push(this.adhocItem);

      if (e.data.textValue && e.data.textValue.length) {
        const foundSelection = this.lookupList.find(i => i.description === e.data.textValue);
        if (!foundSelection) {
          // asdd it to the list
          this.adhocItem = new TrackingFieldLookup(4, e.data.textValue, 4, 0);
          this.lookupList.push(this.adhocItem);
        }
      }
    }
  }

  onEditorPreparing(e: any) {
    if (e.parentType !== 'dataRow') {
      return;
    } else {
      this.dataFieldForEdit = e;
      if (e.dataField === 'textValue' && e.row.data.trackingFieldTypeId === this.trackingFieldTypeEnum.Text) {
        e.editorName = 'dxTextArea';
        e.editorOptions.autoResizeEnabled = true;
        let prevHeight = null;
        e.editorOptions.onInput = (args) => {
          const td = args.element.closest('td');
          if (prevHeight !== td.offsetHeight) {
            const overlay = e.element.querySelector('.dx-datagrid-focus-overlay');
            if (overlay != null) {
              overlay.style.height = (td.offsetHeight + 1) + 'px';
            }
            prevHeight = td.offsetHeight;
          }
        };
      } else if (e.dataField === 'textValue' && e.row.data.trackingFieldTypeId === this.trackingFieldTypeEnum.Number) {
        e.editorName = 'dxNumberBox';
        e.editorOptions.showSpinButtons = true;
        e.editorOptions.showClearButton = true;
      } else if (e.dataField === 'textValue' && e.row.data.trackingFieldTypeId === this.trackingFieldTypeEnum.Date) {
        e.editorName = 'dxDateBox';
        e.editorOptions = {
          type: 'date',
          showClearButton: true,
          displayFormat: 'd-MMM-yy',
          onValueChanged: this.onDateValueChanged.bind(this),
          value: this.adhocSelection
        };
      } else if (e.dataField === 'textValue'
        && (e.row.data.trackingFieldTypeId === this.trackingFieldTypeEnum.Lookup
          || e.row.data.trackingFieldTypeId === this.trackingFieldTypeEnum.Boolean)) {
        e.editorName = 'dxSelectBox';
        e.editorOptions = {
          dataSource: this.lookupList,
          searchEnabled: true,
          acceptCustomValue: true,
          valueExpr: 'description',
          displayExpr: 'description',
          showClearButton: true,
          onValueChanged: this.onLookupValueChanged.bind(this),
          onCustomItemCreating: this.addCustomItem.bind(this),
          value: this.adhocSelection
        };
      } else if (e.dataField === 'textValue' && e.row.data.trackingFieldTypeId === this.trackingFieldTypeEnum.Calculated) {
        e.editorOptions.disabled = true;
      }
    }
  }

  onLookupValueChanged(e) {
    this.adhocSelection = e.value;
    this.dataFieldForEdit.setValue(this.adhocSelection);
  }

  onDateValueChanged(e) {
    if (e.value instanceof Date) {
      this.adhocSelection = formatDate(e.value, 'yyyy-MM-dd');
    } else {
      this.adhocSelection = e.value;
    }
    this.dataFieldForEdit.setValue(this.adhocSelection);
  }

  addCustomItem(data) {
    if (!data.text) {
      data.customItem = null;
      return;
    }

    this.addToList(data.text);
    this.adhocSelection = data.text;
    this.dataFieldForEdit.setValue(this.adhocSelection);
    data.customItem = this.adhocItem;
  }

  addToList(adhocText: string) {
    const productIds = this.lookupList.map(function (item) {
      return item.id;
    });
    const incrementedId = Math.max.apply(null, productIds) + 1;

    this.adhocItem = new TrackingFieldLookup(incrementedId, adhocText, incrementedId, this.trackingFieldId);
    this.lookupList.push(this.adhocItem);
  }

  calculateGroupSortValue(jobField: JobField) {
    let orderNumber = 0;
    let description = '';
    if (jobField.trackingFieldGroupId) {
      const trackingGroup = this.trackingFieldsService.trackingFieldGroups.find(i => i.id === jobField.trackingFieldGroupId);
      if (trackingGroup) {
        orderNumber = trackingGroup.orderNumber;
        description = trackingGroup.description;
      }
    }
    return ('00000' + orderNumber.toString()).slice(-6) + ';' + description;
  }

  getGroupTitle(cellInfo) {
    return cellInfo.data.key.split(';')[1];
  }

  changEditMode() {
    if (this.grid.instance.hasEditData() && this.editMode === 'batch') {
      this.notiService.showInfo('Please Save or Cancel the edited data');
    } else {
      if (this.editMode === 'row') {
        this.editMode = 'batch';
      } else {
        this.editMode = 'row';
      }
    }
  }

  changeColour(e) {
    const rowIndex = this.grid.instance.getRowIndexByKey(e.row.data.trackingFieldId);
    this.grid.instance.cellValue(rowIndex, 'colourId', e.row.data.colourId ? 0 : 1);

    this.grid.instance.saveEditData();
  }

  onRowPrepared(e) {
    if (e.rowType === 'data' && (e.data.colourId)) {
      e.rowElement.style.backgroundColor = 'yellow';
      e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
    }
  }

  dateParse(e) {
    return Date.parse(e);
  }

  calculateField(cellData) {
    const field = this.trackingFieldsService.trackingFields.find(i => i.id === cellData.trackingFieldId);

    if (field.trackingCalculationFieldId === TrackingCalculationFieldEnum.SalesDate) {
      return this.utilsService.addDays(this.jobService.currentJob.salesDate, field.calculationDaysToAdd, field.isCalculationDaysWorkingDays);
    } else if (field.trackingCalculationFieldId === TrackingCalculationFieldEnum.ContractSigned) {
      return this.utilsService.addDays(this.jobService.currentJob.contractSignedDate, field.calculationDaysToAdd, field.isCalculationDaysWorkingDays);
    } else if (field.trackingCalculationFieldId === TrackingCalculationFieldEnum.HandoverDate && this.jobService.currentJobExtra) {
      return this.utilsService.addDays(this.jobService.currentJobExtra.handoverDate, field.calculationDaysToAdd, field.isCalculationDaysWorkingDays);
    } else if (field.trackingCalculationFieldId === TrackingCalculationFieldEnum.PracticalCompletion && this.jobService.currentJobExtra) {
      return this.utilsService.addDays(this.jobService.currentJobExtra.completionDate, field.calculationDaysToAdd, field.isCalculationDaysWorkingDays);
    } else if (field.trackingCalculationFieldId === TrackingCalculationFieldEnum.SiteStart) {
      return '';
    } else if (field.trackingCalculationFieldId === TrackingCalculationFieldEnum.TitleDue) {
      const titleDueDate = this.jobService.currentJob.titleDueDate;
      if (titleDueDate) {
        return this.utilsService.addDays(titleDueDate, field.calculationDaysToAdd, field.isCalculationDaysWorkingDays);
      }
    } else {
      return '';
    }
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'expand',
          onClick: this.expandAll.bind(this),
          matTooltip: 'Collapse All Rows'
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'collapse',
          onClick: this.collapseAll.bind(this),
          matTooltip: 'Collapse All Rows'
        }
      });
  }

  collapseAll() {
    this.grid.instance.collapseAll();
  }

  expandAll() {
    this.grid.instance.expandAll();
  }

  dateOnly(cell): Date {
    if (cell.value) {
      const currentDate = new Date(cell.value).toDateString();

      if (currentDate === 'Mon Jan 01 0001') {
        return null
      }
      return new Date(cell.value);
    }
    else {
      var newDate = null;
      return newDate;
    }
  }
}
