export class AllEstimatingItem {
  id: number;
  jobId: number;
  jobVariationId: number;
  recipeCode: string;
  description: string;
  unitOfMeasure: string;
  quantity: number;
  rate: number;
  isTBA: boolean;
  lineCost: number;

  masterGroupCostCentre: string;
  subGroupItemDesc: string;
  costCentreId: number;

  constructor(jobVariationId: number, recipeCode: string, description: string, unitOfMeasure: string, quantity: number, rate: number, costIsPer: number) {
    this.jobVariationId = jobVariationId;
    this.recipeCode = recipeCode;
    this.description = description;
    this.unitOfMeasure = unitOfMeasure;
    this.quantity = quantity;
    this.rate = rate;
    this.lineCost = (quantity ?? 0) * (rate ?? 0) / costIsPer;
  }
}
