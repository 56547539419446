export class Recipe {
  public id: number;
  public recipeParentId: number;
  public recipeTypeId: number;
  public orderNumber: number;
  public recipeCode: string;
  public description: string;
  public unitOfMeasure: string;
  public rate: number;
  public scale: number;
  public rateLastUpdatedDate: Date;
  public isActive: boolean;
  public companyMarginId: number;
  public isGSTFree: boolean;
  public sellingPrice: number;
  public sellingPriceLastUpdated: Date;
  public districtId: number;
  public costingDate: Date;
  public recipeCostingVendorTypeId: number;
  public recipeRateId: number;

  public margin: number; // markup %

  public priceFileItemId: number;
  public masterGroupCostCentre: string;
  public subGroupItemDesc: string;
  public expiryDate: Date; // for a price file item

  public districtCostedId: number; // so we can tell if the recipe needs to be recosted
}
