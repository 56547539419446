import { JobService } from './job.service';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';
import { LeadManagementDeal } from '../../dtos/lead-management-deal';
import { catchError, map, tap } from 'rxjs/operators';
import { JobIntegration } from '../../dtos/job-integration';
import { LeadManagementField } from '../../dtos/lead-management-field';
import { LeadManagementContact } from '../../dtos/lead-management-contact';

@Injectable()
export class IntegrationService {
  leadManagementDealsCompany: string;
  leadManagementDeals: LeadManagementDeal[];
  leadManagementDealCompany: string;
  leadManagementDeal: LeadManagementDeal;
  leadManagementDealId: string;
  leadManagementFieldsCompany: string;
  leadManagementFields: LeadManagementField[];
  leadManagementFieldsDealId: string;
  leadManagementSecondContact: LeadManagementContact;
  onlyJobData: boolean;
  leadManagementDealDivisionId: number;
  leadManagementFieldsDivisionId: number;
  leadManagementDealsDivisionId: number;

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private jobService: JobService,
    private globalService: GlobalService) { }

  getDeals(useCache: boolean, divisionId: number): Observable<LeadManagementDeal[]> {
    let url = this.globalService.getApiUrl() + '/lead-management/deals';
    if (divisionId) {
      url += '?divisionId=' + divisionId;
    }
    return this._http.get<LeadManagementDeal[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.leadManagementDeals = res;
        this.leadManagementDealsCompany = this.globalService.getCurrentCompanyId();
        this.leadManagementDealsDivisionId = divisionId;
      }),
      catchError(this.handleError));
  }

  getDeal(useCache: boolean, dealId: string, divisionId: number): Observable<LeadManagementDeal> {
    let url = this.globalService.getApiUrl() + '/lead-management/deals/' + dealId;
    if (divisionId) {
      url += '?divisionId=' + divisionId;
    }
    return this._http.get<LeadManagementDeal>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.leadManagementDeal = res;
        this.leadManagementDealId = dealId;
        this.leadManagementDealCompany = this.globalService.getCurrentCompanyId();
        this.leadManagementDealDivisionId = divisionId;
      }),
      catchError(this.handleError));
  }

  getDealFields(useCache: boolean, dealId: string, divisionId: number): Observable<LeadManagementField[]> {
    let url = this.globalService.getApiUrl() + '/lead-management/deals/' + dealId + '/fields';
    if (divisionId) {
      url += '?divisionId=' + divisionId;
    }
    return this._http.get<LeadManagementField[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.leadManagementFields = res;
        this.leadManagementFieldsDealId = dealId;
        this.leadManagementFieldsCompany = this.globalService.getCurrentCompanyId();
        this.leadManagementFieldsDivisionId = divisionId;
      }),
      catchError(this.handleError));
  }

  getLeadManagementContact(contactId: string, divisionId: number): Observable<LeadManagementContact> {
    if (!contactId) {
      return of(null);
    }

    let url = this.globalService.getApiUrl() + '/lead-management/contacts/' + contactId;
    if (divisionId) {
      url += '?divisionId=' + divisionId;
    }
    return this._http.get<LeadManagementContact>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getDealData(useCache: boolean, dealId: string, secondContactId: string, divisionId: number): Observable<LeadManagementField[]> {
    return forkJoin(
      [
        this.getDealFields(useCache, dealId, divisionId),
        this.getLeadManagementContact(secondContactId, divisionId)
      ]
    )
      .pipe(map(
        ([dataRecords, secondContact]) => {
          this.leadManagementSecondContact = secondContact;
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getDealFieldsData(useCache: boolean, dealId: string, divisionId: number): Observable<LeadManagementField[]> {
    return forkJoin(
      [
        this.getDealFields(useCache, dealId, divisionId),
      ]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getJobIntegrations(integrationTypeId: number): Observable<JobIntegration[]> {
    return this._http.get<JobIntegration[]>(this.globalService.getApiUrl() + '/job-integrations?integrationTypeId=' + integrationTypeId,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getJobIntegration(jobId: number, integrationTypeId: number): Observable<JobIntegration> {
    return this._http.get<JobIntegration>(this.globalService.getApiUrl() + '/jobs/' + jobId + '/integrations/' + integrationTypeId,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getJobIntegrationData(jobId: number, integrationTypeId: number): Observable<JobIntegration> {
    return forkJoin(
      [
        this.getJobIntegration(jobId, integrationTypeId),
        this.jobService.getCompanyRoles()
      ]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  updateJobIntegration(jobId: number, integrationTypeId: number, integrationId: string): Observable<JobIntegration> {
    const url = this.globalService.getApiUrl() + '/job-integrations';
    const dataRecord = { jobId: jobId, integrationTypeId: integrationTypeId, integrationId: integrationId };
    return this._http.post<JobIntegration>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  deleteJobIntegration(jobId, integrationTypeId: number) {
    const url = this.globalService.getApiUrl() + '/job-integrations/job/' + jobId + '/type/' + integrationTypeId;
    // Clear the cache
    this.leadManagementDealCompany = '';
    this.leadManagementDealId = '';
    this.leadManagementFieldsCompany = '';
    this.leadManagementFieldsDealId = '';
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  createDeal(jobId: number): Observable<JobIntegration> {
    const url = this.globalService.getApiUrl() + '/lead-management/create-deal/' + jobId;
    return this._http.post<JobIntegration>(url, '', this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }

}
