<div ngbDropdown>
  <button class="btn-sm btn-outline-primary dropdownmenuclass" id="dropdownBasic1" ngbDropdownToggle
    (click)="$event.preventDefault();">
    <span *ngIf="jobRole.user && jobRole.user.fullName">
      {{jobRole.user.fullName}}
    </span>
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdownmenuclass">
    <div *ngFor="let user of users">
      <button class="dropdown-item" (click)='saveUser(user)'>
        {{user.fullName}}
      </button>
    </div>
  </div>
</div>