import { JobVarItemService } from './../../../services/felixApi/job-var-item.service';
import { AuthService } from './../../../services/auth.service';
import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { EstimatingService } from '../../../services/felixApi/estimating.service';
import { RecipeTypeEnum } from '../../../dtos/recipe-type.enum';
import { NotificationService } from '../../../services/notification.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { JobService } from '../../../services/felixApi/job.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { GlobalService } from '../../../services/global.service';
import { IJobVarCost } from '../../../dtos/job-var-cost';
import mathString from 'math-string';
import { JobItemService } from '../../../services/felixApi/job-item.service';
import { VariationStatusEnum } from '../../../dtos/variation-status.enum';
import { EstimatingAttachmentsModalComponent } from '../estimating-attachments-modal/estimating-attachments-modal.component';
import { ConfigurationEnum } from '../../../dtos/configuration-enum';
import { CompanyService } from '../../../services/felixApi/company.service';
import { EstimatingSaveToRecipeModalComponent } from '../estimating-save-to-recipe-modal/estimating-save-to-recipe-modal.component';
import { JobExtra } from '../../../dtos/job-extra';
import { District } from '../../../dtos/district';
import ArrayStore from 'devextreme/data/array_store';
import { VariationService } from '../../../services/felixApi/variation.service';
import { formatDate } from 'devextreme/localization';
import { UtilsService } from '../../../services/utils.service';
import { UserService } from '../../../services/felixApi/user.service';
import DataSource from 'devextreme/data/data_source';
import { Recipe } from '../../../dtos/recipe';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from '../../../../environments/environment';
import { UnitOfMeasure } from '../../../dtos/unitOfMeasure';

@Component({
  selector: 'js-estimating-modal',
  templateUrl: './estimating-modal.component.html',
  styleUrls: ['./estimating-modal.component.scss'],
  preserveWhitespaces: true
})
export class EstimatingModalComponent implements OnInit, OnDestroy {
  @Input() jobVarItemId: number;
  @Input() itemDescription: string;
  @Input() selection: string;
  @Input() variationId: number;

  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
  @ViewChild('lookupdataGrid') lookupDataGrid: DxDataGridComponent;

  feesSectionExpanded = true;
  dataSource: DataSource;
  recipeTypeEnum = RecipeTypeEnum;
  loading = true;
  refreshMode: string;
  subscriptions: Subscription[] = [];
  itemTotal = 0;
  itemTotalPreFees = 0;
  itemTotalExGST = 0;
  recipeData: CustomStore;
  catalogueData: DataSource;
  dropDownOptions: object;
  optionsForm: UntypedFormGroup;
  popupVisible = false;
  allowUpdate = false;
  variationStatusEnum = VariationStatusEnum;
  isTBA = false;

  updatedData: any;

  enterKeyActions: Array<string>;
  enterKeyDirections: Array<string>;
  editOnkeyPress: boolean;
  enterKeyAction: string;
  enterKeyDirection: string;
  innerHeight: number;
  gridHeight: number;
  itemToExplode: any;
  jobVarCost: IJobVarCost;
  selectedRecipes: string[] = [];
  recipeCodes: string[] = [];
  configurationEnum = ConfigurationEnum;
  estimatingPreContractFeesMargin: boolean;
  gstPercent: any;
  modalTitle: string;

  priceFileBookId: number;
  setBook = 'Not Set';
  estimatingCostingDate: Date | any; // get today's date as the default date to get rates
  jobExtra: JobExtra;
  setPriceFilePopupVisible = false;
  districts: District[];
  districtsDataSource: ArrayStore;
  isDropDownBoxOpened = false;
  resetPopupVisible = false;
  defaultTipVisible = false; // dx tool tip
  updateMarkupPopupVisible = false;
  previousMarkup: number;
  repricePopupVisible: boolean;
  unitOfMeasures: UnitOfMeasure[];
  estimatingRecipesUseRecipeMarkup: boolean;

  constructor(private _activeModal: NgbActiveModal,
    private _fb: UntypedFormBuilder,
    private globalService: GlobalService,
    private _jobItemService: JobItemService,
    private jobVarItemService: JobVarItemService,
    private _userService: UserService,
    private variationService: VariationService,
    private notiService: NotificationService,
    private jobService: JobService,
    private modalService: NgbModal,
    private companyService: CompanyService,
    private estimatingService: EstimatingService,
    private authService: AuthService,
    private utilsService: UtilsService) {

    this.refreshMode = 'reshape'; // ['full', 'reshape', 'repaint']
    this.enterKeyActions = this.estimatingService.getEnterKeyActions();
    this.enterKeyDirections = this.estimatingService.getEnterKeyDirections();
    this.editOnkeyPress = true;
    this.enterKeyAction = 'moveFocus';
    this.enterKeyDirection = 'column';

    this.calculateTotal = this.calculateTotal.bind(this);
    this.calculateLineTotal = this.calculateLineTotal.bind(this);
    this.setNewQuantityStringCellValue = this.setNewQuantityStringCellValue.bind(this);
    this.setOldQuantityStringCellValue = this.setOldQuantityStringCellValue.bind(this);
    this.setRateCellValue = this.setRateCellValue.bind(this);
    this.setMarginCellValue = this.setMarginCellValue.bind(this);
    this.setRecipeCellValue = this.setRecipeCellValue.bind(this);
    this.toggleToolTip = this.toggleToolTip.bind(this);
    this.calculateCostTotal = this.calculateCostTotal.bind(this);
    this.setUnitOfMeasureCellValue = this.setUnitOfMeasureCellValue.bind(this);
  }

  ngOnInit() {
    // check if we can update
    if (this._jobItemService.currentVariation.statusId < this.variationStatusEnum.PendingApproval
      || this.authService.isAdminOrSuperUser
      || this.authService.getSelectionsPermissions('Estimating') === 'Admin') {
      this.allowUpdate = true;
    }

    // get markup flag
    this.estimatingRecipesUseRecipeMarkup = this.globalService.getCompanyConfigValue(this.configurationEnum.EstimatingAddRecipeUsesRecipeMarkup) === 1;

    this.modalTitle = this.itemDescription + ': ' + this.selection;

    if (this.jobService.currentJob.variationMargin) {
      // we hide the margins if already set
      this.feesSectionExpanded = false;
    }

    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerHeightChanged.subscribe(height => {
        setTimeout(() => {
          this.setHeight();
        }, 500); // wait for iPhone
      })
    );

    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerWidthChanged.subscribe(width => {
        setTimeout(() => {
          this.setHeight();
        }, 500); // wait for iPhone
      })
    );

    this.setHeight();

    this.getEstimatingData(true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getEstimatingData(useCache: boolean) {
    this.subscriptions = this.subscriptions.concat(
      this.estimatingService.getEstimatingData(useCache).subscribe({
        next: () => {
          this.districts = this.estimatingService.districts;
          this.unitOfMeasures = this.estimatingService.unitOfMeasures;
          this.districtsDataSource = new ArrayStore({
            data: this.districts,
            key: 'id'
          });

          this.getJobVarCost(useCache);
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  getJobVarCost(useCache: boolean) {
    this.jobVarCost = null;
    this.subscriptions = this.subscriptions.concat(
      this.estimatingService.getJobVarCost(this.jobVarItemId)
        .subscribe({
          next: (jobVarCost) => {
            this.jobVarCost = jobVarCost;
            this.getJobExtra(useCache);
          },
          error: () => {
            this.getJobExtra(useCache);
          }
        })
    );
  }

  getJobExtra(useCache: boolean) {
    this.subscriptions = this.subscriptions.concat(
      this.jobService.getJobExtras(this.jobService.currentJob.id)
        .subscribe({
          next: (jobExtra) => {
            this.jobExtra = jobExtra;
            this.setEstimatingDate(useCache);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  setEstimatingDate(useCache: boolean) {
    if (this.jobExtra && this.jobExtra.districtId) {
      const district = this.districts.find(i => i.id === this.jobExtra.districtId);
      if (district) {
        this.setBook = district.description;
        this.priceFileBookId = this.jobExtra.districtId;
      }
    } else {
      // get the default
      const division = this._userService.divisions.find(i => i.isActive && i.id === this.jobService.currentJob.divisionId);
      const districtId = division?.defaultDistrictId ?? this.districts.find(i => i.isActive && !i.districtParentId).id;
      const districtMaster = this.districts.find(i => i.id === districtId);
      if (districtMaster) {
        this.setBook = districtMaster.description;
        this.priceFileBookId = districtMaster.id;
      }
    }

    let dateString = this.utilsService.convertDateToString(this._jobItemService.currentVariation.estimatingCostingDate, null);

    // we can have a division set default to add weeks to today every time - this speeds it up as the cache will be used for the recipes etc.
    const division = this._userService.divisions.find(i => i.id === this.jobService.currentJob.divisionId);

    if (division?.weeksToAddToPricingDateFromToday) {
      // get today plus 3 months
      this.estimatingCostingDate = new Date();
      this.estimatingCostingDate.setDate(this.estimatingCostingDate.getDate() + (division.weeksToAddToPricingDateFromToday * 7));
      dateString = this.utilsService.convertDateToString(this.estimatingCostingDate, null);
    }

    if (!dateString) {
      // use todays date then see if we need to add default days
      this.estimatingCostingDate = new Date();

      if (division && this._jobItemService.currentVariation.statusId < this.variationStatusEnum.PendingApproval) {
        // add days from division
        if (this._jobItemService.currentVariation.variationType < 10) {
          if (division.weeksToAddToPricingDateForPreConstruction) {
            this.estimatingCostingDate.setDate(this.estimatingCostingDate.getDate()
              + division.weeksToAddToPricingDateForPreConstruction * 7);
          }
        } else if (this._jobItemService.currentVariation.variationType < 20) {
          if (division.weeksToAddToPricingDateForSales) {
            this.estimatingCostingDate.setDate(this.estimatingCostingDate.getDate()
              + division.weeksToAddToPricingDateForSales * 7);
          }
        } else {
          if (division.weeksToAddToPricingDateForPreContract) {
            this.estimatingCostingDate.setDate(this.estimatingCostingDate.getDate()
              + division.weeksToAddToPricingDateForPreContract * 7);
          }
        }
      }
      this._jobItemService.currentVariation.estimatingCostingDate = this.estimatingCostingDate;
      this.setPriceFile(); // this will loop around to set the EstimatingDate
    } else {
      if (dateString < formatDate(new Date(), 'yyyy-MM-dd')) {
        this.notiService.showWarning('The date used is in the past');
        this.feesSectionExpanded = true;
      }
      this.estimatingCostingDate = new Date(dateString);
      this.setupForm(useCache);
    }
  }

  setupForm(useCache: boolean) {
    const isGSTFree = !this.jobVarCost ? this.jobService.currentJob.isGSTFree : this.jobVarCost.gstRate ? false : true;

    let gstRateToUse = isGSTFree ? 0 : !this.jobVarCost ? this.estimatingService.gstRate : this.jobVarCost.gstRate;

    // if (this.jobVarCost) {
    //   gstRateToUse = this.jobVarCost.gstRate;
    // }

    this.optionsForm = this._fb.group({
      variationMargin: this.jobService.currentJob.variationMargin,
      feesMargin: this.jobService.currentJob.feesMargin,
      gstRate: gstRateToUse,
      isGSTFree: isGSTFree,
      isFeesMarginActive: this.jobVarCost ? this.jobVarCost.isFeesMarginActive : true
    });

    // get config
    if (!this.jobService.currentJob.isLocked
      && this.globalService.getCompanyConfigValue(this.configurationEnum.EstimatingPreContractFeesMargin) === 1) {
      this.estimatingPreContractFeesMargin = true;

      // get the fees margin if not set - get the first one
      if (!this.jobService.currentJob.feesMargin) {
        this.subscriptions = this.subscriptions.concat(
          this.companyService.getAllCompanyFeesMargins().subscribe(feesMargins => {
            this.optionsForm.patchValue({
              feesMargin: feesMargins[0].margin
            });
          })
        );
      }
    }

    this.setUpRecipeData(useCache);
    this.setUpDataSet();
  }

  onEditorPreparing(e: any) {
    if (e.parentType !== 'dataRow') {
      return;
    } else {
      if (e.dataField === 'description') {
        e.editorName = 'dxTextArea';
        e.editorOptions.autoResizeEnabled = true;
        let prevHeight = null;
        e.editorOptions.onInput = (args) => {
          const td = args.element.closest('td');
          if (prevHeight !== td.offsetHeight) {
            const overlay = e.element.querySelector('.dx-datagrid-focus-overlay');
            if (overlay != null) {
              overlay.style.height = (td.offsetHeight + 1) + 'px';
            }
            prevHeight = td.offsetHeight;
          }
        };
      } else if (e.dataField === 'unitOfMeasure' && e.row.data.recipeCode) {
        e.editorOptions.disabled = true;
      }
    }
  }

  setUpDataSet() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.estimatingService.getJobVarCostItems(this.jobVarItemId).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        values.jobVarItemId = this.jobVarItemId;
        if (values.recipeCode) {
          values.recipeCode = values.recipeCode.toUpperCase();
        }
        return new Promise((resolve, reject) =>
          this.estimatingService.addJobVarCostItem(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        if (values.recipeCode) {
          values.recipeCode = values.recipeCode.toUpperCase();
        }
        return new Promise((resolve, reject) =>
          this.estimatingService.updateJobVarCostItem(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.estimatingService.deleteJobVarCostItem(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  subscribeToWidthChanges() {
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerHeightChanged.subscribe(height => {
        this.setHeight();
      })
    );
  }

  setHeight() {
    this.gridHeight = this.globalService.innerHeight - 310;

    if (this.feesSectionExpanded) {
      this.gridHeight -= 76;
    }

    this.dropDownOptions = { width: 800, height: this.gridHeight < 800 ? 500 : this.gridHeight - 300 };
  }

  closeModal(ans: boolean) {
    if (this.dataGrid?.instance.hasEditData()) {
      this.notiService.showInfo('Please Save or Cancel the edited data');
    } else {
      if (ans) {
        if (this.optionsForm.get('variationMargin').value !== this.jobService.currentJob.variationMargin
          || this.optionsForm.get('isGSTFree').value !== this.jobService.currentJob.isGSTFree) {
          // update the job
          this.loading = true;
          this.updatedData = {
            variationMargin: this.optionsForm.get('variationMargin').value
          };
          this.subscriptions = this.subscriptions.concat(
            this.jobService.updateJob(this.updatedData).subscribe({
              next: () => {
                this.jobService.currentJob.variationMargin = this.optionsForm.get('variationMargin').value;
                this.getJobVarCostForUpdate();
              },
              error: (err) => {
                this.notiService.notify(err);
                this.loading = false;
              }
            })
          );
        } else {
          this.getJobVarCostForUpdate();
        }
      } else {
        this._activeModal.dismiss();
      }
    }
  }

  updateJobVarCost() {
    // check we have not added the JobVarCost record by adding a line
    if (this.jobVarCost) {
      if (this.jobVarCost.gstRate !== this.optionsForm.get('gstRate').value
        || this.jobVarCost.isFeesMarginActive !== this.optionsForm.get('isFeesMarginActive').value) {
        this.loading = true;
        this.updatedData = {
          gstRate: this.optionsForm.get('gstRate').value,
          isFeesMarginActive: this.optionsForm.get('isFeesMarginActive').value
        };
        this.subscriptions = this.subscriptions.concat(
          this.estimatingService.updateJobVarCost(this.jobVarCost.id, this.updatedData).subscribe({
            next: () => {
              this.close();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
        );
      } else {
        this.close();
      }
    } else {
      // insert
      this.loading = true;
      this.updatedData = {
        jobVarItemId: this.jobVarItemId,
        gstRate: this.optionsForm.get('gstRate').value,
        isFeesMarginActive: this.optionsForm.get('isFeesMarginActive').value
      };
      this.subscriptions = this.subscriptions.concat(
        this.estimatingService.addJobVarCost(this.updatedData).subscribe({
          next: () => {
            this.close();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
      );
    }
  }

  getJobVarCostForUpdate() {
    this.jobVarCost = null;
    this.subscriptions = this.subscriptions.concat(
      this.estimatingService.getJobVarCost(this.jobVarItemId)
        .subscribe({
          next: (jobVarCost) => {
            this.jobVarCost = jobVarCost;
            this.updateJobVarCost();
          },
          error: () => {
            this.updateJobVarCost();
          }
        })
    );
  }

  onFocusedCellChanging(e) {
    e.isHighlighted = true;
  }

  close() {
    if (this.isTBA) {
      this._activeModal.close(null);
    } else {
      this._activeModal.close(this.itemTotal);
    }
  }

  setUpRecipeData(useCache: boolean) {
    if (!useCache || !this.estimatingService.estimatingCostingDateString || this.estimatingService.estimatingCostingDateString !== this.estimatingCostingDate.toDateString()
      || !this.estimatingService.priceFileBookId || this.estimatingService.priceFileBookId !== this.priceFileBookId) {
      this.estimatingService.estimatingCostingDateString = this.estimatingCostingDate.toDateString();
      this.estimatingService.priceFileBookId = this.priceFileBookId;
      let dateTimer = new Date();
      console.log('recalculating of recipes at ' + this.estimatingService.estimatingCostingDateString + ' at ' + dateTimer.getMilliseconds() + 'ms');
      const recipes = this.estimatingService.getRecipesForDateAndDistrict(this.estimatingCostingDate, this.priceFileBookId);
      const priceFileItems = this.estimatingService.getItemsForDateAndDistrict(this.estimatingCostingDate, this.priceFileBookId, useCache)

      priceFileItems.forEach(item => {
        const newItem = new Recipe();
        newItem.recipeCode = item.priceFileCode;
        newItem.description = item.description;
        newItem.rate = item.rate;
        newItem.unitOfMeasure = this.estimatingService.unitOfMeasures?.find(i => i.id === item.unitOfMeasureId)?.description;

        if (item.expiryDate) {
          const effectiveDateString = this.estimatingCostingDate.getFullYear() + '-'
            + ('0' + (this.estimatingCostingDate.getMonth() + 1)).toString().slice(-2) + '-'
            + ('0' + this.estimatingCostingDate.getDate()).slice(-2);

          if (item.expiryDate.toString().slice(0, 10) < effectiveDateString) {
            newItem.rate = null;
          }
        }
        newItem.expiryDate = item.expiryDate;

        newItem.priceFileItemId = item.priceFileItemId;
        newItem.masterGroupCostCentre
          = 'I' + ('00000' + item.groupOrderNumber.toString()).slice(-6) + ';' + item.masterGroupCostCentre;
        newItem.subGroupItemDesc = ('00000' + item.subGroupOrderNumber.toString()).slice(-6) + ';' + item.subGroupItemDesc;

        recipes.push(newItem);
      });

      this.estimatingService.recipesAndItems = recipes;
      dateTimer = new Date();
      console.log('recalculating of recipes done' + ' at ' + dateTimer.getMilliseconds() + 'ms');
    }

    this.recipeData = new CustomStore({
      key: 'recipeCode',
      loadMode: 'raw',
      load: () => this.estimatingService.recipesAndItems
    });
    this.loading = false;
  }

  calcQty(rowData, calcLineTotal: boolean): number {
    let validQty = true;

    if (rowData) {
      rowData.newQuantity = 0;
      if (rowData.newQuantityString) {
        const newQuantityString = this.getQtyString(rowData.newQuantityString);

        try {
          rowData.newQuantity = mathString(newQuantityString);
        } catch (err) {
          console.log('newQuantityString ERROR: ' + rowData.newQuantityString);
          validQty = false;
          rowData.newQuantity = 0;
        }

        if (isNaN(rowData.newQuantity)) {
          validQty = false;
          rowData.newQuantity = 0;
        }
      }

      rowData.oldQuantity = 0;
      if (rowData.oldQuantityString) {
        const oldQuantityString = this.getQtyString(rowData.oldQuantityString);

        try {
          rowData.oldQuantity = mathString(oldQuantityString);
        } catch (err) {
          validQty = false;
          rowData.oldQuantity = 0;
        }

        if (isNaN(rowData.oldQuantity)) {
          validQty = false;
          rowData.oldQuantity = 0;
        }
      }

      if (calcLineTotal) {
        const tempLineTotal = this.calculateLineTotal(rowData);
        if (tempLineTotal != rowData.lineTotal) {
          rowData.lineTotal = tempLineTotal;
        }
      }

      if (validQty) {
        return Math.round(((rowData.newQuantity - rowData.oldQuantity) + Number.EPSILON) * 10000) / 10000;
      } else {
        return null;
      }
    } else {
      return 0;
    }
  }

  getQtyString(oldQtyString): string {
    let newQuantityString = oldQtyString;
    newQuantityString = newQuantityString.replace(/(^\s+|\s+$)/g, '');
    newQuantityString = newQuantityString.replace(/=/g, '');
    if (newQuantityString.search(' ') >= 0) {
      newQuantityString = newQuantityString.replace(/\s/g, '');
    }
    return newQuantityString;
  }

  setUnitOfMeasureCellValue(rowData, value, originalData) {
    if (rowData.unitOfMeasure !== value) {
      rowData.unitOfMeasure = value;
      rowData.oldQuantityString = rowData.oldQuantityString ? rowData.oldQuantityString : originalData.oldQuantityString;
      rowData.newQuantityString = rowData.newQuantityString ? rowData.newQuantityString : originalData.newQuantityString;
      rowData.rate = rowData.rate ? rowData.rate : originalData.rate;
      rowData.margin = rowData.margin ? rowData.margin : originalData.margin;
      rowData.recipeCode = rowData.recipeCode ? rowData.recipeCode : originalData.recipeCode;
      this.calcQty(rowData, true);
    }
  }

  setNewQuantityStringCellValue(rowData, value, originalData) {
    if (rowData.newQuantityString !== value) {
      rowData.newQuantityString = value;
      rowData.oldQuantityString = rowData.oldQuantityString ? rowData.oldQuantityString : originalData.oldQuantityString;
      rowData.rate = rowData.rate ? rowData.rate : originalData.rate;
      rowData.margin = rowData.margin ? rowData.margin : originalData.margin;
      rowData.recipeCode = rowData.recipeCode ? rowData.recipeCode : originalData.recipeCode;
      rowData.unitOfMeasure = rowData.unitOfMeasure ? rowData.unitOfMeasure : originalData.unitOfMeasure;
      this.calcQty(rowData, true);
    }
  }

  setOldQuantityStringCellValue(rowData, value, originalData) {
    if (rowData.oldQuantityString !== value) {
      rowData.oldQuantityString = value;
      rowData.newQuantityString = rowData.newQuantityString ? rowData.newQuantityString : originalData.newQuantityString;
      rowData.rate = rowData.rate ? rowData.rate : originalData.rate;
      rowData.margin = rowData.margin ? rowData.margin : originalData.margin;
      rowData.recipeCode = rowData.recipeCode ? rowData.recipeCode : originalData.recipeCode;
      rowData.unitOfMeasure = rowData.unitOfMeasure ? rowData.unitOfMeasure : originalData.unitOfMeasure;
      this.calcQty(rowData, true);
    }
  }

  setRateCellValue(rowData, value, originalData) {
    if (rowData.rate !== value) {
      rowData.rate = value;
      rowData.oldQuantityString = rowData.oldQuantityString ? rowData.oldQuantityString : originalData.oldQuantityString;
      rowData.newQuantityString = rowData.newQuantityString ? rowData.newQuantityString : originalData.newQuantityString;
      rowData.margin = rowData.margin ? rowData.margin : originalData.margin;
      rowData.recipeCode = rowData.recipeCode ? rowData.recipeCode : originalData.recipeCode;
      rowData.unitOfMeasure = rowData.unitOfMeasure ? rowData.unitOfMeasure : originalData.unitOfMeasure;
      this.calcQty(rowData, true);
    }
  }

  setMarginCellValue(rowData, value, originalData) {
    if (rowData.margin !== value) {
      rowData.margin = value;
      rowData.oldQuantityString = rowData.oldQuantityString ? rowData.oldQuantityString : originalData.oldQuantityString;
      rowData.newQuantityString = rowData.newQuantityString ? rowData.newQuantityString : originalData.newQuantityString;
      rowData.rate = rowData.rate ? rowData.rate : originalData.rate;
      rowData.recipeCode = rowData.recipeCode ? rowData.recipeCode : originalData.recipeCode;
      rowData.unitOfMeasure = rowData.unitOfMeasure ? rowData.unitOfMeasure : originalData.unitOfMeasure;
      this.calcQty(rowData, true);
    }
  }

  calculateQty = (rowData) => {
    if (rowData) {
      return this.calcQty(rowData, false);
    }
  }

  calculateLineTotal = (rowData) => {
    if (rowData) {
      const newQty = !rowData.newQuantity || isNaN(rowData.newQuantity) ? 0 : rowData.newQuantity;
      const oldQty = !rowData.oldQuantity || isNaN(rowData.oldQuantity) ? 0 : rowData.oldQuantity;
      const rate = !rowData.rate || isNaN(rowData.rate) ? 0 : rowData.rate;
      const margin = !rowData.margin || isNaN(rowData.margin) ? 1 : 1 + (rowData.margin / 100);

      let qty = newQty - oldQty;
      if (rowData.recipeId) {
        const lineRecipe = this.estimatingService.allRecipes.find(i => i.id === rowData.recipeId);
        if (lineRecipe && lineRecipe.unitOfMeasure) {
          const unitOfMeasure = this.estimatingService.unitOfMeasures.find(i => i.description === lineRecipe.unitOfMeasure);
          if (unitOfMeasure && unitOfMeasure.costIsPer) {
            qty /= unitOfMeasure.costIsPer;
          }
        }
      } else {
        const unitOfMeasure = this.estimatingService.unitOfMeasures.find(i => i.description === rowData.unitOfMeasure);
        if (unitOfMeasure && unitOfMeasure.costIsPer) {
          qty /= unitOfMeasure.costIsPer;
        }
      }
      return this.utilsService.roundEven(qty * rate * margin);
    } else {
      return 0;
    }
  }

  calculateTotal = (options) => {
    if (options.name === 'gridTotal') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
        this.isTBA = false;
        this.itemTotalExGST = 0;
        this.itemTotalPreFees = 0;
        this.itemTotal = 0;

      } else if (options.summaryProcess === 'calculate') {
        options.totalValue += options.value.lineTotal ?? 0;

        this.isTBA = options.value.isTBA ? true : this.isTBA;
        this.itemTotalExGST = options.totalValue;

      } else if (options.summaryProcess === 'finalize') {
        if (!this.isTBA) {
          this.gstPercent = (this.optionsForm.get('gstRate').value === null ? 0 : this.optionsForm.get('gstRate').value) / 100;
          this.itemTotalPreFees =
            this.utilsService.roundEven(this.itemTotalExGST === null ? 0 : this.itemTotalExGST * (1 + this.gstPercent));

          this.itemTotal = this.itemTotalPreFees;

          if (this.estimatingPreContractFeesMargin
            && this.optionsForm.get('feesMargin').value && this.optionsForm.get('isFeesMarginActive').value) {
            this.itemTotal +=
              this.utilsService.roundEven(this.itemTotalPreFees * (this.optionsForm.get('feesMargin').value / 100) * (1 + this.gstPercent));
          }
        }
      }
    }
  }

  setEditedValue(valueChangedEventArg, cellInfo) {
    cellInfo.setValue(valueChangedEventArg.value);
  }

  onGSTChange() {
    this.dataGrid?.instance.refresh();
  }

  onSelectionChanged(cellInfo, dropDownBox, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0].recipeCode);
      dropDownBox.close();
    }
  }

  setRecipeCellValue(rowData, value, originalData) {
    if (value) {
      const recipeItem = this.estimatingService.recipesAndItems.find(i => i.recipeCode === value);
      rowData.recipeCode = value;
      rowData.description = recipeItem.description;
      rowData.unitOfMeasure = recipeItem.unitOfMeasure;
      rowData.priceFileItemId = recipeItem.priceFileItemId;
      if (recipeItem.rate) {
        if (recipeItem.scale) {
          rowData.rate = recipeItem.rate / recipeItem.scale;
        } else {
          rowData.rate = recipeItem.rate;
        }
      } else {
        rowData.rate = recipeItem.rate === null ? null : 0;
      }
      rowData.oldQuantityString = rowData.oldQuantityString ? rowData.oldQuantityString : originalData.oldQuantityString;
      rowData.newQuantityString = rowData.newQuantityString ? rowData.newQuantityString : originalData.newQuantityString;
      rowData.margin = rowData.margin ? rowData.margin : originalData.margin;

      if (this.estimatingRecipesUseRecipeMarkup) {
        const recipe = this.estimatingService.allRecipes.find(i => i.recipeCode === value);
        if (recipe?.margin) {
          rowData.margin = recipe.margin;
        }
      }

      this.calcQty(rowData, true);
    }
  }

  initNewRow = (e) => {
    e.data.margin = this.optionsForm.get('variationMargin').value;
  }

  isExplodeIconVisible = (e: any): boolean => {
    let result = false;
    if (this.allowUpdate) {
      const foundRecipe = this.estimatingService.allRecipes?.find(i => i.recipeCode === e.data.recipeCode);

      if (foundRecipe) {
        const foundLines = this.estimatingService.allRecipeLines?.find(i => i.recipeId === foundRecipe.id);

        if (foundLines) {
          result = true;
        }
      }
    }
    return result;
  }

  explode = () => {
    this.loading = true;
    this.popupVisible = false;

    this.subscriptions = this.subscriptions.concat(
      this.estimatingService.explodeJobVarCostItem(this.itemToExplode)
        .subscribe({
          next: () => {
            this.dataSource = null;
            this.setUpDataSet();
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  explodeAsk = (e) => {
    if (this.dataGrid?.instance.hasEditData()) {
      this.notiService.showInfo('Please save edited data before exploding');
    } else if (!this.optionsForm.get('variationMargin').value) {
      this.notiService.showInfo('Please set a mark-up before exploding');
    } else {
      this.popupVisible = true;
      this.itemToExplode = e.data.id;
    }
  }

  setMarginInJob() {
    if (this.allowUpdate) {
      if (this.dataGrid.instance.hasEditData()) {
        this.notiService.showWarning('Please save/cancel edited data before updating the mark-up');
        this.optionsForm.patchValue({
          variationMargin: this.jobService.currentJob.variationMargin
        });
      } else {
        this.updatedData = {
          variationMargin: this.optionsForm.get('variationMargin').value,
          feesMargin: this.optionsForm.get('feesMargin').value
        };
        this.subscriptions = this.subscriptions.concat(
          this.jobService.updateJob(this.updatedData).subscribe({
            next: () => {
              this.previousMarkup = this.jobService.currentJob.variationMargin;
              this.jobService.currentJob.variationMargin = this.optionsForm.get('variationMargin').value;
              this.jobService.currentJob.feesMargin = this.optionsForm.get('feesMargin').value;
              this.notiService.showInfo('Margin set');
              this.updateMarkupPopupVisible = true;
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
        );

        this.itemTotal = this.itemTotalPreFees;

        if (this.optionsForm.get('feesMargin').value && this.optionsForm.get('isFeesMarginActive').value) {
          this.itemTotal +=
            this.utilsService.roundEven(this.itemTotalPreFees * (this.optionsForm.get('feesMargin').value / 100) * (1 + this.gstPercent));
        }
      }
    }
  }

  onGSTFreeClick() {
    const newVal = !this.optionsForm.get('isGSTFree').value;
    if (newVal) {
      this.optionsForm.patchValue({
        gstRate: 0
      });
    } else {
      this.optionsForm.patchValue({
        gstRate: this.estimatingService.gstRate
      });
    }

    this.dataGrid?.instance.refresh();
  }

  addRecipesFromList() {
    // add the recipes selected
    if (this.dataGrid?.instance.hasEditData()) {
      this.notiService.showInfo('Please Save the edited data before adding multiple items');
    } else {
      this.loading = true;
      this.isDropDownBoxOpened = false;
      this.updatedData = { recipeCodes: this.selectedRecipes };

      this.subscriptions = this.subscriptions.concat(
        this.estimatingService.addJobVarCostItemsFromSelectedRecipes(this.jobVarItemId, this.updatedData).subscribe({
          next: (res) => {
            this.dataSource = null;
            this.setUpDataSet();
            this.selectedRecipes = [];
            this.lookupDataGrid.instance.clearFilter('search');
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
      );
    }
  }

  goToAttachments() {
    if (this.jobVarCost) {
      this.goToAttachmentsAfterCheck();
    } else {
      // insert
      this.loading = true;
      this.updatedData = { jobVarItemId: this.jobVarItemId, gstRate: this.optionsForm.get('gstRate').value };
      this.subscriptions = this.subscriptions.concat(
        this.estimatingService.addJobVarCost(this.updatedData).subscribe({
          next: (jobVarCost) => {
            this.loading = false;
            this.jobVarCost = jobVarCost;
            this.goToAttachmentsAfterCheck();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
      );
    }
  }

  goToAttachmentsAfterCheck() {
    const modalRef = this.modalService.open(EstimatingAttachmentsModalComponent,
      { windowClass: 'modal-edit', scrollable: true });
    modalRef.componentInstance.jobVarCostId = this.jobVarCost.id;

    modalRef.result.then(() => {
    }, () => {
    });
  }

  onMultiRowClick(e) {
    let keys = e.component.getSelectedRowKeys();
    const index = keys.indexOf(e.key);

    if (index > -1) {
      keys.splice(index, 1);
    } else {
      keys = keys.concat(e.key);
    }

    e.component.selectRows(keys);
  }

  onRowPrepared(e) {
    // we test to colour the row
    if (e.rowType === 'data') {
      if (e.data.isTBA) {
        e.rowElement.classList.add('yellowBackground');
      }
    }
  }

  saveToRecipe() {
    if (this.dataGrid?.instance.hasEditData()) {
      this.notiService.showInfo('Please Save the edited data before saving to a recipe');
    } else {
      const modalRef = this.modalService.open(EstimatingSaveToRecipeModalComponent,
        { windowClass: 'modal-1000' });
      modalRef.componentInstance.jobVarItemId = this.jobVarItemId;
      modalRef.componentInstance.gridHeight = this.gridHeight;

      modalRef.result.then(() => {
        this.getEstimatingData(false);
      }, () => { });
    }
  }

  selectPriceFile() {
    // set to a different book or date
    if (this.dataGrid?.instance.hasEditData()) {
      this.notiService.showInfo('Please Save the edited data before changing details');
    } else {
      this.setPriceFilePopupVisible = true;
    }
  }

  setPriceFile() {
    // set to a different book or date
    this.setPriceFilePopupVisible = false;
    this.setBook = this.districts.find(i => i.id === this.priceFileBookId).description;
    this.loading = true;
    const estimatingCostingDateToSet = formatDate(this.estimatingCostingDate, 'yyyy-MM-dd');

    const variationUpdate = { id: this._jobItemService.currentVariation.id, estimatingCostingDate: estimatingCostingDateToSet };
    this.subscriptions = this.subscriptions.concat(
      this.variationService.updateVariation(variationUpdate).subscribe({
        next: () => {
          this._jobItemService.currentVariation.estimatingCostingDate = this.estimatingCostingDate;
          this.estimatingCostingDate = this._jobItemService.currentVariation.estimatingCostingDate
          this.updateJobExtra();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  updateJobExtra() {
    const updateRecord = { jobId: this.jobService.currentJob.id, districtId: this.priceFileBookId };
    if (this.jobExtra) {
      if (this.jobExtra.districtId !== this.priceFileBookId) {
        this.subscriptions = this.subscriptions.concat(
          this.jobService.updateJobExtra(this.jobExtra.id, updateRecord).subscribe({
            next: (res) => {
              this.jobService.currentJobExtra = res;
              this.getJobExtra(true);
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
        );
      } else {
        this.getJobExtra(true);
      }
    } else {
      // create it
      this.subscriptions = this.subscriptions.concat(
        this.jobService.addJobExtra(updateRecord).subscribe({
          next: (res) => {
            this.jobService.currentJobExtra = res;
            this.getJobExtra(true);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
      );
    }
  }

  refreshRecipes() {
    if (this.dataGrid?.instance.hasEditData()) {
      this.notiService.showInfo('Please Save the edited data before refreshing');
    } else {
      this.loading = true;
      this.getEstimatingData(false);
    }
  }

  onCellPrepared(e) {
    if (e.rowType === 'data' && e.column.dataField === 'rate' && e.data.rate === null && e.data.recipeCode && e.data.recipeCode !== '') {
      e.cellElement.style.color = 'red';
    }
    if (e.rowType === 'data' && e.column.dataField === 'newQuantityString' && e.data.newQuantityString) {
      const newQuantityString = this.getQtyString(e.data.newQuantityString);
      try {
        const calcCheck = mathString(newQuantityString);
        if (isNaN(+calcCheck)) {
          e.cellElement.classList.add('redWhite');
        }
      } catch (err) {
        e.cellElement.classList.add('redWhite');
      }
    }
    if (e.rowType === 'data' && e.column.dataField === 'oldQuantityString' && e.data.oldQuantityString) {
      const oldQuantityString = this.getQtyString(e.data.oldQuantityString);
      try {
        const calcCheck = mathString(oldQuantityString);
        if (isNaN(+calcCheck)) {
          e.cellElement.classList.add('redWhite');
        }
      } catch (err) {
        e.cellElement.classList.add('redWhite');
      }
    }
    if (e.rowType === 'data' && e.column.dataField === 'rate') {
      if (!e.data.rate && (e.data.newQuantityString || e.data.oldQuantityString)) {
        e.cellElement.classList.add('pinkBackground');
      } else if (e.data.recipeCode && e.data.recipeCode !== '') {
        // check the rate is the same as the price file
        const matchingItem = this.estimatingService.recipesAndItems?.find(i => i.recipeCode === e.data.recipeCode);
        if (matchingItem && matchingItem.rate && e.data.rate) {
          const matchingRate = Math.round(matchingItem.rate * 100) / 100;
          const currentRate = Math.round(e.data.rate * 100) / 100;
    
          // Use Math.min to choose between 1% of the matching rate and $1 for tolerance
          const tolerance = Math.min(1, matchingRate * 0.01);
    
          // Check if the absolute rate difference exceeds the smaller of $1 or 1%
          if (Math.abs(matchingRate - currentRate) > tolerance) {
            e.cellElement.classList.add('pinkBackground');
          }
        }
      }
    }
    
  }

  onClickFeesActive() {
    if (this.allowUpdate) {
      this.itemTotal = this.itemTotalPreFees;

      if (this.optionsForm.get('feesMargin').value && !this.optionsForm.get('isFeesMarginActive').value) {
        this.itemTotal +=
          this.utilsService.roundEven(this.itemTotalPreFees * (this.optionsForm.get('feesMargin').value / 100) * (1 + this.gstPercent));
      }
    }
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'always',
        widget: 'dxButton',
        options: {
          width: 120,
          text: 'Reset Layout',
          onClick: this.clearStatePersistance.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Re-Price',
          onClick: this.reprice.bind(this)
        }
      });
  }

  clearStatePersistance() {
    this.resetPopupVisible = true;
  }

  clearStatePersistanceGo() {
    this.resetPopupVisible = false;
    this.loading = true;
    localStorage.removeItem('estimatingtaskgrid');
    setTimeout(() => {
      this.loading = false;
    }, 300);
  }

  getGroupTitle(cellInfo) {
    return cellInfo.data.key.split(';')[1];
  }

  getRecipeGroupTitle(cellInfo) {
    return cellInfo.data.key.split(';')[1];
  }

  getRate(recipeLine: any) {
    if (recipeLine.recipeCode && recipeLine.recipeCode !== '' && recipeLine.rate === null) {
      return 'Invalid';
    }
    return recipeLine.rate;
  }

  // calculateCostTotal(data: any) {
  //   return data.rate * (data.newQuantity - data.oldQuantity);
  // }

  calculateCostTotal(rowData: any) {
    const newQty = !rowData.newQuantity || isNaN(rowData.newQuantity) ? 0 : rowData.newQuantity;
    const oldQty = !rowData.oldQuantity || isNaN(rowData.oldQuantity) ? 0 : rowData.oldQuantity;
    let qty = newQty - oldQty;
    const rate = !rowData.rate || isNaN(rowData.rate) ? 0 : rowData.rate;

    if (rowData.recipeId) {
      const lineRecipe = this.estimatingService.allRecipes.find(i => i.id === rowData.recipeId);
      if (lineRecipe && lineRecipe.unitOfMeasure) {
        const unitOfMeasure = this.estimatingService.unitOfMeasures.find(i => i.description === lineRecipe.unitOfMeasure);
        if (unitOfMeasure && unitOfMeasure.costIsPer) {
          qty /= unitOfMeasure.costIsPer;
        }
      }
    } else {
      const unitOfMeasure = this.estimatingService.unitOfMeasures.find(i => i.description === rowData.unitOfMeasure);
      if (unitOfMeasure && unitOfMeasure.costIsPer) {
        qty /= unitOfMeasure.costIsPer;
      }
    }
    return this.utilsService.roundEven(qty * rate);
  }

  toggleToolTip(data) {
    this.defaultTipVisible = false;
    if (data.rowType === 'data' && data.column.dataField === 'rate') {
      if (!data.data.rate && (data.data.newQuantityString || data.data.oldQuantityString)) {
        this.defaultTipVisible = true;
      } else if (data.data.recipeCode && data.data.recipeCode !== '') {
        // check the rate is the same as the price file
        const matchingItem = this.estimatingService.recipesAndItems.find(i => i.recipeCode === data.data.recipeCode);
        if (matchingItem && matchingItem.rate !== data.data.rate) {
          this.defaultTipVisible = true;
        }
      }
    }
  }

  updateLinesToNewMarkup() {
    this.updateMarkupPopupVisible = false;
    this.loading = true;
    this.subscriptions = this.subscriptions.concat(
      this.estimatingService.updateJobVarCostItemsSetMargin(this.jobVarItemId, this.previousMarkup, this.optionsForm.get('variationMargin').value)
        .subscribe({
          next: () => {
            this.loading = false;
            this.setUpDataSet();
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
    );
  }

  reprice() {
    this.repricePopupVisible = true;
  }

  runReprice() {
    this.loading = true;
    this.repricePopupVisible = false;
    this.subscriptions = this.subscriptions.concat(
      this.jobVarItemService.repriceVariation(this.variationId, formatDate(this.estimatingCostingDate, 'yyyyMMdd'), this.jobVarItemId).subscribe({
        next: () => {
          this.loading = false;
          this.setUpDataSet();
        },
        error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  calculateCodeSortValue(data) {
    if (data.recipeCode && data.recipeCode.trim() !== '') {
      return data.recipeCode;
    }

    return '~'; // put at the end
  }
}
