import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs';

import { environment } from '../../environments/environment';
import { GlobalService } from './global.service';
import { AuthService } from './auth.service';
import { formatDate } from 'devextreme/localization';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

constructor(
  private globalService: GlobalService,
  private auth: AuthService) { }

  getHttpOptions(): { headers: HttpHeaders; } {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id': environment.clientName,
        'client-session-id': require('uuid').v4(),
        'client-version': environment.fullVersion,
        'company-id': this.globalService.getCurrentCompanyId(),
        'user-id': this.auth.getCurrentUserId() ? this.auth.getCurrentUserId().toString() : '0'
      })
    };
    return httpOptions;
  }

  getHttpFileOptions(): { headers: HttpHeaders; } {
    const httpOptions = {
      headers: new HttpHeaders({
        'client-id': environment.clientName,
        'client-session-id': require('uuid').v4(),
        'client-version': environment.fullVersion,
        'company-id': this.globalService.getCurrentCompanyId(),
        'user-id': this.auth.getCurrentUserId() ? this.auth.getCurrentUserId().toString() : '0'
      })
    };
    return httpOptions;
  }

  getHttpFilePDFOptions(): { headers: HttpHeaders; } {
    const httpOptions = {
      headers: new HttpHeaders({
        responseType: 'arraybuffer',
        'client-id': environment.clientName,
        'client-session-id': require('uuid').v4(),
        'client-version': environment.fullVersion,
        'company-id': this.globalService.getCurrentCompanyId(),
        'user-id': this.auth.getCurrentUserId() ? this.auth.getCurrentUserId().toString() : '0'
      })
    };
    return httpOptions;
  }

  jsonDateReplacer(key, value) {
    if (this[key] instanceof Date) {
      return formatDate(this[key], 'yyyy-MM-dd');
    } else {
      // check if this[key] is a date
      const checkNum = Date.parse(this[key]);
      const isDateField = key.includes('date') || key.includes('Date');
      if (!Number.isNaN(checkNum) && isDateField) {
        const newDate = new Date(checkNum);
        const newDateString = formatDate(newDate, 'yyyy-MM-dd');
        return newDateString;
      }
    }
    return value;
  }

  handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
