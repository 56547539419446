import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IJob } from '../dtos/job';
import { JobSearchTypeEnum } from '../dtos/job-search-type.enum';
import { AuthService } from '../services/auth.service';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '../services/felixApi/configuration.service';
import { NotificationService } from '../services/notification.service';
import { GlobalService } from '../services/global.service';
import { DESKTOP_SIZE, PHONE_SIZE } from '../../config/variables';
import { AddMaintenanceJobComponent } from './job-modals/add-maintenance-job/add-maintenance-job.component';

@Component({
  selector: 'js-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss']
})

export class JobComponent implements OnInit, OnDestroy {
  jobNum = '';
  errorMessage: any;
  formErrorMessage = '';
  job: IJob = new IJob();
  jobs: IJob[] = [];
  closeResult = '';
  jobsAdmin = false; // permission as a job admin?
  jobsWrite = false; // permission as job write?
  jobSearchTypeEnum = JobSearchTypeEnum; // when we do a job search we can specify if we want to get the templates or all jobs initially
  updateSuccessful = true;
  phoneSize = false;
  desktop = false;
  subscriptions: Subscription[] = [];
  loading = true;
  addingJob: boolean;

  constructor(private router: Router,
    private _authService: AuthService,
    private _globalService: GlobalService,
    private notiService: NotificationService,
    private modalService: NgbModal,
    private _configurationService: ConfigurationService) { }


  ngOnInit(): void {
    this.subscribeToWidthChanges();

    const permissionLevel = this._authService.getSelectionsPermissions('Jobs');
    if (permissionLevel === 'Admin' || this._authService.isAdminOrSuperUser()) {
      this.jobsAdmin = true;
      this.jobsWrite = true;
    } else if (permissionLevel === 'Write') {
      this.jobsWrite = true;
    } else if (permissionLevel !== 'Read' && !this._authService.isClient()) {
      // no access to trades also
      this.router.navigate(['selections/']);
    }

    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  subscribeToWidthChanges() {
    this.setWidth(this._globalService.innerWidth);

    this.subscriptions = this.subscriptions.concat(
      this._globalService.innerWidthChanged.subscribe((width: number) => {
        this.setWidth(width);
      })
    );
  }

  setWidth(innerWidth: number) {
    if (innerWidth <= PHONE_SIZE) {
      this.phoneSize = true;
    } else {
      this.phoneSize = false;
    }

    if (innerWidth >= DESKTOP_SIZE) {
      this.desktop = true;
    } else {
      this.desktop = false;
    }
  }

  getData() {
    this.subscriptions = this.subscriptions.concat(
      this._configurationService.getHouseAndLandData()
        .subscribe({
          next: () => {
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }})
    );
  }

  refreshJobNumber(jobNo: string) {
    this.jobNum = jobNo;
  }

  addJob() {
    // set job to 0 to tell details that we want to add
    this.jobNum = '-1';
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onRefresh(updateResult: boolean) {
    this.updateSuccessful = updateResult;
    if (updateResult) {
      this.notiService.showSuccess('Update successful');
    } else {
      this.notiService.showWarning('Update NOT succesful');
    }
  }

  addMaintenanceJob() {
    // use modal to set the users to the roles
    const modalRef = this.modalService.open(AddMaintenanceJobComponent, { windowClass: 'modal-1000', backdrop: 'static', keyboard: false });

    modalRef.result.then(() => {
      this.addingJob = true;
      setTimeout(() => {
        this.addingJob = false;
      }, 200);
    }, () => {
    });
  }
}
