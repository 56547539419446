
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { JobItemLink } from '../../dtos/job-item-link';
import { GlobalService } from '../global.service';
import { PDFReports } from '../../dtos/pdf-report';
import { HttpService } from '../http.service';

@Injectable()
export class JobItemLinkService {

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  getJobItemLinks(id): Observable<JobItemLink[]> {
    return this._http.get<JobItemLink[]>(this.globalService.getApiUrl() + '/jobitem/' + id + '/links',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addJobItemLink(itm: any) {
    const url = this.globalService.getApiUrl() + '/jobitem/' + itm.id + '/links';
    return this._http.post(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  updateJobItemLink(itm: any) {
    const url = this.globalService.getApiUrl() + '/jobitem-links/' + itm.id;
    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteJobItemLink(itmId: number) {
    const url = this.globalService.getApiUrl() + '/jobitem-links/' + itmId;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  getJobItemLinksReport(): Observable<PDFReports> {
    const url = this.globalService.getApiUrl() + '/jobitem-links/' + this.globalService.getCurrentJob() + '/pdfreport';
    return this._http.get<PDFReports>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
