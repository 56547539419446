import { AuthService } from './../../../services/auth.service';
import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';
import { EstimatingService } from '../../../services/felixApi/estimating.service';
import { RecipeTypeEnum } from '../../../dtos/recipe-type.enum';
import { NotificationService } from '../../../services/notification.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { JobService } from '../../../services/felixApi/job.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { GlobalService } from '../../../services/global.service';
import mathString from 'math-string';
import { Recipe } from '../../../dtos/recipe';
import { ConfigurationEnum } from '../../../dtos/configuration-enum';
import ArrayStore from 'devextreme/data/array_store';
import { District } from '../../../dtos/district';
import { formatDate } from 'devextreme/localization';
import { UtilsService } from '../../../services/utils.service';
import { UserService } from '../../../services/felixApi/user.service';
import { UnitOfMeasure } from '../../../dtos/unitOfMeasure';

@Component({
  selector: 'js-job-estimating-modal',
  templateUrl: './job-estimating-modal.component.html',
  styleUrls: ['./job-estimating-modal.component.scss']
})
export class JobEstimatingModalComponent implements OnInit, OnDestroy {
  @Input() fromFuelGauge: boolean;

  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;

  feesSectionExpanded = true;
  dataSource: CustomStore;
  recipeTypeEnum = RecipeTypeEnum;
  loading = true;
  refreshMode: string;
  subscriptions: Subscription[] = [];
  itemTotalExGST = 0;
  itemTotalPreFees = 0;
  itemTotal = 0;
  recipeData: CustomStore;
  catalogueData: CustomStore;
  dropDownOptions: object;
  optionsForm: UntypedFormGroup;
  popupVisible = false;
  allowUpdate = false;
  updatedData: any;
  enterKeyActions: Array<string>;
  enterKeyDirections: Array<string>;
  editOnkeyPress: boolean;
  enterKeyAction: String;
  enterKeyDirection: String;
  innerHeight: number;
  gridHeight: number;
  itemToExplode: any;
  selectedRecipes: string[] = [];
  recipeIds: number[] = [];
  recipe: Recipe[];
  isTBA: boolean;
  estimatingPreContractFeesMargin = false;
  configurationEnum = ConfigurationEnum;
  gstPercent: number;
  estimatingCostingDate: Date; // get today's date as the default date to get rates
  districts: District[];
  setBook: string;
  priceFileBookId: number;
  districtsDataSource: ArrayStore;
  setPriceFilePopupVisible: boolean;
  isDropDownBoxOpened = false;
  marginSet: boolean;
  estimatingAdmin: boolean;
  isRevalueItems = false;
  newPriceFileBookId: number;
  newEstimatingCostingDate: Date;
  isJobSoldOrFromFuelGauge: boolean;
  updateMarginsPopupVisible = false;
  deleteAllPopupVisible = false;
  deleteAllConfirm = false;
  unitOfMeasures: UnitOfMeasure[];
  estimatingRecipesUseRecipeMarkup: boolean;

  constructor(private _activeModal: NgbActiveModal,
    private _fb: UntypedFormBuilder,
    private globalService: GlobalService,
    private _jobService: JobService,
    private notiService: NotificationService,
    private jobService: JobService,
    private _userService: UserService,
    private _authService: AuthService,
    private estimatingService: EstimatingService,
    private utilsService: UtilsService) {

    this.refreshMode = 'reshape'; // ['full', 'reshape', 'repaint']
    this.enterKeyActions = this.estimatingService.getEnterKeyActions();
    this.enterKeyDirections = this.estimatingService.getEnterKeyDirections();
    this.editOnkeyPress = true;
    this.enterKeyAction = 'moveFocus';
    this.enterKeyDirection = 'column';

    this.calculateTotal = this.calculateTotal.bind(this);
    this.calculateLineTotal = this.calculateLineTotal.bind(this);
    this.calculateQty = this.calculateQty.bind(this);
    this.setQuantityStringCellValue = this.setQuantityStringCellValue.bind(this);
    this.setMarginCellValue = this.setMarginCellValue.bind(this);
    this.setRateCellValue = this.setRateCellValue.bind(this);
    this.setRecipeCellValue = this.setRecipeCellValue.bind(this);
    this.calculateCostTotal = this.calculateCostTotal.bind(this);
    this.setUnitOfMeasureCellValue = this.setUnitOfMeasureCellValue.bind(this);
  }

  ngOnInit() {
    // check if we can update
    if (this._jobService.currentJob.isActive && !this._jobService.currentJob.isLocked) {
      this.allowUpdate = true;
    }

    this.isJobSoldOrFromFuelGauge = this.fromFuelGauge || this.jobService.currentJob.salesDate !== null;

    if (this._authService.getSelectionsPermissions('Estimating') === 'Admin' || this._authService.isAdminOrSuperUser()) {
      this.estimatingAdmin = true;
    }

    // get markup flag
    this.estimatingRecipesUseRecipeMarkup = this.globalService.getCompanyConfigValue(this.configurationEnum.EstimatingAddRecipeUsesRecipeMarkup) === 1;

    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerHeightChanged.subscribe(height => {
        setTimeout(() => {
          this.setHeight();
        }, 500); // wait for iPhone
      })
    );

    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerWidthChanged.subscribe(width => {
        setTimeout(() => {
          this.setHeight();
        }, 500); // wait for iPhone
      })
    );
    this.setHeight();
    this.getEstimatingData(true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setUpDataSet() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.estimatingService.getJobEstimatingItems(this._jobService.currentJob.id).subscribe({
            next: (res) => {
              res.forEach(item => {
                this.setUpGroupDescs(item);
              });
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        if (values.recipeCode) {
          values.recipeCode = values.recipeCode.toUpperCase();
        }
        values.jobId = this._jobService.currentJob.id;
        return new Promise((resolve, reject) =>
          this.estimatingService.addJobEstimatingItem(values).subscribe({
            next: (res) => {
              // get the group headings
              this.setUpGroupDescs(res);
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        if (values.recipeCode) {
          values.recipeCode = values.recipeCode.toUpperCase();
        }
        return new Promise((resolve, reject) =>
          this.estimatingService.updateJobEstimatingItem(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              this.setUpGroupDescs(res);
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.estimatingService.deleteJobEstimatingItem(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  setUpGroupDescs(item) {
    if (item.recipeCode) {
      const foundItem = this.estimatingService.recipesAndItems.find(i => i.recipeCode === item.recipeCode);
      if (foundItem?.priceFileItemId) {
        const priceFileItem = this.estimatingService.allPriceFileItems.find(i => i.id === foundItem.priceFileItemId);
        const subGroup = this.estimatingService.costCentresAndSubGroups.find(i => i.id === priceFileItem?.priceFileItemParentId);
        if (subGroup) {
          const masterGroup = this.estimatingService.costCentresAndSubGroups.find(i => i.id === subGroup?.priceFileItemParentId);
          const masterGroupDesc = masterGroup.priceFileCode
            ? masterGroup.priceFileCode + ' - ' + masterGroup.description : masterGroup.description;

          const subGroupDesc = subGroup.priceFileCode
            ? subGroup.priceFileCode + ' - ' + subGroup.description : subGroup.description;

          item.masterGroupCostCentre
            = ('00000' + masterGroup.orderNumber.toString()).slice(-6) + ';' + masterGroupDesc;
          item.subGroupItemDesc
            = ('00000' + subGroup.orderNumber.toString()).slice(-6) + ';' + subGroupDesc;
        } else {
          item.masterGroupCostCentre = '00000;Cost Centre Not Found';
          item.subGroupItemDesc = '00000;Sub-Group Not Found';
        }
      } else {
        item.masterGroupCostCentre = ' ;RECIPES';
        const recipe = this.estimatingService.allRecipes.find(i => i.recipeCode === item.recipeCode);
        if (recipe) {
          const subGroup = this.estimatingService.recipeGroups.find(i => i.id === recipe.recipeParentId);
          if (subGroup) {
            item.subGroupItemDesc = '00000;' + subGroup.description;
          } else {
            item.subGroupItemDesc = '00000;Class NOT Found';
          }
        } else {
          item.subGroupItemDesc = '00000;Class NOT Found';
        }
      }
    } else {
      item.masterGroupCostCentre = '100000;Ad-Hoc';
      item.subGroupItemDesc = '000000;Items';
    }
  }

  subscribeToWidthChanges() {
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerHeightChanged.subscribe(height => {
        this.setHeight();
      })
    );
  }

  setHeight() {
    this.gridHeight = this.globalService.innerHeight - 310;

    if (this.feesSectionExpanded) {
      this.gridHeight -= 76;
    }

    this.dropDownOptions = { width: 800, height: this.gridHeight < 800 ? 500 : this.gridHeight - 300 };
  }

  getEstimatingData(useCache: boolean) {
    this.subscriptions = this.subscriptions.concat(
      this.estimatingService.getEstimatingData(useCache).subscribe({
        next: () => {
          this.districts = this.estimatingService.districts;
          this.unitOfMeasures = this.estimatingService.unitOfMeasures;
          this.districtsDataSource = new ArrayStore({
            data: this.districts,
            key: 'id'
          });

          this.getJobExtra();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  getJobExtra() {
    this.subscriptions = this.subscriptions.concat(
      this.jobService.getJobExtras(this.jobService.currentJob.id)
        .subscribe({
          next: () => {
            this.getDistricts();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  getDistricts() {
    // we can have a division set default to add weeks to today every time - this speeds it up as the cache will be used for the recipes etc.
    const division = this._userService.divisions.find(i => i.id === this.jobService.currentJob.divisionId);

    if (division?.weeksToAddToPricingDateFromToday) {
      // get today plus 3 months
      this.estimatingCostingDate = new Date();
      this.estimatingCostingDate.setDate(this.estimatingCostingDate.getDate() + (division.weeksToAddToPricingDateFromToday * 7));
    } else if (!this.jobService.currentJobExtra?.estimatingCostingDate) {
      // use todays date and add the default weeks
      const division = this._userService.divisions.find(i => i.id === this._jobService.currentJob.divisionId);

      if (this.jobService.currentJob.salesDate) {
        this.estimatingCostingDate = new Date();
      } else {
        this.estimatingCostingDate = new Date();
        if (division?.weeksToAddToPricingDateForSales) {
          this.estimatingCostingDate = this.utilsService.addDays(this.estimatingCostingDate, division.weeksToAddToPricingDateForSales * 7);
        }
      }
    } else {
      // use JSON to get the date
      if (this.jobService.currentJobExtra.estimatingCostingDate instanceof Date) {
        this.estimatingCostingDate = this.jobService.currentJobExtra.estimatingCostingDate;
      } else {
        const estDate = JSON.stringify(this.jobService.currentJobExtra.estimatingCostingDate).substr(1, 10);
        this.estimatingCostingDate = new Date(+estDate.substr(0, 4), +estDate.substr(5, 2) - 1, +estDate.toString().substr(8, 2));
      }
    }

    if (this.jobService.currentJobExtra) {
      const district = this.districts.find(i => i.id === this.jobService.currentJobExtra.districtId);
      if (district) {
        this.setBook = district.description;
        this.priceFileBookId = this.jobService.currentJobExtra.districtId;
      } else {
        // get the default
        const division = this._userService.divisions.find(i => i.isActive && i.id === this.jobService.currentJob.divisionId);
        const districtId = division?.defaultDistrictId ?? this.districts.find(i => i.isActive && !i.districtParentId).id;

        const districtMaster = this.districts.find(i => i.id === districtId);
        if (districtMaster) {
          this.setBook = districtMaster.description;
          this.priceFileBookId = districtMaster.id;

          // update the jobExtra record
          const updateRecord = {
            jobId: this.jobService.currentJob.id,
            districtId: this.priceFileBookId,
            estimatingCostingDate: this.estimatingCostingDate ? formatDate(this.estimatingCostingDate, 'yyyy-MM-dd') : null
          };

          this.subscriptions = this.subscriptions.concat(
            this.jobService.updateJobExtra(this.jobService.currentJobExtra.id,
              { districtId: this.priceFileBookId }
            ).subscribe({
              next: (jobExtra) => {
                this.jobService.currentJobExtra = jobExtra;
              },
              error: (err) => {
                this.notiService.notify(err);
              }
            })
          );
        }
      }
    } else {
      // get the default
      const division = this._userService.divisions.find(i => i.isActive && i.id === this.jobService.currentJob.divisionId);
      const districtId = division?.defaultDistrictId ?? this.districts.find(i => i.isActive && !i.districtParentId).id;

      const districtMaster = this.districts.find(i => i.id === districtId);
      if (districtMaster) {
        this.setBook = districtMaster.description;
        this.priceFileBookId = districtMaster.id;

        // add the jobExtra record
        const updateRecord = {
          jobId: this.jobService.currentJob.id,
          districtId: this.priceFileBookId,
          estimatingCostingDate: this.estimatingCostingDate ? formatDate(this.estimatingCostingDate, 'yyyy-MM-dd') : null
        };

        this.subscriptions = this.subscriptions.concat(
          this.jobService.addJobExtra(updateRecord).subscribe({
            next: (jobExtra) => {
              this.jobService.currentJobExtra = jobExtra;
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
        );
      }
    }

    this.setupForm();
  }

  setupForm() {
    let gstRateToUse = this.jobService.currentJob.isGSTFree ? 0 : this.estimatingService.gstRate;
    if (this._jobService.currentJob.gstRate) {
      gstRateToUse = this._jobService.currentJob.gstRate;
    }

    this.optionsForm = this._fb.group({
      // catalogueNumber: [1, Validators.required],
      // districtId: [0, Validators.required],
      jobMargin: this.jobService.currentJob.jobMargin,
      feesMargin: this.jobService.currentJob.feesMargin,
      gstRate: gstRateToUse,
      isGSTFree: this.jobService.currentJob.isGSTFree
    });

    this.marginSet = this.optionsForm.get('jobMargin').value ? true : false;

    this.setUpRecipeData(false);
    this.loading = false;
    this.setUpDataSet();
  }

  setUpRecipeData(useCache: boolean) {
    if (!useCache || !this.estimatingService.estimatingCostingDateString || this.estimatingService.estimatingCostingDateString !== this.estimatingCostingDate.toDateString()
      || !this.estimatingService.priceFileBookId || this.estimatingService.priceFileBookId !== this.priceFileBookId) {
      this.estimatingService.estimatingCostingDateString = this.estimatingCostingDate.toDateString();
      this.estimatingService.priceFileBookId = this.priceFileBookId;

      const recipes = this.estimatingService.getRecipesForDateAndDistrict(this.estimatingCostingDate, this.priceFileBookId);
      const priceFileItems = this.estimatingService.getItemsForDateAndDistrict(this.estimatingCostingDate, this.priceFileBookId, useCache)

      priceFileItems.forEach(item => {
        const newItem = new Recipe();
        newItem.recipeCode = item.priceFileCode;
        newItem.description = item.description;
        newItem.rate = item.rate;
        newItem.unitOfMeasure = this.estimatingService.unitOfMeasures?.find(i => i.id === item.unitOfMeasureId)?.description;

        if (item.expiryDate) {
          const effectiveDateString = this.estimatingCostingDate.getFullYear() + '-'
            + ('0' + (this.estimatingCostingDate.getMonth() + 1)).toString().slice(-2) + '-'
            + ('0' + this.estimatingCostingDate.getDate()).slice(-2);

          if (item.expiryDate.toString().slice(0, 10) < effectiveDateString) {
            newItem.rate = null;
          }
        }
        newItem.expiryDate = item.expiryDate;

        newItem.priceFileItemId = item.priceFileItemId;
        newItem.masterGroupCostCentre
          = 'I' + ('00000' + item.groupOrderNumber.toString()).slice(-6) + ';' + item.masterGroupCostCentre;
        newItem.subGroupItemDesc = ('00000' + item.subGroupOrderNumber.toString()).slice(-6) + ';' + item.subGroupItemDesc;

        recipes.push(newItem);
      });

      this.estimatingService.recipesAndItems = recipes;
    }

    this.recipeData = new CustomStore({
      key: 'recipeCode',
      loadMode: 'raw',
      load: () => this.estimatingService.recipesAndItems
    });
    this.loading = false;
  }

  closeModal(ans: boolean) {
    if (this.dataGrid?.instance?.hasEditData() || isNaN(this.itemTotal)) {
      this.notiService.showInfo('Please Save or Cancel the edited data (or saving is in progress)');
    } else {
      if (ans) {
        let update = false;
        if (this.optionsForm.get('jobMargin').value !== this.jobService.currentJob.jobMargin
          || this.optionsForm.get('isGSTFree').value !== this.jobService.currentJob.isGSTFree
          || this.optionsForm.get('feesMargin').value !== this.jobService.currentJob.feesMargin
          || this.optionsForm.get('gstRate').value !== this.jobService.currentJob.gstRate
          || (!this.jobService.currentJob.salesDate && this.jobService.currentJob.basePrice !== this.itemTotal)) {
          update = true;
        }

        if (update) {
          // update the job
          this.loading = true;
          this.updatedData = {
            jobMargin: this.optionsForm.get('jobMargin').value,
            feesMargin: this.optionsForm.get('feesMargin').value,
            isGSTFree: this.optionsForm.get('isGSTFree').value
          };

          if (!this.jobService.currentJob.salesDate && this.jobService.currentJob.basePrice !== this.itemTotal) {
            this.updatedData.basePrice = this.itemTotal;
          }

          this.subscriptions = this.subscriptions.concat(
            this.jobService.updateJob(this.updatedData).subscribe({
              next: (res) => {
                this.jobService.currentJob.jobMargin = this.optionsForm.get('jobMargin').value;
                this.jobService.currentJob.feesMargin = this.optionsForm.get('feesMargin').value;
                this.jobService.currentJob.isGSTFree = this.optionsForm.get('isGSTFree').value;

                if (!this.jobService.currentJob.salesDate && this.jobService.currentJob.basePrice !== this.itemTotal) {
                  this.jobService.currentJob.basePrice = this.itemTotal;
                }

                this.close(ans);
              },
              error: (err) => {
                this.notiService.notify(err);
                this.loading = false;
              }
            })
          );
        } else {
          this.close(ans);
        }
      } else {
        this.close(ans);
      }
    }
  }

  close(ans: boolean) {
    if (ans) {
      if (this._jobService.currentJob.salesDate) {
        this._activeModal.close(this._jobService.currentJob.basePrice);
      } else if (this.isTBA) {
        this._activeModal.close(null);
      } else {
        this._activeModal.close(this.itemTotal);
      }
    } else {
      this._activeModal.dismiss();
    }
  }

  onFocusedCellChanging(e) {
    e.isHighlighted = true;
  }

  calculateQty(rowData): number {
    if (rowData) {
      rowData.quantity = 0;
      if (rowData.quantityString) {
        if (rowData.quantityString[0] === '=') {
          rowData.quantityString = rowData.quantityString.slice(1);
        }
        try {
          rowData.quantity = Math.round((+(mathString(rowData.quantityString)) + Number.EPSILON) * 10000) / 10000;
        } catch (err) {
          rowData.quantity = null;
        }
      }

      rowData.lineTotal = this.calculateLineTotal(rowData);
      return rowData.quantity;
    } else {
      return 0;
    }
  }

  calculateLineTotal(rowData): number {
    if (rowData) {
      let qty = !rowData.quantity || isNaN(rowData.quantity) ? 0 : rowData.quantity;
      const rate = !rowData.rate || isNaN(rowData.rate) ? 0 : rowData.rate;
      const margin = !rowData.margin || isNaN(rowData.margin) ? 1 : 1 + (rowData.margin / 100);

      if (rowData.recipeId) {
        const lineRecipe = this.estimatingService.allRecipes.find(i => i.id === rowData.recipeId);
        if (lineRecipe && lineRecipe.unitOfMeasure) {
          const unitOfMeasure = this.estimatingService.unitOfMeasures.find(i => i.description === lineRecipe.unitOfMeasure);
          if (unitOfMeasure && unitOfMeasure.costIsPer) {
            qty /= unitOfMeasure.costIsPer;
          }
        }
      } else {
        const unitOfMeasure = this.estimatingService.unitOfMeasures.find(i => i.description === rowData.unitOfMeasure);
        if (unitOfMeasure && unitOfMeasure.costIsPer) {
          qty /= unitOfMeasure.costIsPer;
        }
      }
      return this.utilsService.roundEven(qty * rate * margin);
    } else {
      return 0;
    }
  }

  setUnitOfMeasureCellValue(rowData, value, originalData) {
    if (rowData.unitOfMeasure !== value) {
      rowData.unitOfMeasure = value;
      rowData.rate = rowData.rate ? rowData.rate : originalData.rate;
      rowData.quantity = rowData.quantity ? rowData.quantity : originalData.quantity;
      rowData.margin = rowData.margin ? rowData.margin : originalData.margin;
      rowData.quantityString = rowData.quantityString ? rowData.quantityString : originalData.quantityString;
      rowData.recipeCode = rowData.recipeCode ? rowData.recipeCode : originalData.recipeCode;
      this.calculateQty(rowData);
    }
  }

  setQuantityStringCellValue(rowData, value, originalData) {
    rowData.quantityString = value;
    rowData.rate = rowData.rate ? rowData.rate : originalData.rate;
    rowData.margin = rowData.margin ? rowData.margin : originalData.margin;
    rowData.recipeCode = rowData.recipeCode ? rowData.recipeCode : originalData.recipeCode;
    rowData.unitOfMeasure = rowData.unitOfMeasure ? rowData.unitOfMeasure : originalData.unitOfMeasure;
    this.calculateQty(rowData);
  }

  setRateCellValue(rowData, value, originalData) {
    rowData.rate = value;
    rowData.quantity = rowData.quantity ? rowData.quantity : originalData.quantity;
    rowData.margin = rowData.margin ? rowData.margin : originalData.margin;
    rowData.quantityString = rowData.quantityString ? rowData.quantityString : originalData.quantityString;
    rowData.recipeCode = rowData.recipeCode ? rowData.recipeCode : originalData.recipeCode;
    rowData.unitOfMeasure = rowData.unitOfMeasure ? rowData.unitOfMeasure : originalData.unitOfMeasure;
    this.calculateQty(rowData);
  }

  setMarginCellValue(rowData, value, originalData) {
    rowData.margin = value;
    rowData.quantity = rowData.quantity ? rowData.quantity : originalData.quantity;
    rowData.rate = rowData.rate ? rowData.rate : originalData.rate;
    rowData.quantityString = rowData.quantityString ? rowData.quantityString : originalData.quantityString;
    rowData.recipeCode = rowData.recipeCode ? rowData.recipeCode : originalData.recipeCode;
    rowData.unitOfMeasure = rowData.unitOfMeasure ? rowData.unitOfMeasure : originalData.unitOfMeasure;
    this.calculateQty(rowData);
  }

  calculateTotal = (options) => {
    if (options.name === 'gridTotal') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
        this.isTBA = false;
        this.itemTotalExGST = 0;
        this.itemTotalPreFees = 0;
        this.itemTotal = 0;

      } else if (options.summaryProcess === 'calculate') {
        options.totalValue += options.value.lineTotal ?? 0;

        this.isTBA = options.value.isTBA ? true : this.isTBA;
        this.itemTotalExGST = options.totalValue;

      } else if (options.summaryProcess === 'finalize') {
        if (!this.isTBA) {
          this.gstPercent = (this.optionsForm.get('gstRate').value === null ? 0 : this.optionsForm.get('gstRate').value) / 100;
          this.itemTotalPreFees =
            this.utilsService.roundEven(this.itemTotalExGST === null ? 0 : this.itemTotalExGST * (1 + this.gstPercent));

          this.itemTotal = this.itemTotalPreFees;

          if (this.estimatingPreContractFeesMargin && this.optionsForm.get('feesMargin').value) {
            this.itemTotal +=
              this.utilsService.roundEven(this.itemTotalPreFees * (this.optionsForm.get('feesMargin').value / 100) * (1 + this.gstPercent));
          }
        }
      }
    }
  }

  onGSTChange() {
    if (this.allowUpdate) {
      this.dataGrid?.instance?.refresh();
    }
  }

  onSelectionChanged(cellInfo, dropDownBox, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0].recipeCode);
      dropDownBox.close();
    }
  }

  setRecipeCellValue(rowData, value, originalData) {
    if (value) {
      const recipeItem = this.estimatingService.recipesAndItems.find(i => i.recipeCode === value);
      rowData.recipeCode = value;
      rowData.description = recipeItem.description;
      rowData.unitOfMeasure = recipeItem.unitOfMeasure;
      rowData.priceFileItemId = recipeItem.priceFileItemId;
      if (recipeItem.rate) {
        if (recipeItem.scale) {
          rowData.rate = recipeItem.rate / recipeItem.scale;
        } else {
          rowData.rate = recipeItem.rate;
        }
      } else {
        rowData.rate = recipeItem.rate === null ? null : 0;
      }
      rowData.quantityString = rowData.quantityString ? rowData.quantityString : originalData.quantityString;
      rowData.margin = rowData.margin ? rowData.margin : originalData.margin;

      if (this.estimatingRecipesUseRecipeMarkup) {
        const recipe = this.estimatingService.allRecipes.find(i => i.recipeCode === value);
        if (recipe?.margin) {
          rowData.margin = recipe.margin;
        }
      }
      this.calculateQty(rowData);
    }
  }

  initNewRow = (e) => {
    e.data.margin = this.optionsForm.get('jobMargin').value;
  }

  isExplodeIconVisible = (e: any): boolean => {
    let result = false;
    if (this.allowUpdate) {
      const foundRecipe = this.estimatingService.allRecipes.find(i => i.recipeCode === e.data.recipeCode);

      if (foundRecipe) {
        const foundLines = this.estimatingService.allRecipeLines.find(i => i.recipeId === foundRecipe.id);

        if (foundLines) {
          result = true;
        }
      }
    }
    return result;
  }

  explode = () => {
    this.loading = true;
    this.popupVisible = false;

    this.subscriptions = this.subscriptions.concat(
      this.estimatingService.explodeJobEstimatingItem(this.itemToExplode)
        .subscribe({
          next: () => {
            this.dataSource = null;
            this.setUpDataSet();
            this.itemTotalExGST = 0;
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  explodeAsk = (e) => {
    if (this.dataGrid.instance.hasEditData()) {
      this.notiService.showInfo('Please save edited data before exploding');
    } if (!e.row.data.quantity) {
      this.notiService.showWarning('Item needs a quantity to be exploded');
    } else {
      this.popupVisible = true;
      this.itemToExplode = e.row.data.id;
    }
  }

  setMarginCheck() {
    if (this.allowUpdate && this.jobService.currentJob.jobMargin
      && this.optionsForm.get('jobMargin').value
      && this.jobService.currentJob.jobMargin !== this.optionsForm.get('jobMargin').value) {
      if (this.dataGrid.instance.hasEditData()) {
        this.notiService.showWarning('Please save/cancel edited data before updating the mark-up');
        this.optionsForm.patchValue({
          jobMargin: this.jobService.currentJob.jobMargin
        });
      } else {
        this.updateMarginsPopupVisible = true;
      }
    } else {
      this.setMarginInJob(false);
    }
  }

  setMarginInJob(updateItems: boolean) {
    this.updateMarginsPopupVisible = false;

    if (this.jobService.currentJob.jobMargin !== this.optionsForm.get('jobMargin').value) {

      this.updatedData = {};
      this.updatedData.jobMargin = this.optionsForm.get('jobMargin').value;

      this.subscriptions = this.subscriptions.concat(
        this.jobService.updateJob(this.updatedData).subscribe({
          next: () => {
            if (updateItems) {
              this.updateMargins();
            } else {
              this.jobService.currentJob.jobMargin = this.optionsForm.get('jobMargin').value;
              this.notiService.showInfo('Margin set');
            }
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );

      this.marginSet = this.optionsForm.get('jobMargin').value ? true : false;
    }
  }

  updateMargins() {
    this.loading = true;
    this.subscriptions = this.subscriptions.concat(
      this.estimatingService.updateMarginsForJobEstimatingItems(this.jobService.currentJobExtra.jobId,
        this.jobService.currentJob.jobMargin, this.optionsForm.get('jobMargin').value).subscribe({
          next: () => {
            this.jobService.currentJob.jobMargin = this.optionsForm.get('jobMargin').value;
            this.refreshRecipes();
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  setFeesMarginInJob() {
    if (this.allowUpdate) {
      if (this.jobService.currentJob.feesMargin !== this.optionsForm.get('feesMargin').value) {

        this.updatedData = {};
        this.updatedData.feesMargin = this.optionsForm.get('feesMargin').value;

        this.subscriptions = this.subscriptions.concat(
          this.jobService.updateJob(this.updatedData).subscribe({
            next: () => {
              this.jobService.currentJob.feesMargin = this.optionsForm.get('feesMargin').value;
              this.notiService.showInfo('Fees Margin set');
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
        );
      }
    }
  }

  onGSTFreeClick() {
    if (this.allowUpdate) {
      const newVal = !this.optionsForm.get('isGSTFree').value;
      if (newVal) {
        this.optionsForm.patchValue({
          gstRate: 0
        });
      } else {
        this.optionsForm.patchValue({
          gstRate: this.estimatingService.gstRate
        });
      }

      this.dataGrid?.instance?.refresh();
    }
  }

  onMultiRowClick(e) {
    if (e.groupIndex === undefined) {
      let keys = e.component.getSelectedRowKeys();
      const index = keys.indexOf(e.key);

      if (index > -1) {
        keys.splice(index, 1);
      } else {
        keys = keys.concat(e.key);
      }

      e.component.selectRows(keys);
    }
  }

  addRecipesFromList() {
    // add the recipes selected
    if (this.dataGrid.instance.hasEditData()) {
      this.notiService.showInfo('Please Save the edited data before adding multiple items');
    } else {
      this.loading = true;
      this.isDropDownBoxOpened = false;
      this.updatedData = { recipeCodes: this.selectedRecipes };

      this.subscriptions = this.subscriptions.concat(
        this.estimatingService.addJobEstimatingItemsFromSelectedRecipes(this._jobService.currentJob.id, this.updatedData).subscribe({
          next: (res) => {
            this.dataSource = null;
            this.setUpDataSet();
            this.selectedRecipes = [];
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
      );
    }
  }

  onRowPrepared(e) {
    // we test to colour the row
    if (e.rowType === 'data') {
      if (e.data.isTBA) {
        e.rowElement.classList.add('yellowBackground');
      }
    }
  }

  selectPriceFile() {
    // set to a different book or date
    if (this.dataGrid?.instance?.hasEditData()) {
      this.notiService.showInfo('Please Save the edited data before changing details');
    } else {
      this.newPriceFileBookId = this.priceFileBookId;
      this.newEstimatingCostingDate = this.estimatingCostingDate;
      this.isRevalueItems = true;
      this.setPriceFilePopupVisible = true;
    }
  }

  updateJobExtra() {
    this.setPriceFilePopupVisible = false;

    if (this.newPriceFileBookId !== this.priceFileBookId || this.newEstimatingCostingDate !== this.estimatingCostingDate) {
      this.priceFileBookId = this.newPriceFileBookId;
      this.setBook = this.districts.find(i => i.id === this.priceFileBookId).description;
      this.loading = true;

      const updateRecord = {
        jobId: this.jobService.currentJob.id,
        districtId: this.priceFileBookId,
        estimatingCostingDate: formatDate(this.newEstimatingCostingDate, 'yyyy-MM-dd')
      };

      if (this.jobService.currentJobExtra) {
        this.subscriptions = this.subscriptions.concat(
          this.jobService.updateJobExtra(this.jobService.currentJobExtra.id, updateRecord, this.isRevalueItems).subscribe({
            next: (res) => {
              this.jobService.currentJobExtra = res;
              this.revalueJobItems();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
        );
      } else {
        // create it
        this.subscriptions = this.subscriptions.concat(
          this.jobService.addJobExtra(updateRecord).subscribe({
            next: (res) => {
              this.jobService.currentJobExtra = res;
              this.revalueJobItems();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
        );
      }
    } else {
      this.revalueJobItems();
    }
  }

  revalueJobItems() {
    if (this.isRevalueItems) {
      this.loading = true;
      this.subscriptions = this.subscriptions.concat(
        this.estimatingService.revalueJobEstimatingItems(this.jobService.currentJobExtra.jobId).subscribe({
          next: () => {
            this.estimatingCostingDate = this.newEstimatingCostingDate;
            this.getJobExtra();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
      );
    } else {
      this.estimatingCostingDate = this.newEstimatingCostingDate;
      this.getJobExtra();
    }
  }

  refreshRecipes() {
    if (this.dataGrid?.instance.hasEditData()) {
      this.notiService.showInfo('Please Save the edited data before refreshing');
    } else {
      this.loading = true;
      this.getEstimatingData(false);
    }
  }

  onCellPrepared(e) {
    if (e.rowType === 'data' && e.column.dataField === 'rate' && e.data.rate === null && e.data.recipeCode && e.data.recipeCode !== '') {
      e.cellElement.style.color = 'red';
    }
    if (e.rowType === 'data' && e.column.dataField === 'quantityString' && e.data.quantityString) {
      try {
        const calcCheck = mathString(e.data.quantityString);
        if (isNaN(+calcCheck)) {
          e.cellElement.classList.add('redWhite');
        }
      } catch (err) {
        e.cellElement.classList.add('redWhite');
      }
    }
    if (e.rowType === 'data' && e.column.dataField === 'rate') {
      if (!e.data.rate && e.data.quantityString) {
        e.cellElement.classList.add('pinkBackground');
      } else if (e.data.recipeCode && e.data.recipeCode !== '') {
        // check the rate is the same as the price file
        const matchingItem = this.estimatingService.recipesAndItems.find(i => i.recipeCode === e.data.recipeCode);
        if (matchingItem && matchingItem.rate && e.data.rate) {
          const matchingRate = Math.round(matchingItem.rate * 100) / 100;
          const currentRate = Math.round(e.data.rate * 100) / 100;
    
          // Use Math.min to choose between $1 and 1% of the matching rate for tolerance
          const tolerance = Math.min(1, matchingRate * 0.01);
    
          // Check if the absolute rate difference exceeds the smaller of $1 or 1%
          if (Math.abs(matchingRate - currentRate) > tolerance) {
            e.cellElement.classList.add('pinkBackground');
          }
        }
      }
    }
    
  }

  getGroupTitle(cellInfo) {
    return cellInfo.data.key.split(';')[1];
  }

  getRecipeGroupTitle(cellInfo) {
    return cellInfo.data.key.split(';')[1];
  }

  unlock() {
    this.allowUpdate = true;
  }

  getRate(recipeLine: any) {
    if (recipeLine.recipeCode && recipeLine.recipeCode !== '' && recipeLine.rate === null) {
      return 'Invalid';
    }
    return recipeLine.rate;
  }

  calculateCostTotal(rowData: any) {
    // return data.rate * data.quantity;
    let qty = !rowData.quantity || isNaN(rowData.quantity) ? 0 : rowData.quantity;
    const rate = !rowData.rate || isNaN(rowData.rate) ? 0 : rowData.rate;

    if (rowData.recipeId) {
      const lineRecipe = this.estimatingService.allRecipes.find(i => i.id === rowData.recipeId);
      if (lineRecipe && lineRecipe.unitOfMeasure) {
        const unitOfMeasure = this.estimatingService.unitOfMeasures.find(i => i.description === lineRecipe.unitOfMeasure);
        if (unitOfMeasure && unitOfMeasure.costIsPer) {
          qty /= unitOfMeasure.costIsPer;
        }
      }
    } else {
      const unitOfMeasure = this.estimatingService.unitOfMeasures.find(i => i.description === rowData.unitOfMeasure);
      if (unitOfMeasure && unitOfMeasure.costIsPer) {
        qty /= unitOfMeasure.costIsPer;
      }
    }
    return this.utilsService.roundEven(qty * rate);
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'expand',
          onClick: this.expandAll.bind(this),
          matTooltip: 'Collapse All Rows'
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'collapse',
          onClick: this.collapseAll.bind(this),
          matTooltip: 'Collapse All Rows'
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Delete All',
          onClick: this.deleteAll.bind(this)
        }
      });
  }

  collapseAll() {
    this.dataGrid.instance.collapseAll();
  }

  expandAll() {
    this.dataGrid.instance.expandAll();
  }

  deleteAll() {
    // delete all lines
    this.deleteAllConfirm = false;
    this.deleteAllPopupVisible = true;
  }

  deleteAllItems(confirmed: boolean) {
    if (confirmed) {
      this.subscriptions = this.subscriptions.concat(
        this.estimatingService.deleteAllJobEstimatingItems(this._jobService.currentJob.id).subscribe({
          next: () => {
            this.deleteAllPopupVisible = false;
            this.setUpDataSet();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.deleteAllPopupVisible = false;
          }
        })
      );
    } else {
      this.deleteAllConfirm = true;
    }
  }

  onEditorPreparing(e: any) {
    if (e.parentType !== 'dataRow') {
      return;
    } else {
      if (e.dataField === 'description') {
        e.editorName = 'dxTextArea';
        e.editorOptions.autoResizeEnabled = true;
        let prevHeight = null;
        e.editorOptions.onInput = (args) => {
          const td = args.element.closest('td');
          if (prevHeight !== td.offsetHeight) {
            const overlay = e.element.querySelector('.dx-datagrid-focus-overlay');
            if (overlay != null) {
              overlay.style.height = (td.offsetHeight + 1) + 'px';
            }
            prevHeight = td.offsetHeight;
          }
        };
      } else if (e.dataField === 'unitOfMeasure' && e.row.data.recipeCode) {
        e.editorOptions.disabled = true;
      }
    }
  }
}
