import { Category } from './category';

export class IOptionListHeader {
    id: number;
    optionListIdAbove: number;
    description: string;
    warningNote: string;
    productCode: string;
    optionColour: number;
    orderNo: number;
    isActive: boolean;
    notSelectable: boolean;
    isBoldText: boolean;
    attachmentId: number;
    optionImageId: number;
    children: IOptionListHeader[];

    defaultDescription: string;
    houseTypeId: number;
    salesPriceIfAdded: number;
    salesPriceTypeIfAddedId: number;
    salesPriceIfChangedInSameList: number;
    salesPriceTypeIfChangedInSameListId: number;
    costToCompanyIfAdded: number;
    costToCompanyIfChangedInSameList: number;
    lastPriceUpdate: Date;
    lastPriceUpdatedByUserId: number;
    isQtyRequired: boolean;
    optionTypeId: number;
    childItemSelectableByClient: boolean;
    printedDescription: string;
    childSelectionWarning: string; // show this warning if we click the dropdown
    hideQuantity: boolean; // if isQtyRequired we may still hide it

    optionListCategories: Category[];

    checked: boolean; // used when selecting in a tree

    constructor(desc: string, inOrderNo: number, id: number) {
        this.description = desc;
        this.orderNo = inOrderNo;
        this.warningNote = null;
        this.productCode = null;
        this.isActive = true;
        this.id = id;
    }
}
