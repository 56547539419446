import { UserService } from './../../services/felixApi/user.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit, Input, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { GlobalService } from '../../services/global.service';
import { IJobDocument } from '../../dtos/job-document';
import { JobDocumentService } from '../../services/felixApi/job-document.service';
import { JobDocumentAttachmentService } from '../../services/felixApi/job-document-attachment.service';
import { IJobDocAttachment } from '../../dtos/job-doc-attachment';
import { AttachmentTypeEnum } from '../../dtos/attachment-type.enum';
import { JobDocumentRecordTypeEnum } from '../../dtos/job-document-record-type.enum';
import { ShowPdfComponent } from '../../shared/show-pdf.component';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { JobDocumentType } from '../../dtos/job-document-type';
import { FileService } from '../../services/file.service';
import { DEFAULT_ERR_MSG } from '../../../config/variables';
import { SelectedJobItems } from '../../dtos/selectedJobItemIds';
import { IJob } from '../../dtos/job';
import { JobService } from '../../services/felixApi/job.service';
import { JobSearchTypeEnum } from '../../dtos/job-search-type.enum';
import { CallUpDocsType } from '../../dtos/call-up-docs-type';
import { UtilsService } from '../../services/utils.service';
import { DocumentVersionsComponent } from './document-versions/document-versions.component';


@Component({
  selector: 'js-document-modal',
  templateUrl: './document-modal.component.html',
  styleUrls: ['./document-modal.component.scss']
})
export class DocumentModalComponent implements OnInit, OnDestroy {

  COMPONENT_NAME = 'document-modal';
  subscriptions: Subscription[] = [];

  @Input() jobDocument: IJobDocument; // set if edit modal
  @Input() parentId: number;
  @Input() orderNo: number;

  public refresh = new EventEmitter<boolean>();

  modalButton: string;
  filesToUpload: File[] = [];
  fileInputId = '123'; // unique ID required for ng2 file input (drag + drop)
  imageFound = false;
  modalImageLoading = false;
  jobDocAttachment: IJobDocAttachment;
  jobDocumentRecordTypeEnum = JobDocumentRecordTypeEnum;
  attachmentTypeEnum = AttachmentTypeEnum;

  jobDocumentStatuses = [
    { id: 1, description: 'Not Started' },
    { id: 2, description: 'Pending' },
    { id: 3, description: 'Outstanding High Priority' },
    { id: 4, description: 'Completed / Yes' },
    { id: 5, description: 'Not Applicable' }
  ];

  modalHeading: string;
  documentForm: UntypedFormGroup;
  documentAddType: number;

  loading = true;
  submitLoading = false;
  updateError = false;
  wrongFileType = false;
  errorMessage = DEFAULT_ERR_MSG;
  updateRequired: boolean;
  deleteConfirmed = false;
  deleteImageConfirmed = false;
  updateImageClicked = false;
  docsLeftToLoad = 0;
  isCustomerViewable = false;
  isTradeViewable = false;
  uploadingMultipleImages = false;
  jobDocumentTypes: JobDocumentType[];
  isStatusRequired = false;
  typeId: number;
  statusId: number;
  blobData;

  // copy
  jobNumber: string;
  jobToCopy: IJob;
  itemsToCopyDto: SelectedJobItems;
  selectedJobDocs: any[] = [];
  selectedJobDocIds: number[] = [];
  loadingDocs: boolean;
  jobDocumentsForCopy: IJobDocument[];
  jobSearchTypeEnum = JobSearchTypeEnum;
  isTrackingActive: boolean;
  callUpDocsTypes: CallUpDocsType[];
  wrongFileTypeErrorMessage: string;
  hasVersions: boolean;
  isInfoAdmin: boolean;
  modifiedUser: string;
  insertMissing = false;
  multiFileLoadDescription = ''; //we can enter the starting part of the file name

  constructor(
    private _jobDocumentService: JobDocumentService,
    private _jobDocAttachmentService: JobDocumentAttachmentService,
    private _jobService: JobService,
    private modalService: NgbModal,
    private notiService: NotificationService,
    private _globalService: GlobalService,
    private fileService: FileService,
    private formBuilder: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    private utils: UtilsService,
    private authService: AuthService,
    private userService: UserService
  ) { }

  ngOnInit() {
    if (this.authService.isAdminOrSuperUser() || this.authService.getSelectionsPermissions('Information') === 'Admin') {
      this.isInfoAdmin = true;
    }

    this.jobDocumentTypes = this._jobDocumentService.jobDocumentTypes; // get types
    this.isTrackingActive = this._globalService.isTrackingActive;
    this.callUpDocsTypes = this._jobDocumentService.callUpDocsTypes;

    if (this.isTrackingActive) {
      this.wrongFileTypeErrorMessage = 'Please select PDF, JPG, PNG, DOC, XLS or DXF only';
    } else {
      this.wrongFileTypeErrorMessage = 'Please select PDF, JPG or PNG only';
    }

    if (this.jobDocument) {
      this.modalHeading = 'Edit entry';
      this.modalButton = 'Update';
      this.documentForm = this.formBuilder.group({
        recordTypeId: this.jobDocument.recordTypeId,
        description: [this.jobDocument.description, Validators.required],
        isCustomerViewable: this.jobDocument.isCustomerViewable,
        isTradeViewable: this.jobDocument.isTradeViewable,
        jobDocumentStatusId: this.jobDocument.jobDocumentStatusId ? this.jobDocument.jobDocumentStatusId : 0,
        jobDocumentTypeId: this.jobDocument.jobDocumentTypeId ? this.jobDocument.jobDocumentTypeId : 0,
        callUpDocsTypeId: this.jobDocument.callUpDocsTypeId ? this.jobDocument.callUpDocsTypeId : 0
      });
      if (this.jobDocument.attachmentTypeId) {
        if (this.jobDocument.attachmentTypeId === this.attachmentTypeEnum.JPEG
          || this.jobDocument.attachmentTypeId === this.attachmentTypeEnum.PNG) {
          this.findImage();
        } else {
          this.imageFound = true;
          this.modalImageLoading = false;
        }
      }

      this.checkForVersions();

      if (this.jobDocument.jobDocumentTypeId && this.jobDocument.recordTypeId !== this.jobDocumentRecordTypeEnum.Heading) {
        const selectedDocTypes = this._jobDocumentService.jobDocumentTypes.filter(i => i.id === this.jobDocument.jobDocumentTypeId);
        if (selectedDocTypes && selectedDocTypes.length) {
          this.isStatusRequired = selectedDocTypes[0].isStatusRequired;
        }
      }

      // get modifiedUser
      this.modifiedUser = this.userService.users.find(i => i.id === this.jobDocument.modifiedUserId)?.fullName;
    } else {
      this.modalHeading = 'Add new entry';
      this.modalButton = 'Add';
      this.getParentValues();

      this.documentForm = this.formBuilder.group({
        recordTypeId: 0,
        description: ['', Validators.required],
        isCustomerViewable: this.isCustomerViewable,
        isTradeViewable: this.isTradeViewable,
        isOverWrite: false,
        jobDocumentStatusId: this.statusId,
        jobDocumentTypeId: this.typeId,
        callUpDocsTypeId: 0
      });

      this.setIsStatusRequired(this.typeId);
    }

    this.loading = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getParentValues() {
    this._jobDocumentService.treeNodes.forEach(node => {
      if (node.id === this.parentId) {
        this.isCustomerViewable = node.isCustomerViewable;
        this.isTradeViewable = node.isTradeViewable;
        this.typeId = node.jobDocumentTypeId;
        this.statusId = node.jobDocumentStatusId;
      } else if (node.recordTypeId === this.jobDocumentRecordTypeEnum.Heading) {
        this.getParentValues2(node);
      }
    });

    this.typeId = this.typeId ? this.typeId : 0; // set default
    this.statusId = this.statusId ? this.statusId : 0;
  }

  getParentValues2(node: IJobDocument) {
    node.children.forEach((child) => {
      if (child.id === this.parentId) {
        this.isCustomerViewable = child.isCustomerViewable;
        this.isTradeViewable = child.isTradeViewable;
        this.typeId = child.jobDocumentTypeId;
        this.statusId = child.jobDocumentStatusId;
      } else if (child.recordTypeId === this.jobDocumentRecordTypeEnum.Heading) {
        this.getParentValues2(child);
      }
    });
  }

  submit() {
    if (this.modalButton === 'Add' && this.documentForm.get('recordTypeId').value === this.jobDocumentRecordTypeEnum.ImportMultipleFiles) {
      this.uploadingMultipleImages = true;
      this.addMultipleDocuments();
    } else if (this.modalButton === 'Add') {
      this.uploadingMultipleImages = false;
      this.addDocument();
    } else if (this.modalButton === 'Update') {
      this.uploadingMultipleImages = false;
      this.editDocument(this.jobDocument.id, 0);
    }
  }

  addDocument(fileNumber = 0) {
    this.submitLoading = true;

    const updatedDocument: any = {
      jobId: this._globalService.getCurrentJob(),
      recordTypeId: this.documentForm.get('recordTypeId').value,
      parentId: this.parentId,
      orderNo: 0,
      description: this.documentForm.get('description').value,
      isCustomerViewable: this.documentForm.get('isCustomerViewable').value,
      isTradeViewable: this.documentForm.get('isTradeViewable').value,
      jobDocumentStatusId:
        +this.documentForm.get('jobDocumentStatusId').value === 0 ? null : this.documentForm.get('jobDocumentStatusId').value,
      jobDocumentTypeId: +this.documentForm.get('jobDocumentTypeId').value === 0 ? null : this.documentForm.get('jobDocumentTypeId').value,
      callUpDocsTypeId: +this.documentForm.get('callUpDocsTypeId').value === 0 ? null : this.documentForm.get('callUpDocsTypeId').value
    };

    let imageSet = false;
    if (this.filesToUpload && this.filesToUpload.length > 0) {
      imageSet = true;
    }

    this.subscriptions = this.subscriptions.concat(
      this._jobDocumentService.addJobDocument(updatedDocument).subscribe({
        next: (res) => {
          this.jobDocument = res;

          if (imageSet) {
            this.compressImage(this.jobDocument.id, fileNumber, true);
          } else {
            this.close(true);
          }
        },
        error: (err) => {
          this.notiService.notify(err);
          this.updateError = true;
          this.submitLoading = false;
          this.documentForm.enable();
          this.docsLeftToLoad -= 1; // in case we are loading multiple
        }
      })
    );
  }

  editDocument(id, fileNumber) {
    let imageSet = false;
    if (this.filesToUpload && this.filesToUpload.length > 0) {
      imageSet = true;
    }
    let updateRequired = false; // don't know if update required yet

    const updatedDocument: any = {};
    if (this.jobDocument.description !== this.documentForm.get('description').value) {
      updatedDocument.description = this.documentForm.get('description').value;
      updateRequired = true;
    }
    if (this.jobDocument.isCustomerViewable !== this.documentForm.get('isCustomerViewable').value) {
      updatedDocument.isCustomerViewable = this.documentForm.get('isCustomerViewable').value;
      updateRequired = true;
    }
    if (this.jobDocument.isTradeViewable !== this.documentForm.get('isTradeViewable').value) {
      updatedDocument.isTradeViewable = this.documentForm.get('isTradeViewable').value;
      updateRequired = true;
    }

    const jobDocStatusId =
      +this.documentForm.get('jobDocumentStatusId').value !== 0 ? this.documentForm.get('jobDocumentStatusId').value : null;
    if (this.jobDocument.jobDocumentStatusId !== jobDocStatusId) {
      updatedDocument.jobDocumentStatusId = jobDocStatusId;
      updateRequired = true;
    }

    const jobDocTypeId = +this.documentForm.get('jobDocumentTypeId').value !== 0 ? this.documentForm.get('jobDocumentTypeId').value : null;
    if (this.jobDocument.jobDocumentTypeId !== jobDocTypeId) {
      updatedDocument.jobDocumentTypeId = jobDocTypeId;
      updateRequired = true;
    }

    const callUpDocsTypeId
      = +this.documentForm.get('callUpDocsTypeId').value !== 0 ? this.documentForm.get('callUpDocsTypeId').value : null;
    if (this.jobDocument.callUpDocsTypeId !== callUpDocsTypeId) {
      updatedDocument.callUpDocsTypeId = callUpDocsTypeId;
      updateRequired = true;
    }

    if (updateRequired || imageSet) {
      this.submitLoading = true;
      this.documentForm.disable();
    }

    if (updateRequired) {
      this.updateDocument(id, updatedDocument, fileNumber, imageSet);
    } else if (imageSet) {
      this.compressImage(id, fileNumber, false);
    } else {
      this.close(true);
    }
  }

  updateDocument(id, updatedDocument: any, fileNumber = 0, filesToLoad) {
    this.subscriptions = this.subscriptions.concat(
      this._jobDocumentService.updateJobDocument(id, updatedDocument).subscribe({
        next: () => {
          if (filesToLoad) {
            this.compressImage(id, fileNumber, false);
          } else {
            this.jobDocument.description = this.documentForm.get('description').value;
            this.jobDocument.isCustomerViewable = this.documentForm.get('isCustomerViewable').value;
            this.jobDocument.isTradeViewable = this.documentForm.get('isTradeViewable').value;
            this.jobDocument.jobDocumentStatusId = +this.documentForm.get('jobDocumentStatusId').value !== 0 ? +this.documentForm.get('jobDocumentStatusId').value : null;
            this.jobDocument.jobDocumentTypeId = +this.documentForm.get('jobDocumentTypeId').value !== 0 ? this.documentForm.get('jobDocumentTypeId').value : null;
            this.jobDocument.callUpDocsTypeId = +this.documentForm.get('callUpDocsTypeId').value !== 0 ? this.documentForm.get('callUpDocsTypeId').value : null;
            this.jobDocument.isStatusRequired = this.isStatusRequired;

            this.close(false);
          }
        },
        error: (err) => {
          this.updateError = true;
          this.submitLoading = false;
          this.documentForm.enable();
          this.notiService.notify(err);
        }
      })
    );
  }

  delete() {
    if (this.deleteConfirmed) {
      this.documentForm.patchValue({
        mode: 'delete'
      });
      this.submitLoading = true;
      this.documentForm.disable();
      this.deleteDocument(this.jobDocument.id, true);
    } else {
      this.deleteConfirmed = true;
    }
  }

  unDelete() {
    this.submitLoading = true;
    this.documentForm.disable();
    this.updateDocument(this.jobDocument.id, { isDeleted: false }, null, 0);
  }

  deleteImage(deleteDoc: boolean) {
    if (this.deleteImageConfirmed || deleteDoc) {
      this.deleteImageConfirmed = false;
      this.submitLoading = true;

      this.subscriptions = this.subscriptions.concat(
        this._jobDocAttachmentService.deleteJobDocAttachment(this.jobDocument.id).subscribe({
          next: (jobDocument) => {
            this.jobDocument = jobDocument;
            this.jobDocAttachment = null;
            this.imageFound = false;
            if (deleteDoc) {
              this.deleteDocument(this.jobDocument.id, true);
            } else {
              this.submitLoading = false;
            }
          },
          error: (err) => {
            this.updateError = true;
            this.submitLoading = false;
            this.notiService.notify(err);
          }
        })
      );
    } else {
      this.deleteImageConfirmed = true;
    }
  }

  updateImage() {
    this.updateImageClicked = true;
    this.imageFound = false;
  }

  deleteDocument(id: number, closeForm: boolean) {
    this.subscriptions = this.subscriptions.concat(
      this._jobDocumentService.deleteJobDocument(id).subscribe({
        next: () => {
          if (closeForm) {  // we don't close the form if we are loading multiple images
            // delete item from the tree
            this.removeFromTreeNodes(id, this._jobDocumentService.treeNodes);
            this.close(false);
          }
        },
        error: (err) => {
          this.updateError = true;
          this.submitLoading = false;
          this.documentForm.enable();
          this.notiService.notify(err);
        }
      })
    );
  }

  removeFromTreeNodes(id: number, nodes: IJobDocument[]) {
    if (nodes) {
      const recNum = nodes.findIndex(i => i.id === id);
      if (recNum >= 0) {
        nodes.splice(recNum, 1);
      } else {
        nodes.forEach(treeNode => {
          this.removeFromTreeNodes(id, treeNode.children);
        });
      }
    }
  }

  handleFileInput(eventRecord) {
    for (let i = 0; i < eventRecord.value.length; i++) {
      const fileType = this.fileService.getFileType(eventRecord.value[i].name);

      if (!fileType
        || (!this.isTrackingActive
          && fileType !== this.attachmentTypeEnum.JPEG
          && fileType !== this.attachmentTypeEnum.JPG
          && fileType !== this.attachmentTypeEnum.PDF
          && fileType !== this.attachmentTypeEnum.PNG)) {
        this.wrongFileType = true;
        return;
      }
    }
    this.wrongFileType = false;
    this.filesToUpload = eventRecord.value;
  }

  findImage() {
    this.imageFound = false;
    this.modalImageLoading = true;

    this.subscriptions = this.subscriptions.concat(
      this._jobDocAttachmentService.getJobDocAttachment(this.jobDocument.id, true)
        .subscribe({
          next: (jobDocAttachment) => {
            this.jobDocAttachment = jobDocAttachment;
            this.imageFound = true;
            this.modalImageLoading = false;
          },
          error: (err) => {
            this.imageFound = false;
            this.modalImageLoading = false;
            this.jobDocument.attachmentTypeId = null;
            this.notiService.notify(err);
          }
        })
    );
  }

  compressImage(id, fileNumber, isAdding: boolean) {
    const fileList = this.filesToUpload;
    const image: File = fileList[fileNumber];

    const attachmentType = this.fileService.getFileType(image.name);

    if (!attachmentType) {
      this.notiService.showWarning('Only PDF, PNG, JPG, XLS, DOC or DXF files can be loaded. Please reselect.');

      if (this.uploadingMultipleImages) {
        // we are uploading multiple files so we need to delete the created jobDoc record
        this.docsLeftToLoad -= 1;
        this.deleteDocument(id, false);
      }
    } else if (this.fileService.typeIsImage(attachmentType)) {
      this.subscriptions = this.subscriptions.concat(
        this.fileService.fileToBase64(image).subscribe({
          next: (base64Image) => {
            this.subscriptions = this.subscriptions.concat(
              this.fileService.compressImage(base64Image, image.name, true).subscribe({
                next: (compressedImgForm) => {
                  this.saveImage(id, compressedImgForm, attachmentType, isAdding);
                }, error: () => {
                  this.imageError();
                }
              })
            );
          }, error: () => {
            this.imageError();
          }
        })
      );
    } else {
      this.saveImage(id, image, attachmentType, isAdding);
    }
  }

  /* If a file has been selected save the img, deleting prev img first if one has been found */
  saveImage(id, image, attachmentTypeId, isAdding: boolean) {
    const form = this.fileService.fileToFormData(image);

    // alert if file large
    if (this.fileService.checkFileSize(form)) {
      this.jobDocument.attachmentTypeId = null;
      this.imageFound = false;

      this.subscriptions = this.subscriptions.concat(
        this._jobDocAttachmentService.patchJobDocAttachment(id, form).subscribe({
          next: () => {
            this.imageSuccess(id, image, attachmentTypeId);
          }, error: () => {
            this.imageError();
          }
        }));
    } else {
      this.imageError(true);
    }
  }

  showImage() {
    // show the PDF
    // show the attachment but read only so open full screen
    const modalRef = this.modalService.open(ShowPdfComponent, { windowClass: 'modal-pdf' });
    modalRef.componentInstance.jobDocumentId = this.jobDocument.id;

    modalRef.result.then(() => { }, () => { });
  }

  imageSuccess(id: number, image: File, attachmentTypeId: number) {
    this.docsLeftToLoad -= 1;
    if (this.docsLeftToLoad < 1) {
      this.close(true);
    }
  }

  imageError(isTooLarge: boolean = false) {
    this.docsLeftToLoad -= 1;
    this.imageFound = false;
    this.submitLoading = false;
    this.documentForm.enable();
    let errorMessage = 'The record was created/updated but the attachment failed to upload.';
    if (isTooLarge) {
      errorMessage += ' The file is too large.';
    }
    this.notiService.showError(errorMessage);
    if (this.docsLeftToLoad < 1) {
      this.close(true);
    }
  }

  close(refresh: boolean) {
    this.activeModal.close(refresh);
  }

  getMultiFileDescription(fileList: File[], i: number): string {
    // if we have entered a desc we use that with -1, -2 on the end
    let multiFileDescription = this.multiFileLoadDescription;

    if (multiFileDescription?.trim() === '') {
      multiFileDescription = fileList[i].name.split('.')[0];
    } else {
      multiFileDescription += ' - ' + (i + 1).toString();
    }
    return multiFileDescription;
  }

  addMultipleDocuments() {
    const fileList = this.filesToUpload;
    let duplicateFound = false;
    this.multiFileLoadDescription = this.documentForm.get('description').value;

    // if not overwriting then check for any existing
    if (!this.documentForm.get('isOverWrite').value) {
      // search current parent list for existing files
      this._jobDocumentService.treeNodes.forEach(node => {
        if (node.parentId === this.parentId && !duplicateFound) {
          for (let i = 0; i < fileList.length && !duplicateFound; i++) {
            if (node.description === this.getMultiFileDescription(fileList, i)) {
              this.notiService.showError('Duplicate file: ' + node.description);
              duplicateFound = true;
            }
          }
        } else if (node.recordTypeId === this.jobDocumentRecordTypeEnum.Heading && !duplicateFound) {
          duplicateFound = this.checkChildForDesc(node, fileList);
        }
      });
    }

    if (!duplicateFound) { // so if we now have duplicates we patch them
      this.docsLeftToLoad = fileList.length;
      this.documentForm.patchValue({
        jobId: this._globalService.getCurrentJob(),
        recordTypeId: this.jobDocumentRecordTypeEnum.Detail,
        parentId: this.parentId,
        orderNo: 0,
        isCustomerViewable: this.documentForm.get('isCustomerViewable').value,
        isTradeViewable: this.documentForm.get('isTradeViewable').value
      });

      let fileFound = false;
      for (let fileNum = 0; fileNum < fileList.length; fileNum++) {
        fileFound = false;
        this.documentForm.patchValue({
          description: this.getMultiFileDescription(fileList, fileNum)
        });

        // search current parent list for existing files
        this._jobDocumentService.treeNodes.forEach(node => {
          if (!fileFound) {
            if (node.parentId === this.parentId && node.description === this.documentForm.get('description').value) {
              fileFound = true;
              this.jobDocument = node;
              this.editDocument(node.id, fileNum);
            } else if (node.recordTypeId === this.jobDocumentRecordTypeEnum.Heading) {
              fileFound = this.updateChildForDesc(node, fileList, fileNum);
            }
          }
        });
        if (!fileFound) {
          this.addDocument(fileNum);
        }
      }
    }
  }

  checkChildForDesc(parent, fileList: File[]): boolean {
    let duplicateFound = false;
    parent.children.forEach(node => {
      if (node.parentId === this.parentId && !duplicateFound) {
        for (let i = 0; i < fileList.length && !duplicateFound; i++) {
          if (node.description === this.getMultiFileDescription(fileList, i)) {
            this.notiService.showError('Duplicate file: ' + node.description);
            duplicateFound = true;
          }
        }
      } else if (node.recordTypeId === this.jobDocumentRecordTypeEnum.Heading && !duplicateFound) {
        duplicateFound = this.checkChildForDesc(node, fileList);
      }
    });
    return duplicateFound;
  }

  updateChildForDesc(parent, fileList: File[], fileNum: number): boolean {
    let fileFound = false;
    parent.children.forEach(node => {
      if (!fileFound) {
        if (node.parentId === this.parentId && node.description === this.documentForm.get('description').value) {
          fileFound = true;
          this.jobDocument = node;
          this.editDocument(node.id, fileNum);
        } else if (node.recordTypeId === this.jobDocumentRecordTypeEnum.Heading) {
          fileFound = this.updateChildForDesc(node, fileList, fileNum);
        }
      }
    });
    return fileFound;
  }

  setIsStatusRequired(selectedTypeId: any) {
    if (this.documentForm.get('recordTypeId').value === this.jobDocumentRecordTypeEnum.Heading) {
      this.isStatusRequired = false;

      this.documentForm.patchValue({
        jobDocumentStatusId: 1 // set to Not Started as default
      });
    } else {
      const selectedDocTypes = this._jobDocumentService.jobDocumentTypes.filter(i => i.id === +selectedTypeId);

      if (selectedDocTypes && selectedDocTypes.length) {
        this.isStatusRequired = selectedDocTypes[0].isStatusRequired;
      } else {
        this.isStatusRequired = false;
      }

      if (this.isStatusRequired && +this.documentForm.get('jobDocumentStatusId').value === 0) {
        this.documentForm.patchValue({
          jobDocumentStatusId: 1 // set to Not Started as default
        });
      }
    }
  }

  refreshJobNumber(jobNumber: string) {
    if (jobNumber !== this.jobNumber) {
      this.jobNumber = jobNumber;

      if (jobNumber && jobNumber !== '') {
        const currentJob = this._jobService.jobs?.find(i => i.jobNumber === jobNumber);
        this.setupCopyItemNodes(currentJob.id);
      } else {
        this.selectedJobDocs = [];
        this.jobDocumentsForCopy = [];
      }
    }
  }

  setupCopyItemNodes(fromJobId: number) {
    this.selectedJobDocs = [];
    this.jobDocumentsForCopy = [];
    this.loadingDocs = true;
    this.subscriptions = this.subscriptions.concat(
      this._jobDocumentService.getJobDocumentForEmail(fromJobId, false)
        .subscribe({
          next: (res) => {
            // this.allJobTasks = tasks;
            this.jobDocumentsForCopy = res;
            this.loadingDocs = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loadingDocs = false;
          }
        })
    );
  }

  attachmentSelectionChanged(e): void {
    // get all leaves as new list
    this.selectedJobDocs = e.component.getSelectedRowKeys('all').filter(this.utils.onlyUnique);
  }

  runCopy() {
    // headings must be in the list
    // copy so we don't muck up the original selected list
    this.selectedJobDocIds = [];

    this.selectedJobDocs.forEach(jobDocId => {
      this.selectedJobDocIds.push(jobDocId);
      this.addHeadingsToCopyList(jobDocId);
    });

    this.loadingDocs = true;
    this.subscriptions = this.subscriptions.concat(
      this._jobDocumentService.copyItems(this._jobService.currentJob.jobNumber, this.parentId, this.selectedJobDocIds, this.insertMissing)
        .subscribe({
          next: () => {
            this.close(true);
          }, error: (err) => {
            this.notiService.notify(err);
            this.loadingDocs = false;
          }
        })
    );
  }

  addHeadingsToCopyList(jobDocId: number) {
    // the heading must also be copied
    const foundDoc = this.jobDocumentsForCopy.find(i => i.id === jobDocId);

    if (foundDoc && foundDoc.parentId) {
      const foundParent = this.jobDocumentsForCopy.find(i => i.id === foundDoc.parentId);

      if (foundParent) {
        // must be in the list
        const parentExists = this.selectedJobDocIds.find(i => i === foundParent.id);

        if (!parentExists) {
          this.selectedJobDocIds.push(foundParent.id);

          if (foundParent.parentId) {
            this.addHeadingsToCopyList(foundParent.id);
          }
        }
      }
    }
  }

  checkForVersions() {
    this.subscriptions = this.subscriptions.concat(
      this._jobDocAttachmentService.getJobDocAttachmentVersions(this.jobDocument.id).subscribe({
        next: (versions) => {
          this.hasVersions = versions.length > 1;
        }, error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  showVersions() {
    const modalRef = this.modalService.open(DocumentVersionsComponent, { windowClass: 'modal-1000' });
    modalRef.componentInstance.jobDocumentId = this.jobDocument.id;

    modalRef.result.then(() => { });
  }
}
