import { Injectable } from '@angular/core';
import { Variation } from '../../../app/dtos/variation';
import { VariationStatusEnum } from '../../dtos/variation-status.enum';


export class VariationChecks {
  openVariations: boolean;
  postContractVariations: boolean;
  openClientVariation: number;
}

@Injectable({
  providedIn: 'root'
})
export class VariationHelperService {

  constructor() { }

  checkVariations(variations: Variation[]): VariationChecks {
    const checks = new VariationChecks();

    if (variations && variations.length) {
      for (let i = 0; i < variations.length; i++) {
        if (variations[i].statusId < VariationStatusEnum.Cancelled) {
          checks.openVariations = true;

          if (variations[i].statusId === VariationStatusEnum.Open && variations[i].canBeModifiedByClient) {
            checks.openClientVariation = variations[i].variationNumber;
          }
        }

        if (variations[i].variationType < 10) {
          checks.postContractVariations = true;
        }
      }
    }

    return checks;
  }

}
