import { JobService } from './../../../services/felixApi/job.service';
import { NotificationService } from './../../../services/notification.service';
import { GlobalService } from './../../../services/global.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { PDFReports } from '../../../dtos/pdf-report';
import { ShowPdfComponent } from '../../../shared/show-pdf.component';

@Component({
  selector: 'js-load-addenda-pdf',
  templateUrl: './load-addenda-pdf.component.html',
  styleUrls: ['./load-addenda-pdf.component.css']
})
export class LoadAddendaPdfComponent implements OnInit {
  addendumName: string;
  value: any[] = [];
  loading: boolean;
  subscriptions: Subscription[] = [];
  replacementAddendaPdf: PDFReports;
  useUploadedAddenda: boolean;

  constructor(private _activeModal: NgbActiveModal,
    private _globalService: GlobalService,
    private jobService: JobService,
    private modalService: NgbModal,
    private notiService: NotificationService) { }

  ngOnInit() {
    this.addendumName = this._globalService.getAddendumName();
    this.useUploadedAddenda = this.jobService.currentJob.useUploadedAddenda;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  close() {
    this._activeModal.close();
  }

  // getFile() {
  //   if (this.jobService.currentJob.useUploadedAddenda) {
  //     this.loading = true;
  //     this.subscriptions = this.subscriptions.concat(
  //       this.jobService.getReplacementAddendaPDF(this.jobService.currentJob.id).subscribe(
  //         replacementAddendaPdf => {
  //           this.replacementAddendaPdf = replacementAddendaPdf;
  //           this.loading = false;
  //           this.showPDF();
  //         },
  //         err => {
  //           this.loading = false;
  //           this.notiService.notify(err);
  //         })
  //     );
  //   }
  // }

  uploadClick() {
    // load the file
    if (this.value && this.value.length) {
      this.loading = true;
      const formData: FormData = new FormData();
      formData.append('image', this.value[0], this.value[0].name);

      this.subscriptions = this.subscriptions.concat(
        this.jobService.loadReplacementAddendaPDF(this.jobService.currentJob.id, formData).subscribe({
          next: () => {
            this.jobService.currentJob.useUploadedAddenda = true;
            this.useUploadedAddenda = true;
            this.loading = false;
          }, error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
      );
    }
  }

  showPDF() {
    const modalRef1 = this.modalService.open(ShowPdfComponent, { windowClass: 'modal-pdf' });
    modalRef1.componentInstance.useUploadedAddenda = true;

    modalRef1.result.then(() => {
    }, () => {
    });
  }

  deleteFile() {
    this.loading = true;
    this.subscriptions = this.subscriptions.concat(
      this.jobService.deleteReplacementAddendaPDF(this.jobService.currentJob.id).subscribe({
        next: () => {
          this.jobService.currentJob.useUploadedAddenda = false;
          this.useUploadedAddenda = false;
          this.loading = false;
        }, error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }
}
