<div class="modal-header">
  <h4 class="modal-title" placement="bottom">Variations</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" #allEstimateGrid id="allEstimateGrid" class="mt-3" #treeList id="tree-list"
    [dataSource]="dataSource" [height]="gridHeight" [remoteOperations]="false" [showRowLines]="false"
    [showColumnLines]="true" [showBorders]="true" [columnAutoWidth]="false" [wordWrapEnabled]="true"
    [allowColumnResizing]="true" [allowColumnReordering]="true">

    <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-paging [enabled]="false"> </dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-column-chooser [enabled]="true" [height]="500"></dxo-column-chooser>

    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
    </dxo-grouping>


    <dxi-column dataField="variationTypeDesc" caption="Variation Type" dataType="number" sortOrder="asc" groupIndex="0">
    </dxi-column>

    <dxi-column dataField="statusId" caption="Status" dataType="number" sortOrder="asc" groupIndex="1">
      <dxo-lookup [dataSource]="variationStatuses" valueExpr="id" displayExpr="description">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="jobId" caption="Job" [width]="100" dataType="number" sortOrder="asc"
      [calculateSortValue]="calculateJobSortValue">
      <dxo-lookup [dataSource]="jobs" valueExpr="id" displayExpr="jobNumber">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="contractName" dataType="string" [calculateCellValue]='calculateContractName' [minWidth]="120">
    </dxi-column>

    <dxi-column dataField="jobAddressString" caption='Site Address' [calculateCellValue]="calcSiteAddress" [minWidth]="150">
    </dxi-column>

    <dxi-column dataField="displayedVariationNumber" dataType="string" caption="#" [width]="60" alignment="center">
    </dxi-column>

    <dxi-column dataField="title" dataType="string"> </dxi-column>

    <dxi-column dataField="variationDate" caption="Dated" dataType="date" [width]="90" format="dd-MMM-yy"
      alignment="center"></dxi-column>

    <dxi-column dataField="dueDate" caption="Due" dataType="date" [width]="90" format="dd-MMM-yy"
      alignment="center"></dxi-column>

    <dxi-column dataField="variationTotal" dataType="number" [width]="100" format="#,###.####"> </dxi-column>

    <dxi-column dataField="statusModifiedUserId" dataType="number" caption='Status Modified By' [width]="150">
      <dxo-lookup [dataSource]="users" valueExpr="id" displayExpr="fullName"> </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="currentActivity" dataType="string" [width]="130" alignment="left"
      [calculateCellValue]="calculateCurrentActivity" [calculateSortValue]="calculateActivitySort">
    </dxi-column>

    <dxi-column dataField="author" [visible]="false" [minWidth]="90"></dxi-column>

    <dxi-column dataField="assignedUserId" dataType="number" caption='Estimator' [visible]="false" [minWidth]="90">
      <dxo-lookup [dataSource]="users" valueExpr="id" displayExpr="fullName"> </dxo-lookup>
    </dxi-column>

    <dxi-column *ngIf="siteSupervisorName" dataField="siteSupervisorName" [caption]="siteSupervisorName"
      dataType="string" [width]="140" [calculateCellValue]="calculateSiteSupervisor" [visible]="false">
    </dxi-column>

    <dxi-column *ngIf="liaisonName" dataField="liaisonName" [caption]="liaisonName" dataType="string" [width]="140"
      [calculateCellValue]="calculateLiaison" [visible]="false">
    </dxi-column>

    <dxi-column *ngIf="prestartOfficer" dataField="prestartOfficer" [caption]="prestartOfficer" dataType="string"
      [width]="140" [calculateCellValue]="calculatePrestartOfficer" [visible]="false">
    </dxi-column>

    <dxi-column *ngIf="constructionCoordinator" dataField="constructionCoordinator" [caption]="constructionCoordinator"
      dataType="string" [width]="140" [calculateCellValue]="calculateConstructionCoordinator" [visible]="false">
    </dxi-column>

    <dxi-column *ngIf="preConstructionCoordinator" dataField="preConstructionCoordinator"
      [caption]="preConstructionCoordinator" dataType="string" [width]="140"
      [calculateCellValue]="calculatePreConstructionCoordinator" [visible]="false">
    </dxi-column>
  </dx-data-grid>
</div>
