<div class="page">
  <div class="card">
    <div class="card-header">
      <!-- select job -->
      <js-job-number (refreshJobNumber)='onJobNumberChanged($event)'></js-job-number>
    </div>

    <div class="card-body">
      <js-loading-spinner *ngIf="loading" [usePopUp]="false"></js-loading-spinner>

      <dx-data-grid *ngIf="!loading" id="jobProgressGrid" [dataSource]="jobProgressTasks" keyExpr="orderNumber"
        [remoteOperations]="false" [showBorders]="true" [focusedRowEnabled]="false" [showRowLines]="true"
        [hoverStateEnabled]="true" [wordWrapEnabled]="true" [rowAlternationEnabled]="false" [width]="gridWidth"
        [height]="gridHeight" (onExporting)="onExporting($event)">

        <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-export [enabled]="true"></dxo-export>

        <dxi-column dataField="taskDescription" caption="Updates" [allowSorting]="false"></dxi-column>

        <dxi-column dataField="date" dataType="date" [width]="150" alignment="center" format="d-MMM-yy"> </dxi-column>
      </dx-data-grid>
    </div>
  </div>
</div>
