import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { GlobalService } from '../global.service';
import { IJobItemAttachment } from '../../dtos/job-item-attachment';
import { AuthService } from '../auth.service';
import { HttpService } from '../http.service';
import { AttachmentTypeEnum } from '../../dtos/attachment-type.enum';

@Injectable({
  providedIn: 'root'
})
export class JobVarItemAttachmentService {
  cache: IJobItemAttachment[] = [];

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private _authService: AuthService,
    private globalService: GlobalService) { }


  getJobItemAttachmentById(id): Observable<IJobItemAttachment> {
    const img = this.cache.filter(i => i.id === id);
    if (img[0]) {
      return of(img[0]);
    } else {
      return this._http.get<IJobItemAttachment>(this.globalService.getApiUrl() + '/variation-item-attachments/' + id,
        this.httpService.getHttpFileOptions())
        .pipe(tap(res => {
          if (res.attachmentTypeId !== AttachmentTypeEnum.PDF) {
            this.cache = this.cache.concat(res);
          }
        }),
          catchError(this.handleError));
    }
  }

  postJobItemAttachment(jobitemId, imageFile) {
    let url = this.globalService.getApiUrl() + '/variation-items/' + jobitemId + '/attachments/upload';

    if (this._authService.isClient()) {
      url += '-by-client';
    }
    const options = this.httpService.getHttpFileOptions();
    return this._http.post(url, imageFile, options);
  }

  deleteJobItemAttachment(id) {
    let url = this.globalService.getApiUrl() + '/variation-item-attachments/' + id;

    if (this._authService.isClient()) {
      url += '/by-client';
    }
    const options = this.httpService.getHttpFileOptions();
    return this._http.delete(url, options)
      .pipe(
        tap(() => {
          const attachmentIndex = this.cache.findIndex(i => i.id === id);
          if (attachmentIndex >= 0) {
            // remove the record from the array
            this.cache.splice(attachmentIndex, 1);
          }
        }),
        catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
