<div class="modal-header">
  <h2 class="modal-title">Move item under selected item</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancelWithNo()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <!-- Loading Spinner -->
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <div *ngIf="!loading">
    <div class="globalTreeclass" [ngStyle]="{'height': 'calc(' + treeHeight + 'px' + ')'}">
      <tree-root #tree3 [focused]="true" [nodes]="treeNodes" [options]="copyTreeOptions"
        (activate)="selectItemToAddAfter(tree.treeModel.getFocusedNode().data)" (initialized)="onItemTreeInit(tree3)">
        <ng-template #treeNodeTemplate let-node="node" let-index="index">
          <span>{{ node.data.description }}</span>
        </ng-template>
      </tree-root>
    </div>
  </div>
</div>

<div class="modal-footer">
  <span class="red">{{errorMessage}}</span>

  <!-- if heading are we adding as sibling or child -->
  <div *ngIf="itemToAddAfter && itemToAddAfter.itemTypeId === itemTypeEnum.Heading">
    As:
    <div class="btn-group btn-group-toggle marginleft15" ngbRadioGroup name="radioBasic">
      <label ngbButtonLabel class="btn-sm btn-primary">
        <input ngbButton type="radio" [value]="false" [(ngModel)]="addAsChild">
        Sibling
      </label>
      <label ngbButtonLabel class="btn-sm btn-primary ms-1">
        <input ngbButton type="radio" [value]="true" [(ngModel)]="addAsChild">
        Child
      </label>
    </div>
  </div>

  <button type="button" class="btn btn-sm btn-primary" (click)="runMove()"
    [disabled]="loading || !itemToAddAfter">Move</button>
</div>
