export enum TaskStatusEnum {
    NotStarted = 1,
    InProgress = 2,
    Hold = 3,
    Problem = 4,
    Waiting = 5,
    Cancelled = 8,
    Completed = 9,
    NotApplicable = 10
}
