import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';
import { Estate } from '../../dtos/estate';
import { environment } from '../../../environments/environment';
// import { EstateAttachment } from '../../../dtos/estate-attachment';
// import { Logo } from '../../dtos/logo';

@Injectable({
  providedIn: 'root'
})
export class EstateService {

  estateURL = environment.apiUrl + '/estates';
  // estateAttachmentsURL = environment.apiUrl + '/estate-attachments';
  estates: Estate[];
  estatesCompany: string;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  getEstates(useCache: boolean) {
    if (useCache && this.estates && this.estates.length && this.estatesCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.estates);
    } else {
      return this.http.get<Estate[]>(this.estateURL, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.estates = res;
          this.estatesCompany = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.httpService.handleError));
    }
  }

  addEstate(dataRecord: any): Observable<Estate> {
    return this.http.post<Estate>(this.estateURL, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateEstate(id: string, itm: any): Observable<Estate> {
    const url = this.estateURL + '/' + id;
    return this.http.patch<Estate>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteEstate(id: string) {
    const url = this.estateURL + '/' + id;
    return this.http.delete(url, this.httpService.getHttpOptions());
  }


  // getEstateAttachments(estateId: number): Observable<EstateAttachment> {
  //   const url = this.estateAttachmentsURL + '?estateId=' + estateId;
  //   return this.http.get<EstateAttachment>(url, this.httpService.getHttpOptions());
  // }

  // addEstateAttachment(dataRecord: any): Observable<Estate> {
  //   return this.http.post<Estate>(this.estateAttachmentsURL, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  // }

  // updateEstateAttachment(id: string, itm: any): Observable<Estate> {
  //   const url = this.estateAttachmentsURL + '/' + id;
  //   return this.http.patch<Estate>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  // }

  // deleteEstateAttachment(id: string) {
  //   const url = this.estateAttachmentsURL + '/' + id;
  //   return this.http.delete(url, this.httpService.getHttpOptions());
  // }


  // attachments
  // getEstateAttachmentFile(id: number): Observable<Logo> {
  //   const url = this.estateAttachmentsURL + '/' + id + '/attachment';
  //   return this.http.get<Logo>(url, this.httpService.getHttpOptions());
  // }

  // uploadEstateAttachmentFile(id: number, image: any) {
  //   const url = this.estateAttachmentsURL + '/' + id + '/attachment';
  //   return this.http.post(url, image, this.httpService.getHttpFileOptions());
  // }
}
