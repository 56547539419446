<div class="modal-header">
  <h4 class="modal-title">Maintenance Complete</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <div *ngIf="!loading">
    <span class='me-1 ms-3'>Maintenance Completed:</span>

    <dx-date-box class="inline-block" [(value)]="maintenanceCompleteDate" type="date" displayFormat="dd/MM/yy"
      [showClearButton]="true" [width]="150" [calendarOptions]="{ showTodayButton: true }"></dx-date-box>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" (click)="submit()">Update</button>
</div>
