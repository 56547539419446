import { VariationSplitsService } from './../services/felixApi/variation-splits.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JobsRoutingModule } from './jobs-routing.module';

import { SharedModule } from '../shared/shared.module';

import { AddressToStringPipe } from '../shared/address-to-string.pipe';
import { JobDetailsComponent } from './job-details.component';
import { JobComponent } from './job.component';

import { AddressService } from '../services/felixApi/address.service';
import { JobService } from '../services/felixApi/job.service';
import { JobSearchModalComponent } from './job-search-modal.component';
import { JobNumberComponent } from './job-number.component';
import { JobCustomerService } from '../services/felixApi/job-customer.service';
import { JobCustomerModalComponent } from './job-modals/job-customer-modal.component';
import { JobCopyModalComponent } from './job-modals/job-copy-modal.component';
import { JobUsersModalComponent } from './job-modals/job-users-modal.component';
import { SelectUserComponent } from './job-modals/select-user.component';
import { ChangeNumberModalComponent } from './job-modals/change-number-modal.component';
import { JobEstimatingModalComponent } from './job-modals/job-estimating-modal/job-estimating-modal.component';
import { JobClaimsComponent } from './job-modals/job-claims/job-claims.component';
import { UtilsService } from '../services/utils.service';
import { TrackingFieldsService } from '../services/felixApi/tracking-fields.service';
import { JobFieldsComponent } from './job-modals/job-fields/job-fields.component';
import { JobNumberSmallComponent } from './job-number-small/job-number-small.component';
import { LeadManagementComponent } from './job-modals/lead-management/lead-management.component';
import { IntegrationService } from '../services/felixApi/integration.service';
import { LeadManagementFieldsComponent } from './job-modals/lead-management-fields/lead-management-fields.component';
import { EstateService } from '../services/felixApi/estate.service';
import { MaintenanceCompleteComponent } from './job-modals/maintenance-complete/maintenance-complete.component';
import { StatementComponent } from './job-modals/statement/statement.component';
import { AddMaintenanceJobComponent } from './job-modals/add-maintenance-job/add-maintenance-job.component';
import { AddClientUserComponent } from './job-modals/add-client-user/add-client-user.component';

@NgModule({
    imports: [
        CommonModule,
        JobsRoutingModule,
        NgbModule,
        SharedModule
    ],
    declarations: [
        JobComponent,
        JobNumberComponent,
        JobDetailsComponent,
        AddressToStringPipe,
        JobSearchModalComponent,
        JobCustomerModalComponent,
        JobCopyModalComponent,
        JobUsersModalComponent,
        SelectUserComponent,
        ChangeNumberModalComponent,
        JobEstimatingModalComponent,
        JobClaimsComponent,
        JobFieldsComponent,
        JobNumberSmallComponent,
        LeadManagementComponent,
        LeadManagementFieldsComponent,
        MaintenanceCompleteComponent,
        StatementComponent,
        AddMaintenanceJobComponent,
        AddClientUserComponent
    ],
    providers: [
        JobService,
        AddressService,
        JobCustomerService,
        UtilsService,
        VariationSplitsService,
        TrackingFieldsService,
        IntegrationService,
        EstateService
    ],
    exports: [
        JobNumberComponent,
        JobNumberSmallComponent
    ]
})
export class JobsModule { }
