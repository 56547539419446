<div class="card">
  <dx-data-grid *ngIf="!loading" id="dataGrid" #dataGrid class="m-auto" [dataSource]="dataSource" [width]="gridWidth"
    [allowColumnReordering]="false" [columnAutoWidth]="true" [allowColumnResizing]="false" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [height]="gridHeight"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate')">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" [width]="100"></dxo-search-panel>
    <dxo-editing mode="popup" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="repaint">
      <dxo-popup title="" [showTitle]="false" [width]="400" [height]="editPopupHeight" [shading]="false"
        [hideOnOutsideClick]="true">
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton"
          [options]="{ type: 'default', text: 'Save', onClick: saveClickHandler }">
        </dxi-toolbar-item>
        <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton"
          [options]="{ type:'danger', text: 'Cancel', onClick: cancelClickHandler }">
        </dxi-toolbar-item>
      </dxo-popup>
      <dxo-form>
        <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
          <dxi-item dataField="officeComment" editorType="dxTextArea" [editorOptions]="{ height: 130 }" [colSpan]="2">
          </dxi-item>

          <dxi-item *ngIf="canEditDates || ownTask" dataField="dueDate" editorType="dxDateBox" [colSpan]="2"
            [editorOptions]="{ width: 200, showClearButton: true, calendarOptions: { showTodayButton: true } }">
          </dxi-item>

          <dxi-item *ngIf="canEditDates || ownTask" dataField="endDate" editorType="dxDateBox" [colSpan]="2"
            [editorOptions]="{ width: 200, showClearButton: true, calendarOptions: { showTodayButton: true } }">
          </dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxi-column dataField="jobId" caption="Job" [width]="90" [calculateSortValue]="calculateJobSortValue">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="jobData" displayExpr="jobNumber" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="contractName" dataType="string" caption="Contract Name" [minWidth]="contractNameWidth"
      [hidingPriority]="6">
    </dxi-column>

    <dxi-column dataField="jobAddressString" caption="Site Address" dataType="string" [minWidth]="contractNameWidth"
      [hidingPriority]="2">
    </dxi-column>

    <dxi-column dataField="taskTypeId" caption="Type" dataType="number" [width]="120" [hidingPriority]="3">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="taskTypes" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="taskMasterId" dataType="number" caption="Title" [width]="120"
      [calculateSortValue]="calculateTaskSortValue">
      <dxo-lookup [dataSource]="getTaskList" displayExpr="taskTitle" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="startDate" dataType="date" [width]="115" alignment="center" format="d-MMM-yy"
      [hidingPriority]="5">
    </dxi-column>

    <dxi-column dataField="dueDate" dataType="date" [width]="115" alignment="center" format="d-MMM-yy" sortOrder="asc">
    </dxi-column>

    <dxi-column dataField="endDate" caption="Completed" dataType="date" [width]="120" alignment="center"
      format="d-MMM-yy" [hidingPriority]="4">
    </dxi-column>

    <dxi-column dataField="officeComment" caption="Comment" [width]="200" cellTemplate="commentCellTemplate"
      [hidingPriority]="1">
    </dxi-column>

    <dxi-column dataField="userId" caption="Assigned To" dataType="number" [width]="140" [hidingPriority]="0">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>


    <div *dxTemplate="let cellInfo of 'commentCellTemplate'">
      <div id="vendorComment" class='mx-0 px-0'>{{cellInfo.value}}</div>
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <span class="ms-2 me-2">Show Completed</span>
        <dx-check-box [(value)]="showCompleted" [width]="30" (onValueChanged)="showCompletedChanged($event)">
        </dx-check-box>
      </div>
    </div>
  </dx-data-grid>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
