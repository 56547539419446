<div class="modal-header">
  <h2 class="modal-title">{{modalHeading}}</h2>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<!-- Loading Spinner -->
<js-loading-spinner *ngIf="loading"></js-loading-spinner>

<div *ngIf="!loading" class="modal-body">

  <div *ngIf="canChangeQuoteDate && (isSalesQuote || isContractQuote)" class="quoteLine mb-2">
    <div class="qWidth">Quote Date</div>
    <dx-date-box class="inline-block" [(value)]="quoteDate" type="date" displayFormat="dd-MMM-yy" [width]="125"
      [calendarOptions]="{ showTodayButton: true }"></dx-date-box>

    <span *ngIf="isPriceGuideCheckBoxVisible" class="priceGuideClass">
      <label for="printImages">
        Price Guide &nbsp;
      </label>
      <input type="checkbox" id="isPriceGuide" [checked]="isPriceGuide" (change)="isPriceGuide = !isPriceGuide">
    </span>
  </div>

  <div *ngIf="canChangeQuoteDate && showQuoteExpiryDate" class="quoteLine mb-2">
    <div class="qWidth">Quote Expires</div>
    <dx-date-box class="inline-block" [(value)]="quoteExpiryDate" type="date" displayFormat="dd-MMM-yy" [width]="125"
      [calendarOptions]="{ showTodayButton: true }"></dx-date-box>
  </div>

  <div *ngIf="canChangeQuoteDate && isSalesQuote">
    <label for="salesQuoteNumber">
      Revision &nbsp;
    </label>
    <input type="text" id="salesQuoteNumber" [(ngModel)]="salesQuoteNumber">
  </div>

  <div *ngIf="!exportToExcel" class="checkbox">
    <label for="printImages">
      Print Images &nbsp;
    </label>
    <input type="checkbox" id="printImages" [checked]="printImages" (change)="printImages = !printImages">
  </div>

  <div *ngIf="variationNumber && !isAssociate && canSeeAmounts" class="checkbox">
    <label for="printPrices">
      Print Prices &nbsp;
    </label>
    <input type="checkbox" id="printPrices" [checked]="printPrices" (change)="printPrices = !printPrices">
  </div>

  <div *ngIf="!exportToExcel && !isClient && !isAssociate" class="checkbox">
    <label for="printNotApplicable">
      Print items marked 'Not Applicable' &nbsp;
    </label>
    <input type="checkbox" id="printNotApplicable" [checked]="printNotApplicable"
      (change)="printNotApplicable = !printNotApplicable">
  </div>

  <div *ngIf="showPrintNonPrint && !isAssociate" class="checkbox">
    <label for="printNonPrintItems">
      Print items marked 'Do Not Print' &nbsp;
    </label>
    <input type="checkbox" id="printNonPrintItems" [checked]="printNonPrintItems"
      (change)="printNonPrintItems = !printNonPrintItems">
  </div>

  <div *ngIf="(isSalesQuote || isContractQuote || variationNumber) && !isClient" class="checkbox">
    <label for="printConnectedTogether">
      Print connected items together &nbsp;
    </label>
    <input type="checkbox" id="printConnectedTogether" [checked]="printConnectedTogether"
      (change)="printConnectedTogether = !printConnectedTogether">
  </div>

  <div *ngIf="variationNumber && !exportToExcel" class="checkbox">
    <label for="printVONumber">
      Print variation number &nbsp;
    </label>
    <input type="checkbox" id="printVONumber" [checked]="printVONumber" (change)="printVONumber = !printVONumber">
  </div>

  <div *ngIf="!variationNumber && !exportToExcel && !isAssociate && !(isSalesQuote || isContractQuote) && canSeeAmounts"
    class="checkbox">
    <label for="printProvisionalSums">
      Print Provisional Sums &nbsp;
    </label>
    <input type="checkbox" id="printProvisionalSums" [checked]="printProvisionalSums"
      (change)="printProvisionalSums = !printProvisionalSums">
  </div>

  <div *ngIf="(isSalesQuote || isContractQuote)" class="checkbox">
    <label for="printWholeAddendum">
      Print whole {{addendumName}} (1st variation only) &nbsp;
    </label>
    <input type="checkbox" id="printWholeAddendum" [checked]="printWholeAddendum"
      (change)="printWholeAddendum = !printWholeAddendum">
  </div>

  <div *ngIf="(isSalesQuote || isContractQuote)" class="checkbox">
    <label for="printItemsNotTaken">
      Print items not taken &nbsp;
    </label>
    <input type="checkbox" id="printItemsNotTaken" [checked]="printItemsNotTaken"
      (change)="printItemsNotTaken = !printItemsNotTaken">
  </div>

  <div *ngIf="(isSalesQuote || isContractQuote)" class="checkbox">
    <label for="printChangeHistory">
      Print history of changes &nbsp;
    </label>
    <input type="checkbox" id="printChangeHistory" [checked]="printChangeHistory"
      (change)="printChangeHistory = !printChangeHistory">
  </div>

  <div *ngIf="(isSalesQuote || isContractQuote || !exportToExcel)"
    class="checkbox">
    <label for="printPdfAttachments">
      Print any PDF attachments &nbsp;
    </label>
    <input type="checkbox" id="printPdfAttachments" [checked]="printPdfAttachments"
      (change)="printPdfAttachments = !printPdfAttachments">
  </div>

  <div *ngIf="(isSalesQuote || isContractQuote) && !isClient && !isAssociate" class="checkbox">
    <label for="printIncludedAmounts">
      Print <b>Office Copy</b> - (shows included amounts) &nbsp;
    </label>
    <input type="checkbox" id="printIncludedAmounts" [checked]="printIncludedAmounts"
      (change)="printIncludedAmounts = !printIncludedAmounts">
  </div>

  <div *ngIf="isContractQuote" class="checkbox">
    <label for="printFromSalesPriceOnwards">
      Print from sales price onwards &nbsp;
    </label>
    <input type="checkbox" id="printFromSalesPriceOnwards" [checked]="printFromSalesPriceOnwards"
      (change)="printFromSalesPriceOnwards = !printFromSalesPriceOnwards">
  </div>

  <div *ngIf="!variationNumber && !(isSalesQuote || isContractQuote) && !isClient && !isAssociate" class="checkbox">
    <label for="printQuantities">
      Print quantities (if any) &nbsp;
    </label>
    <input type="checkbox" id="printQuantities" [checked]="printQuantities"
      (change)="printQuantities = !printQuantities">
  </div>

  <div *ngIf="variationNumber && !exportToExcel" class="checkbox">
    <label for="printWithHighlighting">
      Print highlighting &nbsp;
    </label>
    <input type="checkbox" id="printWithHighlighting" [checked]="printWithHighlighting"
      (change)="printWithHighlighting = !printWithHighlighting">
  </div>

  <div *ngIf="showShowCheckBoxesSelection" class="checkbox">
    <label for="showCheckBoxes">
      Show check boxes &nbsp;
    </label>
    <input type="checkbox" id="showCheckBoxes" [checked]="showCheckBoxes" (change)="showCheckBoxes = !showCheckBoxes">
  </div>

  <div *ngIf="showSignatureCheckBox" class="checkbox">
    <label for="showSignatureSection">
      Show signature section &nbsp;
    </label>
    <input type="checkbox" id="showSignatureSection" [checked]="showSignatureSection"
      (change)="showSignatureSection = !showSignatureSection">
  </div>
</div>

<div *ngIf="!loading" class="modal-footer">
  <div class="buttonLine">
    <div *ngIf="generateXML" class="floatLeft">
      <label for="outputFileName">
        New Job Number&nbsp;
      </label>
      <input type="text" id="outputFileName" [(ngModel)]="outputFileName">
    </div>

    <button *ngIf="!isSalesQuote && !isContractQuote && !generateXML && !exportToExcel && !variationNumber"
      class="btn btn-sm btn-secondary floatLeft" (click)="selectCategories()">Categories</button>

    <button class="btn btn-sm btn-primary floatRight" (click)="runReport(generateXML)"
      [disabled]="generateXML && outputFileName === ''">Go</button>

    <button *ngIf="(isSalesQuote || isContractQuote) && canReadAmountsAdmin && quoteXMLExport && !generateXML"
      class="btn btn-sm btn-secondary floatRight me-2" (click)="generateXML = true">Generate XML</button>

    <button *ngIf="(isSalesQuote || isContractQuote) && hasSeparateAttachments && !generateXML"
      class="btn btn-sm btn-secondary floatRight me-2" (click)="getCompanyAttachments()">Generate Separate
      Attachments</button>
  </div>
</div>
