<div class="modal-header">
  <h2 class="modal-title">Item Setup</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <!-- Loading Spinner -->
  <js-loading-spinner *ngIf="loading || !treeJobItemNodesSetup"></js-loading-spinner>

  <form *ngIf="!loading" novalidate>

    <!-- description is always set-->
    <div class="row">
      <label class="form-label col-sm-2" for="itemDescription">Description:</label>
      <input type="text" class="form-control-sm col-sm-6" name="itemDescription" ngModel id="itemDescription"
        [(ngModel)]="jobItem.itemDescription" readonly>

      <label class="form-label col-sm-4" for="isSetupLine">Job Setup Line:
        <input class="setupCheckbox" type="checkbox" name="isSetupLine" ngModel id="isSetupLine"
          [(ngModel)]="isSetupLine">
      </label>
    </div>

    <div *ngIf="(jobItem.itemTypeId === ItemType.Detail)" class="">
      <div>
        <div class="mt-3">
          <b>Select type of selection field required:</b>
        </div>
        <!-- <div class="row radioclass">
          <label class="marginleft15">
            <input type="radio" [(ngModel)]="detailOptionType" value="Checkbox" name="detailOptionType"> Checkbox
          </label>
          <br />
          <label class="marginleft15">
            <input type="radio" [(ngModel)]="detailOptionType" value="Use an Option List" name="detailOptionType">
            Drop Down using an Option List
          </label>
          <br />
          <label class="marginleft15">
            <input type="radio" [(ngModel)]="detailOptionType" value="Link to another line" name="detailOptionType">
            Linked to Another Selection
          </label>
          <br />
        </div> -->

        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
            [(ngModel)]="detailOptionType" value="Checkbox">
          <label class="form-check-label" for="inlineRadio1">Checkbox</label>
        </div>

        <div class="form-check form-check-inline mb-3">
          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
            [(ngModel)]="detailOptionType" value="Use an Option List">
          <label class="form-check-label" for="inlineRadio1">Drop Down using an Option List</label>
        </div>

        <div class="form-check form-check-inline mb-3">
          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
            [(ngModel)]="detailOptionType" value="Link to another line">
          <label class="form-check-label" for="inlineRadio1">Linked to Another Selection</label>
        </div>
      </div>

      <div *ngIf="(detailOptionType === 'Use an Option List')">

        <!-- Loading Spinner -->
        <js-loading-spinner *ngIf="!treeOptionNodesSetup"></js-loading-spinner>

        <!-- if we need to select an option list use a tree -->
        <div *ngIf="treeOptionNodesSetup">
          <div>
            <b>Select an Option List:</b>
          </div>

          <input id="filter" #filter (keyup)="tree.treeModel.filterNodes(filter.value)" placeholder="filter nodes"
            class='mt-1 pb-1 pt-0 ms-2p' />
          <button class="btn btn-sm btn-secondary"
            (click)="$event.preventDefault(); $event.stopPropagation(); tree.treeModel.clearFilter(); tree.treeModel.collapseAll(); filter.value=''">
            Clear Filter
          </button>

          <button class="btn btn-sm btn-secondary ms-1"
            (click)="$event.preventDefault(); $event.stopPropagation(); refreshList();">
            Refresh
          </button>

          <div class="treeclass">
            <tree-root #tree [focused]="true" [nodes]="treeOptionNodes" [options]="treeOptionOptions"
              (nodeActivate)="setOptionList(tree.treeModel.getFocusedNode().data.id)"
              (initialized)="onOptionTreeInit(tree)"></tree-root>
          </div>
        </div>
      </div>

      <div *ngIf="(detailOptionType === 'Link to another line')">

        <!-- if we need to select another job item use a tree -->
        <div *ngIf="treeJobItemNodesSetup">
          <div>
            <b>Select the job line to connect to:</b>
          </div>
          <div class="treeclass">
            <tree-root #tree [focused]="true" [nodes]="treeJobItemNodes" [options]="treeJobItemOptions"
              (nodeActivate)="setLinkedItemId(tree.treeModel.getFocusedNode().data.id, tree)"
              (initialized)="onItemTreeInit(tree)"></tree-root>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-sm-6">
        <div *ngIf="jobItem.itemTypeId === ItemType.Detail
            && (detailOptionType === 'Link to another line' || detailOptionType === 'Use an Option List')">
          <label class="form-label" for="canEnterManualSelection">
            <b>Can a user enter their own value:</b>
          </label>
          <input type="checkbox" name="canEnterManualSelection" ngModel id="canEnterManualSelection"
            [(ngModel)]="canEnterManualSelection">
        </div>
      </div>
      <!-- <div *ngIf="purchaseOrdersActive" class="col-sm-6">
        <div class="float-end">
          <label class="form-label" for="addendumCode">Purchase Order Code:</label>
          <input type="text" class="form-control-sm addendumCode ms-1" name="addendumCode" ngModel id="addendumCode"
            [(ngModel)]="addendumCode">
        </div>
      </div> -->
    </div>

    <button class="btn btn-sm btn-primary" (click)="closeOk(false)">
      Update
    </button>
    <button *ngIf="jobLineSetupAdmin" class="btn btn-sm btn-secondary ms-3" (click)="closeOk(true)">
      Show/Hide by linking to another line
    </button>
  </form>
</div>
