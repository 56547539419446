import { UserTypeEnum } from './../../../dtos/user-type.enum';
import { NotificationService } from './../../../services/notification.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '../../../dtos/user';
import { AuthApiService } from '../../../services/felixApi/auth-api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'js-add-client-user',
  templateUrl: './add-client-user.component.html',
  styleUrls: ['./add-client-user.component.css']
})
export class AddClientUserComponent implements OnInit, OnDestroy {
  @Input() firstName: string;
  @Input() lastName: string;

  subscriptions: Subscription[] = [];
  userForm: User;
  loading = false;

  constructor(private authApiService: AuthApiService,
    private _activeModal: NgbActiveModal,
    private notiService: NotificationService
  ) { }

  ngOnInit() {
    this.userForm = new User('', 0);
    this.userForm.email = '';
    this.userForm.firstName = this.firstName;
    this.userForm.lastName = this.lastName;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  close() {
    this._activeModal.dismiss();
  }

  addUser() {
    if (this.userForm.email !== '') {
      this.loading = true;
      this.userForm.userTypeId = UserTypeEnum.Client;
      this.authApiService.addUserToComp(this.userForm).subscribe({
        next: (res) => {
          this._activeModal.close(res);
        },
        error: (err) => {
          if (err.status === 422 && err.error?.message?.indexOf('already exists') > -1) {
            this.notiService.showInfo('User is already added to this company');
          } else {
            this.notiService.notify(err);
          }
          this.loading = false;
        }
      });
    }
  }
}
