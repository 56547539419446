<div class="modal-header">
  <h2 class="modal-title">Select trades to apply to this job</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="card">
    <div class="card-header">

      <div *ngIf="!job.isActive">Warning: This job is not active. No access will be given.</div>

      <!-- filter field -->
      <label for="filterText" class="col-form-label">
        <input id="filterText" class="form-control form-control-sm" type="text" placeholder="Search..." [(ngModel)]="filterText"
          (keyup)="setUserCheckedField()" />
      </label>

    </div>

    <div class="card-body">

      <table class="table table-sm">
        <thead class="rowclass">
          <tr>
            <th scope="col" class="col1class">Select</th>
            <th scope="col" class="col2class">Name</th>
            <th scope="col" class="col2class">Email</th>
          </tr>
        </thead>

        <!-- Image Loading Spinner -->
        <js-loading-spinner *ngIf="loading"></js-loading-spinner>

        <tbody *ngIf='associates && associates.length' class="rowclass">
          <tr *ngFor='let user of shownUsers' class="rowclass">

            <ng-container>
              <td>
                <mat-checkbox [(ngModel)]="user.checked" (click)="checkUser(user)"></mat-checkbox>
              </td>
              <td>{{user.firstName}} {{user.middleName}} {{user.lastName}}</td>
              <td>{{user.email}}</td>
            </ng-container>

          </tr>
        </tbody>
      </table>

      <button *ngIf="numberOfPages !== 0" class="btn btn-sm btn-outline-primary btnline" [disabled]="currentPage === 0"
        (click)="previousPage()">Previous
        Page</button>
      <button *ngIf="numberOfPages !== 0" class="btn btn-sm btn-outline-primary btnline" [disabled]="currentPage === numberOfPages"
        (click)="nextPage()">Next
        Page</button>

    </div>

    <div class="card-footer">
      <button class="btn btn-sm btn-primary" (click)="cancel()" [disabled]="updatingNumber != 0">Close</button>
      <a *ngIf="updatingNumber === 0" class="btn btn-sm btn-secondary ms-3" [href]="emailString">Create email to selected trades <span *ngIf="filterText !== ''">(filtered)</span></a>
    </div>
  </div>
</div>
