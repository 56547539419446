import { GlobalService } from './../../services/global.service';
import { JobService } from './../../services/felixApi/job.service';
import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobSearchModalComponent } from '../job-search-modal.component';
import { JobSearchTypeEnum } from '../../dtos/job-search-type.enum';
import { IJob } from '../../dtos/job';

@Component({
  selector: 'js-job-number-small',
  templateUrl: './job-number-small.component.html',
  styleUrls: ['./job-number-small.component.scss']
})
export class JobNumberSmallComponent implements OnChanges {
  @Input() jobNumber: string;
  @Input() saveJobNumber: boolean;
  @Input() defaultToTemplatesOnSearch: boolean;

  @Output() refreshJobNumber: EventEmitter<string> =
    new EventEmitter<string>();

  jobString: string;
  subscriptions: Subscription[] = [];

  constructor(
    private jobService: JobService,
    private globalService: GlobalService,
    private modalService: NgbModal
  ) { }

  ngOnChanges(): void {
    if (this.jobNumber) {
      const currentJob = this.jobService.jobs?.find(i => i.jobNumber === this.jobNumber);
      if (currentJob) {
        this.setJob(currentJob);
      } else {
        this.getJob();
      }
    }
  }

  getJob() {
    this.jobString = '';
    this.subscriptions = this.subscriptions.concat(
      this.jobService.getJob(this.jobNumber)
        .subscribe({
          next: (currentJob) => {
            if (currentJob) {
              this.setJob(currentJob);
            } else {
              this.jobString = 'Job Not Found';
              this.refreshJobNumber.emit(null);
            }
          },
          error: () => {
            this.jobString = 'Job Not Found';
            this.refreshJobNumber.emit(null);
          }
        })
    );
  }

  setJob(job: IJob) {
    this.jobNumber = job.jobNumber;
    this.jobString = this.globalService.getJobString(job, true);
    if (this.saveJobNumber) {
      this.globalService.setCurrentJob(this.jobNumber);
      this.jobService.currentJob = job;
    }

    this.refreshJobNumber.emit(this.jobNumber);
  }

  clearJob() {
    this.jobNumber = '';
    this.jobString = '';
    this.refreshJobNumber.emit(this.jobNumber);
  }

  jobSearch() {
    const modalRef = this.modalService.open(JobSearchModalComponent, { windowClass: 'modal-search' });
    if (this.defaultToTemplatesOnSearch) {
      modalRef.componentInstance.searchType = JobSearchTypeEnum.Templates;
    } else {
      modalRef.componentInstance.searchType = JobSearchTypeEnum.AllJobs;
    }

    modalRef.result.then((jobNumber) => {
      if (jobNumber) {
        const currentJob = this.jobService.jobs.find(i => i.jobNumber === jobNumber);
        if (currentJob) {
          this.setJob(currentJob);
        } else {
          this.jobNumber = jobNumber;
          this.getJob();
        }
      }
    });
  }

}
