import { Component, OnInit, OnDestroy } from '@angular/core';
import { InvoiceService } from '../services/felixApi/invoice.service';
import { Subscription } from 'rxjs';
import { Invoice } from '../dtos/invoice';
import { NotificationService } from '../services/notification.service';
import { Router } from '@angular/router';
import { ShowPdfComponent } from '../shared/show-pdf.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../services/global.service';
import { IJobItemAttachment } from '../dtos/job-item-attachment';
import { AttachmentTypeEnum } from '../dtos/attachment-type.enum';
import { ImageWindowComponent } from '../shared/image-window.component';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'js-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  loading = true;
  invoices: Invoice[];
  errorMessage: string;
  invoiceAttachment: IJobItemAttachment;
  pdf: ArrayBuffer | SharedArrayBuffer;
  vendorName: string;
  validVendor = false;
  isWideScreenWidth = false;

  constructor(
    private _invoiceService: InvoiceService,
    private router: Router,
    private auth: AuthService,
    private modalService: NgbModal,
    private _globalService: GlobalService,
    private notiService: NotificationService) { }

  ngOnInit() {
    this.vendorName = this.auth.getCurrentUser().vendorName;

    if (!this.vendorName || this.vendorName === '') {
      this.errorMessage = 'No vendor specified for user';
      this.loading = false;
    } else {
      this.validVendor = true;

      this.setIsWideScreenWidth();
      this.subscribeToWidthChanges();
      this.getInvoices();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  subscribeToWidthChanges() {
    this.subscriptions = this.subscriptions.concat(
      this._globalService.innerWidthChanged.subscribe((width: number) => {
        this.setIsWideScreenWidth();
      })
    );
  }

  setIsWideScreenWidth() {
    if (this._globalService.innerWidth > 500) {
      this.isWideScreenWidth = true;
    } else {
      this.isWideScreenWidth = false;
    }
  }

  getInvoices() {
    // get the loaded invoices for the related users vendor
    this.subscriptions = this.subscriptions.concat(
      this._invoiceService.getInvoices()
        .subscribe({
          next: (invoices) => {
            this.invoices = invoices;
            if (!this.invoices.length) {
              this.errorMessage = 'No invoices to show';
            }
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }})
    );
  }

  addInvoice() {
    // add a new invoice
    this.router.navigate(['invoice']);
  }

  openInvoicePDF(invoiceId: number) {
    // get the invoice PDF
    this.loading = true;
    this.subscriptions = this.subscriptions.concat(
      this._invoiceService.getInvoiceAttachment(invoiceId).subscribe({
        next: (invoiceAttachment) => {
          this.invoiceAttachment = invoiceAttachment;
          this.loading = false;

          if (this.invoiceAttachment.attachmentTypeId === AttachmentTypeEnum.PDF) {
            const modalRef1 = this.modalService.open(ShowPdfComponent, { windowClass: 'modal-pdf' });
            modalRef1.componentInstance.pdfReport = {
              pdfReport: this.invoiceAttachment.attachment,
              pdfReportName: this.invoiceAttachment.attachmentName
            };

            modalRef1.result.then((returnResult1) => {
            }, (reason) => {
            });
          } else {
            // show image
            const modalRef2 = this.modalService.open(ImageWindowComponent, { windowClass: 'modal-pdf' });
            modalRef2.componentInstance.attachment = this.invoiceAttachment.attachment;
            modalRef2.componentInstance.attachmentTypeId = this.invoiceAttachment.attachmentTypeId;

            modalRef2.result.then((returnResult1) => {
            }, (reason) => {
            });
          }
        },
        error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }})
    );
  }
}
