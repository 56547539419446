import { Observable, throwError as observableThrowError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { GlobalService } from '../global.service';
import { Company } from '../../dtos/company';
import { CompanyConfiguration } from '../../dtos/company-configuration';
import { CompanyActivity } from '../../dtos/company-activity';
import { MarginTypeEnum } from '../../dtos/margin-type.enum';
import { CompanyMargin } from '../../dtos/company-margin';
import { HttpService } from '../http.service';
import { IJobDocAttachment } from '../../dtos/job-doc-attachment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  authCompanies: Company[];
  marginTypeEnum = MarginTypeEnum;
  companyFeesMargins: CompanyMargin[];
  activities: CompanyActivity[] = [];
  currentCompanyForActivitiesId: string;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  setCurrentCompanies(comps: Company[]) {
    this.authCompanies = comps;
  }

  getCurrentCompanies() {
    return this.authCompanies;
  }

  getCompanies() {
    return this.http.get<Company[]>(this.globalService.getApiUrl() + '/companies', this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getCompaniesByUserEmail(email: string) {
    return this.http.get<Company[]>(this.globalService.getApiUrl() + '/email-addresses/' + email + '/authorised-companies',
      this.httpService.getHttpOptions());
  }

  getCompany(id) {
    return this.http.get<Company>(this.globalService.getApiUrl() + '/companies/' + id, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  updateComp(company: Company) {
    const url = this.globalService.getApiUrl() + '/companies/' + company.id;
    return this.http.patch(url, JSON.stringify(company), this.httpService.getHttpOptions());
  }

  addComp(company: Company) {
    const url = this.globalService.getApiUrl() + '/companies';
    return this.http.post(url, JSON.stringify(company), this.httpService.getHttpOptions());
  }

  getCompanyConfigurations() {
    return this.http.get<CompanyConfiguration[]>(this.globalService.getApiUrl() + '/company-configurations',
      this.httpService.getHttpOptions()).pipe(catchError(this.handleError));
  }

  getCompanyConfigurationById(id: number) {
    return this.http.get<CompanyConfiguration>(this.globalService.getApiUrl() + '/company-configuration/' + id,
      this.httpService.getHttpOptions()).pipe(catchError(this.handleError));
  }

  getCompanyActivities(activeOnly: boolean = true, useCache: boolean = true) {
    if (useCache && this.currentCompanyForActivitiesId === this.globalService.getCurrentCompanyId()) {
      return of(this.activities);
    } else {
      const url = this.globalService.getApiUrl() + '/activities?activeOnly=' + activeOnly;
      return this.http.get<CompanyActivity[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.activities = res;
          this.currentCompanyForActivitiesId = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getCompanyActivitiesForClient(jobId: number) {
    let url = this.globalService.getApiUrl() + '/activities?activeOnly=true&jobId=' + jobId;
    return this.http.get<CompanyActivity[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getCompanyActivityById(id: number) {
    const url = this.globalService.getApiUrl() + '/activities/' + id;
    return this.http.get<CompanyActivity>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getAllCompanyFeesMargins() {
    if (this.companyFeesMargins) {
      return of(this.companyFeesMargins);
    } else {
      const url = this.globalService.getApiUrl() + '/company-margins?marginTypeId=' + this.marginTypeEnum.insuranceAndFees + '&activeOnly=true';
      return this.http.get<CompanyMargin[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => this.companyFeesMargins = res),
        catchError(this.handleError));
    }
  }

  getDivisionLogo(id: number): Observable<IJobDocAttachment> {
    return this.http.get<IJobDocAttachment>(this.globalService.getApiUrl() + '/division-attachments/' + id,
      this.httpService.getHttpFileOptions()).pipe(
        catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
