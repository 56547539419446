import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Variation } from '../../dtos/variation';
import { VariationInvoiceSummary } from '../../dtos/variation-invoice-summary';
import { PDFReports } from '../../dtos/pdf-report';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { SelectedIDs } from '../../dtos/selectedIDs';
import { HideSection } from '../../dtos/hide-section';
import { TemplateTask } from '../../dtos/template-task';
import { HttpService } from '../http.service';
import { VariationWorkFlow } from '../../dtos/variation-workflow';

@Injectable()
export class VariationService {
  currentVariations: Variation[] = [];

  hiddenSectionsInVariation: HideSection[] = []; // to allow hiding of specific sections in a variation type

  variationTypes = [
    { text: 'Sales - Variation', id: 11 },
    { text: 'Sales - Siteworks', id: 12 },
    { text: 'Sales - Office', id: 13 },
    { text: 'Pre-Contract - Variation', id: 21 },
    { text: 'Pre-Contract - Siteworks', id: 22 },
    { text: 'Pre-Contract - Office', id: 23 },
    { text: 'Post-Contract - PreConstruction', id: 1 },
    { text: 'Post-Contract - Construction', id: 2 },
    { text: 'Post-Contract - Office', id: 3 },
    { text: 'Post-Contract - Final Account', id: 4 }
  ];
  variationInvoices: VariationInvoiceSummary[];
  variations: Variation[];
  variationWorkFlowsCompany: number;
  variationWorkFlows: VariationWorkFlow[] = [];
  taskForPreContractVariationCompany: number;
  taskForPreContractVariation: TemplateTask;
  taskForSalesVariation: TemplateTask;
  taskForSalesVariationCompany: number;
  taskForPostContractVariation: TemplateTask;
  taskForPostContractVariationCompany: number;

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  getVariations(jobNum: string = null): Observable<Variation[]> {
    let url = this.globalService.getApiUrl();
    if (jobNum) {
      url += '/jobs/' + jobNum + '/variations';
    } else {
      url += '/jobs/' + this.globalService.getCurrentJob() + '/variations';
    }

    return this._http.get<Variation[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.variations = res;
      }),
      catchError(this.handleError));
  }

  getOpenVariations(jobId: number): Observable<Variation[]> {
    let url = this.globalService.getApiUrl();
    url += '/job-variations/open?jobId=' + jobId;

    return this._http.get<Variation[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getVariation(id): Observable<Variation[]> {
    const url = this.globalService.getApiUrl() + '/jobs/' + this.globalService.getCurrentJob() + '/variations?variationNumber=' + id;
    return this._http.get<Variation[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  addVariation(itm: any): Observable<Variation> {
    const url = this.globalService.getApiUrl() + '/jobs/' + this.globalService.getCurrentJob() + '/variations';
    return this._http.post<Variation>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  updateVariation(itm: any, adminOverride: boolean = false) {
    let url = this.globalService.getApiUrl() + '/job-variations/' + itm.id;
    if (adminOverride) {
      url += '?adminOverride=true';
    }

    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  updateVariationByClient(itm: any) {
    const url = this.globalService.getApiUrl() + '/job-variations/' + itm.id + '/by-client';
    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteVariation(itmId: number, deleteLines: boolean) {
    const url = this.globalService.getApiUrl() + '/job-variations/' + itmId + '?deleteLines=' + deleteLines;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  getVariationsReport(selectedIDs: SelectedIDs, includeTestJobs: boolean, includeZeroValueVOs: boolean): Observable<Variation[]> {
    const url = this.globalService.getApiUrl() + '/job-variations/variations-report?includeTestJobs=' + includeTestJobs
      + '&includeZeroValueVOs=' + includeZeroValueVOs;

    return this._http.post<Variation[]>(url, JSON.stringify(selectedIDs), this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getContractSummaryReport(printVONumbers: boolean, printFromContract: boolean): Observable<PDFReports> {
    let url = this.globalService.getApiUrl() + '/job-variations/' + this.globalService.getCurrentJob() + '/summary-report';

    if (printVONumbers) {
      url += '?printVONumbers=true&printFromContract=' + printFromContract;
    } else {
      url += '?printFromContract=' + printFromContract;
    }
    return this._http.get<PDFReports>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  private getPostContractVariationTask(useCache: boolean): Observable<TemplateTask> {
    if (useCache && this.taskForPostContractVariation && this.taskForPostContractVariationCompany === this.globalService.getCurrentCompany().id) {
      return of(this.taskForPostContractVariation);
    }
    const url = this.globalService.getApiUrl() + '/template-tasks/created-by-variation';
    return this._http.get<TemplateTask>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.taskForPostContractVariation = res;
        this.taskForPostContractVariationCompany = this.globalService.getCurrentCompany().id;
      }),
      catchError(this.handleError));
  }

  private getSalesVariationTask(useCache: boolean): Observable<TemplateTask> {
    if (useCache && this.taskForSalesVariation && this.taskForSalesVariationCompany === this.globalService.getCurrentCompany().id
    ) {
      return of(this.taskForSalesVariation);
    }
    const url = this.globalService.getApiUrl() + '/template-tasks/created-by-sales-variation';
    return this._http.get<TemplateTask>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.taskForSalesVariation = res;
        this.taskForSalesVariationCompany = this.globalService.getCurrentCompany().id;
      }),
      catchError(this.handleError));
  }

  private getPreContractVariationTask(useCache: boolean): Observable<TemplateTask> {
    if (useCache && this.taskForPreContractVariation && this.taskForPreContractVariationCompany === this.globalService.getCurrentCompany().id
    ) {
      return of(this.taskForPreContractVariation);
    }
    const url = this.globalService.getApiUrl() + '/template-tasks/created-by-precontract-variation';
    return this._http.get<TemplateTask>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.taskForPreContractVariation = res;
        this.taskForPreContractVariationCompany = this.globalService.getCurrentCompany().id;
      }),
      catchError(this.handleError));
  }

  getVariationWorkflows(useCache: boolean): Observable<VariationWorkFlow[]> {
    if (useCache && this.variationWorkFlowsCompany === this.globalService.getCurrentCompany().id
      && this.variationWorkFlows && this.variationWorkFlows.length) {
      return of(this.variationWorkFlows);
    } else {
      return this._http.get<VariationWorkFlow[]>(this.globalService.getApiUrl() +
        '/variation-work-flows', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.variationWorkFlows = res;
            this.variationWorkFlowsCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }

  // get all variation tasks
  getVariationTasks(): Observable<TemplateTask> {
    return forkJoin(
      [
        this.getPostContractVariationTask(true),
        this.getSalesVariationTask(true),
        this.getPreContractVariationTask(true),
        this.getVariationWorkflows(true)
      ]
    )
      .pipe(map(
        ([data]) => {
          return data;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getVariationFromCurrentList(id: number): Observable<Variation> {
    const varn = this.currentVariations.filter(i => i.id === id);
    if (varn[0]) {
      return of(varn[0]);
    }

    return null;
  }

  getVariationInvoiceSummary(jobId: number): Observable<VariationInvoiceSummary[]> {
    const url = this.globalService.getApiUrl() + '/job-variations/' + jobId + '/invoice-summary';
    return this._http.get<VariationInvoiceSummary[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.variationInvoices = res;
      }),
      catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
