<div class="modal-header">
  <h4 class="modal-title">Save items to a recipe</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismissModal()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="card">

  <div class="card-body">

    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <form *ngIf="!loading" [formGroup]="recipeForm">
      <!-- get class -->
      <dx-tree-list #treeList id="tree-list" [dataSource]="treeListDataSource" keyExpr="id"
        parentIdExpr="recipeParentId" [remoteOperations]="false" [showRowLines]="false" [showColumnLines]="false"
        [showBorders]="true" [columnAutoWidth]="true" [rootValue]=null [expandedRowKeys]="[0]" [wordWrapEnabled]="true"
        [(selectedRowKeys)]="recipeParentIds" [ngStyle]="{'max-height': 'calc(' + maxTreeHeight + 'px)'}">

        <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
        <dxo-editing mode="row" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-scrolling mode="standard"></dxo-scrolling>
        <dxo-paging [enabled]="false"></dxo-paging>

        <dxi-column dataField="description" caption="Class">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column dataField="orderNumber" [visible]="false" sortOrder="asc"></dxi-column>

      </dx-tree-list>

      <br>

      <!-- get recipe code -->
      <div class="form-group row">
        <label class="col-sm-3 form-label righttext" for="recipeCode">Recipe Code:</label>
        <input class="form-control-sm col-sm-3" id="recipeCode" type="text" formControlName="recipeCode" />
      </div>

      <!-- get recipe name -->
      <div class="form-group row">
        <label class="col-sm-3 form-label righttext" for="description">Description:</label>
        <input class="form-control-sm col-sm-8" id="description" type="text" formControlName="description" />
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="runCopy()"
      [disabled]="loading || recipeForm.invalid || !recipeParentIds || !recipeParentIds[0]">Run Copy</button>
  </div>
</div>
