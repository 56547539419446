import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VariationService } from '../../../services/felixApi/variation.service';
import { VariationStatusEnum } from '../../../dtos/variation-status.enum';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../../services/notification.service';
import { EmailService } from '../../../services/felixApi/email.service';
import { JobItemService } from '../../../services/felixApi/job-item.service';

@Component({
  selector: 'js-approve-variation',
  templateUrl: './approve-variation.component.html',
  styleUrls: ['./approve-variation.component.scss']
})
export class ApproveVariationComponent implements OnInit, OnDestroy {
  @Input() variationId: number;

  subscriptions: Subscription[] = [];
  updaterecord: any;
  loading = false;
  variationStatus = VariationStatusEnum;

  constructor(private _activeModal: NgbActiveModal,
    private notiService: NotificationService,
    private _emailService: EmailService,
    public _jobItemService: JobItemService,
    private _variationService: VariationService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  cancel() {
    this._activeModal.dismiss();
  }

  approveVariation() {
    this.loading = true;
    // edit a variation so save it.
    this.updaterecord = { id: this.variationId, statusId: this.variationStatus.Approved };

    this.subscriptions = this.subscriptions.concat(
      this._variationService.updateVariation(this.updaterecord).subscribe({
        next: () => {
          this.sendEmailNotification();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  sendEmailNotification() {
    this.subscriptions = this.subscriptions.concat(
      this._emailService.sendVariationStatusEmail(this.variationId).subscribe({
        next: () => {
          this.notiService.showSuccess('Variation successfully submitted');
          this._activeModal.close();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }
}
