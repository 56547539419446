import { IntegrationService } from './../../services/felixApi/integration.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { JobService } from '../../services/felixApi/job.service';
import { GlobalService } from '../../services/global.service';
import { NotificationService } from '../../services/notification.service';
import CustomStore from 'devextreme/data/custom_store';
import { JobIntegrationEnum } from '../../dtos/job-integration';
import { IJob } from '../../dtos/job';

@Component({
  selector: 'js-report-active-campaign',
  templateUrl: './report-active-campaign.component.html',
  styleUrls: ['./report-active-campaign.component.scss']
})
export class ReportActiveCampaignComponent implements OnInit {

  subscriptions: Subscription[] = [];
  gridHeight: number;
  dataSource: CustomStore;
  jobs: IJob[];

  constructor(private _activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private integrationService: IntegrationService,
    private jobService: JobService,
    private notiService: NotificationService) {
    this.calcContractName = this.calcContractName.bind(this);
  }

  ngOnInit() {
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerWidthChanged.subscribe(() => {
        setTimeout(() => {
          this.setHeight();
        }, 500); // wait for iPhone
      })
    );
    this.setHeight();
    this.getJobs();
  }

  setHeight() {
    this.gridHeight = this.globalService.innerHeight - 300;
  }

  getJobs() {
    this.subscriptions = this.subscriptions.concat(
      this.jobService.getJobsByAddress('')
        .subscribe({
          next: (jobs) => {
            this.jobs = jobs;
            this.setUpDataSet();
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  setUpDataSet() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.integrationService.getJobIntegrations(JobIntegrationEnum.ActiveCampaign).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  calcContractName(data) {
    return this.jobs.find(i => i.id === data.jobId)?.contractName;
  }

  close() {
    this._activeModal.close(null);
  }
}
