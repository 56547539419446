<div class="modal-header">
  <h4 class="modal-title" placement="bottom">Active Campaign Links</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <dx-data-grid class="mt-3" #treeList id="tree-list" [dataSource]="dataSource" [height]="gridHeight"
    [remoteOperations]="false" [showRowLines]="false" [showColumnLines]="true" [showBorders]="true"
    [columnAutoWidth]="false" [wordWrapEnabled]="true">

    <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-paging [enabled]="false"> </dxo-paging>

    <dxi-column dataField="jobId" caption="Job" dataType="number" sortOrder="asc" [width]="200">
      <dxo-lookup [dataSource]="jobs" valueExpr="id" displayExpr="jobNumber">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="contractName" [calculateCellValue]="calcContractName">
    </dxi-column>
  </dx-data-grid>
</div>
