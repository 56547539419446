<div class="modal-header">
  <h2 class="modal-title">{{modalHeading}}</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form *ngIf="!loading" [formGroup]="editForm" (ngSubmit)="update()">

    <!-- first use a radio button to select the type of line - if jobItemAboveId then we are at the top and just want headings -->
    <div *ngIf="(addEditDelete === addEditDeleteEnum.Add && jobItemAboveId)" class="row">
      <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic">
        <label ngbButtonLabel class="btn btn-sm btn-primary">
          <input ngbButton type="radio" [value]="itemTypeEnum.Heading" formControlName="itemTypeId">
          Heading
        </label>
        <label ngbButtonLabel class="btn btn-sm btn-primary">
          <input ngbButton type="radio" [value]="itemTypeEnum.Detail" formControlName="itemTypeId">
          Detail
        </label>
        <label ngbButtonLabel class="btn btn-sm btn-primary">
          <input ngbButton type="radio" [value]="itemTypeEnum.Note" formControlName="itemTypeId">
          Note
        </label>
      </div>
    </div>

    <!-- if in a variation first use a radio button to select where this line goes -->
    <div *ngIf="(addEditDelete === addEditDeleteEnum.Add && indexNo === 0)" class="row radioclass">
      <label class="marginLeft30">
        <input type="radio" formControlName="addAboveOrAfter" value="After" name="addAboveOrAfter">
        Add below this line
      </label>
      <label class="marginLeft30">
        <input type="radio" formControlName="addAboveOrAfter" value="Above" name="addAboveOrAfter">
        Add above this line
      </label>
    </div>

    <div class="row">
      <label class="col-sm-2 form-label righttext" for="itemDescription">Description:</label>
      <input *ngIf="indexNo === -1 || jobitem?.changeTypeId !== changeTypeEnum.Delete" class="form-control-sm col-sm-9"
        id="itemDescription" type="text" formControlName="itemDescription" />
      <input *ngIf="indexNo !== -1 && jobitem?.changeTypeId === changeTypeEnum.Delete" class="form-control-sm col-sm-9"
        id="itemDescription" type="text" formControlName="itemDescription" readonly />
    </div>

    <div *ngIf="indexNo === -1 || jobitem?.changeTypeId !== changeTypeEnum.Delete">
      <div *ngIf="!variationId && !this.jobitem.changedByJobVarId" class="row mt-2">
        <label class="col-sm-10 form-label righttext" for="isSetUpLine">Hide when job is
          locked (Set up section)?
          <input class="marginTop5 ms-1" type="checkbox" id="isSetUpLine" formControlName="isSetUpLine" tabindex="-1">
        </label>
      </div>

      <div *ngIf="variationId && jobitem?.changedByVOId === this.variationId" class="row mt-1">
        <label class="col-sm-10 form-label righttext" for="itemNotTaken">Item NOT taken by client?
          <input class="marginTop5 ms-1" type="checkbox" id="itemNotTaken" formControlName="itemNotTaken">
        </label>
      </div>

      <div *ngIf="!editForm.get('isSetUpLine').value" class="row mt-1">
        <label class="col-sm-10 form-label righttext" for="isDoNotPrint">Do NOT print on
          {{addendumName}}?
          <input class="marginTop5 ms-1" type="checkbox" id="isDoNotPrint" formControlName="isDoNotPrint" tabindex="-1">
        </label>
      </div>

      <!-- Commenting this feature out for now as we now can have headings that do not print -->
      <!-- <div *ngIf="variationId && !editForm.get('isSetUpLine').value" class="row">
        <label class="col-sm-10 form-label righttext" for="isHiddenFromMaster">Do NOT merge this into the
          Addendum?</label>
        <input class="marginTop5" type="checkbox" id="isHiddenFromMaster" formControlName="isHiddenFromMaster">
      </div> -->
    </div>

  </form>
</div>

<div *ngIf="!isDeletePressed && !loading" class="modal-footer">
  <button *ngIf="jobitem?.changedByVOId === variationId && jobitem?.changeTypeId === changeTypeEnum.Add" type="button"
    class="btn btn-sm btn-secondary marginleft15" (click)="moveItem()">Move</button>
  <button *ngIf="!variationId && jobitem.itemTypeId === itemTypeEnum.Heading
    && jobitem && !jobitem.jobItemAboveId && jobitem.originalItemTypeId !== jobVarTypeEnum.JobVarItem" type="button"
    class="btn btn-sm btn-secondary modal2ndButton" (click)="setCategories()">Categories</button>
  <button *ngIf="!variationId && jobitem.itemTypeId === itemTypeEnum.Heading
  && jobitem && !jobitem.jobItemAboveId && jobitem.originalItemTypeId !== jobVarTypeEnum.JobVarItem" type="button"
    class="btn btn-sm btn-secondary modal2ndButton" (click)="setHideSection()">Show in Variation</button>
  <button *ngIf="(addEditDelete !== addEditDeleteEnum.Add) && !variationId
  && jobLineSetupAdmin && jobitem.originalItemTypeId !== jobVarTypeEnum.JobVarItem" type="button"
    class="btn btn-sm btn-secondary modal2ndButton" (click)="setupTags()">Show/Hide by linking to another line</button>
  <button
    *ngIf="canDelete && (addEditDelete !== addEditDeleteEnum.Add) && !(jobitem?.changedByVOId === variationId && jobitem?.changeTypeId !== changeTypeEnum.Add)"
    type="button" class="btn btn-sm btn-danger" (click)="delete()">Delete</button>
  <button
    *ngIf="(addEditDelete !== addEditDeleteEnum.Add) && jobitem?.changedByVOId === variationId && jobitem?.changeTypeId !== changeTypeEnum.Add"
    type="button" class="btn btn-sm btn-danger" (click)="delete()">Revert to Original</button>
  <button *ngIf="jobitem?.changedByVOId !== variationId || jobitem?.changeTypeId !== changeTypeEnum.Delete"
    type="button" class="btn btn-sm btn-primary" (click)="update()"
    [disabled]='editForm.pristine'>{{modalButton}}</button>
</div>

<div *ngIf="isDeletePressed && !loading" class="modal-footer">
  <span *ngIf="hasChildItems" class="me-2">
    <dx-check-box text="Delete child items" [rtlEnabled]="true" [(value)]="confirmDeleteChildren"></dx-check-box>
  </span>

  <button type="button" class="btn btn-sm btn-danger leftalign" (click)="delete()"
    [disabled]="hasChildItems && !confirmDeleteChildren">Confirm Delete</button>
</div>

<!-- Loading Spinner -->
<js-loading-spinner *ngIf="loading"></js-loading-spinner>
