<div class="modal-header">
  <h4 class="modal-title"><span *ngIf="!isHubSpotActive">Active Campaign</span><span *ngIf="isHubSpotActive">HubSpot</span></h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <div *ngIf="loading">Loading records from <span *ngIf="!isHubSpotActive">Active Campaign</span><span *ngIf="isHubSpotActive">HubSpot</span>. Please wait...</div>

  <js-loading-spinner *ngIf="loadingData"></js-loading-spinner>

  <div *ngIf="!loadingData" class="m-auto">
    <dx-data-grid [dataSource]="dataSource" [allowColumnReordering]="false" [allowColumnResizing]="true"
      [height]="gridHeight" [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true"
      [wordWrapEnabled]="true" [focusedRowEnabled]="true" [(focusedRowKey)]="selectedRecord"
      [autoNavigateToFocusedRow]="true" [hoverStateEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)">

      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
      <dxo-scrolling mode="virtual"></dxo-scrolling>
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-selection mode="single"></dxo-selection>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="true"></dxo-header-filter>

      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-grouping #expand [autoExpandAll]="false" [contextMenuEnabled]="false"></dxo-grouping>

      <dxi-column dataField="id" [visible]="false"> </dxi-column>
      <dxi-column dataField="stage" sortOrder="asc" [visible]="false"> </dxi-column>
      <dxi-column dataField="stageTitle" caption="Stage"> </dxi-column>
      <dxi-column dataField="title"> </dxi-column>
      <dxi-column dataField="name" caption="Main Contact"> </dxi-column>
      <dxi-column dataField="description" [minWidth]="descWidth"> </dxi-column>
      <dxi-column dataField="repName" [caption]="salesRepDescription" [groupIndex]="0" sortOrder="asc"> </dxi-column>
      <dxi-column dataField="cdate" caption="Created" dataType="date" [width]="110" sortOrder="asc"> </dxi-column>

    </dx-data-grid>

    <dx-button text="Select" class="mt-1" type="default" stylingMode="default" (onClick)="select(true)">
    </dx-button>

    <dx-button *ngIf="jobId" text="Link Only" class="mt-1 ms-3" type="default" stylingMode="outlined"
      (onClick)="select(false)">
    </dx-button>
  </div>
</div>

<div class="modal-footer">
</div>
