import { EstimatingService } from './../../services/felixApi/estimating.service';
import { UserService } from './../../services/felixApi/user.service';
import { ConfigurationEnum } from './../../dtos/configuration-enum';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { JobVarItemService } from '../../services/felixApi/job-var-item.service';
import { JobItemService } from '../../services/felixApi/job-item.service';
import { GlobalService } from '../../services/global.service';
import { OptionListService } from '../../services/felixApi/option-list.service';
import { IJobVarItem } from '../../dtos/job-var-item';
import { IJobItem } from '../../dtos/job-item';
import { ChangeTypeEnum } from '../../dtos/change-type.enum';
import { JobVarTypeEnum } from '../../dtos/job-var-type.enum';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CostTypeEnum } from '../../dtos/cost-type.enum';
import { ItemTypeEnum } from '../../dtos/item-type.enum';
import { SelectionTypeEnum } from '../../dtos/selection-type.enum';
import { IOptionListHeader } from '../../dtos/option-list-header';
import { OptionColourEnum } from '../../dtos/option-colour.enum';
import { ConnectItemsModalComponent } from './connect-items-modal.component';
import { SelectedJobItems } from '../../dtos/selectedJobItemIds';
import { VariationTypeEnum } from '../../dtos/variation-type.enum';
import { PatchReturnTypeEnum } from '../../dtos/patchReturnType.enum';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { JobVarCalc } from '../../dtos/job-var-calc';
import { AuthService } from '../../services/auth.service';
import { VariationStatusEnum } from '../../dtos/variation-status.enum';
import { EstimatingModalComponent } from './estimating-modal/estimating-modal.component';
import { MoveItemComponent } from './move-item/move-item.component';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'js-variation-edit-modal',
  templateUrl: './variation-edit-modal.component.html',
  styleUrls: ['./variation-edit-modal.component.scss']
})
export class VariationEditModalComponent implements OnInit, OnDestroy {
  @Input() jobitem: IJobItem;
  @Input() variationId: number;
  @Input() variationNumber: number;
  @Input() isParentHiddenFromMaster: boolean; // this is passed from the heading above so we will default if set and make read only
  @Input() selectionsAdmin: boolean;
  @Input() variationType: number;
  @Input() calledFromDropDown: boolean; // will be true if called from the dropdown in the detail-variation component

  COMPONENT_NAME = 'variation-edit-modal';
  subscriptions: Subscription[] = [];
  VariationPriceEstimatingEnabled = 13; // config type for allowing estimating

  colours = [
    { name: 'Standard', id: 0 },
    { name: 'Red', id: 1 },
    { name: 'Green', id: 2 }
  ];

  prevCostTypes = [
    { name: 'Priced', id: 0 },
    { name: 'Note', id: 1 },
    { name: 'No Charge', id: 2 }
  ];

  optionColourEnum = OptionColourEnum; // import the colour enum for the HTML

  errorMessage = '';
  editForm: UntypedFormGroup;
  updatedItem: any;
  updatedItemCalcs: any; // for updating the jobvarcalc records
  isOriginalSelectionInOptionList = true;
  readOnly: boolean;  // are the input fields read only
  treeOptionNodes: any[] = [];
  newSelection: string;
  selectedOptionId = null;
  modalHeading: string;
  attachmentExists: boolean;
  alreadyHaveVO: boolean;
  canDelete = false;
  canRevert = false;
  canMarkDeleted = false;
  changeTypeEnum = ChangeTypeEnum;
  jobVarTypeEnum = JobVarTypeEnum;
  ItemType = ItemTypeEnum;
  costTypeEnum = CostTypeEnum;
  SelectionType = SelectionTypeEnum;
  costType: string;
  loading = true;
  gotOptionList = false;
  optionList: IOptionListHeader[] = []; // for dropdown options
  optionListTmp: IOptionListHeader[] = [];
  priceUpdate: number;
  warningNote = ''; // note that an option selected in a modal can have a warning
  deletePressed = false; // has the user clicked the delete button - need to ask for a delete note
  connectedItemDesc: string; // get the description for the connected item.
  connectedToHeadingDesc: string; // the heading above the item this item is connected to
  connectedButton = 'Connect Items';
  itemsToSetDto: SelectedJobItems;
  linkedItemDescription: string;
  oneJobItem: IJobItem[] = [];
  updatedItems: IJobVarItem[] = [];
  priceStart: number;

  // we have these for the previous item if that item has a price type or price set in the option list
  previousQty: number;
  previousSelectedOption: IOptionListHeader; // the previously selected option record - we use the current rate
  currentSelectedOption: IOptionListHeader;
  newRate: number;
  newPrice: number;

  returnedFromConnectModal = false;
  variationTypeEnum = VariationTypeEnum;
  setOtherOptionsNotTakenClicked = false;
  patchReturnTypeEnum = PatchReturnTypeEnum;

  isQtyRequired = false; // we may have an item that needs a qty
  currentColour: string;
  showPrice: boolean;
  showPreviousPrice: boolean;
  costTypeDropdown: any[];
  jobVarCalcs: JobVarCalc[];
  costTypeDesc: string;
  prevAndCurrentValid: boolean;
  startingCostTypeId: number;
  isClient = false;
  variationStatusEnum = VariationStatusEnum;
  isVariationOpen = false;
  printedDescription: string; // option list items can have a different printed desc.
  variationPriceEstimatingEnabled = false; // set up in config
  addAndEstimate: boolean;
  forceRefresh: boolean;
  isInDoNotPrintSection = false;
  addendumName: string;
  userCanChangePrices = true;
  modifiedUserName = '';
  modifiedDate: Date;
  isAdmin = false;
  isAcceptedIntoContract: boolean
  showIsAcceptedIntoContractCheckBox: boolean;
  hasEstimatingItems: boolean;
  isVariationOnlyTextShown = false;
  variationOnlyComment: string;
  noteHeight: number;
  previousJobVarItem: IJobVarItem;

  constructor(private _jobItemService: JobItemService,
    private _jobVarItemService: JobVarItemService,
    private _globalService: GlobalService,
    private _optionListService: OptionListService,
    private _authService: AuthService,
    private modalService: NgbModal,
    private notiService: NotificationService,
    private utilsService: UtilsService,
    private userService: UserService,
    private estimatingService: EstimatingService,
    private _formBuilder: UntypedFormBuilder, private _activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.isClient = this._authService.isClient();
    this.isAdmin = this._authService.isAdminOrSuperUser();
    this.noteHeight = window.innerHeight - 600;
    this.noteHeight = this.noteHeight > 300 ? 300 : this.noteHeight;

    this.isAcceptedIntoContract = this.jobitem.isAcceptedIntoContract === null
      || this.jobitem.isAcceptedIntoContract === true ? true : false;

    this.addendumName = this._globalService.getAddendumName();

    if (!this.isClient && this._jobItemService.isInNotPrintedSection(this.jobitem.jobItemAboveTypeId, this.jobitem.jobItemAboveId)) {
      this.isInDoNotPrintSection = true;
    }

    // get config
    if (this._globalService.getCompanyConfigValue(this.VariationPriceEstimatingEnabled) === 1) {
      this.variationPriceEstimatingEnabled = true;
    }

    // can this user change prices - sales variations only
    if (!this.selectionsAdmin
      && (this._jobItemService.currentVariation.variationType > 10 && this._jobItemService.currentVariation.variationType < 20)
      && this._globalService.getCompanyConfigValue(ConfigurationEnum.CanSalesVariationItemsBeUnChecked) === 2) {
      const permission = this._authService.getSelectionsPermissions('Estimating');
      if (!permission || permission === 'Read' || permission === 'none') {
        this.userCanChangePrices = false;
      }
    }

    if (this._jobItemService.currentVariation.statusId < this.variationStatusEnum.PendingApproval) {
      this.isVariationOpen = true;
    }

    // so an admin can correct the locking of the contract
    this.showIsAcceptedIntoContractCheckBox = !this.isVariationOpen && this.variationType > 10 && this.isAdmin;

    if (this.jobitem.noteColour) {
      this.currentColour = this.colours[this.jobitem.noteColour].name;
    } else {
      this.currentColour = this.colours[0].name;
    }

    if (this.jobitem.isDoNotPrint) {
      this.priceStart = null;
      this.startingCostTypeId = this.costTypeEnum.Note;
    } else if (this.jobitem.changedByVOId !== this.variationId) {
      this.priceStart = null;
      this.startingCostTypeId = this.costTypeEnum.Priced;
    } else {
      this.startingCostTypeId = this.jobitem.costTypeId === null ? this.costTypeEnum.Priced : this.jobitem.costTypeId;

      if (this.jobitem.provisionalSum) {
        // we get this price to make calcs easier if null
        if (this.jobitem.price) {
          this.priceStart = this.jobitem.price + this.jobitem.provisionalSum;
        } else {
          this.priceStart = this.jobitem.provisionalSum;
        }
      } else {
        this.priceStart = this.jobitem.price;
      }
    }

    if (this.priceStart === 0) {
      this.priceStart = null; // so we have a clear input field
    }

    // for changing the qty we need the original value as default new qty
    let prevQty = this.jobitem.quantity ?? 0;
    if (this.jobitem.changedByVOId === this.variationId) {
      prevQty = this.jobitem.previousQuantity ?? 0;

      // we can look up the last modified user
      this.getModifiedUser();
    }

    let newQty = null;
    if (this.jobitem.quantity && this.jobitem.quantity !== 0) {
      newQty = this.jobitem.quantity;
      if (this.priceStart && this.jobitem.changeTypeId !== this.changeTypeEnum.Delete) {
        this.priceStart = this.priceStart / newQty;
      }
    }
    const jobVarItemExtra = this._jobItemService.currentJobVarItemExtras.find(i => i.jobVarItemId === this.jobitem.changedByJobVarId);
    if (jobVarItemExtra && jobVarItemExtra.variationOnlyComment && jobVarItemExtra.variationOnlyComment !== '') {
      this.isVariationOnlyTextShown = true;
      this.variationOnlyComment = jobVarItemExtra.variationOnlyComment;
    }

    this.editForm = this._formBuilder.group({
      itemDescription: this.jobitem.itemDescription,
      selection: this.calledFromDropDown && this.jobitem.changedByVOId !== this.variationId ? '' : this.jobitem.selection,
      provisionalSum: this.jobitem.provisionalSum,
      price: this.priceStart,
      itemNotTaken: this.jobitem.itemNotTaken,
      isHiddenFromMaster: this.jobitem.isHiddenFromMaster,
      deleteComment: this.jobitem.deleteComment,
      isBoldNote: this.jobitem.isBoldNote,
      isItalicNote: this.jobitem.isItalicNote,
      noteColour: this.jobitem.noteColour,
      isChecked: this.jobitem.isChecked,
      hideFixedPSum: this.jobitem.hideFixedPSum,
      quantity: newQty,
      unitOfMeasure: this.jobitem.unitOfMeasure,
      costTypeId: this.startingCostTypeId,
      previousItemRate: 0,
      previousQuantity: prevQty,
      previousCostTypeId: this.costTypeEnum.Priced,
      newItemRate: this.priceStart,
      variationOnlyComment: jobVarItemExtra?.variationOnlyComment,
    });

    if (this.jobitem.changeTypeId === this.changeTypeEnum.Delete) {
      this.modalHeading = 'Edit Deleted Item';
      this.setCostTypeList(true);
    } else {
      this.modalHeading = 'Edit Item';
      this.setCostTypeList(false);
    }

    this.setShowPrice();

    this.connectedItemDesc = '';
    if (this.jobitem.connectedItemId) {
      // this item is connected to another so we read that item to get the desc

      const connectedToItem = this._jobItemService.currentJobItemsUnfiltered.filter(
        i => i.changedByJobVarId === this.jobitem.connectedItemId && !i.isHistoryRecord
      );
      if (connectedToItem[0]) {
        // get the connected "to" item's heading
        if (connectedToItem[0].jobItemAboveId) {
          const connectedToHeading = this._jobItemService.currentJobItemsUnfiltered.filter(
            i => i.originalItemTypeId === connectedToItem[0].jobItemAboveTypeId && i.id === connectedToItem[0].jobItemAboveId
          );

          if (connectedToHeading[0]) {
            this.connectedToHeadingDesc = connectedToHeading[0].itemDescription;
          }
        }

        if (!connectedToItem[0].itemDescription
          || connectedToItem[0].itemDescription === '') {
          this.connectedItemDesc = connectedToItem[0].selection;
        } else if (connectedToItem[0].selection
          && connectedToItem[0].selection.length > 35) {
          this.connectedItemDesc = connectedToItem[0].itemDescription
            + ' - ' + connectedToItem[0].selection.slice(0, 35) + '...';
        } else if (connectedToItem[0].selection) {
          this.connectedItemDesc = connectedToItem[0].itemDescription
            + ' - ' + connectedToItem[0].selection;
        } else {
          this.connectedItemDesc = connectedToItem[0].itemDescription
            + ' - ' + connectedToItem[0].selection;
        }
      }
    }
    this.getStartingDetails();
    this.anyJobVarCostItems();
    this.getPreviousJobVarItem();
  }

  getModifiedUser() {
    if (this.jobitem.changedByJobVarId) {
      this.subscriptions = this.subscriptions.concat(
        this._jobVarItemService.getJobVarItemChangedBy(this.jobitem.changedByJobVarId).subscribe(
          modifiedUser => {
            this.modifiedUserName = this.userService.users.find(i => i.id === modifiedUser.modifiedUserId)?.fullName;
            this.modifiedDate = modifiedUser.modifiedDate;
          })
      );
    }
  }

  getPreviousJobVarItem() {
    if (this.jobitem.changedByJobVarId) {
      this.subscriptions = this.subscriptions.concat(
        this._jobVarItemService.getPreviousJobVarItem(this.variationId, this.jobitem.changedByJobVarId).subscribe(
          previousItem => {
            this.previousJobVarItem = previousItem;
          })
      );
    }
  }

  setCostTypeList(deleteClicked: boolean) {
    if (deleteClicked || this.jobitem.linkedJobItemId) {
      // PS not allowed for linked items
      this.costTypeDropdown = [
        { name: 'Priced', id: 0 },
        { name: 'Note', id: 1 },
        { name: 'No Charge', id: 2 },
        { name: 'EO Standard', id: 3 },
        { name: 'By Owner', id: 6 },
        { name: 'Included', id: 7 }
      ];
    } else {
      this.costTypeDropdown = [
        { name: 'Priced', id: 0 },
        { name: 'Note', id: 1 },
        { name: 'No Charge', id: 2 },
        { name: 'EO Standard', id: 3 },
        { name: 'Provisional Sum', id: 4 },
        { name: 'By Owner', id: 6 },
        { name: 'Included', id: 7 }
      ];
    }

    if (this.variationType < 20 && this.variationType > 10) {
      this.costTypeDropdown = this.costTypeDropdown.concat(
        { name: 'Promotion', id: 8 },
        { name: 'Bonus Item', id: 9 }
      );
    }

    this.costTypeDropdown = this.costTypeDropdown.concat(
      { name: 'Excluded', id: 10 }
    );

    this.costTypeDropdown.forEach(element => {
      if (element.id === this.editForm.get('costTypeId').value) {
        this.costTypeDesc = element.name;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getStartingDetails() {
    if (!this.jobitem.changedByVOId || this.jobitem.changedByVOId !== this.variationId) {
      // have to add a variation line for this VO which is changing the previous value
      this.alreadyHaveVO = false;
      if (!this.jobitem.linkedJobItemId) { // note can't delete a provisional sum item
        this.canMarkDeleted = true;
      }
      this.attachmentExists = false;
    } else {
      // change already for this VO so patch or delete
      this.alreadyHaveVO = true;

      // if this item has an attachment then can't delete
      if (this.jobitem.attachmentVariationId === this.variationId) {
        this.attachmentExists = true;
      } else {
        this.attachmentExists = false;
        if (this.jobitem.changeTypeId === this.changeTypeEnum.Add) {
          // item was added so can change it or delete it
          if (!this.jobitem.linkedJobItemId) {
            // cannot delete an item linked to another - this would happen after copy items
            this.canDelete = true;
          }
        } else {
          // if our current option list is the same then we can revert

          // we need to check previous in case the option list has been deleted
          if (this.jobitem.previousOptionListId) {
            const previousOptionList = this._jobItemService.getOptionListItems(this.jobitem.previousOptionListId);
            if (!this._jobItemService.optionsListFound) {
              this.jobitem.previousOptionListId = null;
            }
          }
          if (this.jobitem.optionListId) {
            const currentOptionList = this._jobItemService.getOptionListItems(this.jobitem.optionListId);
            if (!this._jobItemService.optionsListFound) {
              this.jobitem.optionListId = null;
            }
          }
          if (this.jobitem.previousOptionListId === this.jobitem.optionListId || this.jobitem.changeTypeId === this.changeTypeEnum.Delete) {
            // the lists are the same so we can revert otherwise we must have been changed by a linked item
            // if (!this.jobitem.linkedJobItemId) {
            // cannot delete an item linked to another - this would happen after copy items
            this.canRevert = true;
            // }
          }
        }
      }
    }

    this.selectedOptionId = this.jobitem.selectedOptionId;
    if (this.calledFromDropDown) {
      this.selectedOptionId = null;
    } else {
      if (this.jobitem.printedDescription && this.jobitem.printedDescription !== '') {
        this.printedDescription = this.jobitem.printedDescription;
      } else {
        this.printedDescription = null;
      }
    }

    if ((this.jobitem.itemTypeId === this.ItemType.Detail && this.jobitem.selectionTypeId === this.SelectionType.Dropdown)) {
      if ((this.alreadyHaveVO && this.jobitem.changeTypeId === this.changeTypeEnum.Delete)) {
        this.gotOptionList = true;
        this.newSelection = this.jobitem.selection;
      } else {
        this.getOptions();
      }
    } else {
      this.getOptions(); // we need to try in case we have a note from a house option that has qty
      // this.isQtyRequired = this.jobitem.isQtyRequired;
      // this.gotOptionList = true;
    }

    if (this.jobitem.changeTypeId === this.changeTypeEnum.Delete && this.alreadyHaveVO) {
      this.readOnly = true;
    } else {
      this.readOnly = false;
    }

    // if we have a linked item get it's description
    if (this.jobitem.linkedJobItemId) {
      this.linkedItemDescription = '';
      // we don't have it so we need to get it from the list in memory
      this._jobItemService.currentJobItemsUnfiltered.forEach(element => {
        if (element.originalItemTypeId === this.jobitem.originalItemTypeId && element.id === this.jobitem.linkedJobItemId) {
          this.linkedItemDescription = element.itemDescription;
        }
      });
      this.loading = false;
    } else {
      this.loading = false;
    }
  }

  updateItem(gotoAttachments) {
    if (this.editForm.get('selection').value) {
      this.editForm.patchValue({
        selection: this.editForm.get('selection').value.trim().replace(/\n$/, '')
      });
    }

    // if zero then set to null to clear
    this.loading = true;
    let provSum = this.jobitem.provisionalSum;
    if (!this.jobitem.provisionalSum) {
      provSum = 0;
    }

    if (provSum !== 0) {
      this.editForm.patchValue({
        costTypeId: this.costTypeEnum.PSFinalised
      });
    }

    let price = 0;
    let newRate = 0;

    if (!this.jobitem.hasLinkedItems) {
      if (this.editForm.get('newItemRate').value) {
        newRate = this.editForm.get('newItemRate').value;
      }

      // round qty to max 4 decimal places
      if (this.editForm.get('quantity').value) {
        this.editForm.patchValue({
          quantity: +(this.editForm.get('quantity').value.toFixed(4))
        });
      }

      if (this.isQtyRequired) {
        price = this.utilsService.roundEven((newRate * this.editForm.get('quantity').value)
          - (this.editForm.get('previousItemRate').value * this.editForm.get('previousQuantity').value));
      } else {
        price = newRate;
      }

      if (provSum !== 0) {
        if (this.isQtyRequired) {
          price = this.utilsService.roundEven(newRate * this.editForm.get('quantity').value) - provSum;
        } else {
          price = newRate - provSum;
        }
      } else if ((this.editForm.get('costTypeId').value === this.costTypeEnum.Note
        || this.editForm.get('costTypeId').value === this.costTypeEnum.NoCharge)) {
        this.editForm.patchValue({
          newItemRate: null
        });
        price = 0;
      }
    }

    this.priceUpdate = this.utilsService.roundEven(price);
    if (this.priceUpdate === 0) {
      this.priceUpdate = null;
    }

    // check if default values have changed and 'uncheck' if no permission
    // note that if changing the price type to or from PS then also mark as un-checked
    if (!this.selectionsAdmin) {
      if (this.jobitem.price !== this.priceUpdate
        || this.jobitem.itemDescription !== this.editForm.get('itemDescription').value
        || this.jobitem.selection !== this.editForm.get('selection').value
        || (this.jobitem.costTypeId !== this.editForm.get('costTypeId').value
          && (this.jobitem.costTypeId === CostTypeEnum.ProvisionalSum || this.editForm.get('costTypeId').value === CostTypeEnum.ProvisionalSum))) {
        this.editForm.patchValue({
          isChecked: false
        });
      }
    }

    // when we have no item prefix desc and the user has changed the selection/note then we clear the selected option id
    // as we need to disconnect it so no image comes up
    if ((!this.editForm.get('itemDescription').value || this.editForm.get('itemDescription').value === '')
      && this.jobitem.selection !== this.editForm.get('selection').value) {
      this.selectedOptionId = null;
    }

    this.resetIsChecked(this.editForm.get('costTypeId').value); // ensure isChecked and price is correct

    // calc the actual new selection value
    if (this.jobitem.itemTypeId === this.ItemType.Detail) {
      if (this.jobitem.selectionTypeId === this.SelectionType.Checkbox) {
        if (this.editForm.get('selection').value === false || this.editForm.get('selection').value === '') {
          this.newSelection = '';
        } else {
          this.newSelection = 'Yes';
        }
      } else {
        if (this.editForm.get('selection').value === 'Please Select...'
          || this.editForm.get('selection').value === 'Please select previous option first...') {
          this.newSelection = '';
        } else {
          this.newSelection = this.editForm.get('selection').value;

          // if we have the exact same selection as a dropdown we add the id as we may have not changed it
          if (this.jobitem.itemDescription === this.editForm.get('itemDescription').value) {
            const findOption = this.optionList.find(i => i.description === this.newSelection);
            if (findOption) {
              this.selectedOptionId = findOption.id;
            }
          }
        }
      }
    } else {
      this.newSelection = this.editForm.get('selection').value;
    }

    if (this._jobItemService.currentVariation.variationType === this.variationTypeEnum.Office
      || this._jobItemService.currentVariation.variationType === this.variationTypeEnum.PreContractExFromContract) {
      this.priceUpdate = 0;
    }

    if (this.alreadyHaveVO) {
      this.calcUpdateItem();
      // check to see if we are reverting to the previous values
      this.updateExistingItem(gotoAttachments);
    } else {
      this.updatedItem = {
        jobVariationId: this.variationId,
        changeTypeId: this.changeTypeEnum.Change,
        jobItemAboveTypeId: this.jobitem.jobItemAboveTypeId,
        jobItemAboveId: this.jobitem.jobItemAboveId,
        orderNo: 0,
        itemTypeId: this.jobitem.itemTypeId,
        itemDescription: this.editForm.get('itemDescription').value,
        selection: this.newSelection,
        originalItemTypeId: this.jobitem.originalItemTypeId,
        originalItemId: this.jobitem.id,
        price: this.priceUpdate,
        itemNotTaken: this.editForm.get('itemNotTaken').value,
        isHiddenFromMaster: this.editForm.get('isHiddenFromMaster').value,
        deleteComment: this.editForm.get('deleteComment').value,
        isBoldNote: this.editForm.get('isBoldNote').value,
        isItalicNote: this.editForm.get('isItalicNote').value,
        noteColour: this.editForm.get('noteColour').value,
        isChecked: this.editForm.get('isChecked').value,
        costTypeId: this.editForm.get('costTypeId').value,
        hideFixedPSum: this.editForm.get('hideFixedPSum').value,
        optionNumber: 1, // new items are for option number 1
        quantity: this.editForm.get('quantity').value,
        unitOfMeasure: this.editForm.get('unitOfMeasure').value,
        variationOnlyComment: this.editForm.get('variationOnlyComment').value
      };

      if (!this.jobitem.changedByJobVarId || this.jobitem.changedByJobVarId === 0) {
        // add with previous item = JobItem
        this.updatedItem.jobVarTypeId = this.jobVarTypeEnum.JobItem;
        this.updatedItem.itemChangedId = this.jobitem.id;
      } else {
        // add with previous item = JobVarItem
        this.updatedItem.jobVarTypeId = this.jobVarTypeEnum.JobVarItem;
        this.updatedItem.itemChangedId = this.jobitem.changedByJobVarId;
      }

      if (this.jobitem.itemTypeId === this.ItemType.Detail && this.jobitem.selectionTypeId === this.SelectionType.Dropdown) {
        this.updatedItem.selectedOptionId = this.selectedOptionId;
        this.updatedItem.optionListId = this.jobitem.optionListId;
      }


      // if we using a qty we need to send the job var calc records as well ------------
      if (this.isQtyRequired) {
        this.updatedItem.addJobVarCalcDto = [
          {
            optionListId: this.jobitem.selectedOptionId,
            quantity: this.editForm.get('previousQuantity').value,
            costTypeId: this.editForm.get('previousCostTypeId').value,
            itemRate: this.editForm.get('previousItemRate').value
          },
          {
            optionListId: this.selectedOptionId,
            quantity: this.editForm.get('quantity').value,
            costTypeId: this.editForm.get('costTypeId').value,
            itemRate: newRate
          }
        ];
      }

      this.subscriptions = this.subscriptions.concat(
        this._jobVarItemService.addJobVarItem(this.variationNumber, this.updatedItem).subscribe({
          next: (updatedItems) => {
            this.updatedItems = updatedItems;
            // we now process the returned records
            if (this.updatedItems[0].patchReturnWarning) {
              this.notiService.showWarning(this.updatedItems[0].patchReturnWarning);
            }

            // if we have a variation comment we add to the jobvaritemextra
            this._jobItemService.updateJobVarItemExtraStore(updatedItems[0].id, this.editForm.get('variationOnlyComment').value);

            if (!gotoAttachments) { // if we go to attachments we refresh after that
              if (this.updatedItems[0].patchReturnTypeId === this.patchReturnTypeEnum.GetAllData) {
                this.updatedItem.refreshLines = true;
                this.forceRefresh = true;
              } else {
                // update the list we already have
                this._jobItemService.updateChangedVarItems(this.updatedItems,
                  this._jobItemService.currentVariation.id, this.variationNumber);
                this.updatedItem.refreshLines = false;
              }
            }
            if (this.addAndEstimate) {
              // go to estimating and return.
              this.jobitem.changedByJobVarId = this.updatedItems[0].id;
              this.jobitem.changedByVOId = this.variationId;
              this.getStartingDetails();
              this.loading = false;
              this.estimatePrice();
            } else {
              this.updatedItem.gotoAttachments = gotoAttachments;
              // add the new record id so the attachment can be saved
              this.updatedItem.changedByJobVarId = this.updatedItems[0].id;
              // this._jobItemService.variationTotal += this.priceUpdate - provSum;
              this.close(this.updatedItem);
            }
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
      );
    }
  }

  updateExistingItem(gotoAttachments: boolean) {
    // patch the jobvaritem record
    this.loading = true;

    // we check if a linked item has an attachment and warn that it will need to be changed/deleted manually
    if (this.jobitem.hasLinkedItems) {
      this._jobItemService.checkForAttachment(this.jobitem.id, this.variationId);
    }

    // if we using a qty we need to send the job var calc records as well ------------
    if (this.isQtyRequired) {
      this.updateJobVarCalcs(gotoAttachments);
    } else {
      this.updateJobVarItem(gotoAttachments);
    }
  }

  updateJobVarItem(gotoAttachments: boolean) {
    this.subscriptions = this.subscriptions.concat(
      this._jobVarItemService.updateJobVarItem(this.updatedItem).subscribe({
        next: (updatedItems) => {
          this.updatedItems = updatedItems;

          // if we have a variation comment we add to the jobvaritemextra
          this._jobItemService.updateJobVarItemExtraStore(this.updatedItem.id, this.editForm.get('variationOnlyComment').value);

          if (gotoAttachments) {
            this.updatedItem.gotoAttachments = gotoAttachments;
            this.updatedItem.changedByJobVarId = this.jobitem.changedByJobVarId;
            this.updatedItem.refreshLines = false;
          } else {
            // we now process the returned records
            if (this.updatedItems[0].patchReturnTypeId === this.patchReturnTypeEnum.GetAllData || this.setOtherOptionsNotTakenClicked) {
              this.updatedItem.refreshLines = true;
            } else {
              // update the list we already have
              this._jobItemService.updateChangedVarItems(this.updatedItems, this.variationId, this.variationNumber);
              this.updatedItem.refreshLines = false;
            }
          }

          this.close(this.updatedItem);
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  updateJobVarCalcs(gotoAttachments: boolean) {
    let id0 = null;
    if (this.jobVarCalcs && this.jobVarCalcs[0]) {
      id0 = this.jobVarCalcs[0].id;
    }
    let id1 = null;
    if (this.jobVarCalcs && this.jobVarCalcs[1]) {
      id1 = this.jobVarCalcs[1].id;
    }

    this.updatedItemCalcs = {
      patchJobVarCalcDtos: [
        {
          id: id0,
          optionListId: this.jobitem.selectedOptionId,
          quantity: this.editForm.get('previousQuantity').value,
          costTypeId: this.editForm.get('previousCostTypeId').value,
          itemRate: this.editForm.get('previousItemRate').value
        },
        {
          id: id1,
          optionListId: this.selectedOptionId,
          quantity: this.editForm.get('quantity').value,
          costTypeId: this.editForm.get('costTypeId').value,
          itemRate: this.editForm.get('newItemRate').value
        }
      ]
    };

    this.jobVarCalcs = this.updatedItemCalcs.patchJobVarCalcDtos;

    this.updatedItem.UpdateJobVarCalcs = true;
    this.updatedItem.PrevJobVarCalcId = id0;
    this.updatedItem.PrevOptionListId = this.jobitem.previousSelectedOptionId;
    this.updatedItem.PrevQuantity = this.editForm.get('previousQuantity').value;
    this.updatedItem.PrevCostTypeId = this.editForm.get('previousCostTypeId').value;
    this.updatedItem.PrevItemRate = this.editForm.get('previousItemRate').value;
    this.updatedItem.NewJobVarCalcId = id1;
    this.updatedItem.NewOptionListId = this.selectedOptionId;
    this.updatedItem.NewQuantity = this.editForm.get('quantity').value;
    this.updatedItem.NewCostTypeId = this.editForm.get('costTypeId').value;
    this.updatedItem.NewItemRate = this.editForm.get('newItemRate').value;

    this.updateJobVarItem(gotoAttachments);
  }

  calcUpdateItem() {
    this.updatedItem = { id: this.jobitem.changedByJobVarId, refreshLines: false };

    if (this.jobitem.itemDescription !== this.editForm.get('itemDescription').value) {
      this.updatedItem.itemDescription = this.editForm.get('itemDescription').value;
    }
    if (this.jobitem.selection !== this.newSelection) {
      this.updatedItem.selection = this.newSelection;
      this.updatedItem.refreshLines = true;
    }
    if (this.jobitem.itemTypeId === this.ItemType.Detail && this.jobitem.selectionTypeId === this.SelectionType.Dropdown
      && this.selectedOptionId !== this.jobitem.selectedOptionId) {
      this.updatedItem.selectedOptionId = this.selectedOptionId;
      this.updatedItem.refreshLines = true;
    }
    if (this.priceUpdate !== this.jobitem.price) {
      this.updatedItem.price = this.priceUpdate;
    }
    if (this.editForm.get('itemNotTaken').value !== this.jobitem.itemNotTaken) {
      this.updatedItem.itemNotTaken = this.editForm.get('itemNotTaken').value;
      this.updatedItem.refreshLines = true;
    }
    if (this.editForm.get('deleteComment').value !== this.jobitem.deleteComment) {
      this.updatedItem.deleteComment = this.editForm.get('deleteComment').value;
    }
    if (this.editForm.get('isHiddenFromMaster').value !== this.jobitem.isHiddenFromMaster) {
      this.updatedItem.isHiddenFromMaster = this.editForm.get('isHiddenFromMaster').value;
    }
    if (this.editForm.get('isBoldNote').value !== this.jobitem.isBoldNote) {
      this.updatedItem.isBoldNote = this.editForm.get('isBoldNote').value;
    }
    if (this.editForm.get('isItalicNote').value !== this.jobitem.isItalicNote) {
      this.updatedItem.isItalicNote = this.editForm.get('isItalicNote').value;
    }
    if (this.editForm.get('noteColour').value !== this.jobitem.noteColour) {
      this.updatedItem.noteColour = this.editForm.get('noteColour').value;
    }
    if (this.editForm.get('isChecked').value !== this.jobitem.isChecked) {
      this.updatedItem.isChecked = this.editForm.get('isChecked').value;
    }
    if (this.editForm.get('costTypeId').value !== this.jobitem.costTypeId) {
      this.updatedItem.costTypeId = this.editForm.get('costTypeId').value;
    }
    if (this.editForm.get('hideFixedPSum').value !== this.jobitem.hideFixedPSum) {
      this.updatedItem.hideFixedPSum = this.editForm.get('hideFixedPSum').value;
    }
    if (this.editForm.get('variationOnlyComment').value !== this.variationOnlyComment) {
      this.updatedItem.variationOnlyComment = this.editForm.get('variationOnlyComment').value;
    }
    let newQty = this.editForm.get('quantity').value;
    if (newQty === 0) {
      newQty = null;
    }
    if (newQty !== this.jobitem.quantity) {
      this.updatedItem.quantity = newQty;
    }
    if (this.editForm.get('unitOfMeasure').value !== this.jobitem.unitOfMeasure) {
      this.updatedItem.unitOfMeasure = this.editForm.get('unitOfMeasure').value;
    }

    if (this.setOtherOptionsNotTakenClicked) {
      this.updatedItem.refreshLines = true;
    }
  }

  deleteItem() {
    if (this.alreadyHaveVO) {
      // cannot delete if any other items connected to this item so check
      let checkVal = false;
      this._jobItemService.currentJobItemsUnfiltered.some(element => {
        if (element.connectedItemId === this.jobitem.changedByJobVarId) {
          checkVal = true;
          return true;
        }
        return false;
      });
      if (checkVal) {
        // alert('Cannot delete an item that has other items connected to it.');
        this.notiService.showWarning('Cannot delete an item that has other items connected to it.');
      } else {
        // delete the existing item
        if (!this.deletePressed) {
          this.deletePressed = true;
        } else {
          this.loading = true;
          this.subscriptions = this.subscriptions.concat(
            this._jobVarItemService.deleteJobVarItem(this.jobitem.changedByJobVarId).subscribe({
              next: () => {
                // note must refresh to get correct history items in case item was changed and approved in another vo
                this.updatedItem = { gotoAttachments: false, refreshLines: true };
                this.close(this.updatedItem);
              },
              error: (err) => {
                this.notiService.notify(err);
                this.loading = false;
              }
            })
          );
        }
      }
    } else {
      // confirm delete and ask for comment
      if (!this.deletePressed) {
        this.deletePressed = true;

        // ensure qty is back to previous
        this.editForm.patchValue({
          quantity: this.jobitem.quantity
        });

        if (this.isQtyRequired) {
          this.editForm.patchValue({
            newItemRate: - this.editForm.get('newItemRate').value
          });
        }

        // take out PS from the list
        this.setCostTypeList(true);
        if (this.editForm.get('costTypeId').value === this.costTypeEnum.ProvisionalSum) {
          this.editForm.patchValue({
            costTypeId: this.costTypeEnum.Priced
          });
        }
      } else {

        let costTypeForUpdate = this.editForm.get('costTypeId').value;
        if (costTypeForUpdate === this.costTypeEnum.NoCharge || costTypeForUpdate === this.costTypeEnum.Note) {
          this.editForm.patchValue({
            newItemRate: null
          });
        }

        let priceForDelete = 0;
        if (this.editForm.get('newItemRate').value) {
          priceForDelete = this.editForm.get('newItemRate').value;
        }

        if (this.jobitem.provisionalSum) {
          costTypeForUpdate = this.costTypeEnum.PSFinalised;
          priceForDelete = -this.jobitem.provisionalSum;
        } else if (this.isQtyRequired) {
          if (this.editForm.get('quantity').value) {
            priceForDelete = this.utilsService.roundEven(this.editForm.get('quantity').value * priceForDelete);
          } else {
            priceForDelete = 0;
          }
        }


        if (this._jobItemService.currentVariation.variationType === this.variationTypeEnum.Office
          || this._jobItemService.currentVariation.variationType === this.variationTypeEnum.PreContractExFromContract) {
          priceForDelete = 0;
        }

        if (this.jobitem.changedByJobVarId === 0) {
          // add with previous item = JobItem
          this.updatedItem = {
            jobVariationId: this.variationId,
            jobVarTypeId: this.jobVarTypeEnum.JobItem,
            itemChangedId: this.jobitem.id,
            changeTypeId: this.changeTypeEnum.Delete,
            itemTypeId: this.jobitem.itemTypeId,
            jobItemAboveTypeId: this.jobitem.jobItemAboveTypeId,
            jobItemAboveId: this.jobitem.jobItemAboveId,
            originalItemTypeId: this.jobitem.originalItemTypeId,
            originalItemId: this.jobitem.id,
            deleteComment: this.editForm.get('deleteComment').value,
            itemDescription: this.jobitem.itemDescription,
            selection: this.jobitem.selection,
            price: priceForDelete,
            costTypeId: costTypeForUpdate,
            isChecked: this.editForm.get('isChecked').value,
            optionNumber: 1,
            quantity: this.editForm.get('quantity').value,
            variationOnlyComment: this.editForm.get('variationOnlyComment').value
          };

          this.loading = true;
          this.subscriptions = this.subscriptions.concat(
            this._jobVarItemService.addJobVarItem(this.variationNumber, this.updatedItem).subscribe({
              next: (res) => {
                this.updatedItems = res;

                // if we have a variation comment we add to the jobvaritemextra
                this._jobItemService.updateJobVarItemExtraStore(res[0].id, this.editForm.get('variationOnlyComment').value);

                if (this.updatedItems[0].patchReturnWarning) {
                  this.notiService.showWarning(this.updatedItems[0].patchReturnWarning);
                }
                this.updatedItem.gotoAttachments = false;
                this._jobItemService.variationTotal += priceForDelete;
                this.updatedItem.refreshLines = true;
                this.close(this.updatedItem);
              },
              error: (err) => {
                this.notiService.notify(err);
                this.loading = false;
              }
            })
          );
        } else {
          // add with previous item = JobVarItem
          this.updatedItem = {
            jobVariationId: this.variationId,
            jobVarTypeId: this.jobVarTypeEnum.JobVarItem,
            itemChangedId: this.jobitem.changedByJobVarId,
            changeTypeId: this.changeTypeEnum.Delete,
            itemTypeId: this.jobitem.itemTypeId,
            jobItemAboveTypeId: this.jobitem.jobItemAboveTypeId,
            jobItemAboveId: this.jobitem.jobItemAboveId,
            originalItemTypeId: this.jobitem.originalItemTypeId,
            originalItemId: this.jobitem.id,
            deleteComment: this.editForm.get('deleteComment').value,
            itemDescription: this.jobitem.itemDescription,
            selection: this.jobitem.selection,
            price: priceForDelete,
            costTypeId: costTypeForUpdate,
            isChecked: this.editForm.get('isChecked').value,
            optionNumber: 1,
            quantity: this.editForm.get('quantity').value,
            variationOnlyComment: this.editForm.get('variationOnlyComment').value
          };
          this.loading = true;
          this.subscriptions = this.subscriptions.concat(
            this._jobVarItemService.addJobVarItem(this.variationNumber, this.updatedItem).subscribe({
              next: (res) => {
                this.updatedItems = res;

                // if we have a variation comment we add to the jobvaritemextra
                this._jobItemService.updateJobVarItemExtraStore(res[0].id, this.editForm.get('variationOnlyComment').value);

                if (this.updatedItems[0].patchReturnWarning) {
                  this.notiService.showWarning(this.updatedItems[0].patchReturnWarning);
                }
                this.updatedItem.gotoAttachments = false;
                this._jobItemService.variationTotal += priceForDelete;
                this.updatedItem.refreshLines = true;
                this.close(this.updatedItem);
              },
              error: (err) => {
                this.notiService.notify(err);
                this.loading = false;
              }
            })
          );
        }
      }
    }
  }

  cancelDeleteItem() {
    this.deletePressed = false;

    // put PS back into the list
    this.setCostTypeList(false);
  }

  getOptions() {
    this.treeOptionNodes = this._optionListService.getCurrentOptionNodes();

    if (this.jobitem.optionListId === null) {
      this.optionList = [new IOptionListHeader('Please select previous option first...', 0, null)];

      // change for items added in a VO - allow 'Other' - GH 6-4-20
      if (this.jobitem.canEnterManualSelection || this.jobitem.originalItemTypeId === this.jobVarTypeEnum.JobVarItem) {
        this.optionList = this.optionList.concat(new IOptionListHeader('Other - Click here', 999, null));
        if (!this.jobitem.selection || this.jobitem.selection === '') {
          this.isOriginalSelectionInOptionList = true;
        } else {
          this.isOriginalSelectionInOptionList = false;
        }
      }

      this.finaliseOptionSetup();

    } else {
      this.optionList = [new IOptionListHeader('Please Select...', 0, null)];
      this.optionListTmp = this._jobItemService.getOptionListItems(this.jobitem.optionListId);

      // we have also set the optionListParentNode in the service
      if (!this.jobitem.hasLinkedItems) {
        this.isQtyRequired = this._jobItemService.optionListParentNode.isQtyRequired;
      }
      if (this._jobItemService.optionListParentNode && !this.calledFromDropDown
        && this._jobItemService.optionListParentNode.childSelectionWarning
        && this._jobItemService.optionListParentNode.childSelectionWarning.trim() !== '') {
        this.notiService.showInfo(this._jobItemService.optionListParentNode.childSelectionWarning);
      }

      // if qty required and this variation item already exists then we read the jobvarcalc records
      if (this.isQtyRequired && this.alreadyHaveVO) { // was  && this.jobitem.changeTypeId !== this.changeTypeEnum.Add
        this.subscriptions = this.subscriptions.concat(
          this._jobVarItemService.getJobVarCalcs(this.jobitem.changedByJobVarId)
            .subscribe({
              next: (jobVarCalcs) => {
                this.jobVarCalcs = jobVarCalcs;
                this.continueOptionsSetup();
              },
              error: (err) => {
                this.notiService.notify(err);
                this.gotOptionList = true;
              }
            })
        );
      } else {
        this.continueOptionsSetup();
      }
    }
  }

  continueOptionsSetup() {
    // if the old selected item is priced in the option list then we get this info
    if (this.alreadyHaveVO) {
      if (this.jobitem.previousSelectedOptionId) {
        this.previousSelectedOption = this._jobItemService.getOptionListItemById(this.jobitem.previousSelectedOptionId);
      }
    } else {
      if (this.jobitem.selectedOptionId) {
        this.previousSelectedOption = this._jobItemService.getOptionListItemById(this.jobitem.selectedOptionId);
      }
    }
    if (this.jobitem.selectedOptionId) {
      this.currentSelectedOption = this._jobItemService.getOptionListItemById(this.jobitem.selectedOptionId);
    }

    this.setPreviousSelectionData();

    this.optionList = this.optionList.concat(this.optionListTmp);
    this.optionList = this.optionList.concat(new IOptionListHeader('Other - Click here', 999, null));

    if (this.calledFromDropDown) {
      this.isOriginalSelectionInOptionList = false;
    } else {
      if (!this.jobitem.selection || this.jobitem.selection === '') {
        this.isOriginalSelectionInOptionList = true;
      } else {
        this.isOriginalSelectionInOptionList = false;
        for (let i = 0; i < this.optionListTmp.length; i++) {
          if (this.jobitem.selection === this.optionListTmp[i].description) {
            this.isOriginalSelectionInOptionList = true;
          }
        }
      }
    }

    this.finaliseOptionSetup();
  }

  finaliseOptionSetup() {
    this.gotOptionList = true;

    if (this.calledFromDropDown) {
      this.isOriginalSelectionInOptionList = false;
    }

    if ((this.jobitem.selection === '' || this.jobitem.selection === null) && !this.calledFromDropDown) {
      this.newSelection = 'Please Select...'; // for dropdown
    } else if (!this.isOriginalSelectionInOptionList) {
      this.newSelection = 'Other - Click here'; // manual selection
    } else {
      this.newSelection = this.jobitem.selection; // for dropdown
    }

    this.setShowPrice();
  }

  setPreviousAndCurrent() {
    let previousCostTypeId = this.costTypeEnum.Priced;
    let previousItemRate = 0;
    let costTypeId = this.costTypeEnum.Priced;
    let newItemRate = 0;
    this.prevAndCurrentValid = false;

    if (this.jobitem.changedByVOId === this.variationId && this.jobitem.changeTypeId === this.changeTypeEnum.Add) {
      // added item so previous is ignored
      previousCostTypeId = this.costTypeEnum.Note; // set to Note as previous is valid

      if (this.currentSelectedOption && this.currentSelectedOption.salesPriceTypeIfAddedId) {
        costTypeId = this.currentSelectedOption.salesPriceTypeIfAddedId;
      }

      if (this.currentSelectedOption && this.currentSelectedOption.salesPriceIfAdded) {
        newItemRate = this.currentSelectedOption.salesPriceIfAdded;
      }

      if (this.currentSelectedOption
        && ((this.currentSelectedOption.salesPriceTypeIfAddedId
          && this.currentSelectedOption.salesPriceTypeIfAddedId !== this.costTypeEnum.Priced)
          || (this.currentSelectedOption.salesPriceIfAdded && this.currentSelectedOption.salesPriceIfAdded !== 0))) {
        this.prevAndCurrentValid = true;
      } else {
        this.prevAndCurrentValid = false;
      }
    } else {
      // if the previous is 'Please Select' then we accept price is zero
      if (((this.jobitem.changedByVOId === this.variationId && this.jobitem.previousSelectedOptionId)
        || (this.jobitem.changedByVOId !== this.variationId && this.jobitem.selectedOptionId))
        && (!this.previousSelectedOption
          || (!this.previousSelectedOption.salesPriceTypeIfChangedInSameListId
            || this.previousSelectedOption.salesPriceTypeIfChangedInSameListId === this.costTypeEnum.Priced)
          && (!this.previousSelectedOption.salesPriceIfChangedInSameList
            || this.previousSelectedOption.salesPriceIfChangedInSameList === 0))) {
        // we don't have a previous price set up so the new is ignored
        this.prevAndCurrentValid = false;
      } else {
        // do we have a new price or type to use?
        if (!this.currentSelectedOption
          || (!this.currentSelectedOption.salesPriceTypeIfChangedInSameListId
            || this.currentSelectedOption.salesPriceTypeIfChangedInSameListId === this.costTypeEnum.Priced)
          && (!this.currentSelectedOption.salesPriceIfChangedInSameList
            || this.currentSelectedOption.salesPriceIfChangedInSameList === 0)) {
          // we don't have a new price set up so the new is ignored
          this.prevAndCurrentValid = false;
        } else {

          // if previous not selected then treat as a note
          if ((this.jobitem.changedByVOId === this.variationId && !this.jobitem.previousSelectedOptionId)
            || (this.jobitem.changedByVOId !== this.variationId && !this.jobitem.selectedOptionId)) {
            previousCostTypeId = this.costTypeEnum.Note;
          } else {
            if (this.previousSelectedOption && this.previousSelectedOption.salesPriceTypeIfChangedInSameListId) {
              previousCostTypeId = this.previousSelectedOption.salesPriceTypeIfChangedInSameListId;
            }
          }

          if (this.previousSelectedOption && this.previousSelectedOption.salesPriceIfChangedInSameList) {
            previousItemRate = this.previousSelectedOption.salesPriceIfChangedInSameList;
          }

          if (this.currentSelectedOption && this.currentSelectedOption.salesPriceTypeIfChangedInSameListId) {
            costTypeId = this.currentSelectedOption.salesPriceTypeIfChangedInSameListId;
          }

          if (this.currentSelectedOption && this.currentSelectedOption.salesPriceIfChangedInSameList) {
            newItemRate = this.currentSelectedOption.salesPriceIfChangedInSameList;
          }

          this.prevAndCurrentValid = true;
        }
      }
    }

    if (!this.isQtyRequired) {
      newItemRate -= previousItemRate;
      previousItemRate = 0;
    }

    this.editForm.patchValue({
      previousCostTypeId: previousCostTypeId,
      previousItemRate: previousItemRate
    });

    if (this.prevAndCurrentValid) {
      this.editForm.patchValue({
        costTypeId: costTypeId,
        newItemRate: newItemRate === 0 ? null : newItemRate
      });
    }
  }

  setPreviousSelectionData() {
    if (!this.alreadyHaveVO || this.jobitem.changeTypeId !== this.changeTypeEnum.Add) {

      if (this.alreadyHaveVO && this.isQtyRequired && this.jobVarCalcs && this.jobVarCalcs.length) {
        // we would have jobvar calc records unless we are accessing old data
        this.editForm.patchValue({
          previousCostTypeId: this.jobVarCalcs[0].costTypeId,
          previousItemRate: this.jobVarCalcs[0].itemRate,
          costTypeId: this.jobVarCalcs[1].costTypeId,
          newItemRate: this.jobVarCalcs[1].itemRate === 0 ? null : this.jobVarCalcs[1].itemRate
        });

        // as we are at the start we check if any prices are outdated
        if (!this.jobitem.hasLinkedItems && !this.jobitem.provisionalSum) {


          if (((this.jobitem.changedByVOId === this.variationId && this.jobitem.previousSelectedOptionId)
            || (this.jobitem.changedByVOId !== this.variationId && this.jobitem.selectedOptionId))
            && (!this.previousSelectedOption
              || (!this.previousSelectedOption.salesPriceTypeIfChangedInSameListId
                || this.previousSelectedOption.salesPriceTypeIfChangedInSameListId === this.costTypeEnum.Priced)
              && (!this.previousSelectedOption.salesPriceIfChangedInSameList
                || this.previousSelectedOption.salesPriceIfChangedInSameList === 0))) {
            // we don't have a previous price set up so the new is ignored
            this.prevAndCurrentValid = false;
          } else {
            // do we have a new price or type to use?
            if (!this.currentSelectedOption
              || (!this.currentSelectedOption.salesPriceTypeIfChangedInSameListId
                || this.currentSelectedOption.salesPriceTypeIfChangedInSameListId === this.costTypeEnum.Priced)
              && (!this.currentSelectedOption.salesPriceIfChangedInSameList
                || this.currentSelectedOption.salesPriceIfChangedInSameList === 0)) {
              // we don't have a new price set up so the new is ignored
              this.prevAndCurrentValid = false;
            } else {
              this.prevAndCurrentValid = true;
              if ((this.previousSelectedOption
                && ((this.previousSelectedOption.salesPriceIfChangedInSameList
                  && this.previousSelectedOption.salesPriceIfChangedInSameList !== this.jobVarCalcs[0].itemRate)))
                || (this.currentSelectedOption
                  && ((this.currentSelectedOption.salesPriceIfChangedInSameList
                    && this.currentSelectedOption.salesPriceIfChangedInSameList !== this.jobVarCalcs[1].itemRate)))) {
                this.notiService.showWarning('Some item rates have been updated since this item was changed. Please review.');
                this.editForm.get('newItemRate').markAsDirty();
              }
            }
          }
        }

      } else if (!this.alreadyHaveVO) {
        // set the rates
        this.setPreviousAndCurrent();
      }
    } else if (this.jobitem.changeTypeId === this.changeTypeEnum.Add && !this.jobitem.hasLinkedItems) {
      if (this.alreadyHaveVO && this.isQtyRequired && this.jobVarCalcs && this.jobVarCalcs.length) {
        // we would have jobvar calc records unless we are accessing old data
        this.editForm.patchValue({
          previousCostTypeId: this.jobVarCalcs[0].costTypeId,
          previousItemRate: this.jobVarCalcs[0].itemRate,
          costTypeId: this.jobVarCalcs[1].costTypeId,
          newItemRate: this.jobVarCalcs[1].itemRate === 0 ? null : this.jobVarCalcs[1].itemRate
        });
      }
    }
  }

  setShowPrice() {
    if ((this.editForm.get('costTypeId').value === this.costTypeEnum.Note)
      || (this.editForm.get('costTypeId').value === this.costTypeEnum.NoCharge)
      || (this.jobitem.provisionalSum && this.jobitem.provisionalSum !== 0)) {
      this.showPrice = false;
    } else {
      this.showPrice = true;
    }

    if ((this.editForm.get('previousCostTypeId').value === this.costTypeEnum.Note)
      || (this.editForm.get('previousCostTypeId').value === this.costTypeEnum.NoCharge)) {
      this.showPreviousPrice = false;
    } else {
      this.showPreviousPrice = true;
    }

    this.costTypeDropdown.forEach(element => {
      if (element.id === this.editForm.get('costTypeId').value) {
        this.costTypeDesc = element.name;
      }
    });
  }

  setSelection(opt: IOptionListHeader) {
    if (this.jobitem.attachmentId) {
      // alert('There is a manual image/attachment for this item that will have to be changed manually');
      this.notiService.showWarning('There is a manual image/attachment for this item that will have to be changed manually');
    }
    if (opt.description !== this.newSelection) {
      this.newSelection = opt.description; // for dropdown

      // need to show we have a different printed desc
      if (opt.printedDescription && opt.printedDescription !== '') {
        this.printedDescription = opt.printedDescription;
      } else {
        this.printedDescription = null;
      }

      if (opt.description === 'Other - Click here') {
        this.isOriginalSelectionInOptionList = false;
        this.selectedOptionId = null;
        this.currentSelectedOption = null;
      } else {
        this.isOriginalSelectionInOptionList = true;
        if (opt.description === 'Please Select...') {
          this.editForm.patchValue({
            selection: ''
          });
          this.selectedOptionId = null;
          this.currentSelectedOption = null;
        } else {
          this.editForm.patchValue({
            selection: opt.description
          });
          this.selectedOptionId = opt.id;
          this.currentSelectedOption = opt; // set the selected option to get price details
        }
      }

      this.editForm.get('selection').markAsDirty();

      this.warningNote = opt.warningNote;
      this.jobitem.isBoldText = opt.isBoldText;
      this.jobitem.optionColour = opt.optionColour;

      // get the price details
      if (!this.jobitem.hasLinkedItems) {
        if (!this._jobItemService.parentLinkedItemChanged(this.jobitem.linkedJobItemId)) {
          this.setPreviousAndCurrent();

          // if previous or new invalid then price TBA
          let price = 0;
          const newItemRate = this.editForm.get('newItemRate').value === null ? 0 : this.editForm.get('newItemRate').value;
          if (this.isQtyRequired) {
            price = this.utilsService.roundEven(newItemRate * this.jobitem.quantity)
              - this.utilsService.roundEven(this.editForm.get('previousItemRate').value * this.editForm.get('previousQuantity').value);
          } else {
            price = newItemRate;
          }

          if (price !== 0
            && (this.editForm.get('costTypeId').value === this.costTypeEnum.Note
              || this.editForm.get('costTypeId').value === this.costTypeEnum.NoCharge)) {
            this.editForm.patchValue({
              costTypeId: this.costTypeEnum.Priced
            });
          }

          if (price === 0 && this.editForm.get('costTypeId').value === this.costTypeEnum.Priced && this.prevAndCurrentValid) {
            if (!this.isQtyRequired || (this.jobitem.quantity && this.jobitem.quantity !== 0)) {
              this.editForm.patchValue({
                costTypeId: this.costTypeEnum.NoCharge
              });
            }
          }
        } else {
          this.editForm.patchValue({
            costTypeId: this.costTypeEnum.Priced,
            newItemRate: null,
            isChecked: false
          });
          this.prevAndCurrentValid = false;
        }
        this.setShowPrice();
      }
    }
  }

  update() {
    this.updateItem(false);
  }

  attachFile() {
    // update with go to attachments true
    this.updateItem(true);
  }

  async close(updatedItem) {
    if (this.updatedItem.refreshLines || this.forceRefresh) {
      this._jobItemService.readJobItems();
    }

    this._activeModal.close(updatedItem);
  }

  async cancel() {
    this._activeModal.dismiss();
  }

  isCheckedClicked() {
    if (this.editForm.get('isChecked').value) {
      if (this._jobItemService.currentVariation.variationType !== this.variationTypeEnum.Office
        && this._jobItemService.currentVariation.variationType !== this.variationTypeEnum.PreContractExFromContract) {
        if ((this.editForm.get('costTypeId').value === this.costTypeEnum.Priced
          && this.jobitem.costTypeId !== this.costTypeEnum.ProvisionalSum)
          || this.editForm.get('costTypeId').value === this.costTypeEnum.EOStandard
          || this.editForm.get('costTypeId').value === this.costTypeEnum.ProvisionalSum) {

          if ((this.isQtyRequired && (!this.editForm.get('quantity').value || this.editForm.get('quantity').value === 0))
            || !this.editForm.get('newItemRate').value || this.editForm.get('newItemRate').value === 0) {
            this.notiService.showWarning('If price is zero please select No Charge or Note');
            this.editForm.patchValue({
              isChecked: false
            });
          }
        }
      }
    }
  }

  connectItem() {
    // connect items to this one
    const modalRef = this.modalService.open(ConnectItemsModalComponent, { windowClass: 'modal-1000' });
    modalRef.componentInstance.jobitem = this.jobitem;
    modalRef.componentInstance.variationId = this.variationId;

    modalRef.result.then((result) => {
      this.returnedFromConnectModal = true;
    }, (reason) => {
    });
  }

  createOption(content) {
    // duplicate all the linked items also if required
    // first confirm
    this.modalService.open(content, { size: 'lg' }).result.then((createOptionResult) => {
      this.loading = true;
      this.subscriptions = this.subscriptions.concat(
        this._jobVarItemService.createJobVarItemOption(this.variationNumber, this.jobitem.changedByJobVarId)
          .subscribe({
            next: () => {
              this.notiService.showInfo('Option Created');
              this.updatedItem = { gotoAttachments: false, refreshLines: true };
              // this.loading = false;
              this.close(this.updatedItem);
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    }, (reason) => {
      // cancelled the create of an option
    });
  }

  setOtherOptionsNotTaken() {
    this.loading = true;
    this.editForm.patchValue({
      itemNotTaken: false
    });
    this.subscriptions = this.subscriptions.concat(
      this._jobVarItemService.setOtherOptionsNotTaken(this.jobitem.changedByJobVarId)
        .subscribe({
          next: () => {
            this.setOtherOptionsNotTakenClicked = true;
            this.updateItem(false);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  setCostType(costTypeId: number) {
    if (costTypeId !== this.editForm.get('costTypeId').value) {
      this.editForm.patchValue({
        costTypeId: costTypeId
      });

      if (costTypeId === this.costTypeEnum.NoCharge || costTypeId === this.costTypeEnum.Note) {
        this.editForm.patchValue({
          newItemRate: null
        });
      } else if (this.prevAndCurrentValid && this.currentSelectedOption && this.currentSelectedOption.salesPriceIfChangedInSameList) {
        this.editForm.patchValue({
          newItemRate: this.currentSelectedOption.salesPriceIfChangedInSameList
        });
      }

      this.resetIsChecked(this.editForm.get('costTypeId').value);

      this.editForm.get('costTypeId').markAsDirty();
      this.setShowPrice();
    }
  }

  resetIsChecked(costTypeId: number) {
    if (this._jobItemService.currentVariation.variationType !== this.variationTypeEnum.Office
      && this._jobItemService.currentVariation.variationType !== this.variationTypeEnum.PreContractExFromContract) {
      if ((!costTypeId || costTypeId === this.costTypeEnum.Priced
        || costTypeId === this.costTypeEnum.EOStandard
        || costTypeId === this.costTypeEnum.ProvisionalSum)
        && (!this.editForm.get('newItemRate').value || this.editForm.get('newItemRate').value === 0)) {
        this.editForm.patchValue({
          isChecked: false
        });
      }

      if (this.isQtyRequired
        && (!costTypeId || costTypeId === this.costTypeEnum.Priced
          || costTypeId === this.costTypeEnum.EOStandard
          || costTypeId === this.costTypeEnum.ProvisionalSum)
        && (!this.editForm.get('quantity').value || this.editForm.get('quantity').value === 0)) {
        this.editForm.patchValue({
          isChecked: false
        });
      }
    }
  }

  setCostTypePrevious(costTypeId: number) {
    if (costTypeId !== this.editForm.get('previousCostTypeId').value) {
      this.editForm.patchValue({
        previousCostTypeId: costTypeId
      });

      if (costTypeId === this.costTypeEnum.NoCharge || costTypeId === this.costTypeEnum.Note) {
        this.editForm.patchValue({
          previousItemRate: 0
        });
      } else if (this.previousSelectedOption && this.previousSelectedOption.salesPriceIfChangedInSameList) {
        this.editForm.patchValue({
          previousItemRate: this.previousSelectedOption.salesPriceIfChangedInSameList
        });
      }

      this.editForm.get('previousCostTypeId').markAsDirty();
      this.setShowPrice();
    }
  }

  setColour(colour: number) {
    if (colour !== this.editForm.get('noteColour').value) {
      this.currentColour = this.colours[colour].name;

      this.editForm.patchValue({
        noteColour: colour
      });
      this.editForm.get('noteColour').markAsDirty();
    }
  }

  estimatePrice() {
    if (!this.alreadyHaveVO) {
      this.addAndEstimate = true;
      this.updateItem(false);
    } else {
      // here we use estimating to calc the price
      const modalRef = this.modalService.open(EstimatingModalComponent,
        { windowClass: 'modal-est3', backdrop: 'static', keyboard: false, scrollable: true });
      modalRef.componentInstance.jobVarItemId = this.jobitem.changedByJobVarId;
      modalRef.componentInstance.itemDescription = this.jobitem.itemDescription;
      modalRef.componentInstance.selection = this.jobitem.selection;
      modalRef.componentInstance.variationId = this.variationId;

      modalRef.result.then((result) => {
        if (result && (+result)) {
          this.editForm.patchValue({
            newItemRate: +result
          });
        } else {
          this.editForm.patchValue({
            newItemRate: 0,
            isChecked: false
          });
        }
        this.editForm.get('newItemRate').markAsDirty();
        this.anyJobVarCostItems();
      }, () => {
      });
    }
  }

  checkNoCents() {
    // we don't allow cents in the price - reports round
    let newItemRate = this.editForm.get('newItemRate').value;
    if (newItemRate) {
      if (!this.isQtyRequired) {
        const val = newItemRate % 1;
        newItemRate = newItemRate - val
        if (val !== 0) {
          // alert('No cents allowed');
          this.notiService.showWarning('No cents allowed');
        }
      }
      this.editForm.patchValue({
        newItemRate: newItemRate,
      });

      if (this.isQtyRequired) {
        this.editForm.patchValue({
          previousItemRate: newItemRate
        });
      }
      this.editForm.get('newItemRate').markAsDirty();
    }
  }

  moveItem() {
    // move added items around
    const modalRef = this.modalService.open(MoveItemComponent, { windowClass: 'modal-1000' });
    modalRef.componentInstance.jobItem = this.jobitem;

    modalRef.result.then(() => {
      this.notiService.showInfo('Item Moved');
      this.updatedItem = { gotoAttachments: false, refreshLines: true };
      // this.loading = false;
      this.close(this.updatedItem);
    }, () => {
    });
  }

  getRoundedPrice(x: number) {
    return this.utilsService.roundEven(x);
  }

  isAcceptedIntoContractClicked(e) {
    this.subscriptions = this.subscriptions.concat(
      this._jobVarItemService.updateJobVarItemIsAcceptedIntoContract(this.jobitem.changedByJobVarId, e.value)
        .subscribe({
          next: () => {
            this.jobitem.isAcceptedIntoContract = e.value;
            this.notiService.showSuccess('Updated');
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  anyJobVarCostItems() {
    if (this.jobitem.changedByJobVarId && this.jobitem.changedByVOId === this.variationId) {
      this.subscriptions = this.subscriptions.concat(
        this.estimatingService.getJobVarCostItems(this.jobitem.changedByJobVarId)
          .subscribe({
            next: (result) => {
              if (result && result.length && result.find(i => i.rate)) {
                this.hasEstimatingItems = true;
              }
            },
            error: () => {
            }
          })
      );
    }

    // previous cost?
  }
}
