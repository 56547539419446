import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JobComponent } from './job.component';
import { JobDetailsComponent } from './job-details.component';
import { MsalGuard } from '@azure/msal-angular';
import { RefreshGuard } from '../services/refresh-guard.service';

const routes: Routes = [
  { path: 'jobs', component: JobComponent, canActivate: [MsalGuard, RefreshGuard] },
  { path: 'job-details', component: JobDetailsComponent, canActivate: [MsalGuard, RefreshGuard] },
  { path: 'job-details/:jobNum', component: JobDetailsComponent, canActivate: [MsalGuard, RefreshGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JobsRoutingModule { }
