import { SearchResult } from './../../dtos/search-result';
import { GlobalService } from './../../services/global.service';
import { JobService } from './../../services/felixApi/job.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IOptionListHeader } from '../../dtos/option-list-header';
import { OptionListService } from '../../services/felixApi/option-list.service';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { TreeComponent, TreeNode } from '@circlon/angular-tree-component';
import { JobItemService } from '../../services/felixApi/job-item.service';
import { IJob } from '../../dtos/job';
import DataSource from 'devextreme/data/data_source';
import { JobRole } from '../../dtos/job-role';
import { RoleTypeEnum } from '../../dtos/role-type.enum';

@Component({
  selector: 'js-select-option-modal',
  templateUrl: './select-option-modal.component.html',
  styleUrls: ['./select-option-modal.component.scss']
})
export class SelectOptionModalComponent implements OnInit, OnDestroy {

  @ViewChild('tree') tree: TreeComponent;

  loading = true;
  treeOptionNodes: IOptionListHeader[] = [];
  treeOptionOptions = {};
  subscriptions: Subscription[] = [];
  selectedOptionListId: number;
  searchText = '';
  pdfReport: any;
  activeJobsOnly = true;
  includeChildOptions = true;
  includeChildOptionsOnly: boolean;
  showIncludeChildrenCheckBox: boolean;
  includeChangedItems: boolean;
  currentJob: IJob;
  jobNumber = '';
  dataSource: DataSource<any, any>;
  gridHeight: number;
  popupHeight: number;
  popupVisible = false;
  allJobRoles: JobRole[];
  siteSupervisorName: string;
  liaisonName: string;
  prestartOfficer: string;
  constructionCoordinator: string;
  preConstructionCoordinator: string;

  constructor(private activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private _optionListService: OptionListService,
    private _jobItemService: JobItemService,
    private jobService: JobService,
    private notiService: NotificationService) {
    this.calculateCurrentActivity = this.calculateCurrentActivity.bind(this);
    this.calculateSiteSupervisor = this.calculateSiteSupervisor.bind(this);
    this.calculateLiaison = this.calculateLiaison.bind(this);
    this.calculatePrestartOfficer = this.calculatePrestartOfficer.bind(this);
    this.calculateConstructionCoordinator = this.calculateConstructionCoordinator.bind(this);
    this.calculatePreConstructionCoordinator = this.calculatePreConstructionCoordinator.bind(this);
  }

  ngOnInit() {
    this.popupHeight = window.innerHeight < 850 ? window.innerHeight : 850;
    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  cancel() {
    this.activeModal.dismiss();
  }

  getData() {
    this.subscriptions = this.subscriptions.concat(
      this.jobService.getAllJobRoles()
        .subscribe({
          next: (res) => {
            this.allJobRoles = res;
            this.siteSupervisorName = this.allJobRoles.find(i => i.roleId === RoleTypeEnum.SiteManager)?.companyRoleDescription;
            this.liaisonName = this.allJobRoles.find(i => i.roleId === RoleTypeEnum.LiaisonOfficer)?.companyRoleDescription;
            this.prestartOfficer = this.allJobRoles.find(i => i.roleId === RoleTypeEnum.PreStartOfficer)?.companyRoleDescription;
            this.constructionCoordinator = this.allJobRoles.find(i => i.roleId === RoleTypeEnum.ConstructionCoordinator)?.companyRoleDescription;
            this.preConstructionCoordinator = this.allJobRoles.find(i => i.roleId === RoleTypeEnum.PreconstructionCoordinator)?.companyRoleDescription;
            this.setupOptionNodes();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  // set up the tree nodes of job items to allow linking
  setupOptionNodes() {
    this.treeOptionOptions = {
      idField: 'id',
      displayField: 'description',
      childrenField: 'children',
      useVirtualScroll: true,
      nodeHeight: 22,
      scrollOnActivate: false
    };

    if (this._optionListService.getCurrentOptionNodes() === null) {
      this.subscriptions = this.subscriptions.concat(
        this._optionListService.getOptionListChildren(0, true, false)
          .subscribe({
            next: (treeOptionNodes) => {
              this.treeOptionNodes = treeOptionNodes;
              this.loading = false;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    } else {
      this.treeOptionNodes = this._optionListService.getCurrentOptionNodes();
      this.loading = false;
    }
  }

  onOptionTreeInit(tree: TreeComponent) {
    tree.treeModel.virtualScroll.setViewport(tree.treeModel.virtualScroll.viewport);
  }

  // set the linked option list when clicked on from tree
  setOptionList(node: TreeNode) {
    this.selectedOptionListId = node.data.id; // selected option id
    this.searchText = '';
    if (node.hasChildren) {
      this.showIncludeChildrenCheckBox = true;
    } else {
      this.showIncludeChildrenCheckBox = false;
    }
  }

  changeSearchText() {
    const node = this.tree.treeModel.getFocusedNode();
    node.setIsSelected(false);
    this.tree.treeModel.update();
    this.selectedOptionListId = null;
  }

  runSearch() {
    // run the search
    this.loading = true;
    this.subscriptions = this.subscriptions.concat(
      this._jobItemService.searchJobItems(this.selectedOptionListId, this.searchText, this.activeJobsOnly,
        this.includeChildOptions, this.includeChildOptionsOnly, this.includeChangedItems, this.currentJob?.id)
        .subscribe({
          next: (res) => {
            this.setupDataSet(res);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  setupDataSet(dataset: SearchResult[]) {
    this.gridHeight = window.innerHeight - 500;
    this.dataSource = null;

    dataset.forEach(element => {
      const job = this.jobService.jobs.find(i => i.jobNumber === element.jobNo);
      if (job) {
        element.jobString = this.globalService.getJobString(job, false);
      }
    });

    this.dataSource = new DataSource({
      key: 'id',
      load: () => dataset
    });

    this.loading = false;
    this.popupVisible = true;
  }

  refreshJobNumber(jobNumber: string) {
    this.jobNumber = jobNumber;
    this.currentJob = this.jobService.jobs.find(i => i.jobNumber === jobNumber);
  }

  calculateCurrentActivity(data) {
    const job = this.jobService.jobs.find(i => i.jobNumber === data.jobNo);
    const jobExtra = this.jobService.jobExtras?.find(i => i.jobId === job?.id);
    return this.jobService.calcJobStatus(job, jobExtra);
  }

  calculateSiteSupervisor(data) {
    const job = this.jobService.jobs.find(i => i.jobNumber === data.jobNo);
    const jobRole = this.allJobRoles?.find(i => i.jobId === job?.id && i.roleId === RoleTypeEnum.SiteManager);
    return jobRole?.user?.fullName;
  }

  calculateLiaison(data) {
    const job = this.jobService.jobs.find(i => i.jobNumber === data.jobNo);
    const jobRole = this.allJobRoles?.find(i => i.jobId === job?.id && i.roleId === RoleTypeEnum.LiaisonOfficer);
    return jobRole?.user?.fullName;
  }

  calculatePrestartOfficer(data) {
    const job = this.jobService.jobs.find(i => i.jobNumber === data.jobNo);
    const jobRole = this.allJobRoles?.find(i => i.jobId === job?.id && i.roleId === RoleTypeEnum.PreStartOfficer);
    return jobRole?.user?.fullName;
  }

  calculateConstructionCoordinator(data) {
    const job = this.jobService.jobs.find(i => i.jobNumber === data.jobNo);
    const jobRole = this.allJobRoles?.find(i => i.jobId === job?.id && i.roleId === RoleTypeEnum.ConstructionCoordinator);
    return jobRole?.user?.fullName;
  }

  calculatePreConstructionCoordinator(data) {
    const job = this.jobService.jobs.find(i => i.jobNumber === data.jobNo);
    const jobRole = this.allJobRoles?.find(i => i.jobId === job?.id && i.roleId === RoleTypeEnum.PreconstructionCoordinator);
    return jobRole?.user?.fullName;
  }
}
