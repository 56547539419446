import { CompanyService } from './company.service';
import { UserService } from './user.service';
import { throwError as observableThrowError, Observable, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IJob } from '../../dtos/job';
import { HideSection } from '../../dtos/hide-section';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { JobRole } from '../../dtos/job-role';
import { PDFReports } from '../../dtos/pdf-report';
import { SelectedVariationTypes } from '../../dtos/selectedVariationTypes';
import { JobExtra } from '../../dtos/job-extra';
import { JobVarItemService } from './job-var-item.service';
import { HttpService } from '../http.service';
import { AuthService } from '../auth.service';
import { CompanyAttachmentSetting } from '../../dtos/company-attachment-setting';
import { CompanyRole } from '../../dtos/companyRole';
import { User } from '../../dtos/user';
import { EstateService } from './estate.service';
import { JobTypeEnum } from '../../dtos/job-type.enum';
import { TrackingFieldsService } from './tracking-fields.service';
import { RoleTypeEnum } from '../../dtos/role-type.enum';
import { JobSalesConsultant } from '../../dtos/job-sales-consultant';

@Injectable()
export class JobService {
  currentJob: IJob;
  currentJobNum: string;
  currentJobString: string;
  separateAttachments: CompanyAttachmentSetting[];
  currentJobExtra: JobExtra;
  jobs: IJob[] = [];
  companyRoles: CompanyRole[] = [];
  companyRolesCompany: number;
  jobExtras: JobExtra[] = [];
  jobExtrasCompany: number;
  currentVariationId: any;
  contractValue: number;
  companyJobRoles: JobRole[] = [];
  currentJobRoles: JobRole[] = [];

  constructor(
    private httpService: HttpService,
    private auth: AuthService,
    private jobVarItemService: JobVarItemService,
    private userService: UserService,
    private companyService: CompanyService,
    private estateService: EstateService,
    private _http: HttpClient,
    private trackingFieldService: TrackingFieldsService,
    private globalService: GlobalService) { }

  // get one job
  getJob(jobNo): Observable<IJob> {
    return this._http.get<IJob>(this.globalService.getApiUrl() + '/jobs/' + jobNo,
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          if (res) {
            // check we have this job
            if (!this.jobs || !this.jobs.length) {
              this.jobs.push(res);
            } else {
              // replace it
              const foundId = this.jobs.findIndex(i => i.id === res.id);
              if (foundId >= 0) {
                this.jobs.splice(foundId, 1);
              }
              this.jobs.push(res);
            }
          }
        }),
        catchError(this.handleError));
  }

  // get one job for duplicate
  getDuplicateJobs(lotAddress: object): Observable<IJob[]> {
    return this._http.patch<IJob[]>(this.globalService.getApiUrl() + '/jobs/lot-check', JSON.stringify(lotAddress),
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  // getJobs(): Observable<IJob[]> {
  //   return this._http.get<IJob[]>(this.globalService.getApiUrl() + '/jobs/',
  //     this.httpService.getHttpOptions()).pipe(
  //       catchError(this.handleError));
  // }

  // search for jobs by a matching contract name string
  getJobsByName(searchString): Observable<IJob[]> {
    if (searchString && searchString !== '') {
      return this._http.get<IJob[]>(this.globalService.getApiUrl() + '/jobs?jobSearchType=ContractName&searchString=' + searchString,
        this.httpService.getHttpOptions()).pipe(
          catchError(this.handleError));
    } else {
      return this._http.get<IJob[]>(this.globalService.getApiUrl() + '/jobs?jobSearchType=ContractName',
        this.httpService.getHttpOptions()).pipe(
          catchError(this.handleError));
    }
  }

  getMyJobs() {
    return this._http.get<IJob[]>(this.globalService.getApiUrl() + '/jobs?jobSearchType=ContractName&userId='
      + this.auth.getCurrentUserId(), this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getMyJobsWithExtras(): Observable<IJob[]> {
    return forkJoin(
      [this.getMyJobs(),
      this.getAllJobExtras(false),
      this.userService.getAllCurrCompUsers(true),
      this.companyService.getCompanyActivities(false, true)]
    )
      .pipe(map(
        ([jobs]) => {
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getTemplateJobsWithExtras(useCache): Observable<IJob[]> {
    return forkJoin(
      [this.getTemplateJobs(),
      this.getAllJobExtras(useCache),
      this.userService.getAllCurrCompUsers(useCache),
      this.companyService.getCompanyActivities(false, useCache)]
    )
      .pipe(map(
        ([jobs]) => {
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getJobsByAddressWithExtras(useCache: boolean): Observable<IJob[]> {
    return forkJoin(
      [this.getJobsByAddress(''),
      this.getAllJobExtras(useCache),
      this.userService.getAllCurrCompUsers(useCache),
      this.companyService.getCompanyActivities(false, useCache)]
    )
      .pipe(map(
        ([jobs]) => {
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getJobsWithRoles(useCache: boolean): Observable<IJob[]> {
    return forkJoin(
      [this.getJobsByAddressWithExtras(useCache),
      this.getAllJobRoles()]
    )
      .pipe(map(
        ([jobs]) => {
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getJobsByAssignee(userId: number) {
    return this._http.get<IJob[]>(this.globalService.getApiUrl() + '/jobs?jobSearchType=ContractName&userId='
      + userId, this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  // search for jobs by a matching contract name string
  getJobsByAddress(searchString): Observable<IJob[]> {
    return this._http.get<IJob[]>(this.globalService.getApiUrl() + '/jobs?jobSearchType=JobAddress&searchString=' + searchString,
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.jobs = res;
        }),
        catchError(this.handleError));
  }

  // search for jobs by a matching contract name string
  getTemplateJobs(): Observable<IJob[]> {
    return this._http.get<IJob[]>(this.globalService.getApiUrl() + '/jobs?jobSearchType=Templates',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  // get the next numeric job number available - ignores templates
  getNextJobNumber(divisionId: number): Observable<string> {
    let url = this.globalService.getApiUrl() + '/jobs/next-number';
    if (divisionId) {
      url += '?divisionId=' + divisionId;
    }
    return this._http.get<string>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  addJob(itm: any): Observable<IJob> {
    const url = this.globalService.getApiUrl() + '/jobs/';
    return this._http.post<IJob>(url, JSON.stringify(itm, this.httpService.jsonDateReplacer), this.httpService.getHttpOptions());
  }

  addMaintenanceJob(dataRecord: any): Observable<IJob> {
    const url = this.globalService.getApiUrl() + '/jobs/maintenance';
    return this._http.post<IJob>(url, JSON.stringify(dataRecord, this.httpService.jsonDateReplacer), this.httpService.getHttpOptions());
  }

  updateJob(itm: any) {
    const url = this.globalService.getApiUrl() + '/jobs/' + this.globalService.getCurrentJob();
    return this._http.patch(url, JSON.stringify(itm, this.httpService.jsonDateReplacer), this.httpService.getHttpOptions());
  }

  updateJobNumber(jobId: number, newJobNumber: string) {
    const url = this.globalService.getApiUrl() + '/jobs/' + jobId + '/update-job-number/' + newJobNumber;
    return this._http.patch(url, '', this.httpService.getHttpOptions());
  }

  deleteJob(jobNumber: string) {
    const url = this.globalService.getApiUrl() + '/jobs/' + jobNumber;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }


  // get the roles for each job
  getCompanyRoles(): Observable<CompanyRole[]> {
    if (this.companyRolesCompany === this.globalService.getCurrentCompany().id) {
      return of(this.companyRoles);
    } else {
      const url = this.globalService.getApiUrl() + '/company-roles';
      return this._http.get<CompanyRole[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.companyRoles = res;
          this.companyRolesCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  getAllJobRoles(): Observable<JobRole[]> {
    return this._http.get<JobRole[]>(this.globalService.getApiUrl() + '/jobs/roles',
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.companyJobRoles = res ?? [];
        }),
        catchError(this.handleError));
  }

  getJobRoles(jobNumber: string): Observable<JobRole[]> {
    return this._http.get<JobRole[]>(this.globalService.getApiUrl() + '/jobs/' + jobNumber + '/roles',
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.currentJobRoles = res ?? [];
        }),
        catchError(this.handleError));
  }

  getJobRoleById(jobNumber: string, roleId: number): Observable<JobRole[]> {
    return this._http.get<JobRole[]>(this.globalService.getApiUrl() + '/jobs/' + jobNumber + '/roles?roleId=' + roleId,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  updateJobRoles(jobNumber: string, updatedRoles: any) {
    const url = this.globalService.getApiUrl() + '/jobs/' + jobNumber + '/roles';
    return this._http.post(url, JSON.stringify(updatedRoles), this.httpService.getHttpOptions());
  }

  updateJobAcceptItems(updates: any) {
    const url = this.globalService.getApiUrl() + '/jobs/' + this.globalService.getCurrentJob() + '/accept-into-contract';
    return this._http.post(url, JSON.stringify(updates), this.httpService.getHttpOptions());
  }

  getJobRolesReport(activeJobsOnly: boolean, includeTestJobs: boolean): Observable<PDFReports> {
    const url = this.globalService.getApiUrl() + '/reports/jobs/export?activeJobsOnly=' + activeJobsOnly
      + '&includeTestJobs=' + includeTestJobs;

    return this._http.get<PDFReports>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }



  getTotalContractValue(): Observable<number> {
    const url = this.globalService.getApiUrl() + '/jobs/' + this.globalService.getCurrentJob() + '/contract-value';
    return this._http.get<number>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.contractValue = res;
      }),
      catchError(this.handleError));
  }

  getHideSections(jobId: number): Observable<HideSection[]> {
    return this._http.get<HideSection[]>(this.globalService.getApiUrl() + '/hide-sections/' + jobId,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addDeleteHideSection(jobItemId: number, selectedTypes: SelectedVariationTypes) {
    const url = this.globalService.getApiUrl() + '/hide-section/' + jobItemId;
    return this._http.post(url, JSON.stringify(selectedTypes), this.httpService.getHttpOptions());
  }


  getHideSectionsAndCalcs(jobId: number, jobVariationId: number): Observable<HideSection[]> {
    return forkJoin(
      [
        this.getHideSections(jobId),
        this.jobVarItemService.getAllJobVarCalcs(jobVariationId)
      ]
    )
      .pipe(map(
        ([hideSections]) => {
          return hideSections;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getJobData(useCache: boolean): Observable<User[]> {
    return forkJoin(
      [
        this.userService.getAllCurrCompUsers(useCache),
        this.userService.getDivisions(useCache),
        this.companyService.getCompanyActivities(),
        this.estateService.getEstates(useCache),
        this.trackingFieldService.getHolidays(useCache)
      ]
    )
      .pipe(map(
        ([data]) => {
          return data;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  // extra details for hold flag etc
  getAllJobExtras(useCache: boolean): Observable<JobExtra[]> {
    if (useCache && this.jobExtrasCompany === this.globalService.getCurrentCompany().id
      && this.jobExtras && this.jobExtras.length) {
      return of(this.jobExtras);
    } else {
      return this._http.get<JobExtra[]>(this.globalService.getApiUrl() + '/job-extras?calcCurrentActivity=false',
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.jobExtras = res; this.jobExtrasCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }


  getJobExtras(jobId: number): Observable<JobExtra> {
    if (this.currentJobExtra && this.currentJobExtra.jobId === jobId) {
      return of(this.currentJobExtra);
    }

    return this._http.get<JobExtra>(this.globalService.getApiUrl() + '/job-extras/' + jobId,
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.currentJobExtra = res;
        }),
        catchError(this.handleError));
  }

  addJobExtra(itm: any): Observable<JobExtra> {
    const url = this.globalService.getApiUrl() + '/job-extras/';
    return this._http.post<JobExtra>(url, JSON.stringify(itm, this.httpService.jsonDateReplacer), this.httpService.getHttpOptions());
  }

  updateJobExtra(id: number, itm: any, revalueJobEstimatingItems: boolean = false): Observable<JobExtra> {
    let url = this.globalService.getApiUrl() + '/job-extras/' + id;
    if (revalueJobEstimatingItems) {
      url += '?revalueJobEstimatingItems=true';
    }
    return this._http.patch<JobExtra>(url, JSON.stringify(itm, this.httpService.jsonDateReplacer), this.httpService.getHttpOptions());
  }


  getSeparateAttachmentSettings(): Observable<CompanyAttachmentSetting[]> {
    if (this.separateAttachments && this.separateAttachments.length) {
      return of(this.separateAttachments);
    } else {
      const settingBaseUrl = this.globalService.getApiUrl() + '/company-attachment-settings/separate-only';
      return this._http.get<CompanyAttachmentSetting[]>(settingBaseUrl, this.httpService.getHttpOptions())
        .pipe(map(res => {
          this.separateAttachments = res;
          return res;
        })
        );
    }
  }

  getCompanyAttachments(jobNumber: string, isSalesQuote: boolean): Observable<PDFReports[]> {
    return this._http
      .get<PDFReports[]>(this.globalService.getApiUrl() + '/reports/job/' + jobNumber + '/attachments?isSalesQuote=' + isSalesQuote,
        this.httpService.getHttpOptions()).pipe(catchError(this.handleError));
  }


  getReplacementAddendaPDF(jobId: number): Observable<PDFReports> {
    return this._http.get<PDFReports>(this.globalService.getApiUrl() + '/job-addenda/' + jobId,
      this.httpService.getHttpFileOptions()).pipe(catchError(this.handleError));
  }

  loadReplacementAddendaPDF(jobId: number, formData: FormData): Observable<any> {
    const url = this.globalService.getApiUrl() + '/job-addenda/' + jobId;
    return this._http.post(url, formData, this.httpService.getHttpFileOptions());
  }

  deleteReplacementAddendaPDF(jobId: number) {
    const url = this.globalService.getApiUrl() + '/job-addenda/' + jobId;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }


  checkSalesRepAndHasJobRole(jobNumber: string): Observable<JobSalesConsultant> {
    return forkJoin(
      [
        this.userService.getCompanyRoleUsersByRoleID(RoleTypeEnum.SalesRep),
        this.getJobRoles(jobNumber)
      ]
    )
      .pipe(map(
        ([salesReps, jobRoles]) => {
          const currentUserId = this.auth.getCurrentUserId();
          const isRep = salesReps.find(i => i.userId === currentUserId);
          const salesRepForJob = jobRoles.find(i => i.roleId === RoleTypeEnum.SalesRep);
          const notRepOrRepForThisJob = (isRep === undefined || salesRepForJob !== undefined || salesRepForJob.userId === currentUserId);
          return new JobSalesConsultant (notRepOrRepForThisJob, salesRepForJob?.user?.fullName);
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  calcJobStatus(job: IJob, jobExtra: JobExtra) {
    if (jobExtra?.maintenanceCompleteDate) {
      return 'Maintenance Complete';
    } else {
      let currentActivity = this.companyService
        .activities?.find(i => i.id === jobExtra?.currentActivityId)?.description;

      if ((!currentActivity || currentActivity === '')) {
        if (job?.jobTypeId === JobTypeEnum.StandardJob) {
          return 'Pending Sale';
        } else {
          return '';
        }
      } else {
        return currentActivity;
      }
    }
  }


  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
