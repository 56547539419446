export enum AttachmentTypeEnum {
    JPEG = 1,
    JPG = 1,
    PDF = 2,
    PNG = 3,
    DOCX = 4,
    XLS = 5,
    XLSM = 6,
    XLSX = 7,
    DXF = 8,
    DWG = 9,
    HEIC = 13
}
