import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { InvoiceService } from '../services/felixApi/invoice.service';
import { NotificationService } from '../services/notification.service';
import { FileService } from '../services/file.service';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'js-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  invoiceForm: UntypedFormGroup;
  loading = false;
  updating = false;
  wrongFileType = false;
  filesToUpload: File[] = [];
  // extraFilesToUpload: FileList;
  // fileInputId: number;
  fileReadyToLoad = false;

  constructor(
    private _fb: UntypedFormBuilder,
    private _invoiceService: InvoiceService,
    private router: Router,
    private fileService: FileService,
    private notiService: NotificationService,
    private utilsService: UtilsService) { }

  ngOnInit() {
    this.setupForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  back() {
    // go back to list
    this.router.navigate(['invoicelist']);
  }

  setupForm() {
    this.invoiceForm = this._fb.group({
      invoiceNumber: ['', Validators.required],
      invoiceDate: ['', Validators.required],
      orderNumber: ['', Validators.required],
      description: '',
      totalExGST: [null, Validators.required],
      totalGST: [null, Validators.required],
      totalIncGST: [null, Validators.required],
    });
  }

  calcTotalFromTotalExGST() {
    this.invoiceForm.patchValue({
      totalExGST: this.utilsService.roundEven(this.invoiceForm.get('totalExGST').value),
      totalGST: this.utilsService.roundEven(this.invoiceForm.get('totalExGST').value * 0.1),
      totalIncGST: this.utilsService.roundEven(this.invoiceForm.get('totalExGST').value) + this.utilsService.roundEven(this.invoiceForm.get('totalExGST').value * 0.1)
    });
  }

  calcTotalFromTotalGST() {
    this.invoiceForm.patchValue({
      totalGST: this.utilsService.roundEven(this.invoiceForm.get('totalGST').value),
      totalIncGST: this.invoiceForm.get('totalExGST').value + this.utilsService.roundEven(this.invoiceForm.get('totalGST').value)
    });
  }

  handleFileInput(eventRecord) {
    for (let i = 0; i < eventRecord.value.length; i++) {
      const fileType = this.fileService.getFileType(eventRecord.value[i].name);
      if (!this.fileService.typeIsImageOrPDF(fileType)) {
        this.wrongFileType = true;
        return;
      }
    }
    this.wrongFileType = false;
    this.filesToUpload = eventRecord.value;
    this.fileReadyToLoad = true;
  }

  submit() {
    // load invoice
    this.updating = true;

    const invoiceDetails = {
      invoiceNumber: this.invoiceForm.get('invoiceNumber').value,
      invoiceDate: this.invoiceForm.get('invoiceDate').value,
      orderNumber: this.invoiceForm.get('orderNumber').value,
      description: this.invoiceForm.get('description').value,
      totalExGST: this.invoiceForm.get('totalExGST').value == null ? 0 : this.invoiceForm.get('totalExGST').value,
      totalGST: this.invoiceForm.get('totalGST').value == null ? 0 : this.invoiceForm.get('totalGST').value,
      totalIncGST: this.invoiceForm.get('totalIncGST').value == null ? 0 : this.invoiceForm.get('totalIncGST').value
    };

    this.subscriptions = this.subscriptions.concat(
      this._invoiceService.addInvoice(invoiceDetails)
        .subscribe({
          next: (invoice) => {
            // now load PDF
            const fileList: File[] = this.filesToUpload;
            const file: File = fileList[0];
            const formData: FormData = new FormData();
            formData.append('image', file, file.name);
            this.subscriptions = this.subscriptions.concat(
              this._invoiceService.postInvoiceAttachment(invoice.id, formData)
                .subscribe({
                  next: (attachmentId) => {
                    this.back();
                  },
                  error: (err) => {
                    this.notiService.notify(err);
                    this.updating = false;
                  }
                })
            );
          },
          error: (err) => {
            this.notiService.notify(err);
            this.updating = false;
          }
        })
    );
  }
}
