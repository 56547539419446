<span ngbDropdown autoClose="outside" class="voClass" #selectionDrop="ngbDropdown">
  <button class="btn-sm btn-outline-primary dropdownHeight31" id="dropdownBasic1"
    ngbDropdownToggle>{{variationString}}</button>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdownmenuclass">
    <!-- until service returns only the ones required test for Ids equal -->
    <div *ngFor="let varn of updatedVariations">
      <div *ngIf="varn.variationNumber === 0" class="dropdown-item">--- <b>Approved/Cancelled</b> ---</div>
      <button *ngIf="varn.variationNumber !== 0" class="dropdown-item"
        (click)='setVariationNum(varn, true); selectionDrop.close();'>
        <span [class.strikeclass]="varn.statusId === variationStatusEnum.Cancelled">
          {{varn.displayedVariationNumber}}: {{varn.title}}
        </span>
      </button>
    </div>
  </div>
</span>
