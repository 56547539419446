import { Component, Input, OnInit, HostListener, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { IJobItem } from '../dtos/job-item';
import { IJobItemAttachment } from '../dtos/job-item-attachment';
import { JobItemAttachmentService } from '../services/felixApi/job-item-attachment.service';
import { JobVarItemAttachmentService } from '../services/felixApi/job-var-item-attachment.service';
import { JobDocumentAttachmentService } from '../services/felixApi/job-document-attachment.service';
import { PDFReports } from '../dtos/pdf-report';
import { NotificationService } from '../services/notification.service';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { JobService } from '../services/felixApi/job.service';
import { AttachmentTypeEnum } from './../dtos/attachment-type.enum';
import { GlobalService } from '../services/global.service';
import { NgxExtendedPdfViewerService, ScrollModeType } from 'ngx-extended-pdf-viewer';
import { IJobDocument } from '../dtos/job-document';
import { SaveDocCopyComponent } from './save-doc-copy/save-doc-copy.component';
import { ConfigurationService } from '../services/felixApi/configuration.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'js-show-pdf',
  templateUrl: './show-pdf.component.html',
  styleUrls: ['./show-pdf.component.scss']
})

/* Shows pdf attachment of either a job or variation, depending on what @Input is passed */
export class ShowPdfComponent implements OnInit, OnDestroy {
  @Input() variationNumber: number;
  @Input() jobitem: IJobItem;
  @Input() printImages: boolean;
  @Input() printPrices: boolean;
  @Input() printNotApplicable: boolean;
  @Input() printNonPrintItems: boolean;
  @Input() printConnectedTogether: boolean;
  @Input() printVONumber: boolean;
  @Input() jobDocumentId: number;
  @Input() pdfReport: PDFReports;
  @Input() exportToExcel: boolean;
  @Input() printProvisionalSums: boolean;
  @Input() isSalesQuote: boolean;
  @Input() printWholeAddendum: boolean;
  @Input() printItemsNotTaken: boolean;
  @Input() printChangeHistory: boolean;
  @Input() printIncludedAmounts: boolean;
  @Input() printSalesQuoteOnly: boolean;
  @Input() printPdfAttachments: boolean;
  @Input() printFromSalesPriceOnwards: boolean;
  @Input() quoteDate: string;
  @Input() quoteExpiryDate: string;
  @Input() exportToXML: boolean;
  @Input() outputFileName: string;
  @Input() isPriceGuide: boolean;
  @Input() printQuantities: boolean;
  @Input() selectedCategories: number[] = null;
  @Input() jobNumber: string;
  @Input() sharePointFileId: string;
  @Input() showSignatureSection: boolean
  @Input() showCheckBoxes: boolean;
  @Input() printWithHighlighting: boolean;
  @Input() useUploadedAddenda: boolean; // replacement addenda pdf
  @Input() jobDocumentData: IJobDocument;

  COMPONENT_NAME = 'show-pdf';
  subscriptions: Subscription[] = [];

  jobItemAttachment: IJobItemAttachment;
  loading = true;
  errorMessage = '';
  pdf: any;
  blob: any;
  page = 1;
  totalPages: number;
  isLoaded = false;
  pageRotation = 0;
  zoom = .95;
  saveName: string;
  isRestrictedForm = false; // if Client or Trade or the screen width is too small then we restrict the form
  fileDownloaded = false;
  mobileFriendlyZoomSetting = '150%';
  isMobile = false;
  jobAddressString = '';
  pdfHeight: number;
  scrollMode: ScrollModeType = ScrollModeType.vertical;
  formDataStore: { [fieldName: string]: string; };
  formEnabled = false;
  disableFormText = 'Enable Merge';
  formDataSetup: boolean;
  isClientOrAssociate = true;

  // get the width of the screen
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.calcWidthDeduct();
  }
  constructor(
    private _jobItemAttachmentService: JobItemAttachmentService,
    private _jobVarItemAttachmentService: JobVarItemAttachmentService,
    private _jobDocAttachmentService: JobDocumentAttachmentService,
    private _authService: AuthService,
    private _jobService: JobService,
    private globalService: GlobalService,
    private notiService: NotificationService,
    private deviceService: DeviceDetectorService,
    private activeModal: NgbActiveModal,
    protected sanitizer: DomSanitizer,
    private pdfViewerService: NgxExtendedPdfViewerService,
    private modalService: NgbModal,
    private configurationService: ConfigurationService
  ) {
  }

  ngOnInit() {
    this.isMobile = (this.deviceService.isMobile() || this.deviceService.isTablet()) && this.deviceService.browser !== 'Firefox';
    this.isClientOrAssociate = this._authService.isClient() || this._authService.isAssociate();

    if (this._jobService.currentJob) {
      this.jobAddressString = this._jobService.currentJob.jobNumber + ' - '
        + this.globalService.getJobString(this._jobService.currentJob, false);
    }

    this.calcWidthDeduct();
    this.openFile();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setupFormData() {
    if (!this.formDataSetup) {
      this.formDataStore = this.configurationService.formData();
      this.formDataSetup = true;
    }
  }

  public get formData(): { [fieldName: string]: string } {
    return {
      JobNumber: this.formDataStore.JobNumber,
      JobAddress: this.formDataStore.JobAddress,
      ContractName: this.formDataStore.ContractName,
      PracticalCompletionDate: this.formDataStore.PracticalCompletionDate,
      HandoverDate: this.formDataStore.HandoverDate,
      Salutation: this.formDataStore.Salutation,
      LotNumber: this.formDataStore.LotNumber,
      StreetNumber: this.formDataStore.StreetNumber,
      StreetName1: this.formDataStore.StreetName1,
      StreetName2: this.formDataStore.StreetName2,
      SuburbTown: this.formDataStore.SuburbTown,
      State: this.formDataStore.State,
      PostCode: this.formDataStore.PostCode,
      ClientEmail: this.formDataStore.ClientEmail,
      PhoneNumber1: this.formDataStore.PhoneNumber1,
      PhoneNumber2: this.formDataStore.PhoneNumber2,
      SalesRep: this.formDataStore.SalesRep,
      DepositAmount: this.formDataStore.DepositAmount,
      ContractTotal: this.formDataStore.ContractTotal,
      SalesDate: this.formDataStore.SalesDate,
      ContractSignedDate: this.formDataStore.ContractSignedDate,
      HouseTypeDescription: this.formDataStore.HouseTypeDescription,
      PlanNumber: this.formDataStore.PlanNumber,
      Volume: this.formDataStore.Volume,
      Folio: this.formDataStore.Folio,
      Council: this.formDataStore.Council,
      Estate: this.formDataStore.Estate,
      LandZoning: this.formDataStore.LandZoning,
      LandTitleType: this.formDataStore.LandTitleType,
      LandType: this.formDataStore.LandType,
      SiteManager: this.formDataStore.SiteManager,
      LiaisonOfficer: this.formDataStore.LiaisonOfficer,
      DepositPaid: this.formDataStore.DepositPaid,
      DepositBalanceDue: this.formDataStore.DepositBalanceDue,
      TitleDueDate: this.formDataStore.TitleDueDate,
      ClientLotNumber: this.formDataStore.ClientLotNumber,
      ClientStreetNumber: this.formDataStore.ClientStreetNumber,
      ClientStreetName1: this.formDataStore.ClientStreetName1,
      ClientStreetName2: this.formDataStore.ClientStreetName2,
      ClientSuburbTown: this.formDataStore.ClientSuburbTown,
      ClientState: this.formDataStore.ClientState,
      ClientPostCode: this.formDataStore.ClientPostCode,
      Date: this.formDataStore.Date,
      Date2: this.formDataStore.Date2,
      Text: this.formDataStore.Text
    };
  }

  public set formData(data: { [fieldName: string]: string }) {
    this.formDataStore.JobNumber = data.JobNumber as string;
    this.formDataStore.JobAddress = data.JobAddress as string;
    this.formDataStore.ContractName = data.ContractName as string;
    this.formDataStore.PracticalCompletionDate = data.PracticalCompletionDate as string;
    this.formDataStore.HandoverDate = data.HandoverDate as string;
    this.formDataStore.Salutation = data.Salutation as string;
    this.formDataStore.LotNumber = data.LotNumber as string;
    this.formDataStore.StreetNumber = data.StreetNumber as string;
    this.formDataStore.StreetName1 = data.StreetName1 as string;
    this.formDataStore.StreetName2 = data.StreetName2 as string;
    this.formDataStore.SuburbTown = data.SuburbTown as string;
    this.formDataStore.State = data.State as string;
    this.formDataStore.PostCode = data.PostCode as string;
    this.formDataStore.ClientEmail = data.ClientEmail as string;
    this.formDataStore.PhoneNumber1 = data.PhoneNumber1 as string;
    this.formDataStore.PhoneNumber2 = data.PhoneNumber2 as string;
    this.formDataStore.SalesRep = data.SalesRep as string;
    this.formDataStore.DepositAmount = data.DepositAmount as string;
    this.formDataStore.ContractTotal = data.ContractTotal as string;
    this.formDataStore.SalesDate = data.SalesDate as string;
    this.formDataStore.ContractSignedDate = data.ContractSignedDate as string;
    this.formDataStore.HouseTypeDescription = data.HouseTypeDescription as string;
    this.formDataStore.PlanNumber = data.PlanNumber as string;
    this.formDataStore.Volume = data.Volume as string;
    this.formDataStore.Folio = data.Folio as string;
    this.formDataStore.Council = data.Council as string;
    this.formDataStore.Estate = data.Estate as string;
    this.formDataStore.LandZoning = data.LandZoning as string;
    this.formDataStore.LandTitleType = data.LandTitleType as string;
    this.formDataStore.LandType = data.LandType as string;
    this.formDataStore.SiteManager = data.SiteManager as string;
    this.formDataStore.LiaisonOfficer = data.LiaisonOfficer as string;
    this.formDataStore.DepositPaid = data.DepositPaid as string;
    this.formDataStore.DepositBalanceDue = data.DepositBalanceDue as string;
    this.formDataStore.TitleDueDate = data.TitleDueDate as string;
    this.formDataStore.ClientLotNumber = data.ClientLotNumber as string;
    this.formDataStore.ClientStreetNumber = data.ClientStreetNumber as string;
    this.formDataStore.ClientStreetName1 = data.ClientStreetName1 as string;
    this.formDataStore.ClientStreetName2 = data.ClientStreetName2 as string;
    this.formDataStore.ClientSuburbTown = data.ClientSuburbTown as string;
    this.formDataStore.ClientState = data.ClientState as string;
    this.formDataStore.ClientPostCode = data.ClientPostCode as string;
    this.formDataStore.Date = data.Date as string;
    this.formDataStore.Date2 = data.Date2 as string;
    this.formDataStore.Text = data.Text as string;
  }

  calcWidthDeduct() {
    if (window.innerWidth < 600) {
      this.isRestrictedForm = true;
    } else {
      this.isRestrictedForm = false;
    }
    this.pdfHeight = window.innerHeight - 45;
  }

  openFile() {
    if (this.isSalesQuote) {
      this.getSalesQuote();
    } else if (this.useUploadedAddenda) {
      this.getReplacementAddendaPDF();
    } else if (this.pdfReport) {
      this.saveName = this.pdfReport.pdfReportName;
      this.pdf = this.base64ToArrayBuffer(this.pdfReport.pdfReport);
      if (this.exportToExcel || this.pdfReport.attachmentTypeId !== AttachmentTypeEnum.PDF) {
        this.save();
        this.fileDownloaded = true;
      }
      this.loading = false;
    } else if (this.jobDocumentId) {
      this.getJobDocAttachment();
    } else if (this.jobitem) {
      this.getJobAttachment();
    } else if (this.sharePointFileId && this.sharePointFileId !== '') {
      this.getSharePointFile();
    } else {
      this.getAddendumOrVO();
    }
  }

  getReplacementAddendaPDF() {
    this.subscriptions = this.subscriptions.concat(
      this._jobService.getReplacementAddendaPDF(this._jobService.currentJob.id).subscribe({
        next: (replacementAddendaPdf) => {
          this.saveName = replacementAddendaPdf.pdfReportName;
          this.pdf = this.base64ToArrayBuffer(replacementAddendaPdf.pdfReport);
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  getJobDocAttachment() {
    // getting the job document PDF attachment
    this.subscriptions = this.subscriptions.concat(
      this._jobDocAttachmentService.getJobDocAttachment(this.jobDocumentId, false).subscribe({
        next: (jobItemAttachment) => {
          this.jobItemAttachment = jobItemAttachment;
          this.saveName = this.jobItemAttachment.attachmentName;
          this.pdf = this.base64ToArrayBuffer(this.jobItemAttachment.attachment);

          if (this.jobItemAttachment.attachmentTypeId !== AttachmentTypeEnum.PDF) {
            // download it
            this.save();
            this.fileDownloaded = true;
            this.loading = false;
          } else {
            this.loading = false;
          }
        },
        error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  getSharePointFile() {
    // getting the job document PDF attachment
    this.subscriptions = this.subscriptions.concat(
      this._jobDocAttachmentService.getSharePointDocument(this.jobNumber, this.sharePointFileId, false).subscribe({
        next: (res) => {
          this.saveName = res.name;
          this.pdf = this.base64ToArrayBuffer(res.attachment);

          if (res.attachmentTypeId !== AttachmentTypeEnum.PDF) {
            // download it
            this.save();
            this.fileDownloaded = true;
            this.loading = false;
          } else {
            this.loading = false;
          }
        },
        error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  getAddendumOrVO() {
    // get original job item attachment
    this.subscriptions = this.subscriptions.concat(
      this._jobItemAttachmentService.getJobItemAttachmentPDF(this.variationNumber, this.printImages, this.printPrices,
        this.printNotApplicable, this.printNonPrintItems, this.printConnectedTogether, this.printVONumber, this.exportToExcel,
        this.printProvisionalSums, this.printQuantities, this.selectedCategories, this.printPdfAttachments, this.showSignatureSection,
        this.showCheckBoxes, this.printWithHighlighting).subscribe({
          next: (jobItemAttachment) => {
            this.jobItemAttachment = jobItemAttachment;
            this.saveName = this.jobItemAttachment.attachmentName;
            this.loading = false;
            if (!this.jobItemAttachment.attachment) {
              // No variations found for given job
              this.errorMessage = 'No data to display';
            } else {
              this.pdf = this.base64ToArrayBuffer(this.jobItemAttachment.attachment);
              if (this.exportToExcel) {
                this.save();
                this.fileDownloaded = true;
              }
            }
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
    );
  }

  getSalesQuote() {
    // get the sales quote
    this.subscriptions = this.subscriptions.concat(
      this._jobItemAttachmentService.getSalesQuotePDF(this.printImages, this.printPrices,
        this.printNotApplicable, this.printNonPrintItems, this.quoteDate, this.printConnectedTogether, this.printVONumber,
        this.printProvisionalSums, this.printWholeAddendum, this.printItemsNotTaken, this.printChangeHistory,
        this.printIncludedAmounts, this.printSalesQuoteOnly, this.printPdfAttachments, this.printFromSalesPriceOnwards,
        this.exportToXML, this.isPriceGuide, this.quoteExpiryDate, this.showSignatureSection).subscribe({
          next: (jobItemAttachment) => {
            this.jobItemAttachment = jobItemAttachment;
            this.saveName = this.jobItemAttachment.attachmentName;

            if (this.printSalesQuoteOnly) {
              this._jobService.currentJob.quoteDate = new Date(this.quoteDate);  // update the quote date used
            } else {
              this._jobService.currentJob.contractQuoteDate = new Date(this.quoteDate);  // update the quote date used
            }

            if (!this.jobItemAttachment.attachment) {
              // No variations found for given job
              this.errorMessage = 'No data to display';
            } else {
              this.pdf = this.base64ToArrayBuffer(this.jobItemAttachment.attachment);
              if (this.exportToExcel || this.exportToXML) {
                this.save();
                this.fileDownloaded = true;
              }
            }
            this.loading = false;
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
    );
  }

  getJobAttachment() {
    if (!this.jobitem.attachmentVariationId || this.jobitem.attachmentVariationId === 0) {
      // get original job item attachment
      this.subscriptions = this.subscriptions.concat(
        this._jobItemAttachmentService.getJobItemAttachmentById(this.jobitem.attachmentId).subscribe({
          next: (jobItemAttachment) => {
            this.jobItemAttachment = jobItemAttachment;
            this.saveName = this.jobItemAttachment.attachmentName;
            this.pdf = this.base64ToArrayBuffer(this.jobItemAttachment.attachment);
            this.loading = false;
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
      );
    } else {
      // get job variation-item attachment
      this.subscriptions = this.subscriptions.concat(
        this._jobVarItemAttachmentService.getJobItemAttachmentById(this.jobitem.attachmentId).subscribe({
          next: (jobItemAttachment) => {
            this.jobItemAttachment = jobItemAttachment;
            this.saveName = this.jobItemAttachment.attachmentName;
            this.pdf = this.base64ToArrayBuffer(this.jobItemAttachment.attachment);
            this.loading = false;
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    if (this.exportToExcel) {
      this.blob = new Blob([bytes], { type: 'application/vnd.ms-excel' });
    } else {
      if (this.saveName.endsWith('.pdf') || !this.saveName.includes('.')) {
        this.blob = new Blob([bytes], { type: 'application/pdf' });
      } else if (this.saveName.toLowerCase().endsWith('.jpg')) {
        this.blob = new Blob([bytes], { type: 'image/jpg' });
      } else if (this.saveName.toLowerCase().endsWith('.png')) {
        this.blob = new Blob([bytes], { type: 'image/png' });
      } else if (this.saveName.toLowerCase().endsWith('.doc') || this.saveName.toLowerCase().endsWith('.docx')) {
        this.blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      } else if (this.saveName.toLowerCase().endsWith('.xls') || this.saveName.toLowerCase().endsWith('.xlsx') || this.saveName.toLowerCase().endsWith('.xlsm')) {
        this.blob = new Blob([bytes], { type: 'application/vnd.ms-excel' });
      } else {
        this.blob = new Blob([bytes], { type: 'application/octet-stream' });
      }
    }
    return bytes.buffer;
  }

  save() {
    if (this.exportToExcel) {
      this.saveName += '.xls';
    } else if (this.exportToXML) {
      if (this.outputFileName) {
        this.saveName = 'Contract Cost Sheet – ' + this.outputFileName.trim();
      }
      this.saveName += '.xml';
    } else if (!this.saveName.includes('.')) {
      this.saveName += '.pdf';
    }
    saveAs(this.blob, this.saveName);
  }

  openInNewWindow() {
    const url = window.URL.createObjectURL(this.blob);
    window.open(url);
    this.close();
  }

  close() {
    this.activeModal.close(true);
  }

  getSaveName(name: string): string {
    if (name) {
      if (name.includes('.pdf')) {
        return name.replace('.pdf', ' copy.pdf');
      }
      return name;
    }
    return 'Attachment';
  }

  async export(): Promise<void> {
    this.blob = await this.pdfViewerService.getCurrentDocumentAsBlob();

    if (this.isMobile) {
      this.openInNewWindow();
    } else {
      this.save();
    }
  }

  async saveAsAnotherFile() {
    // use modal to add secondary POs that are sent with the Call-Up
    const modalRef = this.modalService.open(SaveDocCopyComponent,
      { backdrop: 'static', keyboard: false, backdropClass: 'backdropClass' });
    modalRef.componentInstance.saveDescription = this.getSaveName(this.saveName);
    modalRef.componentInstance.fileBlob = await this.pdfViewerService.getCurrentDocumentAsBlob();;

    modalRef.result.then(() => {
      this.close();
    }, () => { });
  }

  async enableDisableForm() {
    this.blob = await this.pdfViewerService.getCurrentDocumentAsBlob();
    this.loading = true;
    setTimeout(() => {
      this.formEnabled = !this.formEnabled;
      this.disableFormText = this.formEnabled ? 'Disable Merge' : 'Enable Merge';
      this.setupFormData();
      this.loading = false;
    }, 2000);
  }
}
