export class IJobItemWithChildren {
    id: number;
    itemTypeId: number;
    changeTypeId: number;
    jobVarTypeId: number;
    itemChangedId: number;
    description: string;
    originalItemTypeId: number;
    originalItemId: number;
    connectedItemId: number;
    children: IJobItemWithChildren[];
    parentId: number;
    changedByVOId: number;
    changedByJobVarId: number;
    masterItemId: number;
    hasLinkedItems: boolean;
    optionNumber: number;
    checked: boolean;
    indeterminate: boolean;
    isChecked: boolean;
    variationItemNumber: number;
}
