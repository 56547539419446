<div class="modal-header">
  <h2 class="modal-title">{{addendumName}} Replacement</h2>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <div *ngIf="!loading">
    <p class="mt-2 mid-bold">
      WARNING: Loading your own PDF will mean that Truth Engine will use this loaded file instead of generating the
      {{addendumName}}. This is mainly for use during initial take-up of the system.
    </p>
    <br>

    <div *ngIf="!useUploadedAddenda">
      <div id="fileuploader-container">
        <dx-file-uploader selectButtonText="Select file" labelText="or drop pdf file here" [multiple]="false" accept="*"
          [(value)]="value" uploadMode="useForm" [allowedFileExtensions]="['.pdf', '.PDF']">
        </dx-file-uploader>
      </div>
      <dx-button id="button" text="Load" type="default" (onClick)="uploadClick()"> </dx-button>
    </div>

    <div *ngIf="useUploadedAddenda">
      <dx-button id="button" text="Open" type="default" (onClick)="showPDF()"> </dx-button>
      <dx-button id="button" text="Delete" type="danger" (onClick)="deleteFile()" class="ms-2"> </dx-button>
    </div>
  </div>
</div>