import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IJobItem } from '../../dtos/job-item';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { CommentsService } from '../../services/felixApi/comments.service';
import { JobItemComment } from '../../dtos/job-item-comment';
import { JobItemCommentTypeEnum } from '../../dtos/comment-type.enum';
import { AuthService } from '../../services/auth.service';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.scss']
})
export class CommentModalComponent implements OnInit, OnDestroy {
  @Input() jobitem: IJobItem;
  @Input() variationId: number;
  @Input() isLocked: boolean;
  @Input() isVariationOpen: boolean;

  COMPONENT_NAME = 'comment-modal';
  subscriptions: Subscription[] = [];

  loading = true;
  modalButton: string;
  jobItemComment: JobItemComment;
  isDeletePressed = false;
  editForm: UntypedFormGroup;
  canDelete = false;
  updatedItem: any; // used for updating/adding a record and returning data to the calling component
  jobItemCommentTypeEnum = JobItemCommentTypeEnum;
  updateable = false;
  jobLineSetupWrite = false;
  selectionsWrite = false;
  selectionsAdmin = false;
  textHeight: number;

  constructor(private _formBuilder: UntypedFormBuilder,
    private _commentService: CommentsService,
    private _authService: AuthService,
    private notiService: NotificationService,
    private _activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.textHeight = window.innerHeight - 300;
    if (this.textHeight > 400) {
      this.textHeight = 400;
    }

    // if we are not in a VO then admin can override security
    let permissionLevel = this._authService.getSelectionsPermissions('Selections');
    if (permissionLevel === 'Admin' || this._authService.isAdminOrSuperUser()) {
      this.selectionsWrite = true;
      this.selectionsAdmin = true;
    } else if (permissionLevel === 'Write') {
      this.selectionsWrite = true;
    } else {
      permissionLevel = this._authService.getSelectionsPermissions('SalesVariations');
      if (permissionLevel === 'Admin' || permissionLevel === 'Write') {
        this.selectionsWrite = true;
      } else {
        permissionLevel = this._authService.getSelectionsPermissions('PreContractVariations');
        if (permissionLevel === 'Admin' || permissionLevel === 'Write') {
          this.selectionsWrite = true;
        }
      }
    }

    const permissionJobLineSetup = this._authService.getSelectionsPermissions('JobLineSetup');
    if (this._authService.isAdminOrSuperUser()
      || permissionJobLineSetup === 'Write' || permissionJobLineSetup === 'Admin') {
      this.jobLineSetupWrite = true;
    }

    // read the appropriate comment
    this.editForm = this._formBuilder.group({
      comment: '',
      isCommentImportant: false
    });

    if (this.jobitem.jobItemCommentId) {
      this.modalButton = 'Update';

      if (this.jobitem.changedByJobVarId === null || this.jobitem.changedByJobVarId === 0) {
        // read job item comment
        this.readJobItemComment();
      } else {
        this.readJobVarItemComment();
      }

    } else {
      this.modalButton = 'Add';
      this.loading = false;
      if ((!this.isLocked && this.jobLineSetupWrite)
        || (this.variationId && this.jobitem.changedByVOId === this.variationId && this.isVariationOpen && this.selectionsWrite)
        || this.selectionsAdmin) {
        this.updateable = true;
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  readJobItemComment() {
    this.subscriptions = this.subscriptions.concat(
      this._commentService.getJobItemComment(this.jobitem.id).subscribe({
        next: (jobItemComment) => {
          this.jobItemComment = jobItemComment;
          this.editForm.patchValue({
            comment: this.jobItemComment.comment,
            isCommentImportant: (this.jobItemComment.commentTypeId === this.jobItemCommentTypeEnum.Important)
          });
          this.loading = false;
          if ((!this.isLocked && !this.variationId && this.jobLineSetupWrite)
            || (this.variationId && this.jobitem.changedByVOId === this.variationId
              && this.isVariationOpen && this.selectionsWrite)
            || (!this.variationId && this.selectionsAdmin)) {
            this.updateable = true;
            if (!this.variationId && (!this.isLocked || this.selectionsAdmin)) {
              this.canDelete = true;
            }
          }
        },
        error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  readJobVarItemComment() {
    this.subscriptions = this.subscriptions.concat(
      this._commentService.getJobVarItemComment(this.jobitem.jobItemCommentId).subscribe({
        next: (jobItemComment) => {
          this.jobItemComment = jobItemComment;
          this.loading = false;
          this.editForm.patchValue({
            comment: this.jobItemComment.comment,
            isCommentImportant: (this.jobItemComment.commentTypeId === this.jobItemCommentTypeEnum.Important)
          });
          if ((this.jobitem.changedByVOId === this.variationId && ((this.isVariationOpen && this.selectionsWrite) || this.selectionsAdmin))
            || (!this.variationId && this.selectionsAdmin)) {
            this.updateable = true;
            if (this.jobitem.changedByJobVarId === this.jobItemComment.jobVarItemId || (!this.variationId && this.selectionsAdmin)) {
              this.canDelete = true;
            }
          }
        },
        error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  cancel() {
    const returnRecord = { commentTypeId: this.jobitem.commentTypeId, commentId: this.jobitem.jobItemCommentId };
    this._activeModal.close(returnRecord);
  }

  close() {
    if (this.editForm.get('isCommentImportant').value) {
      const returnRecord = { commentTypeId: this.jobItemCommentTypeEnum.Important, commentId: this.jobitem.jobItemCommentId };
      this._activeModal.close(returnRecord);
    } else {
      const returnRecord = { commentTypeId: this.jobItemCommentTypeEnum.Normal, commentId: this.jobitem.jobItemCommentId };
      this._activeModal.close(returnRecord);
    }
  }

  update() {
    this.loading = true;
    if (!this.jobitem.jobItemCommentId
      || (this.jobitem.changedByJobVarId !== 0 && this.jobitem.changedByJobVarId !== this.jobItemComment.jobVarItemId)
      || (this.jobitem.changedByJobVarId === 0 && this.jobitem.id !== this.jobItemComment.jobItemId)) {
      // add
      this.updatedItem = {
        comment: this.editForm.get('comment').value
      };
      if (this.editForm.get('isCommentImportant').value) {
        this.updatedItem.commentTypeId = this.jobItemCommentTypeEnum.Important;
      } else {
        this.updatedItem.commentTypeId = this.jobItemCommentTypeEnum.Normal;
      }

      if (this.jobitem.changedByJobVarId && this.jobitem.changedByJobVarId !== 0) {
        // add variation comment
        this.subscriptions = this.subscriptions.concat(
          this._commentService.addJobVarItemComment(this.jobitem.changedByJobVarId, this.updatedItem).subscribe({
            next: (res) => {
              this.jobitem.jobItemCommentId = res;
              this.close();
            },
            error: (err) => {
              this.loading = false;
              this.notiService.notify(err);
            }
          })
        );
      } else {
        // add job item comment
        this.subscriptions = this.subscriptions.concat(
          this._commentService.addJobItemComment(this.jobitem.id, this.updatedItem).subscribe({
            next: (res) => {
              this.jobitem.jobItemCommentId = res;
              this.close();
            },
            error: (err) => {
              this.loading = false;
              this.notiService.notify(err);
            }
          })
        );
      }
    } else {
      // edit
      let updateRequired = true;
      if (this.jobItemComment.comment !== this.editForm.get('comment').value
        && this.jobItemComment.commentTypeId === this.jobItemCommentTypeEnum.Important
        && !this.editForm.get('isCommentImportant').value) {
        this.updatedItem = {
          comment: this.editForm.get('comment').value,
          commentTypeId: this.jobItemCommentTypeEnum.Normal,
        };
      } else if (this.jobItemComment.comment !== this.editForm.get('comment').value
        && this.jobItemComment.commentTypeId === this.jobItemCommentTypeEnum.Normal
        && this.editForm.get('isCommentImportant').value) {
        this.updatedItem = {
          comment: this.editForm.get('comment').value,
          commentTypeId: this.jobItemCommentTypeEnum.Important,
        };
      } else if (this.jobItemComment.comment !== this.editForm.get('comment').value) {
        this.updatedItem = {
          comment: this.editForm.get('comment').value
        };
      } else if (this.jobItemComment.commentTypeId === this.jobItemCommentTypeEnum.Important
        && !this.editForm.get('isCommentImportant').value) {
        this.updatedItem = {
          commentTypeId: this.jobItemCommentTypeEnum.Normal
        };
      } else if (this.jobItemComment.commentTypeId === this.jobItemCommentTypeEnum.Normal
        && this.editForm.get('isCommentImportant').value) {
        this.updatedItem = {
          commentTypeId: this.jobItemCommentTypeEnum.Important
        };
      } else {
        updateRequired = false;
      }

      if (updateRequired) {
        // we are editing a job item comment
        this.subscriptions = this.subscriptions.concat(
          this._commentService.updateJobItemComment(this.jobItemComment.id, this.updatedItem).subscribe({
            next: () => {
              this.close();
            },
            error: (err) => {
              this.loading = false;
              this.notiService.notify(err);
            }
          })
        );
      } else {
        this.loading = false;
      }
    }
  }

  delete() {
    if (!this.isDeletePressed) {
      this.isDeletePressed = true;
    } else {
      this.loading = true;
      // add variation comment
      this.subscriptions = this.subscriptions.concat(
        this._commentService.deleteJobItemComment(this.jobItemComment.id).subscribe({
          next: () => {
            this._activeModal.close(null);
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
      );
    }
  }
}
