<dx-data-grid *ngIf="!loading" id="dataGrid" class="max-w-1200 m-auto" [height]="gridHeight" [dataSource]="dataSource"
  [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true"
  [wordWrapEnabled]="true" (onRowPrepared)="onRowPrepared($event)" (onEditingStart)="onEditingStart($event)"
  (onEditorPreparing)="onEditorPreparing($event)" (onToolbarPreparing)="onToolbarPreparing($event)">

  <dxo-scrolling mode="standard"></dxo-scrolling>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-search-panel [visible]="true" location="before" [width]="200"></dxo-search-panel>

  <dxo-editing mode="batch" [allowAdding]="false" [allowUpdating]="canUpdateData" [allowDeleting]="false" [useIcons]="true"
    refreshMode="repaint">
  </dxo-editing>
  <dxo-paging [enabled]="false"></dxo-paging>
  <!-- <dxo-filter-panel [visible]="true"></dxo-filter-panel> -->
  <dxo-group-panel [visible]="false"></dxo-group-panel>
  <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick"></dxo-grouping>


  <dxi-column dataField="jobId" dataType="number" [visible]='false'></dxi-column>
  <dxi-column dataField="trackingFieldId" dataType="number" [visible]='false'></dxi-column>
  <dxi-column dataField="trackingFieldTypeId" dataType="number" [visible]='false'></dxi-column>
  <dxi-column dataField="trackingFieldOrderNo" dataType="number" [visible]='false' sortOrder="asc"></dxi-column>
  <dxi-column dataField="colourId" dataType="number" [visible]='false'></dxi-column>


  <dxi-column dataField="trackingFieldGroupId" caption="Section" dataType="number" width='200' [groupIndex]="0"
    [calculateGroupValue]="calculateGroupSortValue" groupCellTemplate="groupCellTemplate" [allowEditing]="false">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-lookup [dataSource]="trackingFieldGroups" displayExpr="description" valueExpr="id"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="fieldName" caption="Field" [allowEditing]="false" cellTemplate="fieldNameCellTemplate">
  </dxi-column>

  <dxi-column dataField="textValue" caption="Value" cellTemplate="cellTemplate">
  </dxi-column>

  <dxi-column *ngIf="showModifiedFields" dataField="modifiedUserId" caption="Modified By" dataType="number"
    [width]="130" allowEditing="false" cellTemplate="noWrapTemplate" [allowEditing]="false">
    <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
    </dxo-lookup>
  </dxi-column>

  <dxi-column *ngIf="showModifiedFields" dataField="modifiedDate" caption="Modified" dataType="date" [width]="110"
    alignment="center" cellTemplate="dateTemplate" [allowEditing]="false">
  </dxi-column>

  <div *dxTemplate="let cell of 'noWrapTemplate'">
    <div style="white-space: nowrap; overflow: hidden;">{{cell.text}}</div>
  </div>

  <div *dxTemplate="let cellInfo of 'fieldNameCellTemplate'">
    <div id="fieldName" class='mx-0 px-0'>{{cellInfo.value}}</div>
  </div>

  <div *dxTemplate="let cell of 'dateTemplate'">
    <div *ngIf='cell && cell.value && dateOnly(cell)'>{{cell.value | date : "d-MMM-yy"}}</div>
  </div>

  <div *dxTemplate="let data of 'groupCellTemplate'">
    {{ getGroupTitle(data) }}
  </div>

  <!-- here we define the different types for viewing -->
  <div *dxTemplate="let cell of 'cellTemplate'">
    <div id="wrapComment" class='mx-0 px-0' *ngIf='cell.data.trackingFieldTypeId === trackingFieldTypeEnum.Text
      || cell.data.trackingFieldTypeId === trackingFieldTypeEnum.Boolean
      || cell.data.trackingFieldTypeId === trackingFieldTypeEnum.Number
      || cell.data.trackingFieldTypeId === trackingFieldTypeEnum.Lookup'>{{cell.value}}
    </div>

    <div *ngIf='cell.data.trackingFieldTypeId === trackingFieldTypeEnum.Date'>
      <div *ngIf='cell && cell.value'>{{cell.value | date : "d-MMM-yy"}}</div>
    </div>

    <div *ngIf='cell.data.trackingFieldTypeId === trackingFieldTypeEnum.Time'>
      <div *ngIf='cell && cell.value'>{{cell.value | date : "shortTime"}}</div>
    </div>

    <div *ngIf='cell.data.trackingFieldTypeId === trackingFieldTypeEnum.Calculated'>
      <div *ngIf='cell && cell.data'>{{calculateField(cell.data) | date : "d-MMM-yy"}}</div>
    </div>
  </div>

  <dxi-column type="buttons" [width]="45">
    <dxi-button name="edit"></dxi-button>
  </dxi-column>

</dx-data-grid>
