import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';

import { GlobalService } from './global.service';
import { AuthService } from './auth.service';
import { TruthEngineSession } from '../dtos/session';
import { NotificationService } from './notification.service';
import { UserService } from './felixApi/user.service';
import { CompanyService } from './felixApi/company.service';
import { ConfigurationEnum } from '../dtos/configuration-enum';
import { environment } from '../../environments/environment';
import { AuthApiService } from './felixApi/auth-api.service';

@Injectable({
  providedIn: 'root'
})
export class RefreshGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private authApi: AuthApiService,
    private globalService: GlobalService,
    private compService: CompanyService,
    private notiService: NotificationService,
    private userService: UserService
  ) { }

  canActivate() {
    if (!this.globalService.isHttps()) {
      this.router.navigate(['']);
    }
    this.authService.currentVersion = environment.fullVersion;

    if (this.authService.getUserEmail()) {
      // check current version is correct
      this.checkCorrectVersion();

      return true;
    } else {
      const session = this.globalService.getSessionObject();
      const email = this.authService.getMsalUserEmail();
      if (session.currentCompanyId && email) {
        console.log('restoring from cache for email ' + email + ' with: ' + JSON.stringify(session));
        return this.restoreFromCache(session, email);
      } else {
        this.router.navigate(['']);
      }
    }
  }

  checkCorrectVersion() {
    this.authApi.getCurrentApplicationVersion().subscribe({
      next: (appVersion) => {
        if (appVersion.version.trim() !== environment.majorVersion) {
          this.authService.versionIncorrect = true;
          this.authService.correctVersion = appVersion.version.trim();
          console.log('version incorrect so routing to login');
          // this.globalService.signOut();
        } else {
          this.authService.versionIncorrect = false;
        }
      },
      error: (err) => {
        console.log('cant get the current version');
        this.authService.currentVersion = 'Cannot get the current version';
        this.notiService.notify(err);
        this.authService.versionIncorrect = true;
        this.authService.cannotGetCurrentVersion = true;
        // this.authService.signOut();
      }
    });
  }

  restoreFromCache(session: TruthEngineSession, email: string): Observable<boolean> {
    this.authService.setUserEmail(email);

    return this.authApi.getAuthorisedCompanies()
      .pipe(mergeMap(
        comps => {
          this.compService.setCurrentCompanies(comps);
          const company = comps.filter(x => x.id === session.currentCompanyId);
          if (company.length !== 1) {
            return of(this.error());
          }
          if (company[0] && company[0].id) {
            this.globalService.setCurrentCompany(company[0]);
          }
          return forkJoin([
            this.userService.getUser(company[0].userId),
            this.compService.getCompanyConfigurations()
          ])
            .pipe(map(
              ([user, config]) => {
                this.authService.setCurrentUser(user);
                this.authService.signIn();
                this.globalService.setCurrentCompany(company[0]);
                this.globalService.setIsGetChildren(session.isGetChildren);
                this.globalService.setCurrentJob(session.currentJobNo);
                this.globalService.setAreaSelected(session.areaSelected);

                // reread the configs
                this.globalService.companyConfiguration = config;

                const trackingConfig = config.find(i => i.configurationId === ConfigurationEnum.TrackingSystemActive);
                if (trackingConfig?.configurationValue === 1) {
                  this.globalService.isTrackingActive = true;
                } else {
                  this.globalService.isTrackingActive = false;
                }

                const ordersConfig = config.find(i => i.configurationId === ConfigurationEnum.PurchaseOrderSystemActive);
                if (ordersConfig?.configurationValue === 1) {
                  this.globalService.isPurchaseOrderSystemActive = true;
                } else {
                  this.globalService.isPurchaseOrderSystemActive = false;
                }

                // check current version is correct
                this.checkCorrectVersion();

                return true;
              }, (err) => {
                return this.error();
                // this.globalService.signOut();
              }
            ));
        }
      ));
  }

  error() {
    this.router.navigate(['']);
    this.notiService.showError('There was an error fetching data from the session so you will have to login again');
    return false;
  }
}
