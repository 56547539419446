
import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';


import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';

@Injectable()
export class AddressService {

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }


  addJobAddress(itm: any) {
    const url = this.globalService.getApiUrl() + '/jobs/' + this.globalService.getCurrentJob() + '/addresses';
    return this._http.post(url, JSON.stringify(itm), this.httpService.getHttpOptions());
}
  addContractAddress(itm: any) {
      const url = this.globalService.getApiUrl() + '/jobs/' + this.globalService.getCurrentJob() + '/contract-addresses';
      return this._http.post(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  updateAddress(itm: any) {
      const url = this.globalService.getApiUrl() + '/addresses/' + itm.id;
      return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }

}
