<div class="modal-header">
  <h2 class="modal-title">Sales Give Aways for Job</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-data-grid #dataGrid [dataSource]="dataSource" [remoteOperations]="false" [height]="gridHeight"
    [showBorders]="true" [focusedRowEnabled]="true" [showRowLines]="true" [hoverStateEnabled]="true"
    [wordWrapEnabled]="true" [rowAlternationEnabled]="false" [scrolling]="{ mode: 'virtual', preloadEnabled: true }">

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-export [enabled]="true"></dxo-export>

    <dxi-column dataField="itemDescription" sortOrder="asc" width="35%"></dxi-column>
    <dxi-column dataField="selection" sortOrder="asc"></dxi-column>

    <dxi-column dataField="salesRepGiveAway" caption="Sales Give Away" [width]="130" format="#,##0"></dxi-column>

    <dxo-summary>
      <dxi-total-item column="salesRepGiveAway" summaryType="sum" valueFormat="#,##0" displayFormat="{0}"
        showInColumn="salesRepGiveAway">
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>
</div>
