import { Component, OnInit } from '@angular/core';
import { JobService } from '../../services/felixApi/job.service';
import { Subscription } from 'rxjs';
import { TaskService } from '../../services/felixApi/task.service';
import { JobProgress } from '../../dtos/job-progress';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import * as saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
const ExcelJS = require('exceljs');

@Component({
  selector: 'js-job-progress',
  templateUrl: './job-progress.component.html',
  styleUrls: ['./job-progress.component.css']
})
export class JobProgressComponent implements OnInit {

  loading = true;
  jobId: number;
  subscriptions: Subscription[] = [];
  jobProgressTasks: JobProgress[];
  gridHeight: number;
  gridWidth: number;
  jobNumber: string;

  constructor(private jobService: JobService,
    private taskService: TaskService,
    public gridService: GridService,
    private globalService: GlobalService) { }


  ngOnInit() {
    this.setHeightWidths();

    this.subscriptions.push(
      this.globalService.innerHeightChanged.subscribe(
        () => {
          setTimeout(() => {
            this.setHeightWidths();
          }, 200); // wait for iPhone and grid
        }
      )
    );
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 107;
    this.gridWidth = window.innerWidth < 600 ? window.innerWidth - 20 : 580;
  }

  onJobNumberChanged(jobNo: string) {
    this.jobNumber = this.jobService.currentJob.jobNumber;
    this.jobId = this.jobService.currentJob?.id;

    if (this.jobId) {
      this.getJobProgress();
    }
  }

  getJobProgress() {
    this.subscriptions = this.subscriptions.concat(
      this.taskService.getClientProgressTask(this.jobService.currentJob.id)
        .subscribe({
          next: (res) => {
            this.jobProgressTasks = res;
            this.loading = false;
          },
          error: (err) => {
            //this.notiService.notify(err);
            this.jobProgressTasks = [];
            this.loading = false;
          }
        })
    );
  }
  onExporting(e) {
    const jobAddressString = this.globalService.getJobString(this.jobService.currentJob, false);
    const contractName = this.jobService.currentJob.contractName;
    const reportName = 'Updates';

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(reportName,
      { pageSetup: { paperSize: 9, orientation: 'landscape' } });

      worksheet.columns = [
        { width: 50 },
        { width: 13, style: { numFmt: 'd-mmm-yy' } }
      ];

      worksheet.headerFooter.evenHeader = 'Job ' + this.jobService.currentJob.jobNumber; + ' - ' + reportName;
      worksheet.headerFooter.evenFooter = '&L' + new Date().toLocaleDateString() + '&CPage &P of &N' + '&R' + this.globalService.getCurrentCompany().companyName;
      worksheet.headerFooter.oddHeader = 'Job ' + this.jobService.currentJob.jobNumber; + ' - ' + reportName;
      worksheet.headerFooter.oddFooter = '&L' + new Date().toLocaleDateString() + '&CPage &P of &N' + '&R' + this.globalService.getCurrentCompany().companyName;

    exportDataGrid({
      worksheet: worksheet,
      component: e.component,
      autoFilterEnabled: true,
      keepColumnWidths: false,
      customizeCell: function (options) {
        const { gridCell, excelCell } = options;
        if (gridCell.rowType === 'data') {
          if (gridCell.column.dataField === 'date') {
            excelCell.numFmt = 'd-mmm-yy';
          }
        }
      },
      topLeftCell: { row: 5, column: 1 },
    }).then(() => {
      // header
      worksheet.getCell('A1').value = contractName;
      worksheet.getCell('A1').font = { name: 'Calibri', size: 12, bold: true };
      worksheet.getCell('A2').value = jobAddressString;
      worksheet.getCell('A2').font = { name: 'Calibri', size: 12, bold: true };
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), reportName + ' for job ' + this.jobService.currentJob.jobNumber + '.xlsx');
      });
    });
  }


}
