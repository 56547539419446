<div class="modal-header">
  <h2 class="modal-title">Go to Selections</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancelWithNo()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body text-center">
  <div class="mt-2 mid-bold">You have Selections waiting for you!</div>
  <div class="mid-bold">Do you want to go to the Selection Variation now?</div>
  <div class="m-t-25 info">When you have finished, ensure you <b>Submit</b> your selections using the button at the bottom.</div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-secondary" (click)="cancelWithNo()">No</button>
  <button jsFocusElement type="button" class="btn btn-sm btn-primary w-80" (click)="closeWithYes()">Yes</button>
</div>