<div class="modal-header">
  <h4 class="modal-title">Show section in these variations:</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <div *ngIf="!loading" class="widget-container">
    <dx-list #list [dataSource]='variationTypesStore' [height]="400" [selectionMode]="selectionModeValue"
      [selectAllMode]="selectAllModeVlaue" [showSelectionControls]="true" [(selectedItemKeys)]="selectedVariationTypes"></dx-list>
  </div>
</div>

<div *ngIf="!loading" class="modal-footer">
  <button class="btn btn-secondary" (click)="cancel()">Close</button>
  <button class="btn btn-primary" (click)="update()">Update</button>
</div>