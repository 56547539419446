import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LogService } from './../services/log.service';
import { environment } from '../../environments/environment';
import { UserTypeEnum } from '../dtos/user-type.enum';
import { Company } from '../dtos/company';
import { User } from '../dtos/user';
import { AuthService } from '../services/auth.service';
import { GlobalService } from '../services/global.service';
import { UserService } from '../services/felixApi/user.service';
import { NotificationService } from '../services/notification.service';
import { CompanyService } from '../services/felixApi/company.service';
import { ConfigurationEnum } from '../dtos/configuration-enum';
import { AuthApiService } from '../services/felixApi/auth-api.service';


@Component({
  selector: 'js-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  COMPONENT_NAME = 'welcome';
  userTypeEnum = UserTypeEnum;
  userCompanies: Company[];
  currComp: Company;
  currUser: User;
  currUserEmail: string;
  multipleCompanies = false;
  admin = false;
  loading = true;
  innerWidth: number;
  compForm: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  officeUser = false;
  configurationEnum = ConfigurationEnum;
  cannotGetCompanies = false;
  isMobile = false;
  isTrackingActive: boolean;
  currentVersion: string;
  optionListWrite: boolean;
  isPurchaseOrderSystemActive: boolean;
  selectedCompanyId: number;
  companySelectWidth: number;
  isProduction: boolean;

  constructor(
    private router: Router,
    private userService: UserService,
    private globalService: GlobalService,
    private notiService: NotificationService,
    private authService: AuthService,
    private authApi: AuthApiService,
    private compService: CompanyService,
    private logService: LogService) {

    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerWidthChanged.subscribe(() => {
        this.setWidths();
      })
    );
  }

  ngOnInit() {
    this.setWidths();
    this.globalService.setAreaSelected('welcome');

    this.currentVersion = this.authService.currentVersion;

    this.isTrackingActive = this.globalService.isTrackingActive; // we may have this or when we return to this page
    this.isPurchaseOrderSystemActive = this.globalService.isPurchaseOrderSystemActive; // we may have this or when we return to this page

    this.getCompanies();

    this.currComp = this.globalService.getCurrentCompany();
    this.selectedCompanyId = this.currComp?.id;
    // this.globalService.clearCurrentCompany();
    this.currUserEmail = this.authService.getUserEmail();
    this.currUser = this.authService.getCurrentUser();

    if (this.globalService.isDebugMode) {
      this.notiService.showInfo('currUserEmail is ' + this.currUserEmail);
      // console.log('currUserEmail is ' + this.currUserEmail);
    }

    if ((this.currComp && this.currComp.userTypeId === this.userTypeEnum.Admin) || (this.currUser && this.currUser.isSuperUser)) {
      this.admin = true;
      this.officeUser = true;
    } else if (this.currComp && this.currComp.userTypeId === this.userTypeEnum.Office) {
      this.officeUser = true;

      // can we see the config app button
      const permissionLevel = this.authService.getSelectionsPermissions('OptionLists');
      if (permissionLevel === 'Admin' || permissionLevel === 'Write') {
        this.optionListWrite = true;
      }
    }
  }

  setWidths() {
    this.innerWidth = this.globalService.innerWidth;
    if (this.innerWidth > 400) {
      this.companySelectWidth = 360;
    } else {
      this.companySelectWidth = this.innerWidth - 40;
    }

    this.isProduction = environment.production;
  }

  getCompanies() {
    this.userCompanies = this.compService.getCurrentCompanies();
    if (this.userCompanies && this.userCompanies.length) {

      if (this.globalService.isDebugMode) {
        // console.log('got em boys');
        this.notiService.showInfo('have companies');
      }
      this.populateCompanies();
    } else {
      if (this.globalService.isDebugMode) {
        this.notiService.showInfo('email ' + this.authService.getUserEmail());
        // console.log('reading companies for ' + this.authService.getUserEmail());
      }

      if (this.authService.getUserEmail()) {
        this.subscriptions = this.subscriptions.concat(
          this.authApi.getAuthorisedCompanies().subscribe({
            next: (companies) => {
              this.userCompanies = companies;

              if (this.globalService.isDebugMode) {
                this.notiService.showInfo('have companies after read');
                // console.log('have read companies');
              }
              this.populateCompanies();
            },
            error: (err) => {
              if (err.status === 404) {
                this.notiService.showError('No company allocated for this email ' + this.authService.getUserEmail()
                  + '. Please check the email address or contact your administrator');
                setTimeout(() => {
                  this.signOut();
                }, 5000);
              } else {
                this.notiService.notify(err);
                this.loading = false;
              }
            }
          })
        );
      } else {
        this.signOut();
      }
    }
  }

  populateCompanies() {
    this.compService.setCurrentCompanies(this.userCompanies);

    // this.cd.detectChanges();
    if (this.userCompanies.length === 1) {
      this.multipleCompanies = false;
      this.currComp = this.userCompanies[0];
      this.selectedCompanyId = this.currComp?.id;
      if (!this.authService.signedIn) {
        this.compSelected();
      }
    } else {
      // check for a passed company
      const session = this.globalService.getSessionObject();
      if (session.passedCompanyId) {
        const thisCompany = this.userCompanies.filter(i => i.id === +session.passedCompanyId);
        if (thisCompany.length) {
          this.currComp = thisCompany[0];
          this.selectedCompanyId = this.currComp?.id;
          this.multipleCompanies = false;
          this.globalService.setSessionAtt('passedCompanyId', ''); // reset so we can go back to welcome page
          this.compSelected();
        } else {
          this.multipleCompanies = true;
        }
      } else {
        this.multipleCompanies = true;
      }
    }
    this.loading = false;
  }

  companySelected(e) {
    this.selectedCompanyId = e.value;
    if (this.selectedCompanyId) {
      this.currComp = this.userCompanies.find(i => i.id === this.selectedCompanyId);
      this.compSelected();
    }
  }

  compSelected() {
    if (this.globalService.isDebugMode) {
      // console.log('this.currComp = ' + JSON.stringify(this.currComp));
      this.notiService.showInfo('Company selected ' + this.currComp.companyName);
    }

    this.loading = true;
    this.globalService.menuDisable.emit(true);
    this.globalService.setCurrentCompany(this.currComp);
    // get the user master - only have all user info once company is selected

    this.subscriptions = this.subscriptions.concat(
      this.userService.getUser(this.currComp.userId).subscribe({
        next: (currUser) => {
          this.currUser = currUser;

          if (this.globalService.isDebugMode) {
            // console.log('this.currUser = ' + JSON.stringify(this.currUser));
            this.notiService.showInfo('User set to ' + this.currUser.fullName);
          }
          this.authService.setCurrentUser(this.currUser);
          this.globalService.setCurrentCompany(this.currComp); // need this to emit the company again so we get the perms
          this.authService.signIn();
          if (this.currComp.userTypeId === this.userTypeEnum.Admin) {
            this.admin = true;
          }
          this.globalService.menuDisable.emit(false);

          this.logService.loginLog(this.authService.getUserEmail(), true, 'Company ' + this.currComp.id.toString());

          this.getCompanyConfigurations();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.currUser = null;
          this.globalService.menuDisable.emit(false);
          this.loading = false;
        }
      })
    );
  }

  getCompanyConfigurations() {
    this.subscriptions = this.subscriptions.concat(
      this.compService.getCompanyConfigurations().subscribe({
        next: (companyConfigurations) => {
          this.globalService.companyConfiguration = companyConfigurations;
          if (this.authService.isClient()) {
            this.getCompanyConfigurationForClientDashboard();
          } else {
            this.getCompanyConfigurationForTracking();
          }
        },
        error: () => {
          if (this.authService.isClient()) {
            this.getCompanyConfigurationForClientDashboard();
          } else {
            this.getCompanyConfigurationForTracking();
          }
        }
      })
    );
  }

  getCompanyConfigurationForClientDashboard() {
    const portalActive = this.globalService.getCompanyConfigValue(this.configurationEnum.TruthEngineClientPortalActive);

    if (portalActive) {
      const companyConfigurationValue = this.globalService.getCompanyConfigValue(this.configurationEnum.ClientDashboardVisible);

      if (companyConfigurationValue === 1) {
        const session = this.globalService.getSessionObject();
        if (session && session.passedVariationNumber && session.passedVariationNumber !== '') {
          this.globalService.setAreaSelected('selections');
          this.router.navigateByUrl('/selections');
        } else {
          this.globalService.setAreaSelected('dashboard');
          this.router.navigateByUrl('/dashboard');
        }
      } else {
        this.globalService.setAreaSelected('selections');
        this.router.navigateByUrl('/selections');
      }
    } else {
      this.notiService.showError('Client portal is not active. Please contact your company liaison officer.');
      setTimeout(() => {
        this.signOut();
      }, 5000);
    }
  }

  getCompanyConfigurationForTracking() {
    const companyConfigurationValue = this.globalService.getCompanyConfigValue(this.configurationEnum.TrackingSystemActive);

    if (companyConfigurationValue === 1) {
      this.isTrackingActive = true;
      this.globalService.isTrackingActive = true;
    } else {
      this.isTrackingActive = false;
      this.globalService.isTrackingActive = false;
    }
    this.globalService.setAreaSelected('selections');
    this.router.navigateByUrl('/selections');
  }

  signOut() {
    this.loading = true;
    this.authService.signOut();
  }

  launchAdminPortal() {
    window.open(environment.adminAppUrl);
  }

  launchConfig() {
    window.open(environment.configAppUrl);
  }

  launchEstConfig() {
    window.open(environment.estConfigAppUrl);
  }

  launchTracking() {
    window.open(environment.trackingAppUrl);
  }

  launchPurchaseOrders() {
    window.open(environment.ordersAppUrl);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  clearCache() {
    localStorage.clear();
    this.notiService.showInfo('Cache cleared.');
    setTimeout(() => {
      this.signOut();
    }, 5000);
  }
}
