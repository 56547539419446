<div class="modal-header">
  <h2 class="modal-title">Comment</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div *ngIf="!loading" class="modal-body">
  <div>{{jobitem.itemDescription}}<span *ngIf="jobitem.itemDescription && jobitem.selection">:
    </span>{{jobitem.selection}}</div>
  <form *ngIf="!loading" [formGroup]="editForm" (ngSubmit)="update()">
    <label class="control-label" for="comment"></label>
    <textarea *ngIf="updateable" class="form-control" id="comment" type="text" formControlName="comment"
      [ngStyle]="{'height': 'calc(' + textHeight + 'px' + ')'}"></textarea>
    <textarea *ngIf="!updateable" class="form-control" id="comment" type="text" formControlName="comment"
      readonly></textarea>

    <label *ngIf="updateable" class="control-label mt-3" for="isCommentImportant">Important Note:&nbsp;</label>
    <mat-checkbox *ngIf="updateable" formControlName="isCommentImportant"></mat-checkbox>

    <div *ngIf="!updateable && variationId && isVariationOpen && selectionsWrite">This item needs to be changed in the
      current variation for the note to be editable</div>
    <!-- <mat-checkbox *ngIf="!updateable" formControlName="isCommentImportant" [disabled]="true"></mat-checkbox> -->
  </form>
</div>

<div *ngIf="!isDeletePressed && !loading && updateable" class="modal-footer">
  <button *ngIf="canDelete" type="button" class="btn btn-sm btn-danger" (click)="delete()">Delete</button>
  <button type="button" class="btn btn-sm btn-primary" (click)="update()"
    [disabled]='editForm.pristine'>{{modalButton}}</button>
</div>

<div *ngIf="isDeletePressed && !loading" class="modal-footer">
  <button type="button" class="btn btn-sm btn-danger" (click)="delete()">Confirm Delete</button>
</div>

<!-- Loading Spinner -->
<js-loading-spinner *ngIf="loading"></js-loading-spinner>
