<div class="login" [style.height.px]="containerHeight">
  <br>
  <img src="../assets/teLogo.png" />
  <br><br><br>

  <div *ngIf="showLoginButton && isHttps">
    <button class="btn btn-primary bton-lg" id="signIn" (click)="loginClicked()">Login</button>
  </div>

  <div *ngIf="!isHttps" class="danger-box">
    For security reasons you must use HTTPS when accessing Truth Engine.
    <br>Please use the button below to go to the secure https site.
    <br><br>Update your bookmark to avoid seeing this message again.
    <br>
    <button class="btn btn-primary bton-lg m-t-30" (click)="goToHttps()">Proceed to secure site</button>
  </div>

  <js-loading-spinner *ngIf="!showLoginButton"></js-loading-spinner>

  <br>
  <br>
  <br>
  <button *ngIf="!showLoginButton && !activelyLoggingIn && isHttps && showRefreshButton" class="btn btn-secondary"
    (click)="refresh()">
    Refresh
  </button>
</div>

<br>
<button class="btn btn-sm ms-2" (click)="clearCache()">
  to clear memory click here
</button>
