<div class="modal-header">
  <h2 class="modal-title">Select Sales Option</h2>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(0)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <button class="btn-sm btn-outline-primary btnWidth" (click)="closeModal(0)">Show Master {{addendumName}} (includes approved variations)</button>

  <div class="mt-2">Or Select Sales Variation:</div>

  <div *ngFor="let varn of _variationService.currentVariations">
    <button class="btn-sm btn-outline-primary mt-1 btnWidth" (click)='closeModal(varn.variationNumber)'>{{varn.displayedVariationNumber}}: {{varn.title}}</button>
  </div>

  <button class="btn-sm btn-outline-primary mt-3" (click)="closeModal(-1)">Create New Variation</button>
</div>
