import { PurchaseOrdersService } from './../services/felixApi/purchase-orders.service';
import { VariationService } from './../services/felixApi/variation.service';
import { Component, Input, OnInit, OnChanges, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TreeComponent, TREE_ACTIONS, TreeModel, TreeNode } from '../../../node_modules/@circlon/angular-tree-component';
import { IJobDocument } from '../dtos/job-document';
import { JobDocumentService } from '../services/felixApi/job-document.service';
import { DocumentModalComponent } from './job-documents-modals/document-modal.component';
import { JobDocumentRecordTypeEnum } from '../dtos/job-document-record-type.enum';
import { AttachmentTypeEnum } from '../dtos/attachment-type.enum';
import { ShowPdfComponent } from '../shared/show-pdf.component';
import { ImageSourceEnum } from '../dtos/image-source.enum';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';
import { Subscription } from 'rxjs';
import { JobDocumentType } from '../dtos/job-document-type';
import { GlobalService } from '../services/global.service';
import { JobService } from '../services/felixApi/job.service';
import { JobDocumentStatusEnum } from '../dtos/job-document-status.enum';
import { DESKTOP_SIZE } from '../../config/variables';
import { UtilsService } from '../services/utils.service';
import { Variation } from '../dtos/variation';
import { ConfigurationEnum } from '../dtos/configuration-enum';
import { PurchaseOrder } from '../dtos/purchase-order';
import { JobDocumentAttachmentService } from '../services/felixApi/job-document-attachment.service';
import { AddDocumentsComponent } from './job-documents-modals/add-documents/add-documents.component';
import { JobRolesComponent } from '../shared/job-roles/job-roles.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'js-job-documents-tree',
  templateUrl: './job-documents-tree.component.html',
  styleUrls: ['./job-documents-tree.component.scss']
})
export class JobDocumentsTreeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() jobNumber: string;
  @Input() showImages: boolean;
  @Input() showDeleted: boolean;
  @Input() changeJobNum: boolean; // needed so we can refresh after a job GO

  COMPONENT_NAME = 'job-documents-tree';
  subscriptions: Subscription[] = [];

  @ViewChild('tree') tree: TreeComponent;
  treeOptions = {};
  jobDocuments: IJobDocument[] = [];
  updatedRecord: any;
  copyNodeID: number;
  closeResult: string;
  loading = true;
  error = false;
  errorMessage: string;
  jobDocumentRecordTypeEnum = JobDocumentRecordTypeEnum;
  attachmentTypeEnum = AttachmentTypeEnum;
  imageSourceEnum = ImageSourceEnum;
  permissionWrite = false;
  permissionAdmin = false;
  jobDocumentTypes: JobDocumentType[];
  filterTypes = new UntypedFormControl();
  isClientOrAssociate = true;

  jobDocumentStatusEnum = JobDocumentStatusEnum;
  jobDocumentStatuses = [
    { id: 1, description: 'Not Started' },
    { id: 2, description: 'Pending' },
    { id: 3, description: 'Outstanding High Priority' },
    { id: 4, description: 'Completed / Yes' },
    { id: 5, description: 'No Applicable' }
  ];
  filterStatus = new UntypedFormControl();
  smallScreen: boolean;
  desktop = false;
  filterText = '';
  innerWidthStored = 0;
  emailPopupVisible: boolean;
  selectedJobDocs: any[];
  loadingDocs: boolean;
  jobDocumentsForEmail: IJobDocument[];
  ccToSelf = false;
  toEmail: string;
  ccEmail: string;
  bccEmail: string;
  emailSubject: string;
  emailMessage: string;
  sendAddendum = false;
  popupHeight: number;
  popupWidth: number;
  popupScroll: number;
  emailWidth: number;
  showGetEmail: boolean;
  fieldToSet: any;
  addendumName: string;
  variations: Variation[];
  variationId: number; // variation to send
  variationParamsPopupVisible = false;
  printImages = true;
  printVariationPrices = true;
  printNotApplicable = true;
  printNonPrintItems = false;
  printConnectedTogether = true;
  printVONumber = true;
  descWidth: number;
  treeHeight: number;
  purchaseOrdersActive = false;
  purchaseOrders: PurchaseOrder[];
  selectedPurchaseOrderId: number;
  dxLoadingVisible: boolean;

  constructor(
    private _authService: AuthService,
    public jobDocumentService: JobDocumentService,
    private jobService: JobService,
    private notiService: NotificationService,
    private globalService: GlobalService,
    private modalService: NgbModal,
    private utils: UtilsService,
    private _variationService: VariationService,
    private purchaseOrdersService: PurchaseOrdersService,
    private jobDocumentAttachmentService: JobDocumentAttachmentService
  ) { }

  ngOnInit() {
    this.addendumName = this.globalService.getAddendumName();

    // clear any cache in case another user has added files to this job.
    this.jobDocumentAttachmentService.cache = [];

    if (!this._authService.isClient() && !this._authService.isAssociate()) {
      this.isClientOrAssociate = false;
    }

    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.TrackingSystemActive)) {
      const ordersPermission = this._authService.getSelectionsPermissions('PurchaseOrders');
      if (ordersPermission === 'Read' || ordersPermission === 'Write' || ordersPermission === 'Admin' || this._authService.isAdminOrSuperUser()) {
        this.purchaseOrdersActive = true;
      }
    }

    const permissionLevel = this._authService.getSelectionsPermissions('Information');
    if (permissionLevel === 'Admin' || this._authService.isAdminOrSuperUser()) {
      this.permissionAdmin = true;
      this.permissionWrite = true;
    } else if (permissionLevel === 'Write') {
      this.permissionWrite = true;
    }

    this.jobDocumentTypes = this.jobDocumentService.jobDocumentTypes; // get types
    this.setupOptionNodes();

    // listen to width and height changes
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerWidthChanged.subscribe(() => {
        this.setWidth();
      })
    );
    this.setWidth();
  }

  /* Refresh tree when showImages is updated from option-list-start */
  ngOnChanges() {
    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.TrackingSystemActive)) {
      const ordersPermission = this._authService.getSelectionsPermissions('PurchaseOrders');
      if (ordersPermission === 'Read' || ordersPermission === 'Write' || ordersPermission === 'Admin' || this._authService.isAdminOrSuperUser()) {
        this.purchaseOrdersActive = true;
      }
    }

    if (this.jobNumber && this.jobNumber !== '') {
      this.loading = true;
      this.jobDocumentService.treeNodes = [];
      this.filterTypes.reset();
      this.filterStatus.reset();
      this.filterText = '';
      this.getVariations();
      this.getPurchaseOrdersForJob();
      this.refreshNodes();
    } else {
      this.jobDocumentService.treeNodes = [];
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  updateTree() {
    if (this.tree) {
      this.tree.treeModel.update();
    }
  }

  setupOptionNodes() {
    this.treeOptions = {
      idField: 'id',
      displayField: 'description',
      childrenField: 'children',
      // useVirtualScroll: true,
      nodeHeight: 22,
      allowDrag: true,
      allowDrop: true,
      /* Override clone method to keep prevous ID for use in copying attachment
         Temp hardcoded id (-1) used to delete copied node from tree on error   */
      getNodeClone: (node) => ({
        ...node.data,
        id: -1,
        oldID: node.id
      }),
      actionMapping: {
        mouse: {
          click: (tree, node, $event) => {
            if (node.hasChildren) {
              TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
            }
          }
        }
      }
    };
  }

  setWidth() {
    this.innerWidthStored = window.innerWidth;
    if (window.innerWidth < 760) {
      this.smallScreen = true;
      this.treeHeight = window.innerHeight > 300 ? window.innerHeight - 170 : 130;
    } else {
      this.smallScreen = false;
      this.treeHeight = window.innerHeight > 300 ? window.innerHeight - 197 : 103;
    }

    if (window.innerWidth >= DESKTOP_SIZE) {
      this.desktop = true;
    } else {
      this.desktop = false;
    }

    this.popupHeight = window.innerHeight < 890 ? window.innerHeight : 890;
    this.popupWidth = this.innerWidthStored < 600 ? this.innerWidthStored : 600;

    this.popupScroll = this.popupHeight - 50;
    this.emailWidth = this.popupWidth - 42 - 40;

    this.descWidth = window.innerWidth > 380 ? window.innerWidth - 260 : window.innerWidth - 150;
  }

  refreshNodes() {
    // get all children (i.e. from root '0')
    this.subscriptions = this.subscriptions.concat(
      this.jobDocumentService.getJobDocumentsWithChildren(this.jobNumber, null, this.showDeleted)
        .subscribe({
          next: (treeNodes) => {
            this.jobDocumentService.treeNodes = treeNodes;

            this.addAddButtonToTree();

            this.updateTree();
            this.loading = false;
          },
          error: (err) => {
            this.loading = false;
            this.error = true;
            this.notiService.notify(err);
          }
        })
    );
  }

  addAddButtonToTree() {
    this.jobDocumentService.treeNodes.forEach(node => {
      if (node.recordTypeId === this.jobDocumentRecordTypeEnum.Detail) {
        node.isStatusRequired = node.jobDocumentTypeId
          ? this.jobDocumentTypes.find(i => i.id === node.jobDocumentTypeId).isStatusRequired : false;
      }

      if (node.recordTypeId === this.jobDocumentRecordTypeEnum.Heading && !node.isDeleted) {
        this.addAddButtonToTreeChildren(node);
      }
    });
  }

  addAddButtonToTreeChildren(node: IJobDocument) {
    node.children.forEach((child) => {
      if (child.recordTypeId === this.jobDocumentRecordTypeEnum.Detail) {
        child.isStatusRequired = child.jobDocumentTypeId
          ? this.jobDocumentTypes.find(i => i.id === child.jobDocumentTypeId).isStatusRequired : false;
      }
      if (child.recordTypeId === this.jobDocumentRecordTypeEnum.Heading && !node.isDeleted) {
        this.addAddButtonToTreeChildren(child);
      }
    });

    if (this.permissionWrite) {
      // add a dummy record to the end of the list
      node.children = node.children.concat({
        id: 99999,
        jobId: node.jobId,
        recordTypeId: this.jobDocumentRecordTypeEnum.AddButton,
        parentId: node.id,
        orderNo: node.orderNo,
        description: 'Add to ' + node.description,
        jobDocAttachmentId: null,
        attachmentTypeId: null,
        isCustomerViewable: node.isCustomerViewable,
        isTradeViewable: node.isTradeViewable,
        children: null,
        attachment: null,
        modifiedDate: node.modifiedDate,
        jobDocumentStatusId: node.jobDocumentStatusId,
        jobDocumentTypeId: node.jobDocumentTypeId,
        callUpDocsTypeId: node.callUpDocsTypeId,
        isDeleted: false,
        isStatusRequired: false,
        hasBlob: false,
        jobNumber: node.jobNumber,
        modifiedUserId: null,
        isSharePoint: node.isSharePoint,
        sharePointId: node.sharePointId,
        sharePointUrl: '',
        keyId: '',
        parentKeyId: '',
        fileModifiedDate: null
      });
    }
  }

  addDocument(parentId, isSharePoint: boolean, sharePointId: string = null) {
    // add a new entry to the bottom
    if (isSharePoint) {
      const modalRef = this.modalService.open(AddDocumentsComponent);
      modalRef.componentInstance.selectedFolder = sharePointId;

      modalRef.result.then(() => {
        this.refreshNodes();
      }, () => {
      });
    } else {
      const modalRef = this.modalService.open(DocumentModalComponent);
      modalRef.componentInstance.jobDocument = null;
      modalRef.componentInstance.parentId = parentId;
      modalRef.componentInstance.orderNo = 0;

      modalRef.result.then(() => {
        this.refreshNodes();
      }, () => {
      });
    }
  }

  editItem(data) {
    // add a new entry to the bottom
    const modalRef = this.modalService.open(DocumentModalComponent);
    modalRef.componentInstance.jobDocument = data;
    modalRef.componentInstance.parentId = data.parentId;
    modalRef.componentInstance.orderNo = data.orderNo;

    modalRef.result.then((refresh) => {
      if (refresh) {
        this.refreshNodes();
      } else {
        this.updateTree();
      }
    }, () => {
    });
  }

  onMoveNode($event) {
    const movedNodeId = $event.node.id;
    if (movedNodeId < 0 || $event.to?.parent?.id < 0) {
      this.notiService.showError('Cannot move file in or out of SharePoint');
      this.refreshNodes();
    } else {
      const newIndex = $event.to.index + 1; // tree uses 0-based indexing, back-end uses 1
      let parentId = null;
      if ($event.to.parent.virtual || !$event.to.parent) {
        parentId = null;
        this.moveOption(movedNodeId, newIndex, parentId);
      } else {
        if ($event.to.parent.recordTypeId !== this.jobDocumentRecordTypeEnum.Heading) {
          alert('Cannot move under a detail record. Please choose a heading.');
          this.refreshNodes();
        } else {
          parentId = $event.to.parent.id;
          this.moveOption(movedNodeId, newIndex, parentId);
        }
      }
    }
  }

  moveOption(nodeId: number, newIndex: number, parentId: number) {
    this.subscriptions = this.subscriptions.concat(
      this.jobDocumentService.moveJobDocument(nodeId, parentId, newIndex).subscribe({
        next: () => {
        },
        error: (err) => {
          // hard to replace original position so reload tree from backend
          this.notiService.notify(err);
          this.refreshNodes();
        }
      })
    );
  }

  showImage(nodeData: IJobDocument) {
    if (nodeData.isSharePoint) {
      const modalRef = this.modalService.open(ShowPdfComponent, { windowClass: 'modal-infopdf' });
      modalRef.componentInstance.jobNumber = this.jobNumber;
      modalRef.componentInstance.sharePointFileId = nodeData.sharePointId;
      modalRef.componentInstance.jobDocumentData = nodeData;

      modalRef.result.then(() => {
        this.refreshNodes();
      }, () => { });
    } else {
      const modalRef = this.modalService.open(ShowPdfComponent, { windowClass: 'modal-infopdf' });
      modalRef.componentInstance.jobDocumentId = nodeData.id;

      modalRef.result.then(() => {
        this.refreshNodes();
      }, () => { });
    }
  }

  markComplete(nodeData: IJobDocument) {
    // mark status as complete
    nodeData.jobDocumentStatusId = JobDocumentStatusEnum.Completed;

    // run in the background
    this.subscriptions = this.subscriptions.concat(
      this.jobDocumentService.updateJobDocument(nodeData.id, { jobDocumentStatusId: JobDocumentStatusEnum.Completed }).subscribe({
        next: () => {
        },
        error: err => {
          this.notiService.notify(err);
          this.refreshNodes();
        }
      })
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  runFilter(treeModel: TreeModel) {
    treeModel.filterNodes((node: TreeNode) => this.filterTest(node.data));

    if ((!this.filterTypes || !this.filterTypes.value || !this.filterTypes.value.length)
      && (!this.filterStatus || !this.filterStatus.value || !this.filterStatus.value.length)
      && (!this.filterText || !this.filterText.length)) {
      treeModel.collapseAll();
    }
  }

  clearunFilter(treeModel: TreeModel) {
    this.filterText = '';
    this.runFilter(treeModel);
  }

  filterTest(nodeData) {
    let foundJobType = false;
    if (!this.filterTypes || !this.filterTypes.value || !this.filterTypes.value.length) {
      foundJobType = true;
    } else {
      const jobTypeIds = this.filterTypes.value.filter(i => i === nodeData.jobDocumentTypeId);
      if (jobTypeIds[0]) {
        foundJobType = true;
      }
    }

    let foundJobStatus = false;
    if (!this.filterStatus || !this.filterStatus.value || !this.filterStatus.value.length) {
      foundJobStatus = true;
    } else {
      const jobStatusIds = this.filterStatus.value.filter(i => i === nodeData.jobDocumentStatusId);
      if (jobStatusIds[0]) {
        foundJobStatus = true;
      }
    }

    let foundFilterText = false;
    if (!this.filterText || !this.filterText.length || nodeData.description.toLowerCase().search(this.filterText.toLowerCase()) >= 0) {
      foundFilterText = true;
    }

    return (foundJobType && foundJobStatus && foundFilterText);
  }

  onCopyNode() {
    this.notiService.showWarning('Copy information record not supported.');
    this.loading = true;
    this.jobDocumentService.treeNodes = [];
    this.refreshNodes();
  }

  createEmail() {
    // we can send a document(s) by email
    // attach docs from the Info section of Truth Engine
    this.emailPopupVisible = true;
    this.variationId = null;
    this.emailMessage = '';
    this.ccEmail = '';
    this.bccEmail = '';
    this.sendAddendum = false;
    this.toEmail = '';
    this.emailSubject = 'Job ' + this.jobService.currentJob.jobNumber + ' - '
      + this.globalService.getJobString(this.jobService.currentJob, false);
    this.selectedJobDocs = [];
    this.jobDocumentsForEmail = [];
    this.loadingDocs = true;
    this.subscriptions = this.subscriptions.concat(
      this.jobDocumentService.getJobDocumentForEmail(this.jobService.currentJob.id, true)
        .subscribe({
          next: (res) => {
            this.jobDocumentsForEmail = res;
            this.loadingDocs = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.jobDocuments = [];
            this.loadingDocs = false;
          }
        })
    );
  }

  attachmentSelectionChanged(e): void {
    // get all leaves as new list
    this.selectedJobDocs = e.component.getSelectedRowKeys('all').filter(this.utils.onlyUnique);
  }

  sendEmail() {
    this.dxLoadingVisible = true;
    const po = this.purchaseOrders?.find(i => i.id === this.selectedPurchaseOrderId);
    const isHideCostsFromTracking = this.purchaseOrdersService.costCentres?.find(i => i.id === po?.costCentreId)?.isHideCostsFromTracking;

    this.subscriptions = this.subscriptions.concat(
      this.jobDocumentService.sendEmail(this.jobService.currentJob.id, this.selectedJobDocs, this.toEmail,
        this.emailMessage, this.ccToSelf, this.ccEmail, this.bccEmail, this.sendAddendum, this.emailSubject,
        this.variationId, this.printImages, this.printVariationPrices, this.printNotApplicable, this.printNonPrintItems,
        this.printConnectedTogether, this.printVONumber, this.selectedPurchaseOrderId, isHideCostsFromTracking ? false : true)
        .subscribe({
          next: () => {
            this.dxLoadingVisible = false;
            this.emailPopupVisible = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.dxLoadingVisible = false;
          }
        })
    );
  }

  hasChildren(node: IJobDocument): boolean {
    if (node.recordTypeId === this.jobDocumentRecordTypeEnum.Detail) {
      return true;
    }

    let result = false;
    if (node.recordTypeId === this.jobDocumentRecordTypeEnum.Heading) {
      node.children.forEach((child) => {
        if (child.recordTypeId === this.jobDocumentRecordTypeEnum.Detail) {
          result = true;
        } else if (child.recordTypeId === this.jobDocumentRecordTypeEnum.Heading) {
          const headingHasChildren = this.hasChildren(child);
          if (headingHasChildren) {
            result = true;
          }
        }
      });
    }
    return result;
  }

  lookupEmail(fieldName) {
    // lookup an email address
    this.showGetEmail = true;
    this.fieldToSet = fieldName;
  }

  setEmail(event) {
    this.showGetEmail = false;

    if (event) {
      if (this.fieldToSet === 'toEmail') {
        this.toEmail = this.toEmail.length ? this.toEmail + ';' + event : event;
      } else if (this.fieldToSet === 'ccEmail') {
        this.ccEmail = this.ccEmail.length ? this.ccEmail + ';' + event : event;
      } else {
        this.bccEmail = this.bccEmail.length ? this.bccEmail + ';' + event : event;
      }
    }
  }

  getVariations() {
    this.subscriptions = this.subscriptions.concat(
      this._variationService.getVariations().subscribe({
        next: (variations) => {
          this.variations = variations;
        },
        error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  getPurchaseOrdersForJob() {
    if (this.purchaseOrdersActive) {
      this.selectedPurchaseOrderId = null;
      this.purchaseOrders = [];
      this.subscriptions = this.subscriptions.concat(
        this.purchaseOrdersService.getPurchaseOrdersAndCostCentresForJob(this.jobService.currentJob.id).subscribe({
          next: (res) => {
            this.purchaseOrders = res;
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  getVariationParams(e) {
    if (this.variationId !== e.value) {
      this.variationId = e.value;
      if (this.variationId) {
        this.variationParamsPopupVisible = true;
      }
    }
  }

  showJobRoles() {
    // use modal to set the users to the roles
    const modalRef = this.modalService.open(JobRolesComponent, { windowClass: 'modal-edit' });
    modalRef.componentInstance.jobNumber = this.jobNumber;

    modalRef.result.then(() => {
    }, () => {
    });
  }

  download(data) {
    if (!data.isSharePoint) {
      this.subscriptions = this.subscriptions.concat(
        this.jobDocumentAttachmentService.getJobDocAttachment(data.id, true, true).subscribe({
          next: (res) => {
            this.saveFile(this.globalService.base64ToArrayBuffer(res.attachment, res.attachmentName), res.attachmentName, res.attachmentTypeId);
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    } else {
      this.subscriptions = this.subscriptions.concat(
        this.jobDocumentAttachmentService.getSharePointDocument(this.jobService.currentJob?.jobNumber, data.sharePointId, false, true).subscribe({
          next: (res) => {
            this.saveFile(this.globalService.base64ToArrayBuffer(res.attachment, res.name), res.name, res.attachmentTypeId);
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  saveFile(blob: Blob, saveName: string, attachmentTypeId: number) {
    if (attachmentTypeId && !saveName.includes('.')) {
      saveName += '.' + AttachmentTypeEnum[attachmentTypeId];
    }
    saveAs(blob, saveName);
  }
}
