<div #ParentDiv>
  <div class="titleBar">
    <div class="title">Welcome to Truth Engine</div>
  </div>

  <div class="welcome">

    <br>

    <div class="chooseComp" *ngIf="multipleCompanies && !loading">
      <div *ngIf="!currComp" class="subTitle">Please Select a Company:</div>
      <div *ngIf="currComp" class="subTitle">Current Company:</div>
      <br>
      <div *ngIf="userCompanies && userCompanies.length">
        <div *ngIf="userCompanies[0].userTypeId === userTypeEnum.SuperUser">Start typing the company name</div>
        <dx-select-box [dataSource]="userCompanies" displayExpr="companyName" valueExpr="id" [value]="selectedCompanyId"
          searchMode="startswith" [searchEnabled]="true" [searchTimeout]="400" [minSearchLength]="1"
          [width]="companySelectWidth" [showClearButton]="true"
          [showDataBeforeSearch]="!isProduction || userCompanies[0].userTypeId !== userTypeEnum.SuperUser"
          (onValueChanged)="companySelected($event)" class="marginAuto">
          <div *dxTemplate="let data of 'item'">
            <div class="custom-item">
              <div class="product-name">
                {{ data.companyName }}
              </div>
            </div>
          </div>
        </dx-select-box>
      </div>

    </div>

    <div *ngIf="loading" style="margin-bottom: 50px">
      <js-loading-spinner></js-loading-spinner>
    </div>

    <div *ngIf="!loading && cannotGetCompanies" class="refresh">
      <h4>Login Error</h4>
      <h5>
        Ensure that the Safari setting:
        <span *ngIf="!isMobile">(Preferences -> Privacy)</span>
        "Prevent cross-site tracking" option is off.
      </h5>
      <h5>Otherwise contact your administrator</h5>
      <br>
      <h5>Please sign out to retry.</h5>
      <br>
      <button class="btn btn-primary" (click)="signOut()">Sign Out</button>
    </div>

    <br>

    <div *ngIf="!loading">
      <div *ngIf="!(innerWidth < 750)" class="user">
        <div class="userDetails">
          <div style="text-align: left;">
            <span *ngIf="currUser" class="username">{{currUser.firstName}} {{currUser.lastName}}</span>
            <span *ngIf="!currUser" class="username">User</span>
          </div>
          {{currUserEmail}}
        </div>
        <div class="userButtons">
          <button class="btn btn-outline-primary" (click)="signOut()">Sign out</button>
        </div>
      </div>
      <div *ngIf="innerWidth < 750" class="userSmall">
        <div class="userDetailsSmall">
          <div *ngIf="currUser" class="username">{{currUser.firstName}} {{currUser.lastName}}</div>
          <div *ngIf="!currUser" class="username">User</div>
          {{currUserEmail}}
        </div>
        <div class="userButtonsSmall">
          <button class="btn btn-outline-primary buttonSmall" (click)="signOut()">Sign out</button>
        </div>
      </div>
      <br>
      <div *ngIf="officeUser" class="links">
        <button *ngIf="admin" class="btn btn-sm btn-secondary linkBtn" (click)="launchAdminPortal()">
          Launch Admin Portal
        </button>
        <button *ngIf="admin || optionListWrite" class="btn btn-sm btn-secondary linkBtn" (click)="launchConfig()">
          Launch Config App
        </button>
        <button class="btn btn-sm btn-secondary linkBtn" (click)="launchEstConfig()">
          Launch Estimating Config App
        </button>
        <button *ngIf="isTrackingActive" class="btn btn-sm btn-secondary linkBtn" (click)="launchTracking()">
          Launch Tracking App
        </button>
        <button *ngIf="isPurchaseOrderSystemActive" class="btn btn-sm btn-secondary linkBtn"
          (click)="launchPurchaseOrders()">
          Launch Costing App
        </button>
        <a class="btn btn-sm btn-secondary linkBtn" href="mailto:gerard@truthengine.com">Support</a>
      </div>
    </div>

    <div class='versionString'>Truth Engine version {{currentVersion}}</div>
  </div>

  <br>
  <button class="btn btn-sm ms-2" (click)="clearCache()">
    to clear memory click here
  </button>
</div>