
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { GlobalService } from '../global.service';
import { Router } from '@angular/router';
import { BoardMaster, BoardType, BoardAttachment } from '../../dtos/board-master';
import { HttpService } from '../http.service';

@Injectable()
export class BoardMasterService {

  constructor(
    private router: Router,
    private zone: NgZone,
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  getBoards(): Observable<BoardMaster[]> {
    return this._http.get<BoardMaster[]>(this.globalService.getApiUrl() + '/boards',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addBoard(itm: any) {
    const url = this.globalService.getApiUrl() + '/boards';
    return this._http.post(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  updateBoard(id, itm: any) {
    const url = this.globalService.getApiUrl() + '/boards?boardId=' + id;
    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteBoard(itmId: number) {
    const url = this.globalService.getApiUrl() + '/boards?boardId=' + itmId;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  // Board Types
  getBoardTypes(): Observable<BoardType[]> {
    return this._http.get<BoardType[]>(this.globalService.getApiUrl() + '/board-types', this.httpService.getHttpOptions());
  }

  // board attachments
  getBoardAttachment(id): Observable<BoardAttachment> {
    return this._http.get<BoardAttachment>(this.globalService.getApiUrl() + '/boards/' + id + '/attachment',
      this.httpService.getHttpFileOptions()).pipe(
        catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    if (err.status === 500) {
      // not logged in
      console.log('redirecting using zone');
      this.zone.run(() => this.router.navigate(['']));
      // this.router.navigate(['welcome']);
    }
    return observableThrowError(err);
  }

}
