import { JobsModule } from './../jobs/jobs.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { SelectOptionModalComponent } from './select-option/select-option-modal.component';
import { HouseOptionsGridComponent } from './house-options-grid/house-options-grid.component';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        JobsModule
    ],
    declarations: [
        SelectOptionModalComponent,
        HouseOptionsGridComponent
    ],
    providers: [],
    exports: []
})
export class OptionListsModule { }
