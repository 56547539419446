<div class="modal-header">
  <h2 class="modal-title">Create sales variation header</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div *ngIf="!loading" class="modal-body">
  <form [formGroup]="editForm">
    <div class="row">
      <label class="control-label-sm col-sm-3" for="varnTitleId">Variation Title</label>
      <input class="form-control-sm col-sm-8" id="varnTitleId" type="text" placeholder="Title (required)"
        formControlName="varnTitle" required />
    </div>

    <div *ngIf="createOfficeVO" class="row mt-2">
      <label class="control-label-sm col-sm-3" for="varnOfficeTitleId">Office Variation Title</label>
      <input class="form-control-sm col-sm-8" id="varnOfficeTitleId" type="text" placeholder="Title (required)"
        formControlName="varnOfficeTitle" required />
    </div>
  </form>
</div>

<div *ngIf="!loading" class="modal-footer">
  <button type="button" class="btn btn-sm btn-primary" (click)="createVO()">Create</button>
</div>

<!-- Loading Spinner -->
<js-loading-spinner *ngIf="loading"></js-loading-spinner>
