<div class="modal-header">
  <h2 class="modal-title">Invoice Splits</h2>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-data-grid [dataSource]="dataSource" [allowColumnReordering]="false" [allowColumnResizing]="true"
    [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    (onInitNewRow)="initNewRow($event)" (onEditorPreparing)="onEditorPreparing($event)">

    <dxo-sorting mode="none"></dxo-sorting>
    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="full">
    </dxo-editing>
    <dxo-row-dragging [allowReordering]="true" [onReorder]="reorder"></dxo-row-dragging>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxi-column dataField="orderNo" caption="" [allowEditing]="false" [width]="50" alignment="center"> </dxi-column>

    <dxi-column dataField="variationSplitTypeId" caption="Type" dataType="number"
      [setCellValue]="setVariationSplitType">
      <dxo-lookup [dataSource]="claimSplitTypes" valueExpr="id" displayExpr="description"> </dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="jobLineId" caption="Claim">
      <dxo-lookup [dataSource]="claimJobLines" valueExpr="id" displayExpr="description"> </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="companyActivityId" caption="Activity">
      <dxo-lookup [dataSource]="activities" valueExpr="id" displayExpr="description"> </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="claimDate" caption="Date to Invoice" dataType="date" [width]="120" alignment="center"
      format="d-MMM-yy"> </dxi-column>

    <dxi-column *ngIf="lenderInformationEnabled" dataField="invoice" [width]="150"
      [calculateCellValue]="calculateInvoiceToValue" [setCellValue]="setInvoiceToCellValue">
      <dxo-lookup [dataSource]="invoiceTos" valueExpr="description" displayExpr="description"> </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="isInvoiceToClient" dataType="boolean" [visible]="false">
    </dxi-column>

    <dxi-column dataField="amount" dataType="number" format="#,###" [width]="100">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="dateInvoiced" dataType="date" [width]="100" [allowEditing]="false" alignment="center"
      format="d-MMM-yy">
    </dxi-column>


    <dxo-summary [calculateCustomSummary]="calculateTotal" [recalculateWhileEditing]="true">
      <dxi-total-item name="gridTotal" summaryType="custom" valueFormat="#,##0" displayFormat="{0}"
        showInColumn="amount">
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>

  <div class="totalLine">
    Variation total: <span class="valueClass">{{ variationTotal | number }}</span>
  </div>
  <div class="totalLine">
    Outstanding: <span class="valueClass">{{ outstandingAmount | number }}</span>
  </div>

  <br>
  <div *ngIf="isAdmin">Note: Admin users can add <b>new</b> splits to claims that are already invoiced to adjust posted
    claims. This does <u>not change</u> the accounting entry.</div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" (click)="close()">Close</button>
</div>
