<div class="modal-header">
  <h4 class="modal-title">Change Variation Type (Admin Only)</h4>

  <button *ngIf="!loading" type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-select-box [dataSource]="variationTypes" displayExpr="text" valueExpr="id"
    [(value)]="variationTypeId"></dx-select-box>
</div>

<div *ngIf="!loading" class="modal-footer">
  <button class="btn btn-sm btn-danger" (click)="submit()" [disabled]="!variationTypeId">Go</button>
</div>
