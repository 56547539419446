<dx-popup class="popup" [width]="popupWidth" [height]="popupHeight" [showTitle]="true" title="Select Vendor Email"
  [dragEnabled]="true" [hideOnOutsideClick]="true" [visible]="true" (onHiding)="cancel()" [shading]="false">
  <div *dxTemplate="let data of 'content'">
    <dx-tab-panel #tabPanel [height]="popupHeight - 125" [dataSource]="emailTypes" [(selectedIndex)]="selectedIndex"
      [loop]="false" [animationEnabled]="true" [swipeEnabled]="true">
      <div *dxTemplate="let emailType of 'title'">
        <span>{{emailType.description}}</span>
      </div>
      <div *dxTemplate="let company of 'item'">
        <div class="tabpanel-item">
          <!-- Vendors -->
          <dx-data-grid *ngIf="selectedIndex === 0" #dataGridVendors [dataSource]="dataSourceVendors"
            [remoteOperations]="false" [height]="popupHeight - 165" [showBorders]="false" [focusedRowEnabled]="true"
            [(selectedRowKeys)]="selectedVendorRowKeys" [hoverStateEnabled]="true" [scrolling]="{ mode: 'infinite' }"
            (onRowClick)="onMultiRowClick($event)">

            <dxo-selection [allowSelectAll]="false" showCheckBoxesMode='always' mode="multiple">
            </dxo-selection>
            <dxo-paging [enabled]="false"> </dxo-paging>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-search-panel [visible]="true" [width]="200"></dxo-search-panel>

            <dxi-column dataField="vendorCode" caption="Code" [width]="100"></dxi-column>
            <dxi-column dataField="vendorName"></dxi-column>
            <dxi-column dataField="email"> </dxi-column>
          </dx-data-grid>

          <!-- Users -->
          <dx-data-grid *ngIf="selectedIndex === 1" #dataGridUsers [dataSource]="dataSourceUsers"
            [remoteOperations]="false" [height]="popupHeight - 165" [showBorders]="false" [focusedRowEnabled]="true"
            [(selectedRowKeys)]="selectedUserRowKeys" [hoverStateEnabled]="true" [scrolling]="{ mode: 'infinite' }"
            (onRowClick)="onMultiRowClick($event)">

            <dxo-selection [allowSelectAll]="false" showCheckBoxesMode='always' mode="multiple">
            </dxo-selection>
            <dxo-paging [enabled]="false"> </dxo-paging>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-search-panel [visible]="true" [width]="200"></dxo-search-panel>

            <dxi-column dataField="fullName"></dxi-column>
            <dxi-column dataField="email"> </dxi-column>
          </dx-data-grid>

          <!-- Client -->
          <dx-data-grid *ngIf="selectedIndex === 2" #dataGridClient [dataSource]="dataSourceClient"
            [remoteOperations]="false" [height]="popupHeight - 165" [showBorders]="false" [focusedRowEnabled]="true"
            [(selectedRowKeys)]="selectedClientRowKeys" [hoverStateEnabled]="true" [scrolling]="{ mode: 'infinite' }"
            (onRowClick)="onMultiRowClick($event)">

            <dxo-selection [allowSelectAll]="false" showCheckBoxesMode='always' mode="multiple">
            </dxo-selection>
            <dxo-paging [enabled]="false"> </dxo-paging>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-search-panel [visible]="false"></dxo-search-panel>

            <dxi-column dataField="email"> </dxi-column>
          </dx-data-grid>
        </div>
      </div>
    </dx-tab-panel>

    <button *ngIf="selectedIndex === 0" type="button" class="btn btn-sm btn-primary mt-1" (click)="selectVendor()"
      [disabled]="!selectedVendorRowKeys.length">
      Select </button>

    <button *ngIf="selectedIndex === 1" type="button" class="btn btn-sm btn-primary mt-1" (click)="selectUser()"
      [disabled]="!selectedUserRowKeys.length">
      Select </button>

    <button *ngIf="selectedIndex === 2" type="button" class="btn btn-sm btn-primary mt-1" (click)="selectClient()"
      [disabled]="!selectedClientRowKeys.length">
      Select </button>
  </div>
</dx-popup>
