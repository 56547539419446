import { Holiday } from './../../dtos/holiday';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { TrackingField } from '../../dtos/tracking-field';
import { JobField } from '../../dtos/job-field';
import { TrackingFieldLookup } from '../../dtos/tracking-field-lookup';
import { TrackingFieldGroup } from '../../dtos/tracking-field-group';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class TrackingFieldsService {
  trackingFieldsCompany: number;
  trackingFields: TrackingField[];
  trackingFieldGroups: TrackingFieldGroup[];
  trackingFieldGroupsCompany: number;
  trackingFieldLookupsCompany: number;
  trackingFieldLookups: TrackingFieldLookup[];
  holidays: Holiday[];
  holidaysCompany: number;

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  getTrackingFields(activeOnly: boolean, useCache: boolean): Observable<TrackingField[]> {
    if (useCache && this.trackingFieldsCompany === this.globalService.getCurrentCompany().id
      && this.trackingFields && this.trackingFields.length) {
      return of(this.trackingFields);
    } else {
      return this._http.get<TrackingField[]>(this.globalService.getApiUrl() +
        '/tracking-fields?activeOnly=true&isShownInJobDetailsOnly=true', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.trackingFields = res;
            this.trackingFieldsCompany = this.globalService.getCurrentCompany().id;
            if (activeOnly) {
              res = res.filter(i => i.isActive);
            }
          }),
          catchError(this.handleError));
    }
  }

  /* Field Groups */

  getTrackingFieldGroups(activeOnly: boolean, useCache: boolean): Observable<TrackingFieldGroup[]> {
    if (useCache && this.trackingFieldGroupsCompany === this.globalService.getCurrentCompany().id
      && this.trackingFieldGroups && this.trackingFieldGroups.length) {
      return of(this.trackingFieldGroups);
    } else {
      return this._http.get<TrackingFieldGroup[]>(this.globalService.getApiUrl() +
        '/tracking-field-groups?activeOnly=true&isShownInJobDetailsOnly=true', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.trackingFieldGroups = res; this.trackingFieldGroupsCompany = this.globalService.getCurrentCompany().id;
            if (activeOnly) {
              res = res.filter(i => i.isActive);
            }
          }),
          catchError(this.handleError));
    }
  }

  /* Field Lookups */

  getAllTrackingFieldLookups(): Observable<TrackingFieldLookup[]> {
    if (this.trackingFieldLookupsCompany === this.globalService.getCurrentCompany().id
      && this.trackingFieldLookups && this.trackingFieldLookups.length) {
      return of(this.trackingFieldLookups);
    } else {
      return this._http.get<TrackingFieldLookup[]>(this.globalService.getApiUrl() +
        '/tracking-field-lookups', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.trackingFieldLookups = res; this.trackingFieldLookupsCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }

  /* Job Fields */

  getJobFields(jobId: number): Observable<JobField[]> {
    return this._http.get<JobField[]>(this.globalService.getApiUrl() +
      '/job/' + jobId + '/fields?activeOnly=true&isShownInJobDetailsOnly=true', this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addJobField(dataRecord: any): Observable<JobField> {
    const url = this.globalService.getApiUrl() + '/job-fields';
    return this._http.post<JobField>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateJobField(jobId: number, id: string, itm: any): Observable<JobField> {
    const url = this.globalService.getApiUrl() + '/job/' + jobId + '/field/' + id;
    return this._http.patch<JobField>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  getJobFieldsData(): Observable<TrackingFieldLookup[]> {
    return forkJoin(
      [
        this.getAllTrackingFieldLookups(),
        this.getTrackingFields(true, true),
        this.getTrackingFieldGroups(true, true)

      ]
    )
      .pipe(map(
        ([records]) => {
          return records;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  getHolidays(useCache: boolean): Observable<Holiday[]> {
    if (useCache && this.holidaysCompany === this.globalService.getCurrentCompany().id
      && this.holidays && this.holidays.length) {
      return of(this.holidays);
    } else {
      const url = this.globalService.getApiUrl() + '/holidays/';
      return this._http.get<Holiday[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.holidays = res;
          this.holidaysCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
