import { GlobalService } from './../../services/global.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VariationService } from '../../services/felixApi/variation.service';

@Component({
  selector: 'js-sales-choice-modal',
  templateUrl: './sales-choice-modal.component.html',
  styleUrls: ['./sales-choice-modal.component.scss']
})
export class SalesChoiceModalComponent implements OnInit {
  addendumName: string;

  constructor(public _variationService: VariationService,
    private _globalService: GlobalService,
    private _activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.addendumName = this._globalService.getAddendumName();
  }

  closeModal(ans) {
    this._activeModal.close(ans);
  }
}
