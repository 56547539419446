<js-background-logo [showImg]="showBackgroundLogo"></js-background-logo>

<div class="card">
  <div class="card-header">

    <!-- enter job number -->
    <js-job-number (refreshJobNumber)='refreshJobNumber($event)'></js-job-number>

    <button *ngIf="permissionWrite && showAddButton && currentJob" class="btn btn-sm btn-primary mt-2"
      (click)="addVariation()">
      New Variation
    </button>
  </div>

  <div class="card-body">

    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <!-- Warning Note -->
    <p *ngIf="!isClient && (currentJob && currentJob.warningNote && currentJob.warningNote !== '')"
      class="warningClass">
      WARNING: {{currentJob.warningNote}}
    </p>

    <div>
      <div *ngIf="currentJob && formErrorMessage">
        {{ formErrorMessage }}
      </div>

      <dx-data-grid *ngIf="!loading && formErrorMessage !== 'No variations found for given job'"
        [dataSource]="variations" keyExpr="id" [height]="tableMaxHeight" [remoteOperations]="false" [showBorders]="true"
        [showRowLines]="true" [hoverStateEnabled]="false" [wordWrapEnabled]="true" [rowAlternationEnabled]="false"
        [allowColumnReordering]="false" [allowColumnResizing]="false" [columnAutoWidth]="true"
        (onRowPrepared)="onRowPrepared($event)" (onCellPrepared)="onCellPrepared($event)"
        [class.clientWidth]="isClient || isAssociate" [class.officeWidth]="!isClient && !isAssociate"
        (onToolbarPreparing)="onToolbarPreparing($event)">

        <dxo-scrolling mode="standard"></dxo-scrolling>
        <dxo-export [enabled]="showAddButton && !isAssociate"></dxo-export>
        <dxo-search-panel [visible]="showAddButton" location="before" [width]="200"></dxo-search-panel>
        <dxo-paging [enabled]="false"></dxo-paging>

        <dxi-column dataField="displayedVariationNumber" caption="Variation" [width]="70" alignment="center"
          cellTemplate="voNumberCellTemplate2">
        </dxi-column>
        <dxi-column dataField="title" cellTemplate="descCellTemplate2" [minWidth]="220"></dxi-column>

        <dxi-column dataField="variationDate" caption="Dated" dataType="date" [width]="85" format="dd-MMM-yy"
          alignment="center" [hidingPriority]="8"></dxi-column>

        <dxi-column *ngIf="!isAssociate && canSeeAmountsPerm" dataField="variationTotal" dataType="number"
          caption="Total (Inc. GST)" [width]="100" [hidingPriority]="7" cellTemplate="totalCellTemplate2">
        </dxi-column>

        <dxi-column *ngIf="!isAssociate" dataField="statusId" dataType="number" caption='Status' [width]="110"
          alignment="center" [hidingPriority]="6">
          <dxo-lookup [dataSource]="statuses" valueExpr="id" displayExpr="description"> </dxo-lookup>
        </dxi-column>

        <dxi-column *ngIf="!isClient && !isAssociate" dataField="author" dataType="text" caption='Raised By'
          [width]="150" [hidingPriority]="1">
        </dxi-column>

        <dxi-column *ngIf="!isClient && !isAssociate" dataField="approvedUserId" dataType="number" caption='Approved By'
          [width]="150" [hidingPriority]="3">
          <dxo-lookup [dataSource]="users" valueExpr="id" displayExpr="fullName"> </dxo-lookup>
        </dxi-column>

        <dxi-column *ngIf="!isClient && !isAssociate" dataField="approvedDate" caption="Approved" dataType="date"
          [width]="85" format="dd-MMM-yy" alignment="center" [hidingPriority]="4"></dxi-column>

        <dxi-column *ngIf="!isClient && !isAssociate && isConstructionActivitiesUsed" dataField="companyActivity"
          caption='Claimed At' [width]="160" [hidingPriority]="2" cellTemplate="claimCellTemplate">
        </dxi-column>

        <dxi-column *ngIf="!isAssociate" dataField="additionalDays" dataType="number" caption='+Days' [width]="50"
          [hidingPriority]="1">
        </dxi-column>

        <dxi-column *ngIf="!isClient && !isAssociate" dataField="assignedUserId" dataType="number" caption='Estimator'
          [width]="150" [hidingPriority]="3">
          <dxo-lookup [dataSource]="users" valueExpr="id" displayExpr="fullName"> </dxo-lookup>
        </dxi-column>

        <dxi-column *ngIf="!isAssociate && !isClient" caption="" width="110" cellTemplate="gotoTemplate"
          [hidingPriority]="5">
        </dxi-column>


        <!-- templates -->
        <div *dxTemplate="let data of 'gotoTemplate'" class="text-center">
          <dx-button *ngIf="data.data.variationNumber > 0" id="gotoButton" text="Go to Variation"
            (onClick)="gotoSelections(data)" class="bootstrap-blue">
          </dx-button>
        </div>

        <div *dxTemplate="let cellInfo of 'voNumberCellTemplate2'">
          <div *ngIf="cellInfo.value && cellInfo.value != ''">
            <span *ngIf="isAssociate || isClient" id="variationNumber">{{cellInfo.value}}</span>
            <dx-button *ngIf="!isAssociate && !isClient" [text]='cellInfo.value' id="variationNumber" type="outline"
              (onClick)="openVariation(cellInfo)">
            </dx-button>
          </div>
        </div>

        <div *dxTemplate="let cellInfo of 'descCellTemplate2'">
          <div *ngIf="cellInfo.data.variationNumber <= 0 && (!isRestrictedForm || cellInfo.data.variationNumber < 0)">
            <span>{{cellInfo.value}}</span>
          </div>
          <div *ngIf="cellInfo.data.variationNumber > 0 && cellInfo.value != ''">
            <span id="variationText" (click)="gotoSelections(cellInfo)">{{cellInfo.value}}</span>
          </div>
        </div>

        <div *dxTemplate="let cellInfo of 'totalCellTemplate2'">
          <div *ngIf="cellInfo.value && cellInfo.value != ''">
            <div *ngIf="cellInfo.data.variationNumber >= 0">
              <span *ngIf="isPendingVariations && cellInfo.data.title === 'Grand Total'" id="totalNoteTip"
                class="red">**</span>
              <span>{{cellInfo.value | number : '1.0-0'}}</span>
              <dx-tooltip target="#totalNoteTip" showEvent="dxhoverstart" hideEvent="dxhoverend"
                [hideOnOutsideClick]="true">
                <div *dxTemplate="let data of 'content'">
                  <div>Includes un-approved variations</div>
                </div>
              </dx-tooltip>
            </div>
          </div>
        </div>

        <div *dxTemplate="let data of 'claimCellTemplate'">
          {{ getClaimActivity(data) }}
        </div>

        <dxi-column *ngIf="!isClient && !isAssociate" type="buttons" [width]="50">
          <dxi-button icon="edit" [onClick]="openHeader" hint='Open Header' [visible]="isEditIconVisible">
          </dxi-button>
        </dxi-column>
      </dx-data-grid>

    </div>
  </div>
</div>

<div *ngIf="salesVariationsActive && canLockJob && currentJob" class="card-footer footer-sticky">
  <button class="btn btn-sm btn-primary ms-1" (click)="createContract()">Lock Job / Finalise Contract</button>
</div>
