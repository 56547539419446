import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { JobVarItemService } from '../../../services/felixApi/job-var-item.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'js-mark-all-checked',
  templateUrl: './mark-all-checked.component.html',
  styleUrls: ['./mark-all-checked.component.scss']
})
export class MarkAllCheckedComponent implements OnDestroy {
  @Input() jobVariationId: number;

  subscriptions: Subscription[] = [];
  loading = false;

  constructor(private _activeModal: NgbActiveModal,
    private notiService: NotificationService,
    private jobVarItemService: JobVarItemService) { }


  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  cancel() {
    this._activeModal.dismiss();
  }

  submit(isChecked: boolean) {
    this.loading = true;

    this.subscriptions = this.subscriptions.concat(
      this.jobVarItemService.updateJobVarItemsAllChecked(this.jobVariationId, isChecked).subscribe({
        next: () => {
          this._activeModal.close();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }
}
