import { JobService } from './job.service';
import { throwError as observableThrowError, Observable, forkJoin } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { HouseType } from '../../dtos/house-type';
import { HouseModification } from '../../dtos/house-modification';
import { Lookup } from '../../dtos/lookup';
import { HttpService } from '../http.service';
import { ClaimsService } from './claims.service';
import { RoleTypeEnum } from '../../dtos/role-type.enum';
import { UtilsService } from '../utils.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  houseTypes: HouseType[];
  houseModifications: HouseModification[];
  landZones: Lookup[];
  landTitleTypes: Lookup[];
  landTypes: Lookup[];

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private jobService: JobService,
    private claimService: ClaimsService,
    private utilsService: UtilsService,
    private globalService: GlobalService) { }

  getHouseTypes(): Observable<HouseType[]> {
    return this._http.get<HouseType[]>(this.globalService.getApiUrl() + '/house-types?activeOnly=true',
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.houseTypes = [new HouseType('', 0)];
          this.houseTypes = this.houseTypes.concat(res);
        }),
        catchError(this.handleError));
  }

  getHouseTypeById(houseTypeId: number): Observable<HouseType> {
    return this._http.get<HouseType>(this.globalService.getApiUrl() + '/house-types/' + houseTypeId,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getHouseModifications(): Observable<HouseModification[]> {
    return this._http.get<HouseModification[]>(this.globalService.getApiUrl() + '/house-modifications?activeOnly=true',
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.houseModifications = res;
        }),
        catchError(this.handleError));
  }

  getLandZones(): Observable<Lookup[]> {
    return this._http.get<Lookup[]>(this.globalService.getApiUrl() + '/land-zones',
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.landZones = [new Lookup('', 0)];
          this.landZones = this.landZones.concat(res);
        }),
        catchError(this.handleError));
  }

  getLandTitleTypes(): Observable<Lookup[]> {
    return this._http.get<Lookup[]>(this.globalService.getApiUrl() + '/land-title-types',
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.landTitleTypes = [new Lookup('', 0)];
          this.landTitleTypes = this.landTitleTypes.concat(res);
        }),
        catchError(this.handleError));
  }

  getLandTypes(): Observable<Lookup[]> {
    return this._http.get<Lookup[]>(this.globalService.getApiUrl() + '/land-types',
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.landTypes = [new Lookup('', 0)];
          this.landTypes = this.landTypes.concat(res);
        }),
        catchError(this.handleError));
  }

  getHouseAndLandData(): Observable<HouseType[]> {
    return forkJoin(
      [this.getHouseTypes(),
      this.getHouseModifications(),
      this.getLandZones(),
      this.getLandTitleTypes(),
      this.getLandTypes(),
      this.jobService.getJobsByAddress('')]
    )
      .pipe(map(
        ([houseTypes]) => {
          return houseTypes;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getJobRolesHouseTypesClaims(jobId: number, jobNumber: string): Observable<HouseType[]> {
    return forkJoin(
      [
        this.getHouseTypes(),
        this.jobService.getJobRoles(jobNumber),
        this.claimService.getJobClaims(jobId),
        this.getLandZones(),
        this.getLandTitleTypes(),
        this.getLandTypes(),
      ]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  formData(): { [fieldName: string]: string } {
    return {
      JobNumber: this.jobService.currentJob?.jobNumber,
      JobAddress: this.globalService.getJobString(this.jobService.currentJob, false),
      ContractName: this.jobService.currentJob?.contractName,
      PracticalCompletionDate: this.utilsService.convertDateToStringDMY(this.jobService.currentJobExtra?.completionDate, ''),
      HandoverDate: this.utilsService.convertDateToStringDMY(this.jobService.currentJobExtra?.handoverDate, ''),
      Salutation: this.jobService.currentJob?.salutation ?? '',
      LotNumber: this.jobService.currentJob?.jobAddress?.lotNumber ?? '',
      StreetNumber: this.jobService.currentJob?.jobAddress?.streetNumber ?? '',
      StreetName1: this.jobService.currentJob?.jobAddress?.streetName1 ?? '',
      StreetName2: this.jobService.currentJob?.jobAddress?.streetName2 ?? '',
      SuburbTown: this.jobService.currentJob?.jobAddress?.suburbTown ?? '',
      State: this.jobService.currentJob?.jobAddress?.state ?? '',
      PostCode: this.jobService.currentJob?.jobAddress?.postCode ?? '',
      ClientEmail: this.jobService.currentJob?.clientEmail ?? '',
      PhoneNumber1: this.jobService.currentJob?.phoneNumber1 ?? '',
      PhoneNumber2: this.jobService.currentJob?.phoneNumber2 ?? '',
      SalesRep: this.jobService.currentJobRoles?.find(jr => jr.roleId === RoleTypeEnum.SalesRep)?.user?.fullName ?? '',
      DepositAmount: this.jobService.currentJob?.depositAmount?.toFixed(2) ?? '',
      ContractTotal: this.jobService.currentJob?.contractPrice?.toFixed(2) ?? '',
      SalesDate: this.utilsService.convertDateToStringDMY(this.jobService.currentJob?.salesDate, ''),
      ContractSignedDate: this.utilsService.convertDateToStringDMY(this.jobService.currentJob?.contractSignedDate, ''),
      HouseTypeDescription: this.houseTypes?.find(h => h.id === this.jobService.currentJob?.houseTypeId)?.description ?? '',
      PlanNumber: this.jobService.currentJob?.planNumber ?? '',
      Volume: this.jobService.currentJob?.volume ?? '',
      Folio: this.jobService.currentJob?.folio ?? '',
      Council: this.jobService.currentJob?.council ?? '',
      Estate: this.jobService.currentJob?.estate ?? '',
      LandZoning: this.landZones?.find(lz => lz.id === this.jobService.currentJob?.landZoningId)?.description ?? '',
      LandTitleType: this.landTitleTypes?.find(ltt => ltt.id === this.jobService.currentJob?.landTitleTypeId)?.description ?? '',
      LandType: this.landTypes?.find(lt => lt.id === this.jobService.currentJob?.landTypeId)?.description ?? '',
      SiteManager: this.jobService.currentJobRoles?.find(jr => jr.roleId === RoleTypeEnum.SiteManager)?.user?.fullName ?? '',
      LiaisonOfficer: this.jobService.currentJobRoles?.find(jr => jr.roleId === RoleTypeEnum.LiaisonOfficer)?.user?.fullName ?? '',
      DepositPaid: this.jobService.currentJob?.depositPaid?.toFixed(2) ?? '',
      DepositBalanceDue: this.utilsService.convertDateToStringDMY(this.jobService.currentJob?.depositBalanceDue, ''),
      TitleDueDate: this.utilsService.convertDateToStringDMY(this.jobService.currentJob?.titleDueDate, ''),
      ClientLotNumber: this.jobService.currentJob?.contractAddress?.lotNumber ?? '',
      ClientStreetNumber: this.jobService.currentJob?.contractAddress?.streetNumber ?? '',
      ClientStreetName1: this.jobService.currentJob?.contractAddress?.streetName1 ?? '',
      ClientStreetName2: this.jobService.currentJob?.contractAddress?.streetName2 ?? '',
      ClientSuburbTown: this.jobService.currentJob?.contractAddress?.suburbTown ?? '',
      ClientState: this.jobService.currentJob?.contractAddress?.state ?? '',
      ClientPostCode: this.jobService.currentJob?.contractAddress?.postCode ?? '',
      Date: '',
      Date2: '',
      Text: '',
    };
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
