import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../services/global.service';
import { TreeComponent, } from '@circlon/angular-tree-component';
import { JobItemService } from '../services/felixApi/job-item.service';
import { ItemTypeEnum } from '../dtos/item-type.enum';
import { IJobItemWithChildren } from '../dtos/job-item-with-children';
import { IJob } from '../dtos/job';
import { NotificationService } from '../services/notification.service';
import { JobService } from '../services/felixApi/job.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { JobClaimsComponent } from '../jobs/job-modals/job-claims/job-claims.component';
import { ConfigurationEnum } from '../dtos/configuration-enum';
import { ITreeNode } from '@circlon/angular-tree-component/lib/defs/api';

@Component({
  selector: 'js-accept-into-contract',
  templateUrl: './accept-into-contract.component.html',
  styleUrls: ['./accept-into-contract.component.scss']
})
export class AcceptIntoContractComponent implements OnInit, OnDestroy {

  currentJob: IJob;
  subscriptions: Subscription[] = [];
  jobString: string;
  treeOptions = {};
  loading = true;
  oneNode: IJobItemWithChildren = new IJobItemWithChildren;
  treeNodes: IJobItemWithChildren[] = [];
  itemTypeEnum = ItemTypeEnum;
  id: number;
  foundItemSet: boolean;
  itemsToSet: any[] = [];
  jobs: IJob[];

  @ViewChild('tree') tree: TreeComponent;

  constructor(private _globalService: GlobalService,
    private _jobItemService: JobItemService,
    private _jobService: JobService,
    private router: Router,
    private notiService: NotificationService,
    private modalService: NgbModal) { }


  ngOnInit(): void {
    if (!this._jobService.currentJob) {
      this.router.navigate(['selections']);
    } else {
      this._globalService.setAreaSelected(null); // set no menu item selected

      this.currentJob = this._jobService.currentJob;
      this.jobString = this._globalService.getJobString(this.currentJob, true);

      this.checkDuplicateHouseOnStreet();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  checkDuplicateHouseOnStreet() {
    // we give a warning if the same house type is on the same street so clients don't get side by side same house
    if (this.currentJob.houseTypeId && this.currentJob.jobAddress && this.currentJob.jobAddress.streetName1) {
      let streetToSearchFor = this.currentJob.jobAddress.streetName1.toLowerCase().trim();

      // get rid of the street suffix - word at the end
      if (streetToSearchFor.lastIndexOf(' ') > 0) {
        streetToSearchFor = streetToSearchFor.slice(0, streetToSearchFor.lastIndexOf(' '));
      }
      // console.log('-' + streetToSearchFor + '-');

      this.subscriptions = this.subscriptions.concat(
        this._jobService.getJobsByAddress(streetToSearchFor)
          .subscribe({
            next: (jobs) => {
              this.jobs = jobs;

              const foundDuplicate = this.jobs.find(i => i.houseTypeId === this.currentJob.houseTypeId
                && i.id !== this.currentJob.id);

              if (foundDuplicate) {
                this.notiService.showWarningNoTimeOut('NOTE: Same house type on same street as job ' + foundDuplicate.jobNumber);
              }

              this.setupJobItemNodes();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    } else {
      this.setupJobItemNodes();
    }
  }

  // set up the tree nodes for the add/edit item
  setupJobItemNodes() {
    this.treeOptions = {
      idField: 'id',
      displayField: 'description',
      childrenField: 'children',
      useVirtualScroll: true,
      nodeHeight: 22,
      scrollOnActivate: false
    };
    // get the items from this VO only - create from list in memory
    this.treeNodes = [];
    this.getJobItems();
  }

  getJobItems() {
    this.subscriptions = this.subscriptions.concat(
      this._jobItemService.getAllJobItems(this.currentJob.jobNumber, false, true, false, false)
        .subscribe({
          next: (jobItemsRaw) => {
            this._jobItemService.currentJobItemsUnfiltered = jobItemsRaw;

            this.oneNode.children = this.setupJobItemNodesChildren(1, null);

            this.treeNodes = this.treeNodes.concat({
              id: 0,
              itemTypeId: 0,
              changeTypeId: 0,
              jobVarTypeId: 1,
              itemChangedId: 0,
              description: 'All Items',
              originalItemTypeId: 0,
              originalItemId: 0,
              connectedItemId: 0,
              children: this.oneNode.children,
              parentId: null,
              changedByVOId: 0,
              changedByJobVarId: 0,
              masterItemId: 0,
              hasLinkedItems: false,
              optionNumber: 1,
              checked: false,
              indeterminate: false,
              isChecked: false,
              variationItemNumber: null
            });

            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  setupJobItemNodesChildren(originalItemTypeId: number, id: number): IJobItemWithChildren[] {
    // recursively check the children

    let childrenNodes: IJobItemWithChildren[] = [];
    const childNode: IJobItemWithChildren = new IJobItemWithChildren;
    this._jobItemService.currentJobItemsUnfiltered.forEach(element => {

      // we skip deleted items
      if (((id === null && element.jobItemAboveId === null)
        || (id !== null && element.jobItemAboveTypeId === originalItemTypeId && element.jobItemAboveId === id))
        && !element.isHistoryRecord && !element.isDeleted && !element.isSetUpLine) {

        if (element.itemTypeId === this.itemTypeEnum.Heading) {
          childNode.children = this.setupJobItemNodesChildren(element.originalItemTypeId, element.id);
        } else {
          childNode.children = [];
        }

        if (!element.itemDescription || element.itemDescription === '') {
          childNode.description = element.selection;
        } else if (element.selection) {
          childNode.description = element.itemDescription + ' - ' + element.selection;
        } else {
          childNode.description = element.itemDescription;
        }

        // if PS then add to desc
        if (element.provisionalSum && element.provisionalSum !== 0) {
          childNode.description += '  PS: $' + element.provisionalSum;
        }

        // if the item has not been changed use the original id
        if (element.changedByJobVarId && element.changedByJobVarId !== 0) {
          this.id = element.changedByJobVarId;
        } else {
          this.id = element.id;
        }

        // add manually as the point will not work.
        childrenNodes = childrenNodes.concat({
          id: this.id,
          itemTypeId: element.itemTypeId,
          changeTypeId: element.changeTypeId,
          jobVarTypeId: element.jobVarTypeId,
          itemChangedId: element.itemChangedId,
          description: childNode.description,
          originalItemTypeId: element.originalItemTypeId,
          originalItemId: element.id,
          connectedItemId: element.connectedItemId,
          children: childNode.children,
          parentId: id,
          changedByVOId: element.changedByVOId,
          changedByJobVarId: element.changedByJobVarId,
          masterItemId: element.masterItemId,
          hasLinkedItems: element.hasLinkedItems,
          optionNumber: element.optionNumber,
          checked: false,
          indeterminate: false,
          isChecked: element.isChecked,
          variationItemNumber: element.variationItemNumber
        });
      }
    });
    return childrenNodes;
  }

  treeNodeClicked(node: ITreeNode) {
    node.data.checked = !node.data.checked;
    node.data.indeterminate = false;

    if (node.children) {
      node.children.forEach((child) => this.checkAllChildren(child, node.data.checked));
      node.expandAll();
    }

    // if we have linked items we keep together so tick/untick them automatically
    if (node.data.masterItemId !== null || node.data.hasLinkedItems) {
      node.parent.children.forEach((item) => {
        if (node.data.masterItemId === item.data.id
          || node.data.id === item.data.masterItemId
          || (node.data.masterItemId && node.data.masterItemId === item.data.masterItemId)) {
          item.data.checked = node.data.checked;
        }
      });
    }

    if (node.data.id !== 0) {
      this.updateParentNodeCheckbox(node.parent);
    }
  }

  checkAllChildren(node, checkValue) {
    node.data.checked = checkValue;
    node.data.indeterminate = false;
    if (node.children) {
      node.children.forEach((child) => this.checkAllChildren(child, checkValue));
    }
  }

  updateParentNodeCheckbox(node: ITreeNode) {
    let allChildrenChecked = true;
    let noChildChecked = true;

    for (const child of node.children) {
      if (!child.data.checked || child.data.indeterminate) {
        allChildrenChecked = false;
      }
      if (child.data.checked) {
        noChildChecked = false;
      }
    }

    if (allChildrenChecked) {
      node.data.checked = true;
      node.data.indeterminate = false;
    } else if (noChildChecked) {
      // for this create contract we need to be able to send a heading without any children
      // node.data.checked = false;
      // node.data.indeterminate = false;
      this.notiService.showInfo('Headings will still be accepted into the contract.'
        + ' If you do not want the heading then specifically set to unticked.');
      node.data.checked = true;
      node.data.indeterminate = true;
    } else {
      node.data.checked = true;
      node.data.indeterminate = true;
    }

    if (node.data.id !== 0) {
      this.updateParentNodeCheckbox(node.parent);
    }
  }

  onTreeInit(tree: TreeComponent) {
    tree.treeModel.virtualScroll.setViewport(tree.treeModel.virtualScroll.viewport);
  }

  createContract() {
    // check if we can lock the job and create the contract version
    this.foundItemSet = false;
    this.itemsToSet = [];
    this.loading = true;

    // console.log(JSON.stringify(this.tree.treeModel.nodes));
    // top item is the all item so we skip that
    this.tree.treeModel.nodes[0].children.forEach((child) => this.checkAddItemsToCopy(child));

    if (!this.foundItemSet) {
      this.loading = false;
      this.notiService.showWarning('At least one item must be accepted. Cannot create contract.');
    } else {
      // update

      // need to get any isDeleted items and send them also - as these could be 'unhidden' by a future variation
      this._jobItemService.currentJobItemsUnfiltered.forEach(item => {
        if (item.isDeleted && !item.isHistoryRecord && !item.isSetUpLine) {
          this.itemsToSet = this.itemsToSet.concat({
            jobVarTypeId: item.originalItemTypeId,
            id: item.id
          });
        }
      });

      // console.log(JSON.stringify(this.itemsToSet));
      this.subscriptions = this.subscriptions.concat(
        this._jobService.updateJobAcceptItems(this.itemsToSet).subscribe({
          next: (res) => {
            this.notiService.showSuccess('Job Locked & Contract created successfully.');
            this.claimLines();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
      );
    }
  }

  claimLines() {
    if (this._globalService.getCompanyConfigValue(ConfigurationEnum.ClaimSchedule) === 1) {
      const modalRef = this.modalService.open(JobClaimsComponent,
        { windowClass: 'modal-claims', backdrop: 'static', keyboard: false, scrollable: true });

      modalRef.result.then(() => {
        this.router.navigate(['selections']);
      });
    } else {
      this.router.navigate(['selections']);
    }
  }

  checkAddItemsToCopy(node) {
    if (node.checked) {
      this.foundItemSet = true;
      this.itemsToSet = this.itemsToSet.concat({
        jobVarTypeId: node.originalItemTypeId,
        id: node.originalItemId
      });
    }

    if (node.children) {
      node.children.forEach((child) => this.checkAddItemsToCopy(child));
    }
  }
}
