<!-- <js-background-logo [showImg]="true"></js-background-logo> -->

<div class="card job-card">
  <div class="card-header" [ngClass]="{'header-sticky' : desktop}">
    <!-- enter job number -->
    <js-job-number *ngIf="!addingJob" (refreshJobNumber)='refreshJobNumber($event)'></js-job-number>

    <button *ngIf="(jobsWrite || jobsAdmin) && !loading" class="btn btn-sm btn-primary m-t-5" (click)="addJob()">
      Add New Job
    </button>

    <button *ngIf="jobsAdmin && !loading" class="btn btn-sm btn-secondary m-t-5 maintenanceAddButton"
      (click)="addMaintenanceJob()">
      Add Maintenance Job
    </button>
  </div>

  <div *ngIf="!addingJob" class="card-body m-b-0 p-t-0 p-b-0" [ngClass]="{'small-padding' : phoneSize}">

    <!-- Loading Spinner -->
    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <js-job-details *ngIf="jobNum && jobNum !== '' && !loading" [jobNum]='jobNum' [jobsWrite]=jobsWrite
      [jobsAdmin]=jobsAdmin (refreshLines)='onRefresh($event)'>
    </js-job-details>

  </div>
</div>
