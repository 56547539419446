<div class="modal-header">
  <h2 class="modal-title">Admin Change</h2>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div *ngIf="isSalesVO">This sales variation CANNOT normally be modified as a sales date has been entered.</div>
  <div *ngIf="!isSalesVO">This pre-contract variation CANNOT normally be modified as the job has been locked.</div>
  <div>However as an administrator you can override and continue.</div>
  <br>
  <div>
    NOTE: You must understand the possible implications of a change otherwise the information may be compromised. If
    this variation is opened for change the approved date is NOT changed. This is neccessary so that the data sequence
    is not compromised. You will possibly not be able to see items in other open variations that are changes to or
    additions following items in this variation until this variation is marked approved again.
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-primary" (click)="close()">Cancel</button>
  <button class="btn btn-sm btn-danger ms-2" (click)="closeYes()">Continue</button>
</div>
