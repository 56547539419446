import { GlobalService } from './../../services/global.service';
import { JobService } from './../../services/felixApi/job.service';
import { UserService } from './../../services/felixApi/user.service';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { ManualOrdersService } from '../../services/felixApi/manual-orders.service';
import { Component, EventEmitter, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular';
import { EmailAddress } from '../../dtos/email-address';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-email-lookup',
  templateUrl: './email-lookup.component.html',
  styleUrls: ['./email-lookup.component.css']
})
export class EmailLookupComponent implements OnInit, OnDestroy {

  @ViewChild('dataGridVendors') dataGridVendors: DxDataGridComponent;
  @ViewChild('dataGridUsers') dataGridUsers: DxDataGridComponent;
  @ViewChild('dataGridClient') dataGridClient: DxDataGridComponent;

  @Output() vendorEmail: EventEmitter<string> =
    new EventEmitter<string>();

  subscriptions: Subscription[] = [];
  dataSourceVendors: CustomStore;
  selectedVendorRowKeys: number[] = [];
  selectedUserRowKeys: number[] = [];
  selectedClientRowKeys: number[] = [];
  selectedIndex = 0;
  emailTypes = [
    { id: 0, description: 'Vendors' },
    { id: 1, description: 'Users' },
    { id: 2, description: 'Client' }
  ];
  dataSourceUsers: CustomStore;
  dataSourceClient: CustomStore;
  clientEmails: EmailAddress[] = [];
  popupHeight: number;
  popupWidth: number;
  emails: string;

  constructor(private manualOrderService: ManualOrdersService,
    private userService: UserService,
    private globalService: GlobalService,
    private jobService: JobService,
    private estimatingService: EstimatingService) { }

  ngOnInit() {
    // listen to width and height changes
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerWidthChanged.subscribe(() => {
        this.setWidth();
      })
    );
    this.setWidth();

    this.setUpDataSets();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setWidth() {
    this.popupHeight = window.innerHeight < 800 ? window.innerHeight : 800;
    this.popupWidth = window.innerWidth < 600 ? window.innerWidth : 600;

    // this.popupScroll = this.popupHeight - 50;
    // this.emailWidth = this.popupWidth - 42 - 36;
  }


  setUpDataSets() {
    this.dataSourceVendors = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.manualOrderService.getVendors().subscribe({
            next: (res) => {
              res = res.filter(i => i.isActive);
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });

    this.dataSourceUsers = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.userService.getAllCurrCompUsers(true).subscribe({
            next: (res) => {
              res = res.filter(i => i.isActive);
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });

    this.dataSourceClient = new CustomStore({
      key: 'id',
      load: () => this.getClientEmails()
    });
  }

  getClientEmails() {
    this.clientEmails = [];
    this.clientEmails.push({ id: 1, email: this.jobService.currentJob.clientEmail.replace(' ', ';').replace('\n', ';').replace(';;', ';') });
    return this.clientEmails;
  }

  cancel() {
    this.vendorEmail.emit(null);
  }

  selectVendor() {
    this.emails = '';
    this.dataGridVendors.instance.getSelectedRowsData().forEach(rowData => {
      this.emails += this.emails.length ? (';' + rowData.email) : rowData.email;
    });
    this.vendorEmail.emit(this.emails);
  }

  selectUser() {
    this.emails = '';
    this.dataGridUsers.instance.getSelectedRowsData().forEach(rowData => {
      this.emails += this.emails.length ? (';' + rowData.email) : rowData.email;
    });
    this.vendorEmail.emit(this.emails);
  }

  selectClient() {
    this.emails = '';
    this.dataGridClient.instance.getSelectedRowsData().forEach(rowData => {
      this.emails += this.emails.length ? (';' + rowData.email) : rowData.email;
    });
    this.vendorEmail.emit(this.emails);
  }

  onMultiRowClick(e) {
    if (e.groupIndex === undefined) {
      let keys = e.component.getSelectedRowKeys();
      const index = keys.indexOf(e.key);

      if (index > -1) {
        keys.splice(index, 1);
      } else {
        keys = keys.concat(e.key);
      }

      e.component.selectRows(keys);
    }
  }
}
