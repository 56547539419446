import { CompanyService } from './company.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { User } from '../../dtos/user';
import { GlobalService } from '../global.service';
import { CompanyRoleUser } from '../../dtos/companyRoleUser';
import { Division } from '../../dtos/division';
import { HttpService } from '../http.service';
import { UserTypeEnum } from '../../dtos/user-type.enum';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  // companyUsers: User[] = []; // hold the list if retrieved
  users: User[];
  officeUsers: User[];
  cachCompanyUsers: string;
  companyUsersCompany: string;
  divisions: Division[];
  divisionsCompany: string;
  isActiveCampaignActive: boolean;
  isActiveCampaignCompany: string;

  constructor(
    private http: HttpClient,
    private _authService: AuthService,
    private companyService: CompanyService,
    private httpService: HttpService,
    private globalService: GlobalService) { }


  getDivisions(useCache: boolean): Observable<Division[]> {
    if (useCache && this.divisionsCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.divisions);
    } else {
      const url = this.globalService.getApiUrl() + '/divisions/';
      return this.http.get<Division[]>(url, this.httpService.getHttpOptions())
        .pipe(
          tap(res => {
            this.divisions = res;
            this.divisionsCompany = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError)
        );
    }
  }

  getUsersAndDivisions(useCache: boolean): Observable<User[]> {
    return forkJoin(
      [
        this.getAllCurrCompUsers(useCache),
        this.getDivisions(useCache),
        this.companyService.getCompanyActivities(true, useCache)
      ]
    )
      .pipe(map(
        ([jobs]) => {
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getAllCurrCompUsers(useCache: boolean): Observable<User[]> {
    if (this._authService.isClient() || this._authService.isAssociate()) {
      return of([]);
    }
    if (useCache && this.users && this.users.length
      && this.cachCompanyUsers === this.globalService.getCurrentCompanyId()) {
      return of(this.users);
    } else {
      const url = this.globalService.getApiUrl() + '/companies/users';

      return this.http.get<User[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.users = res; this.cachCompanyUsers = this.globalService.getCurrentCompanyId();
          this.getOfficeUsers();
        }),
        catchError(this.handleError));
    }
  }

  getUser(id: number): Observable<User> {
    return this.http.get<User>(this.globalService.getApiUrl() + '/users/' + id + '?includeUserType=true',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getUserByEmail(email: string) {
    return this.http.get<User>(this.globalService.getApiUrl() + '/users/' + email, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getCompanyRoleUsers(roleId: number): Observable<CompanyRoleUser[]> {
    const url = this.globalService.getApiUrl() + '/company-roles/' + roleId + '/users';
    return this.http.get<CompanyRoleUser[]>(url, this.httpService.getHttpOptions());
  }

  getCompanyRoleUsersByRoleID(roleTypeId: number): Observable<CompanyRoleUser[]> {
    const url = this.globalService.getApiUrl() + '/company-roles/' + roleTypeId + '/role-users';
    return this.http.get<CompanyRoleUser[]>(url, this.httpService.getHttpOptions());
  }


  getOfficeUsers() {
    this.officeUsers = [];
    this.users.forEach(user => {
      if ((user.isActive) && (user.userTypeId === UserTypeEnum.Admin || user.userTypeId === UserTypeEnum.Office)) {
        this.officeUsers.push(user);
      }
    });
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
