<div class="modal-header">
  <h4 class="modal-title">Upload Attachments</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <!-- Add attachments -->
  <div *ngIf="!loadingDocs && fileValue.length" class="fileNamesClass">{{getFileNames()}}</div>

  <dx-button *ngIf="!loadingDocs && fileValue.length" text="Save" class="mt-1" type="default"
    (onClick)="save()">
  </dx-button>

  <dx-button *ngIf="!loadingDocs && fileValue.length" text="Re-Select" class="mt-1 ms-4"
    type="default" stylingMode="outlined" (onClick)="fileValue = []">
  </dx-button>


  <dx-file-uploader *ngIf="!loadingDocs && !fileValue.length" #fileUploader
    selectButtonText="Select file" labelText="or drop file here" [multiple]="true" [(value)]="fileValue"
    uploadMode="useForm">
  </dx-file-uploader>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingDocs" [showIndicator]="true"
  [showPane]="true" [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
