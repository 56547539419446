import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CloseDropdownService {

  closeDropdown = new EventEmitter<number>();

  constructor() { }

  setCloseDropdown(itemId: number) {
    this.closeDropdown.emit(itemId);
  }
}
