import { Component, OnInit, Input } from '@angular/core';
import { IOptionListHeader } from '../../../dtos/option-list-header';
import { IJobItem } from '../../../dtos/job-item';
import { CostTypeEnum } from '../../../dtos/cost-type.enum';
import { JobItemService } from '../../../services/felixApi/job-item.service';
import { ChangeTypeEnum } from '../../../dtos/change-type.enum';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'js-image-select-price',
  templateUrl: './image-select-price.component.html',
  styleUrls: ['./image-select-price.component.scss']
})
export class ImageSelectPriceComponent implements OnInit {
  @Input() jobitem: IJobItem;
  @Input() option: IOptionListHeader;
  @Input() isQtyRequired: boolean;
  @Input() previousSelectedOption: IOptionListHeader;
  @Input() prevQty: number;

  currentSelectedOption: IOptionListHeader;
  costTypeEnum = CostTypeEnum;
  previousCostTypeId: number;
  changeTypeEnum = ChangeTypeEnum;
  previousItemRate: number;
  costTypeId: number;
  newItemRate: number;
  prevAndCurrentValid: boolean;
  priceChange: number;
  sameItem = false;
  prevItem = false;
  prevEqualsSelected: boolean;

  constructor(private _jobItemService: JobItemService,
    private utilsService: UtilsService) { }

  ngOnInit() {
    if (this.jobitem.previousSelectedOptionId === this.option.id) {
      this.prevItem = true;
      if (this.jobitem.selectedOptionId === this.option.id) {
        this.prevEqualsSelected = true;
      }
    } else if (this.jobitem.selectedOptionId === this.option.id) {
      this.sameItem = true;
    } else {
      this.setPricesFromChangedOption(this.option);
    }
  }


  setPricesFromChangedOption(selectedOption: IOptionListHeader) {
    this.currentSelectedOption = selectedOption;
    this.setPreviousAndCurrent();

    // if previous or new invalid then price TBA
    if (this.isQtyRequired) {
      this.priceChange = this.utilsService.roundEven(this.newItemRate * this.jobitem.quantity)
        - this.utilsService.roundEven(this.previousItemRate * this.prevQty);
    } else {
      this.priceChange = this.utilsService.roundEven(this.newItemRate - this.previousItemRate);
    }

    if (this.priceChange !== 0
      && (this.costTypeId === this.costTypeEnum.Note || this.costTypeId === this.costTypeEnum.NoCharge)) {
      this.costTypeId = this.costTypeEnum.Priced;
    }

    if (this.priceChange === 0 && this.costTypeId === this.costTypeEnum.Priced && this.prevAndCurrentValid) {
      if (!this.isQtyRequired || (this.jobitem.quantity && this.jobitem.quantity !== 0)) {
        this.costTypeId = this.costTypeEnum.NoCharge;
      }
    }
  }

  setPreviousAndCurrent() {
    if (this.jobitem.changedByVOId === this._jobItemService.currentVariation.id && this.jobitem.changeTypeId === this.changeTypeEnum.Add) {
      // added item so previous is ignored
      this.previousCostTypeId = this.costTypeEnum.Note; // set to Note as previous is valid
      this.previousItemRate = 0;

      if (this.currentSelectedOption && this.currentSelectedOption.salesPriceTypeIfAddedId) {
        this.costTypeId = this.currentSelectedOption.salesPriceTypeIfAddedId;
      } else {
        this.costTypeId = this.costTypeEnum.Priced;
      }

      if (this.currentSelectedOption && this.currentSelectedOption.salesPriceIfAdded) {
        this.newItemRate = this.currentSelectedOption.salesPriceIfAdded;
      } else {
        this.newItemRate = 0;
      }

      if (this.currentSelectedOption
        && ((this.currentSelectedOption.salesPriceTypeIfAddedId
          && this.currentSelectedOption.salesPriceTypeIfAddedId !== this.costTypeEnum.Priced)
          || (this.currentSelectedOption.salesPriceIfAdded && this.currentSelectedOption.salesPriceIfAdded !== 0))) {
        this.prevAndCurrentValid = true;
      } else {
        this.prevAndCurrentValid = false;
      }
    } else {
      // if the previous is 'Please Select' then we accept price is zero
      if (((this.jobitem.changedByVOId === this._jobItemService.currentVariation.id && this.jobitem.previousSelectedOptionId)
        || (this.jobitem.changedByVOId !== this._jobItemService.currentVariation.id && this.jobitem.selectedOptionId))
        && (!this.previousSelectedOption
          || (!this.previousSelectedOption.salesPriceTypeIfChangedInSameListId
            || this.previousSelectedOption.salesPriceTypeIfChangedInSameListId === this.costTypeEnum.Priced)
          && (!this.previousSelectedOption.salesPriceIfChangedInSameList
            || this.previousSelectedOption.salesPriceIfChangedInSameList === 0))) {
        // we don't have a previous price set up so the new is ignored
        this.previousCostTypeId = this.costTypeEnum.Priced;
        this.previousItemRate = 0;
        this.costTypeId = this.costTypeEnum.Priced;
        this.newItemRate = 0;
        this.prevAndCurrentValid = false;
      } else {
        // do we have a new price or type to use?
        if (!this.currentSelectedOption
          || (!this.currentSelectedOption.salesPriceTypeIfChangedInSameListId
            || this.currentSelectedOption.salesPriceTypeIfChangedInSameListId === this.costTypeEnum.Priced)
          && (!this.currentSelectedOption.salesPriceIfChangedInSameList
            || this.currentSelectedOption.salesPriceIfChangedInSameList === 0)) {
          // we don't have a new price set up so the new is ignored
          this.previousCostTypeId = this.costTypeEnum.Priced;
          this.previousItemRate = 0;
          this.costTypeId = this.costTypeEnum.Priced;
          this.newItemRate = 0;
          this.prevAndCurrentValid = false;
        } else {

          // if previous not selected then treat as a note
          if ((this.jobitem.changedByVOId === this._jobItemService.currentVariation.id && !this.jobitem.previousSelectedOptionId)
            || (this.jobitem.changedByVOId !== this._jobItemService.currentVariation.id && !this.jobitem.selectedOptionId)) {
            this.previousCostTypeId = this.costTypeEnum.Note;
          } else {
            if (this.previousSelectedOption && this.previousSelectedOption.salesPriceTypeIfChangedInSameListId) {
              this.previousCostTypeId = this.previousSelectedOption.salesPriceTypeIfChangedInSameListId;
            } else {
              this.previousCostTypeId = this.costTypeEnum.Priced;
            }
          }

          if (this.previousSelectedOption && this.previousSelectedOption.salesPriceIfChangedInSameList) {
            this.previousItemRate = this.previousSelectedOption.salesPriceIfChangedInSameList;
          } else {
            this.previousItemRate = 0;
          }

          if (this.currentSelectedOption && this.currentSelectedOption.salesPriceTypeIfChangedInSameListId) {
            this.costTypeId = this.currentSelectedOption.salesPriceTypeIfChangedInSameListId;
          } else {
            this.costTypeId = this.costTypeEnum.Priced;
          }

          if (this.currentSelectedOption && this.currentSelectedOption.salesPriceIfChangedInSameList) {
            this.newItemRate = this.currentSelectedOption.salesPriceIfChangedInSameList;
          } else {
            this.newItemRate = 0;
          }

          this.prevAndCurrentValid = true;
        }
      }
    }
  }
}
