<div class="modal-header">
  <h2 class="modal-title">Find Item</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <js-job-number-small [jobNumber]="" [saveJobNumber]="true"
    (refreshJobNumber)="refreshJobNumber($event)"></js-job-number-small>

  <div>
    <b>Select Option:</b>
  </div>

  <div *ngIf="treeOptionNodes && treeOptionNodes.length">
    <input id="filter" #filter (keyup)="tree.treeModel.filterNodes(filter.value)" placeholder="filter nodes" />
    <button
      (click)="$event.preventDefault(); $event.stopPropagation(); tree.treeModel.clearFilter(); tree.treeModel.collapseAll(); filter.value=''">
      Clear Filter
    </button>

    <label class="control-label-sm ms-4">Active Jobs Only?&nbsp;
      <mat-checkbox [(ngModel)]="activeJobsOnly"></mat-checkbox>
    </label>

    <div class="treeclass">
      <tree-root #tree [focused]="true" [nodes]="treeOptionNodes" [options]="treeOptionOptions"
        (focus)="setOptionList(tree.treeModel.getFocusedNode())" (initialized)="onOptionTreeInit(tree)">
      </tree-root>
    </div>

    <br>
    <label *ngIf="showIncludeChildrenCheckBox" class="control-label-sm">Include Child Options?&nbsp;
      <mat-checkbox [(ngModel)]="includeChildOptions"></mat-checkbox>
    </label>

    <label *ngIf="showIncludeChildrenCheckBox && includeChildOptions" class="control-label-sm ms-2">
      Include Child Options Only?&nbsp;
      <mat-checkbox [(ngModel)]="includeChildOptionsOnly"></mat-checkbox>
    </label>

    <br>
    <label class="control-label-sm">
      Include items subsequently changed?&nbsp;
      <mat-checkbox [(ngModel)]="includeChangedItems"></mat-checkbox>
    </label>
  </div>

  <!-- get search string -->
  <label class="form-label col-sm-3" for="searchText"></label>
  <input id="jobNumberId" class="form-control form-control-sm col-sm-7" type="text"
    placeholder="Or enter search text here" name="searchText" [(ngModel)]="searchText" (keyup)="changeSearchText()" />

  <br>
  <button type="button" class="btn btn-sm btn-primary" (click)="runSearch()" [disabled]="loading">
    Run Search
  </button>

</div>

<dx-popup class="popup" [width]="1200" [height]="popupHeight" [showTitle]="true" [title]="searchText"
  [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="popupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-data-grid *ngIf="!loading && dataSource" class="mt-3" #treeList id="tree-list" [dataSource]="dataSource"
      [height]="popupHeight- 140" [remoteOperations]="false" [showRowLines]="false" [showColumnLines]="true"
      [showBorders]="true" [columnAutoWidth]="false" [wordWrapEnabled]="true" [allowColumnResizing]="true">

      <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-paging [enabled]="false"> </dxo-paging>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
      </dxo-grouping>

      <dxi-column dataField="jobNo" caption="Job" dataType="string" [width]="130" sortOrder="asc"> </dxi-column>

      <dxi-column dataField="jobString" dataType="string"> </dxi-column>

      <dxi-column dataField="itemDescription" dataType="string"> </dxi-column>

      <dxi-column dataField="selection" dataType="string"> </dxi-column>

      <dxi-column dataField="displayedVariationNumber" caption="Variation" dataType="string" [width]="100"
        alignment="center">
      </dxi-column>

      <dxi-column dataField="currentActivity" caption="Activity" dataType="string" [width]="140"
        [calculateCellValue]="calculateCurrentActivity">
      </dxi-column>

      <dxi-column *ngIf="siteSupervisorName" dataField="siteSupervisorName" [caption]="siteSupervisorName"
        dataType="string" [width]="140" [calculateCellValue]="calculateSiteSupervisor" [visible]="false">
      </dxi-column>

      <dxi-column *ngIf="liaisonName" dataField="liaisonName" [caption]="liaisonName" dataType="string" [width]="140"
        [calculateCellValue]="calculateLiaison" [visible]="false">
      </dxi-column>

      <dxi-column *ngIf="prestartOfficer" dataField="prestartOfficer" [caption]="prestartOfficer" dataType="string"
        [width]="140" [calculateCellValue]="calculatePrestartOfficer" [visible]="false">
      </dxi-column>

      <dxi-column *ngIf="constructionCoordinator" dataField="constructionCoordinator"
        [caption]="constructionCoordinator" dataType="string" [width]="140"
        [calculateCellValue]="calculateConstructionCoordinator" [visible]="false">
      </dxi-column>

      <dxi-column *ngIf="preConstructionCoordinator" dataField="preConstructionCoordinator"
        [caption]="preConstructionCoordinator" dataType="string" [width]="140"
        [calculateCellValue]="calculatePreConstructionCoordinator" [visible]="false">
      </dxi-column>
    </dx-data-grid>

    <div class="mt-1">** Note: Add roles to the grid via the column chooser</div>
  </div>
</dx-popup>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel [(visible)]="loading" [showIndicator]="true" [showPane]="true" [shading]="false"
  [hideOnOutsideClick]="false">
</dx-load-panel>