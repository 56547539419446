import { GlobalService } from './../../services/global.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobItemService } from '../../services/felixApi/job-item.service';

@Component({
  selector: 'js-show-full-addenda',
  templateUrl: './show-full-addenda.component.html',
  styleUrls: ['./show-full-addenda.component.scss']
})
export class ShowFullAddendaComponent implements OnInit {
  addendumName: string;

  constructor(private _activeModal: NgbActiveModal,
    private _globalService: GlobalService,
    private _jobItemService: JobItemService) { }

  ngOnInit() {
    this.addendumName = this._globalService.getAddendumName();
  }

  showFullAddenda(ans) {
    this._jobItemService.showOnlyAddsChanges = ans;
    this._activeModal.close(ans);
  }
}
