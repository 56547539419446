<div class="modal-header">
  <h4 class="modal-title">Versions</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" [dataSource]="dataSource" [height]="gridHeight" [remoteOperations]="false"
    [showRowLines]="false" [showColumnLines]="true" [showBorders]="true" [columnAutoWidth]="true"
    [wordWrapEnabled]="true">

    <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-paging [enabled]="false"> </dxo-paging>

    <dxo-editing mode="row" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false" [useIcons]="true">
    </dxo-editing>

    <dxi-column dataField="attachmentName"> </dxi-column>

    <dxi-column dataField="modifiedDate" sortOrder="desc" dataType="datetime" caption="Date" [width]="160"
      alignment="center"> </dxi-column>

    <dxi-column dataField="createdByUserId" caption="Created By">
      <dxo-lookup [dataSource]="users" valueExpr="id" displayExpr="fullName"> </dxo-lookup>
    </dxi-column>

    <dxi-column type="buttons" [width]="50">
      <dxi-button icon="download" [onClick]="openVersion"> </dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>
