<div class="modal-header">
  <h2 class="modal-title">Create Task</h2>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div>
    Create a task to track this variation?
  </div>
</div>

<div class="modal-footer">
  <div *ngIf="!answered">
    <button class="btn btn-sm btn-secondary" (click)="close()">No</button>
    <button class="btn btn-sm btn-primary ms-2" (click)="closeYes()">Yes</button>
  </div>

  <div *ngIf="answered">
    <button class="btn btn-sm btn-danger" (click)="close()">Confirm No</button>
    <button class="btn btn-sm btn-primary ms-2" (click)="closeYes()">Yes Create Task</button>
  </div>
</div>
