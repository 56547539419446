import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { SelectionJobComponent } from './selections/selection-job.component';
import { JobComponent } from './jobs/job.component';
import { VariationlistComponent } from './variations/variationlist.component';
import { VariationDetailComponent } from './variations/variation-detail.component';
// import { JobSearchComponent } from './jobs/job-search.component';
import { JobDocumentsComponent } from './job-documents/job-documents.component';

// import { AuthGuard } from './services/auth-guard.service';
import { ReportsComponent } from './reports/reports.component';
import { MsalGuard } from '@azure/msal-angular';
import { RefreshGuard } from './services/refresh-guard.service';
import { AcceptIntoContractComponent } from './accept-into-contract/accept-into-contract.component';
import { InvoiceListComponent } from './invoices/invoice-list.component';
import { InvoiceComponent } from './invoices/invoice.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SalesTasksComponent } from './sales-tasks/sales-tasks.component';
import { BrowserUtils } from '@azure/msal-browser';
import { CallUpsComponent } from './call-ups/call-ups.component';
import { JobDocumentsClientComponent } from './job-documents-client/job-documents-client.component';
import { JobProgressComponent } from './dashboard/job-progress/job-progress.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login/:comp', component: LoginComponent },
  { path: 'welcome', component: WelcomeComponent, canActivate: [RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'welcome/:comp', component: WelcomeComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'selections', component: SelectionJobComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  // { path: 'selections/:id', component: SelectionJobComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'documents', component: JobDocumentsComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'documents-client', component: JobDocumentsClientComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'variations', component: VariationlistComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'variationdetail', component: VariationDetailComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'jobs', component: JobComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  // { path: 'jobsearch/:id', component: JobSearchComponent, canActivate: [MsalGuard, RefreshGuard] },
  { path: 'reports', component: ReportsComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'acceptIntoContract', component: AcceptIntoContractComponent, canActivate: [MsalGuard, RefreshGuard] },
  { path: 'invoicelist', component: InvoiceListComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'invoice', component: InvoiceComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'salestasks', component: SalesTasksComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'job-progress', component: JobProgressComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'login', redirectTo: '', pathMatch: 'full' },
  { path: 'callups', component: CallUpsComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: '**', redirectTo: '', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy',
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
