import { NotificationService } from './notification.service';
import { Injectable } from '@angular/core';
import { AttachmentTypeEnum } from '../dtos/attachment-type.enum';
import { from, Observable, of } from 'rxjs';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';

export const DEFAULT_FILENAME = 'image.png';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private imageCompress: NgxImageCompressService,
    private notiService: NotificationService
  ) { }

  getFileType(imgName: string): number {
    const nameExtn = imgName.toUpperCase().split('.').pop();

    return AttachmentTypeEnum[nameExtn];
  }

  typeIsImage(type: AttachmentTypeEnum): boolean {
    return type === AttachmentTypeEnum.JPEG || type === AttachmentTypeEnum.PNG;
  }

  typeIsImageOrPDF(type: AttachmentTypeEnum): boolean {
    return type === AttachmentTypeEnum.JPEG || type === AttachmentTypeEnum.PNG || type === AttachmentTypeEnum.PDF;
  }

  getAttachmentTypeFromKey(key: number): string {
    if (key === AttachmentTypeEnum.JPEG) {
      return '.jpg';
    } else if (key === AttachmentTypeEnum.PDF) {
      return '.pdf';
    } else if (key === AttachmentTypeEnum.PNG) {
      return '.png';
    } else if (key === AttachmentTypeEnum.DOCX) {
      return '.docx';
    } else if (key === AttachmentTypeEnum.XLS) {
      return '.xls';
    } else if (key === AttachmentTypeEnum.XLSM) {
      return '.xlsm';
    } else if (key === AttachmentTypeEnum.XLSX) {
      return '.xlsx';
    } else if (key === AttachmentTypeEnum.DXF) {
      return '.dxf';
    } else {
      return '';
    }
  }

  compressImage(fileToUpload: string, fileName: string, compressImageToStore: boolean): Observable<File> {
    const beforeSize = this.imageCompress.byteCount(fileToUpload);
    console.log('Size in bytes of the uploaded image was:', beforeSize);

    if (compressImageToStore && beforeSize > 50000) {
      const resultImage = this.imageCompress
        // .compressFile(fileToUpload, DOC_ORIENTATION.NotDefined, beforeSize > 200000 ? 50 : beforeSize > 100000 ? 75 : 100, beforeSize > 200000 ? 50 : beforeSize > 100000 ? 75 : 100, 450, 300)
        .compressFile(fileToUpload, DOC_ORIENTATION.NotDefined)
        .then(
          (compressedImage) => {
            const aftersize = this.imageCompress.byteCount(compressedImage);
            console.log('Size in bytes after compression is now:', aftersize);
            console.log('Use compressed file:', aftersize < (beforeSize * 0.9));
            const blobData = this.dataURItoBlob(aftersize < (beforeSize * 0.9) ? compressedImage : fileToUpload);
            return this.blobToFile(blobData, fileName);
          }
        );
      return from(resultImage);
    } else {
      const blobData = this.dataURItoBlob(fileToUpload);
      return of(this.blobToFile(blobData, fileName));
    }
  }

  blobToFormData(blobData, fileName = DEFAULT_FILENAME) {
    const fd = new FormData();
    fd.append(blobData.name ? blobData.name : fileName, blobData, blobData.name ? blobData.name : fileName);
    return fd;
  }

  /* npm install exif-orientation */
  // getOrientation(img: File): Observable<number> {
  //   return new Observable(observer => {
  //     findOrientation(img, (err, orientation) => {
  //       if (!err && orientation.rotation === 90) {
  //         observer.next(2);
  //       } else {
  //         observer.next(1); // don't rotate if couldnt ind orientation
  //       }
  //       observer.complete();
  //     });
  //   });
  // }

  fileToBase64(file: File): Observable<string> {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Observable(observer => {
      reader.onload = () => {
        observer.next(reader.result.toString());
        observer.complete();
      };
    });
  }

  fileToFormData(file) {
    const fd = new FormData();
    fd.append(file.name ? file.name : 'TruthEngine', file, file.name ? file.name : 'TruthEngine.png');
    return fd;
  }

  blobToFile(theBlob: Blob, name?: string): File {
    const b: any = theBlob;
    b.lastModifiedDate = new Date();
    if (name && name !== '') {
      b.name = name;
    }

    return <File>theBlob;
  }

  dataURItoBlob(dataURI: string) {
    let byteString: any;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  checkFileSize(form: FormData): boolean {
    let size = 0;
    form.forEach(element => {
      if (element instanceof Blob) {
        size += element.size;
      } else {
        size += element.length;
      }
    });

    const sizeInMb = size / 1024 / 1000;

    if (sizeInMb > 20) {
      this.notiService.showWarning('NOTE: file is greater than 20MB (' + Math.round(sizeInMb * 10) / 10 + 'MB) and cannot be loaded.');
      return false;
    } else if (sizeInMb > 10 && sizeInMb < 20) {
      this.notiService.showWarning('NOTE: file is large (' + Math.round(sizeInMb * 10) / 10 + 'MB). Email max is 30MB total size.');
    }

    return true;
  }

  // stringToBlob(byteString: any): Blob {
  //     const arrayBuffer = new ArrayBuffer(byteString.length);
  //     const int8Array = new Uint8Array(arrayBuffer);
  //     for (let i = 0; i < byteString.length; i++) {
  //         int8Array[i] = byteString.charCodeAt(i);
  //     }
  //     const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
  //     return blob;
  // }
}
