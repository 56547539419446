import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../../services/notification.service';
import { EstimatingService } from '../../../services/felixApi/estimating.service';
import { Subscription } from 'rxjs';
import { IJobVarCostAttachment } from '../../../dtos/job-var-cost-attachment';
import { ShowPdfComponent } from '../../../shared/show-pdf.component';
import { PDFReports } from '../../../dtos/pdf-report';
import { AttachmentTypeEnum } from '../../../dtos/attachment-type.enum';
import { ImageWindowComponent } from '../../../shared/image-window.component';

@Component({
  selector: 'js-estimating-attachments-modal',
  templateUrl: './estimating-attachments-modal.component.html',
  styleUrls: ['./estimating-attachments-modal.component.scss']
})
export class EstimatingAttachmentsModalComponent implements OnInit, OnDestroy {
  @Input() jobVarCostId: number;

  loading = true;
  subscriptions: Subscription[] = [];
  attachments: IJobVarCostAttachment[];
  popupVisible = false;
  value: any[] = [];
  deletePopupVisible = false;
  attachmentToDelete: string;
  attachmentTypeEnum = AttachmentTypeEnum;

  constructor(private _activeModal: NgbActiveModal,
    private notiService: NotificationService,
    private modalService: NgbModal,
    private estimatingService: EstimatingService) { }

  ngOnInit() {
    this.getJobCostAttachments();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getJobCostAttachments() {
    // read all the attachments
    this.subscriptions = this.subscriptions.concat(
      this.estimatingService.getJobVarCostAttachments(this.jobVarCostId)
        .subscribe({
          next: (attachments) => {
            this.attachments = attachments;

            // update the hasBlob flag
            const jobVarCost = this.estimatingService.jobVarCosts.find(i => i.id === this.jobVarCostId);
            if (jobVarCost) {
              jobVarCost.hasBlob = attachments && attachments.length > 0;
            }

            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  dismissModal() {
    this._activeModal.dismiss();
  }

  openAttachment(attachment: Blob, attachmentName: string, attachmentTypeId: number) {
    // open
    if (attachmentTypeId === this.attachmentTypeEnum.PDF) {
      const pdfReport: PDFReports = new PDFReports;
      pdfReport.pdfReport = attachment;
      pdfReport.pdfReportName = attachmentName;
      const modalRef1 = this.modalService.open(ShowPdfComponent, { windowClass: 'modal-pdf' });
      modalRef1.componentInstance.pdfReport = pdfReport;

      modalRef1.result.then((returnResult1) => {
      }, (reason) => {
      });
    } else {
      // image
      const modalRef2 = this.modalService.open(ImageWindowComponent, { windowClass: 'modal-pdf' });
      modalRef2.componentInstance.attachment = attachment;
      modalRef2.componentInstance.attachmentTypeId = attachmentTypeId;

      modalRef2.result.then((returnResult1) => {
      }, (reason) => {
      });
    }
  }

  deleteAttachment(attachment: string) {
    this.deletePopupVisible = true;
    this.attachmentToDelete = attachment;
  }

  deleteClick() {
    this.loading = true;
    this.deletePopupVisible = false;
    this.subscriptions = this.subscriptions.concat(
      this.estimatingService.deleteJobVarCostAttachment(this.jobVarCostId, this.attachmentToDelete).subscribe({
        next: () => {
          this.getJobCostAttachments();
        }, error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  uploadFile() {
    this.popupVisible = true;
  }

  uploadClick() {
    // load the file
    if (this.value && this.value.length) {
      this.loading = true;
      this.popupVisible = false;
      const formData: FormData = new FormData();
      formData.append('image', this.value[0], this.value[0].name);

      this.subscriptions = this.subscriptions.concat(
        this.estimatingService.addJobVarCostAttachment(this.jobVarCostId, formData).subscribe({
          next: () => {
            this.getJobCostAttachments();
            this.value = [];
          }, error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
      );
    }
  }
}
