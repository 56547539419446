<div class="modal-header">
  <h4 class="modal-title" placement="bottom">Estimating Items</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <!-- enter job number -->
  <div class="jobClass">
    <js-job-number [fromJobMargin]="true" [calcWidth]="true" (refreshJobNumber)='refreshJobNumber($event)'>
    </js-job-number>
  </div>

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading && jobNumber" #allEstimateGrid id="allEstimateGrid" class="mt-3" #treeList
    id="tree-list" [dataSource]="dataSource" [height]="gridHeight" [remoteOperations]="false" [showRowLines]="false"
    [showColumnLines]="true" [showBorders]="true" [columnAutoWidth]="false" [wordWrapEnabled]="true"
    [allowColumnResizing]="true" (onToolbarPreparing)="onToolbarPreparing($event)"
    (onCellPrepared)="onCellPrepared($event)" [selectedRowKeys]="selectedRowKeys">

    <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
    <dxo-selection *ngIf="!linesExploded" mode="multiple" [allowSelectAll]="true"></dxo-selection>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-paging [enabled]="false"> </dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-sorting mode="multiple"></dxo-sorting>


    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
    </dxo-grouping>

    <dxi-column dataField="masterGroupCostCentre" dataType="string" caption="Cost Centre" [width]="200"
      groupCellTemplate="groupCellTemplate" cellTemplate="getCostCentreTitle" sortOrder="asc">
    </dxi-column>

    <dxi-column dataField="subGroupItemDesc" dataType="string" caption="Sub-Group" [width]="150"
      groupCellTemplate="subgroupCellTemplate" cellTemplate="getCostCentreTitle" sortOrder="asc">
    </dxi-column>

    <dxi-column dataField="jobVariationId" caption="Variation" [width]="80" dataType="number" alignment="center">
      <dxo-lookup [dataSource]="jobVariations" valueExpr="id" displayExpr="displayedVariationNumber">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="recipeCode" caption="Recipe / Item" dataType="string" [width]="130" sortOrder="asc">
    </dxi-column>

    <dxi-column dataField="description" dataType="string"> </dxi-column>

    <dxi-column dataField="unitOfMeasure" caption="Units" dataType="string" [width]="80"> </dxi-column>

    <dxi-column dataField="quantity" dataType="number" [width]="90" format="#,###.####"> </dxi-column>

    <dxi-column dataField="rate" dataType="number" [width]="100" format="#,###.00" [calculateCellValue]="getRate">
    </dxi-column>

    <dxi-column dataField="lineCost" caption="Line Cost" dataType="number" [width]="110" format="#,###">
    </dxi-column>


    <div *dxTemplate="let data of 'groupCellTemplate'">
      {{ getGroupTitle(data) }}
    </div>

    <div *dxTemplate="let data of 'getCostCentreTitle'">
      {{ getCostCentreTitle(data.value) }}
    </div>

    <div *dxTemplate="let data of 'subgroupCellTemplate'">
      {{ getSubGroupTitle(data) }}
    </div>

    <dxo-summary>
      <dxi-total-item column="lineCost" summaryType="sum" [valueFormat]="{ type: 'fixedPoint', precision: 0 }"
        displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>
</div>
