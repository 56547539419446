import { UserService } from './user.service';
import { JobService } from './job.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { Task } from '../../dtos/task';
import { catchError, map, tap } from 'rxjs/operators';
import { TaskMaster } from '../../dtos/task-master';
import { TaskType } from '../../dtos/task-type';
import { IJob } from '../../dtos/job';
import { TemplateTask } from '../../dtos/template-task';
import { HttpService } from '../http.service';
import { JobProgress } from '../../dtos/job-progress';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  taskMasters: any;
  taskMastersCompany: number;
  taskTypes: TaskType[];
  taskTypesCompany: number;
  salesTasks: Task[];
  salesTemplateTasks: TemplateTask[];
  salesTemplateTasksCompany: number;

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private jobService: JobService,
    private userService: UserService,
    private globalService: GlobalService) { }


  getSalesJobTasksData(): Observable<IJob[]> {
    return forkJoin(
      [
        this.jobService.getJobsByAddress(''),
        this.getTaskMasters(true),
        this.userService.getAllCurrCompUsers(true),
        this.getTaskTypes(true),
        this.getSalesJobTasks(),
        this.getSalesTemplateTasks()
      ]
    )
      .pipe(map(
        ([jobs]) => {
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getTaskMasters(useCache: boolean): Observable<TaskMaster[]> {
    if (useCache && this.taskMastersCompany === this.globalService.getCurrentCompany().id
      && this.taskMasters && this.taskMasters.length) {
      return of(this.taskMasters);
    } else {
      const url = this.globalService.getApiUrl() + '/task-masters/';

      return this._http.get<TaskMaster[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.taskMasters = res;
          this.taskMastersCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  getTaskTypes(useCache: boolean): Observable<TaskType[]> {
    if (useCache && this.taskTypesCompany === this.globalService.getCurrentCompany().id
      && this.taskTypes && this.taskTypes.length) {
      return of(this.taskTypes);
    } else {
      const url = this.globalService.getApiUrl() + '/task-types/';
      return this._http.get<TaskType[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.taskTypes = res;
          this.taskTypesCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  getSalesTemplateTasks(): Observable<TemplateTask[]> {
    if (this.salesTemplateTasksCompany === this.globalService.getCurrentCompany().id
      && this.salesTemplateTasks && this.salesTemplateTasks.length) {
      return of(this.salesTemplateTasks);
    } else {
      const url = this.globalService.getApiUrl() + '/template-tasks/sales';
      return this._http.get<TemplateTask[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.salesTemplateTasks = res;
          this.salesTemplateTasksCompany = this.globalService.getCurrentCompany().id;
        }),
        catchError(this.handleError));
    }
  }

  getSalesJobTasks(): Observable<Task[]> {
    const url = this.globalService.getApiUrl() + '/job-tasks/sales';

    return this._http.get<Task[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.salesTasks = res;
      }),
      catchError(this.handleError));
  }

  addSalesJobTask(jobId: number, templateTaskId: number, officeComment: string): Observable<Task[]> {
    const url = this.globalService.getApiUrl() + '/job-tasks/sales?jobId=' + jobId + '&templateTaskId=' + templateTaskId;
    return this._http.post<Task[]>(url, JSON.stringify({officeComment: officeComment}), this.httpService.getHttpOptions());
  }

  updateJobTask(id: string, itm: any): Observable<Task[]> {
    const dataToPass = JSON.stringify(itm);
    const url = this.globalService.getApiUrl() + '/job-tasks/sales/' + id;
    return this._http.patch<Task[]>(url, dataToPass, this.httpService.getHttpOptions());
  }

  deleteSalesJobTask(id: string) {
    const url = this.globalService.getApiUrl() + '/job-tasks/sales/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  getClientProgressTask(jobId: number): Observable<JobProgress[]> {
    const url = this.globalService.getApiUrl() + '/job-tasks/client-update?jobId='+jobId;

    return this._http.get<JobProgress[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
