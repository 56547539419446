import { Component, Input, OnChanges, OnDestroy, Output, EventEmitter } from '@angular/core';

import { ImageLibraryService } from '../services/felixApi/image-library.service';
import { OptionListAttachmentService } from '../services/felixApi/option-list-attachment.service';
import { IOptionListAttachment } from '../dtos/option-list-attachment';
import { JobItemAttachmentService } from '../services/felixApi/job-item-attachment.service';
import { JobVarItemAttachmentService } from '../services/felixApi/job-var-item-attachment.service';
import { ImageSourceEnum } from '../dtos/image-source.enum';
import { JobDocumentAttachmentService } from '../services/felixApi/job-document-attachment.service';
import { NotificationService } from '../services/notification.service';
import { Subscription } from 'rxjs';
import { AttachmentTypeEnum } from '../dtos/attachment-type.enum';
import { IJobVarCostAttachment } from '../dtos/job-var-cost-attachment';
import { JobService } from '../services/felixApi/job.service';

@Component({
  selector: 'js-show-image',
  templateUrl: './show-image.component.html',
  styleUrls: ['./show-image.component.scss']
})
export class ShowImageComponent implements OnChanges, OnDestroy {
  @Input() imageId: number;
  @Input() manualItem: boolean;
  @Input() imageLibrary: boolean;
  @Input() attachmentVariationId: number;
  @Input() imageSourceId: number;
  @Input() attachmentImageId: number; // not used to get attachment but required to trigger onChanges
  @Input() maxWidth: number; // if passed
  @Input() maxHeight: number; // if passed
  @Input() fullHeight = false;
  @Input() jobVarCostAttachment: IJobVarCostAttachment;
  @Input() showPointer = false;
  @Input() refreshImage: boolean; // not used to get attachment but required to trigger onChanges
  @Input() isSharePoint: boolean;
  @Input() sharePointId: string;
  @Input() attachmentTypeId: number;

  // event to emit a 'image click' event to the parent component
  @Output() emitImageClick: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output() emitDate: EventEmitter<Date> =
    new EventEmitter<Date>();

  COMPONENT_NAME = 'show-image';
  subscriptions: Subscription[] = [];

  optionAttachment: IOptionListAttachment;
  image: any;
  imageFound: boolean;
  loading: boolean;
  errorMessage: string;
  imageSourceEnum = ImageSourceEnum;
  imageTypeId: number;
  attachmentTypeEnum = AttachmentTypeEnum;

  constructor(private _optionListAttachmentService: OptionListAttachmentService,
    private _imageLibraryService: ImageLibraryService,
    private _jobService: JobService,
    private _jobItemAttachmentService: JobItemAttachmentService,
    private _jobVarItemAttachmentService: JobVarItemAttachmentService,
    private _jobDocAttachmentService: JobDocumentAttachmentService,
    private notiService: NotificationService) { }

  ngOnChanges(e) {
    if (this.jobVarCostAttachment) {
      this.image = this.jobVarCostAttachment.attachment;
      this.imageTypeId = this.jobVarCostAttachment.attachmentTypeId;
      this.imageFound = true;
      this.loading = false;
    } else {
      this.imageFound = false;
      this.loading = true;

      if (this.isSharePoint) {
        this.subscriptions = this.subscriptions.concat(
          this._jobDocAttachmentService.getSharePointDocument(this._jobService.currentJob?.jobNumber, this.sharePointId, true, true).subscribe({
            next: (res) => {
              this.getImage(res.attachment, res.attachmentTypeId);
            },
            error: (err) => {
              this.loading = false;
              this.notiService.notify(err);
            }
          })
        );
      } else if (this.imageSourceId === this.imageSourceEnum.JobDocumentAttachment) {
        this.subscriptions = this.subscriptions.concat(
          this._jobDocAttachmentService.getJobDocAttachment(this.imageId, true, true).subscribe({
            next: (jobDocAttachment) => {
              this.emitDate.emit(jobDocAttachment.fileModifiedDate);
              this.getImage(jobDocAttachment.attachment, jobDocAttachment.attachmentTypeId);
            },
            error: (err) => {
              this.imageFound = false;
              this.loading = false;
              this.notiService.notify(err);
            }
          })
        );
      } else if (this.manualItem) {
        if (this.attachmentVariationId === null || this.attachmentVariationId === undefined || this.attachmentVariationId === 0) {
          // a manual option with an image has been loaded
          this.subscriptions = this.subscriptions.concat(
            this._jobItemAttachmentService.getJobItemAttachmentById(this.imageId).subscribe({
              next: (jobItemAttachment) => {
                this.getImage(jobItemAttachment.attachment, jobItemAttachment.attachmentTypeId);
              },
              error: (err) => {
                this.imageFound = false;
                this.loading = false;
                this.notiService.notify(err);
              }
            })
          );
        } else {
          // a manual variation option with an image has been loaded
          this.subscriptions = this.subscriptions.concat(
            this._jobVarItemAttachmentService.getJobItemAttachmentById(this.imageId).subscribe({
              next: (jobItemAttachment) => {
                this.getImage(jobItemAttachment.attachment, jobItemAttachment.attachmentTypeId);
              },
              error: (err) => {
                this.imageFound = false;
                this.loading = false;
                this.notiService.notify(err);
              }
            })
          );
        }
      } else if (this.imageLibrary) {
        this.subscriptions = this.subscriptions.concat(
          this._imageLibraryService.getLibraryImageAttachment(this.imageId).subscribe({
            next: (optionAttachment) => {
              this.getImage(optionAttachment.attachment, optionAttachment.attachmentTypeId);
            },
            error: (err) => {
              this.imageFound = false;
              this.loading = false;
            }
          })
        );
      } else {
        this.subscriptions = this.subscriptions.concat(
          this._optionListAttachmentService.getOptionListAttachment(this.imageId).subscribe({
            next: (optionAttachment) => {
              this.getImage(optionAttachment.attachment, optionAttachment.attachmentTypeId);
            },
            error: () => {
              this.imageFound = false;
              this.loading = false;
            }
          })
        );
      }
    }
  }

  getImage(attachment, attachmentTypeId) {
    if (attachmentTypeId === this.attachmentTypeEnum.JPEG) {
      this.image = 'data:image/jpeg;base64,' + attachment;
    } else {
      this.image = 'data:image/png;base64,' + attachment;
    }
    this.imageTypeId = attachmentTypeId;
    this.imageFound = true;
    this.loading = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  emitImageClicked() {
    // image has been clicked so we emit this to the calling component
    this.emitImageClick.emit(true);
  }
}
