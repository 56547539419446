import { JobItemService } from './../../../services/felixApi/job-item.service';
import { NotificationService } from './../../../services/notification.service';
import { JobVarItemService } from './../../../services/felixApi/job-var-item.service';
import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { EstimatingService } from '../../../services/felixApi/estimating.service';
import { GlobalService } from '../../../services/global.service';
import CustomStore from 'devextreme/data/custom_store';
import { formatDate } from 'devextreme/localization';
import { EstimatingModalComponent } from '../estimating-modal/estimating-modal.component';
import { VariationService } from '../../../services/felixApi/variation.service';
import { VariationStatusEnum } from '../../../dtos/variation-status.enum';
import { DxTreeListComponent } from 'devextreme-angular';

@Component({
  selector: 'js-job-item-cost',
  templateUrl: './job-item-cost.component.html',
  styleUrls: ['./job-item-cost.component.scss']
})
export class JobItemCostComponent implements OnInit, OnDestroy {
  @Input() jobVariationId: number;
  @Input() variationNumber: number;
  @Input() displayedVariationNumber: string;
  @Input() adminFee: number;
  @Input() variationStatusId: number;

  @ViewChild(DxTreeListComponent) treeGrid: DxTreeListComponent;

  loading = false;
  subscriptions: Subscription[] = [];
  gridHeight: number;
  treeListDataSource: CustomStore;
  totalCost = 0;
  totalPrice = 0;
  totalManualCost = 0;
  totalMargin = 0;
  markupPercent = 0;
  newDate: Date;
  hasChanges = false;
  variationStatusEnum = VariationStatusEnum;
  editMode = 'row';

  costTypes = [
    // { name: 'Priced', id: 0 }, // we don't show this one
    { name: 'Note', id: 1 },
    { name: 'No Charge', id: 2 },
    { name: 'EOStandard', id: 3 },
    { name: 'PS', id: 4 },
    { name: 'PS Finalised', id: 5 },
    { name: 'By Owner', id: 6 },
    { name: 'Included', id: 7 },
    { name: 'Promotion', id: 8 },
    { name: 'Bonus Item', id: 9 },
    { name: 'Excluded', id: 10 }
  ];
  repricePopupVisible: boolean;
  updatingVariation: boolean;

  constructor(private _activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private estimatingService: EstimatingService,
    private jobVarItemService: JobVarItemService,
    private jobItemService: JobItemService,
    private variationService: VariationService,
    private modalService: NgbModal,
    private notiService: NotificationService) {
    this.estimatePrice = this.estimatePrice.bind(this);
    this.setUpDataSet = this.setUpDataSet.bind(this);
  }

  ngOnInit() {
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerWidthChanged.subscribe(() => {
        setTimeout(() => {
          this.setHeight();
        }, 500); // wait for iPhone
      })
    );
    this.setHeight();

    this.setUpDataSet();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  subscribeToWidthChanges() {
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerHeightChanged.subscribe(height => {
        this.setHeight();
      })
    );
  }

  setHeight() {
    this.gridHeight = this.globalService.innerHeight - 310;
  }

  setUpDataSet() {
    this.treeListDataSource = new CustomStore({
      key: 'key',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.estimatingService.getJobVarItemCosts(this.jobVariationId).subscribe({
            next: (res) => {
              this.totalPrice = this.adminFee ?? 0;
              this.totalCost = 0;
              this.totalManualCost = 0;
              this.markupPercent = 0;
              if (res) {
                res.forEach(item => {
                  if (!item.itemNotTaken && !item.isIgnoreCosts) {
                    this.totalPrice += (item.price ?? 0);
                    this.totalCost += (item.costTotal ?? 0);
                    this.totalManualCost += (item.manualCost ? item.manualCost : (item.costTotal ?? 0));
                  }
                });
                this.totalMargin = (this.totalPrice * 10 / 11) - this.totalManualCost;

                if (this.totalManualCost) {
                  this.markupPercent = (this.totalMargin / this.totalManualCost) * 100;
                }
              }
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        if (values.recipeCode) {
          values.recipeCode = values.recipeCode.toUpperCase();
        }
        return new Promise((resolve, reject) =>
          this.estimatingService.updateJobVarItemCost(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              this.hasChanges = true;
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: this.editMode === 'row' ? 'Batch Edit' : 'Row Edit',
          onClick: this.changeEditMode.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Re-Price',
          onClick: this.reprice.bind(this)
        }
      });
  }

  changeEditMode() {
    if (this.editMode === 'row') {
      this.editMode = 'batch';
    } else {
      if (this.treeGrid.instance.hasEditData()) {
        this.notiService.showInfo('Please Save or Cancel the edited data (or saving is in progress)');
      } else {
        this.editMode = 'row';
      }
    }
  }

  close() {
    if (this.treeGrid.instance.hasEditData()) {
      this.notiService.showInfo('Please Save or Cancel the edited data (or saving is in progress)');
    } else {
      if (this.hasChanges) {
        this._activeModal.close();
      } else {
        this._activeModal.dismiss();
      }
    }
  }

  isEditIconVisible = (e) => {
    return this.editMode === 'row' && e.row.data.itemTypeId !== 1 && !e.row.data.hasLinkedItems && !e.row.data.itemNotTaken; // && e.row.data.manualCost;
  }

  onCellPrepared(e) {
    // we want linked items closer
    if (e.rowType === 'data') {
      if (e.data.price && !e.data.manualCost && e.column.dataField === 'costTotal' && !e.data.isIgnoreCosts && !e.data.itemNotTaken) {
        e.cellElement.classList.add('redWhite');
      }

      if (e.data.itemNotTaken && e.column.dataField !== 'itemNotTaken') {
        e.cellElement.classList.add('strikeclass');
      }

      if (e.column.dataField === 'totalOfLinePrices' && e.data.totalOfLinePrices
        && Math.round(e.data.price ?? 0) < Math.round(e.data.totalOfLinePrices)
        && !e.data.isIgnoreCosts && !e.data.itemNotTaken) {
        e.cellElement.classList.add('red');
      }
    }
  }

  reprice() {
    this.repricePopupVisible = true;
  }

  runReprice() {
    if (this.newDate) {
      this.loading = true;
      this.subscriptions = this.subscriptions.concat(
        this.jobVarItemService.repriceVariation(this.jobVariationId, formatDate(this.newDate, 'yyyyMMdd'), null).subscribe({
          next: () => {
            this._activeModal.close();
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
      );
    } else {
      this.notiService.showInfo('Set Date');
    }
  }

  estimatePrice(e) {
    // get variation
    // this.jobItemService.currentVariation = e.data;
    // get the variation
    this.subscriptions = this.subscriptions.concat(
      this.variationService.getVariation(this.variationNumber).subscribe({
        next: (res) => {
          this.jobItemService.currentVariation = res[0];

          // open the estimating window for this item
          const modalRef = this.modalService.open(EstimatingModalComponent,
            { windowClass: 'modal-est3', backdrop: 'static', keyboard: false, scrollable: true });

          modalRef.componentInstance.jobVarItemId = e.row.data.jobVarItemId;
          modalRef.componentInstance.itemDescription = e.row.data.itemDescription;
          modalRef.componentInstance.selection = e.row.data.selection;
          modalRef.componentInstance.variationId = this.jobVariationId;

          modalRef.result.then(() => {
            this.hasChanges = true;
            this.setUpDataSet();
          }, () => {
            this.hasChanges = true;
            this.setUpDataSet();
          });
        },
        error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  approveVariation() {
    this.updatingVariation = true;
    let updaterecord = { id: this.jobVariationId, statusId: this.variationStatusEnum.Approved };
    this.subscriptions = this.subscriptions.concat(
      this.variationService.updateVariation(updaterecord, false).subscribe({
        next: () => {
          this._activeModal.close();
        },
        error: (err) => {
          this.updatingVariation = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  onEditorPreparing(e: any) {
    if (e.parentType !== 'dataRow') {
      return;
    } else {
      const editValue = e.row.data.itemTypeId !== 1 && !e.row.data.hasLinkedItems && !e.row.data.itemNotTaken;
      if (!editValue) {
        e.editorOptions.disabled = true;
      }
    }
  }
}
