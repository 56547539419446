import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { JobService } from '../../../services/felixApi/job.service';
import { NotificationService } from '../../../services/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { IJobItem } from '../../../dtos/job-item';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { VariationService } from '../../../services/felixApi/variation.service';

@Component({
  selector: 'js-hide-section',
  templateUrl: './hide-section.component.html',
  styleUrls: ['./hide-section.component.scss']
})
export class HideSectionComponent implements OnInit, OnDestroy {
  @Input() jobItem: IJobItem;

  subscriptions: Subscription[] = [];
  loading = true;
  variationTypesStore: DataSource;
  selectedVariationTypes: number[] = [];
  selectAllModeVlaue: 'page';
  selectionModeValue: 'all';

  constructor(private jobService: JobService,
    private variationService: VariationService,
    private _activeModal: NgbActiveModal,
    private notiService: NotificationService) { }

  ngOnInit() {
    this.variationTypesStore = new DataSource({
      store: new ArrayStore({
        key: 'id',
        data: this.variationService.variationTypes
      })
    });

    this.getHideSections();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getHideSections() {
    // read all JobCustomers records for this job
    this.selectedVariationTypes = [];

    this.subscriptions = this.subscriptions.concat(
      this.jobService.getHideSections(this.jobService.currentJob.id)
        .subscribe({
          next: (hideSections) => {
            // we want to reverse the selected type so we can show which ones we are showing
            this.variationService.variationTypes.forEach(variationType => {
              if (!hideSections.filter(i => i.jobItemId === this.jobItem.id && i.variationType === variationType.id)[0]) {
                this.selectedVariationTypes = this.selectedVariationTypes.concat(variationType.id);
              }
            });
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  update() {
    // add or delete as required
    this.loading = true;
    const selectedTypes = { variationTypes: this.selectedVariationTypes };
    this.subscriptions = this.subscriptions.concat(
      this.jobService.addDeleteHideSection(this.jobItem.id, selectedTypes)
        .subscribe({
          next: () => {
            this.cancel();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  cancel() {
    this._activeModal.close();
  }
}
