import { AddressService } from './../../../services/felixApi/address.service';
import { GlobalService } from './../../../services/global.service';
import { UserService } from './../../../services/felixApi/user.service';
import { JobForm } from './../../../dtos/job';
import { NotificationService } from './../../../services/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobService } from './../../../services/felixApi/job.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Division } from '../../../dtos/division';

@Component({
  selector: 'js-add-maintenance-job',
  templateUrl: './add-maintenance-job.component.html',
  styleUrls: ['./add-maintenance-job.component.css']
})
export class AddMaintenanceJobComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  loading = false;
  jobForm: JobForm = new JobForm();
  divisions: Division[];

  constructor(
    private jobService: JobService,
    private globalService: GlobalService,
    private activeModal: NgbActiveModal,
    private notiService: NotificationService,
    private userService: UserService,
    private addressService: AddressService
  ) { }

  ngOnInit() {
    this.divisions = this.userService.divisions;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  close() {
    this.activeModal.dismiss();
  }

  submit() {
    this.loading = true;
    this.globalService.setCurrentJob('');

    this.subscriptions = this.subscriptions.concat(
      this.jobService.addMaintenanceJob(this.jobForm).subscribe({
        next: (res) => {
          this.globalService.setCurrentJob(res.jobNumber);
          this.addJobAddress();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  addJobAddress() {
    this.subscriptions = this.subscriptions.concat(
      this.addressService.addJobAddress(this.jobForm).subscribe({
        next: () => {
          this.globalService.setCurrentJob(this.jobForm.jobNumber);
          this.activeModal.close();
        },
        error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

}
