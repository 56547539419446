<!-- modal for creating an option -->
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h2 class="modal-title">Create Option</h2>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form (ngSubmit)="c('Close click')" #changeNameForm="ngForm" novalidate>
      <div class="row">
        Description: {{jobitem.itemDescription}}
      </div>

      <div class="">
        <button type="submit" class="btn btn-sm btn-primary">Create Option</button>
      </div>
    </form>
  </div>
</ng-template>

<div class="modal-header">
  <h2 class="modal-title">{{modalHeading}}</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body pt-1">

  <!-- Submit Loading Spinner -->
  <js-loading-spinner *ngIf="loading || !gotOptionList"></js-loading-spinner>

  <form *ngIf="!loading && gotOptionList" [formGroup]="editForm" (ngSubmit)="update()">

    <!-- <div *ngIf="!(jobitem.changeTypeId === changeTypeEnum.Delete && alreadyHaveVO)"> -->
    <div *ngIf="!isClient" class="row">
      <label class="col-sm-2 form-label" for="itemDescription">Description:</label>
      <input *ngIf="readOnly" class="form-control-sm col-sm-9" id="itemDescription" type="text"
        formControlName="itemDescription" readonly />
      <input *ngIf="!readOnly" class="form-control-sm col-sm-9" id="itemDescription" type="text"
        formControlName="itemDescription" />
    </div>

    <h5 *ngIf="isClient" class="isClientItemDesc">{{jobitem.itemDescription}}</h5>

    <div *ngIf="isInDoNotPrintSection" class="isInDoNotPrintSection">
      BEWARE: This item is "Not Printed on the {{addendumName}}". Ensure colours & selections can be printed.
    </div>

    <div
      *ngIf='(jobitem.itemTypeId === ItemType.Detail) && (jobitem.selectionTypeId === SelectionType.Dropdown) && !calledFromDropDown'
      class="row">
      <label class="col-sm-2 form-label" for="selection">Selection:</label>
      <div class="col-sm-9 p-0" *ngIf="!readOnly" id="selection">
        <!-- next is the selection item linked to the option list -->
        <div class="p-0" ngbDropdown autoClose="outside" #selectionDrop="ngbDropdown">
          <button class="btn-sm btn-outline-primary wrapText" id="dropdownBasicSel" ngbDropdownToggle
            (click)="$event.preventDefault();">
            <span [class.bold]="jobitem.isBoldText" [class.red]="jobitem.optionColour === optionColourEnum.Red"
              [class.green]="jobitem.optionColour === optionColourEnum.Green" class="wrapText">{{newSelection}}</span>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasicSel" class="dropdownmenuclass">
            <!-- until service returns only the ones required test for Ids equal -->
            <div *ngFor="let opt of optionList">
              <button *ngIf="!opt.notSelectable" class="dropdown-item"
                (click)='$event.preventDefault(); selectionDrop.close(); setSelection(opt)'>
                <span [class.bold]="opt.isBoldText" [class.red]="opt.optionColour === optionColourEnum.Red"
                  [class.green]="opt.optionColour === optionColourEnum.Green"
                  class="wrapText">{{opt.description}}</span>
              </button>
              <div *ngIf="opt.notSelectable" class="dropdown-item"><span [class.bold]="opt.isBoldText"
                  [class.red]="opt.optionColour === optionColourEnum.Red"
                  [class.green]="opt.optionColour === optionColourEnum.Green"
                  class="wrapText">{{opt.description}}</span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-9" *ngIf="readOnly">
        <div [class.bold]="jobitem.isBoldText" [class.red]="jobitem.optionColour === optionColourEnum.Red"
          [class.green]="jobitem.optionColour === optionColourEnum.Green">{{newSelection}}</div>
      </div>
    </div>

    <div
      *ngIf='(jobitem.itemTypeId === ItemType.Detail) && (jobitem.selectionTypeId === SelectionType.Dropdown) && !calledFromDropDown && printedDescription'
      class="row">
      <label class="col-sm-2 form-label pe-0" for="printedAs"><span class="red">**</span>Printed as:</label>
      <div class="col-sm-9 p10">
        <div [class.bold]="jobitem.isBoldText" [class.red]="jobitem.optionColour === optionColourEnum.Red"
          [class.green]="jobitem.optionColour === optionColourEnum.Green">{{printedDescription}}</div>
      </div>
    </div>

    <!-- If manual selection show the box -->
    <div
      *ngIf="(jobitem.itemTypeId === ItemType.Detail) && (jobitem.selectionTypeId === SelectionType.Dropdown) && !isOriginalSelectionInOptionList"
      class="row">
      <label class="col-sm-2 form-label" for="manselection"></label>
      <textarea *ngIf="readOnly" class="form-control col-sm-9 noteEntryClass" id="manselection" type="text"
        formControlName="selection" readonly></textarea>
      <textarea *ngIf="!readOnly" class="form-control col-sm-9 noteEntryClass" id="manselection" type="text"
        formControlName="selection" jsFocusElement></textarea>
    </div>
    <div *ngIf="warningNote && warningNote !== ''" class="warningNoteClass">Warning: {{warningNote}}<br><br></div>

    <!-- Checkbox -->
    <div *ngIf='(jobitem.itemTypeId === ItemType.Detail) && (jobitem.selectionTypeId === SelectionType.Checkbox)'
      class="row">
      <label class="form-label col-sm-2" for="selection">Selection:</label>
      <input *ngIf="readOnly" class="col-sm-1 marginTop5" id="selection" type="checkbox" formControlName="selection"
        readonly />
      <input *ngIf="!readOnly" class="col-sm-1 marginTop5" id="selection" type="checkbox" formControlName="selection" />
    </div>

    <!-- Note -->
    <div *ngIf='(jobitem.itemTypeId === ItemType.Note)' class="">
      <label class="form-label" for="selection">Note:</label>
      <textarea *ngIf="readOnly" class="form-control noteEntryClass" id="selection" type="text"
        [style.height.px]="noteHeight" formControlName="selection" readonly></textarea>
      <textarea *ngIf="!readOnly" class="form-control noteEntryClass" id="selection" type="text"
        [style.height.px]="noteHeight" formControlName="selection"></textarea>
    </div>

    <!-- if variation text shown -->
    <div *ngIf="isVariationOnlyTextShown" class="mt-2">
      <label class="form-label" for="variationOnlyComment">Variation ONLY text:</label>
      <textarea class="form-control" id="variationOnlyComment" formControlName="variationOnlyComment"></textarea>
    </div>

    <!-- if note select colour and is bold -->
    <div *ngIf="!deletePressed && (jobitem.itemTypeId === ItemType.Note)" class="row">
      <label class="form-label col-sm-2 mt-1">Colour:</label>
      <div ngbDropdown class="col-sm-2 p-0">
        <button class="btn-sm btn-outline-primary dropdownclass" id="dropdownBasicCol" ngbDropdownToggle
          (click)="$event.preventDefault();">{{currentColour}}</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasicCol" class="dropdownmenuclass">
          <!-- until service returns only the ones required test for Ids equal -->
          <div *ngFor="let colour of colours">
            <button class="dropdown-item"
              (click)='$event.preventDefault(); setColour(colour.id)'>{{colour.name}}</button>
          </div>
        </div>
      </div>

      <div class="priceFieldClass120">
        <label class="form-label labelclass mt-1" for="isBoldNoteId">Bold:</label>
        <input type="checkbox" name="isBoldNoteId" id="isBoldNoteId" formControlName="isBoldNote"
          class="marginleft5 mt-1">
      </div>

      <div class="priceFieldClass80">
        <label class="form-label labelclass mt-1" for="isItalicNoteId">Italic:</label>
        <input type="checkbox" name="isItalicNoteId" id="isItalicNoteId" formControlName="isItalicNote"
          class="marginleft5 mt-1">
      </div>
    </div>

    <div *ngIf="userCanChangePrices">
      <!-- Provisional Sum ---------------------------------------------------------- -->
      <div *ngIf="jobitem.provisionalSum && !isClient" class="row">
        <label class="col-sm-2 form-label marginTop5" for="provisionalSum">PS Amount:</label>
        <input class="form-control-sm col-sm-2 pullright pe-1" id="provisionalSum" formControlName="provisionalSum"
          type="number" readonly />
      </div>

      <div *ngIf="jobitem.provisionalSum && isQtyRequired && !isClient" class="row">
        <label class="col-sm-2 form-label marginTop5" for="quantity">
          Quantity:
        </label>

        <input *ngIf="isQtyRequired" class="form-control-sm col-sm-2 righttext pe-1" name="quantity" id="quantity"
          formControlName="quantity" type="number" step="1" autocomplete="off" />
      </div>


      <!-- Qty heading if required -------------------------------------------------- -->
      <div *ngIf="isQtyRequired && !isClient && !jobitem.hasLinkedItems
      && variationType !== variationTypeEnum.Office
      && variationType !== variationTypeEnum.PreContractExFromContract
      && !jobitem.provisionalSum && !jobitem.isDoNotPrint" class="row pb-0 mb-0">
        <div class="col-sm-4 righttext">Quantity</div>
        <div *ngIf="showPrice" class="col-sm-5 lineTotalClass">Price</div>
        <div *ngIf="showPrice" class="col-sm-2 lineTotalClass me-0">Total</div>
      </div>

      <!-- if we have a previous qty -------------------------------------------------- -->
      <div *ngIf="isQtyRequired && !isClient && !deletePressed && !jobitem.hasLinkedItems
      && variationType !== variationTypeEnum.Office
      && variationType !== variationTypeEnum.PreContractExFromContract
      && !jobitem.provisionalSum && !jobitem.isDoNotPrint
      && (jobitem.changeTypeId !== changeTypeEnum.Add || jobitem.changedByVOId !== variationId)
      && editForm.get('previousQuantity').value && !readOnly" class="row">

        <label class="col-sm-2 form-label marginTop5" for="previousQuantity">Previous:</label>

        <!-- Qty -->
        <input *ngIf="isQtyRequired" class="form-control-sm col-sm-2 noBorderClass righttext pe-1"
          name="previousQuantity" id="previousQuantity" formControlName="previousQuantity" type="number" step="1"
          readonly />

        <!-- Cost Type -->
        <div ngbDropdown class="col-sm-2 p-0">
          <button class="btn-sm btn-outline-primary dropDownClass2" id="dropdownBasicPrev" ngbDropdownToggle
            (click)="$event.preventDefault();">{{costTypeEnum[editForm.get('previousCostTypeId').value]}}</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasicPrev" class="dropdownmenuclass">
            <!-- until service returns only the ones required test for Ids equal -->
            <div *ngFor="let c of prevCostTypes">
              <button class="dropdown-item"
                (click)='$event.preventDefault(); setCostTypePrevious(+c.id)'>{{c.name}}</button>
            </div>
          </div>
        </div>

        <!-- Price -->
        <div *ngIf="showPreviousPrice" class="col-sm-3 px-0 mx-0 righttext pe-1">
          <!-- <input class="form-control-sm width87 righttext" name="previousItemRate" id="previousItemRate"
            formControlName="previousItemRate" type="number" step="1" autocomplete="off" readonly /> -->
        </div>

        <!-- line total -->
        <div *ngIf="showPreviousPrice" class="col-sm-2 marginTop5 lineTotalClass">
          {{getRoundedPrice(editForm.get('previousQuantity').value * editForm.get('previousItemRate').value) |
          number:'1.0-0'}}
        </div>
      </div>


      <!-- Price ----------------------------------------------------------------------- -->
      <div *ngIf="jobitem.isDoNotPrint" class="row">
        <label class="col-sm-6 form-label red">
          Note: Hidden Item
        </label>
      </div>

      <div *ngIf="!jobitem.hasLinkedItems && !isClient
      && variationType !== variationTypeEnum.Office
      && variationType !== variationTypeEnum.PreContractExFromContract
      && !jobitem.provisionalSum
      && !jobitem.isDoNotPrint" class="row">
        <label class="col-sm-2 form-label marginTop5" for="quantity">
          <span *ngIf="editForm.get('previousQuantity').value && isQtyRequired">New:</span>
          <span *ngIf="!(editForm.get('previousQuantity').value && isQtyRequired)">Price:</span>
        </label>

        <input *ngIf="isQtyRequired && !readOnly && !deletePressed" class="form-control-sm col-sm-2 righttext pe-1"
          name="quantity" id="quantity" formControlName="quantity" type="number" step="1" autocomplete="off" />
        <input *ngIf="isQtyRequired && (readOnly || deletePressed)" class="form-control-sm col-sm-2 righttext pe-1"
          name="quantity" id="quantity" formControlName="quantity" type="number" step="1" readonly />

        <!-- Cost Type -->
        <div ngbDropdown class="col-sm-2 p-0">
          <button class="btn-sm btn-outline-primary dropDownClass2" id="dropdownBasicNew" ngbDropdownToggle
            (click)="$event.preventDefault();">{{costTypeDesc}}</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasicNew" class="dropdownmenuclass">
            <!-- until service returns only the ones required test for Ids equal -->
            <div *ngFor="let c of costTypeDropdown">
              <button class="dropdown-item" (click)='$event.preventDefault(); setCostType(+c.id)'>{{c.name}}</button>
            </div>
          </div>
        </div>

        <!-- New Price Rate -->
        <div class="col-sm-3 px-0 mx-0">
          <button *ngIf="selectionsAdmin && variationPriceEstimatingEnabled"
            class='btn btn-sm btn-outline-primary calcButton ms-4' (click)='$event.preventDefault(); estimatePrice()'
            [disabled]="editForm.pristine && !alreadyHaveVO">
            <i class="material-icons" [class.green]="hasEstimatingItems">keyboard</i>
          </button>
          <!-- <input *ngIf="showPrice" class="form-control-sm width87 righttext" name="newItemRate" id="newItemRate"
            formControlName="newItemRate" type="number" step="1" autocomplete="off" (keyup)="checkNoCents()"
            (onClick)="checkNoCents()" /> -->
          <dx-number-box id="newItemRate" *ngIf="showPrice" [width]="110" formControlName="newItemRate"
            format="#,###.##" [showSpinButtons]="true" (onValueChanged)="checkNoCents()">
          </dx-number-box>
        </div>

        <!-- If Qty we show a line total -->
        <div *ngIf="isQtyRequired && showPrice" class="col-sm-2 marginTop5 lineTotalClass me-0">
          {{getRoundedPrice((editForm.get('quantity').value * editForm.get('newItemRate').value)) | number:'1.0-0'}}
        </div>
      </div>

      <!-- Show Total if required ------------------------------------------------------------------------------ -->
      <div *ngIf="showPrice && !isClient && isQtyRequired && !deletePressed" class="row">
        <div class="col-sm-11 righttext">
          <b class="pe-3">Total:</b>
          {{getRoundedPrice(((editForm.get('quantity').value * editForm.get('newItemRate').value) -
          (editForm.get('previousQuantity').value * editForm.get('previousItemRate').value))) | number:'1.0-0'}}
        </div>
      </div>


      <!-- Fixing a PS ------------------------------------------------------------------------------------------ -->
      <div *ngIf="!jobitem.hasLinkedItems && !isClient
      && variationType !== variationTypeEnum.Office
      && variationType !== variationTypeEnum.PreContractExFromContract
      && jobitem.provisionalSum
      && !jobitem.isDoNotPrint && !deletePressed" class="row">
        <label class="form-label col-sm-2 mt-1" for="newItemRate">Fixed Price:</label>
        <input class="form-control-sm col-sm-2 righttext pe-1" name="newItemRate" id="newItemRate"
          formControlName="newItemRate" type="number" step="1" autocomplete="off" (keyup)="checkNoCents()" />
        <button *ngIf="selectionsAdmin && variationPriceEstimatingEnabled"
          class='btn btn-sm col-sm-1 btn-outline-primary calcButton' (click)='$event.preventDefault(); estimatePrice()'>
          <i class="material-icons" [class.green]="hasEstimatingItems">keyboard</i>
        </button>
        <label class="form-label col-sm-5 mt-1" for="hideFixedPSum">
          Delete fixed item from {{addendumName}}:
          <input type="checkbox" name="hideFixedPSum" id="hideFixedPSum" formControlName="hideFixedPSum"
            class="ms-2 mt-1">
        </label>
      </div>

      <!-- Show Total if qty required ------------------------------------------------------------------------------ -->
      <div *ngIf="!jobitem.hasLinkedItems && !isClient
      && variationType !== variationTypeEnum.Office
      && variationType !== variationTypeEnum.PreContractExFromContract
      && jobitem.provisionalSum && !jobitem.isDoNotPrint && !deletePressed && isQtyRequired" class="row">
        <label class="col-sm-2 form-label mt-1">Fixed Total:</label>
        <div class="col-sm-2 righttext pr21">
          {{getRoundedPrice((editForm.get('quantity').value * editForm.get('newItemRate').value)) | number:'1.0-0'}}
        </div>
      </div>

      <!-- Fixing a PS ------------------------------------------------------------------------------------------ -->
      <div *ngIf="!jobitem.hasLinkedItems && !isClient
      && variationType !== variationTypeEnum.Office
      && variationType !== variationTypeEnum.PreContractExFromContract
      && jobitem.provisionalSum && !jobitem.isDoNotPrint && !deletePressed" class="row">
        <label class="col-sm-2 form-label mt-1">Variation:</label>
        <div *ngIf="isQtyRequired" class="col-sm-2 righttext pr21"
          [class.red]="(((editForm.get('quantity').value * editForm.get('newItemRate').value)) - jobitem.provisionalSum) < 0">
          {{getRoundedPrice((editForm.get('quantity').value * editForm.get('newItemRate').value)) -
          jobitem.provisionalSum |
          number:'1.0-0'}}
        </div>
        <div *ngIf="!isQtyRequired" class="col-sm-2 righttext pr21"
          [class.red]="((editForm.get('newItemRate').value) - jobitem.provisionalSum) < 0">
          {{getRoundedPrice((editForm.get('newItemRate').value) - jobitem.provisionalSum) | number:'1.0-0'}}
        </div>
      </div>
    </div>

    <!-- Can mark this item as not taken by the client ------------------------------------------------------- -->
    <div *ngIf="!isClient" class="row mt-3">
      <div class="col-sm-8">
        <div *ngIf="alreadyHaveVO">
          <dx-check-box formControlName="itemNotTaken" text="Mark item as NOT taken by client" [rtlEnabled]="true"
            [disabled]="!isVariationOpen"> </dx-check-box>
        </div>
      </div>

      <div
        *ngIf="(alreadyHaveVO || !editForm.pristine || (jobitem.provisionalSum && deletePressed)) && !jobitem.hasLinkedItems"
        class="col-sm-3 righttext me-0 pe-0">
        <dx-check-box formControlName="isChecked" text="Checked" [rtlEnabled]="true"
          [disabled]="!isVariationOpen || !selectionsAdmin" (onValueChanged)="isCheckedClicked()"> </dx-check-box>
      </div>
    </div>

    <!-- Connected Info -->
    <div *ngIf="alreadyHaveVO && !isClient
      && variationType !== variationTypeEnum.Office
      && variationType !== variationTypeEnum.PreContractExFromContract
      && !jobitem.isDoNotPrint
      && connectedItemDesc !== ''" class="row">
      <label class="form-label col-sm-3 labelclass">
        Connected to:
      </label>
      <div class="col-sm-9">
        {{connectedToHeadingDesc}}:<br>{{connectedItemDesc}}
      </div>
    </div>

    <div *ngIf="jobitem.hasConnectedItems">
      <label class="form-label col-sm-3">
        <span class="red">*</span>Has Connected Items
      </label>
    </div>

    <div *ngIf="(deletePressed && canMarkDeleted) || (jobitem.changeTypeId === changeTypeEnum.Delete && alreadyHaveVO)">
      <!-- Can enter a comment when deleting -->
      <div class="row">
        <label class="col-sm-3 form-label" for="deleteComment">Deletion Comment:</label>
        <input class="form-control-sm col-sm-8" id="deleteComment" type="text" formControlName="deleteComment" />
      </div>
    </div>

    <!-- buttons -->
    <div *ngIf="isVariationPendingApproval && jobitem.changedByJobVarId" class="mt-3">
      <button *ngIf="editForm.get('itemNotTaken').value" (click)="$event.preventDefault(); changeNotTaken()"
        class="btn btn-sm btn-primary">
        Revert Not-Taken
      </button>
      <button *ngIf="!editForm.get('itemNotTaken').value" (click)="$event.preventDefault(); changeNotTaken()"
        class="btn btn-sm btn-primary">
        Mark Not-Taken</button>
    </div>

    <div *ngIf="isVariationOpen" class="mt-3">
      <button *ngIf="!deletePressed" type="submit" class="btn btn-sm btn-primary">Update</button>

      <!-- attachment file -->
      <button *ngIf="!deletePressed && !alreadyHaveVO" type="button" class="btn btn-sm btn-secondary ms-1"
        (click)="attachFile()" [disabled]="editForm.pristine">
        Save/Attach File
      </button>
      <button *ngIf='!deletePressed && alreadyHaveVO && jobitem.changeTypeId !== changeTypeEnum.Delete' type="button"
        class="btn btn-sm btn-secondary ms-1" (click)="attachFile()">
        <i class="material-icons material-icons-paperclip">attach_file</i>
      </button>

      <button *ngIf="!deletePressed && !isClient && alreadyHaveVO && !jobitem.connectedItemId" type="button"
        class="btn btn-sm btn-secondary ms-1" (click)="connectItem()">{{connectedButton}}</button>

      <!-- note if we are trying to fix a PS amount we cannot have options -->
      <button
        *ngIf="!deletePressed && !isClient && alreadyHaveVO && jobitem.itemTypeId !== this.ItemType.Heading && !jobitem.provisionalSum"
        type="button" class="btn btn-sm btn-secondary ms-1" (click)="createOption(content)"
        [disabled]="!editForm.pristine">Create Option</button>

      <!-- note that we can't delete if this line had a linkedJobItemId as it will be linked to another item above which will have to be deleted -->
      <button *ngIf="!deletePressed && !isClient && canRevert && jobitem.optionNumber == 0" type="button"
        class="btn btn-sm btn-danger ms-1" (click)="deleteItem()">Revert to Original</button>
      <button *ngIf="!deletePressed && canRevert && jobitem.optionNumber != 0" type="button"
        class="btn btn-sm btn-danger ms-1" (click)="deleteItem()">Delete</button>
      <button *ngIf="!deletePressed && canMarkDeleted" type="button" class="btn btn-sm btn-danger ms-1"
        (click)="deleteItem()">Mark Deleted</button>
      <button *ngIf="!deletePressed && canDelete" type="button" class="btn btn-sm btn-danger ms-1"
        (click)="deleteItem()">Delete</button>
      <button *ngIf="!deletePressed && alreadyHaveVO && jobitem.optionNumber !== 0" type="button"
        class="btn btn-sm btn-secondary ms-1" (click)="setOtherOptionsNotTaken()">Select</button>

      <!-- show the variation only text box? -->
      <button *ngIf="!deletePressed && !isVariationOnlyTextShown && !jobitem.hasLinkedItems
        && variationType < variationTypeEnum.SalesVariation" type="button" class="btn btn-sm btn-secondary ms-1"
        (click)="isVariationOnlyTextShown = !isVariationOnlyTextShown">Add
        variation text</button>

      <button *ngIf="!deletePressed && alreadyHaveVO && jobitem.changeTypeId === changeTypeEnum.Add" type="button"
        class="btn btn-sm btn-secondary ms-1" (click)="moveItem()">Move</button>

      <button *ngIf="deletePressed && canRevert" type="button" class="btn btn-sm btn-danger"
        (click)="deleteItem()">Confirm
        Revert (Delete Change)</button>
      <button *ngIf="deletePressed && (canDelete || canMarkDeleted)" type="button" class="btn btn-sm btn-danger"
        (click)="deleteItem()">Confirm Delete</button>
      <button *ngIf="deletePressed" type="button" class="btn btn-sm btn-secondary ms-1"
        (click)="cancelDeleteItem()">Cancel</button>
      <span *ngIf="deletePressed && canDelete" class="ms-2">Note: any items linked to this item will also be
        deleted</span>

      <div *ngIf="(alreadyHaveVO && attachmentExists)">
        Note: You must delete the attachment first if you want to
        <span *ngIf="!isClient">delete</span>
        <span *ngIf="isClient">change</span>
        this item.
      </div>
      <div *ngIf="jobitem.linkedJobItemId && !isClient && !canRevert && !canMarkDeleted">
        Note: This item is linked to: "{{linkedItemDescription}}", so cannot be deleted.
        Delete the linked item to delete this item.
      </div>

      <div *ngIf="selectionsAdmin && modifiedUserName !== ''">
        <div>Last modified by: {{modifiedUserName}} on {{modifiedDate | date}}</div>
      </div>
      <div *ngIf="userCanChangePrices && !isClient && previousJobVarItem && previousJobVarItem.price">
        Previous price: {{previousJobVarItem.price | number : '1.2-2'}}
      </div>
    </div>
  </form>
</div>

<!-- only for added items we can change this flag -->
<div *ngIf="showIsAcceptedIntoContractCheckBox" class="modal-footer">
  <dx-check-box [(value)]="isAcceptedIntoContract" text="Is Accepted Into Contract" [rtlEnabled]="true"
    (onValueChanged)="isAcceptedIntoContractClicked($event)"> </dx-check-box>
</div>

<!-- check for marking an item as not taken - used when variation is pending approval -->
<dx-popup class="popup" [width]="280" [height]="130" [showTitle]="true" title="" [dragEnabled]="false" [shading]="false"
  [hideOnOutsideClick]="true" [(visible)]="showItemNotTakenPopup">
  <div *dxTemplate="let data of 'content'">
    <dx-button [text]="changeNotTakenText" type="default" (onClick)="changeNotTakenGo()" [width]="250"
      class="margin: auto">
    </dx-button>
  </div>
</dx-popup>
