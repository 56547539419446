<div class="modal-header">
  <h4 class="modal-title">Import Constructive selections for job {{jobNumber}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body text-center">
  <div *ngIf="loading">Importing selections from Constructive. Please wait...</div>
  <div *ngIf="updatingVariation">Updating variation. Please wait...</div>
  <js-loading-spinner *ngIf="loading || updatingVariation"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading && !updatingVariation" #constructiveGrid id="constructiveGrid"
    [dataSource]="constructiveSelections" [remoteOperations]="false" [height]="gridHeight" [showBorders]="true"
    [wordWrapEnabled]="true" [showColumnLines]="true" [showRowLines]="true"
    (onToolbarPreparing)="onToolbarPreparing($event)" (onSaved)="onSaved()">

    <dxo-export [enabled]="true"></dxo-export>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-search-panel [visible]="true" [width]="200"></dxo-search-panel>
    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
    </dxo-grouping>

    <dxo-editing mode="batch" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false" [useIcons]="true"
      refreshMode="full">
    </dxo-editing>

    <dxi-column dataField="area" [width]="200" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="selectionGroup" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="selection" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="manufacturer" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="item" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="section" [setCellValue]="setSectionValue">
      <dxo-lookup [dataSource]="sectionHeadings" displayExpr="itemDescription" valueExpr="itemDescription">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="subHeading" [setCellValue]="setSubHeadingValue" editCellTemplate="codeDropDown">
      <dxo-lookup [dataSource]="subHeadings" displayExpr="itemDescription" valueExpr="itemDescription">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="itemDescription" [setCellValue]="setItemValue" editCellTemplate="itemDropDown">
      <dxo-lookup [dataSource]="jobItems" displayExpr="itemDescription" valueExpr="itemDescription">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="jobVariationId" [visible]="false"></dxi-column>
    <dxi-column dataField="jobVarTypeId" [visible]="false"></dxi-column>
    <dxi-column dataField="itemChangedId" [visible]="false"></dxi-column>

    <div *dxTemplate="let cellInfo of 'codeDropDown'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="subHeadings" [(value)]="cellInfo.value"
        displayExpr="itemDescription" valueExpr="itemDescription" contentTemplate="contentTemplate"
        (onOpened)="setUpSubHeadings(cellInfo)">
        <!-- Note we have to use standard scrolling so the list will "autoNavigateToFocusedRow" if the item is not near the top of the list -->
        <div *dxTemplate="let e of 'contentTemplate'" class="dropdownClass">
          <dx-data-grid [dataSource]="subHeadingsForDropdown" [remoteOperations]="false" height="99%"
            [showBorders]="false" [focusedRowEnabled]="true" [focusedRowKey]="cellInfo.value"
            [autoNavigateToFocusedRow]="true" [wordWrapEnabled]="true" [selectedRowKeys]="[cellInfo.value]"
            [hoverStateEnabled]="true" (onSelectionChanged)="onSelectionChanged(cellInfo, e.component, $event)"
            [showColumnHeaders]="false">

            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling mode="standard"></dxo-scrolling>
            <dxo-selection mode="single"></dxo-selection>

            <dxi-column dataField="itemDescription"></dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>

    <div *dxTemplate="let cellInfo of 'itemDropDown'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="jobItems" [(value)]="cellInfo.value"
        displayExpr="itemDescription" valueExpr="itemDescription" contentTemplate="contentTemplate"
        (onOpened)="setUpItems(cellInfo)">
        <!-- Note we have to use standard scrolling so the list will "autoNavigateToFocusedRow" if the item is not near the top of the list -->
        <div *dxTemplate="let e of 'contentTemplate'" class="dropdownClass">
          <dx-data-grid [dataSource]="itemsForDropdown" [remoteOperations]="false" height="99%" [showBorders]="false"
            [focusedRowEnabled]="true" [focusedRowKey]="cellInfo.value" [autoNavigateToFocusedRow]="true"
            [wordWrapEnabled]="true" [selectedRowKeys]="[cellInfo.value]" [hoverStateEnabled]="true"
            (onSelectionChanged)="onSelectionChanged(cellInfo, e.component, $event)" [showColumnHeaders]="false">

            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling mode="standard"></dxo-scrolling>
            <dxo-selection mode="single"></dxo-selection>

            <dxi-column dataField="itemDescription"></dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>

  </dx-data-grid>
</div>

<!-- <div class="modal-footer">
  <button type="button" class="btn btn-sm btn-secondary" (click)="runImport()">Run Import</button>
</div> -->
