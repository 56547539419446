export class LeadManagementDeal {
  contact: string;
  description: string;
  status: string;
  isDisabled: string;
  account: string;
  title: string;
  id: string;
  name: string;
  owner: string;
  cdate: Date;
  mdate: Date;
  repEmail: string;
  repName: string;
  stage: string;
  stageTitle: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  secondContactId: string;
}
