<!-- Loading Spinner -->
<js-loading-spinner *ngIf="loading || loadingSetupLinks"></js-loading-spinner>

<div *ngIf="!loading && !loadingSetupLinks" class='table-responsive'>
  <table class='table-xs'>
    <thead>
      <tr>
        <th>Linked Lines</th>
        <th class="IsIsNotClass">Is/Is Not</th>
        <th>Value:</th>
      </tr>
    </thead>
    <tbody *ngIf='jobItemLinks && jobItemLinks.length' class="jobitemlinkclass">
      <tr *ngFor='let jobitemlink of jobItemLinks' class="jobitemlinkclass">
        <td>{{jobitemlink.linkedJobItem.itemDescription}}</td>
        <td *ngIf="jobitemlink.isEqualTo" class="IsIsNotClass">Is</td>
        <td *ngIf="!jobitemlink.isEqualTo" class="IsIsNotClass">Is Not</td>
        <td>{{jobitemlink.setupValue}}</td>
        <td class="IsIsNotClass">
          <button *ngIf="addEditSetupLine === ''" class='btn btn-sm btn-outline-primary jobitemlinkbtn'
            (click)='editLinkedSetupLine(jobitemlink)'>
            <i class="material-icons">create</i>
          </button>
          <button *ngIf="addEditSetupLine === ''" class='btn btn-sm btn-outline-primary jobitemlinkbtn'
            (click)='deleteLinkedSetupLine(jobitemlink.id)'>
            <i class="material-icons">delete_forever</i>
          </button>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <button *ngIf="addEditSetupLine === ''" class='btn btn-sm btn-outline-primary mt-3'
        (click)='addLinkedSetupLine()'>
        Link to a new line
      </button>
    </tfoot>
  </table>
</div>
<br>

<div *ngIf="addEditSetupLine !== '' && !loading && !loadingSetupLinks">
  <div>
    <!-- we need to select another job item so use a tree -->
    <div>
      <b>Select the job line to connect to:</b>
    </div>
    <input id="filter" #filter (keyup)="tree.treeModel.filterNodes(filter.value)" placeholder="filter..."
      class='mt-1 pb-1 pt-0 ms-2p' autocomplete="off" />
    <button class="btn btn-sm btn-secondary"
      (click)="$event.preventDefault(); $event.stopPropagation(); tree.treeModel.clearFilter(); tree.treeModel.collapseAll(); filter.value=''">
      Clear Filter
    </button>

    <div class="globalTreeclass">
      <tree-root #tree [focused]="true" [nodes]="treeJobItemNodes" [options]="treeJobItemOptions"
        (activate)="setSetupLinkedItemId(tree.treeModel.getFocusedNode().data.id)"
        (initialized)="onSetupItemTreeInit(tree)"></tree-root>
    </div>
  </div>

  <div class="row equalToClass">
    <select [(ngModel)]="isEqualTo" class="selectEqual">
      <option *ngFor="let equalTo of equalToVals" [value]="equalTo.value">
        {{equalTo.name}}
      </option>
    </select>

    <!-- if the line connected to is a checkbox -->
    <div
      *ngIf='!loadingSelectedDetails && setupLinkedJobItemIsDetail && (setupLinkedItemSelectionType === SelectionType.Checkbox)'
      class="dropdownDiv">
      <!-- checkbox -->
      <label class="dropdownclass">Value:
        <input name="selected" type="checkbox" [(ngModel)]="checkboxValue">
      </label>
    </div>

    <!-- use radio to say show/hide if value equals next -->
    <div
      *ngIf='!loadingSelectedDetails && setupLinkedJobItemIsDetail && (setupLinkedItemSelectionType === SelectionType.Dropdown)'
      class="dropdownDiv">
      <!-- next is the selection item linked to the option list -->
      <div ngbDropdown placement="top-left">
        <button class="btn-sm btn-outline-primary dropdownclass" id="dropdownBasicS" ngbDropdownToggle
          (click)="$event.preventDefault(); $event.stopPropagation();">{{setupValue}}</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasicS" class="dropdownmenuclass">
          <!-- until service returns only the ones required test for Ids equal -->
          <div *ngFor="let opt of optionList">
            <button class="dropdown-item"
              (click)='$event.preventDefault(); setSetupSelection(opt)'>{{opt.description}}</button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="loadingSelectedDetails" class="dropdownDiv">
      loading details, please wait...
    </div>
  </div>

  <br>

  <button type="button" class="btn btn-sm btn-primary" (click)="updateSetupLinks()"
    [disabled]="!setupLinkedJobItemIsDetail || (setupValue === 'Please select...')">Update
    Links</button>
  <button type="button" class="btn btn-sm btn-secondary marginleft15" (click)="cancelSetupLinks()">Cancel</button>
</div>

<div *ngIf="addEditSetupLine === '' && !loading && !loadingSetupLinks">
  <button type="button" class="btn btn-sm btn-primary" (click)="closeClick()">Close</button>
</div>
