import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, of, Subscription } from 'rxjs';
import { CompanyService } from '../services/felixApi/company.service';
import { CompanyActivity } from '../dtos/company-activity';
import { NotificationService } from '../services/notification.service';
import { JobService } from '../services/felixApi/job.service';
import { GlobalService } from '../services/global.service';
import { Company } from '../dtos/company';
import { AttachmentTypeEnum } from '../dtos/attachment-type.enum';
import { JobDocumentService } from '../services/felixApi/job-document.service';
import { IJobDocument } from '../dtos/job-document';
import { ShowPdfComponent } from '../shared/show-pdf.component';
import { VariationHelperService } from '../services/felixApi/variation-helper.service';
import { VariationService } from '../services/felixApi/variation.service';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Step } from './progress-tracker/progress-tracker.component';

@Component({
  selector: 'js-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  attachmentTypeEnum = AttachmentTypeEnum;
  currentActivity: CompanyActivity;
  activities: Step[];
  currentCompany: Company;
  jobDocument: IJobDocument;
  clientSelectionVariation: number;
  subscriptions: Subscription[] = [];
  logo: Blob;
  logoFileTypeId: number;

  constructor(
    private jobService: JobService,
    private varService: VariationService,
    private varHelper: VariationHelperService,
    private globalService: GlobalService,
    private jobDocumentService: JobDocumentService,
    private notiService: NotificationService,
    private modalService: NgbModal,
    private companyService: CompanyService,
    private router: Router) { }

  ngOnInit() {
    this.currentCompany = this.globalService.getCurrentCompany();
    this.checkVariations();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  checkVariations() {
    this.clientSelectionVariation = null;
    this.subscriptions = this.subscriptions.concat(
      this.varService.getVariations().subscribe(vars => {
        const stats = this.varHelper.checkVariations(vars);
        this.clientSelectionVariation = stats.openClientVariation;
      })
    );
  }

  goToClientSelections() {
    this.jobService.currentVariationId = this.clientSelectionVariation;
    this.router.navigate(['selections']);
  }

  refreshJobNumber() {
    this.readDivisionForLogo();
  }

  readDivisionForLogo() {
    if (this.jobService.currentJob?.divisionId) {
      this.subscriptions.push(
        this.companyService.getDivisionLogo(this.jobService.currentJob.divisionId)
          .subscribe({
            next: (res) => {
              if (res) {
                this.logo = res?.attachment;
                this.logoFileTypeId = res?.attachmentTypeId;
              } else {
                this.logo = this.currentCompany.logo;
                this.logoFileTypeId = this.currentCompany.logoFileTypeId;
              }
              this.readJobActivityDetails();
              this.readLatestJobDocument();
            },
            error: (err) => {
              this.logo = this.currentCompany.logo;
              this.logoFileTypeId = this.currentCompany.logoFileTypeId;
              this.readJobActivityDetails();
              this.readLatestJobDocument();
            }
          })
      );
    } else {
      this.logo = this.currentCompany.logo;
      this.logoFileTypeId = this.currentCompany.logoFileTypeId;
      this.readJobActivityDetails();
      this.readLatestJobDocument();
    }
    this.checkVariations();
  }

  readJobActivityDetails() {
    this.currentActivity = null;

    if (this.jobService.currentJob) {
      const activityInfo$ = this.jobService.getJobExtras(this.jobService.currentJob.id)
        .pipe(
          switchMap(extras => {
            if (extras?.currentActivityId) {
              return forkJoin([
                this.companyService.getCompanyActivityById(extras.currentActivityId),
                this.companyService.getCompanyActivitiesForClient(this.jobService.currentJob.id)
              ]);
            } else {
              return of([null, null]);
            }
          })
        );

      this.subscriptions.push(
        activityInfo$.subscribe({
          next: ([currentActivity, activities]) => {
            if (currentActivity) {
              this.activities = activities.map(a => ({ label: a.description, desc: a.longDescription }));
              this.currentActivity = currentActivity;
            }
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  readLatestJobDocument() {
    this.jobDocument = null;
    if (this.jobService.currentJob) {
      this.subscriptions = this.subscriptions.concat(
        this.jobDocumentService.getLatestJobDocumentsForClient(this.jobService.currentJob.jobNumber, true)
          .subscribe({
            next: (jobDocument) => {
              this.jobDocument = jobDocument;
            },
            error: () => {
              this.jobDocument = null;
            }
          })
      );
    }
  }

  showPDF() {
    // show the attachment but read only so open full screen
    const modalRef = this.modalService.open(ShowPdfComponent, { windowClass: 'modal-infopdf' });
    modalRef.componentInstance.jobDocumentId = this.jobDocument.id;

    modalRef.result.then(() => {
    });
  }

}
