export enum VariationTypeEnum {
    PreConstruction = 1,
    Construction = 2,
    Office = 3,
    FinalAccount = 4,
    SalesVariation = 11,
    SalesProvisionalSum = 12,
    SalesOffice = 13,
    PreContractVariation = 21,
    PreContractProvisionalSum = 22,
    PreContractOffice = 23,
    PreContractExFromContract = 24
}
