import { VariationStatusEnum } from './../../../dtos/variation-status.enum';
import { VariationSplitsService } from './../../../services/felixApi/variation-splits.service';
import { GlobalService } from './../../../services/global.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GridService } from '../../../services/grid.service';
import { NotificationService } from '../../../services/notification.service';
import { ClaimsService } from '../../../services/felixApi/claims.service';
import { VariationService } from '../../../services/felixApi/variation.service';
import { Subscription } from 'rxjs';
import { JobService } from '../../../services/felixApi/job.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { CompanyService } from '../../../services/felixApi/company.service';
import { ConfigurationEnum } from '../../../dtos/configuration-enum';
import DataSource from 'devextreme/data/data_source';
import { IncomeInvoice, IncomeInvoiceTypeEnum } from '../../../dtos/income-invoice';

@Component({
  selector: 'js-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.css']
})
export class StatementComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: DataSource;
  claimInvoices: IncomeInvoice[] = [];
  claimTriggers = [
    { id: 1, description: 'By Activity' },
    { id: 2, description: 'Raised Immediately' }
  ];
  invoiceTos = [
    { id: 2, description: 'From Loan' },
    { id: 3, description: 'Direct to Client' }
  ];
  lenderInformationEnabled: boolean;
  deposit: number;
  contractValue: number;
  claimTotal: number;
  outstandingAmount: number;
  incomeInvoiceTypes: { id: string; description: string; }[];
  gridHeight: number;
  jobNumber: string;
  loading = true;

  constructor(
    public activeModal: NgbActiveModal,
    private claimsService: ClaimsService,
    private variationService: VariationService,
    public gridService: GridService,
    private jobService: JobService,
    private companyService: CompanyService,
    private globalService: GlobalService,
    private variationSplitsService: VariationSplitsService,
    private notiService: NotificationService
  ) {
  }

  ngOnInit() {
    this.gridHeight = window.innerHeight - 300;
    this.jobNumber = this.jobService.currentJob.jobNumber;

    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.LenderInformationEnabled) === 1) {
      this.lenderInformationEnabled = true;
    }

    this.incomeInvoiceTypes = IncomeInvoiceTypeEnum ? Object.keys(IncomeInvoiceTypeEnum).filter(k => typeof IncomeInvoiceTypeEnum[k as any] === 'number').map(k => ({ id: IncomeInvoiceTypeEnum[k as any], description: k })) : [];

    this.getContractValue();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getContractValue() {
    this.subscriptions = this.subscriptions.concat(
      this.variationSplitsService.getClaimsData(this.jobService.currentJob.id).subscribe({
        next: (contractValue) => {
          this.contractValue = contractValue;
          this.getClaimsData();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  getClaimsData() {
    this.subscriptions = this.subscriptions.concat(
      this.claimsService.getClaimsData(this.jobService.currentJob.id).subscribe({
        next: () => {
          this.loading = false;
          this.getData();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  getData() {
    this.deposit = this.jobService.currentJob.depositAmount ? this.jobService.currentJob.depositAmount : 0;
    this.contractValue = this.jobService.contractValue;

    // set the claim amount to the total amount
    this.claimsService.claimInvoices.forEach(claimInvoice => {
      claimInvoice.claimAmount = claimInvoice.totalIncGST;
    });

    // get deposit not invoiced
    const depositClaims = this.claimsService.claimInvoices.filter(i => i.incomeInvoiceTypeId === IncomeInvoiceTypeEnum.Deposit);
    const totalDepositClaimed = depositClaims.reduce((a, b) => a + b.totalIncGST, 0);

    if (this.deposit > totalDepositClaimed) {
      this.claimInvoices.push(new IncomeInvoice(IncomeInvoiceTypeEnum.Deposit, 'Deposit', this.deposit - totalDepositClaimed, ''));
    }

    if (depositClaims) {
      depositClaims.forEach(depositClaim => {
        depositClaim.claimNumber = '';
        this.claimInvoices.push(depositClaim);
      });
    }

    // get progress claims
    this.claimsService.claimJobLines.forEach(claimLine => {
      const claimInvoice = this.claimsService.claimInvoices?.find(i => i.claimJobLineId === claimLine.id);
      if (claimInvoice) {
        claimInvoice.claimNumber = claimLine.orderNo.toString();
        this.claimInvoices.push(claimInvoice);
      } else {
        // calc with any splits
        const variationSplits = this.variationSplitsService.variationSplitsForJob.filter(s => s.jobLineId === claimLine.id);
        const variationSplitsTotal = variationSplits.reduce((a, b) => a + b.amount, 0);
        this.claimInvoices.push(new IncomeInvoice(IncomeInvoiceTypeEnum.Claim, claimLine.description, claimLine.amount + (variationSplitsTotal ?? 0), claimLine.orderNo.toString()));
      }
    });

    // only approved post contract variations
    this.variationService.variations.filter(i => i.variationType < 10 && i.statusId >= VariationStatusEnum.Approved).forEach(variation => {
      const claimInvoice = this.claimsService.claimInvoices?.find(i => i.jobVariationId === variation.id && !i.variationSplitId);
      if (claimInvoice) {
        claimInvoice.claimNumber = variation.displayedVariationNumber;
        this.claimInvoices.push(claimInvoice);
      } else {
        // do we have splits
        const variationSplitsForVariation = this.variationSplitsService.variationSplitsForJob.filter(s => s.jobVariationId === variation.id);

        if (variationSplitsForVariation && variationSplitsForVariation.length) {
          // we exclude the splits added to claims as we have added them above
          const variationSplitsExClaims = variationSplitsForVariation.filter(s => !s.jobLineId);

          variationSplitsExClaims.forEach(variationSplit => {
            const splitInvoice = this.claimsService.claimInvoices?.find(i => i.variationSplitId === variationSplit.id);
            if (splitInvoice) {
              splitInvoice.claimNumber = splitInvoice.orderNumber.toString();
              this.claimInvoices.push(splitInvoice);
            } else {
              let splitTitle = variation.title;
              if (variationSplit.companyActivityId) {
                const activity = this.companyService.activities.find(a => a.id === variationSplit.companyActivityId);
                if (activity) {
                  splitTitle += ' (' + activity.description + ')';
                }
              } else {
                const newDateJson = JSON.stringify(variationSplit.claimDate).substr(1, 10);
                const newDate = new Date(+newDateJson.substr(0, 4), +newDateJson.substr(5, 2) - 1, +newDateJson.toString().substr(8, 2));

                splitTitle += ' (' + newDate.toLocaleDateString() + ')';
              }
              this.claimInvoices.push(new IncomeInvoice(IncomeInvoiceTypeEnum.Variation, splitTitle, variationSplit.amount, variation.displayedVariationNumber + '.' + variationSplit.orderNo.toString()));
            }
          });
        } else {
          this.claimInvoices.push(new IncomeInvoice(IncomeInvoiceTypeEnum.Variation, variation.title, variation.variationTotal, variation.displayedVariationNumber));
        }
      }
    });

    this.setUpDataSets();
  }

  setUpDataSets() {
    this.dataSource = new DataSource({
      key: 'id',
      load: () => this.claimInvoices
    });
  }

  calculateTotal = (options) => {
    if (options.name === 'gridTotal') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
        this.claimTotal = 0;

      } else if (options.summaryProcess === 'calculate') {
        options.totalValue += options.value.amount ? options.value.amount : 0;
        this.claimTotal = options.totalValue;

      } else if (options.summaryProcess === 'finalize') {
        this.outstandingAmount = this.contractValue - this.claimTotal;
      }
    }
  }

  refresh() {
    this.grid.instance.refresh();
  }

  close() {
    this.activeModal.close();
  }

  calculateAmountOutstanding(data: IncomeInvoice) {
    if (!data.totalIncGST) {
      return null;
    }
    return (Math.round((data.totalIncGST) * 100) / 100) - (Math.round((data.totalPaid) * 100) / 100);
  }
}
