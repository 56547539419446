<js-loading-spinner *ngIf="loading"></js-loading-spinner>

<div *ngIf="imageFound && !(maxWidth && maxHeight)">
    <img [src]="image" (click)="emitImageClicked()"
        [ngStyle]="{'max-width': 'calc(' + '300px' + ')', 'max-height': 'calc(' + '200px' + ')'}"
        [ngClass]="{'full-height' : fullHeight, 'pointer' : showPointer}" />
</div>

<div *ngIf="imageFound && maxWidth && maxHeight">
    <img [src]="image" (click)="emitImageClicked()"
        [ngStyle]="{'max-width': 'calc(' + maxWidth + 'px' + ')', 'max-height': 'calc(' + maxHeight + 'px' + ')'}"
        [ngClass]="{'full-height' : fullHeight}" />
</div>