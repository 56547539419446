<div class="modal-header">
  <h2 class="modal-title">Copy the FINAL items from another job to this one</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form *ngIf="!loading" (ngSubmit)="getJobToCopy()" [formGroup]="jobNumberToCopyForm" novalidate class="form-inline">
    <div class="form-group">
      <label for="jobNumberId" class="col-form-label"></label>
      <input id="jobNumberId" class="form-control form-control-sm" type="text" placeholder="Enter Job Number"
        formControlName="jobNumber" (keyup)="changeJobToCopyNum()" />
      <button type="submit" class="btn btn-sm btn-primary"
        [disabled]="!jobNumberToCopyForm.value.jobNumber">Select</button>
      <button type="button" class="btn btn-sm btn-primary ms-2" (click)="jobSearchforCopy(1)">
        Lookup a template
      </button>
      <button type="button" class="btn btn-sm btn-primary ms-2" (click)="jobSearchforCopy(2)">
        Search for a Job
      </button>
    </div>
  </form>
  <div>{{jobToCopyName}}</div>

  <!-- Image Loading Spinner -->
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

</div>

<div *ngIf="!loading" class="modal-footer">
  <button type="button" class="btn btn-sm btn-primary" (click)="copyJob()"
    [disabled]='jobToCopy === null'>Run
    Copy</button>
</div>
