import { Component, OnInit, OnDestroy } from '@angular/core';
import { JobService } from '../../services/felixApi/job.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobSearchTypeEnum } from '../../dtos/job-search-type.enum';
import { GlobalService } from '../../services/global.service';
import { Subscription } from 'rxjs';
import { JobSearchModalComponent } from '../../jobs/job-search-modal.component';
import { NotificationService } from '../../services/notification.service';
import { TaskService } from '../../services/felixApi/task.service';
import { TemplateTask } from '../../dtos/template-task';

@Component({
  selector: 'js-create-sales-task',
  templateUrl: './create-sales-task.component.html',
  styleUrls: ['./create-sales-task.component.scss']
})
export class CreateSalesTaskComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  jobNumber: string;
  jobId: number;
  salesTemplateTasks: TemplateTask[];
  salesTemplateTaskId: number;
  officeComment: string;


  constructor(private jobService: JobService,
    private _activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private taskService: TaskService,
    private notiService: NotificationService,
    private globalService: GlobalService) { }


  ngOnInit(): void {
    this.jobNumber = this.globalService.getCurrentJob();
    if (this.jobNumber && this.jobNumber !== 'undefined') {
      this.getJob();
    } else {
      this.jobNumber = '';
    }
    this.salesTemplateTasks = this.taskService.salesTemplateTasks;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  cancel() {
    this._activeModal.close(null);
  }

  jobSearch() {
    const modalRef = this.modalService.open(JobSearchModalComponent, { windowClass: 'modal-1000' });
    modalRef.componentInstance.searchType = JobSearchTypeEnum.MyJobs;

    modalRef.result.then((jobNumber) => {
      if (jobNumber) {
        this.jobNumber = jobNumber;
        this.getJob();
      }
    });
  }

  getJob() {
    this.subscriptions = this.subscriptions.concat(
      this.jobService.getJob(this.jobNumber)
        .subscribe({
          next: (job) => {
            if (job) {
              this.globalService.setCurrentJob(this.jobNumber);
              this.jobId = job.id;
            } else {
              this.notiService.showInfo('Job not found');
              this.jobId = null;
            }
          },
          error: () => {
            this.notiService.showInfo('Job not found');
            this.jobId = null;
          }
        })
    );
  }

  changeJobNum() {
    this.jobId = null;
  }

  createTask() {
    this._activeModal.close({ jobId: this.jobId, templateTaskId: this.salesTemplateTaskId, officeComment: this.officeComment });
  }
}
