import { GlobalService } from './../../../services/global.service';
import { CategoryService } from './../../../services/felixApi/category.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-select-categories',
  templateUrl: './select-categories.component.html',
  styleUrls: ['./select-categories.component.scss']
})
export class SelectCategoriesComponent implements OnInit {

  dataSource: CustomStore;
  selectedRows: number[];
  gridHeight: number;

  constructor(
    private _activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private categoryService: CategoryService) { }

  ngOnInit() {
    this.gridHeight = window.innerHeight > 1000 ? 700 : window.innerHeight - 300;
    this.setupDataSource();
  }

  close() {
    this._activeModal.close(this.selectedRows);
  }

  cancel() {
    this._activeModal.dismiss();
  }

  setupDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.categoryService.getCategories().subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

}
