import { Component, OnInit, OnDestroy } from '@angular/core';
import { BoardMasterService } from '../../../services/felixApi/board-master.service';
import { NotificationService } from '../../../services/notification.service';
import { AuthService } from '../../../services/auth.service';
import { BoardMaster, BoardType } from '../../../dtos/board-master';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ShowPdfComponent } from '../../../shared/show-pdf.component';
import { PDFReports } from '../../../dtos/pdf-report';

@Component({
  selector: 'js-add-selection-board',
  templateUrl: './add-selection-board.component.html',
  styleUrls: ['./add-selection-board.component.scss']
})
export class AddSelectionBoardComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  loading = true;
  boards: BoardMaster[];
  boardsForType: BoardMaster[];
  boardTypes: BoardType[] = [];
  selectedBoardType: BoardType;
  selectedBoardId: number;

  constructor(
    public boardService: BoardMasterService,
    private authService: AuthService,
    private _activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private notiService: NotificationService) { }

  ngOnInit() {
    this.getBoardTypes();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getBoardTypes() {
    // read all JobCustomers records for this job
    this.subscriptions = this.subscriptions.concat(
      this.boardService.getBoardTypes()
        .subscribe({
          next: (boardTypes) => {
            boardTypes.forEach(bType => {
              if (!this.authService.isClient() || bType.isSelectableByClient) {
                this.boardTypes = this.boardTypes.concat(bType);
              }
            });

            this.getBoards();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  boardTypeChanged = () => {
    this.selectedBoardId = null;

    this.boards.forEach(b => {
      b.selected = false;
    });
  }

  getBoards() {
    // read all JobCustomers records for this job
    this.subscriptions = this.subscriptions.concat(
      this.boardService.getBoards()
        .subscribe({
          next: (boards) => {
            this.boards = boards;
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  filterBoards = (board: BoardMaster) => {
    if (!this.selectedBoardType) {
      return false;
    }
    return (board.boardTypeId === this.selectedBoardType.id);
  }

  openPDF(boardId: number) {
    // open the PDF
    this.loading = true;
    this.boardService.getBoardAttachment(boardId)
      .subscribe({
        next: (attachment) => {
          this.loading = false;
          if (attachment) {
            const pdfReport: PDFReports = new PDFReports;
            pdfReport.pdfReport = attachment.attachment;
            pdfReport.pdfReportName = attachment.attachmentName;
            const modalRef1 = this.modalService.open(ShowPdfComponent, { windowClass: 'modal-pdf' });
            modalRef1.componentInstance.pdfReport = pdfReport;
            modalRef1.result.then();
          } else {
            this.notiService.showInfo('No PDF is a available for this board');
          }
        },
        error: () => {
          this.notiService.showInfo('No PDF is a available for this board');
          this.loading = false;
        }
      });
  }

  selectBoard(board: BoardMaster) {
    this.selectedBoardId = board.selected ? null : board.id;

    this.boards.forEach(b => {
      if (b.id !== board.id) {
        b.selected = false;
      }
    });
  }

  update() {
    this._activeModal.close(this.selectedBoardId);
  }

  cancel() {
    this._activeModal.dismiss();
  }
}
