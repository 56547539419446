<div class="card">

    <div class="card-header">
        Enter an Invoice
    </div>

    <js-loading-spinner *ngIf="loading || updating"></js-loading-spinner>

    <div *ngIf="!loading && !updating" class="card-body">

        <form class="form" novalidate
            [formGroup]="invoiceForm">
            <fieldset>
                <!-- Invoice Number -->
                <div class="form-group row">
                    <label class="form-label labelWidth ms-2" for="invoiceNumber">Invoice Number*</label>
                    <input id="invoiceNumber" type="text" placeholder="" formControlName="invoiceNumber"
                        class="labelWidth" />
                </div>

                <!-- Invoice Date -->
                <div class="form-group row">
                    <label class="form-label labelWidth ms-2" for="invoiceDate">Invoice Date*</label>
                    <input id="invoiceDate" type="date" placeholder="" formControlName="invoiceDate"
                        class="labelWidth" />
                </div>

                <!-- Order Number -->
                <div class="form-group row">
                    <label class="form-label labelWidth ms-2" for="orderNumber">Order Number*</label>
                    <input id="orderNumber" type="text" placeholder="" formControlName="orderNumber"
                        class="labelWidth" />
                </div>

                <!-- Order Desc-->
                <!-- <div class="form-group row">
                    <label class="form-label labelWidth" for="invoiceDescription">Description</label>
                    <textarea class="form-control-sm col-sm-8 textareaClass" id="invoiceDescription" type="text"
                        placeholder="Extra description of goods or services"
                        formControlName="invoiceDescription"></textarea>
                </div> -->

                <!-- Totals -->
                <div class="form-group row">
                    <label class="form-label labelWidth ms-2" for="totalExGST">Total Ex. GST*</label>
                    <input class="form-control-sm text-end" name="totalExGST" id="totalExGST"
                        formControlName="totalExGST" type="number" step="1" autocomplete="off" (keyup)="calcTotalFromTotalExGST()"
                        (click)="calcTotalFromTotalExGST()" class="righttext labelWidth" />
                </div>

                <div class="form-group row">
                    <label class="form-label labelWidth ms-2" for="totalGST">Total GST*</label>
                    <input class="form-control-sm text-end" name="totalGST" id="totalGST" formControlName="totalGST"
                        type="number" step="1" autocomplete="off" (keyup)="calcTotalFromTotalGST()" (click)="calcTotalFromTotalGST()"
                        class="righttext labelWidth" />
                </div>

                <div class="form-group row">
                    <label class="form-label labelWidth ms-2" for="totalIncGST">Total Inc. GST*</label>
                    <input class="form-control-sm text-end" name="totalIncGST" id="totalIncGST"
                        formControlName="totalIncGST" type="number" readonly class="righttext labelWidth" />
                </div>

            </fieldset>
        </form>

        <dx-file-uploader
          [ngClass]="wrongFileType ? 'red-faint-back' : 'b-blue-faint-back'"
          [value]="filesToUpload" uploadMode="useForm"
          selectButtonText="Select files" labelText="or drop files here"
          (onValueChanged)="handleFileInput($event)"
          accept="application/pdf,image/jpeg,image/png">
          <!-- Following seems to be broken for dx uploader with pdf: handling manually -->
          <!-- allowedFileExtensions="['.pdf','.PDF','.jpg','.jpeg','.JPG','.JPEG','.png','.PNG']"
          invalidFileExtensionMessage="Please select PDF, JPG or PNG only" -->
        </dx-file-uploader>
        <div *ngIf="wrongFileType" style="color: red">Please select PDF, JPG or PNG only</div>
        <!-- <ng2-file-input uploadMode="useForm" [id]="fileInputId"
            (onValueChanged)="handleFileInput($event)"
            [drop-text]="'<br>Drop invoice here or browse'" extensions="['image/jpeg/pdf/PDF/jpg/JPG/JPEG/PNG/png']"
            [invalid-file-text]="'Please select PDFs only'">
        </ng2-file-input> -->
        <!-- <ng2-file-input (onAdded)="handleFile2Input($event)" [id]="fileInput2Id"
            [drop-text]="'<br>Drop extra PDF here or browse'" extensions="['pdf/PDF']"
            [invalid-file-text]="'Please select PDFs only'">
        </ng2-file-input> -->
    </div>

    <div *ngIf="!loading && !updating" class="card-footer px-1">
        <button type="button" class="btn btn-sm btn-primary ms-2" (click)="submit()"
            [disabled]="!invoiceForm.valid || !fileReadyToLoad">
            Submit
        </button>

        <button type="button" class="btn btn-sm btn-secondary ms-3" (click)="back()">
            Back
        </button>
    </div>
</div>
