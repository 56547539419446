import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'js-admin-override-modal',
  templateUrl: './admin-override-modal.component.html',
  styleUrls: ['./admin-override-modal.component.scss']
})
export class AdminOverrideModalComponent implements OnInit {
  @Input() variationType: number;

  isSalesVO = false;

  constructor(private _activeModal: NgbActiveModal) { }

  ngOnInit() {
    if (this.variationType < 20) {
      this.isSalesVO = true;
    }
  }

  closeYes() {
    this._activeModal.close();
  }

  close() {
    this._activeModal.dismiss();
  }
}
