<div class="modal-header">
  <h4 class="modal-title">House Options</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">
  <dx-data-grid id="typeGrid" class="maintenance-grid" [height]="gridHeight" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" columnResizingMode="widget" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [repaintChangesOnly]="true">
    <dxo-search-panel [visible]="true" location="before" [width]="200"></dxo-search-panel>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="false" [contextMenuEnabled]="true" expandMode="rowClick"></dxo-grouping>

    <dxi-column dataField="level1Name" caption="House" dataType="string" [width]="170" [groupIndex]="0"> </dxi-column>
    <dxi-column dataField="level2Name" caption="Category" dataType="string" [width]="180">
    </dxi-column>
    <dxi-column dataField="level3Name" caption="Sub-Category" dataType="string" [width]="150">
    </dxi-column>
    <dxi-column dataField="level4Name" dataType="string" [width]="150" [visible]="false"> </dxi-column>

    <dxi-column dataField="productCode" caption="Code" dataType="string" [width]="80"> </dxi-column>

    <dxi-column dataField="defaultDescription" dataType="string" caption="Item Prefix" [width]="120"> </dxi-column>

    <dxi-column dataField="description" dataType="string" minWidth="250"> </dxi-column>

    <dxi-column dataField="salesPriceIfAdded" caption="Price" dataType="number" [width]="100" format="#,###">
    </dxi-column>

  </dx-data-grid>
</div>
