import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { GlobalService } from '../global.service';
import { Category } from '../../dtos/category';
import { JobItemCategory } from '../../dtos/job-item-category';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }


  getCategories() {
    return this.http.get<Category[]>(this.globalService.getApiUrl() + '/categories?activeOnly=true',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getJobItemCategories(id: number) {
    return this.http.get<JobItemCategory[]>(this.globalService.getApiUrl() + '/job-items/' + id + '/categories',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  postJobItemCategory(id: number, categoryId: number) {
    const url = this.globalService.getApiUrl() + '/job-items/' + id + '/categories';
    return this.http.post(url, JSON.stringify({ categoryId: categoryId }), this.httpService.getHttpOptions());
  }

  deleteJobItemCategory(id: number) {
    const url = this.globalService.getApiUrl() + '/job-item-Categories/' + id;
    return this.http.delete(url, this.httpService.getHttpOptions());
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
