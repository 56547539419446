import { UtilsService } from './../../services/utils.service';
import { formatDate } from 'devextreme/localization';
import { RecipeLine } from './../../dtos/recipe-line';
import { AllEstimatingItem } from '../../dtos/all-estimating-item';
import { JobService } from './../../services/felixApi/job.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { GlobalService } from '../../services/global.service';
import { NotificationService } from '../../services/notification.service';
import { Variation } from '../../dtos/variation';
import { DxDataGridComponent } from 'devextreme-angular';
import { CostCentreSummaryComponent } from '../cost-centre-summary/cost-centre-summary.component';
import { MarginSaveToRecipeComponent } from '../job-margin/margin-save-to-recipe/margin-save-to-recipe.component';


@Component({
  selector: 'js-all-estimate-items',
  templateUrl: './all-estimate-items.component.html',
  styleUrls: ['./all-estimate-items.component.scss']
})
export class AllEstimateItemsComponent implements OnInit, OnDestroy {
  @Input() districtId: number;

  @ViewChild('allEstimateGrid') dataGrid: DxDataGridComponent;

  loading = true;
  subscriptions: Subscription[] = [];
  gridHeight: number;
  dataSource: DataSource;
  jobNumber: string;
  jobVariations: Variation[];
  allLines: AllEstimatingItem[];
  selectedRowKeys: number[] = [];
  linesExploded: boolean;

  constructor(private _activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private jobService: JobService,
    private notiService: NotificationService,
    private utilsService: UtilsService,
    private estimatingService: EstimatingService,
    private modalService: NgbModal) {
    this.getGroupTitle = this.getGroupTitle.bind(this);
  }

  ngOnInit() {
    this.subscribeToWidthChanges();
    this.setHeight();
  }

  getData() {
    this.dataSource = null;
    this.subscriptions = this.subscriptions.concat(
      this.estimatingService.getDataForAllEstimatingItems()
        .subscribe({
          next: (variations) => {
            this.jobVariations = variations;
            this.getLines();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  getLines() {
    this.subscriptions = this.subscriptions.concat(
      this.estimatingService.getAllJobEstimatingItems(this.jobService.currentJob?.id).subscribe({
        next: (res) => {
          this.allLines = res;
          this.setUpGroupDescs();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  subscribeToWidthChanges() {
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerHeightChanged.subscribe(() => {
        this.setHeight();
      })
    );
  }

  setHeight() {
    this.gridHeight = this.globalService.innerHeight - 300;
  }

  refreshJobNumber(jobNo: string) {
    this.jobNumber = jobNo;

    if (jobNo) {

      if (this.jobService.currentJob?.id) {
        this.getData();
      }
    }
  }

  setUpDataSet() {
    this.dataSource = new DataSource({
      key: 'jobVariationId',
      load: () => this.allLines
    });
  }

  setUpGroupDescs() {
    this.allLines.forEach(item => {
      if (item.recipeCode) {
        const foundRecipe = this.estimatingService.allRecipes.find(i => i.recipeCode === item.recipeCode);

        if (foundRecipe) {
          item.masterGroupCostCentre = ' ;RECIPES';
          const recipe = this.estimatingService.allRecipes.find(i => i.recipeCode === item.recipeCode);
          if (recipe) {
            const subGroup = this.estimatingService.recipeGroups.find(i => i.id === recipe.recipeParentId);
            if (subGroup) {
              item.subGroupItemDesc = '00000;' + subGroup.description;
            } else {
              item.subGroupItemDesc = '00000;Class NOT Found';
            }
          } else {
            item.subGroupItemDesc = '00000;Class NOT Found';
          }
        } else {
          const priceFileItem = this.estimatingService.allPriceFileItems.find(i => i.priceFileCode === item.recipeCode);
          const subGroup = this.estimatingService.costCentresAndSubGroups.find(i => i.id === priceFileItem?.priceFileItemParentId);
          if (subGroup) {
            const masterGroup = this.estimatingService.costCentresAndSubGroups.find(i => i.id === subGroup?.priceFileItemParentId);
            const masterGroupDesc = masterGroup.priceFileCode
              ? masterGroup.priceFileCode + ' - ' + masterGroup.description : masterGroup.description;

            const subGroupDesc = subGroup.priceFileCode
              ? subGroup.priceFileCode + ' - ' + subGroup.description : subGroup.description;

            item.costCentreId = masterGroup.id; // cost centre id

            item.masterGroupCostCentre
              = ('00000' + masterGroup.orderNumber.toString()).slice(-6) + ';' + masterGroupDesc;
            item.subGroupItemDesc
              = ('00000' + subGroup.orderNumber.toString()).slice(-6) + ';' + subGroupDesc;
          } else {
            item.masterGroupCostCentre = '00000;Cost Centre Not Found';
            item.subGroupItemDesc = '00000;Sub-Group Not Found';
          }
        }
      } else {
        item.masterGroupCostCentre = '100000;Ad-Hoc';
        item.subGroupItemDesc = '000000;Items';
      }
    });

    this.loading = false;
    this.setUpDataSet();
  }

  close() {
    this._activeModal.close(null);
  }

  onToolbarPreparing(e) {
    if (!this.linesExploded) {
      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            text: 'Save selected variations to recipe',
            onClick: this.saveToRecipe.bind(this)
          }
        });
    }

    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'expand',
          onClick: this.expandAll.bind(this),
          matTooltip: 'Collapse All Rows'
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'collapse',
          onClick: this.collapseAll.bind(this),
          matTooltip: 'Collapse All Rows'
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Explode recipes',
          onClick: this.explodeRecipes.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Summary',
          onClick: this.openCostCentreSummaryGrid.bind(this)
        }
      });
  }

  explodeRecipes() {
    let district = this.estimatingService.districts.find(i => i.id === this.districtId);

    if (!district) {
      this.notiService.showWarning('Pricing District NOT defined');
    } else {
      this.allLines.forEach(line => {
        let effectiveDateString = this.utilsService.convertDateToString(this.jobService.currentJobExtra?.estimatingCostingDate, null);

        if (line.jobVariationId) {
          // get the date from the variation
          effectiveDateString = this.utilsService.convertDateToString(this.jobVariations.find(i => i.id === line.jobVariationId)?.estimatingCostingDate, null);
        }

        if (!effectiveDateString) {
          effectiveDateString = formatDate(new Date(), 'yyy-MM-dd');
        }

        const foundRecipe = this.estimatingService.allRecipes.find(i => i.recipeCode === line.recipeCode);
        const recipeUnitOfMeasure = this.estimatingService.unitOfMeasures.find(i => i.description === foundRecipe?.unitOfMeasure);

        if (foundRecipe) {
          // explode it
          this.loading = true;

          this.explodeRecipe(foundRecipe.id).then(
            (res) => {
              const indexNo = this.allLines.findIndex(i => i.id === line.id && i.jobVariationId === line.jobVariationId);
              this.allLines.splice(indexNo, 1);
              this.linesExploded = true;

              res.forEach(recipeLine => {
                // get rate
                let costIsPer = 1;
                let unitOfMeasureDescription = '';
                let lineQty = line.quantity ?? 0;
                if (recipeUnitOfMeasure && recipeUnitOfMeasure.costIsPer) {
                  lineQty /= recipeUnitOfMeasure.costIsPer;
                }

                if (recipeLine.recipeItemId) {
                  recipeLine.rate = this.estimatingService.calcRecipeRate(recipeLine.recipeItemId, district, effectiveDateString, false);
                } else if (recipeLine.priceFileItemId) {
                  recipeLine.rate = this.estimatingService.getDistrictPreferredRate(district, recipeLine.priceFileItemId, effectiveDateString);
                }
                if (recipeLine.unitOfMeasureId) {
                  const unitOfMeasure = this.estimatingService.unitOfMeasures.find(i => i.id === recipeLine.unitOfMeasureId);
                  if (unitOfMeasure && unitOfMeasure.costIsPer) {
                    costIsPer = unitOfMeasure.costIsPer;
                  }
                  unitOfMeasureDescription = unitOfMeasure?.description;
                }
                this.allLines.push(new AllEstimatingItem(line.jobVariationId, recipeLine.recipeCode, recipeLine.description,
                  unitOfMeasureDescription, (recipeLine.quantity ?? 0) * lineQty, recipeLine.rate, costIsPer));
              });

              this.loading = true;
              this.setUpGroupDescs();
            },
            (err) => {

            }
          );
        }
      });
    }
  }

  explodeRecipe(recipeId: number): Promise<RecipeLine[]> {
    return new Promise((resolve, reject) =>
      this.estimatingService.explodeRecipeInMemory(recipeId).subscribe({
        next: (res) => {
          return resolve(res);
        }, error: (err) => {
          return reject(this.globalService.returnError(err));
        }
      }));
  }

  getGroupTitle(cellInfo) {
    const allLinesForGroup = this.allLines.filter(i => i.masterGroupCostCentre === cellInfo.data.key);
    let groupTotal = 0;
    allLinesForGroup.forEach(line => {
      groupTotal += line.lineCost;
    });
    return cellInfo.data.key.split(';')[1] + ' -  Total $' + groupTotal.toFixed(2);
  }

  getCostCentreTitle(data) {
    return data.split(';')[1];
  }

  getSubGroupTitle(cellInfo) {
    return cellInfo.data.key.split(';')[1];
  }

  collapseAll() {
    this.dataGrid.instance.collapseAll();
  }

  expandAll() {
    this.dataGrid.instance.expandAll();
  }

  getRate(data: any) {
    if (data.rate === null) {
      return 'Invalid';
    }
    return data.rate;
  }

  onCellPrepared(e) {
    if (e.rowType === 'data' && e.column.dataField === 'rate' && e.data.rate === null) {
      e.cellElement.style.color = 'red';
    }
  }

  openCostCentreSummaryGrid() {
    const modalRef = this.modalService.open(CostCentreSummaryComponent, { scrollable: true });
    modalRef.componentInstance.costLines = this.allLines;

    modalRef.result.then(() => {
    }, () => {
    });
  }

  saveToRecipe() {
    if (this.selectedRowKeys && this.selectedRowKeys.length) {
      const modalRef = this.modalService.open(MarginSaveToRecipeComponent);
      modalRef.componentInstance.selectedVariationIds = this.selectedRowKeys;
      modalRef.componentInstance.districtId = this.districtId;

      modalRef.result.then(() => {
      }, () => {
      });
    }
  }
}
