import { UserService } from './services/felixApi/user.service';
import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Company } from './dtos/company';
import { AuthService } from './services/auth.service';
import { GlobalService } from './services/global.service';
import { UserTypeEnum } from './dtos/user-type.enum';
import { MENU_HEIGHT, PHONE_SIZE } from '../config/variables';
import { CompanyConfiguration } from './dtos/company-configuration';
import { CompanyService } from './services/felixApi/company.service';
import { NotificationService } from './services/notification.service';
import { ConfigurationEnum } from './dtos/configuration-enum';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { JobItemService } from './services/felixApi/job-item.service';
import { locale } from 'devextreme/localization';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RoleTypeEnum } from './dtos/role-type.enum';
import { AuthApiService } from './services/felixApi/auth-api.service';
import dxDataGrid from 'devextreme/ui/data_grid';

@Component({
  selector: 'js-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public isCollapsed = true;
  subscriptions: Subscription[] = [];
  signedIn = false;
  isIframe = false;
  // companySelected = false;
  currComp: Company;
  currUser: string;
  areaSelected = '';
  superUser = false;
  admin = false;

  hiddenSelections = true;
  hiddenJobs = true;
  hiddenVariations = true;
  hiddenInfo = true;
  hiddenSalesTasks = true;
  hiddenReports = true;
  disabledMenu = false;

  phoneSize: boolean;
  userTypeEnum = UserTypeEnum;
  menuHeight = MENU_HEIGHT;
  companyConfiguration: CompanyConfiguration[];
  isSalesTasksOn = false;

  innerWidth: number;
  @ViewChild('ParentDiv') parentDiv: ElementRef;
  isClientDashboardVisible: boolean;
  waitingAPI: boolean;
  jobsHeading: string;
  isMobile = false;
  isSafari: boolean;
  containerHeight: number;
  isTrackingActive = false;
  addendumName: string;
  isPurchaseOrderSystemActive = false;
  isClient: boolean;

  @HostListener('window:resize') onResize() {
    if (this.parentDiv) { // guard against resize before view is rendered
      setTimeout(() => {
        this.innerWidth = this.parentDiv.nativeElement.clientWidth;
        this.setWidths();
      }, 500); // wait for iPhone
    }
  }

  constructor(
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    public authService: AuthService,
    private authApi: AuthApiService,
    private compService: CompanyService,
    private notiService: NotificationService,
    public _jobItemService: JobItemService,
    private userService: UserService,
    private deviceService: DeviceDetectorService
  ) {
    locale('en-AU'); // can use navigator.language (browser language) but is mostly left on US default

    // get any passed variables
    this.subscriptions = this.subscriptions.concat(
      this.route.queryParamMap.subscribe(params => {
        // console.log(params['params']);
        if (params['params']['reset']) {
          localStorage.clear(); // clear the whole local storage as Safari has trouble with corrupt info
          sessionStorage.clear();
          this.globalService.resetStorage = true;

          for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            const value = localStorage.getItem(key);
            // console.log(key, value);
            this.notiService.showInfo(key + '=' + value);
          }

          for (let i2 = 0; i2 < sessionStorage.length; i2++) {
            const key2 = sessionStorage.key(i2);
            const value2 = sessionStorage.getItem(key2);
            // console.log(key2, value2);
            this.notiService.showInfo(key2 + '=' + value2);
          }
        }
        if (params['params']['debug']) {
          this.globalService.setSessionAtt('isDebugMode', params['params']['debug']);
        }
        if (params['params']['login']) {
          this.globalService.setSessionAtt('login', params['params']['login']);
        }
        if (params['params']['coy']) {
          this.globalService.setSessionAtt('passedCompanyId', params['params']['coy']);
        }
        if (params['params']['job']) {
          this.globalService.setCurrentJob(params['params']['job']);
        }
        if (params['params']['varn']) {
          this.globalService.setSessionAtt('passedVariationNumber', params['params']['varn']);
        }
      })
    );

    this.subscribeToLoginChanges();
    this.subscribeToCompanyChanges();
    this.subscribeToAreaChanges();
    this.subscribeToMenuDisable();

    // this.checkIfIosPWA();

    // data grid default
    dxDataGrid.defaultOptions({
      options: {
        filterRow: { showAllText: '' }
      }
    });
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.innerWidth = window.innerWidth;
    this.setWidths();

    // subscribe to the service to see if we are waiting on another API call to complete
    this._jobItemService.waitingJobItemAPI$.subscribe(
      waitingAPI => {
        this.waitingAPI = waitingAPI;
      });

    if (this.deviceService.browser === 'Safari') {
      this.isSafari = true;
      if (this.deviceService.isMobile || this.deviceService.isTablet) {
        this.isMobile = true;
        this.globalService.isMobile = true;
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setWidths() {
    this.globalService.setInnerWidth(this.innerWidth);
    this.globalService.setInnerHeight(window.innerHeight);

    if (this.innerWidth > 890) {
      this.jobsHeading = 'Job-Details';
    } else {
      this.jobsHeading = 'Jobs';
    }

    this.containerHeight = window.innerHeight - this.menuHeight;

    if (this.innerWidth < PHONE_SIZE) {
      this.phoneSize = true;
    } else {
      this.phoneSize = false;
    }
  }

  // checkIfIosPWA() {
  //   const userAgent = window.navigator.userAgent.toLowerCase();
  //   const isIOS = /iphone|ipad|ipod/.test( userAgent );

  //   const nav = (window.navigator as any);
  //   const alone =  ('standalone' in window.navigator) && (nav.standalone);

  //   if (isIOS && !alone()) {
  //     this.notiService.showInfo('you are in IOS webapp');
  //   }
  // }

  /* If a permissionType exists (null = no access) and a company has been chosen unlock app area */
  updatePermissions(permissions) {
    const companySet = this.globalService.isCompSet();
    const superPerms = (this.superUser || this.admin);
    if (superPerms && companySet) {
      this.superPermissions();
    } else {
      permissions.forEach(perm => {
        const allowed = perm.permissionType;

        switch (perm.applicationArea) {
          case 'Selections': {
            if (allowed && companySet) {
              this.hiddenSelections = false;
            } else {
              this.hiddenSelections = true;
            }
            break;
          }
          case 'Jobs': {
            if (allowed && companySet) {
              this.hiddenJobs = false;
            } else {
              this.hiddenJobs = true;
            }
            break;
          }
          case 'Information': {
            if (allowed && companySet) {
              this.hiddenInfo = false;
            } else {
              this.hiddenInfo = true;
            }
            break;
          }
          case 'Variations': {
            if (allowed && companySet) {
              this.hiddenVariations = false;
            } else {
              this.hiddenVariations = true;
            }
            break;
          }
          case 'Reports': {
            if (allowed && companySet) {
              this.hiddenReports = false;
            } else {
              this.hiddenReports = true;
            }
            break;
          }
          case 'SalesVariations': {
            if (allowed && companySet) {
              this.hiddenVariations = false;
            }
            break;
          }
          case 'PreContractVariations': {
            if (allowed && companySet) {
              this.hiddenVariations = false;
            }
            break;
          }
          case 'SalesTasks': {
            if (allowed && companySet) {
              this.hiddenSalesTasks = false;
            } else {
              this.hiddenSalesTasks = true;
            }
            break;
          }
        }
      });
    }
  }

  menuPress(area) {
    this.globalService.setAreaSelected(area);
  }

  subscribeToAreaChanges() {
    this.globalService.areaChanged.subscribe(area => {
      this.areaSelected = area;
      // this.cd.detectChanges();
    });
  }


  superPermissions() {
    this.hiddenSelections = false;
    this.hiddenJobs = false;
    this.hiddenVariations = false;
    this.hiddenInfo = false;
    this.hiddenReports = false;
    this.getSalesUsersForSalesTasks();
  }

  associatePermissions() {
    this.hiddenSelections = false;
    this.hiddenJobs = true;
    this.hiddenVariations = false;
    this.hiddenInfo = false;
    this.hiddenReports = true;
    this.hiddenSalesTasks = true;
  }

  clientPermissions() {
    this.isClient = true;
    this.hiddenSelections = false;
    this.hiddenJobs = true;
    this.hiddenVariations = false;
    this.hiddenInfo = true;
    this.hiddenReports = true;
    this.hiddenSalesTasks = true;
  }

  getSalesUsersForSalesTasks() {
    if (this.superUser) {
      this.hiddenSalesTasks = false;
    } else {
      // we are an admin user but could be in the sales role
      this.subscriptions = this.subscriptions.concat(
        this.userService.getCompanyRoleUsersByRoleID(RoleTypeEnum.SalesRep)
          .subscribe({
            next: (salesReps) => {
              if (salesReps.find(i => i.userId === this.authService.getCurrentUser()?.id)) {
                this.hiddenSalesTasks = false;
              } else {
                this.hiddenSalesTasks = true;
              }
            },
            error: (err) => {
              this.notiService.notify(err);
              this.hiddenSalesTasks = true;
            }})
      );
    }
  }

  subscribeToLoginChanges() {
    this.authService.isLoggedInChanged.subscribe(
      (isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.signedIn = false;
          this.disabledMenu = true;
        } else {
          this.signedIn = true;
          this.getCompanyConfigurations();
          this.superUser = this.authService.isSuperUser();
          const user = this.authService.getCurrentUser();
          this.currUser = user.firstName + ' ' + user.lastName;
          if (this.superUser) {
            this.superPermissions();
          }
          this.cd.detectChanges();  // update view
        }
      });
  }

  subscribeToCompanyChanges() {
    this.globalService.companySelected.subscribe(selectedComp => {
      this.currComp = selectedComp;
      this.addendumName = this.globalService.getAddendumName();

      if (this.authService.isAdminUser()) {
        this.admin = true;
      } else {
        this.admin = false;
      }
      if (!this.superUser) {
        if (this.currComp.userTypeId === this.userTypeEnum.Associate) {
          this.associatePermissions();
        } else if (this.currComp.userTypeId === this.userTypeEnum.Client) {
          this.clientPermissions();
        } else {
          this.authApi.getAreaPermissions().subscribe(permissions => {
            this.updatePermissions(permissions);
            this.authService.setPermissions(permissions);
          });
        }
      }
      // this.updatePermissions(this.authService.areaPermissions);
      // this.cd.detectChanges(); // update view
    });
  }

  subscribeToMenuDisable() {
    // this.subscriptions = this.subscriptions.concat(
    this.globalService.menuDisable.subscribe(
      disable => {
        if (disable) {
          this.disabledMenu = true;
        } else {
          this.disabledMenu = false;
        }
      }
    );
  }

  getCompanyConfigurations() {
    this.subscriptions = this.subscriptions.concat(
      this.compService.getCompanyConfigurations().subscribe({
        next: (companyConfiguration) => {
          this.companyConfiguration = companyConfiguration;
          this.globalService.companyConfiguration = this.companyConfiguration;

          // check for manual orders
          this.isClientDashboardVisible = false;
          this.companyConfiguration.forEach(config => {
            if (config.configurationId === ConfigurationEnum.ClientDashboardVisible && config.configurationValue === 1) {
              this.isClientDashboardVisible = true;
            }
            if (config.configurationId === ConfigurationEnum.TrackingSystemActive && config.configurationValue === 1) {
              this.globalService.isTrackingActive = true;
              this.isTrackingActive = true;
            }
            if (config.configurationId === ConfigurationEnum.PurchaseOrderSystemActive && config.configurationValue === 1) {
              this.globalService.isPurchaseOrderSystemActive = true;
              this.isPurchaseOrderSystemActive = true;
            }
          });
        },
        error: (err) => {
          this.notiService.notify(err);
        }})
    );
  }

  refresh(): void {
    window.location.replace('/path');
  }

  onShowingPopUp(e) {
    const contentElement = e.component.content();
    contentElement.style.border = 'none';
    contentElement.style.background = 'transparent';
  }

  signOut() {
    this.authService.signOut();
  }
}
