<div class="modal-header">
  <h4 class="modal-title">Statement for job {{jobNumber}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="m-auto">

    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <dx-data-grid *ngIf="!loading" [dataSource]="dataSource" [allowColumnReordering]="false"
      [allowColumnResizing]="true" [height]="gridHeight" [repaintChangesOnly]="true" [showBorders]="true"
      [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
      (onToolbarPreparing)="gridService.addRefreshBtnToToolbar($event)"
      (onExporting)="gridService.onExporting($event, 'Statement', jobNumber)">

      <dxo-sorting mode="single"></dxo-sorting>
      <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-paging [enabled]="false"></dxo-paging>

      <div *dxTemplate="let data of 'refreshTemplate'">
        <div class="m-l-10">
          <dx-button icon="refresh" (onClick)="refresh()"></dx-button>
        </div>
      </div>

      <dxi-column dataField="incomeInvoiceTypeId" caption="Type" [width]="90">
        <dxo-lookup [dataSource]="incomeInvoiceTypes" valueExpr="id" displayExpr="description"> </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="claimNumber" caption="#" [width]="60" alignment="center"> </dxi-column>

      <dxi-column dataField="description" caption="Description"> </dxi-column>

      <dxi-column dataField="claimAmount" dataType="number" [width]="115" format="#,##0.00">
      </dxi-column>

      <dxi-column dataField="totalIncGST" caption="Invoiced" dataType="number" [width]="115" format="#,##0.00">
      </dxi-column>

      <dxi-column dataField="invoiceDate" dataType="date" [width]="110" alignment="center" format="d-MMM-yy">
      </dxi-column>

      <dxi-column dataField="totalPaid" dataType="number" [width]="90" format="#,##0.00">
      </dxi-column>

      <dxi-column dataField="lastPaidDate" caption="Last Paid" dataType="date" [width]="110" alignment="center" format="d-MMM-yy">
      </dxi-column>

      <dxi-column dataField="outstanding" dataType="number" [width]="115"
        [calculateCellValue]='calculateAmountOutstanding' format="#,##0.00">
      </dxi-column>

      <dxo-summary>
        <dxi-total-item column="claimAmount" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
        </dxi-total-item>
        <dxi-total-item column="totalIncGST" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
        </dxi-total-item>
        <dxi-total-item column="totalPaid" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
        </dxi-total-item>
        <dxi-total-item column="outstanding" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}">
        </dxi-total-item>
      </dxo-summary>
    </dx-data-grid>
  </div>
</div>
