import { UserService } from './../../services/felixApi/user.service';
import { NotificationService } from './../../services/notification.service';
import { JobService } from './../../services/felixApi/job.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { GlobalService } from '../../services/global.service';
import { JobItemCostComponent } from '../../selections/selection-modals/job-item-cost/job-item-cost.component';
import { JobEstimatingModalComponent } from '../../jobs/job-modals/job-estimating-modal/job-estimating-modal.component';
import { JobMarkup } from '../../dtos/job-markup';
import ArrayStore from 'devextreme/data/array_store';
import { VariationStatusEnum } from '../../dtos/variation-status.enum';
import { AllEstimateItemsComponent } from '../all-estimate-items/all-estimate-items.component';
import { District } from '../../dtos/district';
import { SalesUpsDownsComponent } from '../sales-ups-downs/sales-ups-downs.component';

@Component({
  selector: 'js-job-margin',
  templateUrl: './job-margin.component.html',
  styleUrls: ['./job-margin.component.css']
})
export class JobMarginComponent implements OnInit, OnDestroy {

  loading = true;
  loadingBaseData = true;
  subscriptions: Subscription[] = [];
  gridHeight: number;
  dataSource: DataSource;
  totalPrice = 0;
  totalManualCost = 0;
  totalMargin = 0;
  statusTypes = [
    { name: '', id: 0 },
    { name: 'Open', id: 1 },
    { name: 'Estimating', id: 2 },
    { name: 'Pending Approval', id: 5 },
    { name: 'Cancelled', id: 6 },
    { name: 'Approved', id: 7 },
    { name: 'Approved', id: 8 },
    { name: 'Approved', id: 9 }
  ];
  phases = [
    { name: 'Base House', id: 0 },
    { name: 'Sales', id: 1 },
    { name: 'Pre-Contract', id: 2 },
    { name: 'Post-Contract', id: 3 }
  ];
  jobNumber: string;
  showBasePrice = true;
  showSalesVariations = true;
  showPreContract = true;
  showPostContract = true;
  jobMargins: JobMarkup[] = [];
  filteredJobMargins: JobMarkup[];
  effectiveDate: any;
  districtId: number;
  totalNewCost: number;
  markupPercent = 0;
  newMarkupPercent: number;
  totalCostForOrderedMarkup: number;
  orderedMarkupPercent: number;
  marginPercent = 0;
  newMarginPercent = 0;
  orderedMarginPercent: number;
  includeOpenVariations = true;
  ignoreEstimatingExtra = false;
  totalNewMargin: number;
  districts: District[];

  constructor(private _activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private jobService: JobService,
    private estimatingService: EstimatingService,
    private notiService: NotificationService,
    private userService: UserService,
    private modalService: NgbModal) {
    this.editItem = this.editItem.bind(this);
  }

  ngOnInit() {
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerWidthChanged.subscribe(() => {
        setTimeout(() => {
          this.setHeight();
        }, 500); // wait for iPhone
      })
    );
    this.setHeight();

    this.effectiveDate = new Date();
    this.getBaseData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setHeight() {
    if (this.totalCostForOrderedMarkup) {
      this.gridHeight = this.globalService.innerHeight - 520; // was 480
    } else {
      this.gridHeight = this.globalService.innerHeight - 470; // was 430
    }
  }

  getBaseData() {
    this.subscriptions = this.subscriptions.concat(
      this.estimatingService.getEstimatingData(true)
        .subscribe({
          next: () => {
            this.districts = this.estimatingService.districts?.filter(i => i.isActive);

            if (this.districts?.length === 1) {
              this.districtId = this.districts[0].id;
            }
            this.loadingBaseData = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.jobMargins = [];
            this.filteredJobMargins = [];
            this.loadingBaseData = false;
          }
        })
    );
  }

  refreshJobNumber(jobNo: string) {
    this.jobNumber = jobNo;

    if (jobNo) {

      // may need to set the default
      if (this.districts?.length === 1) {
        this.districtId = this.districts[0].id;
      } else if (this.jobService.currentJobExtra?.districtId) {
        this.districtId = this.jobService.currentJobExtra?.districtId;
      } else {
        const division = this.userService.divisions.find(i => i.isActive && i.id === this.jobService.currentJob.divisionId);
        this.districtId = division?.defaultDistrictId ?? this.districts.find(i => i.isActive && !i.districtParentId).id;
      }

      if (this.jobService.currentJob?.id && this.districtId) {
        this.getData();
      }
    }
  }

  getData() {
    if (this.districtId && this.jobService.currentJob) {
      this.loading = true;
      const effectiveDateString = this.effectiveDate.getFullYear()
        + ('0' + (this.effectiveDate.getMonth() + 1)).toString().slice(-2)
        + ('0' + this.effectiveDate.getDate()).slice(-2);

      this.subscriptions = this.subscriptions.concat(
        this.estimatingService.getJobMarkup(this.jobService.currentJob.id, effectiveDateString,
          this.districtId, this.ignoreEstimatingExtra)
          .subscribe({
            next: (res) => {
              this.jobMargins = res;
              this.loading = false;
              this.getRecords();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.jobMargins = [];
              this.filteredJobMargins = [];
              this.loading = false;
            }
          })
      );
    }
  }

  getRecords() {
    this.totalPrice = 0;
    this.totalManualCost = 0;
    this.markupPercent = 0;
    this.totalNewCost = 0;
    this.newMarkupPercent = 0;
    this.marginPercent = 0;
    this.newMarginPercent = 0;
    this.filteredJobMargins = [];

    this.jobMargins.forEach(item => {
      if ((this.showBasePrice && item.variationPhaseId === 0)
        || (this.showSalesVariations && item.variationPhaseId === 1)
        || (this.showPreContract && item.variationPhaseId === 2)
        || (this.showPostContract && item.variationPhaseId === 3)) {

        if (item.statusId !== VariationStatusEnum.Cancelled) {
          if (this.includeOpenVariations || item.statusId >= VariationStatusEnum.Approved) {
            this.totalPrice += (item.variationTotal ? item.variationTotal : 0);
            this.totalManualCost += (item.manualCost ? item.manualCost : 0);
            this.totalNewCost += (item.currentCost ? item.currentCost : 0);
          }
        }
        this.filteredJobMargins.push(item);
      }
    });

    const totalPriceExGST = this.totalPrice * this.estimatingService.gstRate / ((this.estimatingService.gstRate / 10) + 10);
    this.totalMargin = totalPriceExGST - this.totalManualCost;

    if (this.totalManualCost) {
      this.markupPercent = (this.totalMargin / this.totalManualCost) * 100;
    }

    if (totalPriceExGST) {
      this.marginPercent = (this.totalMargin / totalPriceExGST) * 100;
    }

    this.totalNewMargin = totalPriceExGST - this.totalNewCost;

    if (this.totalNewCost) {
      this.newMarkupPercent = (this.totalNewMargin / this.totalNewCost) * 100;
    }

    if (this.totalNewCost && totalPriceExGST) {
      this.newMarginPercent = (this.totalNewMargin / totalPriceExGST) * 100;
    }

    // get ordered amounts
    this.totalCostForOrderedMarkup = 0;
    this.orderedMarkupPercent = 0;
    this.orderedMarginPercent = 0;

    this.jobMargins.forEach(item => {
      if (item.variationPhaseId === -1) {
        this.totalCostForOrderedMarkup = item.currentCost;
      }
    });

    if (this.totalCostForOrderedMarkup) {
      this.orderedMarkupPercent = (totalPriceExGST - this.totalCostForOrderedMarkup) / this.totalCostForOrderedMarkup * 100;

      if (totalPriceExGST) {
        this.orderedMarginPercent = (totalPriceExGST - this.totalCostForOrderedMarkup) / totalPriceExGST * 100;
      }
    }

    this.setHeight();
    this.setUpDataSet();
  }

  setUpDataSet() {
    this.dataSource = new DataSource({
      key: 'jobVariationId',
      load: () => this.filteredJobMargins,
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.estimatingService.updateJobVarItemCost(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  close() {
    this._activeModal.close(null);
  }

  isEditIconVisible = (e) => {
    return e.row.data.itemTypeId !== 1 && !e.row.data.hasLinkedItems;
  }

  onCellPrepared(e) {
    if (e.rowType === 'data') {
      if (e.data.statusId < VariationStatusEnum.Approved && e.column.dataField === 'statusId') {
        e.cellElement.classList.add('red');
      }

      if (e.data.isItemsNoCost && e.column.dataField === 'manualCost') {
        e.cellElement.classList.add('redBackground');
      }
    }
  }

  // isEditButtonVisible(e) {
  //   return e.row.data.jobVariationId;
  // }

  editItem(e) {
    if (e.row.data.jobVariationId) {
      // here we use the modal to check all items have a cost so margins work
      this.jobService.currentVariationId = e.row.data.jobVariationId;

      const modalRef = this.modalService.open(JobItemCostComponent,
        { windowClass: 'modal-est2', scrollable: true });
      modalRef.componentInstance.jobVariationId = e.row.data.jobVariationId;
      modalRef.componentInstance.variationNumber = e.row.data.variationNumber;
      modalRef.componentInstance.displayedVariationNumber = e.row.data.displayedVariationNumber;
      modalRef.componentInstance.adminFee = e.row.data.adminFee;
      modalRef.componentInstance.variationStatusId = e.row.data.statusId;

      modalRef.result.then(() => {
        this.getData();
      }, () => {
      });
    } else {
      const modalRef = this.modalService.open(JobEstimatingModalComponent,
        { windowClass: 'modal-est2', scrollable: true });
      modalRef.componentInstance.fromFuelGauge = true;

      modalRef.result.then(() => {
        this.getData();
      }, () => {
        this.getData();
      });
    }
  }

  showBasePriceChanged(e) {
    this.showBasePrice = e.value ? true : false;
    this.getRecords();
  }

  showSalesVariationsChanged(e) {
    this.showSalesVariations = e.value ? true : false;
    this.getRecords();
  }

  showPostContractChanged(e) {
    this.showPostContract = e.value ? true : false;
    this.getRecords();
  }

  showPreContractChanged(e) {
    this.showPreContract = e.value ? true : false;
    this.getRecords();
  }

  includeOpenVariationsChanged(e) {
    this.includeOpenVariations = e.value ? true : false;
    this.getRecords();
  }

  ignoreEstimatingExtraChanged(e) {
    this.ignoreEstimatingExtra = e.value ? true : false;
    this.getData();
  }

  onToolbarPreparing(e, templateName: string, templateName1: string, templateName2: string, templateName3: string, templateName4: string, template6: string) {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName
    });
    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName1
    });
    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName2
    });
    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName3
    });
    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: templateName4
    });
    toolbarItems.push({
      location: 'before',
      locateInMenu: 'auto',
      template: template6
    });
  }

  openAllEstimatingGrid() {
    const modalRef = this.modalService.open(AllEstimateItemsComponent,
      { windowClass: 'modal-est', scrollable: true });
    modalRef.componentInstance.districtId = this.districtId;

    modalRef.result.then(() => {
      this.getData();
    }, () => {
      this.getData();
    });
  }

  getSalesUpsDowns() {
    const modalRef = this.modalService.open(SalesUpsDownsComponent,
      { windowClass: 'modal-est', scrollable: true });
    modalRef.componentInstance.jobId = this.jobService.currentJob.id;

    modalRef.result.then(() => {
      this.getData();
    }, () => {
      this.getData();
    });
  }
}
