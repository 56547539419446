import { JobService } from './../../services/felixApi/job.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { NotificationService } from './../../services/notification.service';
import { AllEstimatingItem } from '../../dtos/all-estimating-item';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { CostCentreSummary } from '../../dtos/cost-centre-summary';

@Component({
  selector: 'js-cost-centre-summary',
  templateUrl: './cost-centre-summary.component.html',
  styleUrls: ['./cost-centre-summary.component.css']
})
export class CostCentreSummaryComponent implements OnInit, OnDestroy {
  @Input() costLines: AllEstimatingItem[];

  subscriptions: Subscription[] = [];
  gridHeight: number;
  dataSource: DataSource;
  costCentreSummaries: CostCentreSummary[] = [];
  loading: boolean;

  constructor(private _activeModal: NgbActiveModal,
    private jobService: JobService,
    private estimatingService: EstimatingService,
    private globalService: GlobalService,
    private notiService: NotificationService) {
    this.storeValues = this.storeValues.bind(this);
  }

  ngOnInit() {
    this.subscribeToWidthChanges();
    this.setHeight();
    this.setUpDataSet();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  subscribeToWidthChanges() {
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerHeightChanged.subscribe(() => {
        this.setHeight();
      })
    );
  }

  setHeight() {
    this.gridHeight = this.globalService.innerHeight - 300;
  }

  setUpDataSet() {
    this.costLines.forEach(costLine => {
      const thisCostCode = this.estimatingService.costCentresAndSubGroups.find(i => i.id === costLine.costCentreId);
      const existingEntry = this.costCentreSummaries.find(i => i.costCode === (thisCostCode?.priceFileCode ?? costLine.masterGroupCostCentre.split(';')[1]));

      if (existingEntry) {
        existingEntry.total += (costLine.lineCost ?? 0);
      } else {
        this.costCentreSummaries.push({
          costCentreId: costLine.costCentreId,
          costCode: thisCostCode?.priceFileCode ?? costLine.masterGroupCostCentre.split(';')[1],
          description: thisCostCode?.description,
          total: costLine.lineCost ?? 0
        });
      }
    });

    this.dataSource = new DataSource({
      key: 'costCode',
      load: () => this.costCentreSummaries
    });
  }

  close() {
    this._activeModal.close(null);
  }

  getRate(data: any) {
    if (data.rate === null) {
      return 'Invalid';
    }
    return data.rate;
  }

  onCellPrepared(e) {
    if (e.rowType === 'data' && e.column.dataField === 'rate' && e.data.rate === null) {
      e.cellElement.style.color = 'red';
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          text: 'Store',
          onClick: this.storeValues.bind(this)
        }
      });
  }

  storeValues() {
    const anyRecipes = this.costCentreSummaries.find(i => !i.costCentreId && i.costCode === 'RECIPES');

    if (anyRecipes) {
      this.notiService.showError('All recipes need to exist and be exploded');
    } else {
      const anyAdhoc = this.costCentreSummaries.find(i => !i.costCentreId && i.total);

      if (anyAdhoc) {
        this.notiService.showError('Adhoc items are not allowed when storing values');
      } else {
        this.loading = true;
        const costCentreIds: number[] = [];
        const totals: number[] = [];

        this.costCentreSummaries.forEach(costCentre => {
          if (costCentre.total) {
            costCentreIds.push(costCentre.costCentreId);
            totals.push(costCentre.total);
          }
        });
        this.subscriptions = this.subscriptions.concat(
          this.estimatingService.addJobEstimateSummaries(this.jobService.currentJob?.id, costCentreIds, totals).subscribe({
            next: () => {
              this.notiService.showSuccess('Cost Centre Summary stored');
              this.loading = false;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
        );
      }
    }
  }
}
