import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ClientWelcome, ClientWelcomeAttachment } from '../../dtos/client-welcome';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class ClientWelcomeService {

  baseURL = environment.apiUrl + '/client-selection-welcomes/';

  cachedClientWelcome: ClientWelcome;
  cachedAttachment;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService
  ) { }

  fetchClientWelcome(): Observable<[ClientWelcome, ClientWelcomeAttachment]> {
    return this.getClientWelcome().pipe(
      mergeMap(cw => {
        if (cw.hasBlob) {
          return zip(of(cw), this.getClientWelcomeAttachment(cw.id));
        } else {
          return zip(of(cw), of(null));
        }
      })
    );
  }

  getClientWelcome(): Observable<ClientWelcome> {
    if (this.cachedClientWelcome) {
      return of(this.cachedClientWelcome);
    } else {
      return this.http.get<ClientWelcome>(this.baseURL, this.httpService.getHttpOptions())
        .pipe(tap(res => {
          this.cachedClientWelcome = res;
        })
        );
    }
  }

  getClientWelcomeAttachment(cwId: string): Observable<ClientWelcomeAttachment> {
    if (this.cachedAttachment) {
      return of(this.cachedAttachment);
    } else {
      const url = this.baseURL + cwId + '/attachments';
      return this.http.get<ClientWelcomeAttachment>(url, this.httpService.getHttpFileOptions())
        .pipe(tap(res => {
          this.cachedAttachment = res;
        })
        );
    }
  }

}
