import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { GlobalService } from '../global.service';
import { IOptionListAttachment } from '../../dtos/option-list-attachment';
import { IOptionImage } from '../../dtos/option-image';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class OptionListAttachmentService {

  cache: IOptionListAttachment[] = [];

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  getOptionListAttachment(id): Observable<IOptionListAttachment> {
    const img = this.cache.filter(i => i.optionListId === id);
    if (img[0]) {
      return of(img[0]);
    } else {
      const options = this.httpService.getHttpFileOptions();
      return this._http.get<IOptionListAttachment>(this.globalService.getApiUrl() + '/option-list-attachments/' + id, options)
        .pipe(
          tap(res => this.cache = this.cache.concat(res)),
          catchError(this.handleError)
        );
    }
  }

  getOptionListLibrary(): Observable<IOptionImage[]> {
    const options = this.httpService.getHttpFileOptions();
    return this._http.get<IOptionImage[]>(this.globalService.getApiUrl() + '/option-images', options)
      .pipe(
        catchError(this.handleError.bind(this)));
  }

  // getOptionListLibraryImage(id): Observable<IOptionImage> {
  //   const options = this.globalService.getHttpFileOptions();
  //   return this._http.get<IOptionImage>(this.globalService.getApiUrl() + '/option-images/' + id, options)
  //     .pipe(
  //       catchError(this.handleError.bind(this)));
  // }

  postOptionListAttachment(optionId, imageFile) {
    const options = this.httpService.getHttpFileOptions();
    // options.headers.delete('Content-Type'); // won't work?
    return this._http.post(this.globalService.getApiUrl() + '/option-list-attachments/' + optionId, imageFile, options)
      .pipe(
        catchError(this.handleError.bind(this)));
  }

  patchOptionListAttachment(attachmentId, imageFile) {
    const options = this.httpService.getHttpFileOptions();
    return this._http.patch(this.globalService.getApiUrl() + '/option-list-attachments/' + attachmentId, imageFile, options)
      .pipe(
        catchError(this.handleError.bind(this)));
  }

  deleteOptionListAttachment(id) {
    const options = this.httpService.getHttpFileOptions();
    return this._http.delete(this.globalService.getApiUrl() + '/option-list-attachments/' + id, options)
      .pipe(
        catchError(this.handleError.bind(this)));

  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}


