<div class="modal-header">
  <h4 class="modal-title" placement="bottom">Add client login</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="formClass">
  <div *ngIf="!loading">
    <dx-form id="form" [(formData)]="userForm" [readOnly]="false" [showColonAfterLabel]="true"
      validationGroup="formData" labelLocation="left" [minColWidth]="500">
      <dxi-item dataField="email" editorType="dxTextBox"> </dxi-item>
      <dxi-item dataField="firstName" editorType="dxTextBox"> </dxi-item>
      <dxi-item dataField="lastName" editorType="dxTextBox"> </dxi-item>
    </dx-form>

    <dx-button text="Add" class="mt-1" type="default" (onClick)="addUser()">
    </dx-button>
  </div>
  
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>
</div>
