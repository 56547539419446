import { JobService } from './../../../services/felixApi/job.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from '../../../services/global.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../../services/notification.service';
import { JobDocumentAttachmentService } from '../../../services/felixApi/job-document-attachment.service';

@Component({
  selector: 'js-add-documents',
  templateUrl: './add-documents.component.html',
  styleUrls: ['./add-documents.component.scss']
})
export class AddDocumentsComponent implements OnInit, OnDestroy {
  @Input() selectedFolder: string;

  subscriptions: Subscription[] = [];
  loadingDocs: boolean = false;
  popupWidth: number;
  popupHeight: number;
  fileValue: any[] = [];
  numberOfDocs: number;

  constructor(
    private globalService: GlobalService,
    private jobService: JobService,
    private jobDocumentAttachmentService: JobDocumentAttachmentService,
    private activeModal: NgbActiveModal,
    private notiService: NotificationService
  ) { }

  ngOnInit() {
    this.popupHeight = this.globalService.innerHeight < 575 ? this.globalService.innerHeight : 575;
    this.popupWidth = this.globalService.innerWidth < 650 ? this.globalService.innerWidth : 650;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  save() {
    this.loadingDocs = true;
    this.numberOfDocs = this.fileValue.length;
    this.loadFile(0);
  }

  loadFile(fileId: number) {
    const formData: FormData = new FormData();
    formData.append('image', this.fileValue[fileId], this.fileValue[fileId].name);

    this.subscriptions.push(
      this.jobDocumentAttachmentService.uploadDocument(this.selectedFolder, formData, this.jobService.currentJob.jobNumber)
        .subscribe({
          next: () => {
            this.numberOfDocs--;
            if (this.numberOfDocs === 0) {
              this.activeModal.close();
            } else {
              this.loadFile(fileId + 1);
            }
          }, error: (err) => {
            this.notiService.notify(err);
            this.loadingDocs = false;
          }
        })
    );
  }

  close() {
    this.activeModal.dismiss();
  }

  getFileNames() {
    let result = '';
    this.fileValue.forEach(x => {
      result += x.name + '; ';
    });
    return result;
  }
}
