import { Component, OnInit, Input, OnDestroy, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IOptionListHeader } from '../../../dtos/option-list-header';
import { JobItemService } from '../../../services/felixApi/job-item.service';
import { GlobalService } from '../../../services/global.service';
import { Subscription } from 'rxjs';
import { IJobItem } from '../../../dtos/job-item';
import { OptionTypeEnum } from '../../../dtos/option-type.enum';

export class GridOption extends IOptionListHeader {
  colSpan: number;
  rowSpan: number;
}
export class OptionsByHeader {
  header: IOptionListHeader;
  subOptions: GridOption[];
}

@Component({
  selector: 'js-image-select-modal',
  templateUrl: './image-select-modal.component.html',
  styleUrls: ['./image-select-modal.component.scss']
})
export class ImageSelectModalComponent implements OnInit, OnDestroy {
  @Input() jobitem: IJobItem;
  // @Input() optionListId: number;
  // @Input() canEnterManualSelection: boolean;
  @Input() previousCostTypeId: number;
  @Input() previousItemRate: number;
  @Input() isQtyRequired: boolean;
  @Input() previousSelectedOption: IOptionListHeader;
  // @Input() currentSelectedOption: IOptionListHeader;
  @Input() prevQty: number;

  loading = true;
  innerWidth: number;
  numCollumns = 3;
  subscriptions: Subscription[] = [];
  tileHeight: number;
  tileWidth: number;
  modalWidth: any;
  optionTypeEnum = OptionTypeEnum;
  allOptions: IOptionListHeader[];
  optionsByHeaders: OptionsByHeader[] = [];
  selectedOption: IOptionListHeader;
  gridHeight: number;
  manualSelection: boolean;

  @HostListener('window:resize') onResize() {
    this.setColumns();
  }

  constructor(
    private _activeModal: NgbActiveModal,
    private _globalService: GlobalService,
    private _jobItemService: JobItemService) { }

  ngOnInit() {
    this.allOptions = this._jobItemService.getOptionListItems(this.jobitem.optionListId)
      .filter(i => i.optionTypeId !== this.optionTypeEnum.ToBeSelectedAtPreStart);

    this.subscribeToWidthChanges();
    this.setUpGroupedOptions();
    this.setColumns();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  // ngAfterViewInit() {
  //   this.setColumns();
  // }

  subscribeToWidthChanges() {
    this.subscriptions = this.subscriptions.concat(
      this._globalService.selectonsInnerWidthChanged.subscribe(width => {
        this.setColumns();
      })
    );
  }

  setUpGroupedOptions() {
    this.allOptions.forEach(option => {
      if (option.notSelectable) {
        const newOptionsByHeader = new OptionsByHeader();
        newOptionsByHeader.header = option;
        newOptionsByHeader.subOptions = [];
        this.optionsByHeaders = this.optionsByHeaders.concat(newOptionsByHeader);
      } else {
        const gridOption = (option as GridOption);
        gridOption.colSpan = 1;
        gridOption.rowSpan = 2;
        const prevHeader = this.optionsByHeaders[this.optionsByHeaders.length - 1];
        if (prevHeader) {
          prevHeader.subOptions = prevHeader.subOptions.concat(gridOption);
        } else {
          const newOptionsByHeader = new OptionsByHeader();
          newOptionsByHeader.header = null;
          newOptionsByHeader.subOptions = [gridOption];
          this.optionsByHeaders = this.optionsByHeaders.concat(newOptionsByHeader);
        }
      }
    });
  }

  setColumns() {
    this.loading = true;
    this.innerWidth = this._globalService.innerWidth;
    this.modalWidth = this.innerWidth * 0.98;
    if (this.modalWidth > 930) {
      this.modalWidth = 930;
    }

    if (this.innerWidth > 800) {
      this.numCollumns = 3;
    } else if (this.innerWidth > 600) {
      this.numCollumns = 2;
    } else {
      this.numCollumns = 1;
    }

    this.tileWidth = (this.modalWidth - 32) / this.numCollumns;
    this.tileHeight = this.tileWidth * 2 / 3;
    this.gridHeight = window.innerHeight - 200;
    this.loading = false;
  }

  selectOption(selectedOption: IOptionListHeader) {
    if (!selectedOption.notSelectable) {
      this.manualSelection = false;
      this.selectedOption = selectedOption;
    }
  }

  manualSelect() {
    this.selectedOption = null;
    this.manualSelection = true;
  }

  cancel() {
    this._activeModal.dismiss();
  }

  closeOk() {
    this._activeModal.close(this.selectedOption ? this.selectedOption : -1);
  }
}
