import { Injectable } from '@angular/core';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { IJobCustomer } from '../../dtos/job-customer';
import { HttpService } from '../http.service';

@Injectable()
export class JobCustomerService {

  COMPONENT_NAME = 'JobCustomerService';

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }

  getJobCustomers(jobNo: string): Observable<IJobCustomer[]> {
    const url = this.globalService.getApiUrl() + '/jobs/' + jobNo + '/job-customers';
    return this._http.get<IJobCustomer[]>(url, this.httpService.getHttpOptions()).pipe(catchError(this.handleError));
  }

  postJobCustomer(jobNo: string, email: string, sendInfoUpdateEmail: boolean) {
    const url = this.globalService.getApiUrl() + '/job-customers';
    return this._http.post(url, JSON.stringify({ jobNumber: jobNo, emailAddress: email, sendInfoUpdateEmail: sendInfoUpdateEmail }), this.httpService.getHttpOptions());
  }

  patchJobCustomer(jobCustomerId: number, email: string, sendInfoUpdateEmail: boolean): Observable<IJobCustomer[]> {
    const url = this.globalService.getApiUrl() + '/job-customers/' + jobCustomerId;
    return this._http.patch<IJobCustomer[]>(url, JSON.stringify({ emailAddress: email, sendInfoUpdateEmail: sendInfoUpdateEmail }), this.httpService.getHttpOptions());
  }

  deleteJobCustomer(jobCustomerId: number) {
    const url = this.globalService.getApiUrl() + '/job-customers/' + jobCustomerId;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  getJobCustomerLastLoggedIn(jobCustomerId: number): Observable<Date> {
    const url = this.globalService.getApiUrl() + '/job-customers/' + jobCustomerId + '/last-logged-in';
    return this._http.get<Date>(url, this.httpService.getHttpOptions());
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
