import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }


  sendVariationStatusEmail(variationId: number) {
    const url = this.globalService.getApiUrl() + '/emails/job-variations/' + variationId;
    return this._http.post(url, JSON.stringify(variationId), this.httpService.getHttpOptions());
  }
}
