<div class="modal-header">
  <h4 class="modal-title"><span *ngIf="!isHubSpotActive">Active Campaign</span><span *ngIf="isHubSpotActive">HubSpot</span> Deal</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <div *ngIf="loading">Loading records from <span *ngIf="!isHubSpotActive">Active Campaign</span><span *ngIf="isHubSpotActive">HubSpot</span>. Please wait...</div>

  <js-loading-spinner *ngIf="loadingData"></js-loading-spinner>

  <div *ngIf="!loadingData" class="m-auto">
    <div class="form-group row">
      <label class="col-sm-2 control-label-sm righttext">Title</label>
      <dx-text-box class="col-sm-10 px-0" id="title" [value]="leadManagementDeal.title" readOnly="true"></dx-text-box>          
    </div>
    <div class="form-group row">
      <label class="col-sm-2 control-label-sm righttext">Stage</label>
      <dx-text-box class="col-sm-4 px-0" id="stage" [value]="leadManagementDeal.stageTitle" readOnly="true"></dx-text-box>
      <label class="col-sm-2 control-label-sm righttext">Sales Consultant</label>
      <dx-text-box class="col-sm-4 px-0" id="salesConsultant" [value]="leadManagementDeal.repName" readOnly="true"></dx-text-box>          
    </div>
    <div class="form-group row">
      <label class="col-sm-2 control-label-sm righttext">Main Contact</label>
      <dx-text-box class="col-sm-4 px-0" id="mainContact" [value]="leadManagementDeal.name" readOnly="true"></dx-text-box>
      <label class="col-sm-2 control-label-sm righttext">Description</label>
      <dx-text-box class="col-sm-4 px-0" id="description" [value]="leadManagementDeal.description" readOnly="true"></dx-text-box>
    </div>

    <div class="form-group row">
      <dx-data-grid [dataSource]="dataSource" [allowColumnReordering]="false" [allowColumnResizing]="false"
        [height]="gridHeight" [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true"
        [wordWrapEnabled]="true" [focusedRowEnabled]="true" [(focusedRowKey)]="selectedRecord"
        [autoNavigateToFocusedRow]="true" [selectedRowKeys]="selectedRecords" [hoverStateEnabled]="true"
        (onToolbarPreparing)="onToolbarPreparing($event)">

        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
        <dxo-scrolling mode="virtual"></dxo-scrolling>
        <dxo-export [enabled]="true"></dxo-export>
        <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>

        <dxi-column dataField="displayOrder" [visible]="false" sortOrder="asc"> </dxi-column>
        <dxi-column dataField="fieldName" caption="Field"> </dxi-column>
        <dxi-column dataField="fieldValue"> </dxi-column>
      </dx-data-grid>
    </div>
    
    <dx-button *ngIf="isAdmin && leadManagementDealId && !importClicked" id="downloadButton" text="Import Data"
      class="mt-1" type="danger" stylingMode="default" (onClick)="importData(false)">
    </dx-button>

    <div *ngIf="importClicked">
      Are you sure? Import will override all data it can.
      <dx-button id="downloadButton" text="Import Data" class="mt-1 ms-2" type="danger" stylingMode="default"
        (onClick)="importData(false)">
      </dx-button>

      <dx-button id="downloadButton" text="Import Only Job-Data" class="mt-1 ms-2" type="danger" stylingMode="default"
        (onClick)="importData(true)">
      </dx-button>
    </div>
    
    <dx-button *ngIf="jobId" text="Disconnect" class="mt-1 ms-3"  type="danger" stylingMode="default"
      (onClick)="disconnect()">
    </dx-button>
  </div>
</div>

<div class="modal-footer">
</div>
