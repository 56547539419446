<dx-data-grid #dataGrid [dataSource]="jobData" [remoteOperations]="false" [showBorders]="true"
  [focusedRowEnabled]="true" [showRowLines]="true" [hoverStateEnabled]="true" [wordWrapEnabled]="true"
  [rowAlternationEnabled]="false" [scrolling]="{ mode: 'virtual', preloadEnabled: true }" class="callups">

  <dxo-paging [enabled]="false"></dxo-paging>
  <dxo-selection mode="single"></dxo-selection>

  <dxi-column dataField="jobId" [visible]='false'></dxi-column>

  <dxi-column dataField="jobNumber" [width]="130" alignment="center" sortOrder="asc"></dxi-column>

  <dxi-column dataField="jobAddressString" caption='Site Address'></dxi-column>

  <dxi-column dataField="houseTypeId" caption="Call-Up Date" dataType="number" width="15%"> </dxi-column>
</dx-data-grid>
