<div *ngIf="!isClientOrAssociate && !smallScreen && !loading" class="filterDiv" [ngClass]="{'filter-sticky' : desktop}">

  <div *ngIf="permissionAdmin && !smallScreen" class="filter-bar-add">
    <button class='btn btn-sm btn-outline-primary' (click)='addDocument(null, false)'>Add</button>
  </div>

  <!-- filter by type -->
  <mat-form-field *ngIf="jobDocumentTypes && jobDocumentTypes.length" class="filterType">
    <mat-label>Type Filter</mat-label>
    <mat-select [formControl]="filterTypes" (selectionChange)="runFilter(tree.treeModel)" multiple>
      <mat-option *ngFor="let jobDocumentType of jobDocumentTypes" [value]="jobDocumentType.id">
        {{jobDocumentType.description}}</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- filter by status -->
  <mat-form-field *ngIf="jobDocumentTypes && jobDocumentTypes.length" class="filterType">
    <mat-label>Status Filter</mat-label>
    <mat-select [formControl]="filterStatus" (selectionChange)="runFilter(tree.treeModel)" multiple>
      <mat-option *ngFor="let jobDocumentStatus of jobDocumentStatuses" [value]="jobDocumentStatus.id">
        {{jobDocumentStatus.description}}</mat-option>
    </mat-select>
  </mat-form-field>

  <input *ngIf="jobDocumentService.treeNodes && jobDocumentService.treeNodes.length" id="filter" #filter
    (keyup)="runFilter(tree.treeModel)" placeholder="Search..." [(ngModel)]="filterText" type="text"
    class="form-control filterText" />

  <!-- clear filter -->
  <dx-button *ngIf="jobDocumentService.treeNodes && jobDocumentService.treeNodes.length" icon="close" type="default"
    stylingMode='outlined' (onClick)="clearunFilter(tree.treeModel)">
  </dx-button>

  <!-- Roles -->
  <dx-button id="rolesButton" icon="user" type="default" stylingMode='outlined' (onClick)="showJobRoles()">
  </dx-button>

  <!-- We can send an email -->
  <dx-button id="emailButton" text="Email" icon="email" type="default" stylingMode='outlined' (onClick)="createEmail()">
  </dx-button>
</div>
<br>
<!-- <ng-template #noFilterDiv>
  <div></div>
  <br>
</ng-template> -->

<div *ngIf="permissionAdmin && smallScreen">
  <button class='btn btn-sm btn-outline-primary btnadd' (click)='addDocument(null, false)'>Add</button>
</div>

<div *ngIf="!loading" class="mainTreeDiv" [style.height.px]="treeHeight">
  <tree-root #tree id="tree" *ngIf="jobDocumentService.treeNodes && jobDocumentService.treeNodes.length"
    [focused]="true" [nodes]="jobDocumentService.treeNodes" [options]="treeOptions" (moveNode)="onMoveNode($event)"
    (copyNode)="onCopyNode()">

    <ng-template #treeNodeTemplate let-node="node" let-index="index" class="descClass">
      <button *ngIf="node.data.recordTypeId === jobDocumentRecordTypeEnum.AddButton && permissionWrite"
        class='btn btn-sm btn-outline-primary btnadd'
        (click)='addDocument(node.data.parentId, node.data.isSharePoint, node.data.sharePointId)'>{{node.data.description}}</button>
      <span *ngIf="node.data.recordTypeId !== jobDocumentRecordTypeEnum.AddButton">
        <div class="descClass" [class.black]="hasChildren(node.data)" [class.isDeleted]="node.data.isDeleted"
          [ngStyle]="{'max-width': 'calc(' + descWidth + 'px)'}">{{node.data.description}}</div>
      </span>
      <span *ngIf="node.data.recordTypeId !== jobDocumentRecordTypeEnum.AddButton">
        <span *ngIf="!isClientOrAssociate && node.data.isCustomerViewable && !smallScreen" class="externalViewable">
          Client
        </span>
        <span *ngIf="!isClientOrAssociate && node.data.isCustomerViewable && node.data.isTradeViewable && !smallScreen"
          class="externalViewable">
          &
        </span>
        <span *ngIf="!isClientOrAssociate && node.data.isTradeViewable && !smallScreen" class="externalViewable">
          Trade
        </span>
        <span *ngIf="!isClientOrAssociate && (node.data.isCustomerViewable || node.data.isTradeViewable)"
          class="externalViewable">
          Viewable
        </span>
        <span *ngIf="!isClientOrAssociate && node.data.jobDocumentStatusId === jobDocumentStatusEnum.Pending"
          class="pendingTask">
          Pending
        </span>
        <span
          *ngIf="!isClientOrAssociate && node.data.jobDocumentStatusId === jobDocumentStatusEnum.OutstandingHighPriority"
          class="pendingTask">
          Outstanding
        </span>
        <span *ngIf="!isClientOrAssociate && (node.data.jobDocumentStatusId === jobDocumentStatusEnum.Completed)"
          class="completedTask">
          Completed / Yes
        </span>
        <span *ngIf="!isClientOrAssociate && (node.data.jobDocumentStatusId === jobDocumentStatusEnum.NotApplicable)"
          class="completedTask">
          Not Applicable
        </span>
        <button *ngIf="node.data.isStatusRequired && node.data.jobDocumentStatusId !== 4 && permissionWrite"
          class="btn btn-sm btn-outline-primary markComplete"
          (click)='$event.preventDefault(); $event.stopPropagation(); markComplete(node.data)'>
          Mark<i class="material-icons">done</i>
        </button>
        <button
          *ngIf="node.data.recordTypeId !== jobDocumentRecordTypeEnum.AddButton && permissionWrite && !node.data.isSharePoint"
          class="btn btn-sm btn-outline-primary arrows"
          (click)='$event.preventDefault(); $event.stopPropagation(); editItem(node.data)'>
          <i class="material-icons">create</i>
        </button>
        <button
          *ngIf="!node.data.isSharePoint && node.data.attachmentTypeId && !isClientOrAssociate && !(node.data.attachmentTypeId === attachmentTypeEnum.JPEG || node.data.attachmentTypeId === attachmentTypeEnum.PNG || node.data.attachmentTypeId === attachmentTypeEnum.HEIC )"
          class="btn btn-sm btn-outline-primary arrows"
          (click)='$event.preventDefault(); $event.stopPropagation(); showImage(node.data)'>
          <i class="material-icons">attach_file</i>
        </button>

        <!-- SharePoint -->
        <button
          *ngIf="node.data.isSharePoint && node.data.recordTypeId === this.jobDocumentRecordTypeEnum.Detail && !isClientOrAssociate && !(node.data.attachmentTypeId === attachmentTypeEnum.JPEG || node.data.attachmentTypeId === attachmentTypeEnum.PNG || node.data.attachmentTypeId === attachmentTypeEnum.HEIC )"
          class="btn btn-sm btn-outline-primary arrows"
          (click)='$event.preventDefault(); $event.stopPropagation(); showImage(node.data)'>
          <i class="material-icons">attach_file</i>
        </button>

        <!-- Download -->
        <button *ngIf="node.data.attachmentTypeId && node.data.recordTypeId === this.jobDocumentRecordTypeEnum.Detail"
          class="btn btn-sm btn-outline-primary arrows"
          (click)='$event.preventDefault(); $event.stopPropagation(); download(node.data)'>
          <i class="material-icons">arrow_downward</i>
        </button>
      </span>
      <div
        *ngIf="showImages && node.data.attachmentTypeId
          && (node.data.attachmentTypeId === attachmentTypeEnum.JPEG || node.data.attachmentTypeId === attachmentTypeEnum.PNG || node.data.attachmentTypeId === attachmentTypeEnum.HEIC)"
        class="option-image">
        <js-show-image [imageId]="node.data.id" [imageSourceId]=imageSourceEnum.JobDocumentAttachment
          [isSharePoint]="node.data.isSharePoint" [sharePointId]="node.data.sharePointId">
        </js-show-image>
      </div>
    </ng-template>

  </tree-root>

  <div *ngIf="!jobDocumentService.treeNodes || !jobDocumentService.treeNodes.length" class="ms-2">
    No Information records exist for this job.
    <br><br>
  </div>
</div>

<js-loading-spinner *ngIf="loading"></js-loading-spinner>

<div *ngIf="error" class="error">
  {{errorMessage}}
</div>



<!-- Email -->
<dx-popup class="popup" [width]="popupWidth" [height]="popupHeight" [showTitle]="true" title="Send Email"
  [dragEnabled]="true" [hideOnOutsideClick]="false" [(visible)]="emailPopupVisible" [showCloseButton]="true">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view width="100%" height="popupScroll">
      <js-loading-spinner *ngIf="loadingDocs"></js-loading-spinner>

      <div class="emailForm" *ngIf="!loadingDocs">
        <div [ngStyle]="{'width': 'calc(' + emailWidth + 'px' + ')','display': 'inline-block'}">
          <div class="dx-field">
            <div class="dx-field-label">To</div>
            <div class="emailField">
              <dx-text-box [hoverStateEnabled]="false" [(value)]="toEmail"></dx-text-box>
            </div>
          </div>
        </div>
        <dx-button icon='search' class="search-email-button" type="default" (onClick)="lookupEmail('toEmail')">
        </dx-button>

        <div [ngStyle]="{'width': 'calc(' + emailWidth + 'px' + ')','display': 'inline-block'}">
          <div class="dx-field">
            <div class="dx-field-label">CC</div>
            <div class="emailField">
              <dx-text-box [hoverStateEnabled]="false" [(value)]="ccEmail"></dx-text-box>
            </div>
          </div>
        </div>
        <dx-button icon='search' class="search-email-button" type="default" (onClick)="lookupEmail('ccEmail')">
        </dx-button>

        <div [ngStyle]="{'width': 'calc(' + emailWidth + 'px' + ')','display': 'inline-block'}">
          <div class="dx-field">
            <div class="dx-field-label">BCC</div>
            <div class="emailField">
              <dx-text-box [hoverStateEnabled]="false" [(value)]="bccEmail"></dx-text-box>
            </div>
          </div>
        </div>
        <dx-button icon='search' class="search-email-button" type="default" (onClick)="lookupEmail('bccEmail')">
        </dx-button>

        <div class="dx-field">
          <div class="subjectField">
            <dx-text-box [hoverStateEnabled]="false" [(value)]="emailSubject"></dx-text-box>
          </div>
        </div>

        <textarea class="form-control noteEntryClass mt-1 mb-1" placeholder="message..." name="emailMessage"
          id="emailMessage" [(ngModel)]="emailMessage"></textarea>

        <dx-tree-list id="jobDocumentsTree" [dataSource]="jobDocumentsForEmail" keyExpr="keyId"
          parentIdExpr="parentKeyId" [showRowLines]="true" [showBorders]="true" [columnAutoWidth]="true"
          [(selectedRowKeys)]="selectedJobDocs" (onSelectionChanged)="attachmentSelectionChanged($event)">

          <dxo-search-panel [visible]="true" [width]="200"></dxo-search-panel>
          <dxo-selection mode="multiple" [recursive]="true" [allowSelectAll]="false"> </dxo-selection>
          <dxi-column dataField="description" caption="Attachments" [allowSorting]="false"></dxi-column>
          <dxi-column dataField="orderNo" [visible]="false" sortOrder="asc"></dxi-column>
        </dx-tree-list>

        <div class="mt-1"></div>

        <div class="me-2 labelClass">CC email to yourself?</div>
        <dx-check-box [(value)]="ccToSelf" [width]="30"> </dx-check-box>

        <span class="ms-4 me-2">Attach {{addendumName}}?</span>
        <dx-check-box [(value)]="sendAddendum"> </dx-check-box>

        <div class="mt-1">
          <div class="me-2 mt-1 labelClass">Attach Variation?</div>
          <dx-select-box class="selectClass" [dataSource]="variations" displayExpr="title" valueExpr="id"
            placeholder="Not Selected" showClearButton="true" (onValueChanged)="getVariationParams($event)">
            <div *dxTemplate="let data of 'item'">
              <b class="vo-number">{{data.displayedVariationNumber}}</b>
              <span>{{data.title}}</span>
            </div>
          </dx-select-box>
        </div>

        <div *ngIf="purchaseOrdersActive" class="mt-1">
          <div class="me-2 labelClass">Attach Order?</div>
          <dx-select-box class="selectClass" [dataSource]="purchaseOrders" displayExpr="poNumber" valueExpr="id"
            placeholder="Not Selected" showClearButton="true" [searchEnabled]="true"
            [(value)]="selectedPurchaseOrderId">
            <div *dxTemplate="let data of 'item'">
              <b class="po-number">{{data.poNumber}}</b>
              <span>{{data.vendorName}}</span>
            </div>
          </dx-select-box>
        </div>

        <dx-button text="Send" class="send-email-button" type="default" (onClick)="sendEmail()">
        </dx-button>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>


<js-email-lookup *ngIf="showGetEmail" (vendorEmail)="setEmail($event)"></js-email-lookup>

<!-- Get variation parameters -->
<dx-popup class="popup" [width]="400" [height]="265" [showTitle]="true" title="Variation details" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="variationParamsPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view width="100%" height="popupScroll">

      <div class="checkbox">
        <label for="printImages">
          Print Images &nbsp;
        </label>
        <input type="checkbox" id="printImages" [checked]="printImages" (change)="printImages = !printImages">
      </div>

      <div class="checkbox">
        <label for="printVariationPrices">
          Print Prices &nbsp;
        </label>
        <input type="checkbox" id="printVariationPrices" [checked]="printVariationPrices"
          (change)="printVariationPrices = !printVariationPrices">
      </div>

      <div class="checkbox">
        <label for="printNotApplicable">
          Print items marked 'Not Applicable' &nbsp;
        </label>
        <input type="checkbox" id="printNotApplicable" [checked]="printNotApplicable"
          (change)="printNotApplicable = !printNotApplicable">
      </div>

      <div class="checkbox">
        <label for="printConnectedTogether">
          Print connected items together &nbsp;
        </label>
        <input type="checkbox" id="printConnectedTogether" [checked]="printConnectedTogether"
          (change)="printConnectedTogether = !printConnectedTogether">
      </div>

      <div class="checkbox">
        <label for="printVONumber">
          Print variation number &nbsp;
        </label>
        <input type="checkbox" id="printVONumber" [checked]="printVONumber" (change)="printVONumber = !printVONumber">
      </div>

      <dx-button text="Ok" class="send-email-button" type="default"
        (onClick)="variationParamsPopupVisible = !variationParamsPopupVisible">
      </dx-button>
    </dx-scroll-view>
  </div>
</dx-popup>


<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel [(visible)]="dxLoadingVisible" [showIndicator]="true" [showPane]="true" [shading]="false"
  [hideOnOutsideClick]="false">
</dx-load-panel>
