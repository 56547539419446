<div class="modal-header">
  <h2 class="modal-title">Copy Variation</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancelWithNo()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <js-job-number-small [jobNumber]="jobNumber" [saveJobNumber]="false" (refreshJobNumber)="refreshJobNumber($event)">
  </js-job-number-small>

  <!-- Loading Spinner -->
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <div *ngIf="!loading && variations && variations.length">
    Select variation:
    <span ngbDropdown>
      <button class="btn-sm btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
        {{variationString}}<span *ngIf="variationString === ''" class='red'>Please select a variation...</span>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdownmenuclass">
        <!-- until service returns only the ones required test for Ids equal -->
        <div *ngFor="let varn of variations">
          <button class="dropdown-item" (click)='setVariationNum(varn)'>
            {{varn.displayedVariationNumber}}: {{varn.title}}
          </button>
        </div>
      </div>
    </span>

    <br>
    <div *ngIf="variationString">
      <div *ngIf="!loading">
        <button type="button" class="btn btn-sm btn-primary mb-1" (click)="selectAllNodesForCopy()">
          Select All
        </button>
        <div class="floatRight">
          <input id="filter" #filter (keyup)="tree3.treeModel.filterNodes(filter.value)" placeholder="filter nodes" />
          <button
            (click)="$event.preventDefault(); $event.stopPropagation(); tree3.treeModel.clearFilter(); filter.value=''">
            Clear Filter
          </button>
        </div>

        <div class="treeclass">
          <tree-root #tree3 [focused]="true" [nodes]="treeNodes" [options]="copyTreeOptions"
            (initialized)="onItemTreeInit(tree3)">
            <ng-template #treeNodeTemplate let-node="node" let-index="index">
              <input (change)="copyTreeNodeClicked(node)" type="checkbox" id="check-{{node.data.id}}"
                [indeterminate]="node.data.indeterminate" [checked]="node.data.checked">
              <span>{{ node.data.description }}</span>
            </ng-template>
          </tree-root>
        </div>
      </div>
    </div>
  </div>

  <div>
    Note: if the variation you are copying from is <b>NOT</b> open then <b>New</b> prices will be looked up for any
    option list item.
  </div>
</div>

<div class="modal-footer">
  <span class="red">{{errorMessage}}</span>
  <button type="button" class="btn btn-sm btn-primary" (click)="runCopy()" [disabled]="jobNumber === '' || loading">Run
    Copy</button>
</div>

<!-- check when unticking a heading -->
<dx-popup [width]="300" [height]="130" [showTitle]="true" title="Un-tick child items?" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="popupVisible">
  <div id="explodeButton" *dxTemplate="let data of 'content'">
    <dx-button text="Un-Tick" type="default" (onClick)="copyTreeNodeChecked()"></dx-button>
    <dx-button text="Leave ticked" type="outlined" (onClick)="popupVisible = !popupVisible" class="ms-3"></dx-button>
  </div>
</dx-popup>
