export class JobIntegration {
    public id: number;
    public jobId: number;
    public integrationTypeId: number;
    public integrationId: string;
}

export enum JobIntegrationEnum {
  Xero = 1,
  ActiveCampaign = 2,
  GoogleDocs = 3,
  Constructive = 4,
  SharePoint = 5,
  DataBuild = 6,
  HubSpot = 10
}
