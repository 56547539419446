export enum TrackingFieldTypeEnum {
    Boolean = 1,
    Number = 2,
    Text = 3,
    Date = 4,
    Time = 5,
    Lookup = 6,
    Link = 7,
    Calculated = 8
}
