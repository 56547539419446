export class VariationSplit {
  public id: number;
  public jobVariationId: number;
  public orderNo: number;
  public variationSplitTypeId: number;
  public jobLineId: number;
  public companyActivityId: number;
  public claimDate: Date;
  public amount: number;
  public accountsInvoiceId: string;
  public dateInvoiced: Date;
  public isInvoiceToClient: boolean;
  public modifiedUserId: number;
  public modifiedDate: Date;
}

export enum VariationSplitEnum {
  AddToClaim = 1,
  AtActivity = 2,
  AtDate = 3
}
