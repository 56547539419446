import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobVarItemService } from '../../services/felixApi/job-var-item.service';
import { JobItemService } from '../../services/felixApi/job-item.service';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-confirm-delete-modal',
  templateUrl: './confirm-delete-modal.component.html',
  styleUrls: ['./confirm-delete-modal.component.scss']
})
export class ConfirmDeleteModalComponent implements OnInit, OnDestroy {
  @Input() isClientAndSelectable: boolean;
  @Input() jobVarItemId: number;

  loading = false;
  subscriptions: Subscription[] = [];

  constructor(private _activeModal: NgbActiveModal,
    private _jobItemService: JobItemService,
    private notiService: NotificationService,
    private _jobVarItemService: JobVarItemService) { }

  ngOnInit() {
    if (this.isClientAndSelectable) {
      this.deleteJobVarItem();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  passAnswer(ans) {
    if (ans) {
      this.deleteJobVarItem();
    } else {
      this._activeModal.close();
    }
  }

  deleteJobVarItem() {
    this.loading = true;

    this.subscriptions = this.subscriptions.concat(
      this._jobVarItemService.deleteJobVarItem(this.jobVarItemId).subscribe({
        next: () => {
          this._jobItemService.readJobItems();
          // setTimeout(() => {
          // }, 400); // wait - give read items time to complete
          this._activeModal.close();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
          this._jobItemService.setWaitingJobItemAPI(false);
        }
      })
    );
  }
}
