import { Component, EventEmitter, Input, OnInit, Output, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TreeComponent } from '@circlon/angular-tree-component';

import { ChangeTypeEnum } from '../dtos/change-type.enum';
import { ItemTypeEnum } from '../dtos/item-type.enum';
import { JobVarTypeEnum } from '../dtos/job-var-type.enum';
import { SelectionTypeEnum } from '../dtos/selection-type.enum';
import { IJobItem } from '../dtos/job-item';
import { IJobVarItem } from '../dtos/job-var-item';
import { IOptionListHeader } from '../dtos/option-list-header';
import { ShowPdfComponent } from '../shared/show-pdf.component';
import { SelectionAttachmentModalComponent } from './selection-modals/selection-attachment-modal.component';
import { VariationEditModalComponent } from './selection-modals/variation-edit-modal.component';

import { JobItemService } from '../services/felixApi/job-item.service';
import { JobVarItemService } from '../services/felixApi/job-var-item.service';
import { OptionListService } from '../services/felixApi/option-list.service';
import { GlobalService } from '../services/global.service';
import { OptionColourEnum } from '../dtos/option-colour.enum';
import { VariationAddModalComponent } from './selection-modals/variation-add-modal.component';
import { AttachmentTypeEnum } from '../dtos/attachment-type.enum';
import { ConfirmDeleteModalComponent } from './selection-modals/confirm-delete-modal.component';
import { CostTypeEnum } from '../dtos/cost-type.enum';
import { VariationTypeEnum } from '../dtos/variation-type.enum';
import { AuthService } from '../services/auth.service';
import { JobItemCommentTypeEnum } from '../dtos/comment-type.enum';
import { CommentModalComponent } from './selection-modals/comment-modal.component';
import { PatchReturnTypeEnum } from '../dtos/patchReturnType.enum';
import { NotificationService } from '../services/notification.service';
import { PriceTypeEnum } from '../dtos/price-type.enum';
import { Subscription } from 'rxjs';
import { JobVarCalc } from '../dtos/job-var-calc';
import { VariationStatusEnum } from '../dtos/variation-status.enum';
import { PHONE_SIZE } from '../../config/variables';
import { ImageSelectModalComponent } from './selection-modals/image-select-modal/image-select-modal.component';
import { EstimatingModalComponent } from './selection-modals/estimating-modal/estimating-modal.component';
import { EstimatingService } from '../services/felixApi/estimating.service';
import { BackgroundColourEnum } from '../dtos/background-colour.enum';
import { UtilsService } from '../services/utils.service';
// import { jsonpCallbackContext } from '@angular/common/http/src/module';


@Component({
  selector: 'js-detail-variation',
  templateUrl: './detail-variation.component.html',
  styleUrls: ['./detail-variation.component.scss']
})
export class DetailVariationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() index: number;
  @Input() firstRecord: number;
  @Input() showAddEditButtons: boolean; // to show/hide edit.
  @Input() jobitem: IJobItem;
  // @Input() showSetupLines: boolean;
  // @Input() selectionsMode: string;
  @Input() variationNumber: number;
  // @Input() showHiddenLines: boolean;
  @Input() headingLevel: number;
  // @Input() showHistoryOfChanges: boolean;
  @Input() showImages: boolean;
  @Input() isParentHiddenFromMaster: boolean;
  @Input() selectionsAdmin: boolean;
  @Input() parentConnectedItemId: number;
  @Input() parentChangeTypeId: number;
  @Input() variationType: number;
  // @Input() isLocked: boolean;
  @Input() isVariationOpen: boolean;
  @Input() showNumbering: boolean;

  // event to emit a 'refresh' command to the parent component
  @Output() refreshLines: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @ViewChild('selectionDrop') selectionDrop: NgbDropdown;

  COMPONENT_NAME = 'detail-variation';
  // PHONE_SIZE = 660;
  // SHOW_QTY_AT_SIZE = 660;
  ITEM_DESC_PERC = 0.43; // the % of what is left after deducting the buttons and price etc fields
  PRICE_WIDTH = 90;
  QTY_WIDTH = 60;
  BUTTONS_WIDTH = 150; // was 130
  BUTTONS_WIDTH_SMALL = 40; // was 65
  IS_CHECKED_WIDTH = 20;
  CONTAINER_BORDER = 6; // the deduction required due to the accordian panels
  showQty = false;
  subscriptions: Subscription[] = [];

  descWidthDeduct: number; // to help style the description
  itemDescWidthPerc: number; // calculate the width of the itemDescription field. - depends if buttons are showing
  selectionWidthDeduction: number; // calculated deductions to work out the width of the selections area
  selectionWidthPerc: number; // the % the selections field takes up
  buttonsWidth: number; // width of the buttons.
  quantityWidth: number; // the % width of the qty field if the item has one + the rate.
  priceWidth: number; // width of the price field
  isCheckedWidth: number; // the checked box is hidden for a phone
  noQtyWidthAddition: number; // adjustment to the selections width if qty is not required
  showQtyField: boolean; // do we show the qty field at all

  colours = [
    { name: 'Standard', id: 0 },
    { name: 'Red', id: 1 },
    { name: 'Green', id: 2 }
  ];

  optionColourEnum = OptionColourEnum; // import the colour enum for the HTML
  updatedItems: IJobVarItem[] = [];
  patchReturnTypeEnum = PatchReturnTypeEnum;

  isAssociate: boolean; // is this user an associate - if so we hide prices
  isClient: boolean; // is this user a client as any prices not checked will be TBA

  // in a variation if the header says that a client can change items and this item is changeable by a client
  isClientAndSelectable = false;

  errorMessage: any;
  selectedValue: string;
  optionList: IOptionListHeader[] = [];
  optionListTmp: IOptionListHeader[] = [];
  updatedItem: any;
  testUpdatedItem: boolean;
  optDescription = '';
  optDescription2 = '';
  optItemTypeId: number;
  optionListId = 0;
  editIsSetupLine = false;
  selectionTypeId: number;
  modalHeading = '';
  modalButton = '';
  closeResult = '';
  optSelection = '';
  optPrice = 0;
  optSelectionCheckbox: boolean;
  // canEnterManualSelection: boolean;
  optisChecked = false;
  optnoteColour = 0;
  optisBoldNote = false;
  optisItalicNote = false;
  isHiddenFromMaster = false;

  treeOptionNodes: any[] = [];
  treeOptionOptions = {};
  treeOptionsSetUp = false; // set up once
  loading = false; // loading spinner

  // when adding or editing a detail line we need to ask if we are going to link to another line
  detailOptionType: string;
  linkedJobItemId: number;
  ItemType = ItemTypeEnum;
  SelectionType = SelectionTypeEnum;
  setupValue: string;
  setupValueOrig: string; // holding the original value to check on update

  // form for entering a manual selection in a dropdown
  manualSelection = '';

  // set up tags
  currentJobItemId: number;
  currentJobItem: IJobItem;
  andORSetupLinks: string; // tells us if we ALL or only one condition need to be true to show the line

  // for variations
  originalItem: IJobItem; // the original item before being changed by VO.
  originalDesc = ''; // the original desc before being changed by VO.
  originalSelection = ''; // the original selection value if we are editing
  originalSelectionId: number; // the original selected option Id
  editOption = '';
  itemChangedId: number;
  newjobVarTypeId: number;
  originalJobVarItem: IJobVarItem;
  currentJobVarItem: IJobVarItem;
  jobVarTypeEnum = JobVarTypeEnum;
  changeTypeEnum = ChangeTypeEnum;
  showSelection: boolean; // do we show the selection field in the delete modal
  addAboveOrAfter: string;
  canAddAbove = false;
  updatedJobVarItem: IJobVarItem;
  gotoAttachmentEdit = false;
  attachmentTypeEnum = AttachmentTypeEnum;
  costTypeEnum = CostTypeEnum;
  variationTypeEnum = VariationTypeEnum;
  variationStatusEnum = VariationStatusEnum;

  newItem = new IJobItem; // for adding a new item

  waitingAPI: boolean; // the service will tell us if a previous joitem API is waiting to complete

  showAddEditButtonsTmp: boolean;
  jobItemCommentTypeEnum = JobItemCommentTypeEnum;
  priceTypeEnum = PriceTypeEnum;
  isQtyRequired = false; // is a quantity required as per the option list parent
  quantityEntered: number; // entered on screen

  innerWidth: number; // the actual width of the users window
  phoneSize: boolean; // we calculate if the user is on a small device and show the images differently
  jobVarCalcs: JobVarCalc[];
  previousSelectedOption: IOptionListHeader; // the previously selected option record - we use the current rate
  currentSelectedOption: IOptionListHeader;
  previousItemRate: number;
  newItemRate: number;
  costTypeId: number;
  previousCostTypeId: number;
  updatedItemCalcs: any; // for updating the jobvarcalc records
  prevQty: number; // we need the previous qty when we calc the price
  prevAndCurrentValid: boolean;
  variationId: number;
  maxSelectionDropDownWidth: number;
  selectionDropDownWidth: number;
  minSelectionDropDownWidth: number;
  inThisVO = false;
  isPrintedDescDifferent = false; // option list items may have a different printed desc
  isEstimatingAttachmentsExist = false;
  backgroundColourId: number;
  backgroundColourEnum = BackgroundColourEnum;
  waitingForBackgroundColourChange: boolean;
  refreshImage = false; // used to force refresh of images on change
  numberingDeduction: number;
  variationsWrite: boolean;
  variationOnlyComment: string;

  constructor(
    private _jobItemService: JobItemService,
    private _jobVarItemService: JobVarItemService,
    private _optionListService: OptionListService,
    private _authService: AuthService,
    private _globalService: GlobalService,
    private notiService: NotificationService,
    private _estimatingService: EstimatingService,
    private modalService: NgbModal,
    private utilsService: UtilsService) { }

  ngOnInit(): void {
    // console.log(this.jobitem);
    this.subscribeToWidthChanges();

    this.isAssociate = this._authService.isAssociate();
    this.isClient = this._authService.isClient();

    this.variationId = this._jobItemService.currentVariation.id;

    if (this.isClient && this._jobItemService.currentVariation.statusId === this.variationStatusEnum.Open
      && this._jobItemService.currentVariation.canBeModifiedByClient && this.jobitem.optionListId) {
      this.isClientAndSelectable = this._jobItemService.getOptionListItemById(this.jobitem.optionListId).childItemSelectableByClient;
    }

    if (this.headingLevel > 6) {
      this.descWidthDeduct = 60;
    } else {
      this.descWidthDeduct = (this.headingLevel * 10) - this.headingLevel; // allow for border
    }

    // subscribe to the service to see if we are waiting on another API call to complete
    if (!this.jobitem.isHistoryRecord || this.jobitem.changeTypeId === ChangeTypeEnum.Delete) {
      // this.subscriptions = this.subscriptions.concat(
      //   this._jobItemService.waitingJobItemAPI$.subscribe(
      //     waitingAPI => {
      //       this.waitingAPI = waitingAPI;
      //       if (!waitingAPI) {
      this.setPreviousSelectionData(true); // get data here so we can detect when the data is edited
      //       }
      //     })
      // );
    }

    this.calcIsRestrictedForm();
    this.setBackgroundColour();
    this.doWeHaveEstimatingAttachments();
  }

  ngOnChanges(): void {
    // console.log('onChanges');
    // for changing the qty we need the original value
    this.prevQty = this.jobitem.quantity;
    if (this.jobitem.changedByVOId === this._jobItemService.currentVariation.id) {
      this.inThisVO = true;
      this.prevQty = this.jobitem.previousQuantity;
    }

    if (this.showNumbering) {
      this.numberingDeduction = 30;
    } else {
      this.numberingDeduction = 0;
    }

    this.setIsPrintedDesc();

    this.showAddEditButtonsTmp = this.showAddEditButtons; // we use the ShowAddEditButtons to hide buttons while updating
    this.checkShowEditButtons();

    // get the option list - get this now as we also need to know from the parent if qty is required
    if (this.showAddEditButtons && !this.jobitem.isHistoryRecord) {
      this.getOptionListForDropdown(this.jobitem);
    }

    this.calcIsRestrictedForm();
    this.setBackgroundColour();

    this.variationsWrite = false; // reset the value

    let permissionLevel = '';
    if (this.variationType < 10) {
      permissionLevel = this._authService.getSelectionsPermissions('Variations');
    } else if (this.variationType < 20) {
      permissionLevel = this._authService.getSelectionsPermissions('SalesVariations');
    } else {
      permissionLevel = this._authService.getSelectionsPermissions('PreContractVariations');
    }
    if (permissionLevel === 'Admin' || permissionLevel === 'Write' || this.selectionsAdmin) {
      // this.selectionsAdmin = true;
      this.variationsWrite = true;
    }
  }

  setBackgroundColour() {
    this.backgroundColourId = 0; // reset
    this.variationOnlyComment = null; // reset
    if (this.jobitem.changedByJobVarId
      && (!this.jobitem.isHistoryRecord || this.jobitem.changeTypeId === this.changeTypeEnum.Delete)) {
      const jobVarItemExtra = this._jobItemService.currentJobVarItemExtras.find(i => i.jobVarItemId === this.jobitem.changedByJobVarId);
      if (jobVarItemExtra) {
        this.backgroundColourId = jobVarItemExtra.colourId;
        this.variationOnlyComment = jobVarItemExtra.variationOnlyComment;
      }
    }
  }

  setIsPrintedDesc() {
    if (this.jobitem.printedDescription && this.jobitem.printedDescription !== ''
      && this.jobitem.printedDescription !== this.jobitem.selection) {
      if ((this._authService.isAssociate() || this._authService.isClient())) {
        this.jobitem.selection = this.jobitem.printedDescription;
        this.isPrintedDescDifferent = false;
      } else {
        this.isPrintedDescDifferent = true;
      }
    } else {
      this.isPrintedDescDifferent = false;
    }
  }

  subscribeToWidthChanges() {
    this.subscriptions = this.subscriptions.concat(
      this._globalService.selectonsInnerWidthChanged.subscribe(width => {
        setTimeout(() => {
          this.calcIsRestrictedForm();
        }, 550); // wait for iPhone
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  calcIsRestrictedForm() {
    this.innerWidth = this._globalService.selectonsInnerWidth;

    if (this.innerWidth < PHONE_SIZE) {
      this.phoneSize = true;
    } else {
      this.phoneSize = false;
    }

    if (this.innerWidth < PHONE_SIZE) {
      this.showQty = false;
      this.isCheckedWidth = 0;
    } else {
      this.showQty = true;
      if (this.selectionsAdmin && this.showAddEditButtons) {
        this.isCheckedWidth = this.IS_CHECKED_WIDTH;
      } else {
        this.isCheckedWidth = 0;
      }
    }

    if (!this.isAssociate && this._authService.canSeeAmountsPermission) {
      this.priceWidth = this.PRICE_WIDTH + this.isCheckedWidth;
    } else {
      this.priceWidth = 0;
    }

    // calc width of selection area
    if (this.showAddEditButtons && !this.phoneSize) {
      this.buttonsWidth = this.BUTTONS_WIDTH;
    } else {
      this.buttonsWidth = this.BUTTONS_WIDTH_SMALL;

      if (!this.isClient && !this.isAssociate) {
        this.buttonsWidth += 50; // for comments button & flag button
      }
    }

    if (this.selectionsAdmin && !this.phoneSize) {
      this.buttonsWidth += 25; // for estimating attachments indicator
    }

    if (this.showQty) {
      this.quantityWidth = this.QTY_WIDTH;
      this.showQtyField = true;

      // so we don't worry about null
      if (!this.jobitem.quantity) {
        this.jobitem.quantity = 0;
      }
    } else {
      this.quantityWidth = 0;
      this.showQtyField = false;
    }

    // so we don't worry about null
    if (!this.jobitem.provisionalSum) {
      this.jobitem.provisionalSum = 0;
    }

    if (!this.jobitem.itemDescription || this.jobitem.itemDescription === '') {
      this.itemDescWidthPerc = 0;
      this.selectionWidthPerc = 1;
    } else {
      this.itemDescWidthPerc = this.ITEM_DESC_PERC;
      this.selectionWidthPerc = 1 - this.ITEM_DESC_PERC;
    }

    this.selectionWidthDeduction = this.priceWidth + this.buttonsWidth + this.quantityWidth
      + (this.headingLevel * 2);

    if (!this.showQty || this.jobitem.isQtyRequired === true) {
      this.noQtyWidthAddition = 0;

      // we need to check if we are hiding the qty
      if (this.jobitem.optionListId && (this.isClient || this.isAssociate)) {
        const optionList = this._jobItemService.getOptionListItemById(this.jobitem.optionListId);
        if (optionList && optionList.hideQuantity) {
          this.noQtyWidthAddition = this.QTY_WIDTH;
          this.showQtyField = false;
        }
      }
    } else {
      this.noQtyWidthAddition = this.QTY_WIDTH;
      this.showQtyField = false;
    }

    this.buttonsWidth -= this.CONTAINER_BORDER; // allow for the border around the panel

    this.maxSelectionDropDownWidth = this.innerWidth - (this.innerWidth - this.selectionWidthDeduction) * this.itemDescWidthPerc - 20;
    if (this.maxSelectionDropDownWidth > 450) {
      this.minSelectionDropDownWidth = 450;
    } else if (this.maxSelectionDropDownWidth < 235) {
      this.minSelectionDropDownWidth = this.maxSelectionDropDownWidth;
    } else {
      this.minSelectionDropDownWidth = 235;
    }
  }

  checkShowEditButtons() {
    // if parent marked as deleted we can't edit these items
    if (this.parentChangeTypeId === this.changeTypeEnum.Delete) {
      this.showAddEditButtonsTmp = false;
    } else {
      this.showAddEditButtonsTmp = this.showAddEditButtons;
    }
    this.waitingAPI = false;
    this._jobItemService.setWaitingJobItemAPI(false);
  }

  getJobItemList(answer) {
    this.refreshLines.emit(answer);
    this.checkShowEditButtons();
  }

  getOptionListForDropdown(jitem) {
    this.treeOptionNodes = this._optionListService.getCurrentOptionNodes();
    if (!this.treeOptionNodes || !this.treeOptionNodes.length) {
      this._optionListService.resetCurrentOptionNodes(); // ensure we get a new list of options if we need them
      this.subscriptions = this.subscriptions.concat(
        this._optionListService.getOptionListChildren(0, true, false)
          .subscribe({
            next: (treeOptionNodes) => {
              this.treeOptionNodes = treeOptionNodes;
              this._optionListService.setCurrentOptionNodes(treeOptionNodes);
              this.getOptionListForDropdown2(jitem, true);
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    } else {
      this.getOptionListForDropdown2(jitem, true);
    }
  }

  getOptionListForDropdown2(jitem, getCalcs: boolean) {
    this.isQtyRequired = false;

    if (jitem.optionListId === null) {
      this.optionList = [new IOptionListHeader('Please select previous option first...', 0, null)];
      if (jitem.canEnterManualSelection || jitem.originalItemTypeId === this.jobVarTypeEnum.JobVarItem) {
        this.optionList = this.optionList.concat(new IOptionListHeader('Other - Click here', 999, null));
        this.manualSelection = ''; // 6-4-19 GH was jitem.selection
      }
    } else {
      this.optionList = [new IOptionListHeader('Please Select...', 0, null)];
      this.optionListTmp = this._jobItemService.getOptionListItems(jitem.optionListId);

      // we have also set the optionListParentNode in the service
      if (this._jobItemService.optionListParentNode.isQtyRequired === true && !jitem.hasLinkedItems) {
        this.isQtyRequired = this._jobItemService.optionListParentNode.isQtyRequired;
      }

      this.optionList = this.optionList.concat(this.optionListTmp);
      if (jitem.canEnterManualSelection || jitem.changeTypeId === this.changeTypeEnum.Add
        || jitem.originalItemTypeId === this.jobVarTypeEnum.JobVarItem) {
        this.optionList = this.optionList.concat(new IOptionListHeader('Other - Click here', 999, null));
        this.manualSelection = ''; // 6-4-19 GH was jitem.selection
      }

      // if the old selected item is priced in the option list then we get this info
      if (this.jobitem.changedByVOId === this._jobItemService.currentVariation.id) {
        if (this.jobitem.previousSelectedOptionId) {
          this.previousSelectedOption = this._jobItemService.getOptionListItemById(this.jobitem.previousSelectedOptionId);
        }
      } else {
        if (this.jobitem.selectedOptionId) {
          this.previousSelectedOption = this._jobItemService.getOptionListItemById(this.jobitem.selectedOptionId);
        }
      }
      if (this.jobitem.selectedOptionId) {
        this.currentSelectedOption = this._jobItemService.getOptionListItemById(this.jobitem.selectedOptionId);
      }

      // this.setPreviousSelectionData(getCalcs); no called from waitingAPI
    }
  }

  setPreviousSelectionData(getCalcs: boolean) {
    if (this.jobitem.selectionTypeId === this.SelectionType.Dropdown && !this.jobitem.hasLinkedItems) {
      if (this.jobitem.changedByVOId === this._jobItemService.currentVariation.id && this.isQtyRequired && getCalcs) {
        // we would have jobvar calc records unless we are accessing old data
        this.getJobVarCalcs();
      } else {
        // set the rates
        this.setPreviousAndCurrent();
      }
    }
  }

  setPreviousAndCurrent() {
    if (this.jobitem.changedByVOId === this._jobItemService.currentVariation.id && this.jobitem.changeTypeId === this.changeTypeEnum.Add) {
      // added item so previous is ignored
      this.previousCostTypeId = this.costTypeEnum.Note; // set to Note as previous is valid
      this.previousItemRate = 0;

      if (this.currentSelectedOption && this.currentSelectedOption.salesPriceTypeIfAddedId) {
        this.costTypeId = this.currentSelectedOption.salesPriceTypeIfAddedId;
      } else {
        this.costTypeId = this.costTypeEnum.Priced;
      }

      if (this.currentSelectedOption && this.currentSelectedOption.salesPriceIfAdded) {
        this.newItemRate = this.currentSelectedOption.salesPriceIfAdded;
      } else {
        this.newItemRate = 0;
      }

      if (this.currentSelectedOption
        && ((this.currentSelectedOption.salesPriceTypeIfAddedId
          && this.currentSelectedOption.salesPriceTypeIfAddedId !== this.costTypeEnum.Priced)
          || (this.currentSelectedOption.salesPriceIfAdded && this.currentSelectedOption.salesPriceIfAdded !== 0))) {
        this.prevAndCurrentValid = true;
      } else {
        this.prevAndCurrentValid = false;
      }
    } else {
      // if the previous is 'Please Select' then we accept price is zero
      if (((this.jobitem.changedByVOId === this._jobItemService.currentVariation.id && this.jobitem.previousSelectedOptionId)
        || (this.jobitem.changedByVOId !== this._jobItemService.currentVariation.id && this.jobitem.selectedOptionId))
        && (!this.previousSelectedOption
          || (!this.previousSelectedOption.salesPriceTypeIfChangedInSameListId
            || this.previousSelectedOption.salesPriceTypeIfChangedInSameListId === this.costTypeEnum.Priced)
          && (!this.previousSelectedOption.salesPriceIfChangedInSameList
            || this.previousSelectedOption.salesPriceIfChangedInSameList === 0))) {
        // we don't have a previous price set up so the new is ignored
        this.previousCostTypeId = this.costTypeEnum.Priced;
        this.previousItemRate = 0;
        this.costTypeId = this.costTypeEnum.Priced;
        this.newItemRate = 0;
        this.prevAndCurrentValid = false;
      } else {
        // do we have a new price or type to use?
        if (!this.currentSelectedOption
          || (!this.currentSelectedOption.salesPriceTypeIfChangedInSameListId
            || this.currentSelectedOption.salesPriceTypeIfChangedInSameListId === this.costTypeEnum.Priced)
          && (!this.currentSelectedOption.salesPriceIfChangedInSameList
            || this.currentSelectedOption.salesPriceIfChangedInSameList === 0)) {
          // we don't have a new price set up so the new is ignored
          this.previousCostTypeId = this.costTypeEnum.Priced;
          this.previousItemRate = 0;
          this.costTypeId = this.costTypeEnum.Priced;
          this.newItemRate = 0;
          this.prevAndCurrentValid = false;
        } else {

          // if previous not selected then treat as a note
          if ((this.jobitem.changedByVOId === this._jobItemService.currentVariation.id && !this.jobitem.previousSelectedOptionId)
            || (this.jobitem.changedByVOId !== this._jobItemService.currentVariation.id && !this.jobitem.selectedOptionId)) {
            this.previousCostTypeId = this.costTypeEnum.Note;
          } else {
            if (this.previousSelectedOption && this.previousSelectedOption.salesPriceTypeIfChangedInSameListId) {
              this.previousCostTypeId = this.previousSelectedOption.salesPriceTypeIfChangedInSameListId;
            } else {
              this.previousCostTypeId = this.costTypeEnum.Priced;
            }
          }

          if (this.previousSelectedOption && this.previousSelectedOption.salesPriceIfChangedInSameList) {
            this.previousItemRate = this.previousSelectedOption.salesPriceIfChangedInSameList;
          } else {
            this.previousItemRate = 0;
          }

          if (this.currentSelectedOption && this.currentSelectedOption.salesPriceTypeIfChangedInSameListId) {
            this.costTypeId = this.currentSelectedOption.salesPriceTypeIfChangedInSameListId;
          } else {
            this.costTypeId = this.costTypeEnum.Priced;
          }

          if (this.currentSelectedOption && this.currentSelectedOption.salesPriceIfChangedInSameList) {
            this.newItemRate = this.currentSelectedOption.salesPriceIfChangedInSameList;
          } else {
            this.newItemRate = 0;
          }

          this.prevAndCurrentValid = true;
        }
      }
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  setSelection(itm: IJobItem, opt: IOptionListHeader) {
    if (itm.changedByVOId === this._jobItemService.currentVariation.id
      && itm.previousOptionListId === itm.optionListId
      && ((!itm.previousSelectedOptionId && !opt.id) || itm.previousSelectedOptionId === opt.id)
      && (this.jobitem.changeTypeId !== this.changeTypeEnum.Add || !this.jobitem.linkedJobItemId)
      && !this.jobitem.previousIsDeleted) {
      // we can ask to delete and revert to original
      if (itm.attachmentId && itm.attachmentVariationId === this._jobItemService.currentVariation.id) {
        this.notiService.showError('Cannot change back as there is an attachment to this line.');
      } else {
        // we can ask to delete and revert to original
        this.deleteItem();
      }
    } else {
      if (itm.attachmentId) {
        this.notiService.showWarning('There is a manual image/attachment for this item that will have to be changed manually');
      }

      if (opt.description === 'Please Select...' || opt.description === 'Please select previous option first...') {
        this.optDescription2 = '';
        this.optionListId = null;
      } else {
        this.optDescription2 = opt.description;
        this.optionListId = opt.id;
      }

      if (itm.selection !== this.optDescription2) {
        // show warning message if there is one
        if (opt.warningNote && opt.warningNote !== '') {
          this.notiService.showWarningNoTimeOut(opt.warningNote);
        }

        if (opt.id) {
          this.currentSelectedOption = this._jobItemService.getOptionListItemById(opt.id);
        } else {
          this.currentSelectedOption = null;
        }

        this.editjobvaritem(itm, this.optDescription2, this.optionListId, this.SelectionType.Dropdown, opt);
      }
    }
  }

  // when the pption list tree is initialised set the focused node to the one already selected.
  onOptionTreeInit(tree: TreeComponent) {
    tree.treeModel.virtualScroll.setViewport(tree.treeModel.virtualScroll.viewport);
    this.loading = false;
  }

  setCheckBox(itm, sel) {
    // a job item with a checkbox has been ticked so update it
    if (sel === false) {
      this.optDescription2 = '';
    } else {
      this.optDescription2 = 'Yes';
    }
    this.editjobvaritem(itm, this.optDescription2, null, this.SelectionType.Checkbox, null);
  }

  setManualFromModal() {
    this.optSelection = this.manualSelection;
  }

  editjobvaritem(itm: IJobItem, newSelection: string, newSelectedOptionId: number,
    selectionTypeId: number, selectedOption: IOptionListHeader) {
    // we check if a linked item has an attachment and warn that it will need to be changed/deleted manually
    if (itm.hasLinkedItems) {
      this._jobItemService.checkForAttachment(itm.id, this._jobItemService.currentVariation.id);
    }

    if (itm.changedByVO === this.variationNumber) {

      // added in this VO so just change it
      this.updatedItem = { id: itm.changedByJobVarId, selection: newSelection };
      if (selectionTypeId === this.SelectionType.Dropdown) {
        this.updatedItem.selectedOptionId = newSelectedOptionId;

        // we check if a linked item has an attachment and warn that it will need to be changed/deleted manually
        this._jobItemService.currentJobItems.forEach(element => {
          if (element.linkedJobItemId === this.jobitem.id && element.attachmentId
            && element.attachmentVariationId === this._jobItemService.currentVariation.id) {
            // alert('A linked item has a manual image which will need to be changed or deleted manually if required');
            this.notiService.showWarning('A linked item has a manual image which will need to be changed or deleted manually if required');
          }
        });

        if (selectedOption && !itm.hasLinkedItems) {
          // we are still selecting from the same list
          // so we can possibly auto set prices
          this.setPricesFromChangedOption(selectedOption);
        } else {
          // we need to mark as not checked in case price needs to change
          this.updatedItem.isChecked = false;
        }
      }

      if (this.updatedItem.price
        && (this._jobItemService.currentVariation.variationType === this.variationTypeEnum.Office
          || this._jobItemService.currentVariation.variationType === this.variationTypeEnum.PreContractExFromContract
          || this.jobitem.isDoNotPrint)) {
        this.updatedItem.price = null;
        this.notiService.showWarning('Item setup price has been changed to zero for OFFICE variation type');
      }

      this._jobItemService.setWaitingJobItemAPI(true); // tell the service to tell all other components that an API is pending

      if (selectedOption && this.isQtyRequired) {
        this.updateJobVarCalcs(true);
      } else {
        this.updateJobVarItem(true);
      }
    } else {
      // add a new jobvaritem record
      if (itm.changedByVO && itm.changedByVO !== 0) {
        // was already changed so need to use the changedById
        this.itemChangedId = itm.changedByJobVarId;
        this.newjobVarTypeId = this.jobVarTypeEnum.JobVarItem;
      } else {
        this.itemChangedId = itm.id;
        this.newjobVarTypeId = this.jobVarTypeEnum.JobItem;
      }
      this.updatedItem = {
        jobVariationId: this._jobItemService.currentVariation.id,
        jobVarTypeId: this.newjobVarTypeId,
        itemChangedId: this.itemChangedId,
        changeTypeId: this.changeTypeEnum.Change,
        jobItemAboveTypeId: itm.jobItemAboveTypeId,
        jobItemAboveId: itm.jobItemAboveId,
        orderNo: 0,
        itemTypeId: this.ItemType.Detail,
        itemDescription: itm.itemDescription,
        selection: newSelection,
        originalItemTypeId: itm.originalItemTypeId,
        originalItemId: itm.id,
        quantity: itm.quantity,
        optionListId: itm.optionListId,
        isDoNotPrint: itm.isDoNotPrint
      };

      if (selectionTypeId === this.SelectionType.Dropdown) {
        this.updatedItem.selectedOptionId = newSelectedOptionId;

        // we can possibly auto set prices
        if (selectedOption && !itm.hasLinkedItems) {
          this.setPricesFromChangedOption(selectedOption);

          // if we using a qty we need to send the job var calc records as well ------------
          if (this.isQtyRequired) {
            this.setupAddJobVarCalcs(true);
          }
        }
      }

      if (this.updatedItem.price
        && (this._jobItemService.currentVariation.variationType === this.variationTypeEnum.Office
          || this._jobItemService.currentVariation.variationType === this.variationTypeEnum.PreContractExFromContract
          || this.jobitem.isDoNotPrint)) {
        this.updatedItem.price = null;
        this.notiService.showWarning('Item setup price has been changed to zero for OFFICE variation type');
      }

      this._jobItemService.setWaitingJobItemAPI(true); // tell the service to tell all other components that an API is pending

      this.subscriptions = this.subscriptions.concat(
        this._jobVarItemService.addJobVarItem(this.variationNumber, this.updatedItem).subscribe({
          next: (updatedItems) => {
            this.updatedItems = updatedItems;

            if (this.updatedItems[0].patchReturnWarning) {
              this.notiService.showWarning(this.updatedItems[0].patchReturnWarning);
            }

            if (this.gotoAttachmentEdit) {
              this.gotoAttachmentEdit = false;
              this.jobitem.changedByVOId = this._jobItemService.currentVariation.id;
              this.jobitem.changedByJobVarId = this.updatedItems[0].id;
              this.showAttachment(this.jobitem);
            } else {
              // this.getJobItemList(false);
              // we now process the returned records
              if (this.updatedItems[0].patchReturnTypeId === this.patchReturnTypeEnum.GetAllData) {
                this.getJobItemList(false);
              } else {
                // update the list we already have
                this._jobItemService.updateChangedVarItems(this.updatedItems,
                  this._jobItemService.currentVariation.id, this.variationNumber);
              }
            }
          },
          error: (err) => {
            this.notiService.notify(err);
            this._jobItemService.setWaitingJobItemAPI(false);
            this.checkShowEditButtons();
          }
        })
      );
    }
  }

  setPricesFromChangedOption(selectedOption: IOptionListHeader) {
    this.currentSelectedOption = selectedOption;

    // if parent linked item changed in this VO then price cannot be calculated
    if (!this._jobItemService.parentLinkedItemChanged(this.jobitem.linkedJobItemId)) {
      this.setPreviousSelectionData(false);

      // if previous or new invalid then price TBA
      if (this.isQtyRequired) {
        this.updatedItem.price = this.utilsService.roundEven(this.newItemRate * this.jobitem.quantity)
          - this.utilsService.roundEven(this.previousItemRate * this.prevQty);
      } else {
        this.updatedItem.price = this.utilsService.roundEven(this.newItemRate - this.previousItemRate);
      }

      if (this.updatedItem.price !== 0
        && (this.costTypeId === this.costTypeEnum.Note || this.costTypeId === this.costTypeEnum.NoCharge)) {
        this.costTypeId = this.costTypeEnum.Priced;
      }

      if (this.updatedItem.price === 0 && this.costTypeId === this.costTypeEnum.Priced && this.prevAndCurrentValid) {
        if (!this.isQtyRequired || (this.jobitem.quantity && this.jobitem.quantity !== 0)) {
          this.costTypeId = this.costTypeEnum.NoCharge;
        }
      }

      this.updatedItem.costTypeId = this.costTypeId;

      if (!this.isQtyRequired && this.prevAndCurrentValid
        && ((this.updatedItem.price && this.updatedItem.price !== 0)
          || (this.costTypeId === this.costTypeEnum.Note || this.costTypeId === this.costTypeEnum.NoCharge))) {
        this.updatedItem.isChecked = true;
      } else {
        this.updatedItem.isChecked = false;
      }
    } else {
      this.updatedItem.costTypeId = this.costTypeEnum.Priced;
      this.costTypeId = this.costTypeEnum.Priced;
      this.updatedItem.price = 0;
      this.updatedItem.isChecked = false;
      this.newItemRate = 0;
      this.prevAndCurrentValid = false;
    }
  }

  // parentLinkedItemChanged(linkedJobItemId: number): boolean {
  //   // find parent linked item. if changed we need to set this item to TBA
  //   if (linkedJobItemId) {
  //     const parentItem = this._jobItemService.currentJobItems
  //       .find(i => i.id === linkedJobItemId && i.changedByVOId === this.variationId);
  //     if (parentItem) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  getJobVarCalcs() {
    // if qty required and this variation item already exists then we read the jobvarcalc records
    this.subscriptions = this.subscriptions.concat(
      this._jobVarItemService.getJobVarCalcs(this.jobitem.changedByJobVarId)
        .subscribe({
          next: (jobVarCalcs) => {
            this.jobVarCalcs = jobVarCalcs;
            if (this.jobVarCalcs && this.jobVarCalcs.length) {
              this.previousCostTypeId = this.jobVarCalcs[0].costTypeId;
              this.previousItemRate = this.jobVarCalcs[0].itemRate;
              this.costTypeId = this.jobVarCalcs[1].costTypeId;
              this.newItemRate = this.jobVarCalcs[1].itemRate;
            } else {
              // we don't have any so we get the original
              this.setPreviousAndCurrent();
            }
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  // using a modal to edit a note
  editItem(itm: IJobItem, fromDropDown: boolean) {
    // if (itm.changedByVO === this.variationNumber || itm.costTypeId !== this.costTypeEnum.PSFinalised) {
    if (!itm.isHistoryRecord || itm.changeTypeId === this.changeTypeEnum.Delete) {
      if (!this.waitingAPI
        && (this.showAddEditButtonsTmp || this.selectionsAdmin
          || this._authService.isAdminOrSuperUser() || this._authService.isClient())) {
        if (this.parentChangeTypeId === this.changeTypeEnum.Delete) {
          // alert('You cannot edit an item if the heading is marked as Deleted.');
          this.notiService.showWarning('You cannot edit an item if the heading is marked as Deleted.');
        } else {
          const modalRef = this.modalService.open(VariationEditModalComponent,
            { windowClass: 'modal-edit', backdrop: 'static' });
          modalRef.componentInstance.jobitem = itm;
          modalRef.componentInstance.variationId = this._jobItemService.currentVariation.id;
          modalRef.componentInstance.variationNumber = this.variationNumber;
          modalRef.componentInstance.isParentHiddenFromMaster = this.isParentHiddenFromMaster;
          modalRef.componentInstance.selectionsAdmin = this.selectionsAdmin;
          modalRef.componentInstance.variationType = this.variationType;
          modalRef.componentInstance.calledFromDropDown = fromDropDown;

          modalRef.result.then((updatedItem) => {
            if (updatedItem.gotoAttachments) {
              // will have saved a job var item so can update

              // copy item so we can change fields and not cause change detection
              let tempItem: IJobItem = new IJobItem;
              tempItem = JSON.parse(JSON.stringify(itm));

              if (updatedItem.itemDescription) {
                tempItem.itemDescription = updatedItem.itemDescription;
              }
              if (updatedItem.selection) {
                tempItem.selection = updatedItem.selection;
              }
              tempItem.changedByVOId = this._jobItemService.currentVariation.id;
              if (updatedItem.changedByJobVarId) {
                tempItem.changedByJobVarId = updatedItem.changedByJobVarId;
              }
              this.showAttachment(tempItem);
            } else if (updatedItem.refreshLines) {
              // this.getJobItemList(true);
              this.doWeHaveEstimatingAttachments();
            } else {
              this.setIsPrintedDesc();
              this.calcIsRestrictedForm();
              this.setBackgroundColour();
              this.doWeHaveEstimatingAttachments();
            }
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            this.doWeHaveEstimatingAttachments();
          });
        }
      }
    }
    // }
  }

  addVarItem(itm) {
    if (!this.waitingAPI) {
      const modalRef = this.modalService.open(VariationAddModalComponent,
        { windowClass: 'modal-edit', backdrop: 'static' });
      modalRef.componentInstance.jobitem = itm;
      modalRef.componentInstance.jobItemParentTypeId = itm.jobItemAboveTypeId; // ? bug 26-3-21 was itm.jobItemAboveId
      modalRef.componentInstance.jobItemAboveId = itm.jobItemAboveId;
      modalRef.componentInstance.parentConnectedItemId = this.parentConnectedItemId;
      modalRef.componentInstance.variationNumber = this.variationNumber;
      modalRef.componentInstance.variationId = this._jobItemService.currentVariation.id;
      modalRef.componentInstance.indexNo = this.index;
      modalRef.componentInstance.firstRecord = this.firstRecord;
      modalRef.componentInstance.selectionsAdmin = this.selectionsAdmin;
      modalRef.componentInstance.variationType = this.variationType;

      modalRef.result.then((updatedItem) => {
        if (!updatedItem.cancel) {
          if (updatedItem.addAttachment || updatedItem.addAndEstimate) {
            // go straight to adding an attachment
            this.newItem = new IJobItem;
            this.newItem.id = updatedItem.addedItemId;
            this.newItem.changedByJobVarId = updatedItem.addedItemId;
            this.newItem.changedByVOId = this._jobItemService.currentVariation.id;
            this.newItem.itemTypeId = updatedItem.itemTypeId;
            this.newItem.itemDescription = updatedItem.itemDescription;
            this.newItem.selection = updatedItem.selection;
            this.newItem.price = updatedItem.price;
            this.newItem.quantity = updatedItem.quantity;
            this.newItem.costTypeId = updatedItem.costTypeId;
            this.newItem.isChecked = updatedItem.isChecked;
            this.isQtyRequired = updatedItem.isQtyRequired;
            if (updatedItem.addAttachment) {
              this.showAttachment(this.newItem);
            } else {
              this.estimatePrice();
            }
          }
        }
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  deleteItem() {
    const modalRef = this.modalService.open(ConfirmDeleteModalComponent, { backdrop: 'static' });
    modalRef.componentInstance.isClientAndSelectable = this.isClientAndSelectable;
    modalRef.componentInstance.jobVarItemId = this.jobitem.changedByJobVarId;

    modalRef.result.then(() => {
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this._jobItemService.setWaitingJobItemAPI(false);
    });
  }

  setUpdatedItem(itm) {
    if (itm.changedByJobVarId === 0) {
      // add with previous item = JobItem
      this.updatedItem = {
        jobVariationId: this._jobItemService.currentVariation.id,
        jobVarTypeId: itm.originalItemTypeId,
        itemChangedId: itm.id,
        changeTypeId: this.changeTypeEnum.Add,
        jobItemAboveTypeId: itm.jobItemAboveTypeId,
        jobItemAboveId: itm.jobItemAboveId,
        orderNo: 1
      };
    } else if (itm.changedByVOId === this._jobItemService.currentVariation.id) {
      // add with previous item = JobVarItem and insert below current one
      if (itm.changeTypeId === ChangeTypeEnum.Add) {
        this.updatedItem = {
          jobVariationId: this._jobItemService.currentVariation.id,
          jobVarTypeId: itm.itemAddedAfterTypeId,
          itemChangedId: itm.itemAddedAfter,
          changeTypeId: this.changeTypeEnum.Add,
          jobItemAboveTypeId: itm.jobItemAboveTypeId,
          jobItemAboveId: itm.jobItemAboveId,
          orderNo: itm.orderNo + 1
        };
      } else {
        this.updatedItem = {
          jobVariationId: this._jobItemService.currentVariation.id,
          jobVarTypeId: itm.originalItemTypeId,
          itemChangedId: itm.id,
          changeTypeId: this.changeTypeEnum.Add,
          jobItemAboveTypeId: itm.jobItemAboveTypeId,
          jobItemAboveId: itm.jobItemAboveId,
          orderNo: 1
        };
      }
    } else {
      // add with previous item = JobVarItem
      this.updatedItem = {
        jobVariationId: this._jobItemService.currentVariation.id,
        jobVarTypeId: this.jobVarTypeEnum.JobVarItem,
        itemChangedId: itm.id,
        changeTypeId: this.changeTypeEnum.Add,
        jobItemAboveTypeId: itm.jobItemAboveTypeId,
        jobItemAboveId: itm.jobItemAboveId,
        orderNo: 1
      };
    }
  }

  // set the linked option list when clicked on from tree
  setOptionList(id, desc) {
    this.optionListId = id;
    this.optSelection = desc;
  }

  showAttachment(jobitem) {
    // show the attachment
    const modalRef = this.modalService.open(SelectionAttachmentModalComponent, { windowClass: 'modal-pdf', backdrop: 'static' });
    modalRef.componentInstance.jobitem = jobitem;
    modalRef.componentInstance.variationId = this._jobItemService.currentVariation.id;

    modalRef.result.then(attachmentId => {
      if (this.showImages) {
        this.showImages = false;
        setTimeout(() => {
          this.showImages = true;
          this.getJobItemList(true);
        }, 100);
      } else {
        this.getJobItemList(true);
      }
    }, () => {
      this.getJobItemList(false);
    });
  }

  showAttachmentReadOnly(jobitem) {
    // show the attachment but read only so open full screen
    if (this.jobitem.attachmentTypeId === this.attachmentTypeEnum.PDF) {
      const modalRef = this.modalService.open(ShowPdfComponent, { windowClass: 'modal-pdf', backdrop: 'static' });
      modalRef.componentInstance.jobitem = jobitem;

      modalRef.result.then((returnResult) => {
        this.closeResult = 'Closed';
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else {
      // image
      const modalRef = this.modalService.open(SelectionAttachmentModalComponent, { size: 'lg' });
      modalRef.componentInstance.jobitem = jobitem;
      modalRef.componentInstance.variationId = this._jobItemService.currentVariation.id;
      modalRef.componentInstance.isReadOnly = true;

      modalRef.result.then((updatedForm) => {
        this.getJobItemList(true);
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.getJobItemList(false);
      });
    }
  }

  itemChecked(itm: IJobItem) {
    if (!itm.isChecked) {
      this.updatedItem = { id: itm.changedByJobVarId, isChecked: true };
    } else {
      this.updatedItem = { id: itm.changedByJobVarId, isChecked: false };
    }

    this.waitingAPI = true;
    // this._jobItemService.setWaitingJobItemAPI(true); // tell the service to tell all other components that an API is pending

    this.subscriptions = this.subscriptions.concat(
      this._jobVarItemService.updateJobVarItemIsChecked(this.updatedItem).subscribe({
        next: () => {
          this.jobitem.isChecked = this.updatedItem.isChecked;
          this.checkShowEditButtons();
          // this.getJobItemList(); // don't need to get the lines
        },
        error: (err) => {
          this.jobitem.isChecked = itm.isChecked;
          this.notiService.notify(err);
          this.checkShowEditButtons();
        }
      })
    );
  }

  showComment() {
    // open the appropriate comment
    // show the attachment
    const modalRef = this.modalService.open(CommentModalComponent, { windowClass: 'modal-edit', backdrop: 'static' });
    modalRef.componentInstance.jobitem = this.jobitem;
    modalRef.componentInstance.variationId = this._jobItemService.currentVariation.id;
    modalRef.componentInstance.isLocked = this._jobItemService.isLocked;
    modalRef.componentInstance.isVariationOpen = this.isVariationOpen;

    modalRef.result.then((returnRecord) => {
      this.getJobItemList(false); // need to get the previous comment id when deleted
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  saveQty(event) {
    // save the qty entered on screen
    // event is false when dropdown closed
    if (!event && this.quantityEntered && this.jobitem.quantity !== this.quantityEntered) {

      this.quantityEntered = +(this.quantityEntered.toFixed(4));

      if (this.jobitem.optionListId === null) {
        this.notiService.showWarning('Please select an option from the dropdown.');
      } else {

        if (this.jobitem.changedByVO === this.variationNumber) {
          // added in this VO so just change it
          this.updatedItem = { id: this.jobitem.changedByJobVarId, quantity: this.quantityEntered, isChecked: false };

          // update the total & current value
          if (this.jobitem.changeTypeId === this.changeTypeEnum.Add) {
            this.updatedItem.price = this.utilsService.roundEven(this.quantityEntered * this.newItemRate);
          } else {
            this.updatedItem.price = this.utilsService.roundEven(this.quantityEntered * this.newItemRate)
              - this.utilsService.roundEven(this.prevQty * this.previousItemRate);
          }

          if (this.updatedItem.price !== 0
            && (this.jobitem.costTypeId === this.costTypeEnum.Note || this.jobitem.costTypeId === this.costTypeEnum.NoCharge)) {
            this.costTypeId = this.costTypeEnum.Priced;
            this.updatedItem.costTypeId = this.costTypeEnum.Priced;
          }

          this._jobItemService.variationTotal += this.updatedItem.price - this.jobitem.price;

          this.updateJobVarCalcs(false);

        } else {
          // add a new jobvaritem record
          if (this.jobitem.changedByVO && this.jobitem.changedByVO !== 0) {
            // was already changed so need to use the changedById
            this.itemChangedId = this.jobitem.changedByJobVarId;
            this.newjobVarTypeId = this.jobVarTypeEnum.JobVarItem;
          } else {
            this.itemChangedId = this.jobitem.id;
            this.newjobVarTypeId = this.jobVarTypeEnum.JobItem;
          }

          this.updatedItem = {
            jobVariationId: this._jobItemService.currentVariation.id,
            jobVarTypeId: this.newjobVarTypeId,
            itemChangedId: this.itemChangedId,
            changeTypeId: this.changeTypeEnum.Change,
            jobItemAboveTypeId: this.jobitem.jobItemAboveTypeId,
            jobItemAboveId: this.jobitem.jobItemAboveId,
            orderNo: 0,
            itemTypeId: this.jobitem.itemTypeId,
            itemDescription: this.jobitem.itemDescription,
            selection: this.jobitem.selection,
            originalItemTypeId: this.jobitem.originalItemTypeId,
            originalItemId: this.jobitem.id,
            selectedOptionId: this.jobitem.selectedOptionId,
            optionListId: this.jobitem.optionListId,
            quantity: this.quantityEntered,
            costTypeId: this.costTypeId,
            price: 0
          };

          if (this.jobitem.itemTypeId === this.ItemType.Note) {
            this.updatedItem.noteColour = this.jobitem.noteColour;
            this.updatedItem.isBoldNote = this.jobitem.isBoldNote;
            this.updatedItem.isItalicNote = this.jobitem.isItalicNote;
          }

          // update the total & current value
          this.updatedItem.price =
            this.utilsService.roundEven(this.quantityEntered * this.newItemRate)
            - this.utilsService.roundEven(this.prevQty * this.previousItemRate);

          if (this.updatedItem.price !== 0
            && (this.costTypeId === this.costTypeEnum.Note || this.costTypeId === this.costTypeEnum.NoCharge)) {
            this.updatedItem.costTypeId = this.costTypeEnum.Priced;
          }

          // if we using a qty we need to send the job var calc records as well ------------
          this.setupAddJobVarCalcs(false);

          this._jobItemService.setWaitingJobItemAPI(true); // tell the service to tell all other components that an API is pending

          this.subscriptions = this.subscriptions.concat(
            this._jobVarItemService.addJobVarItem(this.variationNumber, this.updatedItem).subscribe({
              next: (updatedItems) => {
                this.updatedItems = updatedItems;

                if (this.updatedItems[0].patchReturnWarning) {
                  this.notiService.showWarning(this.updatedItems[0].patchReturnWarning);
                }

                // we now process the returned records
                this._jobItemService.updateChangedVarItems(this.updatedItems,
                  this._jobItemService.currentVariation.id, this.variationNumber);
              },
              error: (err) => {
                this.notiService.notify(err);
                this._jobItemService.setWaitingJobItemAPI(false);
                this.getJobItemList(true);
              }
            })
          );
        }
      }
    }
  }

  setupAddJobVarCalcs(fromSelection: boolean) {
    // if we using a qty we need to send the job var calc records as well ------------
    let qty = this.quantityEntered;
    if (fromSelection) {
      qty = this.jobitem.quantity;
    }

    this.updatedItem.addJobVarCalcDto = [
      {
        optionListId: this.jobitem.selectedOptionId,
        quantity: this.prevQty,
        costTypeId: this.previousCostTypeId,
        itemRate: this.previousItemRate
      },
      {
        optionListId: this.jobitem.selectedOptionId,
        quantity: qty,
        costTypeId: this.costTypeId,
        itemRate: this.newItemRate
      }
    ];

    this.jobVarCalcs = this.updatedItem.addJobVarCalcDto;
  }

  updateJobVarCalcs(fromSelection: boolean) {

    let qty = 0;
    let selectedOptionId = this.jobitem.selectedOptionId;
    if (fromSelection) {
      qty = this.jobitem.quantity;
      selectedOptionId = this.updatedItem.selectedOptionId;
    } else {
      qty = this.quantityEntered;
    }

    let id0 = null;
    if (this.jobVarCalcs && this.jobVarCalcs[0]) {
      id0 = this.jobVarCalcs[0].id;
    }
    let id1 = null;
    if (this.jobVarCalcs && this.jobVarCalcs[1]) {
      id1 = this.jobVarCalcs[1].id;
    }

    this.updatedItemCalcs = {
      patchJobVarCalcDtos: [
        {
          id: id0,
          optionListId: this.jobitem.previousSelectedOptionId,
          quantity: this.prevQty,
          costTypeId: this.previousCostTypeId,
          itemRate: this.previousItemRate
        },
        {
          id: id1,
          optionListId: selectedOptionId,
          quantity: qty,
          costTypeId: this.costTypeId,
          itemRate: this.newItemRate
        }
      ]
    };
    this.jobVarCalcs = this.updatedItemCalcs.patchJobVarCalcDtos;

    this.updatedItem.UpdateJobVarCalcs = true;
    this.updatedItem.PrevJobVarCalcId = id0;
    this.updatedItem.PrevOptionListId = this.jobitem.previousSelectedOptionId;
    this.updatedItem.PrevQuantity = this.prevQty;
    this.updatedItem.PrevCostTypeId = this.previousCostTypeId;
    this.updatedItem.PrevItemRate = this.previousItemRate;
    this.updatedItem.NewJobVarCalcId = id1;
    this.updatedItem.NewOptionListId = selectedOptionId;
    this.updatedItem.NewQuantity = qty;
    this.updatedItem.NewCostTypeId = this.costTypeId;
    this.updatedItem.NewItemRate = this.newItemRate;

    this.updateJobVarItem(fromSelection);
  }

  updateJobVarItem(fromSelection: boolean) {
    this.subscriptions = this.subscriptions.concat(
      this._jobVarItemService.updateJobVarItem(this.updatedItem).subscribe({
        next: (updatedItems) => {
          this.updatedItems = updatedItems;

          if (fromSelection) {
            if (this.gotoAttachmentEdit) {
              this.gotoAttachmentEdit = false;
              this.showAttachment(this.jobitem);
            } else {
              // this.getJobItemList(false);
              // we now process the returned records
              if (this.updatedItems[0].patchReturnTypeId === this.patchReturnTypeEnum.GetAllData) {
                this.getJobItemList(false);
              } else {
                // update the list we already have
                this._jobItemService.updateChangedVarItems(this.updatedItems,
                  this._jobItemService.currentVariation.id, this.variationNumber);
                this.setIsPrintedDesc();
              }
            }
          } else {
            this.updatedItems = updatedItems;
            this.jobitem.quantity = +this.quantityEntered;
            this.jobitem.isChecked = false;
            this.jobitem.price = this.updatedItem.price;
            this.jobitem.costTypeId = this.costTypeId;
          }
        },
        error: (err) => {
          this.notiService.notify(err);
          this.getJobItemList(true);
        }
      })
    );
  }

  selectionOpened($event) {
    if ($event) {
      // get the optionlist - we need to get again in case it's changed as Angular's change detection does not work in all cases
      this.jobitem.optionListId = this._jobItemService.getThisItemOptionListId(this.jobitem.originalItemTypeId,
        this.jobitem.id, this.jobitem.optionNumber);

      this.getOptionListForDropdown2(this.jobitem, false);

      if (this._jobItemService.optionListParentNode
        && this._jobItemService.optionListParentNode.childSelectionWarning
        && this._jobItemService.optionListParentNode.childSelectionWarning.trim() !== '') {
        this.notiService.showInfo(this._jobItemService.optionListParentNode.childSelectionWarning);
      }

      // if we have images ON and we have images in this list we use the modal
      if (this.showImages && this.jobitem.optionListId) {
        let hasImagesInList = false;
        this.optionList.forEach(element => {
          if (element.attachmentId || element.optionImageId) {
            hasImagesInList = true;
          }
        });

        if (hasImagesInList) {
          this.selectionDrop.close();

          if (this.isClient && this.jobitem.attachmentId) {
            this.notiService.showInfo('Cannot change this item until the image/file attached is deleted.');
            this.editItem(this.jobitem, true);
          } else {
            const modalRef = this.modalService.open(ImageSelectModalComponent,
              { windowClass: 'modal-img-select', backdrop: 'static' });
            modalRef.componentInstance.jobitem = this.jobitem;
            modalRef.componentInstance.previousCostTypeId = this.previousCostTypeId;
            modalRef.componentInstance.previousItemRate = this.previousItemRate;
            modalRef.componentInstance.isQtyRequired = this.isQtyRequired;
            modalRef.componentInstance.previousSelectedOption = this.previousSelectedOption;
            // modalRef.componentInstance.currentSelectedOption = this.currentSelectedOption;
            modalRef.componentInstance.prevQty = this.prevQty;
            // modalRef.componentInstance.optionListId = this.jobitem.optionListId;
            // modalRef.componentInstance.canEnterManualSelection = this.jobitem.canEnterManualSelection;

            modalRef.result.then((answer) => {
              if (answer === -1) {
                // have clicked 'Other'
                this.editItem(this.jobitem, true);
              } else {
                // have got a selection from the modal
                this.setSelection(this.jobitem, answer);
              }
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          }
        }
      }
    }
  }

  selectionDropDownOpen() {
    if (this.selectionDrop) {
      this.selectionDrop.open();
    }
  }

  setSelectionFromDropdown($event) {
    // data returned from ng-select
  }

  estimatePrice() {
    // here we use estimating to calc the price - EstimatingModalComponent
    const modalRef = this.modalService.open(EstimatingModalComponent,
      { windowClass: 'modal-est3', backdrop: 'static', keyboard: false, scrollable: true });
    modalRef.componentInstance.jobVarItemId = this.newItem.changedByJobVarId;
    modalRef.componentInstance.itemDescription = this.newItem.itemDescription;
    modalRef.componentInstance.selection = this.newItem.selection;
    modalRef.componentInstance.variationId = this._jobItemService.currentVariation.id;

    modalRef.result.then((result) => {
      let price = 0;
      let isChecked = this.newItem.isChecked;

      if (result && (+result)) {
        price = +result;
      } else {
        // returned a zero or null price so must be TBA so mark not checked
        isChecked = false;
      }

      if (this.isQtyRequired) {
        if (this.newItem.quantity) {
          price = this.utilsService.roundEven(price * this.newItem.quantity);
        } else {
          price = 0;
        }
      }

      if (price === 0) {
        price = null;
      }
      if (price !== this.newItem.price || isChecked !== this.newItem.isChecked) {
        this.updatedItem = {
          id: this.newItem.id,
          price: price,
          isChecked: isChecked
        };

        if (this.isQtyRequired) {
          // we need to update the JobVarCalc record
          this.updatedItem.UpdateJobVarCalcs = true;
          // backend to look up and just for new calc record so we don't pass the id's
          this.updatedItem.NewOptionListId = this.updatedItem.selectedOptionId;
          this.updatedItem.NewQuantity = this.newItem.quantity;
          this.updatedItem.NewCostTypeId = this.newItem.costTypeId;
          this.updatedItem.NewItemRate = +result;
        }

        this.subscriptions = this.subscriptions.concat(
          this._jobVarItemService.updateJobVarItem(this.updatedItem).subscribe({
            next: () => {
              this.getJobItemList(false);
              this.doWeHaveEstimatingAttachments();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.getJobItemList(false);
              this.doWeHaveEstimatingAttachments();
            }
          })
        );
      } else {
        this.getJobItemList(false);
        this.doWeHaveEstimatingAttachments();
      }
    }, () => {
      this.getJobItemList(false);
      this.doWeHaveEstimatingAttachments();
    });
  }

  doWeHaveEstimatingAttachments() {
    // do we have estimating attachments - if so show the icon
    this.isEstimatingAttachmentsExist = false;

    if (this._authService.getSelectionsPermissions('Selections') === 'Admin'
      || this._authService.isAdminOrSuperUser()) {
      if (!this.phoneSize && !this.jobitem.isHistoryRecord
        && this.jobitem.changedByVOId === this.variationId && this.jobitem.changedByJobVarId
        && !this.jobitem.hasLinkedItems) {
        const jobVarCost = this._estimatingService.jobVarCosts.find(i => i.jobVarItemId === this.jobitem.changedByJobVarId && i.hasBlob);
        if (jobVarCost) {
          this.isEstimatingAttachmentsExist = true;
        }
      }
    }
  }

  changeBackground() {
    // change the background colour of this line
    if (this.variationsWrite) {
      this.backgroundColourId = this.backgroundColourId === 3 ? 0 : this.backgroundColourId + 1;

      this.waitingForBackgroundColourChange = true;
      this.subscriptions = this.subscriptions.concat(
        this._jobVarItemService.setJobVarItemExtras(this.jobitem.changedByJobVarId, this.backgroundColourId).subscribe({
          next: (res) => {
            const jobVarItemExtra = this._jobItemService.currentJobVarItemExtras.find(i => i.jobVarItemId === this.jobitem.changedByJobVarId);
            if (jobVarItemExtra) {
              jobVarItemExtra.colourId = this.backgroundColourId;
            } else {
              this._jobItemService.currentJobVarItemExtras = this._jobItemService.currentJobVarItemExtras.concat(res);
            }
            this.waitingForBackgroundColourChange = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.waitingForBackgroundColourChange = false;
          }
        })
      );
    }
  }
}
