import { GlobalService } from './../../services/global.service';
import { Component, Input, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'js-sales-ups-downs',
  templateUrl: './sales-ups-downs.component.html',
  styleUrls: ['./sales-ups-downs.component.css']
})
export class SalesUpsDownsComponent implements OnInit {
  @Input() jobId: number;
  dataSource: DataSource;
  gridHeight: number;

  constructor(
    private estimatingService: EstimatingService,
    private _activeModal: NgbActiveModal,
    private globalService: GlobalService) { }

  ngOnInit() {
    this.gridHeight = window.innerHeight - 200;
    this.getData();
  }

  getData() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.estimatingService.getSalesUpsDowns(this.jobId).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  cancel() {
    this._activeModal.close();
  }
}
