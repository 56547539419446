<div class="modal-header">
  <h2 class="modal-title">Apply Selection Board</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <div>
    <dx-select-box [dataSource]="boardTypes" displayExpr="description" [(value)]="selectedBoardType"
      placeholder="Select the type of board required" (onValueChanged)="boardTypeChanged()"></dx-select-box>
  </div>


  <div *ngIf="selectedBoardType" class="card mt-2">
    <div class="card-body scroll">

      <div *ngIf='!loading && boards && boards.length === 0' class="noBoards">
        <span class="noBoardText">
          <p>No selection boards have been configured.</p>
        </span>
      </div>

      <js-loading-spinner *ngIf="loading"></js-loading-spinner>

      <table *ngIf='!loading && boards && boards.length' class="table table-sm">
        <thead class="rowclass">
          <tr>
            <th scope="col" class="col1class">Select</th>
            <th scope="col" class="col2class"></th>
            <th scope="col" class="col1class">Details</th>
          </tr>
        </thead>

        <tbody class="rowclass">
          <tr *ngFor='let board of boards | filterPipe: filterBoards' class="rowclass">

            <ng-container>
              <td class="col1class">
                <mat-checkbox [(ngModel)]="board.selected" (click)="selectBoard(board)"></mat-checkbox>
              </td>
              <td>{{board.boardName}}</td>
              <td>
                <button class='btn btn-sm btn-outline-primary imageButton' (click)='openPDF(board.id)'>
                  <i class="material-icons orange">attach_file</i>
                </button>
              </td>
            </ng-container>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
  <button class="btn btn-primary" (click)="update()" [disabled]="!selectedBoardId">Update Selections</button>
</div>