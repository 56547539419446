<!-- Template for adding/changing setup tags -->
<ng-template #contentSetupTags let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h2 class="modal-title">Show/Hide by linking to a another line</h2>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div>Description: {{optDescription}}</div>
    <br>
    <!-- use radio to ask if we are ANDing the lines together or ORing them -->
    <div class="form-group row radioclass marginleft15">
      <label class="marginleft15">
        <input type="radio" [(ngModel)]="andORSetupLinks" value="AND" name="andORSetupLinks"> Show if ALL true
      </label>
      <label class="marginleft15">
        <input type="radio" [(ngModel)]="andORSetupLinks" value="OR" name="andORSetupLinks"> Show if ANY true
      </label>
      <!-- <label class="marginleft15">
        <input type="radio" [(ngModel)]="andORSetupLinks" value="NONE" name="andORSetupLinks"> None to be equal
      </label> -->
    </div>
    <!-- <js-job-item-link [iJobItem]=currentJobItem></js-job-item-link> -->
    <js-job-item-link [iJobItem]=currentJobItem (closeclick)="c('Close click')"></js-job-item-link>
    <!-- <br>
    <button type="button" class="btn btn-sm btn-primary" (click)="c('Close click')">{{modalButton}}</button> -->
  </div>
</ng-template>

<div>
  <div cdkDropList class="row-list" (cdkDropListDropped)="drop($event)">
    <div class="row-box" *ngFor='let jobitem of thisHeadingItems; index as index' cdkDragLockAxis="y" cdkDrag
      [cdkDragDisabled]="!showAddEditButtons || phoneSize || _jobItemService.selectionsMode === 'Variations' || jobitem.itemTypeId === ItemType.Heading">

      <div class="custom-placeholder" *cdkDragPlaceholder></div>
      <div>
        <!-- <div *ngFor='let jobitem of thisHeadingItems; index as index'> -->

        <!-- <div          *ngIf="jobitem.jobItemAboveId === iJobItem.id && (!isClientOrAssociate || (jobitem.itemTypeId === ItemType.Heading || !jobitem.isDoNotPrint))"> -->

        <div *ngIf="jobitem.itemTypeId === ItemType.Heading" class="border">

          <div
            *ngIf="(jobitem.isHistoryRecord) && (_jobItemService.showHistoryOfChanges || jobitem.changedByVO === variationNumber)">
            <!-- cannot be a setup line -->
            <span [ngStyle]="{'padding-left.px': containerMargin}">
              <span class="strikeclass">{{jobitem.itemDescription}}</span>
              <span
                *ngIf="(jobitem.changeTypeId === 1) && (_jobItemService.showHistoryOfChanges || jobitem.changedByVO === variationNumber)"
                class="normalclass">:
                Changed in Vn {{calcDisplayedVariationNumber(jobitem.changedByVO)}}</span>
              <span
                *ngIf="(jobitem.changeTypeId === 2) && (_jobItemService.showHistoryOfChanges || jobitem.changedByVO === variationNumber)"
                class="normalclass">:
                Deleted in Vn {{calcDisplayedVariationNumber(jobitem.changedByVO)}}</span>
            </span>
            <button *ngIf="jobitem.changeTypeId === changeTypeEnum.Delete"
              class='btn btn-sm btn-outline-primary btnline titleBtn'
              (click)='$event.preventDefault(); $event.stopPropagation(); editItem(contentSetupTags, jobitem)'>
              <i class="material-icons">delete_forever</i>
            </button>
          </div>

          <div *ngIf="!(jobitem.isHistoryRecord)">
            <span [ngStyle]="{'padding-left.px': containerMargin}">
              <span *ngIf="(jobitem.isSetUpLine)" class="setupclass">{{jobitem.itemDescription}}</span>
              <span *ngIf="!(jobitem.isSetUpLine)" class="normalclass">{{jobitem.itemDescription}}
                <span *ngIf="jobitem.isDoNotPrint" class='notPrintedClass'>(Not printed on {{addendumName}})</span>
              </span>
            </span>

            <!-- only show edit buttons if job is NOT locked -->
            <div *ngIf="(showAddEditButtons)" class="buttonsClass">

              <!-- add a var item line after this one -->
              <button *ngIf="_jobItemService.selectionsMode === 'Variations'"
                class='btn btn-sm btn-outline-primary btnline titleBtn'
                (click)="$event.preventDefault(); $event.stopPropagation(); addVarItem(jobitem, index)"><i
                  class="material-icons">add</i></button>

              <button class='btn btn-sm btn-outline-primary btnline titleBtn'
                (click)='$event.preventDefault(); $event.stopPropagation(); editItem(contentSetupTags, jobitem)'>
                <i class="material-icons">create</i>
              </button>

              <button *ngIf="_jobItemService.selectionsMode === 'Master'"
                class='btn btn-sm btn-outline-primary btnline titleBtn'
                (click)='$event.preventDefault(); $event.stopPropagation(); downItem(jobitem)'>
                <i class="material-icons">arrow_downward</i>
              </button>

              <button *ngIf="_jobItemService.selectionsMode === 'Master'"
                class='btn btn-sm btn-outline-primary btnline titleBtn'
                (click)='$event.preventDefault(); $event.stopPropagation(); upItem(jobitem)'>
                <i class="material-icons">arrow_upward</i>
              </button>
            </div>
          </div>

          <div *ngIf="(!jobitem.isHistoryRecord)">
            <js-selection-heading [showAddEditButtons]=showAddEditButtons [iJobItem]=jobitem
              [variationNumber]=variationNumber [headingLevel]="headingLevel + 1" [showImages]=showImages
              [isParentHiddenFromMaster]=jobitem.isHiddenFromMaster [selectionsAdmin]=selectionsAdmin
              [variationType]=variationType [jobLineSetupAdmin]=jobLineSetupAdmin [isVariationOpen]=isVariationOpen
              [headingIsSetUpLine]="(jobitem.isSetUpLine || headingIsSetUpLine)" (refreshLines)='onRefreshLines($event)'
              [showNumbering]=showNumbering [itemsChangedFlag]="itemsChangedFlag">
            </js-selection-heading>
          </div>
        </div>

        <div *ngIf="jobitem.itemTypeId !== ItemType.Heading" class="border">

          <!-- If detail/note then call selections to handle -->
          <js-selections
            *ngIf="_jobItemService.selectionsMode === 'Master' || jobitem.isSetUpLine || headingIsSetUpLine"
            [showAddEditButtons]=showAddEditButtons [iJobItem]=jobitem [variationNumber]=variationNumber
            [headingLevel]="headingLevel + 1" [showImages]=showImages [jobLineSetupAdmin]=jobLineSetupAdmin
            [selectionsAdmin]=selectionsAdmin [headingIsSetUpLine]="(jobitem.isSetUpLine || headingIsSetUpLine)"
            [dragOn]="showAddEditButtons && !phoneSize && !jobitem.changedByJobVarId"
            (refreshLines)='onRefreshLines($event)'></js-selections>

          <js-detail-variation
            *ngIf="_jobItemService.selectionsMode === 'Variations' && !(jobitem.isSetUpLine || headingIsSetUpLine)
          && (!jobitem.isHistoryRecord || (jobitem.isHistoryRecord && (_jobItemService.showHistoryOfChanges || jobitem.changedByVO === variationNumber)))"
            [index]=index [firstRecord]=firstRecord [showAddEditButtons]=showAddEditButtons [jobitem]=jobitem
            [variationNumber]=variationNumber [headingLevel]="headingLevel + 1" [showImages]=showImages
            [showNumbering]=showNumbering [isParentHiddenFromMaster]=iJobItem.isHiddenFromMaster
            [selectionsAdmin]=selectionsAdmin [parentConnectedItemId]=iJobItem.connectedItemId
            [parentChangeTypeId]=iJobItem.changeTypeId [variationType]=variationType [isVariationOpen]=isVariationOpen
            (refreshLines)='onRefreshFromVO($event)'>
          </js-detail-variation>

          <div
            *ngIf="showAddEditButtons && !phoneSize && !jobitem.changedByJobVarId && _jobItemService.selectionsMode === 'Master'"
            class="btndrag" cdkDragHandle matTooltip="Drag to move item up/down" matTooltipPosition="above"
            [matTooltipShowDelay]="500">
            <i class="material-icons icons-drag">unfold_more</i>
          </div>

          <!-- <div *ngIf="phoneSize" class="floatMiddle">
          <js-show-image
            *ngIf="!jobitem.isHistoryRecord && showImages && jobitem.optionListAttachmentId && (!jobitem.attachmentId || jobitem.attachmentTypeId === attachmentTypeEnum.PDF)"
            [imageId]=jobitem.selectedOptionId [manualItem]=false></js-show-image>
          <js-show-image
            *ngIf="!jobitem.isHistoryRecord && showImages && !jobitem.optionListAttachmentId && (!jobitem.attachmentId || jobitem.attachmentTypeId === attachmentTypeEnum.PDF) && jobitem.optionImageId"
            [imageId]=jobitem.optionImageId [manualItem]=false [imageLibrary]=true></js-show-image>
          <js-show-image
            *ngIf="!jobitem.isHistoryRecord && showImages && jobitem.attachmentId && jobitem.attachmentTypeId !== attachmentTypeEnum.PDF"
            [imageId]=jobitem.attachmentId [manualItem]=true [attachmentVariationId]=jobitem.attachmentVariationId>
          </js-show-image>
        </div> -->
        </div>
        <!-- </div> -->

      </div>
    </div>
  </div>
  <!-- only show button if job is NOT locked -->
  <button
    *ngIf="(showAddEditButtons) && _jobItemService.selectionsMode === 'Master' && iJobItem.originalItemTypeId !== jobVarTypeEnum.JobVarItem"
    class='btn btn-sm btn-outline-primary btnadd' [ngStyle]="{'margin-left.px': containerMargin}"
    (click)='addItem()'>Add to {{iJobItem.itemDescription}}</button>

  <!-- if there are no items we are adding under the heading above -->
  <button *ngIf="(showAddEditButtons) && _jobItemService.selectionsMode === 'Variations' && !headingIsSetUpLine"
    class='btn btn-sm btn-outline-primary btnadd' [ngStyle]="{'margin-left.px': containerMargin}"
    (click)="addVarItem(iJobItem, -1)">Add to
    {{iJobItem.itemDescription}}</button>
</div>
