import { AttachmentTypeEnum } from './../../dtos/attachment-type.enum';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { GlobalService } from '../global.service';
import { IJobItemAttachment } from '../../dtos/job-item-attachment';
import { HttpService } from '../http.service';
import { formatDate } from 'devextreme/localization';

@Injectable({
  providedIn: 'root'
})
export class JobItemAttachmentService {
  private _jobItemUrl = '';
  cache: IJobItemAttachment[] = [];

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService) { }


  getJobItemAttachmentById(id): Observable<IJobItemAttachment> {
    const img = this.cache.filter(i => i.id === id);
    if (img[0]) {
      return of(img[0]);
    } else {
      return this._http.get<IJobItemAttachment>(this.globalService.getApiUrl() + '/job-item-attachments/' + id,
        this.httpService.getHttpFileOptions())
        .pipe(tap(res => {
          if (res.attachmentTypeId !== AttachmentTypeEnum.PDF) {
            this.cache = this.cache.concat(res);
          }
        }),
          catchError(this.handleError));
    }
  }

  postJobItemAttachment(jobitemId, imageFile) {
    const options = this.httpService.getHttpFileOptions();
    return this._http.post(this.globalService.getApiUrl() + '/job-items/' + jobitemId + '/attachments/upload', imageFile, options);
  }

  deleteJobItemAttachment(id) {
    const options = this.httpService.getHttpFileOptions();
    return this._http.delete(this.globalService.getApiUrl() + '/job-item-attachments/' + id, options)
      .pipe(
        tap(() => {
          const attachmentIndex = this.cache.findIndex(i => i.id === id);
          if (attachmentIndex >= 0) {
            // remove the record from the array
            this.cache.splice(attachmentIndex, 1);
          }
        }),
        catchError(this.handleError));
  }

  getJobItemAttachmentPDF(variationNumber, printImages, printPrices,
    printNotApplicable, printNonPrintItems, printConnectedTogether,
    printVONumber, exportToExcel, printProvisionalSums, printQuantities,
    selectedCategories: number[], printPdfAttachments: boolean,
    showSignatureSection: boolean, showCheckBoxes: boolean, printWithHighlighting: boolean): Observable<IJobItemAttachment> {

    this._jobItemUrl = this.globalService.getApiUrl() + '/jobs/' + this.globalService.getCurrentJob() + '/items/pdf';

    if (variationNumber && variationNumber !== 0) {
      this._jobItemUrl += '?variationNumber=' + variationNumber + '&';
    } else {
      this._jobItemUrl += '?';
    }
    if (printImages) {
      this._jobItemUrl += 'printImages=true';
    } else {
      this._jobItemUrl += 'printImages=false';
    }
    if (printPrices) {
      this._jobItemUrl += '&printPrices=true';
    } else {
      this._jobItemUrl += '&printPrices=false';
    }
    if (printNotApplicable) {
      this._jobItemUrl += '&printNotApplicable=true';
    } else {
      this._jobItemUrl += '&printNotApplicable=false';
    }
    if (printNonPrintItems) {
      this._jobItemUrl += '&printNonPrintItems=true';
    } else {
      this._jobItemUrl += '&printNonPrintItems=false';
    }
    if (printConnectedTogether) {
      this._jobItemUrl += '&printConnectedTogether=true';
    } else {
      this._jobItemUrl += '&printConnectedTogether=false';
    }
    if (printVONumber) {
      this._jobItemUrl += '&printVONumber=true';
    } else {
      this._jobItemUrl += '&printVONumber=false';
    }
    if (exportToExcel) {
      this._jobItemUrl += '&excelExport=true';
    } else {
      this._jobItemUrl += '&excelExport=false';
    }
    if (printProvisionalSums) {
      this._jobItemUrl += '&printProvisionalSums=true';
    } else {
      this._jobItemUrl += '&printProvisionalSums=false';
    }
    if (printQuantities) {
      this._jobItemUrl += '&printQuantities=true';
    }
    if (showSignatureSection) {
      this._jobItemUrl += '&showSignatureSection=true';
    }
    if (printPdfAttachments) {
      this._jobItemUrl += '&printPdfAttachments=true';
    } else {
      this._jobItemUrl += '&printPdfAttachments=false';
    }
    if (showCheckBoxes) {
      this._jobItemUrl += '&showCheckBoxes=true'
    }
    if (printWithHighlighting) {
      this._jobItemUrl += '&printWithHighlighting=true'
    }

    return this._http.post<IJobItemAttachment>(this._jobItemUrl, { categoryIds: selectedCategories },
      this.httpService.getHttpFilePDFOptions()).pipe(
        catchError(this.handleError));
  }

  getSalesQuotePDF(printImages: boolean, printPrices: boolean,
    printNotApplicable: boolean, printNonPrintItems: boolean, quoteDate: string, printConnectedTogether: boolean,
    printVONumber: boolean, printProvisionalSums: boolean, printWholeAddendum: boolean,
    printItemsNotTaken: boolean, printChangeHistory: boolean,
    printIncludedAmounts: boolean, printSalesQuoteOnly: boolean,
    printPdfAttachments: boolean,
    printFromSalesPriceOnwards: boolean,
    exportToXML: boolean, isPriceGuide: boolean, quoteExpiryDate: string, showSignatureSection: boolean): Observable<IJobItemAttachment> {
    this._jobItemUrl = this.globalService.getApiUrl() + '/jobs/' + this.globalService.getCurrentJob() + '/items/sales-pdf';

    if (printImages) {
      this._jobItemUrl += '?printImages=true';
    } else {
      this._jobItemUrl += '?printImages=false';
    }
    if (printPrices) {
      this._jobItemUrl += '&printPrices=true';
    } else {
      this._jobItemUrl += '&printPrices=false';
    }
    if (printNotApplicable) {
      this._jobItemUrl += '&printNotApplicable=true';
    } else {
      this._jobItemUrl += '&printNotApplicable=false';
    }
    if (printNonPrintItems) {
      this._jobItemUrl += '&printNonPrintItems=true';
    } else {
      this._jobItemUrl += '&printNonPrintItems=false';
    }
    if (printConnectedTogether) {
      this._jobItemUrl += '&printConnectedTogether=true';
    } else {
      this._jobItemUrl += '&printConnectedTogether=false';
    }
    if (printVONumber) {
      this._jobItemUrl += '&printVONumber=true';
    } else {
      this._jobItemUrl += '&printVONumber=false';
    }
    if (printProvisionalSums) {
      this._jobItemUrl += '&printProvisionalSums=true';
    } else {
      this._jobItemUrl += '&printProvisionalSums=false';
    }
    if (printWholeAddendum) {
      this._jobItemUrl += '&printWholeAddendum=true';
    } else {
      this._jobItemUrl += '&printWholeAddendum=false';
    }
    if (printItemsNotTaken) {
      this._jobItemUrl += '&printItemsNotTaken=true';
    } else {
      this._jobItemUrl += '&printItemsNotTaken=false';
    }
    if (printChangeHistory) {
      this._jobItemUrl += '&printChangeHistory=true';
    } else {
      this._jobItemUrl += '&printChangeHistory=false';
    }
    if (printIncludedAmounts) {
      this._jobItemUrl += '&printIncludedAmounts=true';
    }
    if (printSalesQuoteOnly) {
      this._jobItemUrl += '&printSalesQuoteOnly=true';
    }
    if (printPdfAttachments) {
      this._jobItemUrl += '&printPdfAttachments=true';
    }
    if (printFromSalesPriceOnwards) {
      this._jobItemUrl += '&printFromSalesOnwards=true';
    } else {
      this._jobItemUrl += '&printFromSalesOnwards=false';
    }
    if (quoteDate) {
      this._jobItemUrl += '&quoteDate=' + quoteDate;
    }
    if (exportToXML) {
      this._jobItemUrl += '&exportToXML=' + exportToXML;
    }
    if (isPriceGuide) {
      this._jobItemUrl += '&isPriceGuide=' + isPriceGuide;
    }
    if (quoteExpiryDate) {
      this._jobItemUrl += '&quoteExpiryDate=' + quoteExpiryDate;
    }
    if (showSignatureSection) {
      this._jobItemUrl += '&showSignatureSection=true';
    } else {
      this._jobItemUrl += '&showSignatureSection=false';
    }

    // add todays local date to the url
    this._jobItemUrl += '&localDate=' + formatDate(new Date(), 'yyyy-MM-dd');

    return this._http.get<IJobItemAttachment>(this._jobItemUrl,
      this.httpService.getHttpFilePDFOptions()).pipe(
        catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
