<div class="modal-header">
  <h2 class="modal-title">Select Option</h2>
  <button type="button" class="close" aria-label="Close" (click)="showFullAddenda(true)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <button class="btn-sm btn-outline-primary font10" (click)="showFullAddenda(true)" ngbAutoFocus>
    Show the full {{addendumName}}?
  </button>
  <button class="btn-sm btn-outline-primary font10" (click)="showFullAddenda(false)" style="margin-top: 20px;">
    Show the items in this variation only?
  </button>
</div>
